import React from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useList, useSendInviteEmail } from './hooks';
import BuyerTable from './buyer-table';

const ListItem = ({
  retailerId,
  name,
  address,
  priceGroup,
  paymentTerms,
  paymentMethod,
  shippingMethod,
  selectedBuyers,
  setSelectedBuyers,
  toggleSelectedRetailers,
  selectedRetailers,
}) => {
  const { open, handleListItemClick } = useList();

  return (
    <List
      sx={{
        width: '100%',
        maxWidth: '100%',
        bgcolor: 'var(--paper-background-color)',
        padding: 0,
      }}
      component='nav'
      aria-labelledby='nested-list-subheader'
      onClick={(e) => {
        e.preventDefault();
      }}
    >
      <ListItemButton
        onClick={handleListItemClick}
        divider
        sx={{
          marginBottom: '10px',
          border: 'solid 1px var(--secondary-border-color)',
          padding: '0 15px',
        }}
      >
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          columnSpacing={2}
          sx={{
            padding: '15px',
          }}
        >
          <Grid item xs={0.5} sm={0.5}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={(() => {
                      var isSelected = false;
                      isSelected = selectedRetailers.some(
                        (retailerToCheck) => retailerToCheck === retailerId
                      );
                      return isSelected;
                    })()}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      toggleSelectedRetailers(retailerId);
                    }}
                  />
                }
              />
            </FormGroup>
          </Grid>

          <Grid item xs={3} sm={3}>
            <Typography
              sx={{
                color: 'var(--primary-font-color)',
                fontSize: '16px',
                fontWeight: '900',
                lineHeight: 2,
                letterSpacing: '0.24px',
              }}
            >
              {name}
            </Typography>
            <Typography
              variant='body2'
              display='block'
              gutterBottom
              sx={{
                lineHeight: 1.36,
                opacity: 0.7,
              }}
            >
              {address}
            </Typography>
          </Grid>

          <Grid item xs={2} sm={2}>
            <Typography
              variant='body2'
              sx={{
                color: 'var(--secondary-font-color)',
                lineHeight: 2,
              }}
            >
              Price Group:
            </Typography>
            <Typography
              variant='body2'
              display='block'
              gutterBottom
              sx={{
                lineHeight: 1.33,
              }}
            >
              {priceGroup}
            </Typography>
          </Grid>

          {/* <Grid item xs={2} sm={2}>
            <Typography
              variant='body2'
              sx={{
                color: 'var(--secondary-font-color)',
                lineHeight: 2,
              }}
            >
              Payment Terms:
            </Typography>
            <Typography
              variant='body2'
              display='block'
              gutterBottom
              sx={{
                lineHeight: 1.33,
              }}
            >
              {paymentTerms}
            </Typography>
          </Grid> */}

          <Grid item xs={2} sm={2}>
            <Typography
              variant='body2'
              sx={{
                color: 'var(--secondary-font-color)',
                lineHeight: 2,
              }}
            >
              Payment method
            </Typography>
            <Typography
              variant='body2'
              display='block'
              gutterBottom
              sx={{
                lineHeight: 1.33,
              }}
            >
              {paymentMethod}
            </Typography>
          </Grid>

          <Grid item xs={2} sm={2}>
            <Typography
              variant='body2'
              sx={{
                color: 'var(--secondary-font-color)',
                lineHeight: 2,
              }}
            >
              Shipping Method
            </Typography>
            <Typography
              variant='body2'
              display='block'
              gutterBottom
              sx={{
                lineHeight: 1.33,
              }}
            >
              {shippingMethod}
            </Typography>
          </Grid>

          <Grid item xs={2} sm={2} direction='row' alignItems='center'>
            {open ? (
              <Typography
                variant='h2'
                sx={{
                  color: 'var(--link-color)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '15px',
                  fontWeight: '500',
                }}
              >
                <span>Hide Buyers</span> <ExpandLess />
              </Typography>
            ) : (
              <Typography
                variant='h2'
                sx={{
                  color: 'var(--link-color)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '15px',
                  fontWeight: '500',
                }}
              >
                <span> Show Buyers</span> <ExpandMore />
              </Typography>
            )}
          </Grid>
        </Grid>
      </ListItemButton>
      <Collapse in={open} timeout='auto' unmountOnExit sx={{ marginBottom: 0 }}>
        <Box
          sx={{
            marginTop: '-12px',
            padding: '8px 14px 10px 14px',
            backgroundColor: '#f7fafb',
            boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            variant='h1'
            sx={{
              fontSize: '16px',
              fontWeight: '900',
            }}
          >
            List of Buyers
          </Typography>
          <IconButton onClick={handleListItemClick}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Stack
          sx={{
            padding: '19px',
            boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
            backgroundColor: 'var(--paper-background-color)',
          }}
        >
          <BuyerTable
            retailerId={retailerId}
            selectedBuyers={selectedBuyers}
            setSelectedBuyers={setSelectedBuyers}
          />
        </Stack>
      </Collapse>
    </List>
  );
};

ListItem.propTypes = {
  retailerId: PropTypes.string,
  name: PropTypes.string,
  address: PropTypes.string,
  priceGroup: PropTypes.string,
  paymentTerms: PropTypes.string,
  paymentMethod: PropTypes.string,
  shippingMethod: PropTypes.string,
  selectedBuyers: PropTypes.array.isRequired,
  selectedRetailers: PropTypes.array.isRequired,
  setSelectedBuyers: PropTypes.func.isRequired,
  toggleSelectedRetailers: PropTypes.func.isRequired,
};

ListItem.defaultProps = {
  retailerId: '',
  name: '',
  address: '',
  priceGroup: '',
  paymentTerms: '',
  paymentMethod: '',
  shippingMethod: '',
};
export default ListItem;
