import { useState, useRef } from 'react';

export const useDepartmentDrawerMenu = () => {
  const drawerRef = useRef(null);
  const [selectedDepartment, setSelectedDepartment] = useState();

  const handleSelectDepartment = (department) => {
    setSelectedDepartment(department);
  };

  const handleBackBtnClick = () => {
    setSelectedDepartment();
  };
  return {
    selectedDepartment,
    handleSelectDepartment,
    handleBackBtnClick,
    drawerRef,
  };
};
