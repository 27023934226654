import { useState, useEffect } from 'react';
import { api } from '../../../api';
import { useDispatch, useSelector } from 'react-redux';
import { createGroup } from 'pages/customers/actions';
import SessionStorageManager from 'utils/session-storage-manager';
import {
  useNavigate,
  useParams,
  useSearchParams,
  createSearchParams,
  useMatch,
} from 'react-router-dom';
import {
  suggestionEmailPayloadSelector,
  catalogPdfPayloadSelector,
  catalogLinkPayloadSelector,
  draftLinkPayloadSelector,
  orderSharePdfPayloadSelector,
} from '../selectors';
import { selectCustomerDetailSelector } from 'redux/selectors';
import { loggedInUserSelector } from 'redux/selectors';

export default function useSendingEmailRecipient({
  request,
  setEmailSentInfo,
  showInventory,
  selectedSignature,
  selectedSender,
  orderSharePdf,
  orderSharePdfInline,
}) {
  const [status, setStatus] = useState('Sending...');
  const { page, selectedTab } = useParams();
  let suggestionPage;
  let draftLink;
  let catalogLinkEmail;
  let shareCatalogPdf;
  let sendinviteEmail;

  if (page == 'suggestions') {
    suggestionPage = true;
  }
  if (page == 'catalogPdf') {
    shareCatalogPdf = true;
  }
  if (page == 'catalogLink') {
    catalogLinkEmail = true;
  }
  if (page == 'draftLink') {
    draftLink = true;
  }
  if (page == 'sendInviteEmail' || selectedTab) {
    sendinviteEmail = true;
  }

  const suggestionEmailpayloadData = useSelector(
    suggestionEmailPayloadSelector
  );
  const catalogPdfPayloadData = useSelector(catalogPdfPayloadSelector);
  const catalogLinkPayloadData = useSelector(catalogLinkPayloadSelector);
  const draftLinkPayloadData = useSelector(draftLinkPayloadSelector);
  const orderSharePdfPayloadData = useSelector(orderSharePdfPayloadSelector);
  const client = useSelector(selectCustomerDetailSelector);
  const user = useSelector(loggedInUserSelector);
  async function sendEmail() {
    if (suggestionPage) {
      let res = await api.post(`/shareSuggestionLink`, {
        copy_reps: request.type === 'salesRep' ? [request.recipientId] : '',
        buyer_id: request.type === 'buyer' ? request.recipientId : '',
        group_ids: request.type === 'group' ? request.recipientId : '',
        receipent_contact_id:
          request.type === 'externalContact' ? request?.recipientId : '',
        message: request?.message,
        subject: request?.subject,
        signature: `${user.name}\n${user.company}\n\n${user.email}`,
        linesheet_id: suggestionEmailpayloadData?.suggestionId,
        catalog_link: suggestionEmailpayloadData?.catalogLink,
        pdf_link: suggestionEmailpayloadData?.pdfLink,
        sender: request?.sender.value,
      });

      if (res?.data?.status) {
        setStatus('Sent');
        setEmailSentInfo((prev) => prev + 1);
      } else {
        setStatus('Failed');
      }

      return res;
    }

    if (draftLink) {
      let res = await api.post(`/sendSharedDraftLink`, {
        copy_reps: request?.type === 'salesRep' ? [request?.recipientId] : '',
        buyer_id: request?.type === 'buyer' ? request?.recipientId : '',
        group_ids: request?.type === 'group' ? request?.recipientId : '',
        worksheet_id: draftLinkPayloadData?.worksheetId,
        worksheet_collection_id: draftLinkPayloadData?.worksheetCollectionId,
        show_inventory: showInventory,
        // message: request?.message,
        postedData: {
          subject: request?.subject,
          // message: request?.message.replace(/<[^>]*>/g, ''),
          message: request?.message,
        },
      });
      if (res?.data?.status) {
        setStatus('Sent');
        setEmailSentInfo((prev) => prev + 1);
      } else {
        setStatus('Failed');
      }
      return res;
    }

    if (catalogLinkEmail) {
      let res = await api.post(`/sendCatalogLinkEmail`, {
        copyReps: request.type === 'salesRep' ? request.recipientId : '',
        buyerId: request.type === 'buyer' ? request.recipientId : '',
        groupIds: request.type === 'group' ? request.recipientId : '',
        message: request?.message,
        subject: request?.subject,
        linkName: catalogLinkPayloadData?.shareLinkName ?? '',
        linkUrl: catalogLinkPayloadData?.catalogLink ?? '',
      });

      if (res?.data?.status) {
        setStatus('Sent');
        setEmailSentInfo((prev) => prev + 1);
      } else {
        setStatus('Failed');
      }
      return res;
    }
    if (shareCatalogPdf) {
      let res = await api.post(`/sendCatalogPdfEmail`, {
        subject: request?.subject,
        message: request?.message,
        catalogLink: catalogPdfPayloadData.catalogLink,
        catalogPdfLink: catalogPdfPayloadData.catalogPdfLink,
        copyReps: request.type === 'salesRep' ? request.recipientId : '',
        groupIds: request.type === 'group' ? request.recipientId : '',
        buyerId: request.type === 'buyer' ? request.recipientId : '',
      });

      if (res?.data?.status) {
        setStatus('Sent');
        setEmailSentInfo((prev) => prev + 1);
      } else {
        setStatus('Failed');
      }
      return res;
    }
    if (sendinviteEmail) {
      let res = await api.post(`/sendInviteEmail`, {
        buyer_id: request.type === 'buyer' ? request.recipientId : '',
        sales_rep_id: selectedSignature.value,
        from_sender: selectedSender.value,
        postedData: {
          subject: request?.subject,
          // message: request?.message.replace(/<[^>]*>/g, ''),
          message: request?.message,
        },
      });

      if (res?.data?.status) {
        setStatus('Sent');
        setEmailSentInfo((prev) => prev + 1);
      } else {
        setStatus('Failed');
      }
      return res;
    }
    if (orderSharePdf) {
      let res = await api.post('order/sendOrderPdfEmail', {
        worksheet_collection_id:
          orderSharePdfPayloadData?.worksheetCollectionId ?? '',
        receipent_contact_id:
          request.type === 'buyer' ? request.recipientId : '',
        receipent_rep_assoc_ids:
          request?.type === 'salesRep' ? [request?.recipientId] : [''],
        subject: request?.subject,
        message: request?.message,
        filename: request?.pdfTitle,
        type: orderSharePdfPayloadData?.type,
        oldFileName: orderSharePdfPayloadData?.oldFileName
          ? orderSharePdfPayloadData?.oldFileName
          : '',
      });
      if (res?.data?.status) {
        setStatus('Sent');
        setEmailSentInfo((prev) => prev + 1);
      } else {
        setStatus('Failed');
      }
      return res;
    }
    if (orderSharePdfInline) {
      let res = await api.post('order/sendOrderPdfEmail', {
        to_email: request?.recipientEmail,
        message: request?.message,
        subject: request?.subject,
        worksheet_collection_id:
          orderSharePdfPayloadData?.worksheetCollectionId,
        type: orderSharePdfPayloadData?.type,
        filename: orderSharePdfPayloadData?.fileName,
      });
      if (res?.data?.status) {
        setStatus('Sent');
        setEmailSentInfo((prev) => prev + 1);
      } else {
        setStatus('Failed');
      }
      return res;
    }
  }
  useEffect(() => {
    sendEmail();
  }, []);

  return { status };
}
