import { useState, useEffect } from 'react';
import { api } from '../../../api';

export default function useEmailStatusModal({ notificationId }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [isLoading, setIsLoading] = useState(true);
  const [emailData, setEmailData] = useState([]);

  //    logic to handle onPageChange for pagination
  const onPageChangeHandler = (event, page) => {
    event.preventDefault();
    setPage(page);
    // getBuyers(page, rowsPerPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  // const data = [];
  // for (let i = 1; i < 10; i++) {
  //   data.push({
  //     account: 'Laguna Cycle',
  //     accountno: '1234567',
  //     email: 'test@email.com',
  //     phoneNo: '99228832032',
  //     buyerName: 'testing',
  //     status: 'sent',
  //   });
  // }
  const fetchEmailStatusData = async () => {
    const res = await api.post('notification/displaySentEmailList', {
      notificationId: notificationId,
      iDisplayStart: page,
      iDisplayLength: rowsPerPage,
    });
    console.log('res', res);
    if (res?.data) {
      setEmailData(res?.data);
      setIsLoading(false);
    } else {
      setEmailData([]);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchEmailStatusData();
  }, [page, rowsPerPage]);
  return {
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    onPageChangeHandler,
    handleChangeRowsPerPage,
    emailData,
    isLoading,
    setIsLoading,
  };
}
