import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Box, InputLabel, TextField, InputAdornment } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Typography from '@mui/material/Typography';

import moment from 'moment-timezone';

const CustomTimePicker = ({
  label,
  sx,
  onChange,
  value,
  disabled,
  readOnly,
  ampm,
  id,
  name,
  format,
  error,
  helperText,
  views,
  required,
  showPopupActions,
  ...props
}) => {
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const handleTimeChange = (e) => {
    const dateObject = e ? e.toDate() : null;
    const localTime = moment(dateObject).tz(userTimeZone).format(format); // Convert to user timezone
    onChange(localTime); // Send back the formatted time to parent
  };
  return (
    <Box sx={{ ...sx }}>
      {label && (
        <InputLabel>
          {label} {required && <span style={{ color: 'red' }}>*</span>}
        </InputLabel>
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
          open={openDatePicker}
          onClose={() => setOpenDatePicker(false)}
          timezone={userTimeZone}
          value={moment(value, ['h:mma', 'HH:mm']).toDate()}
          onChange={handleTimeChange}
          ampm={ampm}
          disabled={disabled}
          readOnly={readOnly}
          views={views}
          inputFormat={format}
          {...props}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                disabled={disabled}
                readOnly={true}
                id={id}
                name={name}
                error={error}
                disableError
                inputProps={{
                  ...params.inputProps,
                  readOnly,
                  onClick: () => setOpenDatePicker(true),
                  placeholder: format.toLowerCase(),
                }}
                InputProps={{
                  style: {
                    padding: '0 0 0 8px',
                  },
                  endAdornment: (
                    <InputAdornment position='end'>
                      <AccessTimeIcon
                        sx={{
                          height: '15px',
                          marginRight: '3px',
                          cursor: 'pointer',
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                onClick={() => setOpenDatePicker(true)}
              />
            );
          }}
          componentsProps={{
            actionBar: {
              // The actions will be the same between desktop and mobile
              actions: showPopupActions ? ['clear', 'accept'] : [],
            },
          }}
        />
      </LocalizationProvider>
      {error ? (
        <Typography variant='body2' sx={{ color: 'var(--error-color)' }}>
          {helperText}
        </Typography>
      ) : null}
    </Box>
  );
};

CustomTimePicker.propTypes = {
  label: PropTypes.string,
  sx: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  ampm: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  format: PropTypes.string,
  error: PropTypes.bool,
  showPopupActions: PropTypes.bool,
  views: PropTypes.arrayOf(PropTypes.string),
  props: PropTypes.object,
  helperText: PropTypes.string,
};

CustomTimePicker.defaultProps = {
  label: '',
  sx: {},
  onChange: () => {},
  value: '',
  disabled: false,
  readOnly: false,
  ampm: false,
  showPopupActions: false,
  id: '',
  name: '',
  error: false,
  format: 'HH:mm:ss',
  views: ['hours', 'minutes', 'seconds'],
  required: false,
  helperText: '',
};

export default CustomTimePicker;
