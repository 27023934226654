import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1300,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: 'Avenir',
    h1: {
      fontSize: '22px',
      fontWeight: 900,
      color: 'var(--primary-font-color)',
    },
    h2: {
      fontSize: '16px',
      fontWeight: 700,
      color: 'var(--primary-font-color)',
    },
    h3: {
      fontSize: '15px',
      fontWeight: 700,
      color: 'var(--primary-font-color)',
    },
    h4: {
      fontSize: '14px',
      fontWeight: 900,
      color: 'var(--primary-font-color)',
    },
    body1: {
      fontSize: '12px',
      color: 'var(--primary-font-color)',
    },
    body2: {
      fontSize: '12px',
      color: 'var(--primary-font-color)',
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: '13px',
      color: 'var(--primary-font-color)',
    },
    subtitle2: {
      fontSize: '12px',
      color: 'var(--primary-font-color)',
      fontWeight: 500,
    },
    content: {
      fontSize: '11px',
      color: 'var(--primary-font-color)',
    },
    subtitle1M: {
      fontSize: '14px',
      color: 'var(--primary-font-color)',
    },
  },
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          borderWidth: '1px',
          padding: 0,
          borderRadius: 0,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 0,
          borderRadius: 0,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiModal: {
      defaultProps: {
        disableScrollLock: false,
      },
    },
    MuiDialog: {
      defaultProps: {
        disableScrollLock: false,
      },
    },
    MuiPopover: {
      defaultProps: {
        elevation: 3,
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          border: '1px solid var(--primary-border-color)',
          outline: 'none',
          fontSize: '12px',
          fontWeight: 500,
          color: 'var(--primary-font-color)',
          height: '38px',
          padding: '3px 0 3px',
          borderRadius: '4px',
          '&:before': {
            content: 'none',
          },
          '&:after': {
            content: 'none',
          },
        },
        input: {
          paddingLeft: '8px',
          paddingRight: '8px',
        },
      },
    },
    MuiTextField: {
      fontFamily: 'Avenir',
      backgroundColor: '#fff',
      border: '1px solid var(--primary-border-color)',
      fontSize: '12px',
      fontWeight: 500,
      color: 'var(--primary-font-color)',
      height: '38px',
      padding: '3px 0 3px',
      borderRadius: '4px',
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          color: 'var(--primary-font-color)',
          marginBottom: '2px',
          fontWeight: 700,
        },
        asterisk: {
          color: '#f02711',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '14px',
          color: 'var(--primary-font-color)',
          fontWeight: 500,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          '& .MuiOutlinedInput-input': {
            padding: '10px 15px 10px 10px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px',
            borderColor: 'var(--primary-border-color)',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            border: '1px solid var(--primary-border-color)',
          },
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              border: '1px solid var(--primary-border-color)',
            },
          },
        },

        input: {
          '&::placeholder': {
            color: '#a1a1a1',
            fontSize: '12px',
            fontWeight: '500',
            opacity: 1,
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: 'rgba(68, 68, 68, 0.5)',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {},
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          '& .MuiOutlinedInput-root': {
            padding: '3px 9px 3px 9px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px',
            borderColor: 'var(--primary-border-color)',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            border: '1px solid var(--primary-border-color)',
          },
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              border: '1px solid var(--primary-border-color)',
            },
          },
        },
        tag: {
          margin: '2px 3px 2px 3px',
        },

        input: {
          '&::placeholder': {
            color: '#a1a1a1',
            fontSize: '15px',
            fontWeight: '500',
            opacity: 1,
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          '& ul': {
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
        paper: {
          border: '1px solid var(--primary-border-color)',
          marginTop: '3px',
        },
      },
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          backgroundColor: 'var(--color-white)',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: 'var(--primary-font-color)',
          fontSize: '12px',
          fontWeight: 500,
          padding: '9px 14px',
          minWidth: '128px',
          border: 0,
          '&.Mui-selected': {
            backgroundColor: '#dedede',
            '&:hover': {
              backgroundColor: '#dedede',
            },
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '4px',
          fontWeight: 500,
        },
        containedPrimary: {
          backgroundColor: 'var(--theme-color)',
          color: 'var(--contained-primary-button-text-color)',
          '&:hover': {
            backgroundColor: 'var(--theme-color)',
            filter: 'brightness(85%)',
          },
        },
        outlinedSecondary: {
          color: 'var(--primary-font-color)',
          borderColor: 'var(--primary-border-color)',
          backgroundColor: 'var(--color-white)',
        },
        text: {
          fontSize: '12px',
          color: 'var(--primary-font-color)',
          borderRadius: 0,
          minWidth: 0,
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
        sizeSmall: {
          fontSize: '12px',
          height: '32px',
          fontWeight: 700,
        },
        sizeMedium: {
          fontSize: '12px',
          height: '38px',
          fontWeight: 700,
        },
        sizeLarge: {
          fontSize: '16px',
          height: '40px',
          borderWidth: '2px',
          '&:hover': {
            borderWidth: '2px',
          },
        },
        iconSizeSmall: {
          width: '12px',
          height: 'auto',
          marginRight: '6px',
        },
        iconSizeMedium: {
          width: '15px',
          height: 'auto',
        },
        iconSizeLarge: {
          width: '15px',
          height: 'auto',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          fontWeight: 500,
          borderBottom: '1px solid var(--secondary-border-color)',
          borderTop: '1px solid var(--secondary-border-color)',
          color: 'var(--primary-font-color)',
          paddingLeft: '10px',
          paddingRight: '10px',
          justifyContent: 'center',
        },
        sizeMedium: {
          padding: '13px 16px 13px 16px',
        },
        head: {
          backgroundColor: 'var(--table-header-color)',
          fontSize: '13px',
          color: 'var(--primary-font-color)',
          fontWeight: 700,
        },
        select: {
          padding: 0,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          borderTop: 'solid 1px var(--primary-border-color)',
        },
        select: {
          borderRadius: '4.5px',
          border: 'solid 1px rgba(0, 0, 0, 0.54)',
          paddingRight: '17px',
        },
      },
    },
  },
});
