export const authPageStyles = {
  container: (theme, mobile, tablet) => ({
    padding: '30px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      borderRadius: '4% 4% 0px 0px',
      backgroundColor: 'var(--color-white)',
    },
    minHeight: '100vh',
    height: !mobile || !tablet ? '100%' : 'fit-content',
    // height: '100vh',
    overflow: !mobile || !tablet ? 'scroll' : '',
  }),
  wholesaleAccountText: {
    color: 'var(--primary-font-color)',
    whiteSpace: 'nowrap',
    opacity: '0.8',
  },
  signupBtn: {
    height: '36px',
    whiteSpace: 'nowrap',
    width: 'fit-content',
  },
  wholeSaleAccountSkeleton: { fontSize: '15px' },
  welcomeMessageContainer: { marginBottom: '80px', marginTop: '20px' },
  welcomeMessage: {
    fontSize: '13px',
    color: 'var(--primary-font-color)',
    textAlign: 'center',
  },
  welcomeMessageSkeletonContainer: {
    marginBottom: '80px',
    marginTop: '20px',
  },
  welcomeMessageSkeletonText: {
    fontSize: '13px',
  },
  forSupportContainer: { width: '93%', position: 'absolute', bottom: '30px' },
  forSupport: {
    fontSize: '12px',
    color: 'var(--primary-font-color)',
    textAlign: 'center',
    wordWrap: 'break-word',
  },
  phone: { color: 'var(--primary-font-color)', textDecoration: 'underline' },
  email: { textDecoration: 'underline' },
  fontSize12: {
    fontSize: '12px',
  },
};

export const formErrorStyles = {
  container: {
    border: '2px solid var(--error-color)',
    width: '480px',
    // height: '56px',
    display: 'flex',
    // alignItems: 'center',
    flexDirection: 'column',
    padding: '10px 20px',
    position: 'relative',
  },
  errorText: {
    color: 'var(--error-color)',
    display: 'flex',
    gap: '13px',
    alignItems: 'center',
    flex: 1,
  },
  errors: {
    color: 'var(--error-color)',
  },
  errorIcon: {
    color: 'var(--error-color)',
  },
  closeIcon: {
    flex: 1,
    justifyContent: 'right',
    position: 'absolute',
    right: '25px',
    top: '15px',
  },
};

export const splashStyles = {
  container: (url, theme) => ({
    background: `url(${url})`,
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    // backgroundPosition: 'center center',
    // position: 'fixed',
    // top: 0,
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
    },
  }),
};
