import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import { getDepartmentCatagory } from 'pages/layout/layout-api';
import { CategoriesStyles as styles } from 'pages/layout/department-bar/styles';

const CategoryComponent = ({
  delivery,
  genderId,
  handleDepartmentMenuClick,
}) => {
  // fetching and caching categories
  const { data: categories = [], isLoading: categoriesLoading } = useQuery(
    ['getDepartmentCatagory', genderId, delivery?.id],
    () => getDepartmentCatagory(delivery?.id, genderId),
    {
      enabled: !!delivery?.id && !!genderId,
      cacheTime: 3600000,
      staleTime: 3600000,
      select: (data) => {
        return data?.data?.data || [];
      },
    }
  );

  if (categoriesLoading) {
    return (
      <CircularProgress
        sx={{
          width: '6px',
          height: '6px',
        }}
      />
    );
  } else {
    return categories?.categories_data?.map((category, i) => (
      <Stack key={i} sx={{ padding: '0px 55px 0px 18px' }}>
        <Button
          key={category?.id}
          variant='text'
          sx={[styles.megaMenuCatagories]}
          onClick={() =>
            handleDepartmentMenuClick({ item: category, genderId })
          }
        >
          {category.name}
        </Button>
      </Stack>
    ));
  }
};
export default CategoryComponent;
CategoryComponent.propTypes = {
  delivery: PropTypes.obj,
  genderId: PropTypes.any,
  handleDepartmentMenuClick: PropTypes.func,
};

CategoryComponent.defaultProps = {
  delivery: {},
  genderId: '',
  handleDepartmentMenuClick: () => {},
};
