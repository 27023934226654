import { useState, useEffect } from 'react';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, ContentState } from 'draft-js';

export default function useTextEditor({
  value,
  setValue,
  editorValue,
  setEditorValue,
  edit,
}) {
  const [images, setImages] = useState([]); // Local state to store images as Data URLs

  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(editorValue)
    )
  );
  useEffect(() => {
    if (edit) {
      setEditorState(() =>
        EditorState.createWithContent(ContentState.createFromText(value))
      );
    }
  }, [edit]);
  const onEditorStateChange = (e) => {
    setEditorState(e);
    setEditorValue(() => {
      var contentStateObj = editorState.getCurrentContent();
      return contentStateObj.getBlocksAsArray();
    });
    setValue(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  const uploadImageCallBack = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const dataURL = e.target.result;
        setImages((prevImages) => [...prevImages, dataURL]);
        resolve({ data: { link: dataURL } });
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  return {
    editorState,
    setEditorState,
    onEditorStateChange,
    uploadImageCallBack,
  };
}
