import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '../../../../components/text-field';
import { sendRequestInfoEmailFunc } from '../../action';
import { useDispatch } from 'react-redux';

export function catalogRequestInfoHooks({ ROLE_ID }) {
  const [openRequestInfoModal, setOpenRequestInfoModal] = useState(false);

  let confirmRequestInfo = '';
  let requestInfoTitle = '';
  if (ROLE_ID == 4) {
    requestInfoTitle = 'For Buyer use only';

    confirmRequestInfo = (
      <Button
        variant='contained'
        onClick={() => {
          setOpenRequestInfoModal(false);
        }}
      >
        Ok
      </Button>
    );
  } else {
    requestInfoTitle = 'PRODUCT INFORMATION REQUEST EMAIL';

    confirmRequestInfo = (
      <TextField
        id='outlined-multiline-static'
        label='Multiline'
        multiline
        rows={4}
        defaultValue='Default Value'
      />
    );
  }

  const dispatch = useDispatch();
  const sendRequestInfoEmail = async (payload) => {
    const response = await dispatch(sendRequestInfoEmailFunc(payload));
    if (response.status) {
      // console.log(response);
    }
  };

  return {
    openRequestInfoModal,
    setOpenRequestInfoModal,
    confirmRequestInfo,
    requestInfoTitle,
    sendRequestInfoEmail,
  };
}
