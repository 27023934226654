import React from 'react';
import LoginForm from './login-form';
import AuthPage from '../components/auth-page';

const Login = () => {
  return (
    <AuthPage showWelcomeMessage1 showWelcomeMessage2 height='100vh'>
      <LoginForm />
    </AuthPage>
  );
};

export default Login;
