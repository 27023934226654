import React from 'react';
import PropTypes from 'prop-types';

export default function InstagramIcon({ fill, style }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill={fill}
      style={style}
    >
      <g>
        <path
          d='M211.211,209.105A2.105,2.105,0,1,1,209.105,207,2.105,2.105,0,0,1,211.211,209.105Zm0,0'
          transform='translate(-198.105 -198.105)'
        />
        <path
          d='M145.587,136.941a2.092,2.092,0,0,0-1.2-1.2,3.5,3.5,0,0,0-1.173-.217c-.666-.03-.866-.037-2.552-.037s-1.886.006-2.552.037a3.5,3.5,0,0,0-1.173.218,2.093,2.093,0,0,0-1.2,1.2,3.5,3.5,0,0,0-.217,1.173c-.03.666-.037.866-.037,2.552s.007,1.886.037,2.552a3.5,3.5,0,0,0,.217,1.173,2.092,2.092,0,0,0,1.2,1.2,3.49,3.49,0,0,0,1.173.218c.666.03.866.037,2.552.037s1.887-.006,2.552-.037a3.49,3.49,0,0,0,1.173-.218,2.092,2.092,0,0,0,1.2-1.2,3.5,3.5,0,0,0,.218-1.173c.03-.666.037-.866.037-2.552s-.006-1.886-.037-2.552A3.49,3.49,0,0,0,145.587,136.941Zm-4.924,6.969a3.243,3.243,0,1,1,3.243-3.243A3.243,3.243,0,0,1,140.663,143.91Zm3.372-5.857a.758.758,0,1,1,.758-.758A.758.758,0,0,1,144.034,138.053Zm0,0'
          transform='translate(-129.663 -129.667)'
        />
        <path d='M11,0A11,11,0,1,0,22,11,11,11,0,0,0,11,0Zm6.278,13.6a4.634,4.634,0,0,1-.294,1.533,3.23,3.23,0,0,1-1.847,1.847,4.637,4.637,0,0,1-1.533.294c-.674.031-.889.038-2.6.038s-1.931-.007-2.6-.038a4.637,4.637,0,0,1-1.533-.294,3.23,3.23,0,0,1-1.847-1.847A4.633,4.633,0,0,1,4.722,13.6c-.031-.674-.038-.889-.038-2.6s.007-1.931.038-2.6a4.635,4.635,0,0,1,.293-1.533A3.232,3.232,0,0,1,6.863,5.015,4.639,4.639,0,0,1,8.4,4.722c.674-.031.889-.038,2.6-.038s1.931.007,2.6.038a4.639,4.639,0,0,1,1.533.293,3.231,3.231,0,0,1,1.847,1.847A4.634,4.634,0,0,1,17.278,8.4c.031.674.038.889.038,2.6S17.309,12.931,17.278,13.6Zm0,0' />
      </g>
    </svg>
  );
}

InstagramIcon.propTypes = {
  fill: PropTypes.string,
  style: PropTypes.object,
};

InstagramIcon.defaultProps = {
  fill: 'var(--primary-icon-color)',
  style: {},
};
