import React from 'react';
import PropTypes from 'prop-types';

export default function PencilIcon({ fill, width, height, style }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      fill={fill}
    >
      <path
        d='m16.787 4.85-3.636-3.637a.727.727 0 0 0-1.028 0L1.213 12.122a.727.727 0 0 0-.213.515v3.636a.727.727 0 0 0 .727.727h3.637a.727.727 0 0 0 .514-.213L16.787 5.878a.727.727 0 0 0 0-1.028zM5.063 15.546H2.455v-2.608l8-8 2.608 2.608zm9.028-9.028-2.608-2.609 1.153-1.153 2.608 2.608z'
        transform='translate(-.8 -.744)'
      />
    </svg>
  );
}

PencilIcon.propTypes = {
  fill: PropTypes.string,
  style: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.string,
};

PencilIcon.defaultProps = {
  fill: '#0066c0',
  style: {},
  width: '16.456',
  height: '16.456',
};
