import React from 'react';
import PropTypes from 'prop-types';

export default function PhoneIcon({ fill }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill={fill}
    >
      <g transform='translate(-1 -1)'>
        <path d='M18.421,13.956c-.032-.026-3.625-2.6-4.6-2.429-.469.083-.737.4-1.274,1.042-.086.1-.295.35-.455.526a7.475,7.475,0,0,1-.991-.4A8.22,8.22,0,0,1,7.308,8.9a7.475,7.475,0,0,1-.4-.991c.176-.161.424-.37.529-.458.637-.534.956-.8,1.039-1.271.17-.971-2.4-4.568-2.429-4.6A1.373,1.373,0,0,0,5.02,1C3.977,1,1,4.862,1,5.513c0,.038.055,3.88,4.793,8.7C10.607,18.945,14.45,19,14.487,19,15.138,19,19,16.023,19,14.98a1.372,1.372,0,0,0-.579-1.024Zm-4,3.84c-.524-.043-3.749-.469-7.78-4.429C2.66,9.314,2.246,6.081,2.2,5.58A16.233,16.233,0,0,1,5.028,2.243c.024.024.056.06.1.107A21.234,21.234,0,0,1,7.269,5.988a7.132,7.132,0,0,1-.61.547,6.02,6.02,0,0,0-.907.853l-.146.2.043.247a6.851,6.851,0,0,0,.579,1.585,9.426,9.426,0,0,0,4.349,4.348,6.833,6.833,0,0,0,1.585.58l.247.043.2-.146a6.07,6.07,0,0,0,.857-.911c.188-.224.439-.524.534-.608a21.1,21.1,0,0,1,3.647,2.147c.05.042.085.074.108.1A16.218,16.218,0,0,1,14.421,17.8Z' />
      </g>
    </svg>
  );
}

PhoneIcon.propTypes = {
  fill: PropTypes.string,
};

PhoneIcon.defaultProps = {
  fill: 'var(--primary-icon-color)',
};
