import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from 'components/text-field';
import TextEditor from 'components/text-editor';

export default function SendMessage({
  subject,
  setSubject,
  message,
  setMessage,
  editorValue,
  setEditorValue,
  showSubjectField,
  showPdfTitle,
  subjectEmptyError,
  setSubjectEmptyError,
  messageEmptyError,
  setMessageEmptyError,
  onFirstTimeLoad,
}) {
  useEffect(() => {}, [onFirstTimeLoad, message]); // this is required to refresh the component when the message or onFIrstTimeload changes

  return (
    <Box>
      {showPdfTitle ? (
        <Stack
          direction='row'
          spacing={2}
          width='100%'
          alignItems='center'
          sx={{ width: '100%', paddingBottom: '10px' }}
        >
          <Typography variant='body2'>PDF Title</Typography>
          <TextField
            label=''
            placeholder='Add PDF Title'
            sx={{ width: '100%' }}
            value={subject}
            onChange={(e) => {
              e.preventDefault();
              setSubject(e.target.value);
            }}
          />
        </Stack>
      ) : null}
      {showSubjectField ? (
        <TextField
          error={subjectEmptyError}
          helperText={subjectEmptyError ? 'Subject cannot be empty' : ''}
          label=''
          placeholder='Add Subject'
          sx={{ width: '100%' }}
          value={subject}
          onChange={(e) => {
            e.preventDefault();
            if (e.target.value.length == 0) {
              setSubjectEmptyError(true);
            } else {
              setSubjectEmptyError(false);
            }

            setSubject(e.target.value);
          }}
        />
      ) : null}
      <TextEditor
        value={message}
        setValue={(value) => {
          if (value.trim() === ''.trim() || value.trim() === '<p></p>'.trim()) {
            setMessageEmptyError(true);
          } else {
            setMessageEmptyError(false);
          }
          setMessage(value);
        }}
        editorValue={editorValue}
        setEditorValue={setEditorValue}
        placeholder='Add message'
        height={280}
        error={messageEmptyError}
        helperText={messageEmptyError ? 'Message cannot be empty' : ''}
        edit={onFirstTimeLoad}
      />
    </Box>
  );
}

SendMessage.propTypes = {
  subject: PropTypes.string.isRequired,
  setSubject: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  setMessage: PropTypes.func.isRequired,
  editorValue: PropTypes.array.isRequired,
  setEditorValue: PropTypes.func.isRequired,
  showSubjectField: PropTypes.bool,
  showPdfTitle: PropTypes.bool,
  subjectEmptyError: PropTypes.bool,
  setSubjectEmptyError: PropTypes.func.isRequired,
  messageEmptyError: PropTypes.bool,
  setMessageEmptyError: PropTypes.func.isRequired,
  onFirstTimeLoad: PropTypes.bool,
};

SendMessage.defaultProps = {
  showSubjectField: true,
  showPdfTitle: false,
  messageEmptyError: false,
  setMessageEmptyError: () => {},
  onFirstTimeLoad: false,
};
