import * as Yup from 'yup';

export const changePasswordSchema = Yup.object().shape({
  newpassword: Yup.string()
    .required('New Password is required')
    .min(6, 'New Password must be at least 6 characters'),
  confirmpassword: Yup.string()
    .required('New Password is required')
    .oneOf([Yup.ref('newpassword'), null], 'New Passwords do not match'),
});
