import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ResizeIcon from 'assets/icons/resize-icon';
import styles from './styles';

export default function DraggableContainer({
  children,
  dragHandleProps,
  onRemove,
  sx,
}) {
  return (
    <Stack>
      <Stack {...dragHandleProps} sx={styles.header}>
        <ResizeIcon />
        {onRemove && (
          <IconButton onClick={onRemove}>
            <CancelOutlinedIcon sx={styles.icon} />
          </IconButton>
        )}
      </Stack>
      <Stack sx={{ ...styles.contentWrapper, ...sx }}>{children}</Stack>
    </Stack>
  );
}

DraggableContainer.propTypes = {
  children: PropTypes.node,
  dragHandleProps: PropTypes.object,
  onRemove: PropTypes.func,
  sx: PropTypes.object,
};
