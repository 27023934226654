import * as Yup from 'yup';

export const loginFormSchema = Yup.object().shape({
  username: Yup.string().required('Please enter username'),
  password: Yup.string().required('Please enter password'),
  termsAndConditions: Yup.boolean().oneOf(
    [true],
    'You must accept the terms and conditions'
  ),
});
