/* eslint-disable react/no-unknown-property */
import React from 'react';
import PropTypes from 'prop-types';

export default function AccountIconM({ fill, active, width, height, stroke }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='25'
      viewBox='0 0 25 25'
    >
      <g
        id='vuesax_linear_user'
        data-name='vuesax/linear/user'
        transform='translate(-108 -188)'
      >
        <g id='user' transform='translate(108 188)'>
          <path
            id='Vector'
            d='M10,5A5,5,0,1,1,5,0,5,5,0,0,1,10,5Z'
            transform='translate(7.5 2)'
            fill='none'
            stroke={stroke}
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='1.2'
          />
          <path
            id='Vector-2'
            data-name='Vector'
            d='M18.18,7c0-3.87-4.074-7-9.09-7S0,3.13,0,7'
            transform='translate(3.41 16)'
            fill='none'
            stroke={stroke}
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='1.2'
          />
          <path
            id='Vector-3'
            data-name='Vector'
            d='M0,0H25V25H0Z'
            fill='none'
            opacity='0'
          />
        </g>
      </g>
    </svg>
  );
}

AccountIconM.propTypes = {
  fill: PropTypes.string,
  active: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  stroke: PropTypes.string,
};

AccountIconM.defaultProps = {
  fill: 'var(--primary-icon-color)',
  active: 'false',
  width: '34px',
  height: '34px',
  stroke: '#292d32',
};
