export function catalogSendButton({
  sendButtonType,
  product,
  cartFlag,
  perRow,
  orderDetailsComponent,
}) {
  let buttonText = '';
  if (!product.canAdd || product.canAdd == 'false') {
    if (product.displayOnly) {
      buttonText = 'Request Info';
    } else {
      buttonText = product.buttonText;
    }
  } else {
    if (cartFlag) {
      if (sendButtonType == 'grid') {
        buttonText = perRow > 3 ? 'Remove' : 'Remove From Cart';
      } else if (sendButtonType == 'list') {
        buttonText = 'Remove';
      } else if (sendButtonType == 'modal') {
        buttonText = orderDetailsComponent
          ? 'Remove From Order'
          : 'Remove From Cart';
      }
    } else {
      if (sendButtonType == 'grid') {
        buttonText = perRow > 3 ? 'Add' : 'Add To Cart';
      } else if (sendButtonType == 'list') {
        buttonText = 'Add';
      } else if (sendButtonType == 'modal') {
        buttonText = orderDetailsComponent ? 'Add To Order' : 'Add To Cart';
      }
    }
  }

  return {
    buttonText,
  };
}
