import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveDraftSuggestion } from 'redux/actions/global-actions';
import { departmentListSelector } from 'redux/selectors';
import { checkBuyerBeDeleted } from '../../actions';
import { api } from 'api';
import SessionStorageManager from 'utils/session-storage-manager';
import { useNavigate } from 'react-router-dom';

export default function useRetailerBuyerCard(buyer, retailerId) {
  // state for additional info modal
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);

  // state for more options popover
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMoreOptions, setOpenMoreOptions] = useState(null);
  const [openAdditionalInfo, setOpenAdditionalInfo] = useState(null);
  const dispatch = useDispatch();

  // state for confirm modal to delete
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);

  //  state for edit buyer modal
  const [showEditBuyerModal, setShowEditBuyerModal] = useState(false);
  // state for send buyer invite modal
  const [showSendInviteBuyerModal, setShowSendInviteBuyerModal] =
    useState(false);
  const [noOrdersAlert, setShowNoOrdersAlert] = useState(false);
  const [showCreateNewDraft, setShowCreateNewDraft] = useState(false);
  const defaultDepartment = useSelector(departmentListSelector);
  const navigate = useNavigate();
  const [showActiveConfirmationModal, setShowActiveConfirmationModal] =
    useState(false);

  // handle click for more options popover
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
    if (e.currentTarget.id === 'more-options-trigger')
      setOpenMoreOptions(e.currentTarget);
    else if (e.currentTarget.id === 'additional-info-trigger')
      setOpenAdditionalInfo(e.currentTarget);
  };

  // handle close for AdditionInfo popover
  const handleClose = () => {
    setAnchorEl(null);
    setOpenMoreOptions(null);
    setOpenAdditionalInfo(null);
  };

  const getFirstLetters = (str) => {
    const firstLetters = str
      .split(' ')
      .map((word) => word[0])
      .join('');

    return firstLetters.toUpperCase();
  };
  const checkOrderListed = async () => {
    const response = await dispatch(checkBuyerBeDeleted(buyer.user_id));
    if (response.data.status) {
      setShowConfirmModal(true);
    } else {
      setShowAlertModal(true);
    }
  };
  // create draft
  async function createDraft(name, redirect) {
    const res = await api.post(`/createOrderFromBuyer`, {
      name: name ?? '',
      description: '',
      productGenderId: 0,
      buyerUserId: buyer?.user_id ?? '',
      buyerId: retailerId ?? '',
    });

    if (res.data.status) {
      const backToCatalogUrl =
        SessionStorageManager.getSessionStorage('last_catalog_url') ??
        `/catalog?gndr=${defaultDepartment?.commonGenderId}&cl=${defaultDepartment?.commonCollectionId}&start=0`;

      dispatch(getActiveDraftSuggestion());
      //close quick panel
      if (redirect) navigate(backToCatalogUrl);
    }
  }

  return {
    showAdditionalInfo,
    setShowAdditionalInfo,
    anchorEl,
    setAnchorEl,
    handleClick,
    handleClose,
    openAdditionalInfo,
    setOpenAdditionalInfo,
    openMoreOptions,
    setOpenMoreOptions,
    getFirstLetters,
    showConfirmModal,
    setShowConfirmModal,
    showEditBuyerModal,
    setShowEditBuyerModal,
    showAlertModal,
    setShowAlertModal,
    checkOrderListed,
    showSendInviteBuyerModal,
    setShowSendInviteBuyerModal,
    noOrdersAlert,
    setShowNoOrdersAlert,
    showCreateNewDraft,
    setShowCreateNewDraft,
    createDraft,
    showActiveConfirmationModal,
    setShowActiveConfirmationModal,
  };
}
