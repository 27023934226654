import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Confirm from '../../../../components/modals/confirmation-modal';
import AddRequestInfoModal from '../../../../components/modals/request-info/request-info-model';
import { catalogRequestInfoHooks } from './catalog-request-info-hooks';

const CatalogRequestInfo = ({
  ROLE_ID,
  sendRequestInfoEmail,
  productId,
  openRequestInfoModal,
  setOpenRequestInfoModal,
  confirmRequestInfo,
  requestInfoTitle,
}) => {
  return (
    <>
      {openRequestInfoModal ? (
        ROLE_ID == 4 ? (
          <Confirm
            open={openRequestInfoModal}
            setOpen={setOpenRequestInfoModal}
            title={requestInfoTitle}
            message=''
            action={confirmRequestInfo}
            showCancel={false}
          />
        ) : (
          <AddRequestInfoModal
            open={openRequestInfoModal}
            setOpen={setOpenRequestInfoModal}
            data=''
            sendApiFunc={(notes, setOpen) => {
              let payload = {
                productId: productId,
                note: notes,
              };
              sendRequestInfoEmail(payload);
              setOpen(false);
            }}
          />
        )
      ) : null}
    </>
  );
};

CatalogRequestInfo.propTypes = {
  ROLE_ID: PropTypes.string.isRequired,
  sendRequestInfoEmail: PropTypes.string.isRequired,
  productId: PropTypes.string.isRequired,
  confirmRequestInfo: PropTypes.string.isRequired,
  requestInfoTitle: PropTypes.string.isRequired,
  openRequestInfoModal: PropTypes.string.isRequired,
  setOpenRequestInfoModal: PropTypes.func.isRequired,
};
export default memo(CatalogRequestInfo);
