// import Cryptr from 'cryptr';
// import { SALT } from '../constants';

// const cryptr = new Cryptr(SALT);

const LocalStorageManager = {
  setLocalStorage: (name, value) => {
    // const val = cryptr.encrypt(value);
    localStorage.setItem(name, JSON.stringify(value));
  },

  getLocalStorage: (name) => {
    const data = localStorage.getItem(name);
    if (data) {
      // return cryptr.decrypt(data);
      return data;
    }
    return data;
  },

  removeLocalStorage: (name) => {
    return localStorage.removeItem(name);
  },

  clearLocalStorage: () => {
    return localStorage.clear();
  },
};

export default LocalStorageManager;
