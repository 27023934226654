import PropTypes from 'prop-types';
import { Formik, ErrorMessage } from 'formik';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import CircularProgress from '@mui/material/CircularProgress';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';

import TextField from 'components/text-field';
import MyRadio from 'components/radio';
import { useResetPassword } from './reset-password-hooks';
import { resetPasswordFormSchema } from './validation-schema';
import FormError from '../components/form-error';
import { recoverAccountStyles as styles } from './styles';
import { responsiveBreakpoints } from 'utils/utils';

const RecoverAccountForm = ({ handleRecoverMessage }) => {
  const {
    handleResetPasswordBtnClick,
    isResetPasswordLoading,
    isForgetPasswordLoading,
    apiErrorMessage,
    fieldsError,
    handleCloseError,
    linkedAccount,
    formikRef,
  } = useResetPassword(handleRecoverMessage);
  const { theme, mobile, tablet } = responsiveBreakpoints();

  return (
    <Grid container sx={{ width: '100%' }}>
      <Grid
        item
        sx={{
          width: '100%',
          padding: '5px 0px 5px 0px',
        }}
      >
        <Stack
          direction='column'
          justifyContent='center'
          alignItems='center'
          sx={{ width: '100%' }}
        >
          <Typography
            sx={
              mobile
                ? { ...styles.recoverMessage, width: '100%' }
                : styles.recoverMessage
            }
          >
            Reset password or Recover username using the email address
            associated with this account
          </Typography>

          {apiErrorMessage || fieldsError.length > 0 ? (
            <FormError
              errorText={apiErrorMessage}
              errors={fieldsError}
              handleCloseError={handleCloseError}
            />
          ) : null}
        </Stack>
      </Grid>
      <Grid
        item
        sx={{
          width: '100%',
          padding: '5px 0px 5px 0px',
        }}
      >
        <Stack
          direction='column'
          justifyContent='center'
          alignItems='center'
          sx={{ width: '100%' }}
        >
          {' '}
          <Formik
            innerRef={formikRef}
            validateOnMount
            validationSchema={resetPasswordFormSchema(linkedAccount)}
            initialValues={{
              email: '',
              userId: '',
            }}
            onSubmit={handleResetPasswordBtnClick}
          >
            {({ handleSubmit, ...rest }) => (
              <form
                onSubmit={handleSubmit}
                style={
                  mobile
                    ? {
                        width: '100%',
                      }
                    : {
                        width: '480px',
                      }
                }
              >
                {linkedAccount.length > 0 ? (
                  <Stack sx={styles.accountField}>
                    <InputLabel id='userId'>Select Account</InputLabel>
                    <Select
                      notched={false}
                      name='userId'
                      id='userId'
                      label='Select Account'
                      placeholder='Select Account'
                      labelId='userId'
                      value={rest.values.userId}
                      onChange={rest.handleChange}
                      sx={{ width: '100%', height: '38px' }}
                    >
                      {linkedAccount?.map((account) => {
                        return (
                          <MenuItem key={account.id} value={account.id}>
                            {account.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <ErrorMessage
                      name='userId'
                      render={(msg) => (
                        <Stack sx={{ color: '#ff0033' }}>{msg}</Stack>
                      )}
                    />
                  </Stack>
                ) : (
                  <TextField
                    id='email'
                    name='email'
                    label='Email Address'
                    placeholder='Email Address'
                    value={rest.values.email}
                    onChange={rest.handleChange}
                    error={rest.touched.email && Boolean(rest.errors.email)}
                    helperText={rest.touched.email && rest.errors.email}
                    sx={styles.defaultWidth}
                    marginBottom='16px'
                  />
                )}
                <Button
                  variant='contained'
                  type='submit'
                  sx={styles.defaultWidth}
                  disabled={isResetPasswordLoading || isForgetPasswordLoading}
                  endIcon={
                    (isResetPasswordLoading || isForgetPasswordLoading) && (
                      <CircularProgress size={16} />
                    )
                  }
                >
                  Submit
                </Button>
              </form>
            )}
          </Formik>
        </Stack>
      </Grid>
    </Grid>
  );
};

RecoverAccountForm.propTypes = {
  handleRecoverMessage: PropTypes.func.isRequired,
};

export default RecoverAccountForm;
