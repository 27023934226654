import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

const generateButtonStyle = (isSelected) => ({
  color: isSelected ? '#0066c0' : 'inherit',
  textDecoration: isSelected ? 'underline' : 'none',
  fontWeight: '900',
  width: '23px',
  height: '23px',
});

export default function AlphaSort({ alphabetValue, setAlphabetValue }) {
  const alphabets = ['1', ...'abcdefghijklmnopqrstuvwxyz'.split(''), ''];

  const renderButton = (item) => {
    const value = item === '1' ? '#' : item === '' ? 'All' : item.toUpperCase();
    return (
      <Button
        key={value}
        variant='text'
        sx={generateButtonStyle(alphabetValue === item)}
        onClick={(e) => {
          e.preventDefault();
          setAlphabetValue(item === '1' ? '1' : item);
        }}
      >
        {value}
      </Button>
    );
  };

  return (
    <Stack
      direction='row'
      sx={{
        fontWeight: '900',
        width: '100%',
        overflowX: 'initial',
        flexWrap: 'wrap',
      }}
    >
      {alphabets.map(renderButton)}
    </Stack>
  );
}

AlphaSort.propTypes = {
  alphabetValue: PropTypes.string.isRequired,
  setAlphabetValue: PropTypes.func.isRequired,
};
