export const zIndex = {
  welcomeBar: 996,
  stickyH1: 996,
  stickyH2: 995,
  stickyH3: 994,
  popover: 995,
  stickyCategoryBrowser: 993,
  floatingDraftSummary: 998,
  myModal: 999,
};
