import React from 'react';
import PropTypes from 'prop-types';

export default function HeaderCartIcon({ fill, sx }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 16 16'
    >
      <g>
        <g>
          <g>
            <path
              fill={fill}
              d='M15.86 3.637a.625.625 0 0 0-.485-.231H4.054l-.046-.426v-.014A3.42 3.42 0 0 0 .625 0a.625.625 0 0 0 0 1.25 2.166 2.166 0 0 1 2.141 1.873l.743 6.825a1.878 1.878 0 0 0-1.1 1.708v.032a1.877 1.877 0 0 0 1.875 1.875h.254a1.844 1.844 0 1 0 3.491 0h2.7a1.844 1.844 0 1 0 1.746-1.25H4.281a.626.626 0 0 1-.625-.625v-.016-.016a.626.626 0 0 1 .625-.625h7.979a2.862 2.862 0 0 0 2.594-1.749.625.625 0 0 0-1.145-.5 1.634 1.634 0 0 1-1.449 1H4.748L4.19 4.656h10.416L14.3 6.122a.625.625 0 1 0 1.224.255l.463-2.219a.625.625 0 0 0-.127-.521zm-3.391 9.925a.594.594 0 1 1-.594.594.594.594 0 0 1 .594-.594zm-6.187 0a.594.594 0 1 1-.594.594.594.594 0 0 1 .593-.594z'
              transform='translate(-1108 -90) translate(1108 90)'
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

HeaderCartIcon.propTypes = {
  fill: PropTypes.string,
  sx: PropTypes.object,
};

HeaderCartIcon.defaultProps = {
  fill: 'var(--primary-icon-color)',
  sx: {},
};
