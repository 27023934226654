import { useState, useEffect } from 'react';
import { api } from '../../../api';
import { useDispatch } from 'react-redux';
import { createGroup } from 'pages/customers/actions';
import { useFirstRender } from 'common-hooks/first-renderer';

export default function useSelectBuyersTable({
  selectedBuyers,
  setSelectedBuyers,
  worksheetId,
  sendinviteEmail,
  groups,
  setGroups,
  setIsAllBuyerSelected,
  isAllBuyerSelected,
  salesRep,
  setSalesRep,
  isSelectAll,
  setIsSelectAll,
  emailValue,
  setTotalBuyersSelected,
}) {
  const firstRender = useFirstRender();

  const [options, setOptions] = useState([]);
  const [buyersList, setBuyersList] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [alphabetValue, setAlphabetValue] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [groupOptions, setGroupOptions] = useState([]);
  const [showCreateCustomerGroupModal, seShowCreateCustomerGroupModal] =
    useState(false);

  //state to manage loader
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  async function getGroups() {
    let res = await api.get(`/groups`);
    if (res.data.status) setGroupOptions(res.data.data);
  }

  useEffect(() => {
    getGroups();
  }, []);
  useEffect(() => {
    if (isAllBuyerSelected) {
      setTotalBuyersSelected(buyersList?.count);
    } else {
      setTotalBuyersSelected(selectedBuyers.length);
    }
  }, [selectedBuyers]);

  useEffect(() => {
    if (!firstRender) {
      getBuyers({
        id: worksheetId,
        searchTerm: searchValue,
        salesRepId: salesRep?.value ?? '',
        alphaSort: alphabetValue,
        page: '0',
        rowsPerPage: rowsPerPage,
        groups: groups,
        emailValue: emailValue,
        clearSelectedBuyers: false,
      });
    }
  }, [searchValue]);

  //  logic to handle checkbox
  function handleCheck(value) {
    if (selectedBuyers.some((obj) => obj.buyer_id === value.buyer_id)) {
      setSelectedBuyers(() =>
        selectedBuyers.filter((item) => item.buyer_id !== value.buyer_id)
      );
      if (isSelectAll) {
        setIsSelectAll(false);
      }
      if (isAllBuyerSelected) {
        setIsAllBuyerSelected(false);
      }
    } else {
      let temporarySelectedBuyersList = [...selectedBuyers];
      temporarySelectedBuyersList?.push(value);
      let allPresent = buyersList?.data?.every((buyer) =>
        temporarySelectedBuyersList?.some(
          (selectedBuyer) => selectedBuyer?.buyer_id === buyer?.buyer_id
        )
      );
      if (allPresent) {
        setIsSelectAll(true);
      } else {
        setIsSelectAll(false);
      }
      setSelectedBuyers((prev) => {
        return [...prev, value];
      });
    }
  }
  //    logic to handle onPageChange for pagination
  const onPageChangeHandler = (event, page) => {
    event.preventDefault();
    setPage(page);
    getBuyers({
      id: worksheetId,
      searchTerm: searchValue,
      salesRepId: salesRep?.value ?? '',
      alphaSort: '',
      page: page,
      rowsPerPage: rowsPerPage,
      groups: groups,
      emailValue: emailValue,
    });
    // getBuyers(page, rowsPerPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    getBuyers({
      id: worksheetId,
      searchTerm: searchValue,
      salesRepId: salesRep?.value ?? '',
      alphaSort: '',
      page: page,
      rowsPerPage: parseInt(event.target.value, 10),
      groups: groups,
      emailValue: emailValue,
    });
  };
  // logic ends onpagechange

  //get buyers list
  async function getBuyers({
    searchTerm,
    salesRepId,
    alphaSort,
    page,
    rowsPerPage,
    groups,
    emailValue,
    clearSelectedBuyers,
  }) {
    setIsLoading(true);
    let url = '';
    if (groups.length > 0) {
      if (sendinviteEmail) {
        url = `/draftBuyerList?worksheetId=123&search=${searchTerm}&salesRepId=${salesRepId}&alphasort=${alphaSort}&iDisplayStart=${page}&iDisplayLength=${rowsPerPage}&filters={"groupIds":[${groups}]}&sortByEmail=${
          emailValue?.value ?? '99'
        }`;
      } else {
        url = `/draftBuyerList?worksheetId=123&search=${searchTerm}&salesRepId=${salesRepId}&alphasort=${alphaSort}&iDisplayStart=${page}&iDisplayLength=${rowsPerPage}&filters={"groupIds":[${groups}]}`;
      }
    } else {
      if (sendinviteEmail) {
        url = `/draftBuyerList?worksheetId=123&search=${searchTerm}&salesRepId=${salesRepId}&alphasort=${alphaSort}&iDisplayStart=${page}&iDisplayLength=${rowsPerPage}&sortByEmail=${
          emailValue?.value ?? '99'
        }`;
      } else {
        url = `/draftBuyerList?worksheetId=123&search=${searchTerm}&salesRepId=${salesRepId}&alphasort=${alphaSort}&iDisplayStart=${page}&iDisplayLength=${rowsPerPage}`;
      }
    }
    const res = await api.get(url);
    let arr = [];
    res?.data?.data?.forEach((buyer) => {
      // TODO : ned to check hardcoded 1 value
      if (buyer.isFromGroup === '1') arr.push(buyer);
    });
    // setSelectedBuyers(arr);
    if (arr.length > 0) {
      console.log('Testing arr.length');
      if (clearSelectedBuyers) {
        // this if will work if user is selecting Groups
        setSelectedBuyers([]);
        setIsSelectAll(false);
        setIsAllBuyerSelected(false);
      } else {
        console.log('Testing arr.length else clearSelectedBuyers');
        console.log(
          'testing arr.length else clearSelectedBuyers isAllBuyerSelected',
          isAllBuyerSelected
        );
        // this will work if user is going to next page or searching with atleast one group
        if (isAllBuyerSelected) {
          console.log('Testing arr.length if isAllBuyerSelected');
          setSelectedBuyers((prev) => [...prev, ...arr]);
        } else {
          let allPresent = arr?.every((buyer) =>
            selectedBuyers?.some(
              (selectedBuyer) => selectedBuyer?.buyer_id === buyer?.buyer_id
            )
          );
          if (allPresent) {
            setIsSelectAll(true);
          } else {
            setIsSelectAll(false);
          }
        }
      }
    } else {
      console.log('Testing arr. else');
      if (clearSelectedBuyers) {
        console.log('Testing else if clearSelectedbuyer');
        // this if will work if user is selecting Groups
        setSelectedBuyers([]);
        setIsSelectAll(false);
        setIsAllBuyerSelected(false);
      } else {
        console.log('Testing else else clearSelectedBuyers');
        if (isAllBuyerSelected) {
          let buyerArray = [];
          res?.data?.data?.forEach((buyer) => {
            buyerArray.push(buyer);
          });
          if (clearSelectedBuyers) {
            setSelectedBuyers(() => [...buyerArray]);
          } else {
            let uniqueBuyerIds = {};
            let buyersArray = [...selectedBuyers, ...buyerArray];
            let uniqueArray = buyersArray.filter((obj) => {
              if (!uniqueBuyerIds[obj.buyer_id]) {
                uniqueBuyerIds[obj.buyer_id] = true;
                return true;
              }
              return false;
            });
            setSelectedBuyers(uniqueArray);
          }
        } else {
          let allPresent = res?.data?.data?.every((buyer) =>
            selectedBuyers?.some(
              (selectedBuyer) => selectedBuyer?.buyer_id === buyer?.buyer_id
            )
          );
          if (allPresent) {
            setIsSelectAll(true);
          } else {
            setIsSelectAll(false);
          }
        }
      }
    }
    setBuyersList(res?.data);
    setIsLoading(false);
  }

  //get sort options
  async function getSalesReps() {
    const res = await api.get(`/getSalesRep`);

    var arr = [];
    res.data?.forEach((op) => {
      arr.push({ label: op.name, value: op.id });
    });
    setOptions(arr);
  }

  useEffect(
    () => {
      getBuyers({
        id: worksheetId,
        searchTerm: searchValue,
        salesRepId: salesRep?.value ?? '',
        alphaSort: alphabetValue,
        page,
        rowsPerPage,
        groups,
      });
    },
    [
      // searchValue,
      // alphabetValue,
      // page,
      // rowsPerPage,
      // groups,
      // emailValue,
      // salesRep,
    ]
  );

  useEffect(() => {
    getSalesReps();
  }, []);
  const onCreateGroupSaveHandler = async (payload) => {
    const response = await dispatch(createGroup(payload));
    if (response) {
      seShowCreateCustomerGroupModal(false);
      getGroups();
    }
  };
  return {
    options,
    setOptions,
    handleCheck,
    buyersList,
    searchValue,
    setSearchValue,
    alphabetValue,
    setAlphabetValue,
    salesRep,
    setSalesRep,
    setPage,
    rowsPerPage,
    page,
    setRowsPerPage,
    onPageChangeHandler,
    handleChangeRowsPerPage,
    groups,
    setGroups,
    groupOptions,
    isLoading,
    showCreateCustomerGroupModal,
    seShowCreateCustomerGroupModal,
    onCreateGroupSaveHandler,
    getBuyers,
  };
}
