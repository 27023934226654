import React from 'react';
import PropTypes from 'prop-types';

export default function TableSortIcon({ fill, sx }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='4.854'
      height='8.796'
      viewBox='0 0 4.854 8.796'
      fill={fill}
      style={{ transform: 'scale(1.4)' }}
    >
      <g transform='translate(-195.301 -61.481)'>
        <path
          id='Mask'
          d='M1.945,8.57.147,6.412a.629.629,0,0,1,.482-1.03h3.6a.629.629,0,0,1,.482,1.03L2.91,8.57a.628.628,0,0,1-.965,0ZM.629,3.415a.628.628,0,0,1-.482-1.03L1.945.226a.628.628,0,0,1,.965,0l1.8,2.159a.628.628,0,0,1-.482,1.03Z'
          transform='translate(195.301 61.482)'
          fill={fill}
        />
      </g>
    </svg>
  );
}

TableSortIcon.propTypes = {
  fill: PropTypes.string,
  sx: PropTypes.object,
};

TableSortIcon.defaultProps = {
  // fill: '#666',
  // fill='#aaa',
  fill: '#000000',
  sx: {},
};
