import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { CDN_URL } from 'constants';
import LoadingButton from '@mui/lab/LoadingButton';
import CartIcon from '../../../../assets/icons/cart-icon';
import RemoveIcon from '../../../../assets/icons/remove-icon';
import { useCrudOperationCart } from 'common-hooks/crud-operation-cart-hooks';
import { useProductListItem } from './product-list-item-hooks';
import { useAddProductsModal } from 'common-hooks/add-products-modal-hooks';
import HeaderCartIcon from 'assets/icons/header-cart-icon';
import { responsiveBreakpoints } from 'utils/utils';
const ProductDescriptionModal = lazy(() =>
  import('../../../../pages/catalog/components/productDetailModal')
);
const ProductDescriptionModalM = lazy(() =>
  import('../../../../pages/catalog/mobile_components/productDetailModal-m')
);

export default function ProductListItem({
  name,
  color,
  id,
  deliverFrom,
  description,
  image,
  externalCdn,
  style,
  isOtherDeliveriesDisplay,
  collectionId,
  is_draft_product,
  page,
  worksheetCollectionId,
  getOtherDeliveries,
  isAvailableProducts,
  mainProduct,
  product,
  collectionName,
  orderDetailsComponent,
  selectedWorksheet,
}) {
  const {
    cartFlag,
    setCartFlag,
    cartActionLoader,
    setCartActionLoader,
    setSuggestionFlag,
    suggestionFlag,
  } = useProductListItem({ is_draft_product });
  const { theme, mobile, tablet, laptop, desktop, mainWidth } =
    responsiveBreakpoints();
  const { addToCart, removeFromCart } = useCrudOperationCart({
    setCartActionLoader,
    setCartFlag,
  });
  const {
    getProductModalDetails,
    setProductModalFlag,
    productModalFlag,
    singleProductDetails,
  } = useAddProductsModal();

  let imageUrl = externalCdn == 0 ? CDN_URL + image : image;

  return (
    <>
      <Grid
        container
        sx={
          cartFlag
            ? {
                border: '2px solid',
                borderColor: 'primary.main',
                padding: '10px 15px 10px 15px',
                backgroundColor: 'var(--paper-background-color)',
                display: 'flex',
                alignItems: 'center',
                minHeight: '80px',
                cursor: 'pointer',
                borderRadius: '6px',
                marginBottom: '8px',
                '&:hover': { borderColor: 'primary.main' },
              }
            : {
                border: '1px solid var(--secondary-border-color)',
                padding: '10px 15px 10px 15px',
                backgroundColor: 'var(--paper-background-color)',
                display: 'flex',
                alignItems: 'stretch',
                minHeight: '80px',
                cursor: 'pointer',
                borderRadius: '6px',
                marginBottom: '8px',
                '&:hover': { borderColor: '#CCC' },
              }
        }
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          getProductModalDetails(id);
        }}
      >
        <Grid
          item
          xs={12}
          sm={1.7}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={`${imageUrl}`}
            height={isOtherDeliveriesDisplay ? '60px' : '80px'}
            style={{ objectFit: 'contain' }}
          />
        </Grid>
        <Grid item xs={12} sm={1.7}>
          <Stack sx={{ padding: '0px 15px 0px 0px' }}>
            <Typography
              sx={{
                color: '#222222',
                fontSize: '12px',
                fontWeight: '500',
                lineHeight: 2,
                opacity: 0.7,
              }}
            >
              {!isOtherDeliveriesDisplay ? 'Name' : 'Collection Name'}
            </Typography>
            <Typography
              sx={{
                color: 'var(--primary-font-color)',
                fontSize: '12px',
                fontWeight: '900',
                lineHeight: 2,
                letterSpacing: '0.24px',
                whiteSpace: 'wrap',
                wordWrap: 'break-word',
              }}
            >
              {name}
            </Typography>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          sm={1.7}
          sx={!isOtherDeliveriesDisplay ? { display: '' } : { display: 'none' }}
        >
          <Stack sx={{ padding: '0px 15px 0px 0px' }}>
            <Typography
              sx={{
                color: 'var(--secondary-font-color)',
                fontSize: '12px',
                fontWeight: '500',
                lineHeight: 2,
                opacity: 0.7,
              }}
            >
              Collection
            </Typography>
            <Typography
              sx={{
                color: 'var(--primary-font-color)',
                fontSize: '12px',
                fontWeight: '900',
                lineHeight: 2,
                letterSpacing: '0.24px',
                whiteSpace: 'wrap',
                wordWrap: 'break-word',
              }}
            >
              {collectionName}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={1.7}>
          <Stack>
            <Typography
              sx={{
                color: 'var(--secondary-font-color)',
                fontSize: '12px',
                fontWeight: '500',
                lineHeight: 2,
                opacity: 0.7,
              }}
            >
              Deliver From
            </Typography>
            <Typography
              sx={{
                color: 'var(--primary-font-color)',
                fontSize: '12px',
                fontWeight: '900',
                lineHeight: 2,
                letterSpacing: '0.24px',
              }}
            >
              {deliverFrom}
            </Typography>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={1.7}>
          <Stack sx={{ padding: '0px 15px 0px 0px' }}>
            <Typography
              sx={{
                color: '#222222',
                fontSize: '12px',
                fontWeight: '500',
                lineHeight: 2,
                opacity: 0.7,
              }}
            >
              Color
            </Typography>
            <Typography
              sx={{
                color: 'var(--primary-font-color)',
                fontSize: '12px',
                fontWeight: '900',
                lineHeight: 2,
                letterSpacing: '0.24px',
                whiteSpace: 'wrap',
                wordWrap: 'break-word',
              }}
            >
              {color}
            </Typography>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={1.7}>
          <Stack sx={{ padding: '0px 15px 0px 0px' }}>
            <Typography
              sx={{
                color: '#222222',
                fontSize: '12px',
                fontWeight: '500',
                lineHeight: 2,
                opacity: 0.7,
                float: 'right',
              }}
            >
              {!isOtherDeliveriesDisplay ? 'Description' : 'Message'}
            </Typography>
            <Typography
              sx={{
                color: 'var(--primary-font-color)',
                fontSize: '12px',
                fontWeight: '900',
                lineHeight: 2,
                letterSpacing: '0.24px',
                whiteSpace: 'wrap',
                wordWrap: 'break-word',
              }}
            >
              {description.length > 0 ? description : '-'}
            </Typography>
          </Stack>
        </Grid>

        {!isOtherDeliveriesDisplay ? (
          <Grid item xs={12} sm={1.7}>
            <Stack sx={{ padding: '0px 15px 0px 0px' }}>
              <Typography
                sx={{
                  color: '#222222',
                  fontSize: '12px',
                  fontWeight: '500',
                  lineHeight: 2,
                  opacity: 0.7,
                }}
              >
                Style#
              </Typography>
              <Typography
                sx={{
                  color: 'var(--primary-font-color)',
                  fontSize: '12px',
                  fontWeight: '900',
                  lineHeight: 2,
                  letterSpacing: '0.24px',
                  whiteSpace: 'wrap',
                  wordWrap: 'break-word',
                }}
              >
                {style}
              </Typography>
            </Stack>
          </Grid>
        ) : (
          <Grid item xs={1} sm={1.7}></Grid>
        )}
        {isOtherDeliveriesDisplay && (
          <Grid
            item
            xs={12}
            sm={1.7}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Stack>
              <LoadingButton
                onClick={
                  !cartFlag
                    ? (e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        if (!page) {
                          addToCart({
                            collection_id: collectionId,
                            id: id,
                          });
                        } else {
                          addToCart({
                            collectionId: collectionId,
                            id: id,
                          });
                        }
                        setCartFlag(true);
                      }
                    : (e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        removeFromCart({ item_id: id }, 'delete', 'DRAFT');
                        setCartFlag(false);
                      }
                }
                fullWidth
                size='small'
                variant='outlined'
                startIcon={
                  !cartFlag ? (
                    // <CartIcon fill='#000' />
                    <HeaderCartIcon fill='#000' />
                  ) : (
                    <RemoveIcon fill='#fff' />
                  )
                }
                disabled={cartActionLoader}
                loading={cartActionLoader}
                sx={
                  !cartFlag
                    ? {
                        height: '29px',
                        width: '120px',
                        borderColor: '#000',
                        color: '#000',
                        '&:hover': { borderColor: '#000' },
                      }
                    : {
                        height: '29px',
                        width: '120px',
                        borderColor: '#000',
                        backgroundColor: '#000',
                        color: '#fff',
                        '&:hover': {
                          borderColor: '#000',
                          backgroundColor: '#000',
                        },
                      }
                }
              >
                {!cartFlag ? 'Add' : 'Remove'}
              </LoadingButton>
            </Stack>
          </Grid>
        )}
      </Grid>
      {productModalFlag &&
      Object.keys(singleProductDetails).length > 0 &&
      !mobile &&
      !tablet ? (
        <Suspense fallback={<div>Loading...</div>}>
          <ProductDescriptionModal
            productModalFlag={productModalFlag}
            setProductModalFlag={setProductModalFlag}
            productDetails={singleProductDetails}
            cartFlag={cartFlag}
            setCartFlag={setCartFlag}
            suggestionFlag={suggestionFlag}
            setSuggestionFlag={setSuggestionFlag}
            orderDetailsComponent={orderDetailsComponent}
            selectedWorksheet={selectedWorksheet}
          />
        </Suspense>
      ) : null}
      {productModalFlag &&
      Object.keys(singleProductDetails).length > 0 &&
      (mobile || tablet) ? (
        <Suspense fallback={<div>Loading...</div>}>
          <ProductDescriptionModalM
            productModalFlag={productModalFlag}
            setProductModalFlag={setProductModalFlag}
            productDetails={singleProductDetails}
            cartFlag={cartFlag}
            setCartFlag={setCartFlag}
            suggestionFlag={suggestionFlag}
            setSuggestionFlag={setSuggestionFlag}
            orderDetailsComponent={orderDetailsComponent}
            selectedWorksheet={selectedWorksheet}
          />
        </Suspense>
      ) : null}
    </>
  );
}

ProductListItem.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
  deliverFrom: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  externalCdn: PropTypes.string,
  style: PropTypes.string,
  id: PropTypes.string,
  isOtherDeliveriesDisplay: PropTypes.bool,
  collectionId: PropTypes.bool,
  is_draft_product: PropTypes.bool,
  page: PropTypes.bool,
  worksheetCollectionId: PropTypes.object,
  getOtherDeliveries: PropTypes.func,
  isAvailableProducts: PropTypes.string,
  mainProduct: PropTypes.string,
  product: PropTypes.object,
  collectionName: PropTypes.string,
  orderDetailsComponent: PropTypes.bool,
  selectedWorksheet: PropTypes.object,
};

ProductListItem.defaultProps = {
  name: '',
  color: '',
  deliverFrom: '',
  description: '',
  image: '',
  style: '',
  id: '',
  isOtherDeliveriesDisplay: false,
  orderDetailsComponent: false,
  selectedWorksheet: {},
};
