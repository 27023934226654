import React from 'react';
import PropTypes from 'prop-types';
import { style } from 'pages/orders/styles';

export default function DownloadIcon({ fill, sx }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill={fill}
      style={sx}
    >
      <g transform='translate(-292 -498)'>
        <circle cx='14' cy='14' r='14' transform='translate(292 498)' />
        <g transform='translate(284 506)'>
          <g transform='translate(18.625)'>
            <g transform='translate(0)'>
              <path
                d='M133.964,5.47a.374.374,0,0,0-.341-.22h-1.5V.375A.375.375,0,0,0,131.748,0h-1.5a.375.375,0,0,0-.375.375V5.25h-1.5a.375.375,0,0,0-.282.622l2.625,3a.374.374,0,0,0,.564,0l2.625-3A.374.374,0,0,0,133.964,5.47Z'
                transform='translate(-127.998)'
                fill='#fff'
              />
            </g>
          </g>
          <g transform='translate(16 8.25)'>
            <g transform='translate(0)'>
              <path
                d='M25.75,352v2.25H17.5V352H16v3a.75.75,0,0,0,.75.75H26.5a.749.749,0,0,0,.75-.75v-3Z'
                transform='translate(-16 -352)'
                fill='#fff'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

DownloadIcon.propTypes = {
  fill: PropTypes.string,
  sx: PropTypes.obj,
};

DownloadIcon.defaultProps = {
  fill: 'var(--primary-icon-color)',
  sx: {},
};
