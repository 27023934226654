import { debounce } from 'lodash';

const dispatchSessionStorageUpdateEvent = () => {
  const event = new CustomEvent('sessionStorageUpdate');
  window.dispatchEvent(event);
};

const SessionStorageManager = {
  setSessionStorage: (name, value) => {
    sessionStorage.setItem(name, value);
    dispatchSessionStorageUpdateEvent();
  },

  getSessionStorage: (name) => {
    return sessionStorage.getItem(name);
  },

  removeSessionStorage: (name) => {
    const result = sessionStorage.removeItem(name);
    dispatchSessionStorageUpdateEvent();
    return result;
  },

  clearSessionStorage: () => {
    const result = sessionStorage.clear();
    dispatchSessionStorageUpdateEvent();
    return result;
  },
};

export default SessionStorageManager;
