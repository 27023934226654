export const infoModalStyles = {
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    backgroundColor: 'var(--paper-background-color)',
    border: 0,
    outline: 'none',
    borderRadius: '6px',
    padding: '18px',
    minWidth: '400px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  title: {
    fontWeight: 500,
    color: 'var(--primary-font-color)',
  },
  message: {
    fontWeight: 400,
    color: 'rgba(51, 51, 51, 0.75)',
  },
};
