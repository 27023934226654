import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import SuccessIcon from 'assets/icons/success-icon';
import AuthPage from '../components/auth-page';
import { requestUnderReviewStyles as styles } from './styles';
import { responsiveBreakpoints } from 'utils/utils';

const RequestUnderReview = ({ logo, responseMessage }) => {
  const { theme, mobile, tablet } = responsiveBreakpoints();
  return (
    <AuthPage height='100vh' showBackToLogin showRegisterAccount={false}>
      <Stack sx={styles.contianer} spacing={2}>
        <Stack spacing={2} justifyContent='center' alignItems='center'>
          <SuccessIcon sx={styles.successIcon} />
          <Typography
            sx={mobile ? { ...styles.message, width: '100%' } : styles.message}
          >
            {responseMessage}
          </Typography>
        </Stack>
      </Stack>
    </AuthPage>
  );
};

RequestUnderReview.propTypes = {
  logo: PropTypes.string.isRequired,
  responseMessage: PropTypes.string.isRequired,
};

export default RequestUnderReview;
