import { React } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from '@mui/material/Typography';
import { formErrorStyles as styles } from './styles';
import WarningIcon from 'assets/icons/warning-icon';
import CancelIcon from 'assets/icons/cancel-icon';
import { IconButton } from '@mui/material';
import { responsiveBreakpoints } from 'utils/utils';

const FormError = ({ errorText, errors, handleCloseError }) => {
  const { theme, mobile, tablet } = responsiveBreakpoints();
  return (
    <Box
      sx={mobile ? { ...styles.container, width: '100%' } : styles.container}
    >
      <IconButton onClick={handleCloseError} sx={styles.closeIcon}>
        <CancelIcon height='12px' width='12px' fill='var(--error-color)' />
      </IconButton>

      <List sx={{ width: '100%', maxWidth: 360 }}>
        {errorText && (
          <ListItem sx={{ padding: '0px 10px' }}>
            <ListItemAvatar
              sx={{
                minWidth: 'fit-content',
                paddingRight: '10px',
              }}
            >
              <WarningIcon
                height='18px'
                width='19px'
                fill='var(--error-color)'
              />
            </ListItemAvatar>
            <ListItemText>
              <Typography variant='body1' sx={styles.errorText}>
                {errorText}
              </Typography>
            </ListItemText>
          </ListItem>
        )}
        {errors &&
          errors.map((error) => {
            return (
              <ListItem key={error} sx={{ padding: '0px 10px' }}>
                <ListItemAvatar
                  sx={{
                    minWidth: 'fit-content',
                    paddingRight: '10px',
                  }}
                >
                  <WarningIcon
                    height='18px'
                    width='19px'
                    fill='var(--error-color)'
                  />
                </ListItemAvatar>
                <ListItemText>
                  <Typography variant='body1' sx={styles.errors} key={error}>
                    {error}
                  </Typography>
                </ListItemText>
              </ListItem>
            );
          })}
      </List>
    </Box>
  );
};

FormError.propTypes = {
  errorText: PropTypes.string,
  errors: PropTypes.array,
  handleCloseError: PropTypes.func,
};

export default FormError;
