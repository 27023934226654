import React from 'react';
import PropTypes from 'prop-types';

export default function TwitterIcon({ fill, style }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill={fill}
      style={style}
    >
      <path d='M11,0A11,11,0,1,0,22,11,11,11,0,0,0,11,0Zm5.022,8.577q.007.162.007.326a7.135,7.135,0,0,1-7.184,7.184h0a7.147,7.147,0,0,1-3.87-1.134,5.135,5.135,0,0,0,.6.035,5.066,5.066,0,0,0,3.136-1.081,2.528,2.528,0,0,1-2.359-1.754A2.516,2.516,0,0,0,7.5,12.11,2.525,2.525,0,0,1,5.47,9.635c0-.011,0-.022,0-.032a2.507,2.507,0,0,0,1.144.316,2.527,2.527,0,0,1-.782-3.371,7.168,7.168,0,0,0,5.2,2.638,2.526,2.526,0,0,1,4.3-2.3,5.064,5.064,0,0,0,1.6-.613,2.535,2.535,0,0,1-1.11,1.4,5.035,5.035,0,0,0,1.45-.4A5.131,5.131,0,0,1,16.022,8.577Zm0,0' />
    </svg>
  );
}

TwitterIcon.propTypes = {
  fill: PropTypes.string,
  style: PropTypes.object,
};

TwitterIcon.defaultProps = {
  fill: 'var(--primary-icon-color)',
  style: {},
};
