/* eslint-disable react/no-unknown-property */
import React from 'react';
import PropTypes from 'prop-types';

export default function HomeIconM({ fill, active, width, height, stroke }) {
  return (
    <svg
      id='vuesax_linear_home-2'
      data-name='vuesax/linear/home-2'
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='25'
      viewBox='0 0 25 25'
    >
      <g id='home-2'>
        <path
          id='Vector'
          d='M7.18.841,1.667,5.136A4.866,4.866,0,0,0,0,8.531v7.577a4.321,4.321,0,0,0,4.306,4.315H16.149a4.319,4.319,0,0,0,4.306-4.305V8.674a4.83,4.83,0,0,0-1.841-3.528L12.293.718A4.59,4.59,0,0,0,7.18.841Z'
          transform='translate(2.273 2.293)'
          fill='none'
          stroke={stroke}
          stroke-linecap='round'
          stroke-linejoin='round'
          stroke-width='1.2'
        />
        <path
          id='Vector-2'
          data-name='Vector'
          d='M0,3.409V0'
          transform='translate(12.5 15.412)'
          fill='none'
          stroke={stroke}
          stroke-linecap='round'
          stroke-linejoin='round'
          stroke-width='1.2'
        />
        <path
          id='Vector-3'
          data-name='Vector'
          d='M0,0H25V25H0Z'
          fill='none'
          opacity='0'
        />
      </g>
    </svg>
  );
}

HomeIconM.propTypes = {
  fill: PropTypes.string,
  active: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  stroke: PropTypes.string,
};

HomeIconM.defaultProps = {
  fill: 'var(--primary-icon-color)',
  active: 'false',
  width: '34px',
  height: '34px',
  stroke: '#292d32',
};
