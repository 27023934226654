export const fieldsName = {
  locationName: 'locationName',
  shippingID: 'shippingID',
  attention: 'attention',
  addressLineOne: 'addressLineOne',
  addressLineTwo: 'addressLineTwo',
  city: 'city',
  state: 'state',
  zipCode: 'zipCode',
  country: 'country',
};
