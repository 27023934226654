import {
  api,
  addActionLoader,
  removeActionLoader,
  apiEndPoints,
} from '../../api';
import { logOut, setBearerToken } from 'utils/auth';
import { showSnackbar } from 'components/snackbar/actions';
import { snackbarTypes } from 'constants';
import SessionStorageManager from 'utils/session-storage-manager';

const namespace = 'pages/app/';

export const SET_API_ERROR = `${namespace}SET_API_ERROR`;
export const CUSTOMER_DETAIL_REQUEST = `${namespace}CUSTOMER_DETAIL_REQUEST`;
export const SET_CLIENT_OPTIONS_REQUEST = `${namespace}SET_CLIENT_OPTIONS_REQUEST`;
export const WELCOME_BAR_REQUEST = `${namespace}WELCOME_BAR_REQUEST`;
export const SET_WELCOMEBAR_DATA = `${namespace}SET_WELCOMEBAR_DATA`;
export const SET_CUSTOMER_DETAILS = `${namespace}SET_CUSTOMER_DETAILS`;
export const SET_CUSTOMER_SUBDOMAIN = `${namespace}SET_CUSTOMER_SUBDOMAIN`;
export const SET_CLIENT_OPTIONS = `${namespace}SET_CLIENT_OPTIONS`;
export const CHECK_RESET_TOEKN_REQUEST = `${namespace}CHECK_RESET_TOEKN_REQUEST`;
export const VERIFY_TOKEN_REQUEST = `${namespace}VERIFY_TOKEN_REQUEST`;
export const SET_QUERY_CLIENT = `${namespace}SET_QUERY_CLIENT`;
export const SET_CLIENT_PUBLIC_INFO = `${namespace}SET_CLIENT_PUBLIC_INFO`;

const setApiError = (error) => ({
  type: SET_API_ERROR,
  payload: error,
});

const setCustomerDetails = (payload) => ({
  type: SET_CUSTOMER_DETAILS,
  payload,
});
const setClientPublicInfo = (payload) => ({
  type: SET_CLIENT_PUBLIC_INFO,
  payload,
});

export const setCustomerDomain = (payload) => ({
  type: SET_CUSTOMER_SUBDOMAIN,
  payload,
});

export const setClientOptions = (payload) => ({
  type: SET_CLIENT_OPTIONS,
  payload,
});

export const setQueryClient = (payload) => ({
  type: SET_QUERY_CLIENT,
  payload,
});

export const setWelcomeBarInfo = (payload) => ({
  type: SET_WELCOMEBAR_DATA,
  payload,
});

export const getCustomerDetails = () => async (dispatch) => {
  try {
    dispatch(addActionLoader(CUSTOMER_DETAIL_REQUEST));
    const response = await api.get(apiEndPoints.clientInfo);
    const { data = {}, status } = response;
    if (status === 200 && data) {
      //set theme color css custom property
      document.documentElement.style.setProperty(
        '--theme-color',
        data?.primary_color === '' ? '#f02711' : data?.primary_color
      );

      //logout if status is faluse
      if (data?.status === false) {
        logOut();
      }
      dispatch(setCustomerDetails(data));
      return true;
    }
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(CUSTOMER_DETAIL_REQUEST));
  }
};
export const getClientPublicInfo = () => async (dispatch) => {
  try {
    dispatch(addActionLoader(CUSTOMER_DETAIL_REQUEST));
    const response = await api.get(apiEndPoints.clientPublicInfo);
    const { data = {}, status } = response;
    if (status === 200 && data) {
      //set theme color css custom property
      document.documentElement.style.setProperty(
        '--theme-color',
        data?.primary_color === '' ? '#f02711' : data?.primary_color
      );

      //logout if status is faluse
      if (data?.status === false) {
        logOut();
      }
      dispatch(setClientPublicInfo(data));
      return true;
    }
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(CUSTOMER_DETAIL_REQUEST));
  }
};

export const checkTokenRequest = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(CHECK_RESET_TOEKN_REQUEST));
    const response = await api.post(apiEndPoints.checkToken, payload);
    const {
      data: {
        token = '',
        status = false,
        message = '',
        isChangePassword = false,
      } = {},
    } = response;
    SessionStorageManager.setSessionStorage(
      'linkExpired',
      response.data.message
    );
    if (status && token != '') {
      setBearerToken(token);
      return {
        status: true,
        isChangePassword: isChangePassword ? true : false,
      };
    }
    dispatch(
      showSnackbar({ snackbarMessage: message, type: snackbarTypes.ERROR })
    );
    return status;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(CHECK_RESET_TOEKN_REQUEST));
  }
};

export const verifyTokenRequest = (token) => async (dispatch) => {
  try {
    dispatch(addActionLoader(VERIFY_TOKEN_REQUEST));
    const response = await api.get(apiEndPoints.verifyToken, {
      headers: { Authorization: `Bearer ${token}` },
    });
    const { data: { status = false } = {} } = response;
    if (status && token != '') {
      setBearerToken(token);
      return {
        token,
        status,
      };
    }
    return status;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(VERIFY_TOKEN_REQUEST));
  }
};

export const fetchClientOptions = () => async (dispatch) => {
  try {
    dispatch(addActionLoader(SET_CLIENT_OPTIONS_REQUEST));
    const response = await api.get(apiEndPoints.clientOptions);
    const { data = {}, status } = response;
    if (status === 200 && data) {
      dispatch(setClientOptions(data));
      return true;
    }
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(SET_CLIENT_OPTIONS_REQUEST));
  }
};
// getWelcomeBar info
export const getWelcomeBarConfig = () => async (dispatch) => {
  try {
    dispatch(addActionLoader(WELCOME_BAR_REQUEST));
    const response = await api.get(apiEndPoints.welcomeBar);
    const { data, data: { status = false } = {} } = response;
    if (status) {
      dispatch(setWelcomeBarInfo(data));
    }
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(WELCOME_BAR_REQUEST));
  }
};
