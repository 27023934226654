import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import { useTiltScreen } from './tilt-screen-hooks';

const TiltScreenMessage = () => {
  const { showMessage, setShowMessage } = useTiltScreen();

  if (!showMessage) {
    return null;
  }

  return (
    <Collapse in={TiltScreenMessage}>
      <Alert
        severity='info'
        action={
          <IconButton
            aria-label='close'
            color='inherit'
            size='small'
            onClick={() => {
              setShowMessage(false);
            }}
          >
            <CloseIcon fontSize='inherit' />
          </IconButton>
        }
        sx={{ alignItems: 'center' }}
      >
        Please tilt your screen for a better view.
      </Alert>
    </Collapse>
  );
};

export default TiltScreenMessage;
