import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { loggedInUserSelector } from 'redux/selectors';
import { api } from '../../../api';

export const useSendInviteEmail = () => {
  const [retailers, setRetailers] = useState([]);
  const [selectedRetailers, setSelectedRetailers] = useState([]);
  const [sortBy, setSortBy] = useState('');
  const [sender, setSender] = useState('sr');
  const [senderOptions, setSenderOptions] = useState([]);
  const [signature, setSignature] = useState('');
  const [signatureOptions, setSignatureOptions] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [selectAll, setSelectAll] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [selectedBuyers, setSelectedBuyers] = useState([]);

  //sending emails modal
  const [sendingEmails, setSendingEmails] = useState(false);
  const [emailResponses, setEmailResponses] = useState([]);
  const [emailsSent, setEmailsSent] = useState(0);
  const [index, setIndex] = useState(0);

  //state to manage loader
  const [isLoading, setIsLoading] = useState(false);

  //state to manage buyer limit error
  const [buyerLimitError, setBuyerLimitError] = useState(false);

  //user
  const user = useSelector(loggedInUserSelector);

  //set default signature on loading data
  useEffect(() => {
    setSignature(user?.id);
  }, [signatureOptions]);

  async function getRetailers({ searchTerm, page, sortBy }) {
    setIsLoading(true);
    const res = await api.get(
      `/userEmailListing?page=${page}&search=${searchTerm}&sortBy=${sortBy}&limit=10`
    );

    if (res.data.status) {
      setRetailers(res.data);
      setSenderOptions(res.data.sender);
      setSignatureOptions(res.data.signature);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getRetailers({
      searchTerm: searchTerm,
      page: page,
      sortBy: sortBy,
    });
  }, [page, sortBy]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getRetailers({
        searchTerm: searchTerm,
        page: page,
        sortBy: sortBy,
      });
    }, 1000);

    return () => clearTimeout(timer);
  }, [searchTerm]);

  async function toggleSelectedRetailers(retailerToSelect) {
    var arr = [];
    var alreadyExists = false;
    alreadyExists = selectedRetailers.some(
      (retailer) => retailer === retailerToSelect
    );

    //retailer is already selected
    if (alreadyExists) {
      selectedRetailers.forEach((retailer) => {
        if (retailer !== retailerToSelect) {
          arr.push(retailer);
        }
      });

      //remove buyers
      const res = await api.get(`/retailer/${retailerToSelect}`);
      if (res.data.status) {
        let arr = [];
        selectedBuyers.forEach((selectedBuyer) => {
          if (
            res.data.data.buyerInfo.some(
              (buyerToSelect) =>
                buyerToSelect?.user_id != selectedBuyer?.user_id
            )
          ) {
            arr.push(selectedBuyer);
          }
        });
        setSelectedBuyers(arr);
      }
    }
    //retailer is not selected so add to selected retailers list
    else {
      selectedRetailers.forEach((retailer) => {
        arr.push(retailer);
      });
      arr.push(retailerToSelect);

      //select buyers
      const res = await api.get(`/retailer/${retailerToSelect}`);
      if (res.data.status) {
        let arr = [];
        res.data.data.buyerInfo.forEach((buyerToSelect) => {
          //flag to check if buyer is already select
          let alreadySelected = selectedBuyers.some(
            (buyer) => buyerToSelect.user_id === buyer.user_id
          );

          //if not already selected
          if (!alreadySelected) {
            arr.push(buyerToSelect);
          }
        });
        if (arr.length > 0) setSelectedBuyers([...selectedBuyers, ...arr]);
      }
    }

    setSelectedRetailers([...arr]);
  }

  async function sendInviteEmail() {
    if (selectedBuyers.length < 201) {
      if (index < selectedBuyers.length) {
        const res = await api.post(`/sendInviteEmail`, {
          buyer_id: selectedBuyers[index].user_id,
          sales_rep_id: signature,
          from_sender: sender,
        });

        setEmailResponses([
          ...emailResponses,
          {
            recipientId: selectedBuyers[index].user_id,
            recipientName: selectedBuyers[index].name,
            recipientEmail: selectedBuyers[index]?.email,
            status: res.data.status ? 'sent' : 'failed',
            type: 'buyer',
          },
        ]);

        if (res.data.status) {
          setEmailsSent(emailsSent + 1);
        }

        setIndex(index + 1);
      }
    } else {
      setBuyerLimitError(true);
      setSendingEmails(false);
    }
  }

  useEffect(() => {
    sendInviteEmail();
  }, [index]);

  // async function selectAllBuyers() {
  //   retailers?.data?.forEach((retailer) => {
  //     toggleSelectedRetailers(retailer.id);
  //   });
  // }

  return {
    selectedData,
    sortBy,
    selectAll,
    setSelectAll,
    retailers,
    toggleSelectedRetailers,
    selectedRetailers,
    selectedBuyers,
    setSelectedBuyers,
    sendInviteEmail,
    sendingEmails,
    setSendingEmails,
    emailResponses,
    emailsSent,
    searchTerm,
    setSearchTerm,
    page,
    setPage,
    sender,
    setSender,
    senderOptions,
    signature,
    setSignature,
    signatureOptions,
    setSortBy,
    // selectAllBuyers,
    isLoading,
    buyerLimitError,
    setBuyerLimitError,
  };
};

export const useList = () => {
  const [open, setOpen] = useState(false);

  const handleListItemClick = () => {
    setOpen(!open);
  };

  return {
    open,
    handleListItemClick,
  };
};

export const useTable = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSorting = () => {
    // TODO: Implemented while integrating API
  };

  return {
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  };
};
