import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import Typography from '@mui/material/Typography';

export default function TextField({
  required,
  id,
  label,
  value,
  onChange,
  placeholder,
  sx,
  startAdornment,
  error,
  helperText,
  marginBottom,
  type,
  disabled,
  min,
  max,
  autoFocus,
  step,
  onFocus,
  readOnly,
  flex1,
  inputSize,
  labelStyle,
  validateRegex,
  onBlur,
  ...rest
}) {
  const disabledInputStyle = {
    '& .MuiInputBase-input.MuiInput-input.Mui-disabled': {
      '-webkit-text-fill-color': 'black',
      color: 'black',
    },
    '& .MuiInputBase-input.MuiInput-input.Mui-disabled::placeholder': {
      '-webkit-text-fill-color': 'black !important',
      color: 'black !important',
    },
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    // Check if there's a validateRegex  and test it, default to true if no validation is provided
    const isValid = validateRegex
      ? new RegExp(validateRegex).test(newValue)
      : true;
    if (isValid) {
      onChange(event);
    }
  };

  return (
    <Box
      marginBottom={marginBottom}
      id={'box_' + id}
      flex={flex1}
      sx={[inputSize ? { width: inputSize } : null, { ...disabledInputStyle }]}
    >
      {label ? (
        <InputLabel required={required} sx={labelStyle}>
          {label}
        </InputLabel>
      ) : null}
      <Input
        onFocus={onFocus}
        onBlur={onBlur}
        autoFocus={autoFocus}
        disabled={disabled}
        id={id}
        fullWidth
        type={type}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        readOnly={readOnly}
        sx={[
          sx,
          error ? { borderColor: 'var(--error-color)' } : null,
          { paddingLeft: '10px' },
        ]}
        startAdornment={startAdornment}
        inputProps={(() => {
          if (type === 'date')
            return {
              min: min,
              max: max,
            };
          if (type === 'number') return { step: step, min: min, max: max };
          return {};
        })()}
        {...rest}
      />
      {error ? (
        <Typography
          variant='body2'
          sx={{
            color: 'var(--error-color)',
          }}
        >
          {helperText}
        </Typography>
      ) : null}
    </Box>
  );
}

TextField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  startAdornment: PropTypes.node,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  marginBottom: PropTypes.string,
  flex1: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  min: PropTypes.string,
  max: PropTypes.string,
  autoFocus: PropTypes.bool,
  step: PropTypes.string,
  onFocus: PropTypes.func,
  readOnly: PropTypes.bool,
  inputSize: PropTypes.string,
  labelStyle: PropTypes.object,
  validateRegex: PropTypes.string,
  onBlur: PropTypes.func,
};

TextField.defaultProps = {
  id: null,
  placeholder: null,
  sx: {},
  startAdornment: null,
  label: null,
  error: false,
  helperText: null,
  marginBottom: '',
  required: false,
  flex1: '',
  type: 'text',
  value: '',
  disabled: false,
  onChange: () => {},
  min: null,
  max: null,
  autoFocus: false,
  readOnly: false,
  step: '',
  onFocus: () => {},
  inputSize: '',
  labelStyle: {},
  validateRegex: null,
  onBlur: () => {},
};
