import React from 'react';
import PropTypes from 'prop-types';

export default function LightBulbIconHeader({
  fill,
  width,
  height,
  sx,
  style,
}) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 20.983 20.983'
      sx={sx}
      style={style}
      fill={fill}
    >
      <g
        id='Group_7829'
        data-name='Group 7829'
        transform='translate(-1096.718 -36.579)'
      >
        <path
          id='Vector'
          d='M3.663,14.027V13.013A7.5,7.5,0,0,1,0,6.911,6.924,6.924,0,0,1,8.472.17,6.806,6.806,0,0,1,13.08,3.728,7.207,7.207,0,0,1,10.159,13v1.014c0,.254.1.839-.839.839H4.5C3.541,14.867,3.663,14.491,3.663,14.027Z'
          transform='translate(1100.311 38.324)'
          fill='none'
          stroke='#f0f0f0'
          stroke-linecap='round'
          stroke-linejoin='round'
          stroke-width='1.5'
        />
        <path
          id='Vector-2'
          data-name='Vector'
          d='M0,.426a11.2,11.2,0,0,1,6.12,0'
          transform='translate(1104.15 55.388)'
          fill='none'
          stroke='#f0f0f0'
          stroke-linecap='round'
          stroke-linejoin='round'
          stroke-width='1.5'
        />
        <path
          id='Vector-3'
          data-name='Vector'
          d='M0,0H20.983V20.983H0Z'
          transform='translate(1096.718 36.579)'
          fill='none'
          opacity='0'
        />
        <path
          id='Vector-4'
          data-name='Vector'
          d='M1.025,0,.089,1.626c-.21.358-.035.656.376.656h1.11c.42,0,.586.3.376.656L1.025,4.564'
          transform='translate(1106.188 42.506)'
          fill='none'
          stroke='#292d32'
          stroke-linecap='round'
          stroke-linejoin='round'
          stroke-width='2'
          opacity='0'
        />
        <path
          id='Vector-5'
          data-name='Vector'
          d='M.878,0l-.8,1.394c-.18.307-.03.562.322.562h.952c.36,0,.5.255.322.562L.878,3.913'
          transform='translate(1106.335 43.797)'
          fill='none'
          stroke='#f0f0f0'
          stroke-linecap='round'
          stroke-linejoin='round'
          stroke-width='1.5'
        />
      </g>
    </svg>
  );
}

LightBulbIconHeader.propTypes = {
  fill: PropTypes.string,
  sx: PropTypes.object,
  style: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.string,
};

LightBulbIconHeader.defaultProps = {
  fill: 'var(--primary-icon-color)',
  sx: {},
  style: {},
  width: '20.983',
  height: '20.983',
};
