export const newAccountStyles = {
  width100: {
    width: '100%',
  },
  logo: { height: '110px', marginBottom: '51px', objectFit: 'contain' },
  logoSkeleton: { marginBottom: '51px', width: '300px', height: '110px' },
  thankYouInterestMsg: {
    fontSize: '18px',
    color: 'var(--primary-font-color)',
    fontWeight: '500',
    marginBottom: '25px',
    width: '480px',
  },
  stepsContainer: {
    marginBottom: '10px',
    borderBottom: 'solid 1.4px var(--secondary-border-color)',
    paddingBottom: '4px',
  },

  previousStepContainer: { display: 'flex', alignItems: 'center' },
  previousStepText: {
    cursor: 'pointer',
    marginRight: '5px',
    color: 'var(--link-color)',
    fontSize: '18px',
    opacity: 0.8,
    fontWeight: '500',
  },
  stepsText: {
    fontSize: '18px',
    color: 'var(--primary-font-color)',
    opacity: 0.8,
    fontWeight: '500',
  },
  readTncText: {
    fontSize: '13px',
    color: 'var(--primary-font-color)',
    opacity: '0.8',
    textAlign: 'center',
    width: '100%',
    marginBottom: '15px',
  },
  defaultWidth: {
    width: '100%',
  },
  tncLink: {
    cursor: 'pointer',
    color: 'var(--link-color)',
  },
};

export const stepsStyle = {
  sectionTitle: {
    fontSize: '16px',
    fontWeight: 700,
    color: 'var(--primary-font-color)',
    marginBottom: '6px',
  },
  defaultWidth: { width: '100%' },
  shippingContainer: { marginBottom: '6px' },
  sameAddCheckbox: { width: '18px', height: '18px' },
};

export const requestUnderReviewStyles = {
  contianer: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '200px',
  },
  logo: { height: '110px', marginBottom: '51px' },
  successIcon: { width: '72px', height: '72px' },
  message: {
    width: '480px',
    fontSize: '18px',
    color: 'var(--primary-font-color)',
    textAlign: 'center',
  },
};
