import React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';

import { Formik } from 'formik';
import TextField from '../../../components/text-field';
import FormError from '../components/form-error';
import { fieldsName } from './constant';
import { loginFormSchema } from './validation-schema';
import { useLogin } from './login-hooks';
import { loginStyles as styles } from './styles';
import Tnc from '../../../components/tnc';
import { responsiveBreakpoints } from 'utils/utils';

const LoginForm = () => {
  const {
    handleLoginBtnClick,
    handleForgotUsernameAndPswdClick,
    handleStayLoggedInChange,
    handleShowPasswordClick,
    handleCloseError,
    isStayLoggedIn,
    isLoginLoading,
    apiErrorMessage,
    fieldsError,
    showPassword,
    isShowTnc,
    setIsShowTnc,
    tnc,
    handleTermsAndConditionClick,
    isTncLoading,
    acceptTnC,
    handleAcceptTnC,
    clientPublicInfo,
  } = useLogin();
  const { mobile } = responsiveBreakpoints();
  const { username, password } = fieldsName;
  return (
    <Grid container sx={{ width: '100%' }}>
      <Grid item sx={{ width: '100%', padding: '5px 0px 5px 0px' }}>
        <Stack
          direction='column'
          justifyContent='center'
          alignItems='center'
          sx={{ width: '100%' }}
        >
          {apiErrorMessage || fieldsError.length > 0 ? (
            <FormError
              errorText={apiErrorMessage}
              errors={fieldsError}
              handleCloseError={handleCloseError}
            />
          ) : null}
        </Stack>
      </Grid>
      <Grid item sx={{ width: '100%', padding: '5px 0px 5px 0px' }}>
        <Stack
          direction='column'
          justifyContent='center'
          alignItems='center'
          sx={{ width: '100%' }}
        >
          <Formik
            validateOnMount
            validationSchema={loginFormSchema}
            initialValues={{
              username: '',
              password: '',
              termsAndConditions:
                clientPublicInfo?.termsAndConditionsRequiredOn?.includes(
                  'mandatory_login'
                )
                  ? false
                  : true,
            }}
            onSubmit={handleLoginBtnClick}
          >
            {({ handleSubmit, setFieldValue, ...rest }) => (
              <form
                onSubmit={handleSubmit}
                style={
                  mobile
                    ? {
                        width: '100%',
                      }
                    : {
                        width: '480px',
                      }
                }
              >
                <TextField
                  id={username}
                  name={username}
                  label='Username'
                  placeholder='Username'
                  value={rest.values.username}
                  onChange={rest.handleChange}
                  error={rest.touched.username && Boolean(rest.errors.username)}
                  helperText={rest.touched.username && rest.errors.username}
                  sx={styles.defaultWidth}
                  marginBottom='16px'
                />

                <TextField
                  id={password}
                  name={password}
                  label='Password'
                  placeholder='Password'
                  type={showPassword ? 'text' : 'password'}
                  value={rest.values.password}
                  onChange={rest.handleChange}
                  error={rest.touched.password && Boolean(rest.errors.password)}
                  helperText={rest.touched.password && rest.errors.password}
                  sx={styles.defaultWidth}
                  endAdornment={
                    <IconButton
                      onClick={handleShowPasswordClick}
                      sx={styles.visibilityContainer}
                    >
                      {showPassword ? (
                        <VisibilityOutlinedIcon
                          sx={styles.visibilityOn}
                          color='primary'
                        />
                      ) : (
                        <VisibilityOffOutlinedIcon sx={styles.visibility} />
                      )}
                    </IconButton>
                  }
                />

                <Stack
                  direction='row'
                  justifyContent='space-between'
                  sx={
                    mobile
                      ? styles.mobilestayLoggedInForgotContainer
                      : styles.stayLoggedInForgotContainer
                  }
                >
                  <Stack direction='row' spacing={1} alignItems='center'>
                    <Checkbox
                      sx={styles.checkbox}
                      checked={isStayLoggedIn}
                      onChange={handleStayLoggedInChange}
                    />
                    <InputLabel sx={styles.checkboxLabel}>
                      Stay logged in
                    </InputLabel>
                  </Stack>
                  <Button
                    variant='text'
                    sx={styles.forgotButton}
                    onClick={() => handleForgotUsernameAndPswdClick()}
                  >
                    <Typography
                      sx={{
                        height: '100%',
                        textAlign: 'center',
                        fontSize: '15px',
                        fontWeight: '500',
                        paddingTop: '2px',
                      }}
                    >
                      Forgot Username or Password?
                    </Typography>
                  </Button>
                </Stack>
                <Grid item sx={{ width: '100%', padding: '5px 0px 5px 0px' }}>
                  <Stack
                    justifyContent='center'
                    alignItems='center'
                    sx={{ width: '100%', height: 'fit-content' }}
                  >
                    <Typography
                      sx={mobile ? styles.mobileTncMessage : styles.tncMessage}
                    >
                      <Checkbox
                        sx={styles.tNcCheckbox}
                        checked={acceptTnC}
                        onChange={(e) => {
                          setFieldValue(
                            'termsAndConditions',
                            clientPublicInfo?.termsAndConditionsRequiredOn?.includes(
                              'mandatory_login'
                            )
                              ? e.target.checked
                              : true
                          ); // Update Formik value
                          handleAcceptTnC();
                        }}
                      />
                      By clicking the box, you agree to abide by our{' '}
                      <Link
                        underline='none'
                        sx={styles.tncLink}
                        onClick={(e) => {
                          e.preventDefault();
                          handleTermsAndConditionClick();
                        }}
                      >
                        Terms &amp; Conditions
                      </Link>
                    </Typography>
                    {rest.errors.termsAndConditions &&
                      rest.touched.termsAndConditions && (
                        <Typography
                          sx={{ color: '#f02711', fontWeight: '500' }}
                        >
                          {rest.errors.termsAndConditions}
                        </Typography>
                      )}
                  </Stack>
                </Grid>
                <Button
                  variant='contained'
                  sx={styles.defaultWidth}
                  type='submit'
                  disabled={isLoginLoading}
                  endIcon={isLoginLoading && <CircularProgress size={16} />}
                >
                  Login
                </Button>
              </form>
            )}
          </Formik>
        </Stack>
      </Grid>
      {isShowTnc && (
        <Tnc
          open={isShowTnc}
          setOpen={setIsShowTnc}
          tnc={tnc}
          isTncLoading={isTncLoading}
        />
      )}
    </Grid>
  );
};

LoginForm.propTypes = {};

export default LoginForm;
