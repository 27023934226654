import isEmpty from 'lodash/isEmpty';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import SearchIcon from 'assets/icons/search-icon';
import CancelIcon from 'assets/icons/cancel-icon';
import MultiSelect from 'components/select/multi-select';
import TextField from 'components/text-field';
import { useSearchHooks } from './search-hooks';

export default function Search() {
  const {
    searchTypes,
    setSearchTypes,
    setSearchText,
    searchText,
    searchFilters,
    onApplySearch,
    onClearFilters,
  } = useSearchHooks();

  if (isEmpty(searchFilters)) {
    return null;
  }

  const resetSearch = (
    <IconButton
      onClick={(e) => {
        e.preventDefault();
        onClearFilters();
      }}
      sx={{ height: '16px' }}
      title='Reset'
    >
      <CancelIcon fill='var(--color-red)' height='14px' />
    </IconButton>
  );

  return (
    <Stack direction='row' justifyContent='center' alignItems='center'>
      <MultiSelect
        placeholder='Select'
        value={searchTypes}
        setValue={setSearchTypes}
        options={searchFilters}
        labelAccessor={'displayName'}
        valueAccessor={'key'}
        variant='text'
        size=''
        sx={{ width: '150px' }}
      />
      <TextField
        placeholder='What are you looking for?'
        value={searchText}
        onChange={(e) => {
          let value = e.target.value;
          value = value.replace(/[`~!@#$%^*]/gi, '');
          setSearchText(value);
        }}
        sx={{
          borderRadius: 0,
          width: '220px !important',
          border: 0,
          borderBottom: '1px solid var(--color-silver)',
          marginRight: '4px',
        }}
        onKeyUp={(e) => {
          if (e.keyCode === 13) {
            onApplySearch();
          }
        }}
        endAdornment={searchText ? resetSearch : null}
      />

      <Button
        variant='text'
        title='Search'
        startIcon={<SearchIcon fill='var(--color-black)' />}
        sx={{
          '& .MuiButton-startIcon': {
            marginLeft: '0px',
            width: '18px',
            height: '18px',
          },
        }}
        onClick={onApplySearch}
      />
    </Stack>
  );
}
