import React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

import { useWelcomeBar } from './welcomeBarHooks';
import isEmpty from 'lodash/isEmpty';
import { zIndex } from 'constants/zIndex';
import { responsiveBreakpoints } from 'utils/utils';
import { useSanitizeHTML } from 'common-hooks/sanitize-html';

const WelcomeBar = () => {
  const { mobile, tablet, mainWidth } = responsiveBreakpoints();
  const {
    welcomeBarConfig,
    handleAppBarBtnClick,
    welcomeBarConfigLoading,
    hideWelcomeBar,
  } = useWelcomeBar();
  const { cleanHTML: cleanWelcomeText } = useSanitizeHTML(
    welcomeBarConfig?.welcome_text
  );

  if (hideWelcomeBar) {
    return null;
  }

  if (!isEmpty(welcomeBarConfig) && !welcomeBarConfigLoading)
    return (
      <AppBar
        elevation={0}
        position={welcomeBarConfig.wel_bar_pos == 1 ? 'sticky' : 'relative'}
        // sx={{
        //   backgroundColor: `#${welcomeBarConfig.bg_color}`,
        //   height: `${welcomeBarConfig.bar_height}px`,
        //   opacity: welcomeBarConfig.opacity,
        // }}
        sx={[
          {
            hight: 'fit-content',
            zIndex: zIndex.welcomeBar,
            width: '100%',
          },
          mobile
            ? {
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: `#${welcomeBarConfig.bg_color ?? 'ffff'}`,
              }
            : tablet
            ? {
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: `#${welcomeBarConfig.bg_color ?? 'ffff'}`,
              }
            : {
                padding: '0px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: `#${welcomeBarConfig.bg_color ?? 'ffff'}`,
              },
        ]}
      >
        <Stack
          direction='row'
          spacing={1.5}
          justifyContent={mobile ? '' : 'center'}
          alignItems='center'
          // sx={{
          //   height: '100%',
          // }}
          sx={{
            backgroundColor: 'inherit',
            height: `${welcomeBarConfig.bar_height}px`,
            opacity: welcomeBarConfig.opacity,
            padding: '0px',
            width: !mobile && !tablet ? mainWidth : '100vw',
            overflowX: 'auto',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={[
              {
                color: `#${welcomeBarConfig.text_color}`,
                fontSize: `${mobile ? '8' : welcomeBarConfig.font_size}px`,
                fontFamily: `${welcomeBarConfig.font_type}, Avenir`,
                // display: 'inline-grid',
                minWidth: 'fit-content',
                opacity: `${welcomeBarConfig.opacity}`,
                alignItems: 'center',
                paddingLeft: '10px',
                '& font': {
                  textAlign: 'center',
                  display: 'flex',
                  overflow: 'hidden',
                  verticalAlign: 'middle',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: `${welcomeBarConfig.font_size}px`,
                  backgroundColor: `#${welcomeBarConfig.bg_color}`,
                  color: 'inherit',
                  fontFamily: `${welcomeBarConfig.font_type}`,
                  margin: '0px',
                  whiteSpace: 'nowrap',
                },
                '& p:first-child': {
                  textAlign: 'center',
                  display: 'flex',
                  overflow: 'hidden',
                  verticalAlign: 'middle',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: `${welcomeBarConfig.font_size}px`,
                  backgroundColor: `#${welcomeBarConfig.bg_color}`,
                  color: 'inherit',
                  fontFamily: `${welcomeBarConfig.font_type}`,
                  margin: '0px',
                  whiteSpace: 'nowrap',
                },
                '& img': {
                  height: `${welcomeBarConfig.bar_height}px`,
                  objectFit: 'cover',
                },
              },
              mobile
                ? {
                    '& img': {
                      // width: '35px',
                      height: `${welcomeBarConfig.bar_height}px`,
                      objectFit: 'cover',
                    },
                  }
                : null,
            ]}
            id='welcome_bar_text'
            dangerouslySetInnerHTML={{
              __html: cleanWelcomeText,
            }}
          >
            {/* {welcomeBarConfig.welcome_text} */}
          </Typography>
          {welcomeBarConfig.btn && (
            <Button
              href={`${welcomeBarConfig?.btn?.btn_url || '#'}`}
              variant='contained'
              size='medium'
              onClick={() =>
                handleAppBarBtnClick(
                  welcomeBarConfig.btn.btn_url,
                  welcomeBarConfig.btn.btn_new_win
                )
              }
              target={welcomeBarConfig.btn.btn_new_win ? '_blank' : '_self'}
              sx={{
                color: `#${welcomeBarConfig.btn.btn_text_color}`,
                backgroundColor: `#${welcomeBarConfig.btn.btn_color}`,
                height: '30px',
                fontSize: '12px',
                minWidth: 'fit-content',
                whiteSpace: 'nowrap',
              }}
            >
              {welcomeBarConfig?.btn?.btn_txt}
            </Button>
          )}
        </Stack>
      </AppBar>
    );
  // if (isEmpty(welcomeBarConfig) && welcomeBarConfigLoading)
  //   return <Skeleton variant='rectangular' height='15px' width='100%' />;
};

export default WelcomeBar;
