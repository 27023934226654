import {
  api,
  addActionLoader,
  removeActionLoader,
  apiEndPoints,
} from '../../api';
import { showSnackbar } from 'components/snackbar/actions';
import { snackbarTypes } from 'constants/index';

const namespace = 'buyerRequest/';
export const SET_API_ERROR = `${namespace}SET_API_ERROR`;
export const DECLINE_NEW_RETAILER_REQUEST = `${namespace}DECLINE_NEW_RETAILER_REQUEST`;
export const NOTIFICATION_MORE_DATA_REQUEST = `${namespace}NOTIFICATION_MORE_DATA_REQUEST`;
export const APPROVE_NEW_BUYER_REQUEST = `${namespace}APPROVE_NEW_BUYER_REQUEST`;
export const CHECK_BUYER_USER_NAME_REQUEST = `${namespace}CHECK_BUYER_USER_NAME_REQUEST`;
export const DECLINE_BUYER_ACTION_REQUEST = `${namespace}DECLINE_BUYER_ACTION_REQUEST`;
export const APPROVE_EDIT_BUYER_INFO_REQUEST = `${namespace}APPROVE_EDIT_BUYER_INFO_REQUEST`;

const setApiError = (error) => ({
  type: SET_API_ERROR,
  payload: error,
});

export const declineRetailerRequest = (id, payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(DECLINE_NEW_RETAILER_REQUEST));
    const response = await api.post(
      apiEndPoints.declineRetailerRequest(id),
      payload
    );
    const { data, data: { status = false, message = '' } = {} } = response;
    if (status) {
      dispatch(
        showSnackbar({ snackbarMessage: message, type: snackbarTypes.SUCCESS })
      );
      return data;
    }
    dispatch(
      showSnackbar({ snackbarMessage: message, type: snackbarTypes.ERROR })
    );
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(DECLINE_NEW_RETAILER_REQUEST));
  }
};

export const getMoreNotificationsData = (id) => async (dispatch) => {
  try {
    dispatch(addActionLoader(NOTIFICATION_MORE_DATA_REQUEST));
    const response = await api.get(
      apiEndPoints.buyerRequestNotificationInfo(id)
    );
    const {
      data: {
        data,
        status = false,
        message = 'Something went wrong while getting Notification data.',
      } = {},
    } = response;
    if (status) {
      return data;
    }
    dispatch(
      showSnackbar({ snackbarMessage: message, type: snackbarTypes.ERROR })
    );
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(NOTIFICATION_MORE_DATA_REQUEST));
  }
};

export const getMoreEditNotificationsData = (id) => async (dispatch) => {
  try {
    dispatch(addActionLoader(NOTIFICATION_MORE_DATA_REQUEST));
    const response = await api.get(
      apiEndPoints.buyerEditRequestNotificationInfo(id)
    );
    const {
      data: {
        data,
        status = false,
        message = 'Something went wrong while getting Notification data.',
      } = {},
    } = response;
    if (status) {
      return data;
    }
    dispatch(
      showSnackbar({ snackbarMessage: message, type: snackbarTypes.ERROR })
    );
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(NOTIFICATION_MORE_DATA_REQUEST));
  }
};

export const approveNewBuyerRequest = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(APPROVE_NEW_BUYER_REQUEST));
    const response = await api.post(apiEndPoints.newAddBuyerRequest, payload);
    const { data, data: { status = false, message = '' } = {} } = response;
    if (status) {
      dispatch(
        showSnackbar({ snackbarMessage: message, type: snackbarTypes.SUCCESS })
      );
      return data;
    }
    dispatch(
      showSnackbar({ snackbarMessage: message, type: snackbarTypes.ERROR })
    );
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(APPROVE_NEW_BUYER_REQUEST));
  }
};

export const checkBuyerUserName = (userName) => async (dispatch) => {
  try {
    dispatch(addActionLoader(CHECK_BUYER_USER_NAME_REQUEST));
    const response = await api.get(
      apiEndPoints.checkBuyerUserName.concat(`/${userName}`)
    );
    const {
      data,
      data: { status = false },
    } = response;
    if (status) {
      return data;
    }
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(CHECK_BUYER_USER_NAME_REQUEST));
  }
};

export const declineBuyerActionRequest = (id) => async (dispatch) => {
  try {
    dispatch(addActionLoader(DECLINE_BUYER_ACTION_REQUEST));
    const response = await api.put(
      apiEndPoints.declineBuyerRequestFromNotification(id)
    );
    const { data: { status = false, message = '' } = {} } = response;
    if (status) {
      dispatch(
        showSnackbar({ snackbarMessage: message, type: snackbarTypes.SUCCESS })
      );
      return true;
    }
    dispatch(
      showSnackbar({ snackbarMessage: message, type: snackbarTypes.ERROR })
    );
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(DECLINE_BUYER_ACTION_REQUEST));
  }
};

export const approveEditBuyerInfoRequest = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(APPROVE_EDIT_BUYER_INFO_REQUEST));
    const response = await api.put(
      apiEndPoints.approveBuyerUserProfileEditRequest(payload.id),
      payload
    );
    const { data, data: { status = false, message = '' } = {} } = response;
    if (status) {
      dispatch(
        showSnackbar({ snackbarMessage: message, type: snackbarTypes.SUCCESS })
      );
      return data;
    }
    dispatch(
      showSnackbar({ snackbarMessage: message, type: snackbarTypes.ERROR })
    );
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(APPROVE_EDIT_BUYER_INFO_REQUEST));
  }
};
