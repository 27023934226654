import React from 'react';
import PropTypes from 'prop-types';

export default function PlusSignIcon({ fill, sx }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill={fill}
      stroke={fill}
      style={sx}
    >
      <g>
        <g>
          <path d='M9,0a9,9,0,1,0,9,9A9.01,9.01,0,0,0,9,0ZM9,16.606A7.606,7.606,0,1,1,16.606,9,7.615,7.615,0,0,1,9,16.606Z' />
        </g>
      </g>
      <g transform='translate(4.817 4.754)'>
        <g>
          <path
            d='M144.69,138.724H141.9v-2.789a.7.7,0,0,0-1.394,0v2.789h-2.788a.7.7,0,1,0,0,1.394h2.788v2.789a.7.7,0,0,0,1.394,0v-2.789h2.789a.7.7,0,0,0,0-1.394Z'
            transform='translate(-137.022 -135.238)'
          />
        </g>
      </g>
    </svg>
  );
}

PlusSignIcon.propTypes = {
  fill: PropTypes.string,
  sx: PropTypes.object,
};

PlusSignIcon.defaultProps = {
  fill: 'var(--primary-icon-color)',
  sx: {},
};
