import React from 'react';
import PropTypes from 'prop-types';

export default function OrderEntryViewIcon({ fill, sx }) {
  return (
    <svg
      id='menu'
      xmlns='http://www.w3.org/2000/svg'
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill={fill}
      style={sx}
    >
      <path d='M11.5,0H.5A.5.5,0,0,0,0,.5v2A.5.5,0,0,0,.5,3h11a.5.5,0,0,0,.5-.5V.5A.5.5,0,0,0,11.5,0Z' />
      <path
        d='M11.5,0H.5A.5.5,0,0,0,0,.5v2A.5.5,0,0,0,.5,3h11a.5.5,0,0,0,.5-.5V.5A.5.5,0,0,0,11.5,0Z'
        transform='translate(0 4.8)'
      />
      <path
        d='M11.5,0H.5A.5.5,0,0,0,0,.5v2A.5.5,0,0,0,.5,3h11a.5.5,0,0,0,.5-.5V.5A.5.5,0,0,0,11.5,0Z'
        transform='translate(0 9)'
      />
    </svg>
  );
}

OrderEntryViewIcon.propTypes = {
  fill: PropTypes.string,
  sx: PropTypes.object,
};

OrderEntryViewIcon.defaultProps = {
  fill: 'var(--primary-icon-color)',
  sx: {},
};
