import { React } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Menu from '@mui/material/Menu';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowDownIcon from '../../assets/icons/arrow-down-icon';
import useSelect from './select-hooks';
import { CircularProgress, IconButton } from '@mui/material';

export default function Select({
  placeholder,
  label,
  variant,
  size,
  color,
  startAdornment,
  options,
  value,
  setValue,
  setFormikValue,
  sx,
  error,
  helperText,
  id,
  required,
  marginBottom,
  disabled,
  onChange,
  actions,
  valueAccessor,
  labelAccessor,
  allowRemoveIcon,
  endAdornment,
  isLoading,
}) {
  const { anchorEl, open, handleClick, handleClose } = useSelect();

  return (
    <Box marginBottom={marginBottom} sx={{ width: '100%' }}>
      {label ? <InputLabel required={required}>{label}</InputLabel> : null}
      <Button
        id={id}
        fullWidth
        variant={variant}
        size={size}
        color={color}
        onClick={disabled ? null : handleClick}
        sx={[
          {
            display: 'flex',
            justifyContent: 'space-between',
            fontWeight: 500,
          },
          size === 'medium' ? { height: '38px' } : null,
          error ? { borderColor: 'var(--error-color)' } : null,
          sx,
        ]}
      >
        <Stack
          direction='row'
          spacing={0.5}
          justifyContent='space-between'
          alignItems='center'
          sx={{ width: '100%' }}
        >
          <Box>{startAdornment}</Box>
          <Box
            sx={{
              width: '100%',
              whiteSpace: 'nowrap',
              textAlign: 'left',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {(() => {
              if (value === '' || value === null) {
                return placeholder;
              } else {
                return options?.filter(
                  (opt) => opt?.[valueAccessor] === value
                )[0]?.[labelAccessor];
              }
            })()}
          </Box>
          {endAdornment ? <Box>{endAdornment}</Box> : null}
          <Stack direction='row' spacing={1} alignItems='center'>
            {value && allowRemoveIcon && !disabled ? (
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setValue(null);
                  setFormikValue(id, null);
                }}
              >
                <CancelIcon
                  fontSize='small'
                  sx={{ color: '#ccc', '&:hover': { color: '#bbb' } }}
                />
              </IconButton>
            ) : null}
            {isLoading ? (
              <Box sx={{ height: '20px' }}>
                <CircularProgress size={18} />
              </Box>
            ) : null}
            <ArrowDownIcon sx={{ marginLeft: '5px', width: '15px' }} />
          </Stack>
        </Stack>
      </Button>
      {error ? (
        <Typography variant='body2' sx={{ color: 'var(--error-color)' }}>
          {helperText}
        </Typography>
      ) : null}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ maxHeight: '240px' }}
        PaperProps={{ sx: { minWidth: anchorEl && anchorEl.offsetWidth } }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        {options?.map((option, i) => (
          <MenuItem
            key={i}
            onClick={(e) => {
              e.preventDefault();
              setValue(option?.[valueAccessor]);
              setFormikValue(id, option?.[valueAccessor]);
              onChange(option?.[valueAccessor]);
              handleClose();
            }}
            // style={{ marginBottom: '-10px' }}
            sx={
              valueAccessor
                ? value === option?.[valueAccessor]
                  ? {
                      backgroundColor: 'primary.main',
                      color: 'var(--contained-primary-button-text-color)',
                      '&:hover': {
                        color: 'var(--primary-font-color)',
                      },
                    }
                  : null
                : null
            }
          >
            {option?.[labelAccessor]}
          </MenuItem>
        ))}

        {actions.map((item, i) => {
          return (
            <Box
              key={i}
              onClick={() => {
                handleClose();
              }}
            >
              {item}
            </Box>
          );
        })}
      </Menu>
    </Box>
  );
}

Select.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  options: PropTypes.array,
  startAdornment: PropTypes.node,
  value: PropTypes.any,
  sx: PropTypes.object,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  setValue: PropTypes.func,
  setFormikValue: PropTypes.func,
  id: PropTypes.string,
  required: PropTypes.bool,
  marginBottom: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  actions: PropTypes.array,
  valueAccessor: PropTypes.string,
  labelAccessor: PropTypes.string,
  allowRemoveIcon: PropTypes.bool,
  endAdornment: PropTypes.any,
  isLoading: PropTypes.bool,
};

Select.defaultProps = {
  placeholder: null,
  label: null,
  variant: 'outlined',
  size: 'medium',
  color: 'secondary',
  options: [],
  startAdornment: <></>,
  value: null,
  sx: {},
  error: false,
  helperText: null,
  setValue: function () {},
  setFormikValue: function () {},
  id: null,
  required: false,
  marginBottom: null,
  disabled: false,
  onChange: function () {},
  actions: [],
  valueAccessor: 'value',
  labelAccessor: 'label',
  allowRemoveIcon: true,
  endAdornment: null,
  isLoading: false,
};
