import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getDepartments } from 'pages/layout/layout-api';
import SessionStorageManager from 'utils/session-storage-manager';
import debounce from 'lodash/debounce';
import { setDepartmentListItems } from 'redux/actions/global-actions';

import { useDispatch } from 'react-redux';
export const useDepartmentMenuMobile = ({ setOpen }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data: departments = [], isLoading: departmentsLoading } = useQuery(
    ['getDepartments'],
    () => getDepartments(),
    {
      cacheTime: 3600000,
      staleTime: 3600000,
      select: (data) => {
        return data?.data?.data || [];
      },
    }
  );
  const handleClose = () => {
    setOpen(false);
  };
  const handleDepartmentMenuClick = ({ item, genderId }) => {
    let navigationLink = '';
    if (Object.keys(item).length == 4) {
      // on click of categories
      let filter = `{"filter1":"${item?.id}","cfilter1":"${item?.id}"}`;
      navigationLink = `?gndr=${genderId}&cl=${item?.cl}&filters=${filter}&resetCatalog=true&start=0&resetCatalog=true&fetchProduct=true`;
    } else {
      navigationLink = `?gndr=${genderId}&cl=${item?.id}&resetCatalog=true&start=0&resetCatalog=true&fetchProduct=true`;
    }

    handleClose();
    navigate({
      pathname: '/catalog',
      search: navigationLink,
    });
  };
  const handleDepartmentClick = useCallback(
    debounce((department) => {
      dispatch(
        setDepartmentListItems([
          {
            genderId: department.genderId,
            collectionId: department.collectionId,
          },
        ])
      );
      SessionStorageManager.removeSessionStorage('filters');
      SessionStorageManager.removeSessionStorage('searchFilters');

      handleClose();
      navigate({
        pathname: '/catalog',
        search: `?gndr=${department.genderId}&cl=${department.collectionId}&resetCatalog=true&start=0`,
      });
    }, 1000), // wait for 1s to start
    [] // dependencies for useCallback
  );

  return {
    departments,
    handleDepartmentMenuClick,
    handleClose,
    handleDepartmentClick,
    departmentsLoading,
  };
};
