import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Modal from '@mui/material/Modal';
import CollectionsAccordion from './collectionsAccordion';

const DepartmentAccordion = ({
  item,
  handleDepartmentClick,
  handleDepartmentMenuClick,
}) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <Accordion
      disableGutters
      elevation={0}
      expanded={expanded}
      onChange={() => {
        setExpanded(!expanded);
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          padding: '0px',
          paddingRight: '16px',
          height: '41px',
          maxHeight: '41px',
          '& .MuiAccordionSummary-content': {
            margin: 0, // Remove the margin
          },
        }}
      >
        <Stack
          direction='row'
          onClick={(e) => {
            e.stopPropagation();
            handleDepartmentClick(item);
          }}
        >
          <Typography variant='h4'>{item.genderName}</Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: '0px',
        }}
      >
        <Stack>
          {expanded ? (
            <CollectionsAccordion
              item={item}
              handleDepartmentMenuClick={handleDepartmentMenuClick}
            />
          ) : null}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
export default DepartmentAccordion;
DepartmentAccordion.propTypes = {
  item: PropTypes.obj,
  handleDepartmentClick: PropTypes.func.isRequired,
  handleDepartmentMenuClick: PropTypes.func.isRequired,
};

DepartmentAccordion.defaultProps = {
  item: {},
  handleDepartmentClick: () => {},
  handleDepartmentMenuClick: () => {},
};
