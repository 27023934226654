import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import MyModal from 'components/modals/modal';
import ListItem from './list-item';
import { useSendInviteEmail } from './hooks';
import FilterOptions from './filter-options';
import SendingEmailsModal from 'components/share/components/sending-emails-modal';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import { CircularProgress } from '@mui/material';
import EmptyState from 'components/empty-state';
import BuyerEmptyStateIcon from 'assets/icons/buyer-empty-state-icon';
import AlertModal from '../alert/alert-modal';

const SendInviteEmail = ({ open, setOpen }) => {
  const {
    retailers,
    sendInviteEmail,
    selectedBuyers,
    setSelectedBuyers,
    toggleSelectedRetailers,
    selectedRetailers,
    sendingEmails,
    setSendingEmails,
    emailResponses,
    emailsSent,
    searchTerm,
    setSearchTerm,
    page,
    setPage,
    sender,
    setSender,
    signature,
    setSignature,
    sortBy,
    setSortBy,
    senderOptions,
    signatureOptions,
    selectAllBuyers,
    isLoading,
    buyerLimitError,
    setBuyerLimitError,
  } = useSendInviteEmail();

  // modal actions
  const close = (
    <Button
      variant='outlined'
      color='secondary'
      onClick={(e) => {
        e.preventDefault();
        setOpen(false);
      }}
    >
      Close
    </Button>
  );
  const sendEmail = (
    <Button
      disabled={selectedBuyers?.length === 0}
      variant='contained'
      onClick={() => {
        setSendingEmails(true);
        sendInviteEmail();
      }}
    >
      Send Email
    </Button>
  );

  return (
    <MyModal
      title='Send Invite Email'
      open={open}
      setOpen={setOpen}
      size='fullWidth'
      showCloseIcon
      actions={[close, sendEmail]}
    >
      {/* sending emails modal */}
      <SendingEmailsModal
        open={sendingEmails}
        setOpen={setSendingEmails}
        emailRequests={emailResponses}
        emailsSent={emailsSent}
        openParentModal={open}
        setOpenParentModal={setOpen}
        totalEmails={emailResponses.length}
      />

      {/**alert for buyer limit error */}
      {buyerLimitError && (
        <AlertModal
          open={buyerLimitError}
          setOpen={setBuyerLimitError}
          title='You can only send 200 emails at once. Please deselect some buyers to continue'
        />
      )}

      {/**loader */}
      {isLoading ? (
        <Box
          width='100vw'
          height='100vh'
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 9999,
            opacity: 0.4,
            backgroundColor: '#000',
          }}
        >
          <CircularProgress />
        </Box>
      ) : null}

      <Box sx={{ padding: '18px 25px 18px 25px' }}>
        <FilterOptions
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          signatureOptions={signatureOptions}
          signature={signature}
          setSignature={setSignature}
          sortBy={sortBy}
          setSortBy={setSortBy}
          sender={sender}
          setSender={setSender}
          senderOptions={senderOptions}
          selectAllBuyers={selectAllBuyers}
        />

        {retailers.count > 0 ? (
          <>
            {retailers?.data?.map((retailer, i) => {
              return (
                <ListItem
                  key={i}
                  retailerId={retailer?.id}
                  name={retailer?.name}
                  address={retailer?.addressFull}
                  priceGroup={retailer?.priceGroupName}
                  paymentTerms={retailer?.paymentTerms}
                  paymentMethod={
                    retailer?.paymentMethodName === 'Not on file'
                      ? 'Not on file'
                      : retailer?.paymentMethodName
                  }
                  shippingMethod={retailer?.shippingMethodName}
                  selectedBuyers={selectedBuyers}
                  setSelectedBuyers={setSelectedBuyers}
                  selectedRetailers={selectedRetailers}
                  toggleSelectedRetailers={toggleSelectedRetailers}
                />
              );
            })}
          </>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '300px',
            }}
          >
            <EmptyState
              title='No retailers found'
              icon={<BuyerEmptyStateIcon />}
            />
          </Box>
        )}
      </Box>
      {retailers.count > 10 && retailers.count ? (
        <Stack
          justifyContent='center'
          alignItems='center'
          sx={{ paddingTop: '20px' }}
        >
          <Pagination
            count={parseInt(parseInt(retailers.count) / 10)}
            showFirstButton
            showLastButton
            page={page}
            onChange={(e, value) => {
              setPage(value);
            }}
          />
        </Stack>
      ) : null}
    </MyModal>
  );
};

SendInviteEmail.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default SendInviteEmail;
