import { useState, useEffect } from 'react';
import { getRetailerDetail } from 'pages/customers/actions';
import { useDispatch } from 'react-redux';

export default function useBuyersTable({
  retailerId,
  selectedBuyers,
  setSelectedBuyers,
}) {
  const dispatch = useDispatch();

  const [buyers, setBuyers] = useState([]);

  const getRetailerDetails = (id) => {
    dispatch(
      getRetailerDetail((data) => {
        setBuyers(data?.buyerInfo);
      }, id)
    );
  };

  useEffect(() => {
    getRetailerDetails(retailerId);
  }, []);

  function toggleSelectedBuyers(buyerToSelect) {
    var arr = [];
    var alreadyExists = false;
    alreadyExists = selectedBuyers.some(
      (buyer) => buyer.user_id === buyerToSelect.user_id
    );

    if (alreadyExists) {
      selectedBuyers.forEach((buyer) => {
        if (buyer.user_id !== buyerToSelect.user_id) {
          arr.push(buyer);
        }
      });
    } else {
      selectedBuyers.forEach((buyer) => {
        arr.push(buyer);
      });
      arr.push(buyerToSelect);
    }

    setSelectedBuyers(arr);
  }

  return { buyers, selectedBuyers, setSelectedBuyers, toggleSelectedBuyers };
}
