import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { DeliveriesStyles as styles } from '../styles';

const DepartmentDeliveries = ({
  loading,
  deliveries,
  handleSelectDelivery,
  handleDeliveryClick,
  selectedDelivery,
}) => {
  return loading ? (
    <Box display='flex' alignItems='center' height='100%'>
      <CircularProgress sx={styles.loader} />
    </Box>
  ) : (
    deliveries &&
      deliveries?.map((delverie, index) => (
        <Typography
          key={delverie?.id}
          variant='text'
          sx={[
            styles.megaMenuDeliveries,
            selectedDelivery?.index == index && styles.selectedDeliverie,
          ]}
          onClick={() => handleDeliveryClick(delverie)}
          onMouseEnter={() => handleSelectDelivery({ ...delverie, index })}
        >
          <Typography variant='subtitle1' sx={styles.deliveryTypo}>
            {delverie?.noteText}
          </Typography>
          {delverie.deliver_from}
          <Typography variant='subtitle2' sx={styles.deliveryTypo}>
            {delverie.message}
          </Typography>
        </Typography>
      ))
  );
};

DepartmentDeliveries.propTypes = {
  loading: PropTypes.bool.isRequired,
  deliveries: PropTypes.array.isRequired,
  handleSelectDelivery: PropTypes.func.isRequired,
  handleDeliveryClick: PropTypes.func.isRequired,
  selectedDelivery: PropTypes.object,
};

DepartmentDeliveries.defaultProps = {
  selectedCollection: {},
};

export default DepartmentDeliveries;
