import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

const OtherAccounts = ({ headerMenu, onClickLinkedBuyerAccount }) => {
  return (
    <AccordionDetails
      sx={{
        padding: '10px 0px 0px 0px',
        flexGrow: 1,
        height: '90vh',
        overflow: 'scroll',
        paddingBottom: '70px',
      }}
    >
      <Stack
        sx={{
          flexGrow: 1,
          backgroundColor: '#181818',
          padding: '0px 20px 0px 20px',
        }}
      >
        <Typography
          variant='h4'
          sx={{
            color: '#FFFFFF',
            lineHeight: '26px',
            marginBottom: '24px',
            fontWeight: '700',
            letterSpacing: '0.42pt',
          }}
        >
          Other Profiles
        </Typography>
        <Stack spacing={2}>
          {headerMenu?.rightHeaderMenu?.map((rightMenu) => {
            if (
              rightMenu?.id == 'lft-lnk-my-account-0' &&
              rightMenu?.hasSubOption
            ) {
              console.log('inside 1 if');
              return rightMenu?.subOption?.map((subOption) => {
                if (
                  subOption?.id == 'lnk-other-accounts-0' &&
                  subOption?.isSection
                ) {
                  return subOption?.section?.subSection?.map((section, i) => {
                    return (
                      <Stack
                        direction='row'
                        spacing={2}
                        key={i}
                        sx={{
                          width: 'fit-content',
                        }}
                        onClick={() => {
                          onClickLinkedBuyerAccount({
                            userId: section?.userId,
                          });
                        }}
                      >
                        {section.profile_pic ? (
                          <Avatar
                            src={section.profile_pic}
                            sx={{
                              width: '54px',
                              height: '54px',
                            }}
                          />
                        ) : (
                          <Avatar
                            sx={{
                              width: '54px',
                              height: '54px',
                              padding: '15px',
                            }}
                          >
                            {section.name
                              ? section.name
                                  .split(' ')
                                  .map((name) => name.charAt(0).toUpperCase())
                                  .join('')
                              : null}
                          </Avatar>
                        )}
                        <Stack
                          spacing={0.5}
                          sx={{
                            justifyContent: 'center',
                            display: 'flex',
                          }}
                        >
                          <Typography
                            variant='subtitle1M'
                            sx={{
                              color: '#FFFFFF',
                              fontWeight: '500',
                              letterSpacing: '0.42pt',
                            }}
                          >
                            {section.name}
                          </Typography>
                        </Stack>
                      </Stack>
                    );
                  });
                }
              });
            }
          })}
        </Stack>
      </Stack>
    </AccordionDetails>
  );
};
export default OtherAccounts;
OtherAccounts.propTypes = {
  headerMenu: PropTypes.object,
  onClickLinkedBuyerAccount: PropTypes.func,
};

OtherAccounts.defaultProps = {
  headerMenu: {},
  onClickLinkedBuyerAccount: () => {},
};
