import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectClientPublicInfoSelector } from 'redux/selectors';

export const useNewAccount = () => {
  const [showUnderReviewMessage, setShowUnderReviewMessage] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const clientPublicInfo = useSelector(selectClientPublicInfoSelector);

  const handleReviewMessage = (message, status) => {
    setResponseMessage(message);
    setShowUnderReviewMessage(status);
  };

  return {
    showUnderReviewMessage,
    handleReviewMessage,
    responseMessage,
    clientPublicInfo,
  };
};
