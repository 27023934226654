import { React } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Menu from '@mui/material/Menu';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowDownIcon from '../../assets/icons/arrow-down-icon';
import useSelect from './select-hooks';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';

export default function MultiSelect({
  placeholder,
  label,
  variant,
  size,
  color,
  startAdornment,
  options,
  value,
  setValue,
  setFormikValue,
  sx,
  error,
  helperText,
  id,
  required,
  marginBottom,
  disabled,
  actions,
  valueAccessor,
  labelAccessor,
  defaultOption,
}) {
  const { anchorEl, open, handleClick, handleClose } = useSelect();

  return (
    <Box marginBottom={marginBottom} sx={{ width: '100%' }}>
      {label ? <InputLabel required={required}>{label}</InputLabel> : null}
      <Button
        id={id}
        fullWidth
        variant={variant}
        size={size}
        color={color}
        onClick={disabled ? null : handleClick}
        sx={[
          {
            display: 'flex',
            justifyContent: 'space-between',
            fontWeight: 500,
          },
          size === 'medium' ? { height: '38px' } : null,
          error ? { borderColor: 'var(--error-color)' } : null,
          sx,
        ]}
      >
        <Stack
          direction='row'
          spacing={0.5}
          justifyContent='space-between'
          alignItems='center'
          sx={{ width: '100%' }}
        >
          <Box>{startAdornment}</Box>
          <Box
            sx={{
              width: '100%',
              whiteSpace: 'nowrap',
              textAlign: 'left',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {/* function to create a string of selected options' labels */}

            {(() => {
              if (value?.length === 0 || !value) {
                return placeholder;
              }

              let str = '';
              for (let i = 0; i < value?.length; i++) {
                let matched = options?.filter(
                  (option) => option?.[valueAccessor] === value[i]
                );

                if (i === value?.length - 1) {
                  str = str?.concat(matched[0]?.[labelAccessor]);
                } else {
                  str = str?.concat(matched[0]?.[labelAccessor]);
                  str = str?.concat(', ');
                }
              }
              return str;
            })()}
          </Box>
          <Stack direction='row' spacing={1} alignItems='center'>
            {value?.length > 0 && (
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setValue([]);
                  setFormikValue(id, []);
                }}
              >
                <CancelIcon
                  fontSize='small'
                  sx={{ color: '#ccc', '&:hover': { color: '#bbb' } }}
                />
              </IconButton>
            )}
            <ArrowDownIcon sx={{ marginLeft: '5px', width: '15px' }} />
          </Stack>
        </Stack>
      </Button>
      {error ? (
        <Typography variant='body2' sx={{ color: 'var(--error-color)' }}>
          {helperText}
        </Typography>
      ) : null}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ maxHeight: '280px' }}
        PaperProps={{
          sx: {
            minWidth: anchorEl && anchorEl.offsetWidth,
            minHeight: '150px',
            maxWidth: '170px',
            overflow: 'scroll',
            paddingRight: '8px',
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        {defaultOption ? (
          <MenuItem
            style={{
              padding: '0px',
            }}
            onClick={() => {
              handleClose();
            }}
          >
            {defaultOption}
          </MenuItem>
        ) : null}
        {options &&
          options?.map((option, i) => {
            return (
              <MenuItem
                key={i}
                onClick={(e) => {
                  e.preventDefault();
                  if (Array.isArray(value)) {
                    if (value?.some((val) => val === option?.[valueAccessor])) {
                      let arr = value?.filter(
                        (val) => val !== option[valueAccessor]
                      );
                      setValue(arr);
                      setFormikValue(id, arr);
                    } else {
                      setValue([...value, option?.[valueAccessor]]);
                      setFormikValue(id, [...value, option?.[valueAccessor]]);
                    }
                  }
                }}
                style={{ padding: '3px 3px 3px 3px' }}
                sx={
                  valueAccessor
                    ? value === option?.[valueAccessor]
                      ? {
                          backgroundColor: 'primary.main',
                          color: 'var(--contained-primary-button-text-color)',
                          '&:hover': {
                            color: 'var(--primary-font-color)',
                          },
                        }
                      : null
                    : null
                }
              >
                <Stack
                  direction='row'
                  alignItems='center'
                  spacing={1}
                  sx={{ fontWeight: 30, whiteSpace: 'wrap' }}
                >
                  <Checkbox
                    checked={(() => {
                      if (option && valueAccessor) {
                        if (Array.isArray(value)) {
                          return value?.some(
                            (val) => val === option?.[valueAccessor]
                          );
                        }
                      }
                    })()}
                  />

                  <span>{option?.[labelAccessor]}</span>
                </Stack>
              </MenuItem>
            );
          })}

        {actions.map((item, i) => {
          return (
            <Box
              key={i}
              onClick={() => {
                handleClose();
              }}
            >
              {item}
            </Box>
          );
        })}
      </Menu>
    </Box>
  );
}

MultiSelect.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  options: PropTypes.array,
  startAdornment: PropTypes.node,
  value: PropTypes.array,
  sx: PropTypes.object,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  setValue: PropTypes.func,
  setFormikValue: PropTypes.func,
  id: PropTypes.string,
  required: PropTypes.bool,
  marginBottom: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  actions: PropTypes.array,
  valueAccessor: PropTypes.string,
  labelAccessor: PropTypes.string,
  defaultOption: PropTypes.node,
};

MultiSelect.defaultProps = {
  placeholder: null,
  label: null,
  variant: 'outlined',
  size: 'medium',
  color: 'secondary',
  options: [],
  startAdornment: <></>,
  value: [],
  sx: {},
  error: false,
  helperText: null,
  setValue: function () {},
  setFormikValue: function () {},
  id: null,
  required: false,
  marginBottom: null,
  disabled: false,
  onChange: function () {},
  actions: [],
  valueAccessor: 'value',
  labelAccessor: 'label',
  defaultOption: null,
};
