import React from 'react';
import PropTypes from 'prop-types';

export default function SearchIconVariant({ fill, sx }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='13.516'
      height='14'
      viewBox='0 0 13.516 14'
      fill={fill}
      style={sx}
    >
      <path
        d='M14.295,12.752,10.963,9.286A5.65,5.65,0,1,0,6.636,11.3a5.592,5.592,0,0,0,3.238-1.023l3.357,3.492a.737.737,0,1,0,1.063-1.022ZM6.636,1.475A4.178,4.178,0,1,1,2.459,5.652,4.183,4.183,0,0,1,6.636,1.475Z'
        transform='translate(-0.984)'
      />
    </svg>
  );
}

SearchIconVariant.propTypes = {
  fill: PropTypes.string,
  sx: PropTypes.object,
};

SearchIconVariant.defaultProps = {
  fill: 'rgba(68,68,68,0.5)',
  sx: {},
};
