import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Popover from '@mui/material/Popover';
import { responsiveBreakpoints } from 'utils/utils';

import WatchTourIcon from 'assets/icons/watch-tour-icon';
const WatchTourPopOver = ({
  id,
  anchorEl,
  handleClose,
  setWatchTourCompleted,
  updateTourStatus,
  watchTourData,
  userInfo,
  open,
  anchorOrigin,
  transformOrigin,
  marginTop,
  disableOkGotIt,
}) => {
  const { mobile, tablet } = responsiveBreakpoints();
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      // anchorOrigin={{
      //   vertical: 'bottom',
      //   horizontal: 'left',
      // }}
      anchorOrigin={anchorOrigin}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      // Add 10px to the top position
      sx={{ marginTop: marginTop }}
    >
      <Box
        sx={{
          width: mobile ? '100%' : '489px',
          height: '382px',
          backgroundColor: 'var(--color-white)',
          // position: 'absolute',
          // top: '-392px',
          bottom: '72px',
          left: '0px',
          boxShadow: '0px 13px 32px #0000001C',
          padding: '0px 27px 3px 27px',
          borderRadius: '12px',
          overFlow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Stack
          direction='row'
          width='100%'
          justifyContent='space-between'
          alignItems='center'
          sx={{
            position: 'sticky',
            top: '0px',
            left: '0px',
            width: '100%',
            backgroundColor: 'var(--color-white)',
            zIndex: '999',
            paddingTop: '31px',
            paddingBottom: '23px',
          }}
        >
          <Typography
            variant='h1'
            sx={{
              backgroundColor: 'var(--color-white)',
              color: '#000000',
              fontSize: mobile ? '20px' : '25px',
            }}
          >
            What&apos;s New?
          </Typography>
          <Stack direction='row' spacing={2}>
            {!disableOkGotIt ? (
              <Link
                component='button'
                variant='body2'
                onClick={() => {
                  setWatchTourCompleted(true);
                  updateTourStatus(userInfo?.id ?? '');
                }}
                sx={{
                  fontSize: mobile ? '12px' : '14px',
                  textUnderlineOffset: '6px',
                }}
              >
                Okay, got it
              </Link>
            ) : null}

            <IconButton onClick={() => handleClose()}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </Stack>
        <Stack spacing={2} sx={{ overflow: 'scroll', flexGrow: 1 }}>
          {watchTourData?.map((data, i) => {
            if (data?.isAccessible.includes(data?.userRole))
              return (
                <Grid
                  key={`${data?.title}_${i}`}
                  container
                  sx={{
                    backgroundColor: '#F2F2F2',
                    borderRadius: '11px',
                    padding: '0px 8px',
                  }}
                >
                  <Grid
                    item
                    xs={7}
                    sm={8}
                    md={8}
                    lg={8}
                    sx={{
                      padding: '14px 25px 14px 12px',
                    }}
                  >
                    <Stack spacing={1}>
                      <Typography
                        variant='h3'
                        sx={{ fontSize: mobile ? '14px' : '15px' }}
                      >
                        {data?.title}
                      </Typography>
                      <Typography
                        variant='h4'
                        sx={{
                          fontWeight: '400',
                          color: '#6A6A6A',
                          fontSize: mobile ? '12px' : '14px',
                          wordWrap: 'break-word',
                        }}
                      >
                        {data?.subTitle}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={5} sm={4} md={4} lg={4}>
                    <Stack
                      direction='row'
                      spacing={2}
                      alignItems='center'
                      justifyContent='end'
                      height='100%'
                    >
                      <Button
                        variant='contained'
                        size={mobile ? 'small' : 'large'}
                        sx={{
                          width: 'fit-content',
                          height: '39px',
                          borderRadius: '4px',
                          whiteSpace: 'nowrap',
                        }}
                        startIcon={<WatchTourIcon />}
                        onClick={() => {
                          handleClose();
                          window.open(data?.url, '_blank');
                        }}
                      >
                        Watch Video
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              );
          })}
        </Stack>
      </Box>
    </Popover>
  );
};
WatchTourPopOver.propTypes = {
  id: PropTypes.any,
  anchorEl: PropTypes.any,
  handleClose: PropTypes.func,
  setWatchTourCompleted: PropTypes.func,
  updateTourStatus: PropTypes.function,
  watchTourData: PropTypes.array,
  userInfo: PropTypes.object,
  open: PropTypes.any,
  anchorOrigin: PropTypes.object,
  transformOrigin: PropTypes.object,
  marginTop: PropTypes.string,
  disableOkGotIt: PropTypes.bool,
};
WatchTourPopOver.defaultProps = {
  id: '',
  anchorEl: '',
  handleClose: () => {},
  setWatchTourCompleted: () => {},
  updateTourStatus: () => {},
  watchTourData: [],
  userInfo: {},
  open: '',
  anchorOrigin: {},
  transformOrigin: {},
  marginTop: '0px',
  disableOkGotIt: false,
};
export default WatchTourPopOver;
