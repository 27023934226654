import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';
import moment from 'moment/moment';
import { Formik } from 'formik';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from 'components/text-field';
import MyModal from 'components/modals/modal';
import Select from 'components/select/select';
import SelectOld from 'components/select/select.old';
import { loggedInUserSelector } from 'redux/selectors';
import { profileFieldName } from './buyer-edit-request-modal-helper';
import BuyerRetailerInfoCard from './buyer-retailer-info-cards';
import ArrowNextIcon from 'assets/icons/arrow-next-icon';
import ArrowPrevIcon from 'assets/icons/arrow-prev-icon';
import { Autocomplete } from '@mui/material';
import MuiTextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';

const BuyerInfoEditRequestModal = ({
  open,
  setOpen,
  handleApproveClick,
  declineBtnClick,
  countryOptions,
  notificationDetails,
  handleCheckBuyerUserName,
  selectedNotification,
  isCustomTitle,
  customTitle,
}) => {
  const formikRef = useRef(null);
  const {
    name,
    username,
    department,
    company,
    email,
    businessPhone,
    mobilePhone,
    businessFax,
    city,
    state,
    zip,
    country,
    streetAddress,
    legalName,
    customerId,
    vendorCode,
    taxId,
    note,
    customerIdOrCode,
    dBAOrRooftopName,
    billingContact,
    billingIdOrCode,
    phone,
    buyerStreetAddress,
    buyerStreetAddress2,
    buyerCity,
    buyerState,
    buyerCountry,
    buyerZipCode,
    timezone,
  } = profileFieldName;
  const {
    buyerUserData,
    retailerData,
    created_at,
    currentData,
    formRequestData,
  } = notificationDetails;
  const user = useSelector(loggedInUserSelector);

  const findLabelByValue = (val) => {
    const foundItem = user?.timezones.find((item) => item.value === val);
    return foundItem ? foundItem.label : null;
  };
  const timezoneLabel = findLabelByValue(currentData?.timezone);

  const decline = (
    <Button
      variant='outlined'
      color='error'
      onClick={() => {
        declineBtnClick();
        setOpen(false);
      }}
    >
      Decline
    </Button>
  );

  const approve = (
    <Button variant='contained' onClick={() => formikRef.current.submitForm()}>
      Approve
    </Button>
  );

  return (
    <MyModal
      open={open}
      setOpen={setOpen}
      size='large'
      title={
        selectedNotification?.type != 'BUYER_EDIT_RETAILER_PROFILE_REQUEST'
          ? 'Buyer Edit Request'
          : 'Retailer Info Edit Request'
      }
      showCloseIcon
      actions={[decline, approve]}
      sx={{ width: '780px', height: '80vh' }}
      customTitle={customTitle}
      isCustomTitle={isCustomTitle}
    >
      <Grid container columnSpacing={3}>
        <BuyerRetailerInfoCard
          buyerUserData={buyerUserData}
          retailerData={retailerData}
        />
        <Grid item xs={12} sm={12} sx={{ paddingBottom: '28px' }}>
          <Typography
            variant='body1'
            sx={{ color: 'var(--secondary-font-color)' }}
          >
            Request On:{' '}
            <span
              style={{ color: 'var(--primary-font-color)', fontWeight: 500 }}
            >
              {moment(created_at).format('MMM Do,  YYYY  HH:MM')}
            </span>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography
            variant='h2'
            sx={{
              fontSize: '16px',
              borderBottom: '1px solid var(--secondary-border-color)',
              padding: '0 0 10px 0',
              marginBottom: '16px',
            }}
          >
            Changes Requested
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={
            selectedNotification?.type != 'BUYER_EDIT_RETAILER_PROFILE_REQUEST'
              ? {}
              : { display: 'none' }
          }
        >
          <Box
            sx={{
              border: '1px solid var(--secondary-border-color)',
              borderRadius: '6px',
              backgroundColor: 'var(--paper-background-color)',
            }}
          >
            <Typography
              variant='h2'
              sx={{
                fontSize: '16px',
                borderBottom: '1px solid var(--secondary-border-color)',
                padding: '13px 0 13px 20px',
              }}
            >
              Current Buyer Info
            </Typography>

            <Stack spacing={4.6} sx={{ padding: '20px' }}>
              <Stack spacing={0}>
                <Typography
                  variant='body1'
                  sx={{ color: 'var(--secondary-font-color)' }}
                >
                  Name
                </Typography>
                <Typography variant='body2' sx={{ height: '1.4375em' }}>
                  {currentData?.name || 'Not available'}
                </Typography>
              </Stack>

              <Stack spacing={0}>
                <Typography
                  variant='body1'
                  sx={{ color: 'var(--secondary-font-color)' }}
                >
                  Username
                </Typography>
                <Typography variant='body2' sx={{ height: '1.4375em' }}>
                  {currentData?.username || 'Not available'}
                </Typography>
              </Stack>

              <Stack spacing={0}>
                <Typography
                  variant='body1'
                  sx={{ color: 'var(--secondary-font-color)' }}
                >
                  Department
                </Typography>
                <Typography variant='body2' sx={{ height: '1.4375em' }}>
                  {currentData?.department || 'Not available'}
                </Typography>
              </Stack>

              <Stack spacing={0}>
                <Typography
                  variant='body1'
                  sx={{ color: 'var(--secondary-font-color)' }}
                >
                  Company
                </Typography>
                <Typography variant='body2' sx={{ height: '1.4375em' }}>
                  {currentData?.company || 'Not available'}
                </Typography>
              </Stack>

              <Stack spacing={0}>
                <Typography
                  variant='body1'
                  sx={{ color: 'var(--secondary-font-color)' }}
                >
                  Email Address
                </Typography>
                <Typography variant='body2' sx={{ height: '1.4375em' }}>
                  {currentData?.email || 'Not available'}
                </Typography>
              </Stack>

              <Stack spacing={0}>
                <Typography
                  variant='body1'
                  sx={{ color: 'var(--secondary-font-color)' }}
                >
                  Business Phone
                </Typography>
                <Typography variant='body2' sx={{ height: '1.4375em' }}>
                  {currentData?.businessPhone || 'Not available'}
                </Typography>
              </Stack>

              <Stack spacing={0}>
                <Typography
                  variant='body1'
                  sx={{ color: 'var(--secondary-font-color)' }}
                >
                  Mobile Phone
                </Typography>
                <Typography variant='body2' sx={{ height: '1.4375em' }}>
                  {currentData?.mobilePhone || 'Not available'}
                </Typography>
              </Stack>

              <Stack spacing={0}>
                <Typography
                  variant='body1'
                  sx={{ color: 'var(--secondary-font-color)' }}
                >
                  Business Fax
                </Typography>
                <Typography variant='body2' sx={{ height: '1.4375em' }}>
                  {currentData?.businessFax || 'Not available'}
                </Typography>
              </Stack>

              <Stack spacing={0}>
                <Typography
                  variant='body1'
                  sx={{ color: 'var(--secondary-font-color)' }}
                >
                  Street Address
                </Typography>
                <Typography variant='body2' sx={{ height: '1.4375em' }}>
                  {currentData?.streetAddress ||
                    currentData?.street ||
                    'Not available'}
                </Typography>
              </Stack>

              <Stack spacing={0}>
                <Typography
                  variant='body1'
                  sx={{ color: 'var(--secondary-font-color)' }}
                >
                  City
                </Typography>
                <Typography variant='body2' sx={{ height: '1.4375em' }}>
                  {currentData?.city || 'Not available'}
                </Typography>
              </Stack>

              <Stack spacing={0}>
                <Typography
                  variant='body1'
                  sx={{ color: 'var(--secondary-font-color)' }}
                >
                  State / Province
                </Typography>
                <Typography variant='body2' sx={{ height: '1.4375em' }}>
                  {currentData?.state || 'Not available'}
                </Typography>
              </Stack>

              <Stack spacing={0}>
                <Typography
                  variant='body1'
                  sx={{ color: 'var(--secondary-font-color)' }}
                >
                  Zip / Post Code
                </Typography>
                <Typography variant='body2' sx={{ height: '1.4375em' }}>
                  {currentData?.zip || 'Not available'}
                </Typography>
              </Stack>

              <Stack spacing={0}>
                <Typography
                  variant='body1'
                  sx={{ color: 'var(--secondary-font-color)' }}
                >
                  Country
                </Typography>
                <Typography variant='body2' sx={{ height: '1.4375em' }}>
                  {currentData?.country || 'Not available'}
                </Typography>
              </Stack>

              <Stack spacing={0}>
                <Typography
                  variant='body1'
                  sx={{ color: 'var(--secondary-font-color)' }}
                >
                  Timezone
                </Typography>
                <Typography variant='body2' sx={{ height: '1.4375em' }}>
                  {timezoneLabel || 'Not available'}
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={
            selectedNotification?.type == 'BUYER_EDIT_RETAILER_PROFILE_REQUEST'
              ? {}
              : { display: 'none' }
          }
        >
          <Box
            sx={{
              border: '1px solid var(--secondary-border-color)',
              borderRadius: '6px',
              backgroundColor: 'var(--paper-background-color)',
            }}
          >
            <Typography
              variant='h2'
              sx={{
                fontSize: '16px',
                borderBottom: '1px solid var(--secondary-border-color)',
                padding: '13px 0 13px 20px',
              }}
            >
              Current Retailer Info
            </Typography>

            <Stack spacing={4.6} sx={{ padding: '20px' }}>
              <Stack spacing={0}>
                <Typography
                  variant='body1'
                  sx={{ color: 'var(--secondary-font-color)' }}
                >
                  Legal Name
                </Typography>
                <Typography variant='body2' sx={{ height: '1.4375em' }}>
                  {retailerData?.name || 'Not available'}
                </Typography>
              </Stack>

              <Stack spacing={0}>
                <Typography
                  variant='body1'
                  sx={{ color: 'var(--secondary-font-color)' }}
                >
                  Customer ID / Code
                </Typography>
                <Typography variant='body2' sx={{ height: '1.4375em' }}>
                  {retailerData?.customerId || 'Not available'}
                </Typography>
              </Stack>

              <Stack spacing={0}>
                <Typography
                  variant='body1'
                  sx={{ color: 'var(--secondary-font-color)' }}
                >
                  DBA / Rooftop Name
                </Typography>
                <Typography variant='body2' sx={{ height: '1.4375em' }}>
                  {retailerData?.dba || 'Not available'}
                </Typography>
              </Stack>

              <Stack spacing={0}>
                <Typography
                  variant='body1'
                  sx={{ color: 'var(--secondary-font-color)' }}
                >
                  Vendor Code
                </Typography>
                <Typography variant='body2' sx={{ height: '1.4375em' }}>
                  {retailerData?.vendorCode || 'Not available'}
                </Typography>
              </Stack>

              <Stack spacing={0}>
                <Typography
                  variant='body1'
                  sx={{ color: 'var(--secondary-font-color)' }}
                >
                  Tax ID
                </Typography>
                <Typography variant='body2' sx={{ height: '1.4375em' }}>
                  {retailerData?.taxId || 'Not available'}
                </Typography>
              </Stack>

              <Stack spacing={0}>
                <Typography
                  variant='body1'
                  sx={{ color: 'var(--secondary-font-color)' }}
                >
                  Note
                </Typography>
                <Typography variant='body2' sx={{ height: '1.4375em' }}>
                  {retailerData?.note || 'Not available'}
                </Typography>
              </Stack>

              <Stack spacing={0}>
                <Typography
                  variant='body1'
                  sx={{ color: 'var(--secondary-font-color)' }}
                >
                  Billing Contact
                </Typography>
                <Typography variant='body2' sx={{ height: '1.4375em' }}>
                  {retailerData?.billingContact || 'Not available'}
                </Typography>
              </Stack>

              <Stack spacing={0}>
                <Typography
                  variant='body1'
                  sx={{ color: 'var(--secondary-font-color)' }}
                >
                  Billing ID / Code
                </Typography>
                <Typography variant='body2' sx={{ height: '1.4375em' }}>
                  {retailerData?.billingId || 'Not available'}
                </Typography>
              </Stack>

              <Stack spacing={0}>
                <Typography
                  variant='body1'
                  sx={{ color: 'var(--secondary-font-color)' }}
                >
                  Billing Phone
                </Typography>
                <Typography variant='body2' sx={{ height: '1.4375em' }}>
                  {retailerData?.billingPhone || 'Not available'}
                </Typography>
              </Stack>

              <Stack spacing={0}>
                <Typography
                  variant='body1'
                  sx={{ color: 'var(--secondary-font-color)' }}
                >
                  Address Line 1
                </Typography>
                <Typography variant='body2' sx={{ height: '1.4375em' }}>
                  {retailerData?.address1 || 'Not available'}
                </Typography>
              </Stack>

              <Stack spacing={0}>
                <Typography
                  variant='body1'
                  sx={{ color: 'var(--secondary-font-color)' }}
                >
                  Address Line 2
                </Typography>
                <Typography variant='body2' sx={{ height: '1.4375em' }}>
                  {retailerData?.address2 || 'Not available'}
                </Typography>
              </Stack>
              <Stack spacing={0}>
                <Typography
                  variant='body1'
                  sx={{ color: 'var(--secondary-font-color)' }}
                >
                  City
                </Typography>
                <Typography variant='body2' sx={{ height: '1.4375em' }}>
                  {retailerData?.city || 'Not available'}
                </Typography>
              </Stack>

              <Stack spacing={0}>
                <Typography
                  variant='body1'
                  sx={{ color: 'var(--secondary-font-color)' }}
                >
                  State
                </Typography>
                <Typography variant='body2' sx={{ height: '1.4375em' }}>
                  {retailerData?.state || 'Not available'}
                </Typography>
              </Stack>

              <Stack spacing={0}>
                <Typography
                  variant='body1'
                  sx={{ color: 'var(--secondary-font-color)' }}
                >
                  Country
                </Typography>
                <Typography variant='body2' sx={{ height: '1.4375em' }}>
                  {retailerData?.country || 'Not available'}
                </Typography>
              </Stack>

              <Stack spacing={0}>
                <Typography
                  variant='body1'
                  sx={{ color: 'var(--secondary-font-color)' }}
                >
                  Zip
                </Typography>
                <Typography variant='body2' sx={{ height: '1.4375em' }}>
                  {retailerData?.zip || 'Not available'}
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={
            selectedNotification?.type != 'BUYER_EDIT_RETAILER_PROFILE_REQUEST'
              ? {}
              : { display: 'none' }
          }
        >
          <Box
            sx={{
              border: '1px solid var(--secondary-border-color)',
              borderRadius: '6px',
              backgroundColor: 'var(--paper-background-color)',
            }}
          >
            <Typography
              variant='h2'
              sx={{
                fontSize: '16px',
                borderBottom: '1px solid var(--secondary-border-color)',
                padding: '13px 0 13px 20px',
              }}
            >
              New Buyer Info
            </Typography>

            <Stack spacing={2} sx={{ padding: '20px' }}>
              <Formik
                innerRef={formikRef}
                validateOnMount
                initialValues={{
                  id: formRequestData?.id,
                  name: formRequestData?.name || '',
                  username: formRequestData?.username || '',
                  department: formRequestData?.department || '',
                  company: formRequestData?.company || '',
                  email: formRequestData?.email || '',
                  businessPhone: formRequestData?.businessPhone || '',
                  mobilePhone: formRequestData?.mobilePhone || '',
                  businessFax: formRequestData?.businessFax || '',
                  streetAddress:
                    formRequestData?.streetAddress ||
                    formRequestData?.street ||
                    '',
                  city: formRequestData?.city || '',
                  state: formRequestData?.state || '',
                  zip: formRequestData?.zip || '',
                  country: formRequestData?.country || '',
                  timezone: formRequestData?.timezone || '',
                }}
                onSubmit={handleApproveClick}
              >
                {({ handleSubmit, setFieldError, ...rest }) => (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      id={name}
                      name={name}
                      label='Name'
                      placeholder='Name'
                      value={rest.values.name}
                      onChange={rest.handleChange}
                      error={rest.touched.name && Boolean(rest.errors.name)}
                      helperText={rest.touched.name && rest.errors.name}
                      sx={{
                        marginBottom: '17px',
                        color:
                          currentData[name] != formRequestData[name]
                            ? '#ff0033'
                            : 'inherit',
                      }}
                    />

                    <TextField
                      id={username}
                      name={username}
                      label='Username'
                      placeholder='Username'
                      value={rest.values.username}
                      onChange={rest.handleChange}
                      error={
                        rest.touched.username && Boolean(rest.errors.username)
                      }
                      helperText={rest.touched.username && rest.errors.username}
                      onBlur={(e) => {
                        rest.handleBlur(e);
                        handleCheckBuyerUserName(
                          rest.values.username,
                          setFieldError
                        );
                      }}
                      sx={{
                        marginBottom: '17px',
                        color:
                          currentData[username] != formRequestData[username]
                            ? '#ff0033'
                            : 'inherit',
                      }}
                    />

                    <TextField
                      id={department}
                      name={department}
                      label='Department'
                      placeholder='Department'
                      value={rest.values.department}
                      onChange={rest.handleChange}
                      error={
                        rest.touched.department &&
                        Boolean(rest.errors.department)
                      }
                      helperText={
                        rest.touched.department && rest.errors.department
                      }
                      sx={{
                        marginBottom: '17px',
                        color:
                          currentData[department] != formRequestData[department]
                            ? '#ff0033'
                            : 'inherit',
                      }}
                    />

                    <TextField
                      id={company}
                      name={company}
                      label='Company'
                      placeholder='Company'
                      value={rest.values.company}
                      onChange={rest.handleChange}
                      error={
                        rest.touched.company && Boolean(rest.errors.company)
                      }
                      helperText={rest.touched.company && rest.errors.company}
                      sx={{
                        marginBottom: '17px',
                        color:
                          currentData[company] != formRequestData[company]
                            ? '#ff0033'
                            : 'inherit',
                      }}
                    />

                    <TextField
                      id={email}
                      name={email}
                      label='Email Address'
                      placeholder='Email Address'
                      value={rest.values.email}
                      onChange={rest.handleChange}
                      error={rest.touched.email && Boolean(rest.errors.email)}
                      helperText={rest.touched.email && rest.errors.email}
                      sx={{
                        marginBottom: '17px',
                        color:
                          currentData[email] != formRequestData[email]
                            ? '#ff0033'
                            : 'inherit',
                      }}
                    />

                    <TextField
                      id={businessPhone}
                      name={businessPhone}
                      label='Business Phone'
                      placeholder='Business Phone'
                      value={rest.values.businessPhone}
                      onChange={rest.handleChange}
                      error={
                        rest.touched.businessPhone &&
                        Boolean(rest.errors.businessPhone)
                      }
                      helperText={
                        rest.touched.businessPhone && rest.errors.businessPhone
                      }
                      sx={{
                        marginBottom: '17px',
                        color:
                          currentData[businessPhone] !=
                          formRequestData[businessPhone]
                            ? '#ff0033'
                            : 'inherit',
                      }}
                    />

                    <TextField
                      id={mobilePhone}
                      name={mobilePhone}
                      label='Mobile Phone'
                      placeholder='Mobile Phone'
                      value={rest.values.mobilePhone}
                      onChange={rest.handleChange}
                      error={
                        rest.touched.mobilePhone &&
                        Boolean(rest.errors.mobilePhone)
                      }
                      helperText={
                        rest.touched.mobilePhone && rest.errors.mobilePhone
                      }
                      sx={{
                        marginBottom: '17px',
                        color:
                          currentData[mobilePhone] !=
                          formRequestData[mobilePhone]
                            ? '#ff0033'
                            : 'inherit',
                      }}
                    />

                    <TextField
                      id={businessFax}
                      name={businessFax}
                      label='Business Fax'
                      placeholder='Business Fax'
                      value={rest.values.businessFax}
                      onChange={rest.handleChange}
                      error={
                        rest.touched.businessFax &&
                        Boolean(rest.errors.businessFax)
                      }
                      helperText={
                        rest.touched.businessFax && rest.errors.businessFax
                      }
                      sx={{
                        marginBottom: '17px',
                        color:
                          currentData[businessFax] !=
                          formRequestData[businessFax]
                            ? '#ff0033'
                            : 'inherit',
                      }}
                    />

                    <TextField
                      id={streetAddress}
                      name={streetAddress}
                      label='Street Address'
                      placeholder='Street Addresss'
                      value={rest.values.streetAddress}
                      onChange={rest.handleChange}
                      error={
                        rest.touched.streetAddress &&
                        Boolean(rest.errors.streetAddress)
                      }
                      helperText={
                        rest.touched.streetAddress && rest.errors.streetAddress
                      }
                      sx={{
                        marginBottom: '17px',
                        color:
                          currentData[streetAddress] !=
                          formRequestData[streetAddress]
                            ? '#ff0033'
                            : 'inherit',
                      }}
                    />

                    <TextField
                      id={city}
                      name={city}
                      label='City'
                      placeholder='City'
                      value={rest.values.city}
                      onChange={rest.handleChange}
                      error={rest.touched.city && Boolean(rest.errors.city)}
                      helperText={rest.touched.city && rest.errors.city}
                      sx={{
                        marginBottom: '17px',
                        color:
                          currentData[city] != formRequestData[city]
                            ? '#ff0033'
                            : 'inherit',
                      }}
                    />

                    <TextField
                      id={state}
                      name={state}
                      label='State / Province'
                      placeholder='State / Province'
                      value={rest.values.state}
                      onChange={rest.handleChange}
                      error={rest.touched.state && Boolean(rest.errors.state)}
                      helperText={rest.touched.state && rest.errors.state}
                      sx={{
                        marginBottom: '17px',
                        color:
                          currentData[state] != formRequestData[state]
                            ? '#ff0033'
                            : 'inherit',
                      }}
                    />

                    <TextField
                      id={zip}
                      name={zip}
                      label='Zip / Post Code'
                      placeholder='Zip / Post Code'
                      value={rest.values.zip}
                      onChange={rest.handleChange}
                      error={rest.touched.zip && Boolean(rest.errors.zip)}
                      helperText={rest.touched.zip && rest.errors.zip}
                      sx={{
                        marginBottom: '17px',
                        color:
                          currentData[zip] != formRequestData[zip]
                            ? '#ff0033'
                            : 'inherit',
                      }}
                    />
                    <TextField
                      id='country'
                      name='country'
                      label='Country'
                      placeholder='Enter Country'
                      value={rest?.values?.country}
                      onChange={rest?.handleChange}
                      error={
                        rest?.touched?.country && Boolean(rest?.errors?.country)
                      }
                      helperText={
                        rest?.touched?.country && rest?.errors?.country
                      }
                      sx={{
                        marginBottom: '17px',
                        color:
                          currentData[country] != formRequestData[country]
                            ? '#ff0033'
                            : 'inherit',
                      }}
                    />
                    <Grid spacing={0}>
                      <InputLabel>Timezone</InputLabel>
                      <Autocomplete
                        disablePortal
                        id='timezone'
                        name='timezone'
                        options={user?.timezones ?? []}
                        value={rest?.values?.timezone}
                        onChange={(event, newValue) => {
                          rest?.setFieldValue('timezone', newValue);
                        }}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                          <MuiTextField
                            {...params}
                            placeholder='Select Timezone'
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                fontWeight: '500', // Change font weight for outlined input
                                color:
                                  currentData[timezone] !=
                                  formRequestData.timezone?.value
                                    ? '#ff0033'
                                    : 'inherit',
                              },
                            }}
                          />
                        )}
                        renderOption={(props, option, { selected }) => {
                          return (
                            <Box
                              {...props}
                              display='flex'
                              alignItems='center'
                              component='div'
                              sx={{
                                width: '100%',
                                padding: 1,
                                boxSizing: 'border-box',
                                fontWeight: '500',
                              }}
                            >
                              <Box flexGrow={1}>{option?.label}</Box>
                            </Box>
                          );
                        }}
                      />
                    </Grid>
                  </form>
                )}
              </Formik>
            </Stack>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={
            selectedNotification?.type == 'BUYER_EDIT_RETAILER_PROFILE_REQUEST'
              ? {}
              : { display: 'none' }
          }
        >
          <Box
            sx={{
              border: '1px solid var(--secondary-border-color)',
              borderRadius: '6px',
              backgroundColor: 'var(--paper-background-color)',
            }}
          >
            <Typography
              variant='h2'
              sx={{
                fontSize: '16px',
                borderBottom: '1px solid var(--secondary-border-color)',
                padding: '13px 0 13px 20px',
              }}
            >
              New Retailer Info
            </Typography>

            <Stack spacing={2} sx={{ padding: '20px' }}>
              <Formik
                innerRef={formikRef}
                validateOnMount
                initialValues={
                  selectedNotification?.type ==
                  'BUYER_EDIT_RETAILER_PROFILE_REQUEST'
                    ? {
                        id: formRequestData?.id,
                        legalName: formRequestData?.legalName || '',
                        customerIdOrCode:
                          formRequestData?.customerIdOrCode || '',
                        vendorCode: formRequestData?.vendorCode || '',
                        taxId: formRequestData?.tax_id || '',
                        note: formRequestData?.note || '',
                        dBAOrRooftopName:
                          formRequestData?.dBAOrRooftopName || '',
                        billingContact: formRequestData?.billingContact || '',
                        billingIdOrCode: formRequestData?.billingIdOrCode || '',
                        phone: formRequestData?.phone || '',
                        buyerStreetAddress:
                          formRequestData?.buyerStreetAddress || '',
                        buyerStreetAddress2:
                          formRequestData?.buyerStreetAddress2 || '',
                        buyerCity: formRequestData?.buyerCity || '',
                        buyerCountry: formRequestData?.buyerCountry || '',
                        buyerState: formRequestData?.buyerState || '',
                        buyerZipCode: formRequestData?.buyerZipCode || '',
                        buyerRequestType: selectedNotification?.type,
                        retailerId: retailerData?.id,
                      }
                    : {
                        id: formRequestData?.id,
                        name: formRequestData?.name || '',
                        username: formRequestData?.username || '',
                        department: formRequestData?.department || '',
                        company: formRequestData?.company || '',
                        email: formRequestData?.email || '',
                        businessPhone: formRequestData?.businessPhone || '',
                        mobilePhone: formRequestData?.mobilePhone || '',
                        businessFax: formRequestData?.businessFax || '',
                        streetAddress: formRequestData?.streetAddress || '',
                        city: formRequestData?.city || '',
                        state: formRequestData?.state || '',
                        zip: formRequestData?.zip || '',
                        country: formRequestData?.country || '',
                        // timezone: formRequestData?.timezone || '',
                        timezone: {
                          label:
                            '(GMT -04:00) EDT - Eastern Standard Time New York',
                          value: 'America/New_York',
                        },
                      }
                }
                onSubmit={handleApproveClick}
              >
                {({ handleSubmit, setFieldError, ...rest }) => (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      id={legalName}
                      name={legalName}
                      label='Legal Name'
                      placeholder='Legal Name'
                      value={rest.values.legalName}
                      onChange={rest.handleChange}
                      error={
                        rest.touched.legalName && Boolean(rest.errors.legalName)
                      }
                      helperText={
                        rest.touched.legalName && rest.errors.legalName
                      }
                      sx={{
                        marginBottom: '17px',
                        color:
                          retailerData?.name != formRequestData?.legalName
                            ? '#ff0033'
                            : 'inherit',
                      }}
                    />
                    <TextField
                      id={customerIdOrCode}
                      name={customerIdOrCode}
                      label='Customer ID / Code'
                      placeholder='Customer ID / Code'
                      value={rest.values.customerIdOrCode}
                      onChange={rest.handleChange}
                      error={
                        rest.touched.customerIdOrCode &&
                        Boolean(rest.errors.customerIdOrCode)
                      }
                      helperText={
                        rest.touched.customerIdOrCode &&
                        rest.errors.customerIdOrCode
                      }
                      onBlur={(e) => {
                        rest.handleBlur(e);
                        handleCheckBuyerUserName(
                          rest.values.customerIdOrCode,
                          setFieldError
                        );
                      }}
                      sx={{
                        marginBottom: '17px',
                        color:
                          retailerData?.customerId !=
                          formRequestData?.customerIdOrCode
                            ? '#ff0033'
                            : 'inherit',
                      }}
                    />

                    <TextField
                      id={dBAOrRooftopName}
                      name={dBAOrRooftopName}
                      label='DBA / Rooftop Name'
                      placeholder='DBA / Rooftop Name'
                      value={rest.values.dBAOrRooftopName}
                      onChange={rest.handleChange}
                      error={
                        rest.touched.dBAOrRooftopName &&
                        Boolean(rest.errors.dBAOrRooftopName)
                      }
                      helperText={
                        rest.touched.dBAOrRooftopName &&
                        rest.errors.dBAOrRooftopName
                      }
                      sx={{
                        marginBottom: '17px',
                        color:
                          retailerData?.dba != formRequestData?.dBAOrRooftopName
                            ? '#ff0033'
                            : 'inherit',
                      }}
                    />

                    <TextField
                      id={vendorCode}
                      name={vendorCode}
                      label='Vendor Code'
                      placeholder='Vendor Code'
                      value={rest.values.vendorCode}
                      onChange={rest.handleChange}
                      error={
                        rest.touched.vendorCode &&
                        Boolean(rest.errors.vendorCode)
                      }
                      helperText={
                        rest.touched.vendorCode && rest.errors.vendorCode
                      }
                      sx={{
                        marginBottom: '17px',
                        color:
                          retailerData?.vendorCode !=
                          formRequestData?.vendorCode
                            ? '#ff0033'
                            : 'inherit',
                      }}
                    />

                    <TextField
                      id={taxId}
                      name={taxId}
                      label='Tax Id'
                      placeholder='Tax Id'
                      value={rest.values.taxId}
                      onChange={rest.handleChange}
                      error={rest.touched.taxId && Boolean(rest.errors.taxId)}
                      helperText={rest.touched.taxId && rest.errors.taxId}
                      sx={{
                        marginBottom: '17px',
                        color:
                          retailerData?.taxId != formRequestData?.tax_id
                            ? '#ff0033'
                            : 'inherit',
                      }}
                    />

                    <TextField
                      id={note}
                      name={note}
                      label='Note'
                      placeholder='Note'
                      value={rest.values.note}
                      onChange={rest.handleChange}
                      error={rest.touched.note && Boolean(rest.errors.note)}
                      helperText={rest.touched.note && rest.errors.note}
                      sx={{
                        marginBottom: '17px',
                        color:
                          retailerData?.note != formRequestData?.note
                            ? '#ff0033'
                            : 'inherit',
                      }}
                    />
                    <TextField
                      id={billingContact}
                      name={billingContact}
                      label='Billing Contact'
                      placeholder='Billing Contact'
                      value={rest.values.billingContact}
                      onChange={rest.handleChange}
                      error={
                        rest.touched.billingContact &&
                        Boolean(rest.errors.billingContact)
                      }
                      helperText={
                        rest.touched.billingContact &&
                        rest.errors.billingContact
                      }
                      sx={{
                        marginBottom: '17px',
                        color:
                          retailerData?.billingContact !=
                          formRequestData?.billingContact
                            ? '#ff0033'
                            : 'inherit',
                      }}
                    />
                    <TextField
                      id={billingIdOrCode}
                      name={billingIdOrCode}
                      label='Billing ID / Code'
                      placeholder='Billing ID / Code'
                      value={rest.values.billingIdOrCode}
                      onChange={rest.handleChange}
                      error={
                        rest.touched.billingIdOrCode &&
                        Boolean(rest.errors.billingIdOrCode)
                      }
                      helperText={
                        rest.touched.billingIdOrCode &&
                        rest.errors.billingIdOrCode
                      }
                      sx={{
                        marginBottom: '17px',
                        color:
                          retailerData?.billingId !=
                          formRequestData?.billingIdOrCode
                            ? '#ff0033'
                            : 'inherit',
                      }}
                    />
                    <TextField
                      id={phone}
                      name={phone}
                      label='Billing Phone'
                      placeholder='Billing Phone'
                      value={rest.values.phone}
                      onChange={rest.handleChange}
                      error={rest.touched.phone && Boolean(rest.errors.phone)}
                      helperText={rest.touched.phone && rest.errors.phone}
                      sx={{
                        marginBottom: '17px',
                        color:
                          retailerData?.billingPhone != formRequestData?.phone
                            ? '#ff0033'
                            : 'inherit',
                      }}
                    />
                    <TextField
                      id={buyerStreetAddress}
                      name={buyerStreetAddress}
                      label='Address Line 1'
                      placeholder='Address Line 1'
                      value={rest.values.buyerStreetAddress}
                      onChange={rest.handleChange}
                      error={
                        rest.touched.buyerStreetAddress &&
                        Boolean(rest.errors.buyerStreetAddress)
                      }
                      helperText={
                        rest.touched.buyerStreetAddress &&
                        rest.errors.buyerStreetAddress
                      }
                      sx={{
                        marginBottom: '17px',
                        color:
                          retailerData?.address1 !=
                          formRequestData?.buyerStreetAddress
                            ? '#ff0033'
                            : 'inherit',
                      }}
                    />
                    <TextField
                      id={buyerStreetAddress2}
                      name={buyerStreetAddress2}
                      label='Address Line 2'
                      placeholder='Address Line 2'
                      value={rest.values.buyerStreetAddress2}
                      onChange={rest.handleChange}
                      error={
                        rest.touched.buyerStreetAddress2 &&
                        Boolean(rest.errors.buyerStreetAddress2)
                      }
                      helperText={
                        rest.touched.buyerStreetAddress2 &&
                        rest.errors.buyerStreetAddress2
                      }
                      sx={{
                        marginBottom: '17px',
                        color:
                          retailerData?.address2 !=
                          formRequestData?.buyerStreetAddress2
                            ? '#ff0033'
                            : 'inherit',
                      }}
                    />
                    <TextField
                      id={buyerCity}
                      name={buyerCity}
                      label='City'
                      placeholder='City'
                      value={rest.values.buyerCity}
                      onChange={rest.handleChange}
                      error={
                        rest.touched.buyerCity && Boolean(rest.errors.buyerCity)
                      }
                      helperText={
                        rest.touched.buyerCity && rest.errors.buyerCity
                      }
                      sx={{
                        marginBottom: '17px',
                        color:
                          retailerData?.city != formRequestData?.buyerCity
                            ? '#ff0033'
                            : 'inherit',
                      }}
                    />
                    <TextField
                      id={buyerState}
                      name={buyerState}
                      label='State'
                      placeholder='State'
                      value={rest.values.buyerState}
                      onChange={rest.handleChange}
                      error={
                        rest.touched.buyerState &&
                        Boolean(rest.errors.buyerState)
                      }
                      helperText={
                        rest.touched.buyerState && rest.errors.buyerState
                      }
                      sx={{
                        marginBottom: '17px',
                        color:
                          retailerData?.state != formRequestData?.buyerState
                            ? '#ff0033'
                            : 'inherit',
                      }}
                    />
                    <TextField
                      id={buyerCountry}
                      name={buyerCountry}
                      label='Country'
                      placeholder='Country'
                      value={rest.values.buyerCountry}
                      onChange={rest.handleChange}
                      error={
                        rest.touched.buyerCountry &&
                        Boolean(rest.errors.buyerCountry)
                      }
                      helperText={
                        rest.touched.buyerCountry && rest.errors.buyerCountry
                      }
                      sx={{
                        marginBottom: '17px',
                        color:
                          retailerData?.country != formRequestData?.buyerCountry
                            ? '#ff0033'
                            : 'inherit',
                      }}
                    />
                    <TextField
                      id={buyerZipCode}
                      name={buyerZipCode}
                      label='Zip'
                      placeholder='Zip'
                      value={rest.values.buyerZipCode}
                      onChange={rest.handleChange}
                      error={
                        rest.touched.zip && Boolean(rest.errors.buyerZipCode)
                      }
                      helperText={
                        rest.touched.buyerZipCode && rest.errors.buyerZipCode
                      }
                      sx={{
                        marginBottom: '17px',
                        color:
                          retailerData?.zip != formRequestData?.buyerZipCode
                            ? '#ff0033'
                            : 'inherit',
                      }}
                    />
                  </form>
                )}
              </Formik>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </MyModal>
  );
};

BuyerInfoEditRequestModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  handleApproveClick: PropTypes.func.isRequired,
  declineBtnClick: PropTypes.func.isRequired,
  countryOptions: PropTypes.array.isRequired,
  notificationDetails: PropTypes.object.isRequired,
  handleCheckBuyerUserName: PropTypes.func.isRequired,
  selectedNotification: PropTypes.bool,
  isCustomTitle: PropTypes.bool,
  customTitle: PropTypes.node,
};
BuyerInfoEditRequestModal.defaultProps = {
  isCustomTitle: false,
  customTitle: <></>,
};

export default BuyerInfoEditRequestModal;
