/* eslint-disable react/no-unknown-property */
import React from 'react';
import PropTypes from 'prop-types';

export default function DraftsQuickPanelIcon({ fill, active, width, height }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 34 34'
    >
      <g transform='translate(-50 -30)'>
        <g
          transform='translate(50 30)'
          // eslint-disable-next-line react/no-unknown-property
          fill={active ? 'var(--theme-color)' : 'rgba(0,0,0,0)'}
          stroke={active ? 'var(--theme-color)' : fill}
          strokeWidth='1'
        >
          <circle cx='17' cy='17' r='17' stroke='none' />
          <circle cx='17' cy='17' r='16.5' fill='none' />
        </g>
        <g transform='translate(-1048 -50)'>
          <g transform='translate(1108 90)'>
            <g>
              <path
                d='M13.877,3.182a.547.547,0,0,0-.424-.2H3.547l-.041-.373V2.6A2.992,2.992,0,0,0,.547,0a.547.547,0,0,0,0,1.094A1.9,1.9,0,0,1,2.42,2.732L3.07,8.7A1.643,1.643,0,0,0,2.105,10.2s0,.009,0,.014,0,.009,0,.014a1.642,1.642,0,0,0,1.641,1.641h.223a1.613,1.613,0,1,0,3.055,0H9.383a1.613,1.613,0,1,0,1.527-1.094H3.746a.547.547,0,0,1-.547-.547s0-.009,0-.014,0-.009,0-.014a.547.547,0,0,1,.547-.547h6.982A2.5,2.5,0,0,0,13,8.122a.547.547,0,0,0-1-.44,1.43,1.43,0,0,1-1.268.876H4.155L3.667,4.074H12.78l-.268,1.283a.547.547,0,1,0,1.071.223l.4-1.941A.547.547,0,0,0,13.877,3.182ZM10.91,11.867a.52.52,0,1,1-.52.52A.52.52,0,0,1,10.91,11.867Zm-5.414,0a.52.52,0,1,1-.52.52A.52.52,0,0,1,5.5,11.867Z'
                fill={active ? 'rgba(255,255,255,1)' : fill}
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

DraftsQuickPanelIcon.propTypes = {
  fill: PropTypes.string,
  active: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

DraftsQuickPanelIcon.defaultProps = {
  fill: 'var(--primary-icon-color)',
  active: false,
  width: '34px',
  height: '34px',
};
