import * as Yup from 'yup';

export const advancedFormSchema = Yup.object().shape({
  currency: Yup.string().required('Currency is a required field'),

  priceGroup: Yup.string().required('Price Group is a required field'),

  salesRep: Yup.string().required('Sales Rep is a required field'),

  legalName: Yup.string().required('Legal Name is a required field'),
  buyerName: Yup.string().required('Buyer Name is a required field'),
  username: Yup.string().required('Username is a required field'),
  emailAddress: Yup.string()
    .email('Email Address entered is incorrect')
    .required('Buyer Email is a required field'),
  billingAddressLine1: Yup.string().required(
    'Billing Address 1 is a required field'
  ),

  billingCity: Yup.string().required('City is a required field'),
  billingState: Yup.string().required('State is a required field'),
  billingPostCode: Yup.string().required(
    'Zip / Postal Code is a required field'
  ),
  billingCountry: Yup.string().required('Country is a required field'),
  shippingAddressLine1: Yup.string().required(
    'Shipping Address 1 is a required field'
  ),

  shippingCity: Yup.string().required('City is a required field'),
  shippingState: Yup.string().required('State is a required field'),
  shippingPostCode: Yup.string().required(
    'Zip / Postal Code is a required field'
  ),
  shippingCountry: Yup.string().required('Country is a required field'),
  /*buyerPostCode: Yup.string().required(
    'Zip / Postal Code  is a mandatory field'
  ),*/
  buyerCountry: Yup.string().required('Country is a required field'),
  businessPhone: Yup.string().matches(
    '^[0-9+(0-9) *)#.\\-]*$',
    'Bussiness Phone is an invalid format'
  ),
  businessFax: Yup.string().matches(
    '^[0-9+(0-9) *)#.\\-]*$',
    'Bussiness Fax is an invalid format'
  ),
  mobilePhone: Yup.string().matches(
    '^[0-9+(0-9) *)#.\\-]*$',
    'Mobile Phone is an invalid format'
  ),
  billingPhone: Yup.string().matches(
    '^[0-9+(0-9) *)#.\\-]*$',
    'Billing Phone is an invalid format'
  ),
  password: Yup.string().required('Password is a required field'),
  confirmPassword: Yup.string().required(
    'Confirm Password is a required field'
  ),
});
