import React from 'react';
import PropTypes from 'prop-types';

export default function RemoveIcon({ fill, sx }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='15'
      height='15'
      viewBox='0 0 15 15'
      fill={fill}
      style={sx}
    >
      <g transform='translate(-2 -2)'>
        <path d='M9.5,17A7.5,7.5,0,1,1,17,9.5,7.5,7.5,0,0,1,9.5,17Zm0-13.929A6.429,6.429,0,1,0,15.929,9.5,6.429,6.429,0,0,0,9.5,3.071Z' />
        <path
          d='M18.212,18.748a.536.536,0,0,1-.38-.155l-2.679-2.679a.538.538,0,0,1,.761-.761l2.679,2.679a.536.536,0,0,1-.38.916Z'
          transform='translate(-6.034 -6.034)'
        />
        <path
          d='M10.533,18.748a.536.536,0,0,1-.38-.916l2.679-2.679a.538.538,0,1,1,.761.761l-2.679,2.679A.536.536,0,0,1,10.533,18.748Z'
          transform='translate(-3.712 -6.034)'
        />
        <path
          d='M15.533,13.748a.536.536,0,0,1-.38-.916l2.679-2.679a.538.538,0,1,1,.761.761l-2.679,2.679a.536.536,0,0,1-.38.155Z'
          transform='translate(-6.033 -3.712)'
        />
        <path
          d='M13.212,13.748a.536.536,0,0,1-.38-.155l-2.679-2.679a.538.538,0,0,1,.761-.761l2.679,2.679a.536.536,0,0,1-.38.916Z'
          transform='translate(-3.712 -3.712)'
        />
      </g>
    </svg>
  );
}

RemoveIcon.propTypes = {
  fill: PropTypes.string,
  sx: PropTypes.object,
};

RemoveIcon.defaultProps = {
  fill: '#fff',
  sx: {},
};
