import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Box, CircularProgress, IconButton } from '@mui/material';
import DeleteIcon from 'assets/icons/delete-icon';
import uploadImageBg from 'assets/images/upload_image_bg.png';
import styles from './styles';

function DragAndDropImage({ onFileUpload, uploadImageLoader, onDelete, file }) {
  const [dragging, setDragging] = useState(false);

  const handleFileChange = (file) => {
    if (file) {
      onFileUpload(file);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);

    const file = e.dataTransfer.files[0];
    if (file) {
      handleFileChange(file);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    if (!dragging) setDragging(true);
  };

  const handleDragLeave = () => setDragging(false);

  return (
    <DragDropContext onDragEnd={() => {}}>
      <Droppable droppableId='coverImage'>
        {(provided) => (
          <Box
            ref={provided.innerRef}
            {...provided.droppableProps}
            {...provided.dragHandleProps}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            sx={{
              border: dragging
                ? '2px dashed var(--primary-border-color)'
                : '1px solid var(--primary-border-color)',
              backgroundColor: dragging ? 'rgba(0, 0, 0, 0.04)' : 'transparent',
              background: file?.filepath
                ? 'none'
                : `url(${uploadImageBg}) 50% / cover no-repeat`,
              ...styles.dropContainer,
            }}
          >
            <input
              type='file'
              id='files'
              style={{ display: 'none' }}
              disabled={file?.filepath}
              onClick={(e) => (e.target.value = null)}
              onChange={(e) => {
                handleFileChange(e.target.files[0]);
              }}
              accept='.jpg, .jpeg, .png'
            />
            {uploadImageLoader ? (
              <CircularProgress />
            ) : file?.filepath ? (
              <>
                <Box
                  component='img'
                  src={file?.filepath}
                  alt='Preview'
                  sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  }}
                />
                <IconButton onClick={onDelete} sx={styles.deleteIconButton}>
                  <DeleteIcon />
                </IconButton>
              </>
            ) : null}
            <Box component='label' htmlFor='files' sx={styles.labelBox} />
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
}

DragAndDropImage.propTypes = {
  onFileUpload: PropTypes.func.isRequired,
  uploadImageLoader: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  file: PropTypes.object,
};

export default React.memo(DragAndDropImage);
