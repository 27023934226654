import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import InformationIcon from 'assets/icons/information-icon';

const Alert = ({ children, icon }) => {
  return (
    <Stack
      direction='row'
      sx={{
        backgroundColor: '#FFF2DD',
        padding: '11px 12px',
        alignItems: 'center',
        borderRadius: '4px',
      }}
      spacing={0.8}
    >
      {icon ? (
        icon
      ) : (
        <InformationIcon
          sx={{ height: '12px', width: '12px' }}
          fill='#8D5600'
        />
      )}
      {children}
    </Stack>
  );
};

Alert.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.node,
};

export default Alert;
