/* eslint-disable react/no-unknown-property */
import React from 'react';
import PropTypes from 'prop-types';

export default function HamburgerIconM({
  fill,
  active,
  width,
  height,
  stroke,
}) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='23.639'
      height='16.593'
      viewBox='0 0 23.639 16.593'
    >
      <g
        id='Icon_feather-menu'
        data-name='Icon feather-menu'
        transform='translate(-3.25 -7.75)'
      >
        <path
          id='Path_20735'
          data-name='Path 20735'
          d='M4.5,18H25.639'
          transform='translate(0 -1.954)'
          fill='none'
          stroke='#000'
          stroke-linecap='round'
          stroke-linejoin='round'
          stroke-width='2.5'
        />
        <path
          id='Path_20736'
          data-name='Path 20736'
          d='M4.5,9H25.639'
          fill='none'
          stroke='#000'
          stroke-linecap='round'
          stroke-linejoin='round'
          stroke-width='2.5'
        />
        <path
          id='Path_20737'
          data-name='Path 20737'
          d='M4.5,27H25.639'
          transform='translate(0 -3.907)'
          fill='none'
          stroke='#000'
          stroke-linecap='round'
          stroke-linejoin='round'
          stroke-width='2.5'
        />
      </g>
    </svg>
  );
}

HamburgerIconM.propTypes = {
  fill: PropTypes.string,
  active: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  stroke: PropTypes.string,
};

HamburgerIconM.defaultProps = {
  fill: 'var(--primary-icon-color)',
  active: 'false',
  width: '34px',
  height: '34px',
  stroke: '#292d32',
};
