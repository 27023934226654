import React from 'react';

export default function BuyerEmptyStateIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='76'
      height='76'
      viewBox='0 0 76 76'
    >
      <g transform='translate(-1026 -563)'>
        <circle
          cx='38'
          cy='38'
          r='38'
          transform='translate(1026 563)'
          fill='#f4f4f9'
        />
        <g transform='translate(1026.079 560)' opacity='0.75'>
          <path
            d='M57.068,35.034a8.034,8.034,0,1,0-8.034,8.034A8.035,8.035,0,0,0,57.068,35.034Zm-13.972,0a5.938,5.938,0,1,1,5.938,5.938A5.933,5.933,0,0,1,43.1,35.034Z'
            transform='translate(-11.113)'
            fill='var(--primary-icon-color)'
            stroke='var(--primary-icon-color)'
            strokeWidth='0.3'
          />
          <path
            d='M24.473,90.5a.986.986,0,0,0,.524.14,1.013,1.013,0,0,0,.908-.524,13.823,13.823,0,0,1,24.067,0,1.048,1.048,0,0,0,1.816-1.048,15.951,15.951,0,0,0-27.734,0A1.05,1.05,0,0,0,24.473,90.5Z'
            transform='translate(0 -35.138)'
            fill='var(--primary-icon-color)'
            stroke='var(--primary-icon-color)'
            strokeWidth='0.3'
          />
        </g>
      </g>
    </svg>
  );
}
