import { combineReducers } from 'redux';
import actionLoaderReducer from './api-action-loading/reducer';
import snackbarReducer from 'components/snackbar/reducer';
import appReducer from 'pages/app/reducer';
import layoutReducer from 'pages/layout/reducer';
import userDefaultData from './userDefault-reducer';
import catalogReducer from 'pages/catalog/reducer';
import infoModalReducer from 'components/info-modal/reducer';
import sendEmailReducer from 'pages/send-email/reducer';

const createReducer = () => {
  const combinedAppReducer = combineReducers({
    actionLoaderReducer,
    snackbarReducer,
    appReducer,
    layoutReducer,
    userDefaultData,
    catalogReducer,
    infoModalReducer,
    sendEmailReducer,
  });

  const rootReducer = (state, action) => {
    return combinedAppReducer(state, action);
  };
  return rootReducer;
};

export default createReducer;
