import { React } from 'react';
import NewAccountRequestForm from './form/new-account-request-form';
import AuthPage from '../components/auth-page';
import { useNewAccount } from './new-account-hooks';
import RequestUnderReview from './request-under-review';

const NewAccountRequest = () => {
  const {
    showUnderReviewMessage,
    handleReviewMessage,
    clientPublicInfo,
    responseMessage,
  } = useNewAccount();
  return showUnderReviewMessage ? (
    <RequestUnderReview
      logo={clientPublicInfo?.media?.logo}
      responseMessage={responseMessage}
    />
  ) : (
    <AuthPage
      showWelcomeMessage1
      showBackToLogin
      showRegisterAccount={false}
      height='auto'
      positionAbsolute
    >
      <NewAccountRequestForm handleReviewMessage={handleReviewMessage} />
    </AuthPage>
  );
};

export default NewAccountRequest;
