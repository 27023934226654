import React from 'react';
import PropTypes from 'prop-types';

export default function EditRoundedIcon({ fill, sx }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      style={sx}
    >
      <path
        d='M12 1.5H6C3 1.5 1.5 3 1.5 6V15.75C1.5 16.1625 1.8375 16.5 2.25 16.5H12C15 16.5 16.5 15 16.5 12V6C16.5 3 15 1.5 12 1.5Z'
        stroke={fill}
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.68259 5.88011L5.79009 9.77261C5.64009 9.92261 5.49759 10.2151 5.46759 10.4251L5.25759 11.9101C5.18259 12.4501 5.55759 12.8251 6.09759 12.7501L7.58258 12.5401C7.79258 12.5101 8.08509 12.3676 8.23509 12.2176L12.1276 8.32511C12.7951 7.65761 13.1176 6.87761 12.1276 5.88761C11.1376 4.89011 10.3576 5.20511 9.68259 5.88011Z'
        stroke={fill}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.12744 6.43506C9.45744 7.61256 10.3799 8.54256 11.5649 8.87256'
        stroke={fill}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

EditRoundedIcon.propTypes = {
  fill: PropTypes.string,
  sx: PropTypes.object,
};

EditRoundedIcon.defaultProps = {
  fill: '#000000',
  sx: {},
};
