const styles = {
  customTooltip: {
    [`& .MuiTooltip-tooltip`]: {
      backgroundColor: '#fff',
      color: '#000',
      fontSize: '14px',
      padding: '10px',
      width: '303px',
      margin: 0,
      zIndex: 1501,
    },
    borderRadius: '6px',
  },

  editButtonTooltip: {
    [`& .MuiTooltip-tooltip`]: {
      backgroundColor: '#272727',
      color: '#000',
      fontSize: '14px',
      padding: '10px',
      margin: 0,
      zIndex: 1501,
    },

    borderRadius: '6px',
  },
};

export default styles;
