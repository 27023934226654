/* eslint-disable react/no-unknown-property */
import React from 'react';
import PropTypes from 'prop-types';

export default function SuggestionsQuickPanelIcon({
  fill,
  active,
  width,
  height,
}) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 34 34'
    >
      <g transform='translate(-50 -30)'>
        <g
          transform='translate(50 30)'
          fill={active ? 'var(--theme-color)' : 'rgba(0,0,0,0)'}
          stroke={active ? 'var(--theme-color)' : 'rgba(0,0,0,0.6)'}
          strokeWidth='1'
        >
          <circle cx='17' cy='17' r='17' stroke='none' />
          <circle cx='17' cy='17' r='16.5' fill='none' />
        </g>
        <g transform='translate(-1051 -51)'>
          <g transform='translate(1113 90.001)'>
            <path
              d='M125.816,38.452a4.887,4.887,0,0,0-3.437,8.289,4,4,0,0,1,1.154,2.79V52.1a2.353,2.353,0,1,0,4.706,0V49.532a3.936,3.936,0,0,1,1.136-2.771,4.887,4.887,0,0,0-3.559-8.308Zm-1.2,11.656h2.534v1.106H124.62Zm1.267,3.258a1.269,1.269,0,0,1-1.251-1.066h2.5A1.269,1.269,0,0,1,125.887,53.366ZM128.6,46a5.016,5.016,0,0,0-1.421,3.022h-2.586a5.1,5.1,0,0,0-1.434-3.037,3.779,3.779,0,0,1-1.073-2.68,3.822,3.822,0,0,1,3.746-3.767h.055A3.8,3.8,0,0,1,128.6,46Z'
              transform='translate(-121 -38.452)'
              fill={active ? 'rgba(255,255,255,1)' : fill}
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

SuggestionsQuickPanelIcon.propTypes = {
  fill: PropTypes.string,
  active: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

SuggestionsQuickPanelIcon.defaultProps = {
  fill: 'var(--primary-icon-color)',
  active: 'false',
  width: '34px',
  height: '34px',
};
