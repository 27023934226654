export const buyerFieldsName = {
  name: 'name',
  username: 'username',
  title: 'title',
  status: 'status',
  email: 'email',
  businessPhone: 'businessPhone',
  mobilePhone: 'mobilePhone',
  businessFax: 'businessFax',
  addressLine1: 'addressline1',
  addressLine2: 'addressline2',
  street: 'street',
  city: 'city',
  state: 'state',
  zip: 'zip',
  country: 'country',
  emailEngine: 'emailEngine',
  note: 'note',
};
