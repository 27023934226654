import React from 'react';
import PropTypes from 'prop-types';

export default function ArrowDownIcon({ fill, sx }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='6px'
      height='4.455'
      viewBox='0 0 10 5.455'
      fill={fill}
      style={sx}
    >
      <path
        d='M75.134,4.678,79.679.133a.455.455,0,0,1,.643.643L76.1,5l4.224,4.224a.455.455,0,1,1-.643.643L75.134,5.321a.455.455,0,0,1,0-.643Z'
        transform='translate(0 80.455) rotate(-90)'
      />
    </svg>
  );
}

ArrowDownIcon.propTypes = {
  fill: PropTypes.string,
  sx: PropTypes.object,
};

ArrowDownIcon.defaultProps = {
  fill: 'var(--primary-icon-color)',
  sx: {},
};
