import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectClientOptionsSelector } from '../../../../redux/selectors';

export default function FormTypeSelector({ formType, setFormType }) {
  // Get clientOptions Data
  const clientOptions = useSelector(selectClientOptionsSelector);
  return (
    <ButtonGroup elevation={0} color='secondary' disableElevation>
      {clientOptions.isEditRetailerInfoVisible ? (
        <Button
          onClick={(e) => {
            e.preventDefault();
            setFormType('QUICK_DETAILS');
          }}
          variant={formType === 'QUICK_DETAILS' ? 'contained' : 'outlined'}
          color={formType === 'QUICK_DETAILS' ? 'primary' : 'secondary'}
        >
          Quick Details
        </Button>
      ) : null}

      <Button
        onClick={(e) => {
          e.preventDefault();
          setFormType('ADVANCED_DETAILS');
        }}
        variant={formType === 'ADVANCED_DETAILS' ? 'contained' : 'outlined'}
        color={formType === 'ADVANCED_DETAILS' ? 'primary' : 'secondary'}
        disableElevation
      >
        Advanced Details
      </Button>
    </ButtonGroup>
  );
}

FormTypeSelector.propTypes = {
  formType: PropTypes.string.isRequired,
  setFormType: PropTypes.func.isRequired,
};
