import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Grid, Table, TableRow, TableCell } from '@mui/material';
import { propTypes } from 'cleave.js/react';

export default function PriceTier({ priceTiers, userCurrencySymbol }) {
  return (
    <Grid item sx={12} sm={12}>
      <Table size='small' sx={{ border: 'solid 1px #e3e7ed' }} width='auto'>
        <TableRow>
          <TableCell variant='head'>Tier</TableCell>
          {priceTiers?.map((tier) => (
            <TableCell style={{ textAlign: 'center' }} key={tier?.pt_name}>
              {tier?.pt_name}
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          <TableCell variant='head'>Minimun</TableCell>
          {priceTiers?.map((quantity) => (
            <TableCell
              style={{ textAlign: 'center' }}
              key={quantity?.min_quantity}
            >
              {quantity?.min_quantity}
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          <TableCell variant='head'>Price</TableCell>
          {priceTiers.map((price) => (
            <TableCell style={{ textAlign: 'center' }} key={price?.price}>
              {userCurrencySymbol} {price?.price}
            </TableCell>
          ))}
        </TableRow>
      </Table>
    </Grid>
  );
}

PriceTier.propTypes = {
  priceTiers: PropTypes.array.isRequired,
  userCurrencySymbol: PropTypes.string,
};
PriceTier.defaultProps = {
  priceTiers: [],
  userCurrencySymbol: '$',
};
