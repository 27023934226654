/* eslint-disable react/no-unknown-property */
import React from 'react';
import PropTypes from 'prop-types';

export default function RelaxIcon({ fill, active, width, height, stroke }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='243.77'
      height='181.886'
      viewBox='0 0 243.77 181.886'
    >
      <g
        id='Digital_nomad-rafiki'
        data-name='Digital nomad-rafiki'
        transform='translate(0 -55)'
      >
        <g
          id='freepik--background-complete--inject-3'
          transform='translate(0 55)'
        >
          <rect
            id='Rectangle_4734'
            data-name='Rectangle 4734'
            width='243.77'
            height='0.122'
            transform='translate(0 159.62)'
            fill='#ebebeb'
          />
          <rect
            id='Rectangle_4735'
            data-name='Rectangle 4735'
            width='16.147'
            height='0.122'
            transform='translate(50.967 162.94)'
            fill='#ebebeb'
          />
          <rect
            id='Rectangle_4736'
            data-name='Rectangle 4736'
            width='19.867'
            height='0.122'
            transform='translate(83.437 167.406)'
            fill='#ebebeb'
          />
          <rect
            id='Rectangle_4737'
            data-name='Rectangle 4737'
            width='24.903'
            height='0.122'
            transform='translate(25.576 165.88)'
            fill='#ebebeb'
          />
          <rect
            id='Rectangle_4738'
            data-name='Rectangle 4738'
            width='45.409'
            height='0.122'
            transform='translate(173.934 163.76)'
            fill='#ebebeb'
          />
          <rect
            id='Rectangle_4739'
            data-name='Rectangle 4739'
            width='3.086'
            height='0.122'
            transform='translate(166.509 163.76)'
            fill='#ebebeb'
          />
          <rect
            id='Rectangle_4740'
            data-name='Rectangle 4740'
            width='20.589'
            height='0.122'
            transform='translate(135.151 165.817)'
            fill='#ebebeb'
          />
          <path
            id='Path_21077'
            data-name='Path 21077'
            d='M135.128,192.876H40.989a2.784,2.784,0,0,1-2.779-2.784V57.759A2.784,2.784,0,0,1,40.989,55h94.139a2.784,2.784,0,0,1,2.784,2.784V190.092A2.784,2.784,0,0,1,135.128,192.876ZM40.989,55.1a2.662,2.662,0,0,0-2.657,2.662V190.092a2.662,2.662,0,0,0,2.657,2.662h94.139a2.667,2.667,0,0,0,2.662-2.662V57.759a2.667,2.667,0,0,0-2.662-2.662Z'
            transform='translate(-19.581 -55)'
            fill='#ebebeb'
          />
          <path
            id='Path_21078'
            data-name='Path 21078'
            d='M351.428,192.876H257.284a2.789,2.789,0,0,1-2.784-2.784V57.759A2.789,2.789,0,0,1,257.284,55h94.144a2.784,2.784,0,0,1,2.774,2.759V190.092A2.784,2.784,0,0,1,351.428,192.876ZM257.284,55.1a2.667,2.667,0,0,0-2.662,2.662V190.092a2.667,2.667,0,0,0,2.662,2.662h94.144a2.667,2.667,0,0,0,2.662-2.662V57.759a2.667,2.667,0,0,0-2.662-2.662Z'
            transform='translate(-130.421 -55)'
            fill='#ebebeb'
          />
          <circle
            id='Ellipse_61'
            data-name='Ellipse 61'
            cx='19.316'
            cy='19.316'
            r='19.316'
            transform='matrix(0.984, -0.178, 0.178, 0.984, 81.743, 43.765)'
            fill='#fafafa'
          />
          <path
            id='Path_21079'
            data-name='Path 21079'
            d='M44.4,256.024H244.15s-10.116-12.037-25.586-14.236-42.236-3.549-42.236-3.549-4.164-18.429-22.017-19.272c-2.379-8.961-9.917-49.875-28.365-56.3S108.073,142.232,92.4,136.328s-34.059,38.764-37.687,51.245C45.985,217.479,44.4,256.024,44.4,256.024Z'
            transform='translate(-22.753 -96.403)'
            fill='#f0f0f0'
          />
          <path
            id='Path_21080'
            data-name='Path 21080'
            d='M64.93,227.8a134.242,134.242,0,0,1,4.607-12.637,16.576,16.576,0,0,1,1.624-.653c11.491-3.9,27.643,45.663,55.136,20.618,17.254-15.718,27.244-6.031,32.836,3.267,1.726,5.68,3.032,10.994,3.988,15.036-9.449,0-16.493-20.418-27.171-14.694-14.3,7.669-38.564,15.6-50.295-.351-8.157-11.121-16.81-10.136-21.315-8.508C64.535,229.174,64.73,228.477,64.93,227.8Z'
            transform='translate(-32.972 -136.629)'
            fill='#ebebeb'
          />
          <path
            id='Path_21081'
            data-name='Path 21081'
            d='M55.019,273.79c16.879,1.5,21.374,49.081,80.39,33.25,21.652-5.811,46.063,7.479,61.016,19.7H168.786c-8.288-8.288-17.03-16.957-26.849-14.573-32.075,7.776-54.97,7.23-67.6-10.906a29.7,29.7,0,0,0-21.915-12.9C53.151,283.609,54.009,278.69,55.019,273.79Z'
            transform='translate(-26.863 -167.121)'
            fill='#ebebeb'
          />
          <path
            id='Path_21082'
            data-name='Path 21082'
            d='M360.1,77.925H294.77s.395-4.968,8.956-6.45c-7.313-3.228,8.722-10.029,26.113-.614-1.355-3.135,14.109-2,18.839,2.267C350.364,70.339,365.112,75.22,360.1,77.925Z'
            transform='translate(-151.058 -60.671)'
            fill='#f5f5f5'
          />
          <path
            id='Path_21083'
            data-name='Path 21083'
            d='M141.283,97.349H104.771s-8.556-1.711-7.454-6.245,14.036-.756,14.036-.756-3.9-6.338,8.6-4.529,13.729,8.064,13.729,8.064S150.127,92.361,141.283,97.349Z'
            transform='translate(-49.821 -70.627)'
            fill='#f5f5f5'
          />
          <path
            id='Path_21084'
            data-name='Path 21084'
            d='M403.127,237.294H384.6s-4.344-.868-3.783-3.164,7.123-.385,7.123-.385-1.989-3.208,4.363-2.3,6.972,4.09,6.972,4.09S407.617,234.764,403.127,237.294Z'
            transform='translate(-195.128 -145.339)'
            fill='#f5f5f5'
          />
        </g>
        <g id='freepik--Shadow--inject-3' transform='translate(27.356 225.6)'>
          <ellipse
            id='freepik--path--inject-3'
            cx='94.529'
            cy='5.519'
            rx='94.529'
            ry='5.519'
            fill='#f5f5f5'
          />
        </g>
        <g
          id='freepik--beach-ball--inject-3'
          transform='translate(30.677 169.633)'
        >
          <circle
            id='Ellipse_62'
            data-name='Ellipse 62'
            cx='26.093'
            cy='26.093'
            r='26.093'
            transform='matrix(1, -0.027, 0.027, 1, 6.674, 8.231)'
            fill='#f02711'
          />
          <ellipse
            id='Ellipse_63'
            data-name='Ellipse 63'
            cx='26.093'
            cy='21.349'
            rx='26.093'
            ry='21.349'
            transform='matrix(0.687, -0.726, 0.726, 0.687, 0, 37.911)'
            fill='#fff'
            opacity='0.5'
          />
          <ellipse
            id='Ellipse_64'
            data-name='Ellipse 64'
            cx='26.093'
            cy='14.026'
            rx='26.093'
            ry='14.026'
            transform='matrix(0.687, -0.726, 0.726, 0.687, 5.32, 42.943)'
            fill='#f02711'
          />
          <ellipse
            id='Ellipse_65'
            data-name='Ellipse 65'
            cx='26.093'
            cy='5.992'
            rx='26.093'
            ry='5.992'
            transform='matrix(0.687, -0.726, 0.726, 0.687, 11.156, 48.464)'
            fill='#fff'
            opacity='0.5'
          />
        </g>
        <g
          id='freepik--Character--inject-3'
          transform='translate(64.692 134.071)'
        >
          <path
            id='Path_21085'
            data-name='Path 21085'
            d='M305.514,330.866a1.3,1.3,0,0,1-.853-.317l-85.646-73.131a1.316,1.316,0,0,1,1.706-2l85.646,73.136a1.311,1.311,0,0,1-.853,2.311Z'
            transform='translate(-176.721 -236.642)'
            fill='#f02711'
          />
          <path
            id='Path_21086'
            data-name='Path 21086'
            d='M280.352,364.643a1.336,1.336,0,0,1-.819-.283,1.312,1.312,0,0,1-.488-1.287l8.2-40.953a1.312,1.312,0,0,1,2.574.512l-7.693,38.418L326.809,340.6a1.311,1.311,0,1,1,1.092,2.384l-47.023,21.52A1.341,1.341,0,0,1,280.352,364.643Z'
            transform='translate(-207.679 -270.418)'
            fill='#f02711'
          />
          <path
            id='Path_21087'
            data-name='Path 21087'
            d='M177.159,257.7H135.27c10.848,18.551,40.953,57.042,70.8,57.042h36.517C219,314.723,188.007,276.251,177.159,257.7Z'
            transform='translate(-134.012 -237.947)'
            fill='#f02711'
          />
          <path
            id='Path_21088'
            data-name='Path 21088'
            d='M177.159,257.7H135.27c10.848,18.551,40.953,57.042,70.8,57.042h36.517C219,314.723,188.007,276.251,177.159,257.7Z'
            transform='translate(-134.012 -237.947)'
            opacity='0.2'
          />
          <path
            id='Path_21089'
            data-name='Path 21089'
            d='M232.074,247.309l.3-.317.341-.375c.229-.249.463-.488.687-.77.453-.531.9-1.063,1.336-1.609a41.147,41.147,0,0,0,2.413-3.384c.371-.58.717-1.17,1.038-1.76s.629-1.19.9-1.794a20.387,20.387,0,0,0,1.268-3.661l.795,2.725a6.6,6.6,0,0,0-1.638-1.424,26.718,26.718,0,0,0-2.506-1.463c-.892-.463-1.823-.907-2.774-1.341l-2.862-1.287-.068-.034a1.8,1.8,0,0,1,.8-3.447,30.225,30.225,0,0,1,3.413.346,33.708,33.708,0,0,1,3.384.712,23.888,23.888,0,0,1,3.413,1.19,11.867,11.867,0,0,1,3.6,2.369,2.925,2.925,0,0,1,.834,2.438l-.039.278a23.615,23.615,0,0,1-1.292,5.163,30.271,30.271,0,0,1-2.194,4.646,36.679,36.679,0,0,1-2.8,4.173c-.517.658-1.038,1.307-1.6,1.95-.278.312-.561.624-.853.931l-.453.458-.488.488a3.584,3.584,0,1,1-5.1-5.031Z'
            transform='translate(-182.682 -222.403)'
            fill='#ffc3bd'
          />
          <path
            id='Path_21090'
            data-name='Path 21090'
            d='M230.672,227.007l-4.193-.517-1.389,5.056s4.232,1.087,6.065-1.648Z'
            transform='translate(-180.041 -221.953)'
            fill='#ffc3bd'
          />
          <path
            id='Path_21091'
            data-name='Path 21091'
            d='M220.661,226.43l-.541,4.237,2.423.848,1.389-5.056Z'
            transform='translate(-177.495 -221.922)'
            fill='#ffc3bd'
          />
          <path
            id='Path_21092'
            data-name='Path 21092'
            d='M223.445,307.117s-11.345-8.849-20.964-25.893a7.6,7.6,0,0,1,3.9-10.828c3.622-1.385,7.523-2.7,10.7-3.71a7.586,7.586,0,0,1,8.61,2.969c3.93,5.85,7.8,14.1,19.862,23.982a1.258,1.258,0,0,1-.21,2.091Z'
            transform='translate(-167.954 -242.364)'
            fill='#f02711'
          />
          <path
            id='Path_21093'
            data-name='Path 21093'
            d='M223.445,307.117s-11.345-8.849-20.964-25.893a7.6,7.6,0,0,1,3.9-10.828c3.622-1.385,7.523-2.7,10.7-3.71a7.586,7.586,0,0,1,8.61,2.969c3.93,5.85,7.8,14.1,19.862,23.982a1.258,1.258,0,0,1-.21,2.091Z'
            transform='translate(-167.954 -242.364)'
            fill='#fff'
            opacity='0.8'
          />
          <path
            id='Path_21094'
            data-name='Path 21094'
            d='M201.73,288.975c1.424-4.461,2.652-3.115,3.213-2.672,4.354,3.462,6.616,10.931,6.187,17.693a104.429,104.429,0,0,1-8.59-12.886,7.313,7.313,0,0,1-.809-2.135Z'
            transform='translate(-168.07 -252.329)'
            opacity='0.2'
          />
          <path
            id='Path_21095'
            data-name='Path 21095'
            d='M203.62,250.8c2.267,2.9,5.729,8.342,4.627,11.326a12.315,12.315,0,0,0,9.263.151c3.817-1.37,2.535-2.662.834-3.618-3.959.57-5.363-2.462-6.031-5.148Z'
            transform='translate(-169.039 -234.411)'
            fill='#ffc3bd'
          />
          <path
            id='Path_21096'
            data-name='Path 21096'
            d='M216.365,254.821l-4.875-1.511a13.325,13.325,0,0,0,1.321,3.749c1.034,1.58,3.793,2.438,5.85,2.033A7.873,7.873,0,0,1,216.365,254.821Z'
            transform='translate(-173.072 -235.697)'
            opacity='0.2'
          />
          <path
            id='Path_21097'
            data-name='Path 21097'
            d='M223.647,227.82c1.765,1.419,2.584,4.656,1.95,6.908-3.193-5.036-3.237-6.523-3.237-6.523Z'
            transform='translate(-178.642 -222.635)'
            fill='#263238'
          />
          <path
            id='Path_21098'
            data-name='Path 21098'
            d='M202.343,234.085c1.131,4.583,1.56,7.313,4.344,9.366a6.084,6.084,0,0,0,9.819-4.52c.239-4.281-1.648-11.048-6.538-12.286A6.091,6.091,0,0,0,202.343,234.085Z'
            transform='translate(-168.284 -221.918)'
            fill='#ffc3bd'
          />
          <path
            id='Path_21099'
            data-name='Path 21099'
            d='M196.91,233.569a9.434,9.434,0,0,0,1.077-7.279c2.925-.746,11.979-1.272,9.5-6.138s-5.85-2.677-5.914-.936c-5.548-1.609-9.263.234-7.752,1.95-5.714,2.067-3.705,6.7-2.438,7.259-1.979,2.36-1.107,7.635,1.984,7.752s4.427,2.477,6.172,1.8C199.212,236.187,196.91,233.569,196.91,233.569Z'
            transform='translate(-162.051 -217.185)'
            fill='#263238'
          />
          <path
            id='Path_21100'
            data-name='Path 21100'
            d='M216.38,242.454c.054.356.278.614.512.58s.375-.346.327-.7-.278-.614-.512-.58S216.331,242.1,216.38,242.454Z'
            transform='translate(-175.573 -229.775)'
            fill='#263238'
          />
          <path
            id='Path_21101'
            data-name='Path 21101'
            d='M225.3,240.717c.049.356.278.614.488.58s.38-.346.332-.7-.263-.6-.483-.6S225.246,240.366,225.3,240.717Z'
            transform='translate(-180.144 -228.877)'
            fill='#263238'
          />
          <path
            id='Path_21102'
            data-name='Path 21102'
            d='M199.177,249.1a4.276,4.276,0,0,0,2.706,1.55c1.4.19,1.853-1.087,1.243-2.291-.541-1.087-1.95-2.491-3.232-2.131A1.737,1.737,0,0,0,199.177,249.1Z'
            transform='translate(-166.521 -232.039)'
            fill='#ffc3bd'
          />
          <path
            id='Path_21103'
            data-name='Path 21103'
            d='M222.43,242.28a14.545,14.545,0,0,0,2.4,2.974,2.154,2.154,0,0,1-1.75.575Z'
            transform='translate(-178.678 -230.045)'
            fill='#ed847e'
          />
          <path
            id='Path_21104'
            data-name='Path 21104'
            d='M217.479,252.237a3.349,3.349,0,0,1-2.365-.975.107.107,0,0,1,0-.156.117.117,0,0,1,.161,0,3.242,3.242,0,0,0,2.662.843.117.117,0,0,1,.132.093.107.107,0,0,1-.093.127A3.5,3.5,0,0,1,217.479,252.237Z'
            transform='translate(-174.912 -234.552)'
            fill='#263238'
          />
          <path
            id='Path_21105'
            data-name='Path 21105'
            d='M210.8,238.912a.259.259,0,0,1-.112-.029.229.229,0,0,1-.1-.307,2.116,2.116,0,0,1,1.648-1.146.234.234,0,0,1,.249.21.21.21,0,0,1-.2.234h0A1.677,1.677,0,0,0,211,238.8.224.224,0,0,1,210.8,238.912Z'
            transform='translate(-172.6 -227.559)'
            fill='#263238'
          />
          <path
            id='Path_21106'
            data-name='Path 21106'
            d='M226.181,236.956a.244.244,0,0,1-.21-.063,1.843,1.843,0,0,0-1.555-.561.244.244,0,0,1-.273-.18.21.21,0,0,1,.166-.258,2.345,2.345,0,0,1,1.95.687.229.229,0,0,1,0,.317A.278.278,0,0,1,226.181,236.956Z'
            transform='translate(-179.553 -226.764)'
            fill='#263238'
          />
          <path
            id='Path_21107'
            data-name='Path 21107'
            d='M376.247,390.3l5.543-1.019L368.041,376.62l-3.861,5.543Z'
            transform='translate(-251.32 -298.889)'
            fill='#ffc3bd'
          />
          <path
            id='Path_21108'
            data-name='Path 21108'
            d='M387.036,403.554c-.439-1.268-.1-2.979-1.346-4.934,0,0,2.925-2.959,4.144-1.658s5,2.638,9.6,1.584c.644-.146.839,1.78-.609,2.277-2.394.819-4.164,1.389-6.206,2.092a32.6,32.6,0,0,0-3.413,1.706C388.079,405.1,387.479,404.826,387.036,403.554Z'
            transform='translate(-262.343 -309.144)'
            fill='#ffc3bd'
          />
          <path
            id='Path_21109'
            data-name='Path 21109'
            d='M263.423,331.92s18.141,2.438,28.8,6.192c7.074,2.482,20.477,19.116,20.477,19.116l-5.421,5.446s-13.451-8.888-23.319-13.695c-17.273,3.413-34.781,4.544-37.448-8.23C250.493,338.1,263.423,331.92,263.423,331.92Z'
            transform='translate(-191.018 -275.982)'
            fill='#263238'
          />
          <path
            id='Path_21110'
            data-name='Path 21110'
            d='M332.762,344.17,330.9,357.129c3,1.6,6.133,3.413,8.99,5.124Z'
            transform='translate(-234.265 -282.26)'
            opacity='0.2'
          />
          <path
            id='Path_21111'
            data-name='Path 21111'
            d='M376.938,381.287l-7.81,7.684-2.638-2.516,8.03-8.205Z'
            transform='translate(-252.503 -299.724)'
            fill='#f02711'
          />
          <path
            id='Path_21112'
            data-name='Path 21112'
            d='M274.443,319.563s20.564-6.616,31.2-2.876c7.074,2.482.541,26.059.541,26.059l-9.634,1.18s-1.068-9.4-1.95-14.192c-8.742,3.657-34.406,11.408-37.053-1.365C261.514,325.745,274.443,319.563,274.443,319.563Z'
            transform='translate(-196.676 -267.589)'
            fill='#263238'
          />
          <path
            id='Path_21113'
            data-name='Path 21113'
            d='M342.77,384.292l4.593.2-.385-15.377-6.738.488Z'
            transform='translate(-239.051 -295.046)'
            fill='#ffc3bd'
          />
          <path
            id='Path_21114'
            data-name='Path 21114'
            d='M341.846,401.105c.385-1.287,1.663-2.472,1.8-4.788,0,0,4.115-.673,4.329,1.092s2.506,5.066,6.855,6.913c.6.258-.366,1.95-1.828,1.487-2.438-.736-4.188-1.316-6.255-1.95a32.757,32.757,0,0,0-3.764-.619C341.8,402.968,341.47,402.4,341.846,401.105Z'
            transform='translate(-239.799 -308.919)'
            fill='#ffc3bd'
          />
          <path
            id='Path_21115'
            data-name='Path 21115'
            d='M347.211,370.131l-10.823,1.692-.678-3.583,11.306-1.989Z'
            transform='translate(-236.73 -293.575)'
            fill='#f02711'
          />
          <path
            id='Path_21116'
            data-name='Path 21116'
            d='M208.155,285.185c.278,3.174.551,6.435.907,9.614.166,1.589.38,3.169.6,4.714a33.636,33.636,0,0,0,.892,4.3,7.6,7.6,0,0,0,.254.722c.029.078.059.132.034.1s0,.024-.083-.083l-.107-.107-.078-.073-.083-.058-.049-.029a.534.534,0,0,0-.073-.044c-.073-.029-.034,0,.034.039a2.184,2.184,0,0,0,.3.127,7.969,7.969,0,0,0,.9.273c.668.166,1.414.3,2.174.419s1.541.234,2.33.332c3.154.395,6.436.653,9.678.975h.034a1.794,1.794,0,0,1,.117,3.549,71.669,71.669,0,0,1-10.092.975,35.009,35.009,0,0,1-5.329-.2,15.362,15.362,0,0,1-1.516-.268c-.273-.068-.556-.146-.873-.258a5.946,5.946,0,0,1-1.082-.488c-.093-.054-.214-.127-.331-.21l-.176-.122-.171-.137-.2-.176a3.042,3.042,0,0,1-.215-.219,4.068,4.068,0,0,1-.273-.327,7.242,7.242,0,0,1-.624-.941,13.1,13.1,0,0,1-.717-1.546,23.506,23.506,0,0,1-.873-2.716c-.229-.873-.424-1.726-.6-2.579-.366-1.7-.624-3.384-.878-5.061-.488-3.364-.8-6.665-.975-10.068a3.588,3.588,0,0,1,7.157-.488Z'
            transform='translate(-167.704 -250.338)'
            fill='#ffc3bd'
          />
          <path
            id='Path_21117'
            data-name='Path 21117'
            d='M286.291,316l10.194,4.7c.629.288,1.073,1.2.648,1.341l-29.023,9.068a1.765,1.765,0,0,1-1.346-.317l-8.537-5.129c-.609-.366-.975-1.2-.551-1.321Z'
            transform='translate(-196.647 -267.824)'
            fill='#f02711'
          />
          <path
            id='Path_21118'
            data-name='Path 21118'
            d='M286.291,316l10.194,4.7c.629.288,1.073,1.2.648,1.341l-29.023,9.068a1.765,1.765,0,0,1-1.346-.317l-8.537-5.129c-.609-.366-.975-1.2-.551-1.321Z'
            transform='translate(-196.647 -267.824)'
            opacity='0.4'
          />
          <rect
            id='Rectangle_4741'
            data-name='Rectangle 4741'
            width='2.428'
            height='0.527'
            transform='translate(96.105 33.073) rotate(-16.87)'
            fill='#f02711'
          />
          <rect
            id='Rectangle_4742'
            data-name='Rectangle 4742'
            width='9.107'
            height='0.527'
            transform='translate(84.825 36.482) rotate(-16.87)'
            fill='#f02711'
          />
          <path
            id='Path_21119'
            data-name='Path 21119'
            d='M308.245,285.313l-29.91,9.088a1.063,1.063,0,0,0-.717.887l-1.053,19.092a.458.458,0,0,0,.639.488l29.915-9.058a1.073,1.073,0,0,0,.717-.892l1.053-19.087a.458.458,0,0,0-.644-.517Z'
            transform='translate(-206.418 -252.075)'
            fill='#f02711'
          />
          <path
            id='Path_21120'
            data-name='Path 21120'
            d='M277.618,304.827l-1.053,19.092a.458.458,0,0,0,.639.488l1.131-20.477A1.063,1.063,0,0,0,277.618,304.827Z'
            transform='translate(-206.418 -261.638)'
            opacity='0.4'
          />
          <path
            id='Path_21121'
            data-name='Path 21121'
            d='M249,331.06l4.1-.8-.215,5.363s-4.636.4-5.363-1.731l.4-1.794A1.36,1.36,0,0,1,249,331.06Z'
            transform='translate(-191.536 -275.131)'
            fill='#ffc3bd'
          />
          <path
            id='Path_21122'
            data-name='Path 21122'
            d='M261.8,330.6l1.531,3.978-4.812,1.043.215-5.363Z'
            transform='translate(-197.173 -275.131)'
            fill='#ffc3bd'
          />
          <path
            id='Path_21123'
            data-name='Path 21123'
            d='M219.589,330.866a1.287,1.287,0,0,1-.848-.317l-85.646-73.131a1.316,1.316,0,0,1,1.706-2l85.641,73.136a1.312,1.312,0,0,1-.853,2.311Z'
            transform='translate(-132.69 -236.642)'
            fill='#f02711'
          />
          <path
            id='Path_21124'
            data-name='Path 21124'
            d='M219.589,330.866a1.287,1.287,0,0,1-.848-.317l-85.646-73.131a1.316,1.316,0,0,1,1.706-2l85.641,73.136a1.312,1.312,0,0,1-.853,2.311Z'
            transform='translate(-132.69 -236.642)'
            fill='#fff'
            opacity='0.3'
          />
          <path
            id='Path_21125'
            data-name='Path 21125'
            d='M194.427,364.643a1.331,1.331,0,0,1-.814-.283,1.316,1.316,0,0,1-.488-1.287l8.2-40.953a1.312,1.312,0,0,1,2.574.512l-7.693,38.418L240.895,340.6a1.311,1.311,0,1,1,1.092,2.384l-47.028,21.52a1.341,1.341,0,0,1-.531.137Z'
            transform='translate(-163.649 -270.418)'
            fill='#f02711'
          />
          <path
            id='Path_21126'
            data-name='Path 21126'
            d='M194.427,364.643a1.331,1.331,0,0,1-.814-.283,1.316,1.316,0,0,1-.488-1.287l8.2-40.953a1.312,1.312,0,0,1,2.574.512l-7.693,38.418L240.895,340.6a1.311,1.311,0,1,1,1.092,2.384l-47.028,21.52a1.341,1.341,0,0,1-.531.137Z'
            transform='translate(-163.649 -270.418)'
            fill='#fff'
            opacity='0.3'
          />
        </g>
        <g
          id='freepik--E-mails--inject-3'
          transform='translate(132.843 87.213)'
        >
          <path
            id='Path_21127'
            data-name='Path 21127'
            d='M300.522,187.421a.244.244,0,0,1-.219-.132l-5.49-10.726a28.038,28.038,0,1,1,11.408,0l-5.485,10.726A.234.234,0,0,1,300.522,187.421Zm0-65.818a27.546,27.546,0,0,0-5.5,54.526.244.244,0,0,1,.166.127l5.339,10.385,5.319-10.394a.253.253,0,0,1,.171-.127,27.546,27.546,0,0,0-5.49-54.541Z'
            transform='translate(-272.477 -121.073)'
            fill='#f02711'
          />
          <path
            id='Path_21128'
            data-name='Path 21128'
            d='M286.715,170.853l3.364,13.841a2.106,2.106,0,0,0,2.545,1.545l31.49-7.659a2.116,2.116,0,0,0,1.55-2.545L322.3,162.2a2.16,2.16,0,0,0-.336-.726,2.111,2.111,0,0,0-2.209-.824l-31.471,7.659a2.121,2.121,0,0,0-1.609,1.745A2.023,2.023,0,0,0,286.715,170.853Z'
            transform='translate(-279.742 -141.324)'
            fill='#f02711'
          />
          <path
            id='Path_21129'
            data-name='Path 21129'
            d='M286.7,170.053l22.154,9.18,13.134-17.761a2.111,2.111,0,0,0-2.209-.824l-31.471,7.659A2.121,2.121,0,0,0,286.7,170.053Z'
            transform='translate(-279.766 -141.324)'
            opacity='0.1'
          />
          <path
            id='Path_21130'
            data-name='Path 21130'
            d='M286.7,170.074l22.427,7.313,12.852-15.874a2.111,2.111,0,0,0-2.209-.824l-31.461,7.64A2.121,2.121,0,0,0,286.7,170.074Z'
            transform='translate(-279.766 -141.345)'
            fill='#f02711'
          />
          <path
            id='Path_21131'
            data-name='Path 21131'
            d='M286.7,170.074l22.427,7.313,12.852-15.874a2.111,2.111,0,0,0-2.209-.824l-31.461,7.64A2.121,2.121,0,0,0,286.7,170.074Z'
            transform='translate(-279.766 -141.345)'
            fill='#fff'
            opacity='0.1'
          />
          <path
            id='Path_21132'
            data-name='Path 21132'
            d='M334.481,170.435l-26.181-.551,8.118-1.975,17.712,1.082Z'
            transform='translate(-290.835 -145.075)'
            opacity='0.1'
          />
          <path
            id='Path_21133'
            data-name='Path 21133'
            d='M312.839,135.887,312,148.563a1.882,1.882,0,0,0,1.745,2l28.789,1.916a1.882,1.882,0,0,0,2-1.745l.843-12.652a1.892,1.892,0,0,0-1.75-2l-28.789-1.916a1.887,1.887,0,0,0-1.814,1.058A1.8,1.8,0,0,0,312.839,135.887Z'
            transform='translate(-292.727 -127.781)'
            fill='#f02711'
          />
          <path
            id='Path_21134'
            data-name='Path 21134'
            d='M314.1,135.174l16.357,13.719,15.9-11.569a1.877,1.877,0,0,0-1.658-1.292l-28.789-1.916A1.887,1.887,0,0,0,314.1,135.174Z'
            transform='translate(-293.807 -127.755)'
            opacity='0.1'
          />
          <path
            id='Path_21135'
            data-name='Path 21135'
            d='M314.1,135.159l21.13,11.4,11.131-9.263A1.877,1.877,0,0,0,344.7,136l-28.789-1.916a1.887,1.887,0,0,0-1.814,1.073Z'
            transform='translate(-293.807 -127.74)'
            fill='#f02711'
          />
          <path
            id='Path_21136'
            data-name='Path 21136'
            d='M314.1,135.159l21.13,11.4,11.131-9.263A1.877,1.877,0,0,0,344.7,136l-28.789-1.916a1.887,1.887,0,0,0-1.814,1.073Z'
            transform='translate(-293.807 -127.74)'
            fill='#fff'
            opacity='0.2'
          />
        </g>
      </g>
    </svg>
  );
}

RelaxIcon.propTypes = {
  fill: PropTypes.string,
  active: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  stroke: PropTypes.string,
};

RelaxIcon.defaultProps = {
  fill: 'var(--primary-icon-color)',
  active: 'false',
  width: '34px',
  height: '34px',
  stroke: '#292d32',
};
