import { useEffect, useState } from 'react';
import { api } from '../../api';
import { useSelector } from 'react-redux';
import { selectCustomerDetailSelector } from 'redux/selectors';

export default function useFooter() {
  const [showTerms, setShowTerms] = useState(false);
  const [terms, setTerms] = useState('');
  const customerDetails = useSelector(selectCustomerDetailSelector);

  async function getTerms() {
    const res = await api.get(`termsConditions/${customerDetails.id}`);
    setTerms(res.data.termsCondition);
  }

  useEffect(() => {
    if (customerDetails) {
      getTerms();
    }
  }, []);

  return { showTerms, setShowTerms, terms };
}
