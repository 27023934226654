import { useEffect, useState } from 'react';
import { api } from '../../../api';
import { showSnackbar } from 'components/snackbar/actions';
import { snackbarTypes } from 'constants/index';
import { useDispatch, useSelector } from 'react-redux';
import { customerSupportDataSelector } from 'redux/selectors';
import { selectClientOptionsSelector } from 'redux/selectors';
import { checkBuyerUserName } from 'pages/customers/actions';
import { showInfoModal } from 'components/info-modal/actions';
import { infoModalTypes, defaultMessages } from 'constants';
import { useUpdateURLParams } from 'common-hooks/update-query-params-hooks';

export default function useAddNewRetailerModal({
  edit,
  prospect,
  retailerId,
  callbacks,
  setOpen,
  notificationId,
  retailer,
}) {
  const dispatch = useDispatch();
  const { updateURLParams } = useUpdateURLParams();
  //support data for select field options
  const supportData = useSelector(customerSupportDataSelector);

  //add retailer form type
  const [formType, setFormType] = useState(
    edit || prospect ? 'ADVANCED_DETAILS' : 'QUICK_DETAILS'
  );

  //send invite email state
  const [sendInviteEmail, setSendInviteEmail] = useState(false);

  //same as billing address state
  const [sameAsBilling, setSameAsBilling] = useState(false);

  //initial form values to pass to formik

  const [initialValues, setInitialValues] = useState(null);

  const clientOptions = useSelector(selectClientOptionsSelector);

  const [isBusinessPhoneRequired, setIsBusinessPhoneRequired] = useState(false);

  const [isBusinessPhoneRequiredMsg, setIsBusinessPhoneRequiredMsg] =
    useState('');

  const [isMobilePhoneRequired, setIsMobilePhoneRequired] = useState(false);

  const [isMobilePhoneRequiredMsg, setIsMobilePhoneRequiredMsg] = useState('');

  //function to set initial values
  function initializeForm() {
    let initValues = {
      // paymentMethods: retailer?.paymentMethod ?? [],
      paymentMethods:
        retailer?.paymentMethod != 'Not on file'
          ? retailer?.paymentMethod
          : supportData?.payment_method,

      defaultPaymentMethod: (() => {
        if (retailer?.defaultPaymentMethod)
          return supportData?.payment_method?.filter(
            (paymentMethod) =>
              paymentMethod?.id === retailer?.defaultPaymentMethod
          )[0];

        return supportData?.payment_method?.filter(
          (paymentMethod) => paymentMethod.default === true
        )[0];
      })(),

      shippingMethods: retailer?.shippingMethod ?? [],

      defaultShippingMethod: (() => {
        if (retailer?.defaultShippingMethod)
          return supportData?.shipping_method?.filter(
            (shippingMethod) =>
              shippingMethod?.id === retailer?.defaultShippingMethod
          )[0];
        return supportData?.shipping_method?.filter(
          (shippingMethod) => shippingMethod.default === true
        )[0];
      })(),

      currency: (() => {
        if (retailer?.currency)
          return supportData?.client_currency?.filter(
            (currency) => currency?.id === retailer?.currency
          )[0]?.id;
        return supportData?.client_currency?.filter(
          (currency) => currency.default === true
        )[0]?.id;
      })(),

      inventoryAccess:
        retailer?.inventoryAccess ?? supportData.show_inventory ? 'yes' : 'no',

      VAT: (() => {
        if (retailer?.vat)
          return supportData?.client_vat?.filter(
            (vat) => vat?.id === retailer?.vat
          )[0]?.id;
        return supportData?.client_vat?.filter((vat) => vat.default === true)[0]
          ?.id;
      })(),

      priceGroup: (() => {
        if (retailer?.priceGroup)
          return supportData?.price_groups?.filter(
            (priceGroup) => priceGroup?.id === retailer?.priceGroup
          )[0]?.id;
        return supportData?.price_groups?.filter(
          (priceGroup) => priceGroup.default === true
        )[0]?.id;
      })(),

      salesRep: (() => {
        if (retailer?.salesRep)
          return supportData?.sales_rep?.filter(
            (salesRep) => salesRep?.id === retailer?.salesRep
          )[0]?.id;
        return supportData?.sales_rep?.filter(
          (salesRep) => salesRep.default === true
        )[0]?.id;
      })(),

      // additionalPriceGroup: retailer?.additionalPriceGroups
      //   ? retailer?.additionalPriceGroups
      //   : supportData?.additionalPriceGroups,
      additionalPriceGroup: retailer?.additionalPriceGroups
        ? retailer?.additionalPriceGroups
        : [],

      legalName: retailer?.legalName,

      customerId: retailer?.customerIdOrCode,

      dba: retailer?.dBAOrRooftopName,

      vendorCode: retailer?.vendorCode,

      billingContact: retailer?.buyerName,

      billingLocationName: '',

      billingId: retailer?.billingIdOrCode,

      billingShippingId: '',

      billingPhone: retailer?.phone,

      billingAttention: '',

      billingAddressLine1: retailer?.buyerStreetAddress,

      billingAddressLine2: retailer?.buyerStreetAddress2,

      billingCity: retailer?.buyerCity,

      billingState: retailer?.buyerState,

      billingPostCode: retailer?.buyerZipCode,

      billingCountry: retailer?.buyerCountry,

      shippingLocationName: '',

      shippingShippingId: '',

      shippingAttention: '',

      shippingAddressLine1: '',

      shippingAddressLine2: '',

      shippingCity: '',

      shippingState: '',

      shippingPostCode: '',

      shippingCountry: '',
      buyerName: '',

      businessPhone: '',

      title: '',

      businessFax: '',

      emailAddress: '',

      streetAddress: '',

      mobilePhone: '',

      buyerCity: '',

      username: '',

      password: 'changeme',

      confirmPassword: 'changeme',

      buyerState: '',

      buyerPostCode: '',

      buyerCountry: '',

      tax_id: retailer?.tax_id,

      note: retailer?.note ?? '',
    };

    setInitialValues(initValues);
  }
  function handleSubmit(values) {
    if (edit) editRetailer(values);
    else if (prospect) confirmProspect(values);
    else addRetailer(values);
  }

  //add retailer
  async function addRetailer(values) {
    const res = await api.post(`/retailer`, {
      addType: formType === 'ADVANCED_DETAILS' ? 'advanced' : 'quick',
      defaultPaymentMethod: values.defaultPaymentMethod?.id,
      paymentMethod: values.paymentMethods,
      defaultShippingMethod: values.defaultShippingMethod?.id,
      shippingMethod: values.shippingMethods,
      currency: values.currency,
      inventoryAccess: values.inventoryAccess === 'yes' ? true : false,
      vat: values.tax_id, //values.VAT
      priceGroup: values.priceGroup,
      salesRep: values.salesRep,
      additionalPriceGroups: values?.additioanlPriceGroup,
      legalName: values.legalName,
      customerIdOrCode: values.customerId,
      dBAOrRooftopName: values.dba,
      name: values.buyerName,
      emailId: values.emailAddress,
      userName: values.username,
      password: values.password,
      confirmPassword: values.confirmPassword,
      venderCode: values.vendorCode,
      billingContact: values.billingContact,
      locationName: values.billingLocationName,
      billingIdOrCode: values.billingId,
      billingPhone: values.billingPhone,
      attention: values.billingAttention,
      billingAddress1: values.billingAddressLine1,
      billingAddress2: values.billingAddressLine2,
      billingCity: values.billingCity,
      billingState: values.billingState,
      billingZipCode: values.billingPostCode,
      billingCountry: values.billingCountry,
      shippingLocationName: sameAsBilling
        ? values.billingLocationName
        : values.shippingLocationName,
      shippingCode: sameAsBilling
        ? values.billingId
        : values.shippingShippingId,
      shippingAttention: sameAsBilling
        ? values.billingAttention
        : values.shippingAttention,
      shippingAddress1: sameAsBilling
        ? values.billingAddressLine1
        : values.shippingAddressLine1,
      shippingAddress2: sameAsBilling
        ? values.billingAddressLine2
        : values.shippingAddressLine2,
      shippingCity: sameAsBilling ? values.billingCity : values.shippingCity,
      shippingState: sameAsBilling ? values.billingState : values.shippingState,
      shippingZipCode: sameAsBilling
        ? values.billingPostCode
        : values.shippingPostCode,
      shippingCountry: sameAsBilling
        ? values.billingCountry
        : values.shippingCountry,
      buyerName: values.buyerName,
      businessPhone: values.businessPhone,
      title: values.title,
      businessFax: values.businessFax,
      buyerStreetAddress: values.buyerAddressLine1,
      buyerStreetAddress2: values.buyerAddressLine2,
      buyerMobilePhone: values.mobilePhone, //values.billingPhone
      buyerCity: values.buyerCity,
      buyerState: values.buyerState,
      buyerZipCode: values.buyerPostCode,
      buyerCountry: values.buyerCountry,
      sentInviteMail: sendInviteEmail,
      tax_id: values.tax_id,
      note: values.note ?? '',
      phone: values.mobilePhone,
    });
    if (res.data.status) {
      //window.location.reload();
      window.location.href = '/my-customer';
    } else {
      let concatenatedErrors = '';
      if (res.data?.errors) {
        res.data?.errors?.forEach((item) => {
          Object.keys(item).forEach((key) => {
            const value = item[key];
            if (!key || key.trim() === '') {
              console.log(`Empty key found with value: ${value}`);
            } else if (value === '' || value === null || value === undefined) {
              console.log(`Empty value found for Key: ${key}`);
            } else {
              // Perform data manipulation with key and value
              concatenatedErrors += value;
              concatenatedErrors += '<br/> ';
            }
          });
        });
      }
      dispatch(
        showInfoModal({
          messageTitle: '',
          message: concatenatedErrors ?? defaultMessages.FAILED_TO_EDI_RETAILER,
          type: infoModalTypes.ERROR,
        })
      );
    }
  }

  //edit retailer

  async function editRetailer(values) {
    const res = await api.put(`/retailer/${retailerId}`, {
      defaultPaymentMethod: values.defaultPaymentMethod?.id,
      paymentMethod: values.paymentMethods,
      defaultShippingMethod: values.defaultShippingMethod?.id,
      shippingMethod: values.shippingMethods,
      currency: values.currency,
      inventoryAccess: values.inventoryAccess === 'yes' ? true : false,
      vat: values.VAT,
      priceGroup: values.priceGroup,
      salesRep: values.salesRep,
      additionalPriceGroups: values?.additioanlPriceGroup,
      legalName: values.legalName,
      customerIdOrCode: values.customerId,
      dBAOrRooftopName: values.dba,
      vendorCode: values.vendorCode,
      buyerName: values.buyerName,
      billingIdOrCode: values.billingId,
      buyerStreetAddress: values.billingAddressLine1,
      buyerStreetAddress2: values.billingAddressLine2,
      buyerCity: values.billingCity,
      buyerState: values.billingState,
      buyerZipCode: values.billingPostCode,
      buyerCountry: values.billingCountry,
      tax_id: values.tax_id,
      note: values.note,
      phone: values.billingPhone,
      billingContact: values.billingContact,
    });

    if (res.data.status) {
      window.location.href = '/my-customer';
      //window.location.reload();
    } else {
      let concatenatedErrors = '';
      if (res.data?.errors) {
        res.data?.errors?.forEach((item) => {
          Object.keys(item).forEach((key) => {
            const value = item[key];
            if (!key || key.trim() === '') {
              console.log(`Empty key found with value: ${value}`);
            } else if (value === '' || value === null || value === undefined) {
              console.log(`Empty value found for Key: ${key}`);
            } else {
              // Perform data manipulation with key and value
              concatenatedErrors += value;
              concatenatedErrors += '<br/> ';
            }
          });
        });
      }
      dispatch(
        showInfoModal({
          messageTitle: '',
          message: concatenatedErrors ?? defaultMessages.FAILED_TO_EDI_RETAILER,
          type: infoModalTypes.ERROR,
        })
      );
    }
  }

  //confirm prospect
  async function confirmProspect(values) {
    let payload = {
      defaultPaymentMethod: values?.defaultPaymentMethod?.id,
      paymentMethod: values?.paymentMethods,
      defaultShippingMethod: values?.defaultShippingMethod?.id,
      shippingMethod: values?.shippingMethods,
      currency: values?.currency,
      showInventory: values?.inventoryAccess === 'yes' ? true : false,
      vat: values?.VAT,
      priceGroup: values?.priceGroup,
      salesRep: values?.salesRep,
      additionalPriceGroup: values?.additionalPriceGroup,
    };
    if (notificationId) {
      payload.notificationId = notificationId;
    }
    const res = await api.post(
      `/retailerAccountRequestApproval/${retailerId}`,
      payload
    );

    if (res.data.success) {
      updateURLParams({ rRefetch: true });
      callbacks();
      setOpen(false);
    } else {
      dispatch(
        showSnackbar({
          snackbarMessage: res.data.message,
          type: snackbarTypes.ERROR,
        })
      );
    }
  }

  //initialize form on receiving support data
  useEffect(() => {
    initializeForm();
  }, [supportData]);

  useEffect(() => {
    setIsBusinessPhoneRequired(
      clientOptions?.required_retailer_fields?.includes('phone')
    );
    setIsBusinessPhoneRequiredMsg(
      clientOptions?.required_retailer_fields?.includes('phone')
        ? 'Business phone is a required field'
        : ''
    );
    setIsMobilePhoneRequired(
      clientOptions?.required_retailer_fields?.includes('phone')
    );
    setIsMobilePhoneRequiredMsg(
      clientOptions?.required_retailer_fields?.includes('phone')
        ? 'Mobile phone is a required field'
        : ''
    );
  }, [clientOptions]);
  //  check buyerusename exist
  const handleCheckBuyerUserName = async (username, setFieldError) => {
    if (username !== '') {
      const data = await dispatch(checkBuyerUserName(username));
      if (data) {
        setFieldError('username', data.message);
      }
    }
  };

  return {
    formType,
    setFormType,
    handleSubmit,
    sendInviteEmail,
    setSendInviteEmail,
    sameAsBilling,
    setSameAsBilling,
    initialValues,
    supportData,
    isBusinessPhoneRequired,
    isBusinessPhoneRequiredMsg,
    setIsBusinessPhoneRequiredMsg,
    isMobilePhoneRequired,
    isMobilePhoneRequiredMsg,
    setIsMobilePhoneRequiredMsg,
    handleCheckBuyerUserName,
  };
}
