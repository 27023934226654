import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { useDepartmentSubMenu } from '../department-sub-menu-hooks';
import DepartmentCategories from '../components/department-categories';
import DepartmentCollection from '../components/department-collection';
import DepartmentDeliveries from '../components/department-deliveries';

const SelectedDepartmentDrawerMenus = ({
  selectedDepartment,
  handleCloseMenus,
}) => {
  if (!selectedDepartment) return;
  const {
    collections,
    collectionsLoading,
    deliveries,
    deliveriesLoading,
    handleSelectCollection,
    selectedCollection,
    selectedDelivery,
    handleSelectDelivery,
    categories,
    categoriesLoading,
    handleDepartmentMenuClick,
  } = useDepartmentSubMenu(selectedDepartment, handleCloseMenus);

  return (
    <Box sx={{ overflow: 'auto', position: 'relative' }}>
      <Stack marginBottom={3}>
        <Stack sx={{ height: '35px', justifyContent: 'center' }}>
          <Typography variant='h4'>COLLECTIONS</Typography>
        </Stack>
        <Divider />
        <DepartmentCollection
          loading={collectionsLoading}
          collections={collections}
          handleSelectCollection={handleSelectCollection}
          handleCollectionClick={handleDepartmentMenuClick}
          selectedCollection={selectedCollection}
        />
      </Stack>
      <Stack marginBottom={3}>
        <Stack sx={{ height: '35px', justifyContent: 'center' }}>
          <Typography variant='h4'>DELIVERIES</Typography>
        </Stack>
        <Divider />
        <DepartmentDeliveries
          loading={deliveriesLoading}
          deliveries={deliveries}
          handleSelectDelivery={handleSelectDelivery}
          handleDeliveryClick={handleDepartmentMenuClick}
          selectedDelivery={selectedDelivery}
        />
      </Stack>

      <Stack>
        <Stack sx={{ height: '35px', justifyContent: 'center' }}>
          <Typography variant='h4'>CATEGORIES</Typography>
        </Stack>
        <Divider />
        <DepartmentCategories
          categories={categories?.categories_data}
          loading={categoriesLoading}
          handleCategoryClick={handleDepartmentMenuClick}
        />
      </Stack>
    </Box>
  );
};

SelectedDepartmentDrawerMenus.propTypes = {
  selectedDepartment: PropTypes.object.isRequired,
  handleCloseMenus: PropTypes.func.isRequired,
};

export default SelectedDepartmentDrawerMenus;
