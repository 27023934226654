import React from 'react';
import PropTypes from 'prop-types';

export default function EmailIcon({ fill, sx }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='15.258'
      height='11'
      viewBox='0 0 15.258 11'
      style={sx}
    >
      <g id='email' transform='translate(-1.25 -4.25)'>
        <path
          id='Path_20849'
          data-name='Path 20849'
          d='M14.556,15.25H3.2A1.959,1.959,0,0,1,1.25,13.3V6.2A1.959,1.959,0,0,1,3.2,4.25H14.556A1.959,1.959,0,0,1,16.508,6.2v7.1A1.959,1.959,0,0,1,14.556,15.25ZM3.2,5.315a.887.887,0,0,0-.887.887v7.1a.887.887,0,0,0,.887.887H14.556a.887.887,0,0,0,.887-.887V6.2a.887.887,0,0,0-.887-.887Z'
          fill={fill}
        />
        <path
          id='Path_20850'
          data-name='Path 20850'
          d='M8.882,11.319A2.306,2.306,0,0,1,7.712,11l-6.2-3.683a.539.539,0,0,1-.192-.71.525.525,0,0,1,.71-.185l6.2,3.676a1.2,1.2,0,0,0,1.249,0l6.2-3.676a.525.525,0,0,1,.71.185.539.539,0,0,1-.192.71L10.053,11a2.306,2.306,0,0,1-1.171.319Z'
          transform='translate(-0.003 -0.61)'
          fill={fill}
        />
      </g>
    </svg>
  );
}

EmailIcon.propTypes = {
  fill: PropTypes.string,
  sx: PropTypes.object,
};

EmailIcon.defaultProps = {
  fill: '#667587',
  sx: {},
};
