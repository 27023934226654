export const recoverAccountStyles = {
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  logo: {
    height: '110px',
    marginBottom: '51px',
  },
  recoverMessage: {
    width: '480px',
    fontSize: '18px',
    color: 'var(--primary-font-color)',
    fontWeight: '500',
  },
  defaultWidth: { width: '100%' },
  accountField: {
    marginBottom: '16px',
  },
};

export const recoverConfirmationStyles = {
  container: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: { height: '110px', marginBottom: '51px' },
  successIcon: { width: '72px', height: '72px' },
  message: {
    width: '480px',
    fontSize: '18px',
    color: 'var(--primary-font-color)',
    textAlign: 'center',
  },
};
