const styles = {
  header: {
    backgroundColor: '#545454',
    borderRadius: '8px 8px 0 0',
    px: 1.5,
    py: 1,
    height: '19px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 99,
  },

  contentWrapper: {
    cursor: 'pointer',
    backgroundColor: '#FFFFFF',
    border: '0.7px solid #A9A9A9',
    borderRadius: '0 0 8px 8px',
    padding: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },

  icon: {
    fontSize: '14px',
    color: '#fff',
  },
};

export default styles;
