import React from 'react';
import PropTypes from 'prop-types';

export default function YouTubeIcon({ fill, style }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill={fill}
      style={style}
    >
      <g>
        <path
          d='M224.113,212.161l3.578-2.061-3.578-2.061Zm0,0'
          transform='translate(-214.483 -199.1)'
        />
        <path d='M11,0A11,11,0,1,0,22,11,11,11,0,0,0,11,0Zm6.873,11.011a18.155,18.155,0,0,1-.283,3.307,1.722,1.722,0,0,1-1.212,1.212A41.809,41.809,0,0,1,11,15.813a40.312,40.312,0,0,1-5.379-.294A1.723,1.723,0,0,1,4.41,14.306,18.075,18.075,0,0,1,4.127,11,18.143,18.143,0,0,1,4.41,7.694,1.757,1.757,0,0,1,5.621,6.471,41.809,41.809,0,0,1,11,6.188a40.227,40.227,0,0,1,5.379.294A1.723,1.723,0,0,1,17.59,7.694,17.229,17.229,0,0,1,17.873,11.011Zm0,0' />
      </g>
    </svg>
  );
}

YouTubeIcon.propTypes = {
  fill: PropTypes.string,
  style: PropTypes.object,
};

YouTubeIcon.defaultProps = {
  fill: 'var(--primary-icon-color)',
  style: {},
};
