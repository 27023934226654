import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AlertModal from 'components/modals/alert/alert-modal';
import useSizeTable from './product-size-table-hooks';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { isDataPresent } from 'utils/helper';
import contrast from 'utils/checkContrast';
import { LogLevel } from 'constants';
import LoggingService from 'utils/service/LoggingService';

export default function SizeTable({
  colorName,
  colorHex,
  sizes,
  colorCode,
  productId,
  colorId,
  hideTableHead,
  dimensions,
  prePackSizes,
  costPerUnit,
  cartFlag,
  productDetails,
  quantityErrorFlag,
  showInventory,
  userCurrencySymbol,
  rest,
  validateFormik,
  submit,
  color,
  colorIndex,
  calculateItemTotalAndQuantity,
  itemCalculation,
  isAllowNegativeInventory,
}) {
  const {
    sizeTableAlertModal,
    setSizeTableAlertModal,
    performDynamicSizeTable,
  } = useSizeTable(sizes, dimensions, prePackSizes, color);
  const [inventoryQtyStatus, setInventoryQtyStatus] = useState(false);
  useEffect(() => {
    calculateItemTotalAndQuantity(rest.values);
  }, [rest.values]);
  useEffect(() => {
    performDynamicSizeTable();
  }, []);

  const isInventoryAvailable = (data) => {
    let hasInventory = false;
    if (Object.keys(data).length > 0) {
      hasInventory = data.some((element) => {
        return element.sizeInventory !== 0;
      });

      return hasInventory;
    }
  };
  let nonStateQuantityErrorFlag = { ...quantityErrorFlag };
  let contrastWithWhite = contrast(`#${color?.colorHex}`, '#ffffff');
  let contrastWithBlack = contrast(`#${color?.colorHex}`, '#000000');
  let preferredBorderValue = '#fff';

  if (contrastWithBlack > contrastWithWhite) preferredBorderValue = '#9D9D9D';
  else preferredBorderValue = '#ffffff';
  return (
    <Stack spacing={1} sx={{ paddingTop: '3px', position: 'relative' }}>
      <AlertModal
        type='warning'
        title={sizeTableAlertModal}
        open={!!sizeTableAlertModal}
        setOpen={setSizeTableAlertModal}
      />
      {colorName || colorCode ? (
        <Stack direction='row' spacing={1}>
          <Box
            sx={{
              width: '28px',
              height: '14px',
              backgroundColor: `#${colorHex}`,
              border: `1px solid ${preferredBorderValue}`,
            }}
          />
          {colorName == colorCode ? (
            <Typography variant='body2'>
              {colorName ? `${colorName}` : null}
            </Typography>
          ) : (
            <Typography variant='body2'>
              {colorName ? `${colorName}` : null}
              {colorCode ? ` (${colorCode})` : null}
            </Typography>
          )}
        </Stack>
      ) : null}

      <TableContainer sx={{ width: '100%', padding: '1px 0 0 0' }}>
        {sizes && (
          <Table
            size='small'
            sx={{
              width: 'fit-content',
              marginLeft: '1px',
              marginBottom: `${
                isDataPresent(sizes, 'sizeInventory') ? '0px' : '15px'
              }`,
            }}
          >
            <TableBody>
              {hideTableHead && (
                <TableRow sx={{ width: '100%' }}>
                  {sizes?.map((size, i) => {
                    return (
                      <TableCell
                        key={i}
                        className={`${color?.colorId}_tablecell`}
                        sx={{
                          width: '40px',
                          height: '30px',
                          // borderRight: '1px solid #9D9D9D',
                          // borderTop: '1px solid #9D9D9D',
                          // borderLeft: '1px solid #9D9D9D',
                          // borderBottom: 0,
                          border: 0,
                          textAlign: 'center',
                          fontSize: '12px',
                          color: 'var(--primary-font-color)',
                          opacity: '0.7',
                          padding: '0px 10px',
                        }}
                      >
                        {/* {size.sizeName}
                        {size.isSizeCost ? <br /> : null}
                        {size.isSizeCost ? size.isSizeCost : null} */}
                        <Box
                          display='flex'
                          flexDirection='column'
                          justifyContent={`${
                            size?.sizeName
                              ? isDataPresent(sizes, 'sizeCost')
                                ? 'flex-start'
                                : 'flex-end'
                              : 'flex-end'
                          }`}
                          alignItems='center'
                          sx={{
                            height: '100%',
                            width: '100%',
                          }}
                        >
                          <Typography sx={{ whiteSpace: 'nowrap' }}>
                            {size?.sizeName}
                          </Typography>
                          <Typography>
                            {size?.sizeCost !== 0 && size?.isSizeCost
                              ? `${userCurrencySymbol}${size?.sizeCost}`
                              : null}
                          </Typography>
                        </Box>
                      </TableCell>
                    );
                  })}
                </TableRow>
              )}

              <TableRow>
                {sizes?.map((size, i) => {
                  return size?.tooltipText ? (
                    <Tooltip
                      title={size.tooltipText}
                      placement='top-start'
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.arrow}`]: {
                              color: (theme) => theme.palette.primary.main,
                            },
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: () => '#9D9D9D',
                            },
                          },
                        },
                      }}
                    >
                      <TableCell
                        key={i}
                        id={`${size.sizeCombinationId}_sizeTableCell`}
                        // className={`${color?.colorId}_tablecell`}
                        // className="table-cell-row-2"
                        sx={[
                          {
                            width: '40px',
                            height: '30px',
                            fontWeight: '500',
                            border: '1px solid #9D9D9D',
                            textAlign: 'center',
                          },
                          size?.canAddItem
                            ? {
                                '&:hover': {
                                  backgroundColor: '#ECF6FE',
                                  border: '1px solid #8BC8F0',
                                },
                              }
                            : { backgroundColor: '#E0E0E0' },
                        ]}
                        onClick={() => {
                          document
                            .getElementById(`${size.sizeCombinationId}`)
                            .focus();
                        }}
                        onFocus={() => {
                          try {
                            if (size?.canAddItem) {
                              document.getElementById(
                                `${size.sizeCombinationId}_sizeTableCell`
                              ).style.backgroundColor = '#ECF6FE';
                              document.getElementById(
                                `${size.sizeCombinationId}_sizeTableCell`
                              ).style.border = '1px solid #8BC8F0';
                            } else {
                              document.getElementById(
                                `${size.sizeCombinationId}_sizeTableCell`
                              ).style.backgroundColor = '#9D9D9D';
                              document.getElementById(
                                `${size.sizeCombinationId}_sizeTableCell`
                              ).style.border = '1px solid #9D9D9D';
                            }
                          } catch (err) {
                            LoggingService.log(
                              'onFocus',
                              'SizeTable',
                              err,
                              LogLevel.ERROR,
                              'Something went wrong on product-size-table.jsx event Line-243',
                              {}
                            );
                          }
                        }}
                        onBlur={() => {
                          try {
                            if (size?.canAddItem) {
                              document.getElementById(
                                `${size.sizeCombinationId}_sizeTableCell`
                              ).style.backgroundColor = '#FFFFFF';
                              document.getElementById(
                                `${size.sizeCombinationId}_sizeTableCell`
                              ).style.border = '1px solid #9D9D9D';
                            } else {
                              document.getElementById(
                                `${size.sizeCombinationId}_sizeTableCell`
                              ).style.backgroundColor = '#9D9D9D';
                              document.getElementById(
                                `${size.sizeCombinationId}_sizeTableCell`
                              ).style.border = '1px solid #9D9D9D';
                            }
                          } catch (err) {
                            LoggingService.log(
                              'onBlur',
                              'SizeTable',
                              err,
                              LogLevel.ERROR,
                              'Something went wrong on product-size-table.jsx event Line-271',
                              {}
                            );
                          }
                        }}
                      >
                        <input
                          className={`${color?.colorId}_tablecell`}
                          disabled={!size?.canAddItem ?? true}
                          type='number'
                          min='0'
                          id={`${size.sizeCombinationId}`}
                          name={`${size.sizeCombinationId}`}
                          value={
                            rest.values[`${size.sizeCombinationId}`] === 0
                              ? ''
                              : rest.values[`${size.sizeCombinationId}`]
                          }
                          style={{
                            outline: 'none',
                            width: '100%',
                            border: 0,
                            margin: 0,
                            padding: '0px 1px',
                            backgroundColor: 'inherit',
                            textAlign: 'center',
                            fontSize: '12px',
                          }}
                          onChange={(e) => {
                            // validateFormik(rest.values);
                            //rest.handleChange(e);
                            if (
                              // Value Exceeds Quantity
                              parseInt(size?.sizeInventory) > 0 &&
                              parseInt(e.target.value) >
                                parseInt(size?.sizeInventory) &&
                              !isAllowNegativeInventory
                            ) {
                              if (
                                parseInt(size?.sizeMaximum) > 0 &&
                                parseInt(size?.sizeInventory) <=
                                  parseInt(size?.sizeMaximum)
                              ) {
                                setInventoryQtyStatus(true);
                              }

                              const identifier = setTimeout(() => {
                                setSizeTableAlertModal(
                                  'Value exceeds quantity in stock'
                                );
                                rest.setFieldValue(
                                  `${size.sizeCombinationId}`,
                                  size?.sizeInventory
                                );
                              }, 500);

                              return () => {
                                clearTimeout(identifier);
                              };
                            } else {
                              rest.handleChange(e);
                              const identifier = setTimeout(() => {
                                if (
                                  cartFlag &&
                                  !nonStateQuantityErrorFlag.maxQuantity &&
                                  !nonStateQuantityErrorFlag.minQuantity
                                ) {
                                  submit(
                                    {
                                      [`${size.sizeCombinationId}`]:
                                        e.target.value,
                                      productId,
                                      colorId,
                                      product_quantity: [
                                        {
                                          sizetable_size_id: size.sizeId,
                                          prepack_code: '',
                                          sizetable_row:
                                            size.sizeTableRowCounter,
                                          quantity: e.target.value,
                                        },
                                      ],
                                    },

                                    // quantityErrorFlag
                                    productId,
                                    colorId
                                  );
                                }
                              }, 300);

                              return () => {
                                clearTimeout(identifier);
                              };
                            }
                          }}
                          onBlur={(e) => {
                            rest.handleBlur(e);

                            //rest.handleChange(e);
                            if (
                              (parseInt(size?.sizeMinimum) ||
                                parseInt(size?.sizeMaximum) > 0) &&
                              !inventoryQtyStatus
                            ) {
                              // For minimum Per size
                              if (
                                parseInt(e.target.value) <
                                parseInt(size?.sizeMinimum)
                              ) {
                                let minQty =
                                  size?.sizeMinimum <
                                  parseInt(size?.sizeInventory)
                                    ? size?.sizeMinimum
                                    : parseInt(size?.sizeInventory);

                                rest.setFieldValue(
                                  `${size.sizeCombinationId}`,
                                  minQty
                                );

                                setSizeTableAlertModal(
                                  `Minimum Quantity per Size is ${size?.sizeMinimum}`
                                );

                                if (
                                  cartFlag &&
                                  !nonStateQuantityErrorFlag.maxQuantity &&
                                  !nonStateQuantityErrorFlag.minQuantity
                                ) {
                                  submit(
                                    {
                                      [`${size.sizeCombinationId}`]: minQty,
                                      productId,
                                      colorId,
                                      product_quantity: [
                                        {
                                          sizetable_size_id: size.sizeId,
                                          prepack_code: '',
                                          sizetable_row:
                                            size.sizeTableRowCounter,
                                          quantity: minQty,
                                        },
                                      ],
                                    },
                                    // quantityErrorFlag
                                    productId,
                                    colorId
                                  );
                                }
                              } else if (
                                // For maximum Per size
                                parseInt(e.target.value) >
                                  parseInt(size?.sizeMaximum) &&
                                parseInt(size?.sizeMaximum) > 0
                              ) {
                                rest.setFieldValue(
                                  `${size.sizeCombinationId}`,
                                  size?.sizeMaximum
                                );

                                setSizeTableAlertModal(
                                  `Maximum Quantity per Size is ${size?.sizeMaximum}`
                                );

                                if (
                                  cartFlag &&
                                  !nonStateQuantityErrorFlag.maxQuantity &&
                                  !nonStateQuantityErrorFlag.minQuantity
                                ) {
                                  submit(
                                    {
                                      [`${size.sizeCombinationId}`]:
                                        size?.sizeMaximum,
                                      productId,
                                      colorId,
                                      product_quantity: [
                                        {
                                          sizetable_size_id: size.sizeId,
                                          prepack_code: '',
                                          sizetable_row:
                                            size.sizeTableRowCounter,
                                          quantity: size?.sizeMaximum,
                                        },
                                      ],
                                    },
                                    // quantityErrorFlag
                                    productId,
                                    colorId
                                  );
                                }
                              } else {
                                let enteredValue = parseInt(e.target.value);
                                let sizeIncrement = parseInt(
                                  size?.sizeIncrement
                                );
                                if (enteredValue > 0) {
                                  let tmp = parseInt(
                                    enteredValue / sizeIncrement
                                  );
                                  // alert(
                                  //   'Entered' +
                                  //     enteredValue +
                                  //     '   Increament ' +
                                  //     sizeIncrement
                                  // );
                                  // alert(tmp);
                                  if (enteredValue != tmp * sizeIncrement) {
                                    let tmp2 =
                                      enteredValue - tmp * sizeIncrement;
                                    // alert(tmp2);
                                    let newVal = tmp * sizeIncrement;
                                    if (tmp2 > sizeIncrement / 2 - 1) {
                                      newVal =
                                        tmp * sizeIncrement + sizeIncrement;
                                    }
                                    if (
                                      parseInt(size?.sizeInventory) > 0 &&
                                      parseInt(size?.sizeInventory) < newVal
                                    ) {
                                      newVal = size?.sizeInventory;
                                    }
                                    rest.setFieldValue(
                                      `${size.sizeCombinationId}`,
                                      newVal
                                    );
                                    setSizeTableAlertModal(
                                      `Sizes must be purchased in groups of ${sizeIncrement}`
                                    );
                                    if (
                                      cartFlag &&
                                      !nonStateQuantityErrorFlag.maxQuantity &&
                                      !nonStateQuantityErrorFlag.minQuantity
                                    ) {
                                      submit(
                                        {
                                          [`${size.sizeCombinationId}`]: newVal,
                                          productId,
                                          colorId,
                                          product_quantity: [
                                            {
                                              sizetable_size_id: size.sizeId,
                                              prepack_code: '',
                                              sizetable_row:
                                                size.sizeTableRowCounter,
                                              quantity: newVal,
                                            },
                                          ],
                                        },
                                        // quantityErrorFlag
                                        productId,
                                        colorId
                                      );
                                    }
                                  }
                                }
                              }
                            } else {
                              if (
                                cartFlag &&
                                !nonStateQuantityErrorFlag.maxQuantity &&
                                !nonStateQuantityErrorFlag.minQuantity
                              ) {
                                submit(
                                  {
                                    [`${size.sizeCombinationId}`]:
                                      e.target.value,
                                    productId,
                                    colorId,
                                    product_quantity: [
                                      {
                                        sizetable_size_id: size.sizeId,
                                        prepack_code: '',
                                        sizetable_row: size.sizeTableRowCounter,
                                        quantity: e.target.value,
                                      },
                                    ],
                                  },

                                  // quantityErrorFlag
                                  productId,
                                  colorId
                                );
                              }
                            }
                          }}
                          onInput={(e) => {
                            let charLength = e.target.value.split('').length;
                            const paddingLeft = parseFloat(
                              getComputedStyle(e.target.parentNode).paddingLeft
                            );
                            const paddingRight = parseFloat(
                              getComputedStyle(e.target.parentNode).paddingRight
                            );

                            // Calculate the new width based on the current input value
                            // const targetWidth = e.target.scrollWidth;
                            const targetWidth = charLength * 8;
                            // Set the new width for the parent and the input
                            if (e.target.value == '') {
                              e.target.style.width = '17px';
                              e.target.parentNode.style.width = '40px';
                            } else {
                              e.target.style.width = `${targetWidth}px`;
                              e.target.parentNode.style.width = `${
                                targetWidth + paddingLeft + paddingRight
                              }px`;
                            }
                          }}
                        />
                      </TableCell>
                    </Tooltip>
                  ) : (
                    <TableCell
                      key={i}
                      id={`${size.sizeCombinationId}_sizeTableCell`}
                      // className={`${color?.colorId}_tablecell`}
                      // className="table-cell-row-2"
                      sx={[
                        {
                          width: '40px',
                          height: '30px',
                          fontWeight: '500',
                          border: '1px solid #9D9D9D',
                          textAlign: 'center',
                        },
                        size?.canAddItem
                          ? {
                              '&:hover': {
                                backgroundColor: '#ECF6FE',
                                border: '1px solid #8BC8F0',
                              },
                            }
                          : { backgroundColor: '#E0E0E0' },
                      ]}
                      onClick={() => {
                        document
                          .getElementById(`${size.sizeCombinationId}`)
                          .focus();
                      }}
                      onFocus={() => {
                        try {
                          if (size?.canAddItem) {
                            document.getElementById(
                              `${size.sizeCombinationId}_sizeTableCell`
                            ).style.backgroundColor = '#ECF6FE';
                            document.getElementById(
                              `${size.sizeCombinationId}_sizeTableCell`
                            ).style.border = '1px solid #8BC8F0';
                          } else {
                            document.getElementById(
                              `${size.sizeCombinationId}_sizeTableCell`
                            ).style.backgroundColor = '#9D9D9D';
                            document.getElementById(
                              `${size.sizeCombinationId}_sizeTableCell`
                            ).style.border = '1px solid #9D9D9D';
                          }
                        } catch (err) {
                          LoggingService.log(
                            'onFocus',
                            'SizeTable',
                            err,
                            LogLevel.ERROR,
                            'Something went wrong on product-size-table.jsx event Line-634',
                            {}
                          );
                        }
                      }}
                      onBlur={() => {
                        try {
                          if (size?.canAddItem) {
                            document.getElementById(
                              `${size.sizeCombinationId}_sizeTableCell`
                            ).style.backgroundColor = '#FFFFFF';
                            document.getElementById(
                              `${size.sizeCombinationId}_sizeTableCell`
                            ).style.border = '1px solid #9D9D9D';
                          } else {
                            document.getElementById(
                              `${size.sizeCombinationId}_sizeTableCell`
                            ).style.backgroundColor = '#9D9D9D';
                            document.getElementById(
                              `${size.sizeCombinationId}_sizeTableCell`
                            ).style.border = '1px solid #9D9D9D';
                          }
                        } catch (err) {
                          LoggingService.log(
                            'onClick',
                            'SlotsDateRangePicker',
                            err,
                            LogLevel.ERROR,
                            'Something went wrong on onClick event Line-112',
                            {}
                          );
                        }
                      }}
                    >
                      <input
                        className={`${color?.colorId}_tablecell`}
                        disabled={!size?.canAddItem ?? true}
                        type='number'
                        min='0'
                        id={`${size.sizeCombinationId}`}
                        name={`${size.sizeCombinationId}`}
                        value={
                          rest.values[`${size.sizeCombinationId}`] === 0
                            ? ''
                            : rest.values[`${size.sizeCombinationId}`]
                        }
                        style={{
                          outline: 'none',
                          width: '100%',
                          border: 0,
                          // border: '0.5px solid red',
                          margin: 0,
                          padding: '0px',
                          backgroundColor: 'inherit',
                          textAlign: 'center',
                          fontSize: '12px',
                        }}
                        onChange={(e) => {
                          // validateFormik(rest.values);
                          //rest.handleChange(e);
                          if (
                            // Value Exceeds Quantity
                            parseInt(size?.sizeInventory) > 0 &&
                            parseInt(e.target.value) >
                              parseInt(size?.sizeInventory) &&
                            !isAllowNegativeInventory
                          ) {
                            if (
                              parseInt(size?.sizeMaximum) > 0 &&
                              parseInt(size?.sizeInventory) <=
                                parseInt(size?.sizeMaximum)
                            ) {
                              setInventoryQtyStatus(true);
                            }

                            const identifier = setTimeout(() => {
                              setSizeTableAlertModal(
                                'Value exceeds quantity in stock'
                              );
                              rest.setFieldValue(
                                `${size.sizeCombinationId}`,
                                size?.sizeInventory
                              );
                            }, 500);

                            return () => {
                              clearTimeout(identifier);
                            };
                          } else {
                            rest.handleChange(e);
                            const identifier = setTimeout(() => {
                              if (
                                cartFlag &&
                                !nonStateQuantityErrorFlag.maxQuantity &&
                                !nonStateQuantityErrorFlag.minQuantity
                              ) {
                                submit(
                                  {
                                    [`${size.sizeCombinationId}`]:
                                      e.target.value,
                                    productId,
                                    colorId,
                                    product_quantity: [
                                      {
                                        sizetable_size_id: size.sizeId,
                                        prepack_code: '',
                                        sizetable_row: size.sizeTableRowCounter,
                                        quantity: e.target.value,
                                      },
                                    ],
                                  },

                                  // quantityErrorFlag
                                  productId,
                                  colorId
                                );
                              }
                            }, 300);

                            return () => {
                              clearTimeout(identifier);
                            };
                          }
                        }}
                        onBlur={(e) => {
                          rest.handleBlur(e);

                          //rest.handleChange(e);
                          if (
                            (parseInt(size?.sizeMinimum) ||
                              parseInt(size?.sizeMaximum) > 0) &&
                            !inventoryQtyStatus
                          ) {
                            // For minimum Per size
                            if (
                              parseInt(e.target.value) <
                              parseInt(size?.sizeMinimum)
                            ) {
                              let minQty =
                                size?.sizeMinimum <
                                parseInt(size?.sizeInventory)
                                  ? size?.sizeMinimum
                                  : parseInt(size?.sizeInventory);

                              rest.setFieldValue(
                                `${size.sizeCombinationId}`,
                                minQty
                              );

                              setSizeTableAlertModal(
                                `Minimum Quantity per Size is ${size?.sizeMinimum}`
                              );

                              if (
                                cartFlag &&
                                !nonStateQuantityErrorFlag.maxQuantity &&
                                !nonStateQuantityErrorFlag.minQuantity
                              ) {
                                submit(
                                  {
                                    [`${size.sizeCombinationId}`]: minQty,
                                    productId,
                                    colorId,
                                    product_quantity: [
                                      {
                                        sizetable_size_id: size.sizeId,
                                        prepack_code: '',
                                        sizetable_row: size.sizeTableRowCounter,
                                        quantity: minQty,
                                      },
                                    ],
                                  },
                                  // quantityErrorFlag
                                  productId,
                                  colorId
                                );
                              }
                            } else if (
                              // For maximum Per size
                              parseInt(e.target.value) >
                                parseInt(size?.sizeMaximum) &&
                              parseInt(size?.sizeMaximum) > 0
                            ) {
                              rest.setFieldValue(
                                `${size.sizeCombinationId}`,
                                size?.sizeMaximum
                              );

                              setSizeTableAlertModal(
                                `Maximum Quantity per Size is ${size?.sizeMaximum}`
                              );

                              if (
                                cartFlag &&
                                !nonStateQuantityErrorFlag.maxQuantity &&
                                !nonStateQuantityErrorFlag.minQuantity
                              ) {
                                submit(
                                  {
                                    [`${size.sizeCombinationId}`]:
                                      size?.sizeMaximum,
                                    productId,
                                    colorId,
                                    product_quantity: [
                                      {
                                        sizetable_size_id: size.sizeId,
                                        prepack_code: '',
                                        sizetable_row: size.sizeTableRowCounter,
                                        quantity: size?.sizeMaximum,
                                      },
                                    ],
                                  },
                                  // quantityErrorFlag
                                  productId,
                                  colorId
                                );
                              }
                            } else {
                              let enteredValue = parseInt(e.target.value);
                              let sizeIncrement = parseInt(size?.sizeIncrement);
                              if (enteredValue > 0) {
                                let tmp = parseInt(
                                  enteredValue / sizeIncrement
                                );
                                // alert(
                                //   'Entered' +
                                //     enteredValue +
                                //     '   Increament ' +
                                //     sizeIncrement
                                // );
                                // alert(tmp);
                                if (enteredValue != tmp * sizeIncrement) {
                                  let tmp2 = enteredValue - tmp * sizeIncrement;
                                  // alert(tmp2);
                                  let newVal = tmp * sizeIncrement;
                                  if (tmp2 > sizeIncrement / 2 - 1) {
                                    newVal =
                                      tmp * sizeIncrement + sizeIncrement;
                                  }
                                  if (
                                    parseInt(size?.sizeInventory) > 0 &&
                                    parseInt(size?.sizeInventory) < newVal
                                  ) {
                                    newVal = size?.sizeInventory;
                                  }
                                  rest.setFieldValue(
                                    `${size.sizeCombinationId}`,
                                    newVal
                                  );
                                  setSizeTableAlertModal(
                                    `Sizes must be purchased in groups of ${sizeIncrement}`
                                  );
                                  if (
                                    cartFlag &&
                                    !nonStateQuantityErrorFlag.maxQuantity &&
                                    !nonStateQuantityErrorFlag.minQuantity
                                  ) {
                                    submit(
                                      {
                                        [`${size.sizeCombinationId}`]: newVal,
                                        productId,
                                        colorId,
                                        product_quantity: [
                                          {
                                            sizetable_size_id: size.sizeId,
                                            prepack_code: '',
                                            sizetable_row:
                                              size.sizeTableRowCounter,
                                            quantity: newVal,
                                          },
                                        ],
                                      },
                                      // quantityErrorFlag
                                      productId,
                                      colorId
                                    );
                                  }
                                }
                              }
                            }
                          } else {
                            if (
                              cartFlag &&
                              !nonStateQuantityErrorFlag.maxQuantity &&
                              !nonStateQuantityErrorFlag.minQuantity
                            ) {
                              submit(
                                {
                                  [`${size.sizeCombinationId}`]: e.target.value,
                                  productId,
                                  colorId,
                                  product_quantity: [
                                    {
                                      sizetable_size_id: size.sizeId,
                                      prepack_code: '',
                                      sizetable_row: size.sizeTableRowCounter,
                                      quantity: e.target.value,
                                    },
                                  ],
                                },

                                // quantityErrorFlag
                                productId,
                                colorId
                              );
                            }
                          }
                        }}
                        onInput={(e) => {
                          let charLength = e.target.value.split('').length;
                          const paddingLeft = parseFloat(
                            getComputedStyle(e.target.parentNode).paddingLeft
                          );
                          const paddingRight = parseFloat(
                            getComputedStyle(e.target.parentNode).paddingRight
                          );

                          // Calculate the new width based on the current input value
                          // const targetWidth = e.target.scrollWidth;
                          const targetWidth = charLength * 8;
                          // Set the new width for the parent and the input
                          if (e.target.value == '') {
                            e.target.style.width = '17px';
                            e.target.parentNode.style.width = '40px';
                          } else {
                            e.target.style.width = `${targetWidth}px`;
                            e.target.parentNode.style.width = `${
                              targetWidth + paddingLeft + paddingRight
                            }px`;
                          }
                        }}
                      />
                    </TableCell>
                  );
                })}
              </TableRow>

              {showInventory && isDataPresent(sizes, 'sizeInventory') ? (
                <TableRow>
                  {sizes?.map((size, i) => {
                    return (
                      <TableCell
                        key={i}
                        className={`${color?.colorId}_tablecell`}
                        sx={{
                          textAlign: 'center',
                          border: 0,
                          fontSize: '11px',
                          color: '#222222',
                          width: '40px',
                          height: '30px',
                          paddingTop: 0,
                          opacity: 0.7,
                        }}
                      >
                        {showInventory && size.sizeInventory !== 0
                          ? size.sizeInventory
                          : null}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        )}

        {dimensions && (
          <Stack spacing={1}>
            <Typography
              variant='body2'
              sx={{
                // border: '0px',
                whiteSpace: 'nowrap',
              }}
            >
              {productDetails.horizontalLabel} / {productDetails.verticalLabel}
            </Typography>
            <Table
              size='small'
              sx={{ width: 'fit-content', marginLeft: '1px' }}
            >
              <TableBody>
                {dimensions.map((dimension, i) => {
                  return (
                    <>
                      {/* table row for size cost name */}
                      {i === 0 ? (
                        <>
                          <TableRow key={i}>
                            <TableCell
                              // className={`${color?.colorId}_tablecell`}
                              sx={{
                                fontSize: '12px',
                                color: 'var(--primary-font-color)',
                                opacity: '0.7',
                                width: '40px',
                                height: '30px',
                                border: '0',
                              }}
                            ></TableCell>
                            {dimension.sizes.map((size, i) => {
                              return (
                                <TableCell
                                  key={i}
                                  className={`${color?.colorId}_tablecell`}
                                  sx={{
                                    width: '40px',
                                    height: '30px',
                                    // borderRight: '1px solid #9D9D9D',
                                    // borderTop: '1px solid #9D9D9D',
                                    // borderLeft: '1px solid #9D9D9D',
                                    // borderBottom: 0,
                                    border: 0,
                                    textAlign: 'center',
                                    fontSize: '11px',
                                    color: 'var(--primary-font-color)',
                                    opacity: '0.7',
                                    whiteSpace: 'nowrap',
                                    padding: '0px 10px',
                                  }}
                                >
                                  {size.sizeName}
                                  {color?.isSizeCost ? <br /> : null}
                                  {size?.isSizeCost && size?.sizeCost !== 0
                                    ? `${userCurrencySymbol}${size?.sizeCost}`
                                    : null}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        </>
                      ) : null}
                      {/* table row for input field */}
                      <TableRow>
                        <Typography
                          variant='body1'
                          sx={{
                            border: '0px',
                            color: 'var(--primary-font-color)',
                            opacity: 0.7,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'left',
                            width: 'fit-content',
                            height: '30px',
                            // maxWidth: '100px',
                            maxHeight: '30px', // Set maximum width for table cell
                            whiteSpace: 'nowrap', // Prevent line wrapping within the cell
                            wordWrap: 'break-word',

                            overflow: 'hidden',
                            marginRight: '5px',
                          }}
                        >
                          {dimension.dimension_name}
                        </Typography>

                        {dimension.sizes.map((size, i) => {
                          return size?.tooltipText ? (
                            <Tooltip
                              title={size.tooltipText}
                              placement='top-start'
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.arrow}`]: {
                                      color: (theme) =>
                                        theme.palette.primary.main,
                                    },
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: () => '#9D9D9D',
                                    },
                                  },
                                },
                              }}
                            >
                              <TableCell
                                key={i}
                                id={`${size.sizeCombinationId}_dimensionTableCell`}
                                className='dimension_input_tableCell'
                                sx={[
                                  {
                                    width: '40px',
                                    height: '30px',
                                    fontWeight: '500',
                                    border: '1px solid #9D9D9D',
                                  },
                                  size?.canAddItem
                                    ? {
                                        '&:hover': {
                                          backgroundColor: '#ECF6FE',
                                          border: '1px solid #8BC8F0',
                                        },
                                      }
                                    : { backgroundColor: '#E0E0E0' },
                                ]}
                                onClick={() => {
                                  document
                                    .getElementById(`${size.sizeCombinationId}`)
                                    .focus();
                                }}
                                onFocus={() => {
                                  try {
                                    if (size?.canAddItem) {
                                      document.getElementById(
                                        `${size.sizeCombinationId}_dimensionTableCell`
                                      ).style.backgroundColor = '#ECF6FE';
                                      document.getElementById(
                                        `${size.sizeCombinationId}_dimensionTableCell`
                                      ).style.border = '1px solid #8BC8F0';
                                    } else {
                                      document.getElementById(
                                        `${size.sizeCombinationId}_dimensionTableCell`
                                      ).style.backgroundColor = '#9D9D9D';
                                      document.getElementById(
                                        `${size.sizeCombinationId}_dimensionTableCell`
                                      ).style.border = '1px solid #9D9D9D';
                                    }
                                  } catch (err) {
                                    LoggingService.log(
                                      'onFocus',
                                      'SizeTable',
                                      err,
                                      LogLevel.ERROR,
                                      'Something went wrong on product-size-table.jsx event Line-1159',
                                      {}
                                    );
                                  }
                                }}
                                onBlur={() => {
                                  try {
                                    if (size?.canAddItem) {
                                      document.getElementById(
                                        `${size.sizeCombinationId}_dimensionTableCell`
                                      ).style.backgroundColor = '#FFFFFF';
                                      document.getElementById(
                                        `${size.sizeCombinationId}_dimensionTableCell`
                                      ).style.border = '1px solid #9D9D9D';
                                    } else {
                                      document.getElementById(
                                        `${size.sizeCombinationId}_dimensionTableCell`
                                      ).style.backgroundColor = '#9D9D9D';
                                      document.getElementById(
                                        `${size.sizeCombinationId}_dimensionTableCell`
                                      ).style.border = '1px solid #9D9D9D';
                                    }
                                  } catch (err) {
                                    LoggingService.log(
                                      'onBlur',
                                      'SizeTable',
                                      err,
                                      LogLevel.ERROR,
                                      'Something went wrong on product-size-table.jsx event Line-1187',
                                      {}
                                    );
                                  }
                                }}
                              >
                                <input
                                  type='number'
                                  className={`${color?.colorId}_tablecell`}
                                  disabled={size?.canAddItem ? false : true}
                                  // disabled={false}
                                  id={size.sizeCombinationId}
                                  name={size.sizeCombinationId}
                                  value={
                                    rest.values[size.sizeCombinationId] === 0
                                      ? ''
                                      : rest.values[size.sizeCombinationId]
                                  }
                                  style={{
                                    outline: 'none',
                                    width: '100%',
                                    border: 0,
                                    margin: 0,
                                    padding: '0px',
                                    borderLeft: '0px',
                                    backgroundColor: 'inherit',
                                    textAlign: 'center',
                                    fontSize: '12px',
                                  }}
                                  onInput={(e) => {
                                    try {
                                      let charLength =
                                        e.target.value.split('').length;
                                      const paddingLeft = parseFloat(
                                        getComputedStyle(e.target.parentNode)
                                          .paddingLeft
                                      );
                                      const paddingRight = parseFloat(
                                        getComputedStyle(e.target.parentNode)
                                          .paddingRight
                                      );
                                      // Fill all cells parralel to current input to adjust its width
                                      const tableCells =
                                        document.getElementsByClassName(
                                          `${color?.colorId}_tablecell`
                                        );
                                      let parallelElements = [];
                                      Array.from(tableCells).forEach(
                                        (cell, i) => {
                                          if (cell == e.target) {
                                            let targetElementPointer = i;
                                            let rowLength =
                                              dimension.sizes.length;
                                            if (i > rowLength) {
                                              targetElementPointer =
                                                i - rowLength;
                                            }
                                            while (
                                              targetElementPointer <=
                                              tableCells.length
                                            ) {
                                              parallelElements.push(
                                                tableCells[targetElementPointer]
                                              );
                                              targetElementPointer =
                                                targetElementPointer +
                                                rowLength;
                                            }
                                          }
                                        }
                                      );
                                      // Calculate the new width based on the current input value
                                      const targetWidth = charLength * 8;
                                      // Set the new width for the parent and the input
                                      if (e.target.value == '') {
                                        e.target.style.width = '17px';
                                        e.target.parentNode.style.width =
                                          '40px';
                                        if (parallelElements.length > 0) {
                                          parallelElements.forEach(
                                            (element) => {
                                              element.target.parentNode.style.width =
                                                '40px';
                                            }
                                          );
                                        }
                                      } else {
                                        e.target.style.width = `${targetWidth}px`;
                                        e.target.parentNode.style.width = `${
                                          targetWidth +
                                          paddingLeft +
                                          paddingRight
                                        }px`;
                                        if (parallelElements.length > 0) {
                                          parallelElements.forEach(
                                            (element) => {
                                              element.target.parentNode.style.width = `${
                                                targetWidth +
                                                paddingLeft +
                                                paddingRight
                                              }px`;
                                            }
                                          );
                                        }
                                      }
                                    } catch (err) {
                                      LoggingService.log(
                                        'onInput',
                                        'SizeTable',
                                        err,
                                        LogLevel.ERROR,
                                        'Something went wrong on product-size-table.jsx event Line-1298',
                                        {}
                                      );
                                    }
                                  }}
                                  onChange={(e) => {
                                    validateFormik(rest.values);
                                    if (
                                      parseInt(size?.sizeInventory) > 0 &&
                                      parseInt(e.target.value) >
                                        parseInt(size?.sizeInventory) &&
                                      !isAllowNegativeInventory
                                    ) {
                                      setTimeout(() => {
                                        setSizeTableAlertModal(
                                          'Value exceeds quantity in stock'
                                        );
                                        rest.setFieldValue(
                                          `${size.sizeCombinationId}`,
                                          size?.sizeInventory
                                        );
                                      }, 100);
                                    } else {
                                      rest.handleChange(e);
                                      // handleSubmit();
                                      if (
                                        cartFlag &&
                                        !nonStateQuantityErrorFlag.maxQuantity &&
                                        !nonStateQuantityErrorFlag.minQuantity
                                      ) {
                                        submit({
                                          [`${size.sizeCombinationId}`]:
                                            e.target.value,
                                          productId,
                                          colorId,
                                          product_quantity: [
                                            {
                                              sizetable_size_id: size.sizeId,
                                              prepack_code: '',
                                              sizetable_row:
                                                size.sizeTableRowCounter,
                                              quantity: e.target.value,
                                            },
                                          ],
                                        });
                                      }
                                    }
                                  }}
                                  onBlur={(e) => {
                                    rest.handleBlur(e);

                                    //rest.handleChange(e);
                                    if (
                                      parseInt(size?.sizeMinimum) ||
                                      parseInt(size?.sizeMaximum) > 0
                                    ) {
                                      // For minimum Per size
                                      if (
                                        parseInt(e.target.value) <
                                        parseInt(size?.sizeMinimum)
                                      ) {
                                        rest.setFieldValue(
                                          `${size.sizeCombinationId}`,
                                          size?.sizeMinimum
                                        );

                                        setSizeTableAlertModal(
                                          `Minimum Quantity per Size is ${size?.sizeMinimum}`
                                        );

                                        if (
                                          cartFlag &&
                                          !nonStateQuantityErrorFlag.maxQuantity &&
                                          !nonStateQuantityErrorFlag.minQuantity
                                        ) {
                                          submit({
                                            [`dimension_${size.sizeId}_${colorId}_${size.sizeTableRowCounter}`]:
                                              e.target.value,
                                            productId,
                                            colorId,
                                            product_quantity: [
                                              {
                                                sizetable_size_id: size.sizeId,
                                                prepack_code: '',
                                                sizetable_row:
                                                  size.sizeTableRowCounter,
                                                quantity: size?.sizeMinimum,
                                              },
                                            ],
                                          });
                                        }
                                      } else if (
                                        // For maximum Per size
                                        parseInt(e.target.value) >
                                          parseInt(size?.sizeMaximum) &&
                                        parseInt(size?.sizeMaximum) > 0
                                      ) {
                                        rest.setFieldValue(
                                          `${size.sizeCombinationId}`,
                                          size?.sizeMaximum
                                        );

                                        setSizeTableAlertModal(
                                          `Maximum Quantity per Size is ${size?.sizeMaximum}`
                                        );

                                        if (
                                          cartFlag &&
                                          !nonStateQuantityErrorFlag.maxQuantity &&
                                          !nonStateQuantityErrorFlag.minQuantity
                                        ) {
                                          submit({
                                            [`dimension_${size.sizeId}_${colorId}_${size.sizeTableRowCounter}`]:
                                              size?.sizeMaximum,
                                            productId,
                                            colorId,
                                            product_quantity: [
                                              {
                                                sizetable_size_id: size.sizeId,
                                                prepack_code: '',
                                                sizetable_row:
                                                  size.sizeTableRowCounter,
                                                quantity: size?.sizeMaximum,
                                              },
                                            ],
                                          });
                                        }
                                      } else {
                                        let enteredValue = parseInt(
                                          e.target.value
                                        );
                                        let sizeIncrement = parseInt(
                                          size?.sizeIncrement
                                        );
                                        if (enteredValue > 0) {
                                          let tmp = parseInt(
                                            enteredValue / sizeIncrement
                                          );
                                          // alert(
                                          //   'Entered' +
                                          //     enteredValue +
                                          //     '   Increament ' +
                                          //     sizeIncrement
                                          // );
                                          // alert(tmp);
                                          if (
                                            enteredValue !=
                                            tmp * sizeIncrement
                                          ) {
                                            let tmp2 =
                                              enteredValue -
                                              tmp * sizeIncrement;
                                            // alert(tmp2);
                                            let newVal = tmp * sizeIncrement;
                                            if (tmp2 > sizeIncrement / 2 - 1) {
                                              newVal =
                                                tmp * sizeIncrement +
                                                sizeIncrement;
                                            }
                                            if (
                                              parseInt(size?.sizeInventory) >
                                                0 &&
                                              parseInt(size?.sizeInventory) <
                                                newVal
                                            ) {
                                              newVal = size?.sizeInventory;
                                            }
                                            rest.setFieldValue(
                                              `${size.sizeCombinationId}`,
                                              newVal
                                            );
                                            setSizeTableAlertModal(
                                              `Sizes must be purchased in groups of ${sizeIncrement}`
                                            );
                                            if (
                                              cartFlag &&
                                              !nonStateQuantityErrorFlag.maxQuantity &&
                                              !nonStateQuantityErrorFlag.minQuantity
                                            ) {
                                              submit({
                                                [`dimension_${size.sizeId}_${colorId}_${size.sizeTableRowCounter}`]:
                                                  newVal,
                                                productId,
                                                colorId,
                                                product_quantity: [
                                                  {
                                                    sizetable_size_id:
                                                      size.sizeId,
                                                    prepack_code: '',
                                                    sizetable_row:
                                                      size.sizeTableRowCounter,
                                                    quantity: newVal,
                                                  },
                                                ],
                                              });
                                            }
                                          }
                                        }
                                      }
                                    } else {
                                      if (
                                        cartFlag &&
                                        !nonStateQuantityErrorFlag.maxQuantity &&
                                        !nonStateQuantityErrorFlag.minQuantity
                                      ) {
                                        submit(
                                          {
                                            [`dimension_${size.sizeId}_${colorId}_${size.sizeTableRowCounter}`]:
                                              e.target.value,
                                            productId,
                                            colorId,
                                            product_quantity: [
                                              {
                                                sizetable_size_id: size.sizeId,
                                                prepack_code: '',
                                                sizetable_row:
                                                  size.sizeTableRowCounter,
                                                quantity: e.target.value,
                                              },
                                            ],
                                          },
                                          productId,
                                          colorId,
                                          quantityErrorFlag
                                        );
                                      }
                                    }
                                  }}
                                />
                              </TableCell>
                            </Tooltip>
                          ) : (
                            <TableCell
                              key={i}
                              id={`${size.sizeCombinationId}_dimensionTableCell`}
                              className='dimension_input_tableCell'
                              sx={[
                                {
                                  width: '40px',
                                  height: '30px',
                                  fontWeight: '500',
                                  border: '1px solid #9D9D9D',
                                },
                                size?.canAddItem
                                  ? {
                                      '&:hover': {
                                        backgroundColor: '#ECF6FE',
                                        border: '1px solid #8BC8F0',
                                      },
                                    }
                                  : { backgroundColor: '#E0E0E0' },
                              ]}
                              onClick={() => {
                                document
                                  .getElementById(`${size.sizeCombinationId}`)
                                  .focus();
                              }}
                              onFocus={() => {
                                try {
                                  if (size?.canAddItem) {
                                    document.getElementById(
                                      `${size.sizeCombinationId}_dimensionTableCell`
                                    ).style.backgroundColor = '#ECF6FE';
                                    document.getElementById(
                                      `${size.sizeCombinationId}_dimensionTableCell`
                                    ).style.border = '1px solid #8BC8F0';
                                  } else {
                                    document.getElementById(
                                      `${size.sizeCombinationId}_dimensionTableCell`
                                    ).style.backgroundColor = '#9D9D9D';
                                    document.getElementById(
                                      `${size.sizeCombinationId}_dimensionTableCell`
                                    ).style.border = '1px solid #9D9D9D';
                                  }
                                } catch (err) {
                                  LoggingService.log(
                                    'onFocus',
                                    'SizeTable',
                                    err,
                                    LogLevel.ERROR,
                                    'Something went wrong on product-size-table.jsx event Line-1578',
                                    {}
                                  );
                                }
                              }}
                              onBlur={() => {
                                try {
                                  if (size?.canAddItem) {
                                    document.getElementById(
                                      `${size.sizeCombinationId}_dimensionTableCell`
                                    ).style.backgroundColor = '#FFFFFF';
                                    document.getElementById(
                                      `${size.sizeCombinationId}_dimensionTableCell`
                                    ).style.border = '1px solid #9D9D9D';
                                  } else {
                                    document.getElementById(
                                      `${size.sizeCombinationId}_dimensionTableCell`
                                    ).style.backgroundColor = '#9D9D9D';
                                    document.getElementById(
                                      `${size.sizeCombinationId}_dimensionTableCell`
                                    ).style.border = '1px solid #9D9D9D';
                                  }
                                } catch (err) {
                                  LoggingService.log(
                                    'onBlur',
                                    'SizeTable',
                                    err,
                                    LogLevel.ERROR,
                                    'Something went wrong on product-size-table.jsx event Line-1606',
                                    {}
                                  );
                                }
                              }}
                            >
                              <input
                                className={`${color?.colorId}_tablecell`}
                                type='number'
                                disabled={size?.canAddItem ? false : true}
                                // disabled={false}
                                id={size.sizeCombinationId}
                                name={size.sizeCombinationId}
                                value={
                                  rest.values[size.sizeCombinationId] === 0
                                    ? ''
                                    : rest.values[size.sizeCombinationId]
                                }
                                style={{
                                  outline: 'none',
                                  width: '100%',
                                  border: 0,
                                  margin: 0,
                                  padding: 0,
                                  borderLeft: '0px',
                                  backgroundColor: 'inherit',
                                  textAlign: 'center',
                                  fontSize: '12px',
                                }}
                                onInput={(e) => {
                                  try {
                                    let charLength =
                                      e.target.value.split('').length;
                                    const paddingLeft = parseFloat(
                                      getComputedStyle(e.target.parentNode)
                                        .paddingLeft
                                    );
                                    const paddingRight = parseFloat(
                                      getComputedStyle(e.target.parentNode)
                                        .paddingRight
                                    );
                                    // Fill all cells parralel to current input to adjust its width
                                    const tableCells =
                                      document.getElementsByClassName(
                                        `${color?.colorId}_tablecell`
                                      );
                                    let parallelElements = [];
                                    Array.from(tableCells).forEach(
                                      (cell, i) => {
                                        if (cell == e.target) {
                                          let targetElementPointer = i;
                                          let rowLength =
                                            dimension.sizes.length;
                                          if (i > rowLength) {
                                            targetElementPointer =
                                              i - rowLength;
                                          }
                                          while (
                                            targetElementPointer <=
                                            tableCells.length
                                          ) {
                                            parallelElements.push(
                                              tableCells[targetElementPointer]
                                            );
                                            targetElementPointer =
                                              targetElementPointer + rowLength;
                                          }
                                        }
                                      }
                                    );
                                    // Calculate the new width based on the current input value
                                    const targetWidth = charLength * 8;
                                    // Set the new width for the parent and the input
                                    if (e.target.value == '') {
                                      e.target.style.width = '17px';
                                      e.target.parentNode.style.width = '40px';
                                      if (parallelElements.length > 0) {
                                        parallelElements.forEach((element) => {
                                          element.target.parentNode.style.width =
                                            '40px';
                                        });
                                      }
                                    } else {
                                      e.target.style.width = `${targetWidth}px`;
                                      e.target.parentNode.style.width = `${
                                        targetWidth + paddingLeft + paddingRight
                                      }px`;
                                      if (parallelElements.length > 0) {
                                        parallelElements.forEach((element) => {
                                          element.target.parentNode.style.width = `${
                                            targetWidth +
                                            paddingLeft +
                                            paddingRight
                                          }px`;
                                        });
                                      }
                                    }
                                  } catch (err) {
                                    LoggingService.log(
                                      'onInput',
                                      'SizeTable',
                                      err,
                                      LogLevel.ERROR,
                                      'Something went wrong on product-size-table.jsx event Line-1709',
                                      {}
                                    );
                                  }
                                }}
                                onChange={(e) => {
                                  validateFormik(rest.values);
                                  if (
                                    parseInt(size?.sizeInventory) > 0 &&
                                    parseInt(e.target.value) >
                                      parseInt(size?.sizeInventory) &&
                                    !isAllowNegativeInventory
                                  ) {
                                    setTimeout(() => {
                                      setSizeTableAlertModal(
                                        'Value exceeds quantity in stock'
                                      );
                                      rest.setFieldValue(
                                        `${size.sizeCombinationId}`,
                                        size?.sizeInventory
                                      );
                                    }, 100);
                                  } else {
                                    rest.handleChange(e);
                                    // handleSubmit();
                                    if (
                                      cartFlag &&
                                      !nonStateQuantityErrorFlag.maxQuantity &&
                                      !nonStateQuantityErrorFlag.minQuantity
                                    ) {
                                      submit({
                                        [`${size.sizeCombinationId}`]:
                                          e.target.value,
                                        productId,
                                        colorId,
                                        product_quantity: [
                                          {
                                            sizetable_size_id: size.sizeId,
                                            prepack_code: '',
                                            sizetable_row:
                                              size.sizeTableRowCounter,
                                            quantity: e.target.value,
                                          },
                                        ],
                                      });
                                    }
                                  }
                                }}
                                onBlur={(e) => {
                                  rest.handleBlur(e);

                                  //rest.handleChange(e);
                                  if (
                                    parseInt(size?.sizeMinimum) ||
                                    parseInt(size?.sizeMaximum) > 0
                                  ) {
                                    // For minimum Per size
                                    if (
                                      parseInt(e.target.value) <
                                      parseInt(size?.sizeMinimum)
                                    ) {
                                      rest.setFieldValue(
                                        `${size.sizeCombinationId}`,
                                        size?.sizeMinimum
                                      );

                                      setSizeTableAlertModal(
                                        `Minimum Quantity per Size is ${size?.sizeMinimum}`
                                      );

                                      if (
                                        cartFlag &&
                                        !nonStateQuantityErrorFlag.maxQuantity &&
                                        !nonStateQuantityErrorFlag.minQuantity
                                      ) {
                                        submit({
                                          [`dimension_${size.sizeId}_${colorId}_${size.sizeTableRowCounter}`]:
                                            e.target.value,
                                          productId,
                                          colorId,
                                          product_quantity: [
                                            {
                                              sizetable_size_id: size.sizeId,
                                              prepack_code: '',
                                              sizetable_row:
                                                size.sizeTableRowCounter,
                                              quantity: size?.sizeMinimum,
                                            },
                                          ],
                                        });
                                      }
                                    } else if (
                                      // For maximum Per size
                                      parseInt(e.target.value) >
                                        parseInt(size?.sizeMaximum) &&
                                      parseInt(size?.sizeMaximum) > 0
                                    ) {
                                      rest.setFieldValue(
                                        `${size.sizeCombinationId}`,
                                        size?.sizeMaximum
                                      );

                                      setSizeTableAlertModal(
                                        `Maximum Quantity per Size is ${size?.sizeMaximum}`
                                      );

                                      if (
                                        cartFlag &&
                                        !nonStateQuantityErrorFlag.maxQuantity &&
                                        !nonStateQuantityErrorFlag.minQuantity
                                      ) {
                                        submit({
                                          [`dimension_${size.sizeId}_${colorId}_${size.sizeTableRowCounter}`]:
                                            size?.sizeMaximum,
                                          productId,
                                          colorId,
                                          product_quantity: [
                                            {
                                              sizetable_size_id: size.sizeId,
                                              prepack_code: '',
                                              sizetable_row:
                                                size.sizeTableRowCounter,
                                              quantity: size?.sizeMaximum,
                                            },
                                          ],
                                        });
                                      }
                                    } else {
                                      let enteredValue = parseInt(
                                        e.target.value
                                      );
                                      let sizeIncrement = parseInt(
                                        size?.sizeIncrement
                                      );
                                      if (enteredValue > 0) {
                                        let tmp = parseInt(
                                          enteredValue / sizeIncrement
                                        );
                                        // alert(
                                        //   'Entered' +
                                        //     enteredValue +
                                        //     '   Increament ' +
                                        //     sizeIncrement
                                        // );
                                        // alert(tmp);
                                        if (
                                          enteredValue !=
                                          tmp * sizeIncrement
                                        ) {
                                          let tmp2 =
                                            enteredValue - tmp * sizeIncrement;
                                          // alert(tmp2);
                                          let newVal = tmp * sizeIncrement;
                                          if (tmp2 > sizeIncrement / 2 - 1) {
                                            newVal =
                                              tmp * sizeIncrement +
                                              sizeIncrement;
                                          }
                                          if (
                                            parseInt(size?.sizeInventory) > 0 &&
                                            parseInt(size?.sizeInventory) <
                                              newVal
                                          ) {
                                            newVal = size?.sizeInventory;
                                          }
                                          rest.setFieldValue(
                                            `${size.sizeCombinationId}`,
                                            newVal
                                          );
                                          setSizeTableAlertModal(
                                            `Sizes must be purchased in groups of ${sizeIncrement}`
                                          );
                                          if (
                                            cartFlag &&
                                            !nonStateQuantityErrorFlag.maxQuantity &&
                                            !nonStateQuantityErrorFlag.minQuantity
                                          ) {
                                            submit({
                                              [`dimension_${size.sizeId}_${colorId}_${size.sizeTableRowCounter}`]:
                                                newVal,
                                              productId,
                                              colorId,
                                              product_quantity: [
                                                {
                                                  sizetable_size_id:
                                                    size.sizeId,
                                                  prepack_code: '',
                                                  sizetable_row:
                                                    size.sizeTableRowCounter,
                                                  quantity: newVal,
                                                },
                                              ],
                                            });
                                          }
                                        }
                                      }
                                    }
                                  } else {
                                    if (
                                      cartFlag &&
                                      !nonStateQuantityErrorFlag.maxQuantity &&
                                      !nonStateQuantityErrorFlag.minQuantity
                                    ) {
                                      submit(
                                        {
                                          [`dimension_${size.sizeId}_${colorId}_${size.sizeTableRowCounter}`]:
                                            e.target.value,
                                          productId,
                                          colorId,
                                          product_quantity: [
                                            {
                                              sizetable_size_id: size.sizeId,
                                              prepack_code: '',
                                              sizetable_row:
                                                size.sizeTableRowCounter,
                                              quantity: e.target.value,
                                            },
                                          ],
                                        },
                                        productId,
                                        colorId,
                                        quantityErrorFlag
                                      );
                                    }
                                  }
                                }}
                              />
                            </TableCell>
                          );
                        })}
                      </TableRow>
                      {/* table row for inventory */}
                      {showInventory &&
                      isInventoryAvailable(dimension?.sizes) ? (
                        <TableRow>
                          <TableCell
                            // className={`${color?.colorId}_tablecell`}
                            sx={{
                              border: '0px',
                              width: '40px',
                              height: '30px',
                            }}
                          ></TableCell>
                          {dimension?.sizes?.map((size, i) => {
                            return (
                              <TableCell
                                key={i}
                                className={`${color?.colorId}_tablecell`}
                                sx={{
                                  textAlign: 'center',
                                  // borderLeft: color.isSizeCost
                                  //   ? '1px solid #9D9D9D'
                                  //   : 0,
                                  // borderRight: color.isSizeCost
                                  //   ? '1px solid #9D9D9D'
                                  //   : 0,
                                  // borderTop: '1px solid #9D9D9D',
                                  // borderRight: '1px solid #9D9D9D',
                                  // borderLeft: '1px solid  #9D9D9D',
                                  // borderBottom: '1px solid  #8BC8F0',
                                  // borderBottom: color.isSizeCost
                                  //   ? '1px solid #9D9D9D'
                                  //   : 0,
                                  border: 0,
                                  fontSize: '11px',
                                  color: '#222222',
                                  padding: 0,
                                  opacity: 0.7,
                                }}
                              >
                                {showInventory && size.sizeInventory !== 0
                                  ? size.sizeInventory
                                  : null}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      ) : (
                        <TableRow>
                          {' '}
                          <TableCell
                            // className={`${color?.colorId}_tablecell`}
                            sx={{
                              fontSize: '12px',
                              color: 'var(--primary-font-color)',
                              opacity: '0.7',
                              width: '40px',
                              height: '30px',
                              border: '0',
                            }}
                          ></TableCell>
                        </TableRow>
                      )}
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </Stack>
        )}
        {/* ===================================PrePackSizes =================== */}
        {prePackSizes &&
          prePackSizes.map((prepackSize) => {
            if (Array.isArray(prepackSize) && prepackSize.length > 0) {
              return (
                <>
                  <Table
                    size='small'
                    sx={{
                      width: 'fit-content',
                      marginLeft: '1px',
                    }}
                  >
                    <TableBody>
                      {hideTableHead && (
                        <TableRow sx={{ width: '100%' }}>
                          <TableCell
                            className={`${color?.colorId}_${prepackSize[0]?.packId}_tablecell`}
                            sx={{
                              width: 'fit-content',
                              height: '30px',
                              maxHeight: '30px',
                              // borderRight: '1px solid #9D9D9D',
                              // borderTop: '1px solid #9D9D9D',
                              // borderLeft: '1px solid #9D9D9D',
                              // borderBottom: 0,
                              border: 0,
                              //textAlign: 'left',
                              fontSize: '12px',
                              color: '#222',
                              opacity: '0.7',
                              whiteSpace: 'nowrap',
                              padding: '0px 27px 0px 0px',
                              display: 'flex',
                              alignItems: 'end',
                            }}
                          >
                            Pack Qty
                          </TableCell>
                          {prepackSize?.map((size, i) => {
                            return (
                              <TableCell
                                key={i}
                                className={`${color?.colorId}_${prepackSize[0]?.packId}_tablecell`}
                                sx={{
                                  width: '40px',
                                  height: '30px',
                                  // borderRight: '1px solid #9D9D9D',
                                  // borderTop: '1px solid #9D9D9D',
                                  // borderLeft: '1px solid #9D9D9D',
                                  // borderBottom: 0,
                                  border: 0,
                                  textAlign: 'center',
                                  fontSize: '12px',
                                  color: 'var(--primary-font-color)',
                                  opacity: '0.7',
                                  // padding: '0px',
                                  padding: '0px 10px',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  justifyContent={`${
                                    size?.packName
                                      ? isDataPresent(prepackSize, 'sizeCost')
                                        ? 'flex-start'
                                        : 'flex-end'
                                      : 'flex-end'
                                  }`}
                                  alignItems='center'
                                  sx={{
                                    height: '100%',
                                  }}
                                >
                                  <Typography>{size?.packName}</Typography>
                                  <Typography>
                                    {size?.sizeCost !== 0 && size?.isSizeCost
                                      ? `${userCurrencySymbol}${size?.sizeCost}`
                                      : null}
                                  </Typography>
                                </Box>
                              </TableCell>
                            );
                          })}
                          <TableCell
                            className={`${color?.colorId}_${prepackSize[0]?.packId}_tablecell`}
                            sx={{
                              width: '40px',
                              height: '30px',
                              // borderRight: '1px solid #9D9D9D',
                              // borderTop: '1px solid #9D9D9D',
                              // borderLeft: '1px solid #9D9D9D',
                              // borderBottom: 0,
                              border: 0,
                              textAlign: 'center',
                              fontSize: '12px',
                              color: 'var(--primary-font-color)',
                              opacity: '0.7',
                              padding: '0px',
                            }}
                          >
                            {' '}
                            <Box
                              display='flex'
                              flexDirection='column'
                              justifyContent={`${
                                isDataPresent(prepackSize, 'packName')
                                  ? isDataPresent(prepackSize, 'sizeCost')
                                    ? 'flex-start'
                                    : 'flex-end'
                                  : 'flex-end'
                              }`}
                              alignItems='center'
                              sx={{ height: '100%' }}
                            >
                              <Typography> Total</Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                      <TableRow sx={{ width: '100%' }}>
                        {prepackSize?.map((size, i) => {
                          return i === 0 ? (
                            <>
                              {size?.tooltipText ? (
                                <TableCell
                                  id={`${size.prePackId}_prepackTableCell`}
                                  sx={[
                                    {
                                      width: '40px',
                                      height: '30px',
                                      fontWeight: '500',
                                      border: 0,
                                      padding: 0,
                                    },
                                  ]}
                                >
                                  <Tooltip
                                    title={size.tooltipText}
                                    placement='top-start'
                                    componentsProps={{
                                      popper: {
                                        sx: {
                                          [`& .${tooltipClasses.arrow}`]: {
                                            color: (theme) =>
                                              theme.palette.primary.main,
                                          },
                                          [`& .${tooltipClasses.tooltip}`]: {
                                            backgroundColor: () => '#9D9D9D',
                                          },
                                        },
                                      },
                                    }}
                                  >
                                    <input
                                      type='number'
                                      disabled={size.canAddItem ? false : true}
                                      id={`${size.prePackId}`}
                                      className={`${color?.colorId}_${prepackSize[0]?.packId}_tablecell`}
                                      name={`${size.prePackId}`}
                                      value={
                                        rest.values[`${size.prePackId}`] === 0
                                          ? ''
                                          : rest.values[`${size.prePackId}`]
                                      }
                                      style={{
                                        outline: 'none',
                                        width: '40px',
                                        height: '30px',
                                        border: `${
                                          size.canAddItem
                                            ? '1px solid #8BC8F0'
                                            : '1px solid #9D9D9D'
                                        }`,
                                        margin: 0,
                                        padding: 0,
                                        backgroundColor: `${
                                          size.canAddItem
                                            ? '#ECF6FE'
                                            : '#E0E0E0'
                                        }`,
                                        textAlign: 'center',
                                        fontSize: '12px',
                                      }}
                                      min='0'
                                      onChange={(e) => {
                                        rest.handleChange(e);
                                        // validateFormik(rest.values, color);

                                        if (
                                          parseInt(size?.prepackInventory) >
                                            0 &&
                                          parseInt(e.target.value) >
                                            parseInt(size?.prepackInventory) &&
                                          !isAllowNegativeInventory
                                        ) {
                                          setTimeout(() => {
                                            setSizeTableAlertModal(
                                              'Value exceeds quantity in stock'
                                            );
                                            rest.setFieldValue(
                                              `${size.prePackId}`,
                                              size?.prepackInventory
                                            );
                                          }, 100);
                                        }
                                      }}
                                      onBlur={(e) => {
                                        let temp = size.prePackId.split('|');
                                        let prepackSizeTableId = temp[2];
                                        setTimeout(() => {
                                          if (
                                            cartFlag
                                            //   &&
                                            // !nonStateQuantityErrorFlag.maxQuantity &&
                                            // !nonStateQuantityErrorFlag.minQuantity
                                          ) {
                                            submit({
                                              [`${size.prePackId}`]:
                                                e.target.value,
                                              productId,
                                              colorId,
                                              product_quantity: [
                                                {
                                                  sizetable_size_id:
                                                    prepackSizeTableId, //size.sizeTableSizeId,
                                                  prepack_code: size.packId,
                                                  sizetable_row:
                                                    size.prePackSizeRow,
                                                  quantity: e.target.value,
                                                },
                                              ],
                                            });
                                          }
                                        });
                                      }}
                                    />
                                  </Tooltip>
                                </TableCell>
                              ) : (
                                <TableCell
                                  id={`${size.prePackId}_prepackTableCell`}
                                  sx={[
                                    {
                                      width: '40px',
                                      height: '30px',
                                      fontWeight: '500',
                                      border: 0,
                                      padding: 0,
                                    },
                                  ]}
                                >
                                  <input
                                    type='number'
                                    disabled={size.canAddItem ? false : true}
                                    id={`${size.prePackId}`}
                                    className={`${color?.colorId}_${prepackSize[0]?.packId}_tablecell`}
                                    name={`${size.prePackId}`}
                                    value={
                                      rest.values[`${size.prePackId}`] === 0
                                        ? ''
                                        : rest.values[`${size.prePackId}`]
                                    }
                                    style={{
                                      outline: 'none',
                                      width: '40px',
                                      height: '30px',
                                      border: `${
                                        size.canAddItem
                                          ? '1px solid #8BC8F0'
                                          : '1px solid #9D9D9D'
                                      }`,
                                      margin: 0,
                                      padding: 0,
                                      backgroundColor: `${
                                        size.canAddItem ? '#ECF6FE' : '#E0E0E0'
                                      }`,
                                      textAlign: 'center',
                                      fontSize: '12px',
                                      overflowX: 'scroll',
                                    }}
                                    min='0'
                                    onChange={(e) => {
                                      rest.handleChange(e);
                                      // validateFormik(rest.values, color);

                                      if (
                                        parseInt(size?.prepackInventory) > 0 &&
                                        parseInt(e.target.value) >
                                          parseInt(size?.prepackInventory)
                                      ) {
                                        setTimeout(() => {
                                          setSizeTableAlertModal(
                                            'Value exceeds quantity in stock'
                                          );
                                          rest.setFieldValue(
                                            `${size.prePackId}`,
                                            size?.prepackInventory
                                          );
                                        }, 100);
                                      }
                                    }}
                                    onBlur={(e) => {
                                      let temp = size.prePackId.split('|');
                                      let prepackSizeTableId = temp[2];
                                      setTimeout(() => {
                                        if (
                                          cartFlag
                                          //   &&
                                          // !nonStateQuantityErrorFlag.maxQuantity &&
                                          // !nonStateQuantityErrorFlag.minQuantity
                                        ) {
                                          submit({
                                            [`${size.prePackId}`]:
                                              e.target.value,
                                            productId,
                                            colorId,
                                            product_quantity: [
                                              {
                                                sizetable_size_id:
                                                  prepackSizeTableId, //size.sizeTableSizeId,
                                                prepack_code: size.packId,
                                                sizetable_row:
                                                  size.prePackSizeRow,
                                                quantity: e.target.value,
                                              },
                                            ],
                                          });
                                        }
                                      });
                                    }}
                                  />
                                </TableCell>
                              )}

                              <TableCell
                                className={`${color?.colorId}_${prepackSize[0]?.packId}_tablecell`}
                                sx={{
                                  fontSize: '12px',
                                  width: '40px',
                                  maxWidth: '40px',
                                  height: '30px',
                                  maxHeight: '30px',
                                  fontWeight: '500',
                                  border: '1px solid #9D9D9D',
                                  opacity: 0.5,
                                  // borderTop: '1px solid #8BC8F0',
                                  // borderBottom: '1px solid #8BC8F0',
                                  // borderRight: '1px solid #8BC8F0',
                                  textAlign: 'center',
                                  '&:first-of-type': {
                                    borderLeft: '1px solid  #8BC8F0',
                                  },
                                  backgroundColor: `${
                                    size.canAddItem ? '#ECF6FE' : '#E0E0E0'
                                  }`,
                                }}
                              >
                                {(() => {
                                  let calculatedQuantity =
                                    parseFloat(
                                      rest.values[`${size.prePackId}`]
                                    ) > 0
                                      ? parseFloat(
                                          rest.values[`${size.prePackId}`]
                                        ) * parseFloat(size.prepackQuantity)
                                      : size.prepackQuantity
                                      ? size.prepackQuantity
                                      : '-';

                                  return calculatedQuantity || '-';
                                })()}
                              </TableCell>
                            </>
                          ) : (
                            <TableCell
                              className={`${color?.colorId}_${prepackSize[0]?.packId}_tablecell`}
                              sx={{
                                fontSize: '11px',
                                width: '40px',
                                maxWidth: '40px',
                                height: '30px',
                                maxHeight: '30px',
                                fontWeight: '500',
                                opacity: 0.5,
                                border: '1px solid #9D9D9D',
                                // borderTop: '1px solid #8BC8F0',
                                // borderBottom: '1px solid #8BC8F0',
                                // borderRight: '1px solid #8BC8F0',
                                textAlign: 'center',
                                '&:first-of-type': {
                                  borderLeft: '1px solid  #8BC8F0',
                                },
                                backgroundColor: `${
                                  size.canAddItem ? '#ECF6FE' : '#E0E0E0'
                                }`,
                              }}
                            >
                              {(() => {
                                let calculatedQuantity =
                                  parseFloat(rest.values[`${size.prePackId}`]) >
                                  0
                                    ? parseFloat(
                                        rest.values[`${size.prePackId}`]
                                      ) * parseFloat(size.prepackQuantity)
                                    : size.prepackQuantity
                                    ? size.prepackQuantity
                                    : '-';

                                return calculatedQuantity || '-';
                              })()}
                            </TableCell>
                          );
                        })}
                        <TableCell
                          // className="table-cell-row-2"
                          className={`${color?.colorId}_${prepackSize[0]?.packId}_tablecell`}
                          sx={{
                            fontSize: '12px',
                            width: '40px',
                            maxWidth: '40px',
                            height: '30px',
                            maxHeight: '30px',
                            fontWeight: '700',
                            border: 0,
                            // borderTop: '1px solid #8BC8F0',
                            // borderBottom: '1px solid #8BC8F0',
                            // borderRight: '1px solid #8BC8F0',
                            textAlign: 'center',
                          }}
                        >
                          {(() => {
                            let totalQty = 0;
                            prepackSize.forEach((size) => {
                              totalQty +=
                                parseFloat(rest.values[`${size.prePackId}`]) *
                                parseFloat(size.prepackQuantity);
                            });

                            return totalQty || 0;
                          })()}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          className={`${color?.colorId}_${prepackSize[0]?.packId}_tablecell`}
                          sx={{
                            textAlign: 'center',
                            border: 0,
                            fontSize: '12px',
                            color: '#757574',
                            width: '40px',
                            height: '30px',
                            padding: '5px 0px 0px 0px',
                          }}
                        >
                          <Typography
                            sx={{
                              width: '40px',
                              height: '30px',
                              textAlign: 'center',
                            }}
                          >
                            {(() => {
                              if (showInventory) {
                                let userPackQuantity = 0;
                                prepackSize?.map((size, i) => {
                                  if (i === 0) {
                                    userPackQuantity = size.prepackInventory;
                                  }
                                });
                                return userPackQuantity !== 0
                                  ? userPackQuantity
                                  : '';
                              }
                            })()}
                          </Typography>
                        </TableCell>
                        {prepackSize?.map((size, i) => {
                          return (
                            <TableCell
                              className={`${color?.colorId}_${prepackSize[0]?.packId}_tablecell`}
                              key={i}
                              sx={{
                                textAlign: 'center',
                                border: 0,
                                fontSize: '12px',
                                color: 'var(--secondary-font-color)',
                                width: '40px',
                                height: '30px',
                                paddingTop: 0,
                                opacity: 0.7,
                              }}
                            >
                              {/*{showInventory ? size.prepackInventory : null}*/}
                            </TableCell>
                          );
                        })}
                        <TableCell
                          className={`${color?.colorId}_${prepackSize[0]?.packId}_tablecell`}
                          sx={{
                            textAlign: 'center',
                            borderBottom: 0,
                            fontSize: '12px',
                            color: '#757574',
                            fontWeight: 700,
                            width: '40px',
                            maxWidth: '60px',
                            height: '30px',
                            maxHeight: '30px',
                            border: 0,
                          }}
                        >
                          {userCurrencySymbol}
                          {(() => {
                            let totalCost = 0;
                            prepackSize.forEach((size) => {
                              totalCost +=
                                parseFloat(rest.values[`${size.prePackId}`]) *
                                parseFloat(size.prepackQuantity) *
                                parseFloat(
                                  size?.isSizeCost
                                    ? size?.sizeCost
                                    : costPerUnit
                                ).toFixed(2);
                            });

                            //return (totalCost = totalCost.toFixed(2) || 0);
                            return (totalCost = totalCost
                              ? totalCost.toFixed(2)
                              : 0);
                          })()}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </>
              );
            }
          })}
      </TableContainer>
      {/* Display the total quantity after the last color */}
      {colorIndex === productDetails.item_colors.length - 1 ? (
        <Stack direction='row' justifyContent='flex-end'>
          <Stack
            direction='row'
            spacing={2}
            sx={{
              width: 'fit-content',
              height: '28px',
              backgroundColor: '#E7F3FD',
              alignItems: 'center',
              justifyContent: 'center',
              // position: 'absolute',
              // bottom: '20px',
              // right: '0px',
              padding: '5px',
            }}
          >
            <Typography
              variant='body1'
              sx={{ color: '#0B5EA8', fontSize: '13px' }}
            >
              {' '}
              <span style={{ fontWeight: 700 }}>Quantity :</span>{' '}
              <span
                style={{
                  fontWeight: 500,
                  fontSize: '14px',
                }}
              >
                {itemCalculation.itemQuantity}
              </span>
            </Typography>
            <Typography
              variant='body1'
              sx={{ color: '#0B5EA8', fontSize: '13px' }}
            >
              {' '}
              <span style={{ fontWeight: 700 }}>Item Total :</span>{' '}
              <span
                style={{
                  fontWeight: 500,
                  fontSize: '14px',
                }}
              >
                {userCurrencySymbol}{' '}
                {itemCalculation.itemTotal
                  ? itemCalculation.itemTotal.toFixed(2)
                  : 0.0}
              </span>
            </Typography>
          </Stack>
        </Stack>
      ) : null}
    </Stack>
  );
}
SizeTable.propTypes = {
  colorName: PropTypes.string.isRequired,
  colorHex: PropTypes.string.isRequired,
  sizes: PropTypes.array,
  dimensions: PropTypes.array,
  prePackSizes: PropTypes.array,
  colorCode: PropTypes.any.isRequired,
  updateProductQuantity: PropTypes.func.isRequired,
  productId: PropTypes.any.isRequired,
  colorId: PropTypes.any.isRequired,
  sizeTableId: PropTypes.any.isRequired,
  prepackCode: PropTypes.any.isRequired,
  sizeTableRow: PropTypes.any.isRequired,
  hideTableHead: PropTypes.bool,
  costPerUnit: PropTypes.number,
  cartFlag: PropTypes.bool,
  formRef: PropTypes.any,
  productDetails: PropTypes.object.isRequired,
  setQuantityErrorFlag: PropTypes.func.isRequired,
  quantityErrorFlag: PropTypes.object.isRequired,
  itemCalculation: PropTypes.object.isRequired,
  setItemCalculation: PropTypes.func.isRequired,
  showInventory: PropTypes.bool.isRequired,
  userCurrencySymbol: PropTypes.string,
  rest: PropTypes.object,
  validateFormik: PropTypes.func.isRequired,
  calculateItemTotalAndQuantity: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  color: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  colorIndex: PropTypes.number,
  isAvailableInOtherCollection: PropTypes.any,
  isAllowNegativeInventory: PropTypes.any,
};

SizeTable.defaultProps = {
  hideTableHead: false,
  sizes: null,
  dimensions: null,
  prePackSizes: null,
  costPerUnit: 0,
  cartFlag: false,
  addToCart: () => {},
  productDetails: {},
  PropTypes: () => {},
  quantityErrorFlag: {},
  itemCalculation: {},
  showInventory: true,
  userCurrencySymbol: '$',
  rest: {},
  validateFormik: () => {},
  calculateItemTotalAndQuantity: () => {},
  submit: () => {},
  color: {},
  initialValues: {},
  colorIndex: 0,
  isAllowNegativeInventory: false,
};
