import * as Yup from 'yup';

export const resetPasswordFormSchema = (linkedAccount) =>
  Yup.object().shape({
    email: Yup.string()
      .email('Email Address is Invalid')
      .required('Email Address is required'),
    userId:
      linkedAccount.length > 0
        ? Yup.string().required('Account is required')
        : Yup.string(),
  });
