import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { loggedInUserSelector } from '../../../redux/selectors';

export default function useRequestInfoModal(data, open) {
  const userConfig = useSelector(loggedInUserSelector);
  let firstSalesRepForBuyer = '';
  if (userConfig.roleId == 3) {
    firstSalesRepForBuyer = userConfig.firstSalesRepForBuyer;
  }

  const date = new Date().toLocaleString() + '';

  const [notes, setNotes] = useState(null);
  useEffect(() => {
    setNotes(data?.note);
  }, [data, open]);

  return {
    notes,
    setNotes,
    firstSalesRepForBuyer,
    date,
  };
}
