import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

export default function CloseButton({ onClick }) {
  return (
    <Button variant='outlined' color='secondary' onClick={onClick}>
      Cancel
    </Button>
  );
}

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
