import React from 'react';
import PropTypes from 'prop-types';

export default function InformationIcon({ fill, sx }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill={fill}
      style={sx}
    >
      <path d='M24,0A24,24,0,1,0,48,24,24.027,24.027,0,0,0,24,0Zm0,43.636A19.636,19.636,0,1,1,43.636,24,19.658,19.658,0,0,1,24,43.636Z' />
      <path
        d='M147.911,70a2.909,2.909,0,1,0,2.909,2.91A2.913,2.913,0,0,0,147.911,70Z'
        transform='translate(-123.911 -59.818)'
      />
      <path
        d='M152.182,140A2.182,2.182,0,0,0,150,142.182v13.091a2.182,2.182,0,0,0,4.364,0V142.182A2.182,2.182,0,0,0,152.182,140Z'
        transform='translate(-128.182 -119.636)'
      />
    </svg>
  );
}

InformationIcon.propTypes = {
  fill: PropTypes.string,
  sx: PropTypes.object,
};

InformationIcon.defaultProps = {
  fill: '#ff0033',
  sx: {},
};
