import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import WarningIcon from 'assets/icons/warning-icon';
import SuccessIcon from 'assets/icons/success-icon';
import { alertModalStyles as styles } from './styles';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import { defaultProps } from 'cleave.js/react';
import RelaxIcon from 'assets/icons/relax-icon';
import { responsiveBreakpoints } from 'utils/utils';

const AlertModal = ({
  open,
  setOpen,
  title,
  message,
  type,
  messagesx,
  disableBackdropClose,
  hideCloseIcon,
  handleOkClick,
}) => {
  const handleClose = () => {
    setOpen(false);
  };
  const handleBackdropClick = (event) => {
    if (disableBackdropClose) {
      event.stopPropagation();
    }
  };
  const { mobile } = responsiveBreakpoints();
  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={styles.modal}
      BackdropProps={{
        onClick: handleBackdropClick,
      }}
    >
      <Box
        sx={[
          { position: 'relative' },
          mobile
            ? {
                backgroundColor: 'var(--paper-background-color)',
                // border: 0,
                outline: 'none',
                borderRadius: '6px',
                margin: '0 20px 0 20px',
                padding: '49px 8px 48px 8px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
              }
            : styles.container,
        ]}
      >
        {!hideCloseIcon ? (
          <IconButton
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
            }}
            onClick={(e) => {
              e.preventDefault();
              handleClose();
            }}
          >
            <Close />
          </IconButton>
        ) : null}
        <Stack alignItems='center' spacing={2}>
          {type === 'success' ? <SuccessIcon /> : null}
          {type === 'warning' ? <WarningIcon /> : null}
          {type === 'email' ? <RelaxIcon /> : null}
          <Stack spacing={1} sx={{ paddingTop: '10px' }}>
            <Typography variant='h1' sx={[styles.title, messagesx]}>
              {title}
            </Typography>
            <Typography variant='body1' sx={styles.message}>
              {message}
            </Typography>
          </Stack>
          <Button
            variant='contained'
            onClick={(e) => {
              e.preventDefault();
              handleClose();
              handleOkClick();
            }}
          >
            Okay
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

AlertModal.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  messagesx: PropTypes.object,
  disableBackdropClose: PropTypes.bool,
  hideCloseIcon: PropTypes.bool,
  handleOkClick: PropTypes.func,
};
AlertModal.defaultProps = {
  messagesx: {},
  message: '',
  disableBackdropClose: false,
  hideCloseIcon: false,
  handleOkClick: () => {},
};

export default AlertModal;
