import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { activeDraftSuggestionSelector } from 'redux/selectors';
import { getActiveDraftSuggestion } from 'redux/actions/global-actions';

import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { useTheme } from '@mui/material/styles';

import DraftsQuickPanelIconM from 'assets/icons/icons-for-mobile/drafts-quick-panel-icon-m';
import HomeIconM from 'assets/icons/icons-for-mobile/home-icon-m';
import SuggestionsQuickPanelIconM from 'assets/icons/icons-for-mobile/suggestion-quick-panel-icon-m';
import NotificationsIconM from 'assets/icons/icons-for-mobile/notifications-icon-m';
import AccountIconM from 'assets/icons/icons-for-mobile/account-icon-m';
import NotificationsModal from './notifications/notifications-modal-m';
import TiltScreenMessage from './components/tilt-screen/tilt-screen';

const BottomNavigationMenu = ({ value, setValue }) => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activeDraftSuggestion = useSelector(activeDraftSuggestionSelector);
  const [showNotification, setShowNotification] = useState(false);
  const navigationData = {
    0: '/',
    1: `/suggestions/${activeDraftSuggestion?.suggestion?.id}`,
    2: '/',
    3: `/drafts/${activeDraftSuggestion?.draft?.id}`,
  };
  useEffect(() => {
    if (
      !activeDraftSuggestion?.draft?.id ||
      !activeDraftSuggestion?.suggestion?.id
    )
      dispatch(getActiveDraftSuggestion());
  }, [activeDraftSuggestion?.draft?.id, activeDraftSuggestion?.suggestion?.id]);

  const routeToShowTiltMessage = [
    '/order-payment',
    '/my-customer/buyers',
    '/my-customer/groups',
    '/my-attache/reports?id=',
  ];

  const currentRoute = location?.pathname + location?.search;

  const shouldShowTiltMessage = routeToShowTiltMessage.some((route) =>
    currentRoute.startsWith(route)
  );

  return (
    <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 999 }}>
      {shouldShowTiltMessage ? <TiltScreenMessage /> : null}
      {showNotification ? (
        <NotificationsModal
          open={showNotification}
          setOpen={(bool) => {
            setShowNotification(bool);
            if (!bool) setValue('');
          }}
        />
      ) : null}
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          if (newValue == 2) {
            setShowNotification(true);
          } else navigate(navigationData[newValue]);
        }}
      >
        <BottomNavigationAction
          label='Home'
          icon={
            <HomeIconM
              stroke={`${value == 0 ? theme.palette.primary.main : '#292d32'}`}
            />
          }
        />
        <BottomNavigationAction
          label='Suggestions'
          icon={
            <SuggestionsQuickPanelIconM
              stroke={`${value == 1 ? theme.palette.primary.main : '#292d32'}`}
            />
          }
        />
        <BottomNavigationAction
          label='Notifications'
          icon={
            <NotificationsIconM
              stroke={`${value == 2 ? theme.palette.primary.main : '#292d32'}`}
            />
          }
        />
        <BottomNavigationAction
          label='Drafts'
          icon={
            <DraftsQuickPanelIconM
              stroke={`${value == 3 ? theme.palette.primary.main : '#292d32'}`}
            />
          }
        />
        <BottomNavigationAction
          label='Account'
          icon={
            <AccountIconM
              stroke={`${value == 4 ? theme.palette.primary.main : '#292d32'}`}
            />
          }
        />
      </BottomNavigation>
    </Box>
  );
};
BottomNavigationMenu.propTypes = {
  value: PropTypes.number,
  setValue: PropTypes.func,
};
BottomNavigationMenu.defaultProps = {
  value: 0,
  setValue: () => {},
};
export default BottomNavigationMenu;
