import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';
import { loggedInUserSelector } from 'redux/selectors';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { CircularProgress, Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import LoadingButton from '@mui/lab/LoadingButton';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';

import SelectBuyer from './components/select-buyers';
import SendMessage from './components/send-message';
import Review from './components/review/review';
import useSendEmail from './send-email-hook';
import SendingEmailsModal from './components/sending-emails-modal';
import SelectOld from 'components/select/select.old';
import Page from 'components/page';
import TextField from 'components/text-field';
import Alert from 'components/alert/alert';
import AlertModal from 'components/modals/alert/alert-modal';
import { showSnackbar } from 'components/snackbar/actions';
import { responsiveBreakpoints } from 'utils/utils';
import Confirm from 'components/modals/confirmation-modal';
import ScheduleEmailModal from '../../components/modals/send-email/schedule-email-modal';
import RecipientsAccordion from './components/recipients-accordion/recipients-accordion';
import AddExternalContact from 'components/add-external-contact/add-external-contact';
import SelectSenderSignature from 'components/Select-Sender-Signature/select-sender-signature';
import CopySalesTeam from './components/copy-sales-team';
import { sentEmailSteps } from './constants';
import DragAndDropImage from './components/image-dnd/image-dnd';
import Steps from './components/steps/steps';
import MyModal from 'components/modals/modal';
import CustomButton from './components/custom-button/custom-button';
import DraggableContainer from './components/draggable/draggable-container';

export default function SendEmail({
  open,
  setOpen,
  worksheetId,
  worksheetCollectionId,
  orderLink,
}) {
  const {
    selectedBuyers,
    setSelectedBuyers,
    selectedAssociates,
    setSelectedAssociates,
    subject,
    setSubject,
    editorValue,
    setEditorValue,
    message,
    setMessage,
    timeline,
    setTimeline,
    submit,
    emailRequests,
    sendingEmails,
    setSendingEmails,
    client,
    sender,
    setSender,
    showInventory,
    setShowInventory,
    uploadImage,
    handleImageSelect,
    onFileDelete,
    selectedSender,
    setSelectedSender,
    selectedSignature,
    setSelectedSignature,
    subjectEmptyError,
    setSubjectEmptyError,
    showPreview,
    setShowPreview,
    showSendingEmailAlertModal,
    suggestionPage,
    setShowSendingEmailAlertModal,
    draftLink,
    sendinviteEmail,
    emailCenterSendEmail,
    isBuyersListExceeded,
    setIsBuyersListExceeded,
    sendEmail,
    pageDetails,
    isAllBuyerSelected,
    setIsAllBuyerSelected,
    groups,
    setGroups,
    salesRep,
    setSalesRep,
    messageEmptyError,
    setMessageEmptyError,
    isSelectAll,
    setIsSelectAll,
    emailValue,
    setEmailValue,
    totalBuyersSelected,
    setTotalBuyersSelected,
    isLoading,
    setIsLoading,
    onFirstTimeLoad,
    setOnFirstTimeLoad,
    getPreviewEmailContent,
    navigate,
    previewMessage,
    previewSubject,
    suggestionEmailpayloadData,
    draftLinkPayloadData,
    uploadFileLoader,
    setUploadFileLoader,
    selectedFiles,
    setSelectedFiles,
    uploadImageLoader,
    getPreviewLoader,
    attachedFileCount,
    isError,
    setIsError,
    fileLimit,
    isCopyMeSelected,
    setIsCopyMeSelected,
    selectedExternalContacts,
    setSelectedExternalContacts,
    showSuggestionWarningModal,
    setShowSuggestionWarningModal,
    showCopyAssociateComponent,
    showScheduleEmailModal,
    setShowScheduleEmailModal,
    timeZoneOptions,
    selectedTimeZone,
    timeZoneValue,
    setTimeZoneValue,
    getTimeZoneOptions,
    setSendScheduleEmail,
    setScheduleDetails,
    emailCenterReferenceId,
    sendTestEmailDisable,
    setSendTestEmailDisable,
    writeMessageOrder,
    handleMessageDrag,
    expandedRecipients,
    setExpandedRecipients,
    button,
    setButton,
    sendinviteEmailData,
  } = useSendEmail({
    setOpen,
    worksheetCollectionId,
  });
  const { mobile, tablet } = responsiveBreakpoints();
  const dispatch = useDispatch();
  const isEmailCenter = useMatch('/send-email/email-center');
  const htmlRegex = /<[^>]*>/;
  const isWriteMessagePage = timeline === 2;
  const isInValidFields =
    subject == null ||
    subject.trim() == '' ||
    message.trim() == '' ||
    message.trim() === '<p></p>'.trim();

  const onReview = ({ showPreviewModal = false } = {}) => {
    if (isInValidFields) {
      if (message.trim() == '' || message.trim() === '<p></p>'.trim()) {
        setMessageEmptyError(true);
      } else {
        setMessageEmptyError(false);
      }
      if (subject == null || subject.trim() == '') {
        setSubjectEmptyError(true);
      } else {
        setSubjectEmptyError(false);
      }
      return;
    } else {
      setSubjectEmptyError(false);
      setMessageEmptyError(false);
      getPreviewEmailContent({ showPreviewModal });
    }
  };

  const nextStep = (
    <Button
      variant='contained'
      disabled={
        !(
          totalBuyersSelected ||
          selectedAssociates?.length ||
          selectedExternalContacts.length
        )
      }
      onClick={(e) => {
        window.scrollTo(0, 0);
        e.preventDefault();
        if (user.roleId === '3' && timeline === 1) {
          setTimeline(timeline + 2);
          onReview();
        } else if (isWriteMessagePage) {
          !isInValidFields && setTimeline(timeline + 1);
          onReview();
        } else {
          setTimeline(timeline + 1);
        }
      }}
    >
      Next Step
    </Button>
  );

  const sendLink = (
    <LoadingButton
      variant='contained'
      loading={getPreviewLoader}
      onClick={async () => {
        setShowPreview(false);
        if (suggestionPage) {
          setShowPreview(false);
          setShowSuggestionWarningModal(true);
        } else {
          if (isBuyersListExceeded) {
            setIsLoading(true);
            let response = await sendEmail();
            if (response?.data?.status) {
              setIsLoading(false);
              setShowPreview(false);
              setShowSendingEmailAlertModal(true);
            } else {
              setIsLoading(false);
              dispatch(
                showSnackbar({
                  snackbarMessage: 'Failed To Send Email',
                  type: 'error',
                  snackbarAlign: {
                    vertical: 'top',
                    horizontal: 'right',
                  },
                })
              );
            }
          } else {
            submit();
          }
        }

        // setSendingEmails(true);
        // setShowSendingEmailAlertModal(true);
        // handleClose();
      }}
    >
      Confirm & Send Email
    </LoadingButton>
  );

  const sendTestEmail = (
    <Button
      variant='outlined'
      color='secondary'
      disabled={sendTestEmailDisable}
      onClick={async () => {
        setSendTestEmailDisable(true);
        let obj = { task: 'sendTestEmail' };
        let response = await sendEmail(true, obj);
        if (response?.data?.status) {
          dispatch(
            showSnackbar({
              snackbarMessage: 'Test Email Sent.',
              type: 'success',
              snackbarAlign: {
                vertical: 'top',
                horizontal: 'right',
              },
            })
          );
        } else {
          setIsLoading(false);
          dispatch(
            showSnackbar({
              snackbarMessage: 'Failed To Send Email',
              type: 'error',
              snackbarAlign: {
                vertical: 'top',
                horizontal: 'right',
              },
            })
          );
          setSendTestEmailDisable(false);
        }
      }}
    >
      Send Test Email
    </Button>
  );

  const scheduleEmail = (
    <Button
      variant='outlined'
      color='secondary'
      onClick={() => {
        getTimeZoneOptions();
      }}
    >
      Schedule Email
    </Button>
  );

  const suggestionWarningModalProceed = (
    <Button
      variant='contained'
      onClick={async () => {
        setShowSuggestionWarningModal(false);
        if (isBuyersListExceeded) {
          setIsLoading(true);
          let response = await sendEmail();
          if (response?.data?.status) {
            setIsLoading(false);
            setShowPreview(false);
            setShowSendingEmailAlertModal(true);
          } else {
            setIsLoading(false);
            dispatch(
              showSnackbar({
                snackbarMessage: 'Failed To Send Email',
                type: 'error',
                snackbarAlign: {
                  vertical: 'top',
                  horizontal: 'right',
                },
              })
            );
          }
        } else {
          submit();
        }

        // setSendingEmails(true);
        // setShowSendingEmailAlertModal(true);
        // handleClose();
      }}
    >
      Yes, Proceed
    </Button>
  );
  const back = (
    <Button
      variant='outlined'
      onClick={(e) => {
        e.preventDefault();
        setTimeline(timeline - 1);
      }}
    >
      Back
    </Button>
  );

  const preview = (
    <LoadingButton
      loading={getPreviewLoader}
      disabled={
        getPreviewLoader ||
        selectedBuyers.length > 0 ||
        (suggestionPage ? selectedExternalContacts.length > 0 : false) ||
        (sendinviteEmail ? false : selectedAssociates.length > 0)
          ? false
          : true
      }
      variant='outlined'
      color='secondary'
      onClick={() => onReview({ showPreviewModal: true })}
    >
      Preview
    </LoadingButton>
  );

  // logic to make BuyersList Exceede
  useEffect(() => {
    if (selectedBuyers.length > -1) {
      setIsBuyersListExceeded(true);
    } else {
      if (isBuyersListExceeded) {
        setIsBuyersListExceeded(false);
      }
    }
  }, [selectedBuyers]);
  const user = useSelector(loggedInUserSelector);

  return (
    <Page
      heading={pageDetails?.title}
      backButtonText={pageDetails?.backButtonText}
      backButtonHref={pageDetails?.backButtonUrl}
    >
      {isLoading ? (
        <Box
          width='100vw'
          height='100vh'
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 9999,
            opacity: 0.4,
            backgroundColor: '#000',
          }}
        >
          <CircularProgress />
        </Box>
      ) : null}
      <SendingEmailsModal
        open={sendingEmails}
        setOpen={(bool) => {
          if (!bool) {
            navigate(pageDetails?.backButtonUrl);
            setSendingEmails(bool);
          }
        }}
        emailRequests={emailRequests}
        openParentModal={open}
        setOpenParentModal={setOpen}
        showInventory={showInventory}
        selectedSender={selectedSender}
        selectedSignature={selectedSignature}
      />
      {showSuggestionWarningModal ? (
        <Confirm
          open={showSuggestionWarningModal}
          setOpen={setShowSuggestionWarningModal}
          action={suggestionWarningModalProceed}
          title='Are you sure?'
          message={
            <Typography
              variant='h3'
              sx={{
                fontWeight: 500,
                color: '#333333BF',
              }}
            >
              One or more buyers do not have visibility to certain products in
              your suggestion list. Are you sure you want to proceed?
            </Typography>
          }
        />
      ) : null}
      {showSendingEmailAlertModal ? (
        <AlertModal
          open={showSendingEmailAlertModal}
          setOpen={setShowSendingEmailAlertModal}
          title='Sending emails to selected buyers…'
          message="Sit back and relax and keep an eye on the bell icon, you'll receive a notification as soon as all the emails are sent."
          type='email'
          disableBackdropClose={true}
          hideCloseIcon={true}
          handleOkClick={() => {
            navigate(pageDetails.backButtonUrl);
          }}
        />
      ) : null}
      {isError?.open ? (
        <AlertModal
          type='warning'
          title={isError?.message}
          open={isError?.open}
          setOpen={(bool) => {
            setIsError({
              open: bool,
              message: bool ? 'File upload limit is 3' : '',
            });
          }}
        />
      ) : null}

      {showScheduleEmailModal ? (
        <ScheduleEmailModal
          open={showScheduleEmailModal}
          setOpen={setShowScheduleEmailModal}
          timeZoneOptions={timeZoneOptions}
          selectedTimeZone={selectedTimeZone}
          setSendScheduleEmail={setSendScheduleEmail}
          setScheduleDetails={setScheduleDetails}
          sendEmail={sendEmail}
          setIsLoading={setIsLoading}
          setShowPreview={setShowPreview}
          setShowSendingEmailAlertModal={setShowSendingEmailAlertModal}
        />
      ) : null}

      <Stack
        sx={{
          flexDirection: mobile || tablet ? 'column' : 'row',
          gap: 4,
          width: '100%',
          boxSizing: 'border-box',
        }}
      >
        <Steps step={timeline} setStep={setTimeline} />
        <Stack sx={{ flex: 1, overflow: 'auto', gap: 2 }}>
          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <Stack sx={{ flex: 1 }}>
              <Typography sx={{ fontSize: '16px', fontWeight: 800 }}>
                {sentEmailSteps[timeline]?.title}
              </Typography>
            </Stack>
            <Stack
              sx={{ flexDirection: 'row', gap: 1, flexWrap: 'wrap', ml: '8px' }}
            >
              {timeline === 1 && (
                <Alert>
                  <Typography variant='body2' sx={{ color: '#8D5600' }}>
                    Select at least one Recipient to go next.
                  </Typography>
                </Alert>
              )}
              {!sendinviteEmail && timeline === 3 ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isCopyMeSelected}
                      sx={{ marginRight: '5px' }}
                    />
                  }
                  label={
                    <Typography variant='body1'>
                      Send me a copy of this email
                    </Typography>
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setIsCopyMeSelected(!isCopyMeSelected);
                  }}
                  sx={{
                    marginLeft: 0,
                    width: 'fit-content',
                  }}
                />
              ) : null}
              {timeline > 1 && back}
              {timeline === 2 && preview}
              {timeline < 3 && nextStep}
              {timeline === 3 && (
                <>
                  {isEmailCenter && (
                    <>
                      {sendTestEmail}
                      {scheduleEmail}
                    </>
                  )}
                  {sendLink}
                </>
              )}
            </Stack>
          </Stack>
          <Stack>
            {/* Select Recipients  */}
            {timeline === 1 && (
              <Stack>
                <RecipientsAccordion
                  title='Buyers'
                  subTitle={`${totalBuyersSelected} ${
                    totalBuyersSelected > 1 ? 'Buyers' : 'Buyer'
                  } Selected`}
                  expanded={expandedRecipients}
                  setExpanded={setExpandedRecipients}
                  isSelected={totalBuyersSelected}
                >
                  <SelectBuyer
                    selectedBuyers={selectedBuyers}
                    setSelectedBuyers={setSelectedBuyers}
                    worksheetId={worksheetId}
                    sendinviteEmail={sendinviteEmail}
                    isAllBuyerSelected={isAllBuyerSelected}
                    setIsAllBuyerSelected={setIsAllBuyerSelected}
                    isGroup={false}
                    salesRep={salesRep}
                    setSalesRep={setSalesRep}
                    isSelectAll={isSelectAll}
                    setIsSelectAll={setIsSelectAll}
                    emailValue={emailValue}
                    setEmailValue={setEmailValue}
                    setTotalBuyersSelected={setTotalBuyersSelected}
                  />
                </RecipientsAccordion>
                <RecipientsAccordion
                  title='Groups'
                  expanded={expandedRecipients}
                  setExpanded={setExpandedRecipients}
                  subTitle={`${totalBuyersSelected} ${
                    totalBuyersSelected > 1 ? 'Buyers' : 'Buyer'
                  } Selected`}
                  isSelected={selectedBuyers?.length}
                >
                  <SelectBuyer
                    selectedBuyers={selectedBuyers}
                    setSelectedBuyers={setSelectedBuyers}
                    worksheetId={worksheetId}
                    sendinviteEmail={sendinviteEmail}
                    isAllBuyerSelected={isAllBuyerSelected}
                    setIsAllBuyerSelected={setIsAllBuyerSelected}
                    groups={groups}
                    setGroups={setGroups}
                    salesRep={salesRep}
                    setSalesRep={setSalesRep}
                    isSelectAll={isSelectAll}
                    setIsSelectAll={setIsSelectAll}
                    emailValue={emailValue}
                    setEmailValue={setEmailValue}
                    setTotalBuyersSelected={setTotalBuyersSelected}
                  />
                </RecipientsAccordion>
                {showCopyAssociateComponent && user?.roleId !== '3' ? (
                  <RecipientsAccordion
                    title='Co-Workers'
                    subTitle={`${selectedAssociates?.length} ${
                      selectedAssociates?.length > 1
                        ? 'Co-Workers'
                        : 'Co-Worker'
                    } Selected`}
                    expanded={expandedRecipients}
                    setExpanded={setExpandedRecipients}
                    isSelected={selectedAssociates?.length}
                  >
                    <CopySalesTeam
                      selectedAssociates={selectedAssociates}
                      setSelectedAssociates={setSelectedAssociates}
                      sx={{
                        height: '307px',
                      }}
                    />
                  </RecipientsAccordion>
                ) : null}

                {suggestionPage || emailCenterSendEmail ? (
                  <RecipientsAccordion
                    title='Outside Contacts'
                    subTitle={`${selectedExternalContacts.length} ${
                      selectedExternalContacts.length > 1
                        ? 'Outside Contacts'
                        : 'Outside Contact'
                    } Selected`}
                    expanded={expandedRecipients}
                    setExpanded={setExpandedRecipients}
                    isSelected={selectedExternalContacts?.length}
                  >
                    <AddExternalContact
                      selectedItems={selectedExternalContacts}
                      setSelectedItems={setSelectedExternalContacts}
                      showHeader={false}
                    />
                  </RecipientsAccordion>
                ) : null}
              </Stack>
            )}

            {/* Write Message  */}
            {timeline === 2 && (
              <Stack
                sx={{
                  backgroundColor: '#F5F5F5',
                  borderRadius: '8px',
                  padding: '26px',
                  display: 'flex',
                  flexDirection: 'column',
                  border: '0.7px solid #A9A9A9',
                  boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.05)',
                  gap: 1.5,
                }}
              >
                {sendinviteEmail ? (
                  <Stack
                    spacing={1}
                    width='100%'
                    sx={{
                      borderBottom: '0.7px solid #E7E7E7',
                      pb: 1.5,
                    }}
                  >
                    <Typography variant='body2'>
                      Select Sender and Signature
                    </Typography>
                    <SelectSenderSignature
                      selectedSender={selectedSender}
                      setSelectedSender={setSelectedSender}
                      selectedSignature={selectedSignature}
                      setSelectedSignature={setSelectedSignature}
                      sendinviteEmailData={sendinviteEmailData}
                    />
                  </Stack>
                ) : null}

                {suggestionPage ? (
                  <Stack
                    sx={{
                      borderBottom: '0.7px solid #E7E7E7',
                      pb: 1.5,
                    }}
                  >
                    <Stack
                      direction='row'
                      spacing={1}
                      sx={{
                        alignItems: 'center',
                        width: '15%',
                        minWidth: '112px',
                      }}
                    >
                      <SelectOld
                        placeholder='Select Sender'
                        options={[
                          { label: 'Sales Rep', value: 'sr' },
                          { label: `${client.name}`, value: 'brand' },
                        ]}
                        label='Select Sender:'
                        value={sender}
                        setValue={setSender}
                        allowRemoveIcon={false}
                      />
                    </Stack>
                  </Stack>
                ) : null}

                {draftLink ? (
                  <Stack sx={{ borderBottom: '0.7px solid #E7E7E7', pb: 1.5 }}>
                    <FormControlLabel
                      sx={{
                        marginLeft: 0,
                        width: 'fit-content',
                      }}
                      control={
                        <Checkbox
                          checked={showInventory}
                          sx={{ marginRight: '5px' }}
                        />
                      }
                      label={
                        <Typography variant='body1'>Show Inventory</Typography>
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setShowInventory(!showInventory);
                      }}
                    />
                  </Stack>
                ) : null}

                {suggestionPage || draftLink || emailCenterSendEmail ? (
                  <Stack
                    spacing={1}
                    sx={{
                      borderBottom: '0.7px solid #E7E7E7',
                      pb: 1.5,
                    }}
                  >
                    <Typography variant='body2'>Add Attachments:</Typography>
                    <Stack
                      direction='row'
                      alignItems='center'
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        rowGap: '8px',
                        columnGap: '16px',
                      }}
                    >
                      <LoadingButton
                        loading={uploadFileLoader}
                        disabled={
                          uploadFileLoader || attachedFileCount >= fileLimit
                        }
                        startIcon={
                          <AttachFileOutlinedIcon
                            color='#4D4663'
                            style={{
                              transform: 'scaleX(-1) rotate(-45deg)', // Adjust the angle as needed
                              color:
                                uploadFileLoader ||
                                attachedFileCount >= fileLimit
                                  ? 'rgba(0, 0, 0, 0.26)'
                                  : 'var(--primary-font-color)',
                            }}
                          />
                        }
                        size='small'
                        sx={{
                          backgroundColor: '#fff',
                          width: 'fit-content',
                          border: '1px solid var(--primary-border-color)',
                          borderRadius: '4px',
                          padding: '8px 12px',
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          document.getElementById('uploadFiles').click();
                        }}
                      >
                        <Typography
                          variant='body1'
                          sx={{
                            marginLeft: '6px',
                            color:
                              uploadFileLoader || attachedFileCount >= fileLimit
                                ? 'rgba(0, 0, 0, 0.26)'
                                : 'var(--primary-font-color)',
                          }}
                        >
                          Attach Files
                        </Typography>
                      </LoadingButton>
                      {Array.isArray(selectedFiles) &&
                      selectedFiles?.length == 0 ? (
                        <Typography variant='body2'>No File Chosen</Typography>
                      ) : (
                        Array.isArray(selectedFiles) &&
                        selectedFiles?.map((file) => (
                          <Chip
                            key={`file_${file?.id}`}
                            style={{
                              backgroundColor: '#FFFFFF',
                            }}
                            label={
                              <Stack
                                direction='row'
                                spacing={0.5}
                                alignItems='center'
                              >
                                {file?.filetype == 'file' ? (
                                  <AttachFileOutlinedIcon
                                    style={{
                                      transform: 'scaleX(-1) rotate(-45deg)', // Adjust the angle as needed
                                      color: 'primary.main !important',
                                      width: 'auto',
                                      height: '18px',
                                    }}
                                  />
                                ) : (
                                  <InsertPhotoOutlinedIcon
                                    style={{
                                      color: 'primary.main !important',
                                      width: 'auto',
                                      height: '18px',
                                    }}
                                  />
                                )}

                                <Typography
                                  variant='body1'
                                  color='primary.main'
                                >
                                  {file?.actual_filename}
                                </Typography>
                              </Stack>
                            }
                            variant='outlined'
                            onDelete={() => onFileDelete(file)}
                            sx={{
                              marginBottom: '5px',
                              fontWeight: '700',
                              marginRight: '8px',
                              width: 'fit-content',
                              borderRadius: '16px',
                              height: '32px',
                              backgroundColor: '#F2F2F2',
                              borderColor: 'var(--secondary-border-color)',
                              color: 'primary.main',
                              '& .MuiSvgIcon-root': {
                                color: 'primary.main',
                              },
                            }}
                          />
                        ))
                      )}

                      {/* multiple files input component */}
                      <input
                        id='uploadFiles'
                        type='file'
                        multiple
                        accept='.pdf, .xls, .xlsx, .csv, .doc, .docx, .jpg, .jpeg, .png, .ppt, .pptx, .zip'
                        hidden
                        onChange={async (e) => {
                          let file = e.target.files;

                          if (!file || file.length === 0) {
                            return; // Exit if no files are selected
                          }
                          if (file?.length + attachedFileCount > fileLimit) {
                            setIsError({
                              open: true,
                              message: `A maximum of ${fileLimit} files can be uploaded.`,
                            });
                            return;
                          }
                          if (
                            Array.from(file)?.some(
                              (file) => file?.size > 5 * 1024 * 1024
                            )
                          ) {
                            setIsError({
                              open: true,
                              message: 'Each file must not exceed 5MB in size',
                            });
                            return;
                          }

                          setUploadFileLoader(true);
                          let formData = new FormData();
                          if (draftLink) {
                            formData.append('upload_files', true);
                            formData.append(
                              'worksheet_id',
                              draftLinkPayloadData?.worksheetId
                            );
                            formData.append(
                              'worksheet_collection_id',
                              draftLinkPayloadData?.worksheetCollectionId
                            );
                            formData.append('type', 'proposal');
                            for (let i = 0; i < file.length; i++) {
                              formData.append('files[]', file[i]);
                            }
                          }
                          if (suggestionPage) {
                            formData.append('upload_files', true);
                            formData.append(
                              'suggestion_id',
                              suggestionEmailpayloadData?.suggestionId
                            );
                            formData.append('type', 'suggestion');

                            for (let i = 0; i < file.length; i++) {
                              formData.append('files[]', file[i]);
                            }
                          }
                          if (emailCenterSendEmail) {
                            formData.append('upload_files', true);
                            formData.append('type', 'emailCenter');
                            formData.append(
                              'emailCenterReferenceId',
                              emailCenterReferenceId
                            );

                            for (let i = 0; i < file.length; i++) {
                              formData.append('files[]', file[i]);
                            }
                          }

                          let res = await uploadImage(formData, true);
                          if (res) {
                            setSelectedFiles(res?.data?.data ?? []);
                            setUploadFileLoader(false);
                          } else {
                            setUploadFileLoader(false);
                          }
                        }}
                      ></input>
                    </Stack>
                    <Alert>
                      <Typography variant='body2' sx={{ color: '#8D5600' }}>
                        Each file must be no larger than 5 MB. Supported file
                        types include: PDF, XLS, XLSX, CSV, DOC, DOCX, JPG, PNG,
                        PPT, and ZIP. You may add up to 3 attachments.
                      </Typography>
                    </Alert>
                  </Stack>
                ) : null}

                {orderLink ? (
                  <Stack
                    direction='column'
                    spacing={0.5}
                    sx={{ borderBottom: '0.7px solid #E7E7E7', pb: 1.5 }}
                  >
                    <Typography variant='body2'>PDF Title</Typography>
                    <TextField
                      label=''
                      placeholder='Add PDF Title'
                      sx={{ width: 'max-content' }}
                      value={subject}
                      onChange={(e) => {
                        e.preventDefault();
                        setSubject(e.target.value);
                      }}
                    />
                  </Stack>
                ) : null}

                <Stack
                  spacing={0.5}
                  sx={{ borderBottom: '0.7px solid #E7E7E7', pb: 1.5 }}
                >
                  <Typography variant='body2'>Subject</Typography>

                  <TextField
                    error={subjectEmptyError}
                    helperText={
                      subjectEmptyError ? 'Subject cannot be empty' : ''
                    }
                    label=''
                    placeholder='Add Subject'
                    sx={{ width: '100%' }}
                    value={subject}
                    onChange={(e) => {
                      e.preventDefault();
                      if (e.target.value.length == 0) {
                        setSubjectEmptyError(true);
                      } else {
                        setSubjectEmptyError(false);
                      }

                      setSubject(e.target.value);
                    }}
                  />
                </Stack>

                <DragDropContext onDragEnd={handleMessageDrag}>
                  <Droppable droppableId='droppable' direction='vertical'>
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {writeMessageOrder.map((itemId, index) => (
                          <Draggable
                            key={itemId}
                            draggableId={itemId}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  marginBottom: '14px',
                                }}
                              >
                                {/* Display the content based on item ID */}
                                {itemId === 'logo' && (
                                  <DraggableContainer
                                    dragHandleProps={provided.dragHandleProps}
                                    sx={{ height: '85px' }}
                                  >
                                    <Box
                                      component='img'
                                      src={client?.media?.logo}
                                      alt='Preview'
                                      sx={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'contain',
                                      }}
                                    />
                                  </DraggableContainer>
                                )}
                                {itemId === 'message' && (
                                  <DraggableContainer
                                    dragHandleProps={provided.dragHandleProps}
                                    sx={{
                                      cursor: 'text',
                                      padding: 0,
                                      alignItems: 'none',
                                      justifyContent: 'none',
                                      border: 'none',
                                      transform: 'translate(0, -8px)',
                                    }}
                                  >
                                    <SendMessage
                                      editorValue={editorValue}
                                      setEditorValue={setEditorValue}
                                      message={message}
                                      setMessage={(message) => {
                                        setMessage(message);
                                        if (onFirstTimeLoad) {
                                          setOnFirstTimeLoad(false);
                                        }
                                      }}
                                      subject={subject}
                                      setSubject={setSubject}
                                      setSubjectEmptyError={
                                        setSubjectEmptyError
                                      }
                                      subjectEmptyError={subjectEmptyError}
                                      showSubjectField={false}
                                      showPdfTitle={false}
                                      messageEmptyError={messageEmptyError}
                                      setMessageEmptyError={
                                        setMessageEmptyError
                                      }
                                      onFirstTimeLoad={
                                        htmlRegex.test(message) ? false : true
                                      }
                                    />
                                  </DraggableContainer>
                                )}
                                {itemId === 'button' && button && (
                                  <DraggableContainer
                                    dragHandleProps={provided.dragHandleProps}
                                    {...(!pageDetails?.button && {
                                      onRemove: () => setButton(null),
                                    })}
                                  >
                                    {pageDetails?.button ? (
                                      <Button variant='contained'>
                                        {pageDetails?.button}
                                      </Button>
                                    ) : (
                                      <CustomButton
                                        button={button}
                                        onSaveButton={setButton}
                                      />
                                    )}
                                  </DraggableContainer>
                                )}
                                {itemId === 'image' &&
                                  (suggestionPage ||
                                    draftLink ||
                                    emailCenterSendEmail) && (
                                    <DraggableContainer
                                      dragHandleProps={provided.dragHandleProps}
                                      sx={{
                                        padding: 0,
                                        border: 'none',
                                        transform: 'translate(0, -8px)',
                                      }}
                                    >
                                      <DragAndDropImage
                                        onFileUpload={handleImageSelect}
                                        uploadImageLoader={uploadImageLoader}
                                        onDelete={() =>
                                          onFileDelete(
                                            selectedFiles?.find(
                                              (item) =>
                                                item?.filetype === 'image'
                                            )
                                          )
                                        }
                                        file={selectedFiles?.find(
                                          (item) => item?.filetype === 'image'
                                        )}
                                      />
                                    </DraggableContainer>
                                  )}
                              </div>
                            )}
                          </Draggable>
                        ))}

                        <div>{provided.placeholder}</div>
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Stack>
            )}

            {/* Review & Send */}
            {timeline === 3 &&
              (getPreviewLoader ? (
                <Skeleton variant='rounded' width='100%' height='70vh' />
              ) : (
                <Review
                  open={timeline === 3}
                  subject={previewSubject == '' ? subject : previewSubject}
                  message={previewMessage == '' ? message : previewMessage}
                  selectedBuyers={selectedBuyers}
                  selectedExternalContacts={selectedExternalContacts}
                  selectedAssociates={selectedAssociates}
                  totalBuyersSelected={totalBuyersSelected}
                  uploadedFiles={selectedFiles}
                  sx={{ backgroundColor: '#F9F9F9' }}
                />
              ))}
          </Stack>
          {showPreview ? (
            <MyModal
              open={showPreview}
              showCloseIcon={mobile}
              title={mobile ? 'Preview' : ''}
              setOpen={(bool) => {
                htmlRegex.test(message)
                  ? setOnFirstTimeLoad(false)
                  : setOnFirstTimeLoad(true);

                setShowPreview(bool);
              }}
              size={mobile ? 'fullWidth' : 'medium'}
              sx={
                mobile
                  ? { height: '100vh', width: '100vw' }
                  : { height: '90vh', width: '70vw' }
              }
            >
              <Review
                open={showPreview}
                showRecipients={false}
                subject={previewSubject == '' ? subject : previewSubject}
                message={previewMessage == '' ? message : previewMessage}
                selectedBuyers={selectedBuyers}
                selectedExternalContacts={selectedExternalContacts}
                selectedAssociates={selectedAssociates}
                totalBuyersSelected={totalBuyersSelected}
                uploadedFiles={selectedFiles}
              />
            </MyModal>
          ) : null}
        </Stack>
      </Stack>
    </Page>
  );
}
SendEmail.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  worksheetId: PropTypes.string,
  worksheetCollectionId: PropTypes.string,
  orderLink: PropTypes.string,
};

SendEmail.defaultProps = {
  open: false,
  setOpen: () => {},
  worksheetCollectionId: '',
  worksheetId: '',
  orderLink: '',
};
