import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { useQueryClient } from 'react-query';
import { getActiveDraftSuggestion } from '../../redux/actions/global-actions';

import { api } from '../../api';

export default function useCustomSuggestion() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // create suggestion
  async function createSuggestion(name, description, setOpen) {
    const res = await api.post(`/createSuggestion`, {
      name: name,
      description: description,
    });

    if (res.data.status) {
      dispatch(getActiveDraftSuggestion());
      await queryClient.invalidateQueries('getSuggestions'); // Invalidate the query to ensure fresh data
      navigate(`/suggestions/${res.data.suggestionId}?resetCatalog=true`);
      setOpen(false); //close quick panel
    }
  }

  //delete suggestion
  async function deleteSuggestion(id, setOpen) {
    const res = await api.delete(`/deleteSuggestion?id=${id}`);
    if (res.data.status) {
      const activeDraftSuggestion = await dispatch(getActiveDraftSuggestion());
      await queryClient.invalidateQueries('getSuggestions'); // Invalidate the query to ensure fresh data

      if (activeDraftSuggestion?.suggestion?.id) {
        navigate(`/suggestions/${activeDraftSuggestion.suggestion.id}`);
      }
    }
  }

  //bulk delete suggestions
  async function bulkDeleteSuggestions(ids, setOpen) {
    const res = await api.delete(`/bulkdeleteSuggestions`, {
      data: { id: ids },
    });
    if (res.data.status) {
      const activeDraftSuggestion = await dispatch(getActiveDraftSuggestion());
      await queryClient.invalidateQueries('getSuggestions'); // Invalidate the query to ensure fresh data

      if (activeDraftSuggestion?.suggestion?.id) {
        navigate(`/suggestions/${activeDraftSuggestion.suggestion.id}`);
      }
    }
  }

  //set active suggestion
  async function setActiveSuggestion(id) {
    const res = await api.get(`updateActiveSuggestion?linesheet_id=${id}`);
    if (res.data.status) {
      dispatch(getActiveDraftSuggestion());
      await queryClient.invalidateQueries('getSuggestions'); // Invalidate the query to ensure fresh data
    }
  }

  return {
    createSuggestion,
    deleteSuggestion,
    setActiveSuggestion,
    bulkDeleteSuggestions,
  };
}
