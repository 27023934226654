import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { LogLevel } from 'constants';
import LoggingService from 'utils/service/LoggingService';

export default function AddRetailerButton({ prospect, edit, getRetailers }) {
  const navigate = useNavigate();
  return (
    <Button
      onClick={(e) => {
        try {
          e.preventDefault();
          document.getElementById('submit-add-new-retailer').click();
        } catch (err) {
          LoggingService.log(
            'onClick',
            'AddRetailerButton',
            err,
            LogLevel.ERROR,
            'This error logged from add-retailer-button.jsx file line no 23',
            {}
          );
        }
      }}
      variant='contained'
    >
      {prospect
        ? 'Save And Send Email'
        : edit
        ? 'Save Changes'
        : 'Add New Retailer'}
    </Button>
  );
}

AddRetailerButton.propTypes = {
  prospect: PropTypes.bool.isRequired,
  edit: PropTypes.bool.isRequired,
  getRetailers: PropTypes.func.isRequired,
};
