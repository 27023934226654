import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';

import { useDepartmentMenuMobile } from './department-m-hook';
import DepartmentAccordion from './departmentAccordion';
const DepartmentMenuMobile = ({ open, setOpen }) => {
  const {
    departments,
    handleDepartmentMenuClick,
    handleClose,
    handleDepartmentClick,
  } = useDepartmentMenuMobile({ setOpen });

  if (open)
    return (
      <>
        <Modal open={open} onClose={handleClose} sx={{}}>
          <Box
            sx={{
              width: '70vw',
              height: '100vh',
              backgroundColor: 'var(--color-white)',
              position: 'absolute',
              zIndex: '999',
              top: '0px',
              left: '0px',
              overflowY: 'scroll',
              paddingLeft: '20px',
            }}
          >
            <Stack
              sx={{
                height: '50px',
                justifyContent: 'center',
              }}
            >
              <Typography variant='h1' sx={{ fontSize: '20px' }}>
                Departments
              </Typography>
            </Stack>
            {departments?.map((item) => {
              return (
                <Box key={item.genderId}>
                  <DepartmentAccordion
                    item={item}
                    handleDepartmentClick={handleDepartmentClick}
                    handleDepartmentMenuClick={handleDepartmentMenuClick}
                  />
                </Box>
              );
            })}
          </Box>
        </Modal>
      </>
    );
};
export default DepartmentMenuMobile;
DepartmentMenuMobile.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
DepartmentMenuMobile.defaultProps = {
  open: false,
  setOpen: () => {},
};
