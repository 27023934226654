import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import MyModal from 'components/modals/modal';
import TextField from 'components/text-field';
import useCreateOverrideOrder from './create-override-order-modal-hooks';
import { responsiveBreakpoints } from 'utils/utils';

export default function CreateOverrideOrderModal({ open, setOpen }) {
  const { overrideOrderName, setOverrideOrderName, createOverrideOrder } =
    useCreateOverrideOrder(setOpen);
  const { mobile } = responsiveBreakpoints();

  useEffect(() => {
    setOverrideOrderName('');
  }, [open]);
  const cancel = (
    <Button
      variant='outlined'
      onClick={(e) => {
        e.preventDefault();
        setOpen(false);
      }}
    >
      Cancel
    </Button>
  );

  const create = (
    <Button
      variant='contained'
      onClick={(e) => {
        e.preventDefault();
        createOverrideOrder();
      }}
    >
      Create
    </Button>
  );
  return (
    <MyModal
      open={open}
      setOpen={setOpen}
      showCloseIcon
      actions={[cancel, create]}
      size={mobile ? 'fullWidth' : 'large'}
      title='Create Override Order'
      sx={mobile ? { width: '100%' } : { width: '44vw' }}
      // sx={{ width: '780px' }}
    >
      <Box sx={[mobile ? { padding: '20px' } : null]}>
        <TextField
          label='Override Order Name'
          placeholder='Enter override order name'
          value={overrideOrderName}
          onChange={(e) => {
            e.preventDefault();
            setOverrideOrderName(e.target.value);
          }}
        />
        <Stack sx={{ marginTop: '20px' }}>
          <Typography variant='body1'>Caution!</Typography>
          <Typography variant='body1'>
            Add items to a single order despite whether they are in the same
            collection/delivery or are even active on the front end. All dates,
            deliveries and inventories are overridden, so be sure to use this
            feature only with the parameters that your sales manager has
            authorised. This feature is often used to replace custom orders for
            production.
          </Typography>
        </Stack>
      </Box>
    </MyModal>
  );
}

CreateOverrideOrderModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
