import PeopleIcon from 'assets/icons/people-icon';
import WriteMessageIcon from 'assets/icons/write-message-icon';
import SendMessageIcon from 'assets/icons/send-message';

export const sentEmailSteps = {
  1: {
    id: 1,
    title: 'Select Recipients',
    subTitle: `Buyers, Co-Workers and Outside Contacts`,
    icon: PeopleIcon,
  },
  2: {
    id: 2,
    title: 'Write Your Message',
    subTitle: 'Type in your message.',
    icon: WriteMessageIcon,
  },
  3: {
    id: 3,
    title: 'Review & Send',
    subTitle: `Finally, Review it and send.`,
    icon: SendMessageIcon,
  },
};
