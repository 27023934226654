import { BEARER_TOKEN, STAY_LOGGED_IN } from 'constants';
import LocalStorageManager from './local-storage-manager';
import SessionStorageManager from './session-storage-manager';
import { PublicScreen } from 'routes/screen-names';

export const getBearerToken = () => {
  if (isStayLoggedInSelected()) {
    return LocalStorageManager.getLocalStorage(BEARER_TOKEN);
  }
  return SessionStorageManager.getSessionStorage(BEARER_TOKEN);
};

export const setBearerToken = (token) => {
  if (isStayLoggedInSelected()) {
    return LocalStorageManager.setLocalStorage(BEARER_TOKEN, `Bearer ${token}`);
  }
  return SessionStorageManager.setSessionStorage(
    BEARER_TOKEN,
    `Bearer ${token}`
  );
};

export const removeBearerToken = () => {
  if (isStayLoggedInSelected()) {
    return LocalStorageManager.removeLocalStorage(BEARER_TOKEN);
  }
  return SessionStorageManager.removeSessionStorage(BEARER_TOKEN);
};

export const logOut = (errorMessage = '') => {
  if (isStayLoggedInSelected()) {
    LocalStorageManager.clearLocalStorage();
  } else {
    SessionStorageManager.clearSessionStorage();
  }
  if (errorMessage != '') {
    SessionStorageManager.setSessionStorage('apiError', errorMessage);
  }
  window.location = PublicScreen.login;
};

export const isAuthenticated = () => {
  const token = getBearerToken();
  if (token) {
    return true;
  }
  return false;
};

export const isStayLoggedInSelected = () => {
  const data = LocalStorageManager.getLocalStorage(STAY_LOGGED_IN);
  if (!data || data === 'false') return false;
  return true;
};
