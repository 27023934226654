import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Alert } from '@mui/material';
import InformationIcon from '../../assets/icons/information-icon';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';

import { responsiveBreakpoints } from 'utils/utils';
export default function Confirm({
  open,
  setOpen,
  title,
  message,
  alertInfoMessage,
  action,
  showCancel,
  cancelAction,
  messageAction,
  disableBackdropClose,
  hideCloseIcon,
  setVoidMessage,
  isVoidConfirmBox,
}) {
  const [localVoidmessage, setLocalVoidmessage] = useState('');
  function handleOpen() {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  };
  const handleBackdropClick = (event) => {
    if (disableBackdropClose) {
      event.stopPropagation();
    }
  };
  // console.log('showPriceChanged modal', message);
  const { mobile } = responsiveBreakpoints();
  //reset page on search value change
  useEffect(() => {
    const timer = setTimeout(() => {
      if (setVoidMessage) setVoidMessage(localVoidmessage);
    }, 1000);
    return () => clearTimeout(timer);
  }, [localVoidmessage]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      BackdropProps={{
        onClick: handleBackdropClick,
      }}
    >
      <Box
        sx={[
          mobile
            ? {
                backgroundColor: 'var(--paper-background-color)',
                border: 0,
                outline: 'none',
                borderRadius: '6px',
                margin: '0 10px 0 10px',
                width: '100%',
                height: '370px',
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
                padding: '20px',
                position: 'relative',
              }
            : {
                backgroundColor: 'var(--paper-background-color)',
                border: 0,
                outline: 'none',
                borderRadius: '6px',
                margin: '0 100px 0 100px',
                width: '480px',
                height: '370px',
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
                padding: '20px',
                position: 'relative',
              },
        ]}
      >
        {!hideCloseIcon ? (
          <IconButton
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
            }}
            onClick={(e) => {
              e.preventDefault();
              handleClose();
            }}
          >
            <Close />
          </IconButton>
        ) : null}

        <Stack
          alignItems='center'
          spacing={2}
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Stack spacing={2} alignItems='center'>
            <InformationIcon />
            <Typography
              variant='h1'
              sx={{
                fontWeight: 500,
                marginTop: '22px',
                marginBottom: '6px',
              }}
            >
              {title}
            </Typography>
            <Typography variant='body2'>{message}</Typography>
            {messageAction}
          </Stack>

          {alertInfoMessage && (
            <Alert
              severity='info'
              sx={{
                minHeight: '34px',
                alignItems: 'center',
                borderRadius: '4px',
                fontSize: '12px',
                textAlign: 'left',
                '& .MuiAlert-icon': {
                  marginRight: '8px',
                  fontSize: '18px',
                },
              }}
            >
              {alertInfoMessage}
            </Alert>
          )}
          {isVoidConfirmBox ? (
            <Stack alignItems='center' spacing={4}>
              <TextField
                required
                id='outlined-multiline-static'
                label='Add Message'
                onChange={(e) => {
                  setLocalVoidmessage(e.target.value);
                }}
                multiline
              />
            </Stack>
          ) : null}

          <Stack direction='row' spacing={2} justifyContent='center'>
            {showCancel ? (
              <Button
                variant='outlined'
                color='secondary'
                onClick={(e) => {
                  e.preventDefault();
                  handleClose();
                }}
              >
                No, Cancel
              </Button>
            ) : null}

            {action}
            {cancelAction}
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
}

Confirm.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  alertInfoMessage: PropTypes.string,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  action: PropTypes.node.isRequired,
  showCancel: PropTypes.bool,
  cancelAction: PropTypes.node,
  messageAction: PropTypes.node,
  disableBackdropClose: PropTypes.bool,
  hideCloseIcon: PropTypes.bool,
  isVoidConfirmBox: PropTypes.bool,
  setVoidMessage: PropTypes.func,
};
Confirm.defaultProps = {
  showCancel: true,
  cancelAction: null,
  messageAction: null,
  disableBackdropClose: false,
  hideCloseIcon: false,
  setVoidMessage: () => {},
};
