import * as Yup from 'yup';
import { api, apiEndPoints } from '../../../../api';

export const editGroupDetailsModalSchema = Yup.object().shape({
  groupName: Yup.string().required('Group Name is required'),
  // .test(
  //   'Unique Group Name',
  //   'This group name already exists',
  //   function (value) {
  //     return new Promise((resolve, reject) => {
  //       api
  //         .post(apiEndPoints.checkGroupName, { groupName: value })
  //         .then((res) => {
  //           res.data.success ? resolve(true) : resolve(false);
  //         })
  //         .catch(() => {
  //           reject(false);
  //         });
  //     });
  //   }
  // ),
  groupDesc: Yup.string().required('Description is required'),
});
