import { useEffect, useState } from 'react';
import { LogLevel } from 'constants';
import LoggingService from 'utils/service/LoggingService';

export default function useSizeTable(sizes, dimensions, prePackSizes, color) {
  // sizes,
  // dimensions,
  // prePackSizes,
  // productId,
  // colorId,
  // sizeTableId,
  // prepackCode,
  // sizeTableRow,
  // updateProductQuantity,
  // cartFlag,
  // quantityErrorFlag
  const [initialValues, setInitialValues] = useState({});
  const [sizeTableAlertModal, setSizeTableAlertModal] = useState('');

  // function submit(values) {
  //   for (let key in values) {
  //     let type = key.split('_')[0];
  //     updateProductQuantity({
  //       product_id: parseInt(productId),
  //       product_color_id: parseInt(colorId),
  //       product_quantity: [
  //         {
  //           sizetable_size_id:
  //             type === 'prepack'
  //               ? parseInt(key.split('_')[4].split('|')[2])
  //               : parseInt(key.split('_')[1]),
  //           prepack_code: type === 'prepack' ? key.split('_')[2] : '',
  //           sizetable_row: (() => {
  //             if (type === 'prepack') return parseInt(key.split('_')[3]);
  //             else if (type === 'dimension') return parseInt(key.split('_')[2]);
  //             else return 0;
  //           })(),
  //           quantity: parseInt(values[`${key}`]),
  //         },
  //       ],
  //     });
  //   }
  // }

  const performDynamicSizeTable = () => {
    try {
      const tableContainer = document.getElementById('tableContainer');
      if (sizes?.length > 0 || dimensions?.length > 0) {
        const tableCells = document.getElementsByClassName(
          `${color?.colorId}_tablecell`
        );
        let maxWidth = 0;
        let largestCell = null;

        Array.from(tableCells).forEach((cell) => {
          cell.style.width = `${cell.scrollWidth}px`;
        });
      }
      if (prePackSizes?.length > 0) {
        prePackSizes.forEach((prepackSize) => {
          if (Array.isArray(prepackSize)) {
            const tableCells = document.getElementsByClassName(
              `${color?.colorId}_${prepackSize[0]?.packId}_tablecell`
            );

            let maxWidth = 0;
            Array.from(tableCells).forEach((cell) => {
              // cell.style.width = `${maxWidth}px`;
              cell.style.width = `${cell.scrollWidth}px`;
            });
          }
        });
      }
    } catch (err) {
      LoggingService.log(
        'performDynamicSizeTable',
        'useSizeTable',
        err,
        LogLevel.ERROR
      );
    }
  };

  return {
    initialValues,
    // submit,
    sizeTableAlertModal,
    setSizeTableAlertModal,
    performDynamicSizeTable,
  };
}
