import * as Yup from 'yup';

export const addBillingAddressModalSchema = Yup.object().shape({
  billingIdCode: Yup.string().required('Billing ID / Code is required'),
  emailId: Yup.string().email('Invalid email format'),
  addressLineOne: Yup.string().required('Address is required'),
  zipCode: Yup.string().required('Zip / Postal Code is required'),
});

export const fieldsName = {
  name: 'name',
  billingCode: 'billingCode',
  email: 'email',
  street: 'street',
  street2: 'street2',
  city: 'city',
  state: 'state',
  zip: 'zip',
  country: 'country',
  billingId: 'billingId',
};

export const profileFieldName = {
  name: 'name',
  username: 'username',
  department: 'department',
  company: 'company',
  email: 'email',
  businessPhone: 'businessPhone',
  mobilePhone: 'mobilePhone',
  businessFax: 'businessFax',
  addressLine1: 'addressLine1',
  addressLine2: 'addressLine2',
  city: 'city',
  state: 'state',
  zip: 'zip',
  country: 'country',
  streetAddress: 'streetAddress',
  legalName: 'legalName',
  vendorCode: 'vendorCode',
  taxId: 'taxId',
  note: 'note',
  customerIdOrCode: 'customerIdOrCode',
  billingContact: 'billingContact',
  billingIdOrCode: 'billingIdOrCode',
  phone: 'phone',
  buyerStreetAddress: 'buyerStreetAddress',
  buyerStreetAddress2: 'buyerStreetAddress2',
  buyerCity: 'buyerCity',
  buyerState: 'buyerState',
  buyerCountry: 'buyerCountry',
  buyerZipCode: 'buyerZipCode',
  dBAOrRooftopName: 'dBAOrRooftopName',
  timezone: 'timezone',
};
