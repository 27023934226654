import React from 'react';
import PropTypes from 'prop-types';

export default function DisconnectingPowerCord({ fill, sx }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='325.299'
      height='227.998'
      viewBox='0 0 325.299 227.998'
    >
      <g id='disconnecting-power-cord' transform='translate(-328.789 -303)'>
        <path
          id='Path_21217'
          data-name='Path 21217'
          d='M414.7,520.306s-54.768-15.993-75.277-57.7c-20.865-42.43-9.591-104.1,21.291-129.766C393.5,305.587,438.7,305.587,472.1,312.4s76.832-27.678,137.3,4.684,52.336,129.5,18.31,172.128C584.271,543.621,465.754,534.077,414.7,520.306Z'
          transform='translate(0 0)'
          fill='#efe1e1'
        />
        <path
          id='Path_21218'
          data-name='Path 21218'
          d='M2180.018,615.008c2.482-11.914,5.727-23.649,9.147-35.318a1.618,1.618,0,0,1,2.214-1.012c4.646,2,9.951,4.329,14.549,6.389L2208,586l-2.2.965q3.022-8.532,6.293-16.979c2.2-5.611,4.472-11.259,6.976-16.745-.852,3.661-1.858,7.357-2.864,10.978-2.245,7.988-4.714,15.93-7.318,23.8a1.616,1.616,0,0,1-2.041,1.027,1.648,1.648,0,0,1-.16-.062l-2.084-.9c-3.224-1.391-7.157-3.132-10.39-4.568l-4.143-1.856,2.214-1.013c-3.722,11.576-7.626,23.109-12.262,34.361Zm-77.96,104.3c7.6-9.4,15.791-18.3,24.11-27.054a1.614,1.614,0,0,1,2.321-.012c3.417,3.361,6.837,6.791,10.187,10.224l-2.4.1c7.915-9.116,16-18.132,24.63-26.584-6.906,9.915-14.459,19.376-22.139,28.692a1.616,1.616,0,0,1-2.275.214,1.681,1.681,0,0,1-.127-.116l-1.285-1.266c-2.986-2.956-5.967-5.955-8.9-8.961l2.321-.012c-8.546,8.536-17.233,16.949-26.443,24.779Zm54.887,17.7a217.246,217.246,0,0,1,2.011-25.034,1.607,1.607,0,0,1,1.871-1.373c2.876.418,5.768.879,8.634,1.37l1.23.215-1.886,1.49c.374-4.161.824-8.316,1.385-12.463s1.208-8.315,2.1-12.409a215.108,215.108,0,0,1-.236,25.115,1.611,1.611,0,0,1-1.837,1.5c-1.621-.237-3.376-.506-4.987-.765q-2.465-.4-4.926-.832l1.871-1.373a217.278,217.278,0,0,1-5.235,24.563Zm7.785-122.553a217.3,217.3,0,0,1-9.333-23.315,1.607,1.607,0,0,1,1.065-2.062c2.762-.9,5.557-1.779,8.343-2.613l1.2-.355-1.027,2.173c-1.516-3.893-2.961-7.815-4.3-11.778s-2.617-7.985-3.634-12.05A215.14,215.14,0,0,1,2168,587.05a1.611,1.611,0,0,1-.98,2.158c-1.557.509-3.248,1.049-4.807,1.533q-2.386.739-4.782,1.446l1.065-2.062a217.3,217.3,0,0,1,6.236,24.328Z'
          transform='translate(-1654.147 -233.428)'
          fill='#ffc864'
        />
        <g
          id='Group_10056'
          data-name='Group 10056'
          transform='translate(537.537 362.036)'
        >
          <path
            id='Path_21219'
            data-name='Path 21219'
            d='M3511.28,2860.015l75.343,3.222a4.1,4.1,0,0,0,4.433-4.092v-4.172Z'
            transform='translate(-3506.238 -2742.576)'
            fill='#edd5d5'
          />
          <path
            id='Path_21220'
            data-name='Path 21220'
            d='M3516,1299.256h-74.734a5.042,5.042,0,0,1-5.042-5.042V1186.859a5.042,5.042,0,0,1,5.042-5.042H3516a5.042,5.042,0,0,1,5.042,5.042v107.355A5.042,5.042,0,0,1,3516,1299.256Z'
            transform='translate(-3436.226 -1181.817)'
            fill='#fff'
          />
          <path
            id='Path_21221'
            data-name='Path 21221'
            d='M3535.823,1386.917H3472.58a4.832,4.832,0,0,1-4.826-4.826v-94.181a4.832,4.832,0,0,1,4.826-4.826h63.243a4.832,4.832,0,0,1,4.826,4.826v94.181A4.832,4.832,0,0,1,3535.823,1386.917Zm-63.243-103.028a4.025,4.025,0,0,0-4.02,4.02v94.181a4.025,4.025,0,0,0,4.02,4.02h63.243a4.025,4.025,0,0,0,4.02-4.02v-94.181a4.025,4.025,0,0,0-4.02-4.02Z'
            transform='translate(-3465.635 -1276.28)'
            fill='#e8e8e8'
          />
          <path
            id='Path_21222'
            data-name='Path 21222'
            d='M3556.031,1412.248h-59.422a4.564,4.564,0,0,1-4.559-4.559V1316.9a4.564,4.564,0,0,1,4.559-4.559h59.422a4.564,4.564,0,0,1,4.559,4.559v90.784A4.564,4.564,0,0,1,3556.031,1412.248Zm-59.422-99.1a3.757,3.757,0,0,0-3.753,3.753v90.784a3.757,3.757,0,0,0,3.753,3.753h59.422a3.757,3.757,0,0,0,3.753-3.753V1316.9a3.757,3.757,0,0,0-3.753-3.753Z'
            transform='translate(-3488.3 -1303.577)'
            fill='#e8e8e8'
          />
          <g
            id='Group_10053'
            data-name='Group 10053'
            transform='translate(74.207 4.639)'
          >
            <path
              id='Path_21223'
              data-name='Path 21223'
              d='M4541.288,1364.075a.4.4,0,0,1,0-.806,4.156,4.156,0,0,0,3.934-2.307,6.557,6.557,0,0,0,.705-2.347V1330.9a.4.4,0,1,1,.806,0v27.734c0,.009,0,.017,0,.026a7.3,7.3,0,0,1-.8,2.684A4.969,4.969,0,0,1,4541.288,1364.075Zm5.042-39.708a.4.4,0,0,1-.4-.4v-50.475a.4.4,0,1,1,.806,0v50.475A.4.4,0,0,1,4546.33,1324.367Zm0-57.407a.4.4,0,0,1-.4-.4v-15.283a.4.4,0,1,1,.806,0v15.283A.4.4,0,0,1,4546.33,1266.96Z'
              transform='translate(-4540.885 -1250.871)'
              fill='#d3d3d3'
            />
          </g>
          <g
            id='Group_10055'
            data-name='Group 10055'
            transform='translate(8.816 31.553)'
          >
            <path
              id='Path_21224'
              data-name='Path 21224'
              d='M3592.708,1705.856a23.581,23.581,0,0,1-9.84-2.139,25.155,25.155,0,0,1-8.027-5.828,27.28,27.28,0,0,1-5.406-8.634,29.166,29.166,0,0,1,0-21.13,27.279,27.279,0,0,1,5.406-8.634,25.154,25.154,0,0,1,8.027-5.828,23.7,23.7,0,0,1,19.68,0,25.154,25.154,0,0,1,8.027,5.828,27.281,27.281,0,0,1,5.406,8.634,29.166,29.166,0,0,1,0,21.13,27.279,27.279,0,0,1-5.406,8.634,25.155,25.155,0,0,1-8.027,5.828A23.581,23.581,0,0,1,3592.708,1705.856Zm0-53.526c-13.48,0-24.448,11.825-24.448,26.36s10.967,26.36,24.448,26.36,24.447-11.825,24.447-26.36S3606.189,1652.329,3592.708,1652.329Z'
              transform='translate(-3567.455 -1651.523)'
              fill='#e8e8e8'
            />
            <path
              id='Path_21225'
              data-name='Path 21225'
              d='M3600.739,1724.7c0-11.1,6.917-20.844,16.361-23.853-10.471,2.087-18.4,11.972-18.4,23.853s7.93,21.766,18.4,23.853C3607.656,1745.543,3600.739,1735.8,3600.739,1724.7Z'
              transform='translate(-3596.6 -1697.533)'
              fill='#e8e8e8'
            />
            <path
              id='Path_21226'
              data-name='Path 21226'
              d='M3614.845,1738.31h-.015a22.11,22.11,0,0,1-15.685-7.5,26.022,26.022,0,0,1,0-34.287,22.11,22.11,0,0,1,15.685-7.5.4.4,0,0,1,.418.4v3.634c0,.155.2.292.425.292h1.168c.227,0,.425-.136.425-.292v-3.55a.4.4,0,0,1,.442-.4,22.307,22.307,0,0,1,14.819,7.889,26.054,26.054,0,0,1,0,33.345,22.307,22.307,0,0,1-14.819,7.89.4.4,0,0,1-.442-.4v-3.55c0-.155-.2-.292-.425-.292h-1.168c-.227,0-.425.136-.425.292v3.634a.4.4,0,0,1-.4.4Zm-.4-48.47c-11.774.667-20.936,11.045-20.936,23.823s9.162,23.156,20.936,23.822v-3.212a1.172,1.172,0,0,1,1.231-1.1h1.168a1.172,1.172,0,0,1,1.231,1.1v3.1c11.251-1.325,19.681-11.436,19.681-23.712s-8.43-22.387-19.681-23.712v3.1a1.172,1.172,0,0,1-1.231,1.1h-1.168a1.172,1.172,0,0,1-1.231-1.1Z'
              transform='translate(-3591.003 -1686.496)'
              fill='#d3d3d3'
            />
            <ellipse
              id='Ellipse_284'
              data-name='Ellipse 284'
              cx='2.225'
              cy='2.748'
              rx='2.225'
              ry='2.748'
              transform='translate(22.956 14.563)'
              fill='#d3d3d3'
            />
            <ellipse
              id='Ellipse_285'
              data-name='Ellipse 285'
              cx='1.934'
              cy='2.722'
              rx='1.934'
              ry='2.722'
              transform='translate(23.538 14.59)'
              fill='#9b9c9e'
            />
            <ellipse
              id='Ellipse_286'
              data-name='Ellipse 286'
              cx='2.225'
              cy='2.748'
              rx='2.225'
              ry='2.748'
              transform='translate(22.956 34.225)'
              fill='#d3d3d3'
            />
            <ellipse
              id='Ellipse_287'
              data-name='Ellipse 287'
              cx='1.934'
              cy='2.722'
              rx='1.934'
              ry='2.722'
              transform='translate(23.538 34.252)'
              fill='#9b9c9e'
            />
            <g
              id='Group_10054'
              data-name='Group 10054'
              transform='translate(23.841 25.428)'
            >
              <ellipse
                id='Ellipse_288'
                data-name='Ellipse 288'
                cx='1.412'
                cy='1.738'
                rx='1.412'
                ry='1.738'
                fill='#d3d3d3'
              />
              <path
                id='Path_21227'
                data-name='Path 21227'
                d='M3931.723,2044.777l-.3-.162a.165.165,0,0,1-.065-.224l.162-.3a.165.165,0,0,0-.065-.224l-.083-.045a.165.165,0,0,0-.224.065l-.162.3a.165.165,0,0,1-.224.065l-.3-.162a.165.165,0,0,0-.224.065l-.045.082a.165.165,0,0,0,.065.224l.3.162a.165.165,0,0,1,.065.224l-.162.3a.165.165,0,0,0,.065.224l.083.045a.165.165,0,0,0,.224-.065l.162-.3a.165.165,0,0,1,.224-.065l.3.162a.165.165,0,0,0,.224-.065l.045-.083A.165.165,0,0,0,3931.723,2044.777Z'
                transform='translate(-3929.655 -2042.881)'
                fill='#bababa'
              />
            </g>
          </g>
        </g>
        <g
          id='Group_10067'
          data-name='Group 10067'
          transform='translate(347.65 311.626)'
        >
          <path
            id='Path_21228'
            data-name='Path 21228'
            d='M752.884,509.7c-3.559-3.855-24.911-23.132-33.215-34.1s-34.1-32.325-39.146-34.995c-1.864-.987-7.051-4.788-12.849-9.188-15.993,3.412-31.571,10.009-45.052,21.214a71.071,71.071,0,0,0-13.065,14.565c8.524,7.65,25.086,22.584,26.184,24.121,1.483,2.076,9.194,28.174,21.352,42.409s37.96,35.588,41.222,37.07,66.43-27.58,68.21-29.063S756.443,513.553,752.884,509.7Z'
            transform='translate(-609.557 -431.411)'
            fill='#f6af9a'
          />
          <path
            id='Path_21229'
            data-name='Path 21229'
            d='M1031.343,1124.966a.4.4,0,0,1-.4-.338,113.726,113.726,0,0,0-6.205-22.814,69.808,69.808,0,0,0-3.829-8.376.4.4,0,0,1,.7-.4,70.668,70.668,0,0,1,3.881,8.484,114.545,114.545,0,0,1,6.25,22.978.4.4,0,0,1-.333.463A.416.416,0,0,1,1031.343,1124.966ZM991.9,1074.66a.4.4,0,0,1-.4-.344,23.931,23.931,0,0,1,0-5.277,35.6,35.6,0,0,1,3.62-12.478.4.4,0,1,1,.721.36,34.792,34.792,0,0,0-3.536,12.163,23.694,23.694,0,0,0-.007,5.114.4.4,0,0,1-.4.462Z'
            transform='translate(-965.717 -1014.357)'
            fill='#e57e73'
          />
          <path
            id='Path_21230'
            data-name='Path 21230'
            d='M847.324,1705.186a51.307,51.307,0,0,1,12.911.722,2.335,2.335,0,0,0,1.094-4.541,54.1,54.1,0,0,0-14.005-.857,95.326,95.326,0,0,0-35,8.955c-21.809,10.009-33.413,35.764-41.885,54.569a156.079,156.079,0,0,1-7.026,14.434,33.978,33.978,0,0,1-11.979,11.775q1.751,1.72,3.558,3.342a38.2,38.2,0,0,0,12.431-12.722,158.446,158.446,0,0,0,7.275-14.91c8.169-18.132,19.357-42.964,39.575-52.242A90.781,90.781,0,0,1,847.324,1705.186Z'
            transform='translate(-741.904 -1615.178)'
            fill='#37568a'
          />
          <path
            id='Path_21231'
            data-name='Path 21231'
            d='M2375.335,1701.956l-31.237-7.372a2.57,2.57,0,0,1-1.911-3.092l.954-4.041a2.57,2.57,0,0,1,3.092-1.911l31.237,7.372Z'
            transform='translate(-2225.73 -1601.227)'
            fill='#4d6ea8'
          />
          <path
            id='Path_21232'
            data-name='Path 21232'
            d='M2446.166,1701.465a.4.4,0,0,1-.391-.5l1.16-4.664a.4.4,0,1,1,.782.195l-1.16,4.664A.4.4,0,0,1,2446.166,1701.465Zm4.592,5.694a.4.4,0,0,1-.392-.5l1.208-4.969a.4.4,0,1,1,.783.19l-1.208,4.969A.4.4,0,0,1,2450.758,1707.159Zm8.952-2.565a.4.4,0,0,1-.393-.494l1.062-4.619a.4.4,0,1,1,.785.181l-1.062,4.619A.4.4,0,0,1,2459.709,1704.594Zm4.606,5.765a.412.412,0,0,1-.081-.008.4.4,0,0,1-.314-.476l.864-4.214a.4.4,0,1,1,.79.162l-.864,4.214A.4.4,0,0,1,2464.316,1710.359Z'
            transform='translate(-2322.412 -1611.044)'
            fill='#3d5c91'
          />
          <g
            id='Group_10060'
            data-name='Group 10060'
            transform='translate(70.994 72.424)'
          >
            <path
              id='Path_21233'
              data-name='Path 21233'
              d='M1678.2,1918.468a5.408,5.408,0,0,0-3.954,4.877c-.307,3.173-1.318,7.381-1.845,10.94s6.005,13.43,9.292,19.31c4.02,7.191,7.711,7.315,11.928,7.183s10.511-2.2,12.253-5.713-17.262-21.043-18.844-22.625-2.109-8.04-3.427-11.072S1679.172,1917.885,1678.2,1918.468Z'
              transform='translate(-1671.971 -1890.791)'
              fill='#f6af9a'
            />
            <path
              id='Path_21234'
              data-name='Path 21234'
              d='M1686.729,1955.618a11.112,11.112,0,0,1-4.9-.908c-2.249-1.052-4.178-3.12-6.071-6.506-.689-1.233-1.523-2.644-2.405-4.138-3.455-5.848-7.371-12.477-6.934-15.428.22-1.486.528-3.1.825-4.666.413-2.171.84-4.417,1.018-6.253a5.817,5.817,0,0,1,4.2-5.216,3.511,3.511,0,0,1,2.549-.017,5.491,5.491,0,0,1,3.376,3.134,30.956,30.956,0,0,1,1.4,5.1c.543,2.465,1.1,5.013,1.938,5.846.163.163.526.51,1.028.989,2.1,2,7.012,6.694,11.128,11.2,7.068,7.743,7.245,9.927,6.764,10.9-1.061,2.137-3.524,3.526-5.4,4.315a21.4,21.4,0,0,1-7.2,1.622C1687.6,1955.608,1687.162,1955.618,1686.729,1955.618Zm-14.023-42.344a4.969,4.969,0,0,0-1.7.855,5.02,5.02,0,0,0-1.941,3.668c-.181,1.874-.612,4.137-1.028,6.326-.3,1.555-.6,3.164-.819,4.634-.4,2.669,3.611,9.451,6.831,14.9.885,1.5,1.721,2.913,2.415,4.154,4.032,7.213,7.691,7.1,11.564,6.977a20.551,20.551,0,0,0,6.912-1.559c2.478-1.04,4.251-2.436,4.993-3.931.263-.53.144-2.566-6.637-9.995-4.1-4.489-9-9.167-11.089-11.164-.506-.483-.871-.832-1.041-1-1-1-1.561-3.547-2.155-6.243a30.542,30.542,0,0,0-1.357-4.953,4.694,4.694,0,0,0-2.882-2.687,2.727,2.727,0,0,0-1.945-.028A.4.4,0,0,1,1672.706,1913.274Zm-.088-.393h0Z'
              transform='translate(-1666.386 -1885.204)'
              fill='#e57e73'
            />
            <path
              id='Path_21235'
              data-name='Path 21235'
              d='M1717.091,1964.814s-.158,5.447.031,5.789,1.463,1.315,4.45.9,3.169-1.373,3.134-1.753-.176-4.912-.29-5.764C1724.388,1963.642,1717.517,1963.375,1717.091,1964.814Z'
              transform='translate(-1713.627 -1933.202)'
              fill='#ea9a88'
            />
            <path
              id='Path_21236'
              data-name='Path 21236'
              d='M1715.153,1981.725a7.444,7.444,0,0,1-1.514-.139c-1.471-.312-1.69-1.089-1.69-1.524v-5.009a.4.4,0,0,1,.806,0v5.009c0,.423.572.634,1.051.735a9.588,9.588,0,0,0,3.465-.087c1.431-.274,2.028-.665,2.093-.812.354-.8.175-3.631-.032-5.342a.4.4,0,0,1,.8-.1c.055.458.525,4.515-.032,5.766-.323.726-1.816,1.111-2.678,1.276A12.251,12.251,0,0,1,1715.153,1981.725Z'
              transform='translate(-1708.887 -1942.896)'
              fill='#e57e73'
            />
            <path
              id='Path_21237'
              data-name='Path 21237'
              d='M1712.463,2148.074h-.126a.4.4,0,0,1,.026-.806c.057,0,4.492.093,7.3-3.03a.4.4,0,0,1,.6.54A10.976,10.976,0,0,1,1712.463,2148.074Z'
              transform='translate(-1708.886 -2101.474)'
              fill='#f6a68f'
            />
            <g
              id='Group_10057'
              data-name='Group 10057'
              transform='translate(8.158 9.539)'
            >
              <path
                id='Path_21238'
                data-name='Path 21238'
                d='M1797.691,1658.123a7.825,7.825,0,0,0-3.822,6.986c.264,4.745-.156,12.917-.012,18.453s1.989,7.908,4.889,10.544,19.244,17.267,21.616,18.585,8.963,0,11.863-2.9,4.632-6.063,4.03-8.04-21.56-20.957-22.614-22.012-4.35-13.049-6.722-17.662S1800.591,1656.8,1797.691,1658.123Z'
                transform='translate(-1793.423 -1657.12)'
                fill='#f6af9a'
              />
              <path
                id='Path_21239'
                data-name='Path 21239'
                d='M1817.39,1708.006a5.956,5.956,0,0,1-2.818-.565c-2.487-1.382-19.248-16.418-21.692-18.639a15.709,15.709,0,0,1-3.564-4.3,14.581,14.581,0,0,1-1.457-6.536c-.066-2.52-.014-5.616.036-8.612.059-3.565.12-7.251-.023-9.829a7.76,7.76,0,0,1,1.909-5.557,8.472,8.472,0,0,1,2.122-1.8l.027-.014a6.84,6.84,0,0,1,5.111-.261,8.555,8.555,0,0,1,4.641,4.4,94.409,94.409,0,0,1,4.023,10.648c1.075,3.183,2.186,6.474,2.626,6.914.2.2,1.186,1.109,2.553,2.369,2.925,2.7,7.819,7.209,12.042,11.246,6.891,6.587,7.959,8.037,8.12,8.564a5.5,5.5,0,0,1-.729,3.976,18.635,18.635,0,0,1-3.4,4.467,13.353,13.353,0,0,1-6.324,3.139A14.579,14.579,0,0,1,1817.39,1708.006Zm-25.11-55.125a7.9,7.9,0,0,0-1.906,1.638,6.857,6.857,0,0,0-1.7,4.965c.145,2.607.083,6.308.024,9.887-.05,2.987-.1,6.075-.036,8.577.146,5.628,2.151,7.888,4.757,10.257,3.931,3.574,19.309,17.291,21.541,18.531,1.051.584,3.139.619,5.448.091a12.73,12.73,0,0,0,5.934-2.924c2.913-2.913,4.455-5.911,3.929-7.638-.085-.28-1.026-1.64-7.906-8.216-4.218-4.032-9.109-8.542-12.032-11.236-1.425-1.313-2.366-2.181-2.576-2.392-.547-.547-1.385-2.977-2.82-7.226a94.1,94.1,0,0,0-3.976-10.537C1798.593,1652.047,1794.808,1651.74,1792.28,1652.881Z'
                transform='translate(-1787.829 -1651.518)'
                fill='#e57e73'
              />
              <path
                id='Path_21240'
                data-name='Path 21240'
                d='M1819.976,1715.462s.654,5.842.762,7.688c.108.73.794.884,2.558.407s6.733-2.259,7.1-2.7c.583-.691.157-6.171-.524-8.755C1829.505,1711.026,1820.249,1711,1819.976,1715.462Z'
                transform='translate(-1817.816 -1707.474)'
                fill='#ea9a88'
              />
              <path
                id='Path_21241'
                data-name='Path 21241'
                d='M1815.654,1741.566a1.541,1.541,0,0,1-.687-.119.588.588,0,0,1-.332-.518c0-.011,0-.023,0-.035a78.2,78.2,0,0,0-.657-8.093.4.4,0,1,1,.8-.1c.031.252.746,6,.671,8.059a13.582,13.582,0,0,0,4.03-.817,23.053,23.053,0,0,0,4.928-1.992.414.414,0,0,1,.043-.025c.023-.012.566-.325.157-3.762-.219-1.839-.6-3.621-.606-3.639a.4.4,0,1,1,.788-.17c0,.018.4,1.837.618,3.714.419,3.523-.078,4.3-.572,4.566a24.59,24.59,0,0,1-5.151,2.089A17.02,17.02,0,0,1,1815.654,1741.566Z'
                transform='translate(-1812.22 -1724.761)'
                fill='#e57e73'
              />
              <path
                id='Path_21242'
                data-name='Path 21242'
                d='M1851.657,2036.012a.4.4,0,0,1-.151-.777c.085-.034,8.543-3.463,11.943-6.5a.4.4,0,1,1,.537.6c-3.505,3.128-11.825,6.5-12.178,6.643A.4.4,0,0,1,1851.657,2036.012Z'
                transform='translate(-1846.993 -2003.302)'
                fill='#f6a68f'
              />
            </g>
            <g
              id='Group_10058'
              data-name='Group 10058'
              transform='translate(17.506)'
            >
              <path
                id='Path_21243'
                data-name='Path 21243'
                d='M1933.918,1526.141s4.152,10.843,5.536,15.7,2.373,7.429,4.35,9.8,19.969,19.178,24.12,21.55,10.874-.593,12.456-2.372,5.6-7.711,4.777-10.083-20.792-21.155-22.966-22.934-6.063-12.653-11.4-17.794-13.181-5.536-15.948-2.768C1933.354,1518.731,1932.245,1521.68,1933.918,1526.141Z'
                transform='translate(-1932.671 -1515.138)'
                fill='#f6af9a'
              />
              <path
                id='Path_21244'
                data-name='Path 21244'
                d='M1965.957,1568.863a7.861,7.861,0,0,1-3.92-.933c-2.567-1.467-9.909-8.06-12.947-10.827-5.194-4.731-10.256-9.583-11.283-10.815-2.059-2.471-3.068-5.177-4.428-9.949s-5.483-15.561-5.525-15.669v0c-2.045-5.453.028-8.337,1.015-9.323,1.477-1.478,4.224-2.112,7.349-1.7a16.454,16.454,0,0,1,9.164,4.459c3.214,3.1,5.864,8.15,7.993,12.211,1.375,2.624,2.563,4.89,3.384,5.561,1.069.874,6.585,5.991,12.016,11.268,6.973,6.775,10.7,10.761,11.076,11.847.4,1.152-.11,3.033-1.514,5.59a30.6,30.6,0,0,1-3.342,4.893,12.743,12.743,0,0,1-5.457,2.864A13.424,13.424,0,0,1,1965.957,1568.863Zm-37.349-48.479c.077.2,4.179,10.935,5.546,15.734,1.328,4.661,2.305,7.295,4.272,9.654,1.887,2.265,19.894,19.106,24.011,21.458,2.3,1.315,5.3.773,6.884.332a11.943,11.943,0,0,0,5.071-2.623,29.691,29.691,0,0,0,3.238-4.745c1.254-2.283,1.772-4.036,1.459-4.938-.152-.438-1.515-2.437-10.876-11.533-5.417-5.263-10.906-10.356-11.964-11.222-.948-.776-2.113-3-3.588-5.811-2.1-4.01-4.718-9-7.838-12a15.643,15.643,0,0,0-8.712-4.24c-2.834-.377-5.391.185-6.672,1.467C1928.562,1512.79,1926.728,1515.37,1928.608,1520.384Z'
                transform='translate(-1926.984 -1509.523)'
                fill='#e57e73'
              />
              <path
                id='Path_21245'
                data-name='Path 21245'
                d='M1970.99,1587.924l4.677,9.96s1.266,1,4.052-.432a38.677,38.677,0,0,0,6.229-3.858c.208-.342-2.151-6.016-6.082-10.637C1978.368,1581.137,1970.021,1584.641,1970.99,1587.924Z'
                transform='translate(-1967.961 -1577.559)'
                fill='#ea9a88'
              />
              <path
                id='Path_21246'
                data-name='Path 21246'
                d='M1969.432,1596.4l-.1,0a1.624,1.624,0,0,1-1.273-.543.407.407,0,0,1-.052-.084c-1.432-3.1-4.841-10.308-4.875-10.381a.4.4,0,0,1,.729-.344c.034.072,3.406,7.208,4.854,10.334a.927.927,0,0,0,.646.213c2.291.077,7.615-3.247,8.874-4.433-.235-1.336-3.062-7.205-6.2-10.467a.4.4,0,1,1,.581-.559,31.624,31.624,0,0,1,4.463,6.392c.984,1.811,1.977,3.993,1.977,4.785a.4.4,0,0,1-.108.274C1977.86,1592.752,1972.158,1596.4,1969.432,1596.4Z'
                transform='translate(-1960.667 -1575.271)'
                fill='#e57e73'
              />
              <path
                id='Path_21247'
                data-name='Path 21247'
                d='M2095.723,1897.557a.4.4,0,0,1-.042-.8,27.371,27.371,0,0,0,5.161-1.223,23.418,23.418,0,0,0,9.382-5.659.4.4,0,0,1,.583.557,24.234,24.234,0,0,1-9.728,5.872,27.607,27.607,0,0,1-5.312,1.253Z'
                transform='translate(-2084.012 -1864.205)'
                fill='#f6a68f'
              />
            </g>
            <g
              id='Group_10059'
              data-name='Group 10059'
              transform='translate(56.447 19.92)'
            >
              <path
                id='Path_21248'
                data-name='Path 21248'
                d='M2515.24,1813.253s-3.954,2.966-2.175,9.095,9.688,17.794,12.258,19.375,10.676-4.152,13.246-5.734,3.164-6.327,3.164-6.327a11.547,11.547,0,0,1-2.57-.989c-.989-.593-5.931-9.885-10.281-13.247S2518.206,1811.078,2515.24,1813.253Z'
                transform='translate(-2512.205 -1811.691)'
                fill='#f6af9a'
              />
              <path
                id='Path_21249'
                data-name='Path 21249'
                d='M2520.917,1836.764a2.542,2.542,0,0,1-1.353-.333c-1.3-.8-3.874-3.983-6.552-8.106a50.2,50.2,0,0,1-5.882-11.5,9.521,9.521,0,0,1,.448-7.141,7.248,7.248,0,0,1,1.872-2.389l0,0c1.524-1.118,3.869-1.495,6.6-1.062a16.81,16.81,0,0,1,7.523,3.242c2.9,2.241,6.039,7.039,8.116,10.215a22.772,22.772,0,0,0,2.126,3.005,11.22,11.22,0,0,0,2.46.943.4.4,0,0,1,.3.441c-.025.2-.648,4.956-3.352,6.62-.34.209-.783.5-1.3.826C2527.926,1834.112,2523.561,1836.764,2520.917,1836.764Zm-10.984-28.824a6.615,6.615,0,0,0-1.655,2.145,8.73,8.73,0,0,0-.374,6.515,49.425,49.425,0,0,0,5.783,11.286c2.579,3.969,5.11,7.127,6.3,7.859.575.354,1.937.441,5.165-1.148a67.217,67.217,0,0,0,6.348-3.75c.517-.334.963-.622,1.311-.836,1.992-1.226,2.73-4.6,2.921-5.69a10.762,10.762,0,0,1-2.323-.937c-.4-.242-1.059-1.227-2.385-3.255-2.048-3.13-5.143-7.861-7.935-10.018C2518.908,1806.882,2512.763,1805.868,2509.933,1807.94Z'
                transform='translate(-2506.657 -1806.055)'
                fill='#e57e73'
              />
              <path
                id='Path_21250'
                data-name='Path 21250'
                d='M2538.478,1875.681a1.655,1.655,0,0,0-.12,1.711c.883,1.7,2.927,5.583,4.061,7.352,1.476,2.3,3.322,1.5,4.529.938s4.936-2.661,5.456-4.426c-.145-.338-4.113-6.634-5.644-9.058a1.639,1.639,0,0,0-1.779-.717A11.164,11.164,0,0,0,2538.478,1875.681Z'
                transform='translate(-2536.058 -1867.042)'
                fill='#ea9a88'
              />
              <path
                id='Path_21251'
                data-name='Path 21251'
                d='M2540.282,1888.227l-.13,0c-.472,0-1.006-.487-1.784-1.609a36.836,36.836,0,0,1-1.919-3.2c-1.248-2.283-2.344-4.6-2.354-4.619a.4.4,0,1,1,.729-.345c.01.022,1.062,2.242,2.273,4.466,2.27,4.172,2.983,4.476,3.072,4.5h.005c1.989.067,5.383-1.731,7.09-3.382a2.654,2.654,0,0,0,.89-1.253c-1.328-3.224-5.825-9.073-5.871-9.132a.4.4,0,0,1,.638-.492c.191.248,4.692,6.1,6.015,9.411a.4.4,0,0,1,.029.15,3.01,3.01,0,0,1-1.142,1.9C2546,1886.385,2542.567,1888.227,2540.282,1888.227Zm-.111-.807Zm-.012,0Z'
                transform='translate(-2532.216 -1868.506)'
                fill='#e57e73'
              />
            </g>
          </g>
          <g
            id='Group_10065'
            data-name='Group 10065'
            transform='translate(145.839 78.905)'
          >
            <path
              id='Path_21252'
              data-name='Path 21252'
              d='M2818.8,1654.348l-21.359-5.041a21.946,21.946,0,1,1,10.081-42.718l21.359,5.041Z'
              transform='translate(-2780.534 -1605.997)'
              fill='#587ebf'
            />
            <circle
              id='Ellipse_289'
              data-name='Ellipse 289'
              cx='9.885'
              cy='9.885'
              r='9.885'
              transform='translate(13.989 12.519)'
              fill='#4d6ea8'
            />
            <path
              id='Path_21253'
              data-name='Path 21253'
              d='M2984.134,1801.264a.4.4,0,0,1-.364-.228,10.292,10.292,0,0,1-1.008-4.938.4.4,0,1,1,.805.038,9.482,9.482,0,0,0,.929,4.55.4.4,0,0,1-.363.578Zm8.927,5.6a10.36,10.36,0,0,1-2.382-.279,10.179,10.179,0,0,1-5.728-3.657.4.4,0,1,1,.634-.5,9.486,9.486,0,0,0,16.857-7.122.4.4,0,1,1,.8-.108,10.3,10.3,0,0,1-10.18,11.663Zm8.992-13.955a.4.4,0,0,1-.367-.237,9.485,9.485,0,0,0-17.086-.423.4.4,0,1,1-.718-.367,10.341,10.341,0,0,1,4.787-4.624,10.29,10.29,0,0,1,13.752,5.082.4.4,0,0,1-.367.569Z'
              transform='translate(-2969.167 -1774.17)'
              fill='#3d5c91'
            />
            <g
              id='Group_10061'
              data-name='Group 10061'
              transform='translate(14.671 1.99)'
            >
              <path
                id='Path_21254'
                data-name='Path 21254'
                d='M3025.029,1640.882a.4.4,0,0,1-.1-.011l-16.12-3.9a.4.4,0,1,1,.189-.784l16.12,3.9a.4.4,0,0,1-.094.795Zm-25.705-3.137a.4.4,0,0,1-.178-.765,11.819,11.819,0,0,1,7.432-1.2.4.4,0,0,1-.186.784,11.236,11.236,0,0,0-6.89,1.134A.4.4,0,0,1,2999.324,1637.745Z'
                transform='translate(-2998.921 -1635.619)'
                fill='#628cd4'
              />
            </g>
            <g
              id='Group_10062'
              data-name='Group 10062'
              transform='translate(35.526 1.551)'
            >
              <path
                id='Path_21255'
                data-name='Path 21255'
                d='M3327.212,1661.165c-2.846,12.058-7.378,24.667-7.378,24.667l-2.951-.7c-2.444-.577-1.783-12.244,1.478-26.06s7.885-24.547,10.329-23.971l2.95.7S3330.065,1649.077,3327.212,1661.165Z'
                transform='translate(-3315.003 -1634.681)'
                fill='#587ebf'
              />
              <path
                id='Path_21256'
                data-name='Path 21256'
                d='M3314.119,1680.632l-2.95-.7c-1.435-.339-2.01-3.192-1.71-8.48a125.054,125.054,0,0,1,8.381-35.513c2.1-4.865,3.887-7.16,5.321-6.821l2.951.7-.185.785-2.95-.7c-.694-.164-2.261,1.4-4.4,6.355a124.286,124.286,0,0,0-8.316,35.24c-.305,5.385.4,7.486,1.091,7.65l2.95.7Z'
                transform='translate(-3309.381 -1629.088)'
                fill='#3d5c91'
              />
              <ellipse
                id='Ellipse_290'
                data-name='Ellipse 290'
                cx='25.702'
                cy='4.547'
                rx='25.702'
                ry='4.547'
                transform='matrix(0.23, -0.973, 0.973, 0.23, 0.403, 50.096)'
                fill='#587ebf'
              />
              <path
                id='Path_21257'
                data-name='Path 21257'
                d='M3355.373,1690.333a1.217,1.217,0,0,1-.281-.033c-1.435-.339-2.01-3.192-1.71-8.48a125.032,125.032,0,0,1,8.381-35.513c2.1-4.865,3.887-7.16,5.321-6.821s2.01,3.192,1.71,8.48a125.057,125.057,0,0,1-8.381,35.513C3358.451,1688.031,3356.758,1690.333,3355.373,1690.333Zm11.423-50.074c-.738,0-2.258,1.643-4.294,6.367a124.277,124.277,0,0,0-8.316,35.24c-.305,5.385.4,7.486,1.091,7.65s2.262-1.4,4.4-6.355a109.4,109.4,0,0,0,5.448-17.316l.392.093-.392-.093a109.364,109.364,0,0,0,2.868-17.924c.305-5.385-.4-7.486-1.091-7.65A.438.438,0,0,0,3366.8,1640.259Z'
                transform='translate(-3350.353 -1638.757)'
                fill='#3d5c91'
              />
              <ellipse
                id='Ellipse_291'
                data-name='Ellipse 291'
                cx='25.209'
                cy='3.658'
                rx='25.209'
                ry='3.658'
                transform='matrix(0.23, -0.973, 0.973, 0.23, 0.517, 49.616)'
                fill='#4d6ea8'
              />
              <path
                id='Path_21258'
                data-name='Path 21258'
                d='M3354.458,1693.76a.4.4,0,0,1-.354-.211,11.743,11.743,0,0,1-.771-5.052c0-.794.025-1.667.078-2.6a110.417,110.417,0,0,1,2.885-18.011,118.3,118.3,0,0,1,4.8-15.782c1.216-3.067,3.02-6.97,4.707-8.15a.4.4,0,0,1,.585.138c1.234,2.278.716,8.49.3,11.991a.4.4,0,0,1-.8-.1c.632-5.285.625-9.24-.006-11.049-1.133,1.1-2.546,3.712-4.034,7.463a117.489,117.489,0,0,0-4.769,15.67,109.606,109.606,0,0,0-2.865,17.872c-.053.914-.079,1.771-.077,2.549a13.669,13.669,0,0,0,.474,4.2c1.248-1.222,2.856-4.307,4.488-8.636a.4.4,0,0,1,.754.284c-1.094,2.9-3.219,7.976-5.166,9.339A.4.4,0,0,1,3354.458,1693.76Z'
                transform='translate(-3350.381 -1642.89)'
                fill='#3d5c91'
              />
              <path
                id='Path_21259'
                data-name='Path 21259'
                d='M3473.991,1929.477a.4.4,0,0,1-.386-.518c.711-2.4,1.379-4.925,1.988-7.5.576-2.441,1.083-4.869,1.507-7.215a.4.4,0,1,1,.793.143c-.427,2.36-.937,4.8-1.516,7.256-.612,2.593-1.284,5.132-2,7.547A.4.4,0,0,1,3473.991,1929.477Z'
                transform='translate(-3462.557 -1894.777)'
                fill='#3d5c91'
              />
            </g>
            <g
              id='Group_10064'
              data-name='Group 10064'
              transform='translate(41.763 14.699)'
            >
              <path
                id='Path_21260'
                data-name='Path 21260'
                d='M3501.514,1834.185l-23.54-5.144a.919.919,0,0,1-.7-1.109l.57-2.413a.919.919,0,0,1,1.121-.68l23.356,5.923a1.759,1.759,0,0,1-.808,3.423Z'
                transform='translate(-3472.21 -1824.81)'
                fill='#82a2d9'
              />
              <path
                id='Path_21261'
                data-name='Path 21261'
                d='M3599.209,1864.029a.4.4,0,0,1-.1-.012l-3.419-.843a.4.4,0,0,1,.193-.783l3.419.843a.4.4,0,0,1-.1.794Zm-5.362-1.321a.4.4,0,0,1-.1-.012l-11.317-2.789a.4.4,0,0,1,.193-.783l11.317,2.789a.4.4,0,0,1-.1.794Z'
                transform='translate(-3570.041 -1856.808)'
                fill='#8bade8'
              />
              <g
                id='Group_10063'
                data-name='Group 10063'
                transform='translate(0 21.359)'
              >
                <path
                  id='Path_21262'
                  data-name='Path 21262'
                  d='M3426.48,2152.137l-23.54-5.144a.919.919,0,0,1-.7-1.109l.569-2.414a.92.92,0,0,1,1.121-.68l23.356,5.923a1.759,1.759,0,0,1-.808,3.424Z'
                  transform='translate(-3402.217 -2142.761)'
                  fill='#82a2d9'
                />
                <path
                  id='Path_21263'
                  data-name='Path 21263'
                  d='M3519.391,2185.741a.4.4,0,0,1-.1-.013l-3.409-.882a.4.4,0,0,1,.2-.781l3.409.882a.4.4,0,0,1-.1.793Zm-5.346-1.383a.4.4,0,0,1-.1-.013l-11.284-2.92a.4.4,0,0,1,.2-.78l11.284,2.919a.4.4,0,0,1-.1.793Z'
                  transform='translate(-3495.63 -2178.088)'
                  fill='#8bade8'
                />
              </g>
            </g>
          </g>
          <g
            id='Group_10066'
            data-name='Group 10066'
            transform='translate(49.258 8.493)'
          >
            <path
              id='Path_21264'
              data-name='Path 21264'
              d='M1344.944,599.211s10.017,20.957,23.066,24.516,25.966.527,32.688,5.668,11.99,14.367,25.437,17,25.968-2.241,27.286-6.063c.659-1.714-5.2-6.531-17.464-11.335s-14.3-11.467-17.2-16.476-9.045-18.617-17.794-25.966c-9.885-8.3-22.8-18.057-36.444-18.684C1351.679,567.285,1337.3,578.649,1344.944,599.211Z'
              transform='translate(-1342.809 -567.179)'
              fill='#f6af9a'
            />
            <path
              id='Path_21265'
              data-name='Path 21265'
              d='M1378.181,558.93a.4.4,0,0,1-.063-.8,50.125,50.125,0,0,1,10.485,0,.4.4,0,0,1-.083.8,50.387,50.387,0,0,0-10.275,0A.391.391,0,0,1,1378.181,558.93Zm79.8,79.269a41.126,41.126,0,0,1-7.881-.754c-9.889-1.939-15.4-7.389-20.258-12.2a57.978,57.978,0,0,0-5.346-4.881c-3.688-2.82-9.373-3.119-15.955-3.464-5.089-.267-10.856-.57-16.595-2.135a23.691,23.691,0,0,1-9.271-5.42,52.3,52.3,0,0,1-7.375-8.325,79.194,79.194,0,0,1-6.677-10.987.4.4,0,0,1,.727-.348,78.359,78.359,0,0,0,6.61,10.871c3.733,5.13,9.574,11.623,16.2,13.43,5.655,1.542,11.376,1.842,16.425,2.107,6.716.353,12.515.657,16.4,3.629a58.511,58.511,0,0,1,5.423,4.948c4.779,4.728,10.2,10.088,19.846,11.98a40.414,40.414,0,0,0,17.366-.433.4.4,0,1,1,.2.781A40.218,40.218,0,0,1,1457.983,638.2Z'
              transform='translate(-1366.854 -557.832)'
              fill='#e57e73'
            />
            <path
              id='Path_21266'
              data-name='Path 21266'
              d='M2773.9,1502.3c1.257,0,2.2-.933,2.991-2.581a58.757,58.757,0,0,0-11.861-6.379c-.174,1.079-.4,3.665.979,5.415C2767.8,1501.018,2770.808,1501.969,2773.9,1502.3Z'
              transform='translate(-2669.371 -1430.498)'
              fill='#ea9a88'
            />
            <path
              id='Path_21267'
              data-name='Path 21267'
              d='M2158.15,1400.474l-.046,0c-4.741-.537-6.807-1.529-8.844-4.245-1.087-1.45-.835-4.3-.823-4.42a.4.4,0,0,1,.8.075c0,.036-.233,2.663.666,3.861,1.9,2.533,3.76,3.414,8.29,3.927a.4.4,0,0,1-.045.8Zm-53.24-12.217a.4.4,0,0,1-.143-.78h0a9.594,9.594,0,0,0,3.992-3.583.4.4,0,1,1,.67.448,10.19,10.19,0,0,1-4.378,3.89A.4.4,0,0,1,2104.91,1388.257Z'
              transform='translate(-2053.338 -1328.234)'
              fill='#e57e73'
            />
            <path
              id='Path_21268'
              data-name='Path 21268'
              d='M2463.432,1388.259a.4.4,0,0,1-.129-.785,10.325,10.325,0,0,0,4.567-3.58.4.4,0,0,1,.669.45,10.963,10.963,0,0,1-4.976,3.894A.4.4,0,0,1,2463.432,1388.259Z'
              transform='translate(-2387.776 -1328.236)'
              fill='#f6a68f'
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

DisconnectingPowerCord.propTypes = {
  fill: PropTypes.string,
  sx: PropTypes.object,
};

DisconnectingPowerCord.defaultProps = {
  fill: 'var(--primary-icon-color)',
  sx: {},
};
