import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '../../text-field';
import SelectOld from 'components/select/select.old';
import useSelectBuyersTable from './select-buyers-hooks';
import MultiSelect from 'components/select/multi-select';
import { CircularProgress } from '@mui/material';
import BuyerEmptyStateIcon from 'assets/icons/buyer-empty-state-icon';
import EmptyState from 'components/empty-state';
import Link from '@mui/material/Link';
import CreateCustomerGroupModal from 'components/modals/create-group/create-customer-group-modal';
import PlusSignIcon from 'assets/icons/plus-sign-icon';
import Typography from '@mui/material/Typography';

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0}>
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0}>
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
export default function SelectBuyerTable({
  selectedBuyers,
  setSelectedBuyers,
  worksheetId,
  sendinviteEmail,
  isSelectAll,
  setIsSelectAll,
}) {
  const {
    options,
    handleCheck,
    buyersList,
    searchValue,
    setSearchValue,
    alphabetValue,
    setAlphabetValue,
    salesRep,
    setSalesRep,
    handleChangeRowsPerPage,
    page,
    rowsPerPage,
    onPageChangeHandler,
    groups,
    setGroups,
    groupOptions,
    isLoading,
    emailValue,
    setEmailValue,
    showCreateCustomerGroupModal,
    seShowCreateCustomerGroupModal,
    onCreateGroupSaveHandler,
    setIsLoading,
    setClearSelectedBuyersList,
  } = useSelectBuyersTable({
    selectedBuyers,
    setSelectedBuyers,
    worksheetId,
    sendinviteEmail,
    isSelectAll,
    setIsSelectAll,
  });
  return (
    <Box sx={{ overflow: 'hidden' }}>
      {/* {isLoading ? (
        <Box
          width='100vw'
          height='100vh'
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 9999,
            opacity: 0.4,
            backgroundColor: '#000',
          }}
        >
          <CircularProgress />
        </Box>
      ) : null} */}
      <CreateCustomerGroupModal
        open={showCreateCustomerGroupModal}
        setOpen={seShowCreateCustomerGroupModal}
        edit={false}
        sendApiRequest={(
          groupNameValue,
          groupDescriptionValue,
          selectedBuyers
        ) => {
          let payload = {
            name: groupNameValue,
            description: groupDescriptionValue,
            buyerIds: selectedBuyers,
          };
          onCreateGroupSaveHandler(payload);
        }}
      />
      <Stack spacing={1}>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          spacing={1}
        >
          <Box sx={{ width: '115px' }}>
            <SelectOld
              placeholder='Sort By'
              variant='outlined'
              color='secondary'
              value={salesRep}
              setValue={setSalesRep}
              allowRemoveIcon={false}
              options={options}
            />
          </Box>

          <Box sx={{ width: '115px' }}>
            <MultiSelect
              placeholder='Group'
              value={groups}
              setValue={(value) => {
                setClearSelectedBuyersList(true);
                setIsSelectAll(true);
                setGroups(value);
              }}
              options={groupOptions ?? []}
              valueAccessor='id'
              labelAccessor='name'
              defaultOption={
                <Stack
                  direction='row'
                  spacing={1.3}
                  onClick={() => {
                    seShowCreateCustomerGroupModal(true);
                  }}
                  sx={{
                    width: '100%',
                    whiteSpace: 'nowrap',
                    padding: '7px 5px 5px 5px',
                    alignItems: 'center',
                  }}
                >
                  <PlusSignIcon
                    fill='var(--theme-color)'
                    style={{ width: '12px', height: '12px' }}
                  />
                  <Typography
                    variant='body2'
                    sx={{ color: 'var(--theme-color)' }}
                  >
                    Create Group
                  </Typography>
                </Stack>
              }
            />
          </Box>

          {/* <Link
            component='button'
            variant='body2'
            onClick={() => {
              seShowCreateCustomerGroupModal(true);
            }}
            sx={{
              fontSize: '11px',
              textUnderlineOffset: '6px',
              whiteSpace: 'nowrap',
            }}
          >
            Create Group
          </Link> */}
          {sendinviteEmail ? (
            <Box sx={{ width: '115px' }}>
              <SelectOld
                placeholder='All'
                variant='outlined'
                color='secondary'
                value={emailValue}
                setValue={setEmailValue}
                allowRemoveIcon={false}
                options={[
                  {
                    label: 'All',
                    value: '99',
                  },
                  {
                    label: 'Emails not sent',
                    value: '0',
                  },
                  {
                    label: 'Emails sent',
                    value: '1',
                  },
                  {
                    label: 'Users who havent logged in',
                    value: '2',
                  },
                  {
                    label: 'Users with no email set',
                    value: '3',
                  },
                ]}
              />
            </Box>
          ) : null}

          <Stack direction='row' sx={{ fontWeight: '900' }}>
            <Button
              variant='text'
              sx={
                alphabetValue === '1'
                  ? {
                      color: '#0066c0',
                      textDecoration: 'underline',
                      fontWeight: '900',
                    }
                  : { fontWeight: '900' }
              }
              onClick={(e) => {
                e.preventDefault();
                setAlphabetValue('1');
              }}
            >
              #
            </Button>
            <Button
              variant='text'
              sx={
                alphabetValue === 'a'
                  ? {
                      color: '#0066c0',
                      textDecoration: 'underline',
                      fontWeight: '900',
                    }
                  : { fontWeight: '900' }
              }
              onClick={(e) => {
                e.preventDefault();
                setAlphabetValue('a');
              }}
            >
              A
            </Button>
            <Button
              variant='text'
              sx={
                alphabetValue === 'b'
                  ? {
                      color: '#0066c0',
                      textDecoration: 'underline',
                      fontWeight: '900',
                    }
                  : { fontWeight: '900' }
              }
              onClick={(e) => {
                e.preventDefault();
                setAlphabetValue('b');
              }}
            >
              B
            </Button>
            <Button
              variant='text'
              sx={
                alphabetValue === 'c'
                  ? {
                      color: '#0066c0',
                      textDecoration: 'underline',
                      fontWeight: '900',
                    }
                  : { fontWeight: '900' }
              }
              onClick={(e) => {
                e.preventDefault();
                setAlphabetValue('c');
              }}
            >
              C
            </Button>
            <Button
              variant='text'
              sx={
                alphabetValue === 'd'
                  ? {
                      color: '#0066c0',
                      textDecoration: 'underline',
                      fontWeight: '900',
                    }
                  : { fontWeight: '900' }
              }
              onClick={(e) => {
                e.preventDefault();
                setAlphabetValue('d');
              }}
            >
              D
            </Button>
            <Button
              variant='text'
              sx={
                alphabetValue === 'e'
                  ? {
                      color: '#0066c0',
                      textDecoration: 'underline',
                      fontWeight: '900',
                    }
                  : { fontWeight: '900' }
              }
              onClick={(e) => {
                e.preventDefault();
                setAlphabetValue('e');
              }}
            >
              E
            </Button>
            <Button
              variant='text'
              sx={
                alphabetValue === 'f'
                  ? {
                      color: '#0066c0',
                      textDecoration: 'underline',
                      fontWeight: '900',
                    }
                  : { fontWeight: '900' }
              }
              onClick={(e) => {
                e.preventDefault();
                setAlphabetValue('f');
              }}
            >
              F
            </Button>
            <Button
              variant='text'
              sx={
                alphabetValue === 'g'
                  ? {
                      color: '#0066c0',
                      textDecoration: 'underline',
                      fontWeight: '900',
                    }
                  : { fontWeight: '900' }
              }
              onClick={(e) => {
                e.preventDefault();
                setAlphabetValue('g');
              }}
            >
              G
            </Button>
            <Button
              variant='text'
              sx={
                alphabetValue === 'h'
                  ? {
                      color: '#0066c0',
                      textDecoration: 'underline',
                      fontWeight: '900',
                    }
                  : { fontWeight: '900' }
              }
              onClick={(e) => {
                e.preventDefault();
                setAlphabetValue('h');
              }}
            >
              H
            </Button>
            <Button
              variant='text'
              sx={
                alphabetValue === 'i'
                  ? {
                      color: '#0066c0',
                      textDecoration: 'underline',
                      fontWeight: '900',
                    }
                  : { fontWeight: '900' }
              }
              onClick={(e) => {
                e.preventDefault();
                setAlphabetValue('i');
              }}
            >
              I
            </Button>
            <Button
              variant='text'
              sx={
                alphabetValue === 'j'
                  ? {
                      color: '#0066c0',
                      textDecoration: 'underline',
                      fontWeight: '900',
                    }
                  : { fontWeight: '900' }
              }
              onClick={(e) => {
                e.preventDefault();
                setAlphabetValue('j');
              }}
            >
              J
            </Button>
            <Button
              variant='text'
              sx={
                alphabetValue === 'k'
                  ? {
                      color: '#0066c0',
                      textDecoration: 'underline',
                      fontWeight: '900',
                    }
                  : { fontWeight: '900' }
              }
              onClick={(e) => {
                e.preventDefault();
                setAlphabetValue('k');
              }}
            >
              K
            </Button>
            <Button
              variant='text'
              sx={
                alphabetValue === 'l'
                  ? {
                      color: '#0066c0',
                      textDecoration: 'underline',
                      fontWeight: '900',
                    }
                  : { fontWeight: '900' }
              }
              onClick={(e) => {
                e.preventDefault();
                setAlphabetValue('l');
              }}
            >
              L
            </Button>
            <Button
              variant='text'
              sx={
                alphabetValue === 'm'
                  ? {
                      color: '#0066c0',
                      textDecoration: 'underline',
                      fontWeight: '900',
                    }
                  : { fontWeight: '900' }
              }
              onClick={(e) => {
                e.preventDefault();
                setAlphabetValue('m');
              }}
            >
              M
            </Button>
            <Button
              variant='text'
              sx={
                alphabetValue === 'n'
                  ? {
                      color: '#0066c0',
                      textDecoration: 'underline',
                      fontWeight: '900',
                    }
                  : { fontWeight: '900' }
              }
              onClick={(e) => {
                e.preventDefault();
                setAlphabetValue('n');
              }}
            >
              N
            </Button>
            <Button
              variant='text'
              sx={
                alphabetValue === 'o'
                  ? {
                      color: '#0066c0',
                      textDecoration: 'underline',
                      fontWeight: '900',
                    }
                  : { fontWeight: '900' }
              }
              onClick={(e) => {
                e.preventDefault();
                setAlphabetValue('o');
              }}
            >
              O
            </Button>
            <Button
              variant='text'
              sx={
                alphabetValue === 'p'
                  ? {
                      color: '#0066c0',
                      textDecoration: 'underline',
                      fontWeight: '900',
                    }
                  : { fontWeight: '900' }
              }
              onClick={(e) => {
                e.preventDefault();
                setAlphabetValue('p');
              }}
            >
              P
            </Button>
            <Button
              variant='text'
              sx={
                alphabetValue === 'q'
                  ? {
                      color: '#0066c0',
                      textDecoration: 'underline',
                      fontWeight: '900',
                    }
                  : { fontWeight: '900' }
              }
              onClick={(e) => {
                e.preventDefault();
                setAlphabetValue('q');
              }}
            >
              Q
            </Button>
            <Button
              variant='text'
              sx={
                alphabetValue === 'r'
                  ? {
                      color: '#0066c0',
                      textDecoration: 'underline',
                      fontWeight: '900',
                    }
                  : { fontWeight: '900' }
              }
              onClick={(e) => {
                e.preventDefault();
                setAlphabetValue('r');
              }}
            >
              R
            </Button>
            <Button
              variant='text'
              sx={
                alphabetValue === 's'
                  ? {
                      color: '#0066c0',
                      textDecoration: 'underline',
                      fontWeight: '900',
                    }
                  : { fontWeight: '900' }
              }
              onClick={(e) => {
                e.preventDefault();
                setAlphabetValue('s');
              }}
            >
              S
            </Button>
            <Button
              variant='text'
              sx={
                alphabetValue === 't'
                  ? {
                      color: '#0066c0',
                      textDecoration: 'underline',
                      fontWeight: '900',
                    }
                  : { fontWeight: '900' }
              }
              onClick={(e) => {
                e.preventDefault();
                setAlphabetValue('t');
              }}
            >
              T
            </Button>
            <Button
              variant='text'
              sx={
                alphabetValue === 'u'
                  ? {
                      color: '#0066c0',
                      textDecoration: 'underline',
                      fontWeight: '900',
                    }
                  : { fontWeight: '900' }
              }
              onClick={(e) => {
                e.preventDefault();
                setAlphabetValue('u');
              }}
            >
              U
            </Button>
            <Button
              variant='text'
              sx={
                alphabetValue === 'v'
                  ? {
                      color: '#0066c0',
                      textDecoration: 'underline',
                      fontWeight: '900',
                    }
                  : { fontWeight: '900' }
              }
              onClick={(e) => {
                e.preventDefault();
                setAlphabetValue('v');
              }}
            >
              V
            </Button>
            <Button
              variant='text'
              sx={
                alphabetValue === 'w'
                  ? {
                      color: '#0066c0',
                      textDecoration: 'underline',
                      fontWeight: '900',
                    }
                  : { fontWeight: '900' }
              }
              onClick={(e) => {
                e.preventDefault();
                setAlphabetValue('w');
              }}
            >
              W
            </Button>
            <Button
              variant='text'
              sx={
                alphabetValue === 'x'
                  ? {
                      color: '#0066c0',
                      textDecoration: 'underline',
                      fontWeight: '900',
                    }
                  : { fontWeight: '900' }
              }
              onClick={(e) => {
                e.preventDefault();
                setAlphabetValue('x');
              }}
            >
              X
            </Button>
            <Button
              variant='text'
              sx={
                alphabetValue === 'y'
                  ? {
                      color: '#0066c0',
                      textDecoration: 'underline',
                      fontWeight: '900',
                    }
                  : { fontWeight: '900' }
              }
              onClick={(e) => {
                e.preventDefault();
                setAlphabetValue('y');
              }}
            >
              Y
            </Button>
            <Button
              variant='text'
              sx={
                alphabetValue === 'z'
                  ? {
                      color: '#0066c0',
                      textDecoration: 'underline',
                      fontWeight: '900',
                    }
                  : { fontWeight: '900' }
              }
              onClick={(e) => {
                e.preventDefault();
                setAlphabetValue('z');
              }}
            >
              Z
            </Button>
            <Button
              variant='text'
              sx={
                alphabetValue === ''
                  ? {
                      color: '#0066c0',
                      textDecoration: 'underline',
                      fontWeight: '900',
                    }
                  : { fontWeight: '900' }
              }
              onClick={(e) => {
                e.preventDefault();
                setAlphabetValue('');
              }}
            >
              All
            </Button>
          </Stack>
          <TextField
            placeholder='Search'
            value={searchValue}
            onChange={(e) => {
              e.preventDefault();
              setSearchValue(e.target.value);
            }}
            sx={{
              width: '200px',
              marginBottom: 0,
            }}
          />
        </Stack>
        {/* pagination table */}
        <Box>
          <TableContainer
            sx={{
              maxHeight: '400px',
              backgroundColor: 'var(--paper-background-color)',
            }}
          >
            <Table
              size='small'
              sx={{ border: '1px solid var(--secondary-border-color)' }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Stack direction='row' spacing={1}>
                      <Checkbox
                        checked={
                          isSelectAll ||
                          (buyersList?.data?.length > 0
                            ? selectedBuyers.length === buyersList?.data?.length
                            : false)
                        }
                        onClick={(e) => {
                          setClearSelectedBuyersList(true);
                          if (isSelectAll) {
                            setIsLoading(true);
                            setSelectedBuyers([]);
                          } else {
                            // buyersList?.data?.forEach((buyer) => {
                            //   setSelectedBuyers((prev) => {
                            //     return [...prev, buyer];
                            //   });
                            // });
                            setIsLoading(true);
                          }
                          setIsSelectAll(!isSelectAll);
                        }}
                      />
                      <span>ACCOUNT NAME</span>
                    </Stack>
                  </TableCell>
                  <TableCell>ACCOUNT#</TableCell>
                  <TableCell>EMAIL ADDRESS</TableCell>
                  <TableCell>DBA</TableCell>
                  <TableCell>BUYER</TableCell>
                  <TableCell>CITY</TableCell>
                  <TableCell>STATE</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell colspan='8'>
                      <Box
                        sx={{
                          height: '400px',
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <CircularProgress />
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : null}

                {buyersList?.data &&
                  buyersList?.data.map((buyer, i) => (
                    <TableRow key={i}>
                      {/* <TableCell sx={{ fontWeight: 900 }}>{row.accname}</TableCell> */}
                      <TableCell sx={{ fontWeight: '900px' }}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{ padding: '0 10px 0 10px' }}
                                checked={(() => {
                                  var x = false;
                                  selectedBuyers.forEach((item) => {
                                    if (item.buyer_id === buyer.buyer_id)
                                      x = true;
                                  });
                                  return x;
                                })()}
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleCheck(buyer);
                                }}
                              />
                            }
                            label={buyer.aname}
                          />
                        </FormGroup>
                      </TableCell>
                      <TableCell>{buyer.acct}</TableCell>
                      <TableCell>{buyer.email}</TableCell>
                      <TableCell>{buyer.dba}</TableCell>
                      <TableCell>{buyer.last}</TableCell>
                      <TableCell>{buyer.city}</TableCell>
                      <TableCell>{buyer.state}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
              <TableFooter>
                {buyersList?.count === 0 ? (
                  <TableCell colspan='8'>
                    {' '}
                    <EmptyState
                      title='No Records found'
                      subTitle='We couldnt find any Records. Try adjusting your filters to display results '
                      icon={<BuyerEmptyStateIcon />}
                      sx={{
                        border: '1px solid var(--secondary-border-color)',
                        height: '210px',
                      }}
                    />
                  </TableCell>
                ) : null}
              </TableFooter>
            </Table>
          </TableContainer>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'right',
              border: '1px solid var(--secondary-border-color)',
            }}
          >
            <TablePagination
              rowsPerPageOptions={[
                25, 50, 100 /*{ label: 'All', value: buyersList?.count }*/,
              ]}
              count={buyersList?.count}
              onPageChange={onPageChangeHandler}
              rowsPerPage={
                buyersList?.count < 50
                  ? 25
                  : isSelectAll
                  ? buyersList?.count
                  : rowsPerPage
              }
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page}
              ActionsComponent={TablePaginationActions}
              border={0}
            />
          </Box>
        </Box>
      </Stack>
      {/* Pagination table ends */}
    </Box>
  );
}

SelectBuyerTable.propTypes = {
  selectedBuyers: PropTypes.array.isRequired,
  setSelectedBuyers: PropTypes.func.isRequired,
  worksheetId: PropTypes.string.isRequired,
  sendinviteEmail: PropTypes.bool,
  isSelectAll: PropTypes.bool,
  setIsSelectAll: PropTypes.func,
};
SelectBuyerTable.defaultProps = {
  sendinviteEmail: false,
  isSelectAll: false,
  setIsSelectAll: () => {},
};
