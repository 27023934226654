import { React, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Menu from '@mui/material/Menu';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowDownIcon from '../../assets/icons/arrow-down-icon';
import useSelect from './select-hooks';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';

export default function MultiDefaultSelect({
  placeholder,
  label,
  variant,
  size,
  color,
  startAdornment,
  options,
  value,
  setValue,
  setFormikValue,
  sx,
  error,
  helperText,
  id,
  required,
  marginBottom,
  disabled,
  actions,
  defaultValue,
  defaultValueId,
  valueAccessor,
  labelAccessor,
}) {
  if (!Array.isArray(value)) return <></>;

  const { anchorEl, open, handleClick, handleClose } = useSelect();

  //on changing 'default' value, change selected values
  useEffect(() => {
    let arr = [];
    defaultValue?.options?.forEach((option) => {
      arr.push(option?.[valueAccessor]);
    });
    //if (setValue) setValue(arr);
    if (arr.length > 0) setFormikValue(id, [...arr]);
  }, [defaultValue]);

  return (
    <Box marginBottom={marginBottom} sx={{ width: '100%' }}>
      {label ? <InputLabel required={required}>{label}</InputLabel> : null}
      <Button
        id={id}
        fullWidth
        variant={variant}
        size={size}
        color={color}
        onClick={disabled ? null : handleClick}
        sx={[
          {
            display: 'flex',
            justifyContent: 'space-between',
            fontWeight: 500,
          },
          size === 'medium' ? { height: '38px' } : null,
          error ? { borderColor: 'var(--error-color)' } : null,
          sx,
        ]}
      >
        <Stack
          direction='row'
          spacing={0.5}
          justifyContent='space-between'
          alignItems='center'
          sx={{ width: '100%' }}
        >
          <Box>{startAdornment}</Box>
          <Box
            sx={{
              width: '100%',
              whiteSpace: 'nowrap',
              textAlign: 'left',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {/* function to create a string of selected options' labels */}
            {(() => {
              if (value?.length === 0 || !value) {
                return placeholder;
              }

              let str = '';
              for (let i = 0; i < value?.length; i++) {
                let matched = options?.filter(
                  (option) => option?.[valueAccessor] === value[i]
                );

                if (i === value?.length - 1) {
                  str = str?.concat(matched[0]?.[labelAccessor]);
                } else {
                  str = str?.concat(matched[0]?.[labelAccessor]);
                  str = str?.concat(', ');
                }
              }
              return str;
            })()}
          </Box>
          <Stack direction='row' spacing={1} alignItems='center'>
            <ArrowDownIcon sx={{ marginLeft: '5px', width: '15px' }} />
          </Stack>
        </Stack>
      </Button>
      {error ? (
        <Typography variant='body2' sx={{ color: 'var(--error-color)' }}>
          {helperText}
        </Typography>
      ) : null}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ maxHeight: '240px' }}
        PaperProps={{ sx: { minWidth: anchorEl && anchorEl.offsetWidth } }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <MenuItem sx={{ '&:hover': { background: '#fff' } }}>
          <Stack direction='row'>
            <span
              style={{ marginRight: '20px', opacity: 0.7, fontWeight: 300 }}
            >
              Select
            </span>
            <span style={{ opacity: 0.7, fontWeight: 300 }}>Default</span>
          </Stack>
        </MenuItem>

        {options &&
          options?.map((option, i) => {
            return (
              <MenuItem
                key={i}
                onClick={(e) => {
                  e.preventDefault();
                  if (
                    (() => {
                      if (valueAccessor) {
                        if (defaultValue && valueAccessor) {
                          return defaultValue?.options?.some(
                            (defaultValueOption) =>
                              parseInt(defaultValueOption?.[valueAccessor]) ===
                              parseInt(option?.[valueAccessor])
                          );
                        }
                      }
                      return true;
                    })()
                  ) {
                    if (value?.some((val) => val === option?.[valueAccessor])) {
                      if (option?.[valueAccessor] !== defaultValue.id) {
                        let arr = value?.filter(
                          (val) => val !== option?.[valueAccessor]
                        );
                        setValue(arr);
                        setFormikValue(id, arr);
                      }
                    } else {
                      setValue([...value, option?.[valueAccessor]]);
                      setFormikValue(id, [...value, option?.[valueAccessor]]);
                    }
                  }
                }}
                sx={
                  value === option?.[valueAccessor]
                    ? {
                        backgroundColor: 'primary.main',
                        color: 'var(--contained-primary-button-text-color)',
                        '&:hover': {
                          color: 'var(--primary-font-color)',
                        },
                      }
                    : null
                }
              >
                <Stack
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Checkbox
                    sx={{ marginRight: '20px' }}
                    checked={
                      valueAccessor
                        ? value?.some((val) => val === option?.[valueAccessor])
                        : false
                    }
                    disabled={(() => {
                      return !defaultValue?.options?.some(
                        (defaultValueOption) =>
                          parseInt(defaultValueOption?.[valueAccessor]) ===
                          parseInt(option?.[valueAccessor])
                      );
                    })()}
                  />
                  <Radio
                    id={defaultValueId}
                    checked={
                      defaultValue?.[valueAccessor] === option?.[valueAccessor]
                    }
                    sx={{
                      marginLeft: '29px',
                      marginRight: '30px',
                      width: '20px',
                      height: '20px',
                      '&:hover': { background: 'none' },
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setFormikValue(defaultValueId, option);
                    }}
                  />
                  <span>{option[labelAccessor]}</span>
                </Stack>
              </MenuItem>
            );
          })}

        {actions.map((item, i) => {
          return (
            <Box
              key={i}
              onClick={() => {
                handleClose();
              }}
            >
              {item}
            </Box>
          );
        })}
      </Menu>
    </Box>
  );
}

MultiDefaultSelect.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  options: PropTypes.array,
  startAdornment: PropTypes.node,
  value: PropTypes.string,
  sx: PropTypes.object,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  setValue: PropTypes.func,
  setFormikValue: PropTypes.func,
  id: PropTypes.string,
  required: PropTypes.bool,
  marginBottom: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  actions: PropTypes.array,
  defaultValue: PropTypes.string.isRequired,
  defaultValueId: PropTypes.string.isRequired,
  valueAccessor: PropTypes.string,
  labelAccessor: PropTypes.string,
};

MultiDefaultSelect.defaultProps = {
  placeholder: null,
  label: null,
  variant: 'outlined',
  size: 'medium',
  color: 'secondary',
  options: [],
  startAdornment: <></>,
  value: [],
  sx: {},
  error: false,
  helperText: null,
  setValue: function () {},
  setFormikValue: function () {},
  id: null,
  required: false,
  marginBottom: null,
  disabled: false,
  onChange: function () {},
  actions: [],
  valueAccessor: 'value',
  labelAccessor: 'label',
};
