import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';

import {
  Stack,
  InputLabel,
  Box,
  Grid,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Divider,
} from '@mui/material';

import {
  selectClientOptionsSelector,
  loggedInUserSelector,
  selectCustomerDetailSelector,
} from '../../../redux/selectors';

import { quickFormSchema } from './quick-details-validation-schema';
import { advancedFormSchema } from './advanced-details-validation-schema';
import { quickFormSchemaEdit } from './edit-quick-details-validation-schema';
import { advancedFormSchemaEdit } from './edit-advanced-details-validation-schema';

import MyModal from 'components/modals/modal';
import TextField from '../../text-field';
import MyRadio from '../../radio';
import Select from '../../select/select';
import MultiSelect from 'components/select/multi-select';
import MultiDefaultSelect from 'components/select/multi-default-select';
import FormTypeSelector from './components/form-type-selector';
import CloseButton from './components/close-button';
import DeclineButton from './components/decline-button';
import AddRetailerButton from './components/add-retailer-button';
import AccountRequestInfo from './components/account-request-info';

import useAddNewRetailerModal from './add-new-retailer-modal-hooks';

export default function AddNewRetailerModal({
  open,
  setOpen,
  edit,
  retailer,
  retailerId,
  prospect,
  callbacks,
  declineBtnClick,
  notificationId,
  isEditAllow,
  getRetailers,
  buyerData,
}) {
  const {
    formType,
    setFormType,
    handleSubmit,
    sendInviteEmail,
    setSendInviteEmail,
    sameAsBilling,
    setSameAsBilling,
    initialValues,
    supportData,
    isBusinessPhoneRequired,
    isBusinessPhoneRequiredMsg,
    setIsBusinessPhoneRequiredMsg,
    setIsMobilePhoneRequiredMsg,
    isMobilePhoneRequiredMsg,
    isMobilePhoneRequired,
    handleCheckBuyerUserName,
  } = useAddNewRetailerModal({
    edit,
    prospect,
    retailerId,
    callbacks,
    setOpen,
    notificationId,
    retailer,
  });

  // Get clientOptions Data
  const clientOptions = useSelector(selectClientOptionsSelector);
  const user = useSelector(loggedInUserSelector);
  const clientInfo = useSelector(selectCustomerDetailSelector);

  return (
    <MyModal
      open={open}
      setOpen={setOpen}
      size={prospect ? 'large' : 'fullWidth'}
      title={
        !isEditAllow
          ? 'View Retailer'
          : prospect
          ? 'New Prospect'
          : edit
          ? 'Edit Retailer'
          : 'Add New Retailer'
      }
      showCloseIcon
      // modal actions
      actions={[
        // close modal
        <CloseButton
          key={1}
          onClick={() => {
            setOpen(false);
          }}
        />,

        // decline retailer submit for configure account
        notificationId ? (
          <DeclineButton
            key={2}
            onClick={() => {
              declineBtnClick(retailerId);
              setOpen(false);
            }}
          />
        ) : null,

        // add/edit retailer submit
        //isEditAllow ? (
        <AddRetailerButton
          key={3}
          prospect={prospect}
          edit={edit}
          getRetailers={getRetailers}
        />,
        //) : null,
      ]}
      sx={prospect ? { width: '800px' } : null}
    >
      {Object.keys(buyerData).length > 0 ? (
        <AccountRequestInfo data={buyerData} />
      ) : null}

      <Stack spacing={1} sx={{ marginTop: '15px', marginBottom: '15px' }}>
        <Typography sx={{ fontWeight: 900 }}>Configure Account</Typography>
        <Divider />
        <Formik
          enableReinitialize
          validateOnMount
          validationSchema={(() => {
            if (edit || prospect)
              return formType === 'QUICK_DETAILS'
                ? quickFormSchemaEdit
                : advancedFormSchemaEdit;
            else
              return formType === 'QUICK_DETAILS'
                ? quickFormSchema
                : advancedFormSchema;
          })()}
          initialValues={initialValues}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({ handleSubmit, setFieldValue, ...rest }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Box
                  sx={[
                    {
                      backgroundColor: 'var(--paper-background-color)',
                      marginBottom: '20px',
                    },
                    prospect
                      ? null
                      : {
                          border: '1px solid var(--secondary-border-color)',
                          borderRadius: '6px',
                        },
                  ]}
                >
                  {prospect ? null : (
                    <Typography
                      variant='h2'
                      sx={{
                        borderBottom: '1px solid var(--secondary-border-color)',
                        padding: '13px 0 13px 20px',
                        backgroundColor: 'var(--modal-header-color)',
                      }}
                    >
                      Account Settings
                    </Typography>
                  )}

                  <Grid container columnSpacing={2}>
                    {!edit && !prospect && (
                      <Grid item xs={12} sm={12} sx={{ paddingBottom: '15px' }}>
                        <InputLabel>Do you want to add?</InputLabel>
                        <FormTypeSelector
                          formType={
                            !clientOptions?.isEditRetailerInfoVisible
                              ? setFormType('ADVANCED_DETAILS')
                              : formType
                          }
                          setFormType={setFormType}
                        />
                      </Grid>
                    )}

                    {formType === 'ADVANCED_DETAILS' ? (
                      <>
                        <Grid item xs={12} sm={prospect ? 6 : 4}>
                          <MultiDefaultSelect
                            defaultValueId='defaultPaymentMethod'
                            defaultValue={rest?.values?.defaultPaymentMethod}
                            id='paymentMethods'
                            name='paymentMethods'
                            label='Payment Methods'
                            placeholder='Select Payments Methods'
                            value={rest?.values?.paymentMethods}
                            setFormikValue={setFieldValue}
                            error={
                              rest.touched.paymentMethods &&
                              Boolean(rest.errors.paymentMethods)
                            }
                            helperText={
                              rest.touched.paymentMethods &&
                              rest.errors.paymentMethods
                            }
                            options={supportData?.payment_method}
                            marginBottom='16px'
                            labelAccessor='name'
                            valueAccessor='id'
                            sx={{
                              'pointer-events':
                                user?.roleId && user?.roleId == 3 ? 'none' : '',
                              background:
                                user?.roleId && user?.roleId == 3
                                  ? '#cdccd6'
                                  : '',
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={prospect ? 6 : 4}>
                          <MultiDefaultSelect
                            allowDefaults
                            defaultValueId='defaultShippingMethod'
                            defaultValue={rest?.values?.defaultShippingMethod}
                            id='shippingMethods'
                            name='shippingMethods'
                            label='Shipping Methods'
                            placeholder='Select Payments Methods'
                            value={rest?.values?.shippingMethods}
                            setFormikValue={setFieldValue}
                            error={
                              rest.touched.shippingMethods &&
                              Boolean(rest.errors.shippingMethods)
                            }
                            helperText={
                              rest.touched.shippingMethods &&
                              rest.errors.shippingMethods
                            }
                            options={supportData?.shipping_method ?? []}
                            marginBottom='16px'
                            labelAccessor='name'
                            valueAccessor='id'
                            sx={{
                              'pointer-events':
                                user?.roleId && user?.roleId == 3 ? 'none' : '',
                              background:
                                user?.roleId && user?.roleId == 3
                                  ? '#cdccd6'
                                  : '',
                            }}
                          />
                        </Grid>
                        {prospect ? null : <Grid item xs={12} sm={4} />}
                      </>
                    ) : null}

                    <Grid item xs={12} sm={prospect ? 6 : 4}>
                      <Select
                        required
                        id='currency'
                        name='currency'
                        label='Currency'
                        placeholder='Select Currency'
                        value={rest?.values?.currency}
                        setFormikValue={setFieldValue}
                        error={
                          rest.touched.currency && Boolean(rest.errors.currency)
                        }
                        helperText={
                          rest.touched?.currency && rest.errors?.currency?.value
                        }
                        options={supportData?.client_currency ?? []}
                        marginBottom='16px'
                        labelAccessor='name'
                        valueAccessor='id'
                        sx={{
                          'pointer-events':
                            user?.roleId && user?.roleId == 3 ? 'none' : '',
                          background:
                            user?.roleId && user?.roleId == 3 ? '#cdccd6' : '',
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={prospect ? 6 : 4}>
                      <MyRadio
                        row
                        id='inventoryAccess'
                        name='inventoryAccess'
                        label='Inventory Access'
                        options={
                          user?.roleId && user?.roleId == 3
                            ? rest?.values?.inventoryAccess === 'yes'
                              ? [{ label: 'Yes', value: 'yes' }]
                              : [{ label: 'No', value: 'no' }]
                            : [
                                { label: 'Yes', value: 'yes' },
                                { label: 'No', value: 'no' },
                              ]
                        }
                        value={rest?.values?.inventoryAccess}
                        formikOnChange={rest.handleChange}
                        error={
                          rest.touched.currency && Boolean(rest.errors.currency)
                        }
                        helperText={
                          rest.touched.currency && rest.errors.currency
                        }
                      />
                    </Grid>
                    {prospect ? null : <Grid item xs={12} sm={4} />}
                    {clientInfo?.show_vat ? (
                      <Grid item xs={12} sm={prospect ? 6 : 4}>
                        <Select
                          id='VAT'
                          name='VAT'
                          label='VAT / TAX'
                          placeholder='Select VAT / TAX'
                          value={rest?.values?.VAT}
                          setFormikValue={setFieldValue}
                          error={rest.touched.VAT && Boolean(rest.errors.VAT)}
                          helperText={rest.touched.VAT && rest.errors.VAT}
                          options={supportData?.client_vat ?? []}
                          marginBottom='16px'
                          labelAccessor='name'
                          valueAccessor='id'
                          sx={{
                            'pointer-events':
                              user?.roleId && user?.roleId == 3 ? 'none' : '',
                            background:
                              user?.roleId && user?.roleId == 3
                                ? '#cdccd6'
                                : '',
                          }}
                        />
                      </Grid>
                    ) : null}

                    <Grid item xs={12} sm={prospect ? 6 : 4}>
                      <Select
                        required
                        id='priceGroup'
                        name='priceGroup'
                        label='Price Group'
                        placeholder='Select Price Group'
                        allowRemoveIcon={false}
                        value={rest?.values?.priceGroup}
                        setFormikValue={(id, value) => {
                          setFieldValue(id, value);

                          let additionalGroupArray =
                            rest.values.additionalPriceGroup.filter(
                              (item) => item !== value
                            );

                          setFieldValue(
                            'additionalPriceGroup',
                            additionalGroupArray
                          );
                        }}
                        error={
                          rest.touched.priceGroup &&
                          Boolean(rest.errors.priceGroup)
                        }
                        helperText={
                          rest.touched.priceGroup &&
                          rest.errors.priceGroup?.value
                        }
                        options={supportData?.price_groups ?? []}
                        marginBottom='16px'
                        labelAccessor='name'
                        valueAccessor='id'
                        sx={{
                          'pointer-events':
                            user?.roleId && user?.roleId == 3 ? 'none' : '',
                          background:
                            user?.roleId && user?.roleId == 3 ? '#cdccd6' : '',
                        }}
                      />
                    </Grid>
                    {prospect ? null : <Grid item xs={12} sm={4} />}

                    <Grid item xs={12} sm={prospect ? 6 : 4}>
                      <Select
                        required
                        id='salesRep'
                        name='salesRep'
                        label='Sales Rep'
                        placeholder='Select Sales Rep'
                        value={rest?.values?.salesRep}
                        setFormikValue={setFieldValue}
                        error={
                          rest.touched.salesRep && Boolean(rest.errors.salesRep)
                        }
                        helperText={
                          rest.touched.salesRep && rest.errors.salesRep?.value
                        }
                        options={supportData?.sales_rep ?? []}
                        marginBottom='16px'
                        labelAccessor='name'
                        valueAccessor='id'
                        sx={{
                          'pointer-events':
                            user?.roleId && user?.roleId == 3 ? 'none' : '',
                          background:
                            user?.roleId && user?.roleId == 3 ? '#cdccd6' : '',
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={prospect ? 6 : 4}>
                      {user?.roleId && user?.roleId == 3 ? (
                        ''
                      ) : (
                        <MultiSelect
                          id='additionalPriceGroup'
                          name='additionalPriceGroup'
                          label='Additional Price Groups'
                          placeholder='Select Price Group'
                          value={rest?.values?.additionalPriceGroup}
                          setFormikValue={setFieldValue}
                          error={
                            rest.touched.additionalPriceGroup &&
                            Boolean(rest.errors.additionalPriceGroup)
                          }
                          helperText={
                            rest.touched.additionalPriceGroup &&
                            rest.errors.additionalPriceGroup
                          }
                          options={
                            supportData?.price_groups?.filter(
                              (priceGroup) =>
                                priceGroup.id !== rest.values?.priceGroup
                            ) ?? []
                          }
                          marginBottom='16px'
                          labelAccessor='name'
                          valueAccessor='id'
                          sx={{
                            'pointer-events':
                              user?.roleId && user?.roleId == 3 ? 'none' : '',
                            background:
                              user?.roleId && user?.roleId == 3
                                ? '#cdccd6'
                                : '',
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Box>

                {prospect ? null : (
                  <>
                    <Box
                      sx={{
                        border: '1px solid var(--secondary-border-color)',
                        borderRadius: '6px',
                        backgroundColor: 'var(--paper-background-color)',
                        marginBottom: '20px',
                      }}
                    >
                      <Typography
                        variant='h2'
                        sx={{
                          borderBottom:
                            '1px solid var(--secondary-border-color)',
                          padding: '13px 0 13px 20px',
                          backgroundColor: 'var(--modal-header-color)',
                        }}
                      >
                        Company Information
                      </Typography>

                      <Grid
                        container
                        sx={{ padding: '16px 20px 23px 20px' }}
                        columnSpacing={2}
                      >
                        <Grid item xs={12} sm={4}>
                          <TextField
                            required
                            id='legalName'
                            name='legalName'
                            label='Legal Name'
                            placeholder='Enter legal name'
                            value={rest?.values?.legalName}
                            onChange={rest.handleChange}
                            error={
                              rest.touched.legalName &&
                              Boolean(rest.errors.legalName)
                            }
                            helperText={
                              rest.touched.legalName && rest.errors.legalName
                            }
                            marginBottom='16px'
                            sx={
                              user?.legalName &&
                              user?.legalName['color'] == 'red'
                                ? { color: 'red' }
                                : {}
                            }
                          />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <TextField
                            id='customerId'
                            name='customerId'
                            label='Customer ID / Code'
                            placeholder='Enter customer id / code'
                            value={rest?.values?.customerId}
                            onChange={rest.handleChange}
                            error={
                              rest.touched.customerId &&
                              Boolean(rest.errors.customerId)
                            }
                            helperText={
                              rest.touched.customerId && rest.errors.customerId
                            }
                            marginBottom='16px'
                            sx={
                              user?.customerIdOrCode &&
                              user?.customerIdOrCode['color'] == 'red'
                                ? { color: 'red' }
                                : {}
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={4} />
                        <Grid item xs={12} sm={4}>
                          <TextField
                            id='dba'
                            name='dba'
                            label='DBA / Rooftop Name'
                            placeholder='Enter DBA/ rooftop name'
                            value={rest?.values?.dba}
                            onChange={rest.handleChange}
                            error={rest.touched.dba && Boolean(rest.errors.dba)}
                            helperText={rest.touched.dba && rest.errors.dba}
                            marginBottom='16px'
                            sx={
                              user?.dBAOrRooftopName &&
                              user?.dBAOrRooftopName['color'] == 'red'
                                ? { color: 'red' }
                                : {}
                            }
                          />
                        </Grid>
                        {formType === 'ADVANCED_DETAILS' ? (
                          <>
                            <Grid item xs={12} sm={4}>
                              <TextField
                                id='vendorCode'
                                name='vendorCode'
                                label='Vendor Code'
                                placeholder='Enter Vendor Code'
                                value={rest?.values?.vendorCode}
                                onChange={rest.handleChange}
                                error={
                                  rest.touched.vendorCode &&
                                  Boolean(rest.errors.vendorCode)
                                }
                                helperText={
                                  rest.touched.vendorCode &&
                                  rest.errors.vendorCode
                                }
                                marginBottom='16px'
                                sx={
                                  user?.vendorCode &&
                                  user?.vendorCode['color'] == 'red'
                                    ? { color: 'red' }
                                    : {}
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={4} />
                            <Grid item xs={12} sm={4}>
                              <TextField
                                id='tax_id'
                                name='tax_id'
                                label='Tax ID'
                                placeholder='Enter Tax ID'
                                value={rest?.values?.tax_id}
                                onChange={rest.handleChange}
                                error={
                                  rest.touched.tax_id &&
                                  Boolean(rest.errors.tax_id)
                                }
                                helperText={
                                  rest.touched.tax_id && rest.errors.tax_id
                                }
                                marginBottom='16px'
                                sx={
                                  user?.tax_id && user?.tax_id['color'] == 'red'
                                    ? { color: 'red' }
                                    : {}
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              {/* <Textarea
                                id='note'
                                name='note'
                                label='Note'
                                rows={3}
                                placeholder='Enter Note'
                                value={rest?.values?.note}
                                onChange={rest.handleChange}
                                error={
                                  rest.touched.note && Boolean(rest.errors.note)
                                }
                                helperText={
                                  rest.touched.note && rest.errors.note
                                }
                                sx={
                                  user?.note && user?.note['color'] == 'red'
                                    ? { color: 'red' }
                                    : {}
                                }
                              /> */}
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <Typography
                                variant='h2'
                                sx={{
                                  borderBottom:
                                    '1px solid var(--secondary-border-color)',
                                  padding: '13px 0 5px 0',
                                  marginBottom: '16px',
                                }}
                              >
                                Billing Information
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <TextField
                                id='billingContact'
                                name='billingContact'
                                label='Billing Contact'
                                placeholder='Enter billing contact'
                                value={rest?.values?.billingContact}
                                onChange={rest.handleChange}
                                error={
                                  rest.touched.billingContact &&
                                  Boolean(rest.errors.billingContact)
                                }
                                helperText={
                                  rest.touched.billingContact &&
                                  rest.errors.billingContact
                                }
                                marginBottom='16px'
                                sx={
                                  user?.billingContact &&
                                  user?.billingContact['color'] == 'red'
                                    ? { color: 'red' }
                                    : {}
                                }
                              />
                            </Grid>
                            {edit ? null : (
                              <>
                                <Grid item xs={12} sm={4}>
                                  <TextField
                                    id='billingLocationName'
                                    name='billingLocationName'
                                    label='Location Name'
                                    placeholder='Enter location name'
                                    value={rest?.values?.billingLocationName}
                                    onChange={(e) => {
                                      rest.handleChange(e);
                                      sameAsBilling
                                        ? setFieldValue(
                                            'shippingLocationName',
                                            e.target.value
                                          )
                                        : null;
                                    }}
                                    error={
                                      rest.touched.billingLocationName &&
                                      Boolean(rest.errors.billingLocationName)
                                    }
                                    helperText={
                                      rest.touched.billingLocationName &&
                                      rest.errors.billingLocationName
                                    }
                                    marginBottom='16px'
                                  />
                                </Grid>

                                <Grid item xs={12} sm={4} />
                              </>
                            )}
                            <Grid item xs={12} sm={4}>
                              <TextField
                                id='billingId'
                                name='billingId'
                                label='Billing ID / Code'
                                placeholder='Enter billing id/ code'
                                value={rest?.values?.billingId}
                                onChange={(e) => {
                                  rest.handleChange(e);
                                  sameAsBilling
                                    ? setFieldValue(
                                        'shippingShippingId',
                                        e.target.value
                                      )
                                    : null;
                                }}
                                error={
                                  rest.touched.billingId &&
                                  Boolean(rest.errors.billingId)
                                }
                                helperText={
                                  rest.touched.billingId &&
                                  rest.errors.billingId
                                }
                                marginBottom='16px'
                                sx={
                                  user?.billingIdOrCode &&
                                  user?.billingIdOrCode['color'] == 'red'
                                    ? { color: 'red' }
                                    : {}
                                }
                              />
                            </Grid>
                            {edit ? <Grid item xs={12} sm={4} /> : null}
                            <Grid item xs={12} sm={4}>
                              <TextField
                                id='billingPhone'
                                name='billingPhone'
                                label='Billing Phone'
                                placeholder='Enter billing phone'
                                value={rest?.values?.billingPhone}
                                onChange={rest.handleChange}
                                error={
                                  rest.touched.billingPhone &&
                                  Boolean(rest.errors.billingPhone)
                                }
                                helperText={
                                  rest.touched.billingPhone &&
                                  rest.errors.billingPhone
                                }
                                marginBottom='16px'
                                sx={
                                  user?.phone && user?.phone['color'] == 'red'
                                    ? { color: 'red' }
                                    : {}
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={4} />
                            {edit ? null : (
                              <Grid item xs={12} sm={4}>
                                <TextField
                                  id='billingAttention'
                                  name='billingAttention'
                                  label='Attention'
                                  placeholder='Enter attention'
                                  value={rest?.values?.billingAttention}
                                  onChange={(e) => {
                                    rest.handleChange(e);
                                    sameAsBilling
                                      ? setFieldValue(
                                          'shippingAttention',
                                          e.target.value
                                        )
                                      : null;
                                  }}
                                  error={
                                    rest.touched.billingAttention &&
                                    Boolean(rest.errors.billingAttention)
                                  }
                                  helperText={
                                    rest.touched.billingAttention &&
                                    rest.errors.billingAttention
                                  }
                                  marginBottom='16px'
                                />
                              </Grid>
                            )}
                            {edit ? <Grid item xs={12} sm={4} /> : null}
                            <Grid item xs={12} sm={4}>
                              <TextField
                                required={!edit}
                                id='billingAddressLine1'
                                name='billingAddressLine1'
                                label='Address Line 1'
                                placeholder='Enter address line 1'
                                value={rest?.values?.billingAddressLine1}
                                onChange={(e) => {
                                  rest.handleChange(e);
                                  sameAsBilling
                                    ? setFieldValue(
                                        'shippingAddressLine1',
                                        e.target.value
                                      )
                                    : null;
                                }}
                                error={
                                  rest.touched.billingAddressLine1 &&
                                  Boolean(rest.errors.billingAddressLine1)
                                }
                                helperText={
                                  rest.touched.billingAddressLine1 &&
                                  rest.errors.billingAddressLine1
                                }
                                marginBottom='16px'
                                sx={
                                  user?.phone &&
                                  user?.buyerStreetAddress['color'] == 'red'
                                    ? { color: 'red' }
                                    : {}
                                }
                              />
                            </Grid>
                            {edit ? null : <Grid item xs={12} sm={4} />}
                            <Grid item xs={12} sm={4}>
                              <TextField
                                id='billingAddressLine2'
                                name='billingAddressLine2'
                                label='Address Line 2'
                                placeholder='Enter address line 2'
                                value={rest?.values?.billingAddressLine2}
                                onChange={(e) => {
                                  rest.handleChange(e);
                                  sameAsBilling
                                    ? setFieldValue(
                                        'shippingAddressLine2',
                                        e.target.value
                                      )
                                    : null;
                                }}
                                error={
                                  rest.touched.billingAddressLine2 &&
                                  Boolean(rest.errors.billingAddressLine2)
                                }
                                helperText={
                                  rest.touched.billingAddressLine2 &&
                                  rest.errors.billingAddressLine2
                                }
                                marginBottom='16px'
                                sx={
                                  user?.buyerStreetAddress2 &&
                                  user?.buyerStreetAddress2['color'] == 'red'
                                    ? { color: 'red' }
                                    : {}
                                }
                              />
                            </Grid>
                            {edit ? <Grid item xs={12} sm={4} /> : null}
                            <Grid item xs={12} sm={2}>
                              <TextField
                                required={!edit}
                                id='billingCity'
                                name='billingCity'
                                label='City'
                                placeholder='Enter city'
                                value={rest?.values?.billingCity}
                                onChange={(e) => {
                                  rest.handleChange(e);
                                  sameAsBilling
                                    ? setFieldValue(
                                        'shippingCity',
                                        e.target.value
                                      )
                                    : null;
                                }}
                                error={
                                  rest.touched.billingCity &&
                                  Boolean(rest.errors.billingCity)
                                }
                                helperText={
                                  rest.touched.billingCity &&
                                  rest.errors.billingCity
                                }
                                marginBottom='16px'
                                sx={
                                  user?.buyerCity &&
                                  user?.buyerCity['color'] == 'red'
                                    ? { color: 'red' }
                                    : {}
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                              <TextField
                                required={!edit}
                                id='billingState'
                                name='billingState'
                                label='State / Province'
                                placeholder='Enter state / province'
                                value={rest?.values?.billingState}
                                onChange={(e) => {
                                  rest.handleChange(e);
                                  sameAsBilling
                                    ? setFieldValue(
                                        'shippingState',
                                        e.target.value
                                      )
                                    : null;
                                }}
                                error={
                                  rest.touched.billingState &&
                                  Boolean(rest.errors.billingState)
                                }
                                helperText={
                                  rest.touched.billingState &&
                                  rest.errors.billingState
                                }
                                marginBottom='16px'
                                inputProps={{
                                  maxLength:
                                    clientOptions?.additionalSettings
                                      ?.state_length != 0 &&
                                    clientOptions?.additionalSettings
                                      ?.state_length,
                                }}
                                sx={
                                  user?.buyerState &&
                                  user?.buyerState['color'] == 'red'
                                    ? { color: 'red' }
                                    : {}
                                }
                              />
                            </Grid>
                            {edit ? null : <Grid item xs={12} sm={4} />}
                            <Grid item xs={12} sm={2}>
                              <TextField
                                required={!edit}
                                id='billingPostCode'
                                name='billingPostCode'
                                label='Zip / Postal Code'
                                placeholder='Enter post code'
                                value={rest?.values?.billingPostCode}
                                onChange={(e) => {
                                  rest.handleChange(e);
                                  sameAsBilling
                                    ? setFieldValue(
                                        'shippingPostCode',
                                        e.target.value
                                      )
                                    : null;
                                }}
                                error={
                                  rest.touched.billingPostCode &&
                                  Boolean(rest.errors.billingPostCode)
                                }
                                helperText={
                                  rest.touched.billingPostCode &&
                                  rest.errors.billingPostCode
                                }
                                marginBottom='16px'
                                sx={
                                  user?.buyerZipCode &&
                                  user?.buyerZipCode['color'] == 'red'
                                    ? { color: 'red' }
                                    : {}
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                              <TextField
                                required={!edit}
                                id='billingCountry'
                                name='billingCountry'
                                label='Country'
                                placeholder='Enter Country'
                                value={rest?.values?.billingCountry}
                                onChange={(e) => {
                                  rest.handleChange(e);
                                  sameAsBilling
                                    ? setFieldValue(
                                        'shippingCountry',
                                        e.target.value
                                      )
                                    : null;
                                }}
                                error={
                                  rest.touched.billingCountry &&
                                  Boolean(rest.errors.billingCountry)
                                }
                                helperText={
                                  rest.touched.billingCountry &&
                                  rest.errors.billingCountry
                                }
                                marginBottom='16px'
                                inputProps={{
                                  maxLength:
                                    clientOptions?.additionalSettings
                                      ?.country_length != 0 &&
                                    clientOptions?.additionalSettings
                                      ?.country_length,
                                }}
                                sx={
                                  user?.buyerCountry &&
                                  user?.buyerCountry['color'] == 'red'
                                    ? { color: 'red' }
                                    : {}
                                }
                              />
                            </Grid>
                            {edit ? null : (
                              <>
                                <Grid item xs={12} sm={12}>
                                  <Stack
                                    direction='row'
                                    justifyContent='space-between'
                                    sx={{
                                      borderBottom:
                                        '1px solid var(--secondary-border-color)',
                                      padding: '13px 0 3px 0',
                                      marginBottom: '20px',
                                    }}
                                  >
                                    <Typography
                                      variant='h2'
                                      sx={{
                                        fontSize: '15px',
                                      }}
                                    >
                                      Shipping Information
                                    </Typography>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={sameAsBilling}
                                            onClick={() => {
                                              setSameAsBilling(!sameAsBilling);

                                              for (const value in rest.values) {
                                                if (!sameAsBilling) {
                                                  rest.values[
                                                    'shippingLocationName'
                                                  ] =
                                                    rest.values[
                                                      'billingLocationName'
                                                    ];

                                                  rest.values[
                                                    'shippingShippingId'
                                                  ] = rest.values['billingId'];

                                                  rest.values[
                                                    'shippingAddressLine1'
                                                  ] =
                                                    rest.values[
                                                      'billingAddressLine1'
                                                    ];

                                                  rest.values[
                                                    'shippingAddressLine2'
                                                  ] =
                                                    rest.values[
                                                      'billingAddressLine2'
                                                    ];

                                                  rest.values['shippingState'] =
                                                    rest.values['billingState'];

                                                  rest.values[
                                                    'shippingCountry'
                                                  ] =
                                                    rest.values[
                                                      'billingCountry'
                                                    ];

                                                  rest.values[
                                                    'shippingPostCode'
                                                  ] =
                                                    rest.values[
                                                      'billingPostCode'
                                                    ];

                                                  rest.values[
                                                    'shippingAttention'
                                                  ] =
                                                    rest.values[
                                                      'billingAttention'
                                                    ];
                                                  rest.values['shippingCity'] =
                                                    rest.values['billingCity'];
                                                } else {
                                                  rest.values[
                                                    'shippingLocationName'
                                                  ] = '';

                                                  rest.values[
                                                    'shippingShippingId'
                                                  ] = '';

                                                  rest.values[
                                                    'shippingAddressLine1'
                                                  ] = '';

                                                  rest.values[
                                                    'shippingAddressLine2'
                                                  ] = '';

                                                  rest.values['shippingState'] =
                                                    '';

                                                  rest.values[
                                                    'shippingCountry'
                                                  ] = '';

                                                  rest.values[
                                                    'shippingPostCode'
                                                  ] = '';

                                                  rest.values[
                                                    'shippingAttention'
                                                  ] = '';
                                                  rest.values['shippingCity'] =
                                                    '';
                                                }
                                              }
                                            }}
                                          />
                                        }
                                        label='Same as Billing Address'
                                      />
                                    </FormGroup>
                                  </Stack>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <TextField
                                    disabled={sameAsBilling}
                                    id='shippingLocationName'
                                    name='shippingLocationName'
                                    label='Location Name'
                                    placeholder='Enter location name'
                                    value={rest?.values?.shippingLocationName}
                                    onChange={rest.handleChange}
                                    error={
                                      rest.touched.shippingLocationName &&
                                      Boolean(rest.errors.shippingLocationName)
                                    }
                                    helperText={
                                      rest.touched.shippingLocationName &&
                                      rest.errors.shippingLocationName
                                    }
                                    marginBottom='16px'
                                  />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <TextField
                                    disabled={sameAsBilling}
                                    id='shippingShippingId'
                                    name='shippingShippingId'
                                    label='Shipping ID / Code'
                                    placeholder='Enter shipping id/ code'
                                    value={rest?.values?.shippingShippingId}
                                    onChange={rest.handleChange}
                                    error={
                                      rest.touched.shippingShippingId &&
                                      Boolean(rest.errors.shippingShippingId)
                                    }
                                    helperText={
                                      rest.touched.shippingShippingId &&
                                      rest.errors.shippingShippingId
                                    }
                                    marginBottom='16px'
                                  />
                                </Grid>
                                <Grid item xs={12} sm={4} />
                                <Grid item xs={12} sm={4}>
                                  <TextField
                                    disabled={sameAsBilling}
                                    id='shippingAttention'
                                    name='shippingAttention'
                                    label='Attention'
                                    placeholder='Enter attention'
                                    value={rest?.values?.shippingAttention}
                                    onChange={rest.handleChange}
                                    error={
                                      rest.touched.shippingAttention &&
                                      Boolean(rest.errors.shippingAttention)
                                    }
                                    helperText={
                                      rest.touched.shippingAttention &&
                                      rest.errors.shippingAttention
                                    }
                                    marginBottom='16px'
                                  />
                                </Grid>
                                <Grid item xs={12} sm={4} />
                                <Grid item xs={12} sm={4} />
                                <Grid item xs={12} sm={4}>
                                  <TextField
                                    disabled={sameAsBilling}
                                    required={!edit}
                                    id='shippingAddressLine1'
                                    name='shippingAddressLine1'
                                    label='Address Line 1'
                                    placeholder='Enter address line 1'
                                    value={rest?.values?.shippingAddressLine1}
                                    onChange={rest.handleChange}
                                    error={
                                      rest.touched.shippingAddressLine1 &&
                                      Boolean(rest.errors.shippingAddressLine1)
                                    }
                                    helperText={
                                      rest.touched.shippingAddressLine1 &&
                                      rest.errors.shippingAddressLine1
                                    }
                                    marginBottom='16px'
                                  />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <TextField
                                    disabled={sameAsBilling}
                                    id='shippingAddressLine2'
                                    name='shippingAddressLine2'
                                    label='Address Line 2'
                                    placeholder='Enter address line 2'
                                    value={rest?.values?.shippingAddressLine2}
                                    onChange={rest.handleChange}
                                    error={
                                      rest.touched.shippingAddressLine2 &&
                                      Boolean(rest.errors.shippingAddressLine2)
                                    }
                                    helperText={
                                      rest.touched.shippingAddressLine2 &&
                                      rest.errors.shippingAddressLine2
                                    }
                                    marginBottom='16px'
                                  />
                                </Grid>
                                <Grid item xs={12} sm={4} />
                                <Grid item xs={12} sm={2}>
                                  <TextField
                                    disabled={sameAsBilling}
                                    required={!edit}
                                    id='shippingCity'
                                    name='shippingCity'
                                    label='City'
                                    placeholder='Enter city'
                                    value={rest?.values?.shippingCity}
                                    onChange={rest.handleChange}
                                    error={
                                      rest.touched.shippingCity &&
                                      Boolean(rest.errors.shippingCity)
                                    }
                                    helperText={
                                      rest.touched.shippingCity &&
                                      rest.errors.shippingCity
                                    }
                                    marginBottom='16px'
                                  />
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                  <TextField
                                    disabled={sameAsBilling}
                                    required={!edit}
                                    id='shippingState'
                                    name='shippingState'
                                    label='State / Province'
                                    placeholder='Enter state / province'
                                    value={rest?.values?.shippingState}
                                    onChange={rest.handleChange}
                                    error={
                                      rest.touched.shippingState &&
                                      Boolean(rest.errors.shippingState)
                                    }
                                    helperText={
                                      rest.touched.shippingState &&
                                      rest.errors.shippingState
                                    }
                                    marginBottom='16px'
                                    inputProps={{
                                      maxLength:
                                        clientOptions?.additionalSettings
                                          ?.state_length != 0 &&
                                        clientOptions?.additionalSettings
                                          ?.state_length,
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                  <TextField
                                    disabled={sameAsBilling}
                                    required={!edit}
                                    id='shippingPostCode'
                                    name='shippingPostCode'
                                    label='Zip / Postal Code'
                                    placeholder='Enter post code'
                                    value={rest?.values?.shippingPostCode}
                                    onChange={rest.handleChange}
                                    error={
                                      rest.touched.shippingPostCode &&
                                      Boolean(rest.errors.shippingPostCode)
                                    }
                                    helperText={
                                      rest.touched.shippingPostCode &&
                                      rest.errors.shippingPostCode
                                    }
                                    marginBottom='16px'
                                  />
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                  <TextField
                                    disabled={sameAsBilling}
                                    required={!edit}
                                    id='shippingCountry'
                                    name='shippingCountry'
                                    label='Country'
                                    placeholder='Enter Country'
                                    value={rest?.values?.shippingCountry}
                                    onChange={rest.handleChange}
                                    error={
                                      rest.touched.shippingCountry &&
                                      Boolean(rest.errors.shippingCountry)
                                    }
                                    helperText={
                                      rest.touched.shippingCountry &&
                                      rest.errors.shippingCountry
                                    }
                                    marginBottom='16px'
                                    inputProps={{
                                      maxLength:
                                        clientOptions?.additionalSettings
                                          ?.country_length != 0 &&
                                        clientOptions?.additionalSettings
                                          ?.country_length,
                                    }}
                                  />
                                </Grid>
                              </>
                            )}
                          </>
                        ) : null}
                      </Grid>
                    </Box>
                    {edit ? null : (
                      <Box
                        sx={{
                          border: '1px solid var(--secondary-border-color)',
                          borderRadius: '6px',
                          backgroundColor: 'var(--paper-background-color)',
                        }}
                      >
                        <Stack
                          direction='row'
                          justifyContent='space-between'
                          alignItems='center'
                          sx={{
                            borderBottom:
                              '1px solid var(--secondary-border-color)',
                            paddingLeft: '20px',
                            backgroundColor: 'var(--modal-header-color)',
                          }}
                        >
                          <Typography
                            variant='h2'
                            sx={{
                              borderBottom:
                                '1px solid var(--secondary-border-color)',
                              padding: '13px 0 13px 0',
                            }}
                          >
                            Buyer Information
                          </Typography>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={sendInviteEmail}
                                  onClick={() => {
                                    setSendInviteEmail(!sendInviteEmail);
                                  }}
                                />
                              }
                              label='Send Invite Email'
                            />
                          </FormGroup>
                        </Stack>
                        <Grid
                          container
                          sx={{ padding: '16px 20px 23px 20px' }}
                          columnSpacing={2}
                        >
                          <Grid item xs={12} sm={4}>
                            <TextField
                              required={!edit}
                              id='buyerName'
                              name='buyerName'
                              label='Name'
                              placeholder='Enter buyer name'
                              value={rest?.values?.buyerName}
                              onChange={rest.handleChange}
                              error={
                                rest.touched.buyerName &&
                                Boolean(rest.errors.buyerName)
                              }
                              helperText={
                                rest.touched.buyerName && rest.errors.buyerName
                              }
                              marginBottom='16px'
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              required={!edit}
                              id='emailAddress'
                              name='emailAddress'
                              label='Email Address'
                              placeholder='Enter email address'
                              value={rest?.values?.emailAddress}
                              onBlur={(e) => {
                                rest.handleBlur(e);

                                // handleCheckBuyerUserName(
                                //   e.target.value,
                                //   rest.setFieldError
                                // );
                              }}
                              onChange={(e) => {
                                rest.handleChange(e);
                                setFieldValue('username', e.target.value);
                              }}
                              error={
                                rest.touched.emailAddress &&
                                Boolean(rest.errors.emailAddress)
                              }
                              helperText={
                                rest.touched.emailAddress &&
                                rest.errors.emailAddress
                              }
                              marginBottom='16px'
                            />
                          </Grid>
                          <Grid item xs={12} sm={4} />
                          <Grid item xs={12} sm={4}>
                            <TextField
                              required={!edit}
                              id='username'
                              name='username'
                              label='Username'
                              placeholder='Enter username'
                              onBlur={(e) => {
                                rest.handleBlur(e);
                                handleCheckBuyerUserName(
                                  e.target.value,
                                  rest.setFieldError
                                );
                              }}
                              value={rest?.values?.username}
                              onChange={rest.handleChange}
                              error={
                                rest.touched.username &&
                                Boolean(rest.errors.username)
                              }
                              helperText={
                                rest.touched.username && rest.errors.username
                              }
                              marginBottom='16px'
                            />
                          </Grid>
                          {formType === 'ADVANCED_DETAILS' ? (
                            <>
                              {rest?.values?.businessPhone != '' ||
                              !isBusinessPhoneRequired
                                ? setIsBusinessPhoneRequiredMsg('')
                                : setIsBusinessPhoneRequiredMsg(
                                    'Business Phone is a required field'
                                  )}

                              {rest?.values?.mobilePhone != '' ||
                              !isMobilePhoneRequired
                                ? setIsMobilePhoneRequiredMsg('')
                                : setIsMobilePhoneRequiredMsg(
                                    'Mobile Phone is a required field'
                                  )}

                              <Grid item xs={12} sm={4}>
                                <TextField
                                  id='businessPhone'
                                  name='businessPhone'
                                  label='Business Phone'
                                  placeholder='Enter business phone'
                                  required={isBusinessPhoneRequired}
                                  value={rest?.values?.businessPhone}
                                  onChange={rest.handleChange}
                                  error={
                                    (rest.touched.businessPhone &&
                                      Boolean(rest.errors.businessPhone)) ||
                                    (Boolean(isBusinessPhoneRequiredMsg) &&
                                      rest.touched.businessPhone)
                                  }
                                  helperText={
                                    (rest.touched.businessPhone &&
                                      rest.errors.businessPhone) ||
                                    (rest.touched.businessPhone &&
                                      isBusinessPhoneRequiredMsg)
                                  }
                                  marginBottom='16px'
                                />
                              </Grid>
                              <Grid item xs={12} sm={4} />
                              <Grid item xs={12} sm={4}>
                                <TextField
                                  id='businessFax'
                                  name='businessFax'
                                  label='Business Fax'
                                  placeholder='Enter business fax'
                                  value={rest?.values?.businessFax}
                                  onChange={rest.handleChange}
                                  error={
                                    rest.touched.businessFax &&
                                    Boolean(rest.errors.businessFax)
                                  }
                                  helperText={
                                    rest.touched.businessFax &&
                                    rest.errors.businessFax
                                  }
                                  marginBottom='16px'
                                />
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <TextField
                                  id='title'
                                  name='title'
                                  label='Title'
                                  placeholder='Enter title'
                                  value={rest?.values?.title}
                                  onChange={rest.handleChange}
                                  error={
                                    rest.touched.title &&
                                    Boolean(rest.errors.title)
                                  }
                                  helperText={
                                    rest.touched.title && rest.errors.title
                                  }
                                  marginBottom='16px'
                                />
                              </Grid>
                              <Grid item xs={12} sm={4} />
                              <Grid item xs={12} sm={4}>
                                <TextField
                                  id='buyerAddressLine1'
                                  name='buyerAddressLine1'
                                  label='Address Line 1'
                                  placeholder='Enter adress line 1'
                                  value={rest?.values?.buyerAddressLine1}
                                  onChange={rest.handleChange}
                                  error={
                                    rest.touched.buyerAddressLine1 &&
                                    Boolean(rest.errors.buyerAddressLine1)
                                  }
                                  helperText={
                                    rest.touched.buyerAddressLine1 &&
                                    rest.errors.buyerAddressLine1
                                  }
                                  marginBottom='16px'
                                />
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <TextField
                                  id='buyerAddressLine2'
                                  name='buyerAddressLine2'
                                  label='Address Line 2'
                                  placeholder='Enter address line 2'
                                  value={rest?.values?.buyerAddressLine2}
                                  onChange={rest.handleChange}
                                  error={
                                    rest.touched.buyerAddressLine2 &&
                                    Boolean(rest.errors.buyerAddressLine2)
                                  }
                                  helperText={
                                    rest.touched.buyerAddressLine2 &&
                                    rest.errors.buyerAddressLine2
                                  }
                                  marginBottom='16px'
                                />
                              </Grid>
                              <Grid item xs={12} sm={4} />
                              <Grid item xs={12} sm={4}>
                                <TextField
                                  required={isMobilePhoneRequired}
                                  id='mobilePhone'
                                  name='mobilePhone'
                                  label='Mobile Phone'
                                  placeholder='Enter mobile phone'
                                  value={rest?.values?.mobilePhone}
                                  onChange={rest.handleChange}
                                  error={
                                    (rest.touched.mobilePhone &&
                                      Boolean(rest.errors.mobilePhone)) ||
                                    (Boolean(isMobilePhoneRequiredMsg) &&
                                      rest.touched.mobilePhone)
                                  }
                                  helperText={
                                    (rest.touched.mobilePhone &&
                                      rest.errors.mobilePhone) ||
                                    (rest.touched.mobilePhone &&
                                      isMobilePhoneRequiredMsg)
                                  }
                                  marginBottom='16px'
                                />
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <TextField
                                  id='buyerCity'
                                  name='buyerCity'
                                  label='City'
                                  placeholder='Enter city'
                                  value={rest?.values?.buyerCity}
                                  onChange={rest.handleChange}
                                  error={
                                    rest.touched.buyerCity &&
                                    Boolean(rest.errors.buyerCity)
                                  }
                                  helperText={
                                    rest.touched.buyerCity &&
                                    rest.errors.buyerCity
                                  }
                                  marginBottom='16px'
                                />
                              </Grid>
                              <Grid item xs={12} sm={4} />
                              <Grid item xs={12} sm={4}>
                                <TextField
                                  id='buyerState'
                                  name='buyerState'
                                  label='State / Province'
                                  placeholder='Enter state / province'
                                  value={rest?.values?.buyerState}
                                  onChange={rest.handleChange}
                                  error={
                                    rest.touched.buyerState &&
                                    Boolean(rest.errors.buyerState)
                                  }
                                  helperText={
                                    rest.touched.buyerState &&
                                    rest.errors.buyerState
                                  }
                                  marginBottom='16px'
                                />
                              </Grid>
                            </>
                          ) : null}
                          <Grid item xs={12} sm={4}>
                            <TextField
                              type='text'
                              id='password'
                              name='password'
                              label='Password'
                              placeholder='Enter password'
                              value={rest?.values?.password}
                              onChange={rest.handleChange}
                              error={
                                rest.touched.password &&
                                Boolean(rest.errors.password)
                              }
                              helperText={
                                rest.touched.password && rest.errors.password
                              }
                              marginBottom='16px'
                            />
                          </Grid>
                          <Grid item xs={12} sm={4} />
                          <Grid item xs={12} sm={4}>
                            <TextField
                              type='text'
                              id='confirmPassword'
                              name='confirmPassword'
                              label='Confirm Password'
                              placeholder='Enter password'
                              value={rest?.values?.confirmPassword}
                              onChange={rest.handleChange}
                              error={
                                rest.touched.confirmPassword &&
                                Boolean(rest.errors.confirmPassword)
                              }
                              helperText={
                                rest.touched.confirmPassword &&
                                rest.errors.confirmPassword
                              }
                              marginBottom='16px'
                            />
                          </Grid>
                          {formType === 'ADVANCED_DETAILS' ? (
                            <>
                              <Grid item xs={12} sm={2}>
                                <TextField
                                  id='buyerPostCode'
                                  name='buyerPostCode'
                                  label='Zip / Postal Code'
                                  placeholder='Enter zip / postal code'
                                  value={rest?.values?.buyerPostCode}
                                  onChange={rest.handleChange}
                                  error={
                                    rest.touched.buyerPostCode &&
                                    Boolean(rest.errors.buyerPostCode)
                                  }
                                  helperText={
                                    rest.touched.buyerPostCode &&
                                    rest.errors.buyerPostCode
                                  }
                                  marginBottom='16px'
                                />
                              </Grid>
                              <Grid item xs={12} sm={2}>
                                <TextField
                                  required={!edit}
                                  id='buyerCountry'
                                  name='buyerCountry'
                                  label='Country'
                                  placeholder='Enter Country'
                                  value={rest?.values?.buyerCountry}
                                  onChange={rest.handleChange}
                                  error={
                                    rest.touched.buyerCountry &&
                                    Boolean(rest.errors.buyerCountry)
                                  }
                                  helperText={
                                    rest.touched.buyerCountry &&
                                    rest.errors.buyerCountry
                                  }
                                  marginBottom='16px'
                                />
                              </Grid>
                            </>
                          ) : null}
                        </Grid>
                      </Box>
                    )}
                  </>
                )}
                <button
                  id='submit-add-new-retailer'
                  type='submit'
                  style={{ display: 'none' }}
                >
                  submit add new retailer
                </button>
              </form>
            );
          }}
        </Formik>
      </Stack>
    </MyModal>
  );
}

AddNewRetailerModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  edit: PropTypes.bool,
  retailer: PropTypes.object,
  retailerId: PropTypes.string,
  prospect: PropTypes.bool,
  callbacks: PropTypes.func,
  declineBtnClick: PropTypes.func,
  notificationId: PropTypes.string,
  isEditAllow: PropTypes.bool,
  getRetailers: PropTypes.func,
  buyerData: PropTypes.object,
};

AddNewRetailerModal.defaultProps = {
  edit: false,
  retailerId: '',
  callbacks: () => {},
  declineBtnClick: () => {},
  notificationId: '',
  retailer: null,
  prospect: false,
  isEditAllow: true,
  buyerData: {},
};
