import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { customerSupportDataSelector } from 'redux/selectors';
import { useSearchParams } from 'react-router-dom';
import { defaultFilterState } from 'pages/customers/components/filter-options/constant';
import { useMatch } from 'react-router-dom';

export const useApplyFiltersModal = ({
  applyFilters,
  setOpen,
  setFilters,
  filters,
}) => {
  // Hook to get search parameters from the URL
  const [searchParams] = useSearchParams();

  // Retrieves support data from Redux store
  const supportData = useSelector(customerSupportDataSelector);
  // Match the "retailers" route
  const isRetailersPage = useMatch('/my-customer/retailers');
  // Match the "buyers" route
  const isBuyersPage = useMatch('/my-customer/buyers');

  // Initializes form values from URL search params or defaults
  const initializeForm = () => {
    let filtersFromParams = '';
    if (searchParams.get('isGroup')) {
      filtersFromParams = searchParams.get('gfilters');
    } else if (isRetailersPage)
      filtersFromParams = searchParams.get('retailersFilters');
    else filtersFromParams = searchParams.get('bfilters');
    const parsedFilters = filtersFromParams
      ? JSON.parse(filtersFromParams)
      : {};
    setFilters({ ...defaultFilterState, ...parsedFilters });
  };

  // Effect to initialize form when searchParams change
  useEffect(() => {
    initializeForm();
  }, [searchParams]);

  // Handles form submission for applying or resetting filters
  const handleSubmit = ({ action }) => {
    // Filters out empty values and constructs the applied filters object
    const appliedFilters = Object.entries(filters)
      .filter(([key, value]) =>
        Array.isArray(value) ? value.length > 0 : value
      )
      .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});

    // Calculates the total count of applied filters
    const filtersCount = Object.entries(appliedFilters)
      .filter((item) => item[0] !== 'showBuyers')
      .reduce((count, value) => {
        return count + (Array.isArray(value[1]) ? value[1].length : 1);
      }, 0);

    // Applies or resets filters based on the action
    if (action === 'apply') {
      applyFilters(JSON.stringify(appliedFilters), filtersCount);
    } else if (action === 'reset') {
      const { showBuyers } = appliedFilters;
      applyFilters(
        JSON.stringify(showBuyers ? { showBuyers: showBuyers } : ''),
        0
      );
      setFilters({
        ...defaultFilterState,
        ...(showBuyers && { showBuyers }),
      });
    }

    // Closes the modal after action
    setOpen(false);
  };

  // Returning filter state, setter function, submit handler, and support data
  return {
    ...filters,
    handleSubmit,
    supportData,
  };
};
