import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

export default function ResetFiltersButton({ handleSubmit }) {
  return (
    <Button
      variant='outlined'
      color='secondary'
      onClick={(e) => {
        e.preventDefault();
        handleSubmit({ action: 'reset' });
      }}
    >
      Reset Filter
    </Button>
  );
}

ResetFiltersButton.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};
