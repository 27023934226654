import React from 'react';
import PropTypes from 'prop-types';

export default function GalleryIcon({ fill, sx }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill={fill}
      style={sx}
    >
      <g>
        <g>
          <path
            d='M1.4,85.333H0v9.8a1.4,1.4,0,0,0,1.4,1.4h9.8v-1.4H1.4Z'
            transform='translate(0 -82.533)'
          />
          <path
            d='M95.133,0h-8.4a1.4,1.4,0,0,0-1.4,1.4V9.8a1.4,1.4,0,0,0,1.4,1.4h8.4a1.4,1.4,0,0,0,1.4-1.4V1.4A1.4,1.4,0,0,0,95.133,0Zm-8.4,9.8,2.1-2.8,1.421,1.9,2.079-2.6,2.8,3.5Z'
            transform='translate(-82.533)'
          />
        </g>
      </g>
    </svg>
  );
}

GalleryIcon.propTypes = {
  fill: PropTypes.string,
  sx: PropTypes.object,
};

GalleryIcon.defaultProps = {
  fill: 'var(--primary-icon-color)',
  sx: {},
};
