import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Box, InputLabel, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import MuiTextField from '@mui/material/TextField';

const TimezoneAutoSearch = ({
  label,
  placeholder,
  sx,
  setValue,
  value,
  disabled,
  readOnly,
  ampm,
  id,
  name,
  format,
  error,
  helperText,
  views,
  required,
  showPopupActions,
  setFormikValue,
  options,
  ...props
}) => {
  return (
    <Box sx={{ ...sx }}>
      {label ? <InputLabel required={required}>{label}</InputLabel> : null}
      <Autocomplete
        disablePortal
        id={id}
        options={options}
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          setFormikValue(id, newValue);
        }}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <MuiTextField
            {...params}
            placeholder={placeholder}
            sx={[
              {
                '& .MuiOutlinedInput-root': {
                  fontWeight: '300', // Change font weight for outlined input
                },
                '& .MuiOutlinedInput-input::placeholder': {
                  fontSize: '12px', // Change font size for placeholder text
                },
              },
              error
                ? {
                    '& .MuiInputBase-root ': {
                      fontWeight: '500', // Change font weight for outlined input
                      border: '1px solid var(--error-color)',
                    },
                  }
                : null,
            ]}
          />
        )}
        renderOption={(props, option, { selected }) => {
          return (
            <Box
              {...props}
              display='flex'
              alignItems='center'
              component='div'
              sx={{
                width: '100%',
                padding: 1,
                boxSizing: 'border-box',
                fontWeight: '500',
              }}
            >
              <Box flexGrow={1}>{option?.label}</Box>
            </Box>
          );
        }}
      />
      {error ? (
        <Typography variant='body2' sx={{ color: 'var(--error-color)' }}>
          {helperText}
        </Typography>
      ) : null}
    </Box>
  );
};

TimezoneAutoSearch.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  sx: PropTypes.object,
  setValue: PropTypes.func,
  setFormikValue: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  ampm: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  format: PropTypes.string,
  error: PropTypes.bool,
  showPopupActions: PropTypes.bool,
  views: PropTypes.arrayOf(PropTypes.string),
  props: PropTypes.object,
  options: PropTypes.array,
  helperText: PropTypes.string,
};

TimezoneAutoSearch.defaultProps = {
  placeholder: 'Select Timezone',
  label: 'Timezone',
  sx: {},
  value: '',
  setValue: () => {},
  disabled: false,
  readOnly: false,
  ampm: false,
  showPopupActions: false,
  id: '',
  name: '',
  error: false,
  format: 'HH:mm:ss',
  views: ['hours', 'minutes', 'seconds'],
  required: false,
  options: [],
  helperText: 'Timezone is required',
};

export default TimezoneAutoSearch;
