import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { actionLoaderSelector } from 'redux/selectors';

import {
  getNotificationsData,
  viewdAllNotification,
  dismissAllNotification,
  markViewedNotification,
  checkNewNotificationExist,
} from '../actions';
import { NOTIFICATION_REQUEST } from '../constants';
import { notificationReferenceTypes } from 'constants/notification';
import { checkNewNotificationExistSelector } from '../selectors';

export const useNotifications = ({ setOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
  const [newNotificaitonAvailable, setNewNotificationAvailable] =
    useState(false);
  const [notifications, setNotifications] = useState([]);
  const [showMarkAllAsRead, setShowMarkAllAsRead] = useState(false);
  const [showDismissAll, setShowDismissAll] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState();
  const [showEmailStatusModal, setShowEmailStatusModal] = useState(false);
  const [notificationId, setNotificationId] = useState('');

  const notificationsLoading = useSelector(
    actionLoaderSelector(NOTIFICATION_REQUEST)
  );
  const checkNewNotificationsData = useSelector(
    checkNewNotificationExistSelector
  );
  let notificationCheckInterval;

  useEffect(() => {
    if (notificationCheckInterval) clearInterval(notificationCheckInterval);
    checkNewNotificationStatus();
    //fetchNotifications(); // It should call only when user click on bell icon
    // check for new notificaiton every 20 seconds
    notificationCheckInterval = setInterval(async () => {
      checkNewNotificationStatus();
    }, 20000);
  }, []);

  useEffect(() => {
    if (notificationAnchorEl || setOpen) {
      fetchNotifications();
      setNewNotificationAvailable(false);
    }
  }, [notificationAnchorEl, setOpen]);

  // checks if new notificaiton exist
  const checkNewNotificationStatus = async () => {
    if (!newNotificaitonAvailable) {
      const { status, is_new_notification, is_maintenance_mode } =
        await dispatch(checkNewNotificationExist());
      if (is_maintenance_mode) window.location.reload(); // reload the page and client info api will help to navigate to Maintainance mode page
      if (status) {
        setNewNotificationAvailable(is_new_notification);
      }
    }
  };

  // get the notificaiton list
  const fetchNotifications = async () => {
    const { status, data } = await dispatch(getNotificationsData());
    if (status) {
      setNotifications(data);
      const isReadAvailable = data.find(
        (notification) => notification.read == '0'
      );
      const isDismissAll = data.find(
        (notification) => notification.is_action == '0'
      );
      setShowMarkAllAsRead(!!isReadAvailable);
      setShowDismissAll(!!isDismissAll);
    }
  };

  // marks all the notification as read
  const handleMarkAllAsReadClick = async () => {
    const data = await dispatch(viewdAllNotification());
    if (data) {
      fetchNotifications();
    }
  };

  // dismiss all unactionable notification
  const handleDismissAllClick = async () => {
    const data = await dispatch(dismissAllNotification());
    if (data) {
      fetchNotifications();
    }
  };

  // open notification list
  const openNotification = (event) => {
    setNotificationAnchorEl(event.currentTarget);
  };

  // close notification list
  const closeNotification = () => {
    setNotificationAnchorEl(null);
    if (setOpen) {
      setOpen(false);
    }
  };

  // mark the selected notification as read
  const markNotificationAsRead = async (id) => {
    const isViewed = await dispatch(markViewedNotification(id));
    if (isViewed) {
      const notificaitons = notifications.map((notification) => {
        if (notification.id === id) {
          notification.read = 1;
        }
        return notification;
      });
      setNotifications(notificaitons);
    }
  };

  // handle notification click
  const handleNotificationClick = (notification) => {
    const {
      id,
      is_action,
      is_redirect,
      redirect_url,
      type,
      reference_type_id,
    } = notification;
    markNotificationAsRead(id);

    if (reference_type_id === '37') {
      setShowEmailStatusModal(true);
      closeNotification();
    }
    if (is_redirect == '1' && redirect_url) {
      const url = new URL(redirect_url);
      closeNotification();

      // for suggestion we need entire href
      let navigationLink = url?.pathname;

      if (type == 'SUGGESTION_FROM_SALES_REP_WITH_BUYER_WITH_SALES_REP') {
        navigationLink = navigationLink + url?.search;
      }
      if (
        reference_type_id ==
        notificationReferenceTypes.SUGGESTION_PDF_ARCHIVE_NOTIFICATION
      ) {
        window.open(redirect_url, '_blank');
        return;
      }
      // adding setTimeout to make this async to close the modal first
      return setTimeout(() => navigate(navigationLink), 0);
    }
    if (is_action == '0') {
      return;
    }
    setSelectedNotification(notification);
  };

  const requestActionCallback = () => {
    setSelectedNotification();
  };

  return {
    notificationAnchorEl,
    openNotification,
    closeNotification,
    notifications,
    notificationsLoading,
    handleMarkAllAsReadClick,
    handleDismissAllClick,
    fetchNotifications,
    handleNotificationClick,
    selectedNotification,
    showDismissAll,
    showMarkAllAsRead,
    newNotificaitonAvailable,
    requestActionCallback,
    showEmailStatusModal,
    setShowEmailStatusModal,
    notificationId,
    setNotificationId,
    checkNewNotificationsData,
    dispatch,
  };
};
