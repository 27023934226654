import { React, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMatch } from 'react-router-dom';
import { loggedInUserSelector } from 'redux/selectors';

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { CircularProgress } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import LogoutIconM from 'assets/icons/icons-for-mobile/logout-icon-m';
import HomeIconM from 'assets/icons/icons-for-mobile/home-icon-m';
import DashboardIconM from 'assets/icons/icons-for-mobile/dashboard-icon-m';
import MyOrdersIconM from 'assets/icons/icons-for-mobile/my-orders-icon-m';
import MyCustomersIconM from 'assets/icons/icons-for-mobile/my-customers-icon-m';
import MyAttacheIconM from 'assets/icons/icons-for-mobile/my-attache-icon-m';
import MyContactsIconM from 'assets/icons/icons-for-mobile/my-contacts-icon-m';
import BackArrowIcon from 'assets/icons/back-arrow-icon';

import Share from 'components/share/share';
import CreateOverrideOrderModal from 'components/modals/create-override-order/create-override-order-modal';
import { PrivateScreen } from 'routes/screen-names';
import OtherAccounts from './other-accounts';
import { logOut } from 'utils/auth';
import { useHeader1 } from './hooks';

const AccordianMenu = ({
  redirectTo,
  id,
  name,
  subOption,
  setMenuValue,
  setShowSendInviteEmail,
  setShowOverrideOrderModal,
  hasSubOption,
}) => {
  const navigate = useNavigate();

  if (id == 'lnk-home-0' || id == 'lnk-dashboard-0' || !hasSubOption) {
    return (
      <Box
        sx={{
          height: '52px',
          backgroundColor: '#181818',
          padding: '0px 16px 0px 16px',
        }}
      >
        <Stack direction='row' spacing={1} alignItems='center' height='100%'>
          {id == 'lnk-home-0' ? (
            <HomeIconM stroke='#ffff' />
          ) : id == 'lnk-dashboard-0' ? (
            <DashboardIconM />
          ) : (
            ''
          )}
          <Button
            variant='text'
            onClick={() => {
              navigate(redirectTo);
              setMenuValue('');
            }}
            sx={{
              color: '#FFFFFF',
              fontSIze: '14px',
              width: 'fit-content',
              paddingLeft: '0px',
              backgroundColor: '#181818',
              fontWeight: '400',
            }}
          >
            <Typography variant='subtitle1M' sx={{ color: '#FFFFFF' }}>
              {name}
            </Typography>
          </Button>
        </Stack>
      </Box>
    );
  }
  return (
    <Accordion elevation={0} disableGutters sx={{ backgroundColor: '#181818' }}>
      <AccordionSummary
        sx={{ height: '52px', backgroundColor: '#181818' }}
        expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
      >
        <Stack direction='row' spacing={1} alignItems='center'>
          {id == 'lnk-my-orders-0' ? (
            <MyOrdersIconM />
          ) : id == 'lnk-my-customers-0' ? (
            <MyCustomersIconM />
          ) : id == 'lnk-my-attache-0' ? (
            <MyAttacheIconM />
          ) : id == 'lnk-contact-0' ? (
            <MyContactsIconM />
          ) : null}
          <Typography variant='subtitle1M' sx={{ color: '#FFFFFF' }}>
            {name}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          height: '100%',
          width: '100%',
          backgroundColor: '#262626',
          borderRadius: '5px',
        }}
      >
        <Stack>
          {Array.isArray(subOption) &&
            subOption?.map((option) => {
              if (option.isSection) {
                return (
                  Array.isArray(option.section.subSection) &&
                  option.section.subSection.map((section, i) => {
                    return i == 0 ? (
                      <>
                        <Typography
                          variant='subtitle1M'
                          sx={{
                            color: '#FFFFFF',
                            fontWeight: '900',
                            padding: '5px 0px 5px 0px',
                            opacity: '0.9',
                          }}
                        >
                          {option.section.title}
                        </Typography>
                        <Button
                          key={option.id}
                          variant='text'
                          onClick={() => {
                            navigate(section?.redirectTo);
                            setMenuValue('');
                          }}
                          sx={{
                            width: 'fit-content',
                            color: '#ffff',
                            fontSize: '14px',
                            opacity: '0.9',
                            fontWeight: '400',
                          }}
                        >
                          {section.name}
                        </Button>
                      </>
                    ) : (
                      <Button
                        key={option.id}
                        variant='text'
                        onClick={() => {
                          navigate(section?.redirectTo);
                          setMenuValue('');
                        }}
                        sx={{
                          width: 'fit-content',
                          color: '#ffff',
                          fontSize: '14px',
                          opacity: '0.9',
                          fontWeight: '400',
                        }}
                      >
                        {section.name}
                      </Button>
                    );
                  })
                );
              } else {
                return (
                  <Button
                    key={option.id}
                    variant='text'
                    sx={{
                      width: 'fit-content',
                      color: '#ffff',
                      fontSize: '14px',
                      opacity: '0.9',
                      fontWeight: '400',
                    }}
                    onClick={() => {
                      if (option.id === 'lnk-my-invites-0') {
                        setShowSendInviteEmail(true);
                      } else if (option.id === 'lnk-override-order-0') {
                        setShowOverrideOrderModal(true);
                      } else {
                        navigate(option?.redirectTo);
                        setMenuValue('');
                      }
                    }}
                  >
                    {option.name}
                  </Button>
                );
              }
            })}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
AccordianMenu.propTypes = {
  redirectTo: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  hasSubOption: PropTypes.bool,
  subOption: PropTypes.array,
  setMenuValue: PropTypes.func,
  setShowSendInviteEmail: PropTypes.func,
  setShowOverrideOrderModal: PropTypes.func,
};

AccordianMenu.defaultProps = {
  redirectTo: '',
  id: '',
  name: '',
  hasSubOption: '',
  subOption: '',
  setMenuValue: () => {},
  setShowSendInviteEmail: () => {},
  setShowOverrideOrderModal: () => {},
};
const Header1M = ({ setMenuValue }) => {
  //flag to check if the app is on a marketplace page
  const isMarketplace = useMatch(`/marketplace/*`);
  const { headerMenu, onClickLinkedBuyerAccount, isOverLayLoading } =
    useHeader1({
      isMarketplace,
    });
  const [showSendInviteEmail, setShowSendInviteEmail] = useState(false);
  const [showOverrideOrderModal, setShowOverrideOrderModal] = useState(false);
  const [showOtherAccounts, setShowOtherAccounts] = useState(false);
  const user = useSelector(loggedInUserSelector);
  const theme = useTheme();

  const navigate = useNavigate();
  return (
    <Box
      sx={{
        backgroundColor: '#000000',
        width: '100%',
        height: '100vh',
        position: 'fixed',
        top: '0px',
        left: '0px',
        zIndex: 999,
        color: 'white',
      }}
    >
      {isOverLayLoading ? (
        <Box
          width='100vw'
          height='100vh'
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 9999,
            opacity: 0.4,
            backgroundColor: '#000',
          }}
        >
          <CircularProgress />
        </Box>
      ) : null}
      {showSendInviteEmail ? (
        <Share
          open={showSendInviteEmail}
          setOpen={setShowSendInviteEmail}
          sendinviteEmail={true}
        />
      ) : null}
      {showOverrideOrderModal ? (
        <CreateOverrideOrderModal
          open={showOverrideOrderModal}
          setOpen={(bool) => {
            setShowOverrideOrderModal(bool);
            if (!bool) setMenuValue('');
          }}
        />
      ) : null}
      <Accordion
        disableGutters
        elevation={0}
        expanded={true}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          backgroundColor: '#181818',
          opacity: '100%',
        }}
      >
        <AccordionSummary
          sx={{ height: '92px', backgroundColor: '#000000' }}
          expandIcon={
            showOtherAccounts ? null : (
              <ExpandMoreIcon sx={{ color: 'white' }} />
            )
          }
        >
          {showOtherAccounts ? (
            <Stack
              direction='row'
              spacing={2}
              sx={{ alignItems: 'center' }}
              onClick={() => setShowOtherAccounts(false)}
            >
              <BackArrowIcon fill='#FFFFFF' />
              <Typography
                variant='h2'
                sx={{ color: '#FFFFFF', letterSpacing: '0.4pt' }}
              >
                Select Profile
              </Typography>
            </Stack>
          ) : (
            <Stack direction='row' spacing={2}>
              {user.profile_pic ? (
                <Avatar
                  src={user.profile_pic}
                  sx={{
                    width: '54px',
                    height: '54px',
                  }}
                />
              ) : (
                <Avatar
                  sx={{
                    width: '54px',
                    height: '54px',
                    padding: '15px',
                  }}
                >
                  {user.name
                    ? user.name
                        .split(' ')
                        .map((name) => name.charAt(0).toUpperCase())
                        .join('')
                    : null}
                </Avatar>
              )}
              <Stack
                spacing={0.5}
                sx={{
                  justifyContent: 'center',
                  display: 'flex',
                }}
              >
                <Typography
                  variant='h2'
                  sx={{ color: '#FFFFFF', letterSpacing: '0.42pt' }}
                >
                  {user.name}
                </Typography>
                {user?.hasOtherAccountsLinked ? (
                  <Typography
                    variant='subtitle1M'
                    onClick={() => setShowOtherAccounts(!showOtherAccounts)}
                    sx={{
                      color: 'primary.main',
                      width: 'fit-content',
                    }}
                  >
                    Switch Profile
                  </Typography>
                ) : null}
              </Stack>
            </Stack>
          )}
        </AccordionSummary>
        {showOtherAccounts ? (
          <OtherAccounts
            headerMenu={headerMenu}
            onClickLinkedBuyerAccount={onClickLinkedBuyerAccount}
          />
        ) : (
          <AccordionDetails
            sx={{
              padding: '10px 0px 0px 0px',
              flexGrow: 1,
              height: '90vh',
              overflow: 'scroll',
              paddingBottom: '70px',
            }}
          >
            {Object.keys(headerMenu).length > 0 ? (
              <Stack
                sx={{
                  flexGrow: 1,
                  backgroundColor: '#181818',
                  padding: '0px 20px 0px 10px',
                }}
              >
                {headerMenu?.leftHeaderMenu?.map((item, i) => (
                  <AccordianMenu
                    key={i}
                    id={item.id}
                    name={item?.name?.toUpperCase()}
                    redirectTo={item.redirectTo}
                    hasSubOption={item.hasSubOption}
                    subOption={item.subOption}
                    setMenuValue={setMenuValue}
                    setShowSendInviteEmail={setShowSendInviteEmail}
                    setShowOverrideOrderModal={setShowOverrideOrderModal}
                  />
                ))}
                <Box>
                  <Box
                    sx={{
                      height: '52px',
                      display: 'flex',
                      alignItems: 'center',
                      padding: '0px 16px',
                    }}
                  >
                    <Typography
                      variant='subtitle1M'
                      sx={{
                        color: '#FFFFFF',
                        fontSIze: '14px',
                        width: 'fit-content',
                        paddingLeft: '0px',
                        backgroundColor: '#181818',
                        fontWeight: '400',
                      }}
                    >
                      QUICK ACTIONS
                    </Typography>
                  </Box>

                  <Stack
                    sx={{
                      height: '100%',
                      width: '100%',
                      backgroundColor: '#262626',
                      borderRadius: '5px',
                      padding: '8px 16px',
                    }}
                  >
                    {headerMenu?.rightHeaderMenu?.map((menuItem) => {
                      if (menuItem?.id == 'lft-lnk-my-account-0') {
                        return menuItem?.subOption?.map((subMenu) => {
                          if (subMenu?.id == 'lft-lnk-my-account-4') {
                            return (
                              <Button
                                key={subMenu.id}
                                variant='text'
                                color='primary'
                                sx={{
                                  padding: '8px',
                                  height: 'fit-content',
                                  width: 'fit-content',
                                }}
                                onClick={() => {
                                  logOut();
                                }}
                              >
                                <Stack
                                  direction='row'
                                  spacing={1}
                                  sx={{
                                    height: '100%',
                                    width: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  <LogoutIconM
                                    stroke={`${theme.palette.primary.main}`}
                                  />
                                  <Typography
                                    variant='subtitle1M'
                                    sx={{ color: 'primary.main' }}
                                  >
                                    Sign Out
                                  </Typography>
                                </Stack>
                              </Button>
                            );
                          } else {
                            return (
                              <Button
                                key={subMenu.id}
                                variant='text'
                                onClick={() => {
                                  if (subMenu?.id == 'lft-lnk-my-account-2') {
                                    window.open(subMenu?.redirectTo, '_blank');
                                  } else {
                                    navigate(subMenu?.redirectTo);
                                  }
                                  setMenuValue('');
                                }}
                                sx={{
                                  width: 'fit-content',
                                  color: '#ffff',
                                  fontSize: '14px',
                                  opacity: '0.9',
                                  fontWeight: '400',
                                }}
                              >
                                {/* {section.name} */}
                                {subMenu?.name}
                              </Button>
                            );
                          }
                        });
                      }
                      if (menuItem?.id == 'lft-lnk-help-0') {
                        return (
                          <Button
                            key={menuItem?.id}
                            variant='text'
                            onClick={() => {
                              navigate(PrivateScreen?.help);
                              setMenuValue('');
                            }}
                            sx={{
                              width: 'fit-content',
                              color: '#ffff',
                              fontSize: '14px',
                              opacity: '0.9',
                              fontWeight: '400',
                            }}
                          >
                            Help Center
                          </Button>
                        );
                      }
                    })}
                  </Stack>
                </Box>
              </Stack>
            ) : (
              <Box
                sx={{
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </AccordionDetails>
        )}
      </Accordion>
    </Box>
  );
};
Header1M.propTypes = {
  setMenuValue: PropTypes.func,
};

Header1M.defaultProps = {
  setMenuValue: () => {},
};
export default Header1M;
