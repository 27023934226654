import { useEffect, useState } from 'react';
import { api } from '../../api';
import { useSelector } from 'react-redux';
import { selectCustomerDetailSelector } from 'redux/selectors';
import { loggedInUserSelector } from 'redux/selectors';

export default function useShareDraftEmail({
  setOpen,
  open,
  worksheetId,
  worksheetCollectionId,
  suggestionId,
  catalogLink,
  pdfLink,
  suggestionPdf,
  draftLink,
  catalogLinkEmail,
  shareLinkName,
  shareCatalogPdf,
  sendinviteEmail,
}) {
  // form state
  const [selectedBuyers, setSelectedBuyers] = useState([]);
  const [selectedAssociates, setSelectedAssociates] = useState([]);
  const [subject, setSubject] = useState(shareLinkName ?? '');
  const [editorValue, setEditorValue] = useState([]);
  const [message, setMessage] = useState('<p></p>');
  const [timeline, setTimeline] = useState(1);
  const [emailRequests, setEmailRequests] = useState([]);
  const [emailResponses, setEmailResponses] = useState([]);
  const [sendingEmails, setSendingEmails] = useState(false);
  const [emailsSent, setEmailsSent] = useState(0);
  const [sender, setSender] = useState({ label: 'Sales Rep', value: 'sr' });
  const [index, setIndex] = useState(0);
  const [showInventory, setShowInventory] = useState(false);
  const client = useSelector(selectCustomerDetailSelector);
  const user = useSelector(loggedInUserSelector);
  //state for signature and sender for sendInvite email
  const [selectedSender, setSelectedSender] = useState('');
  const [selectedSignature, setSelectedSignature] = useState('');
  const [subjectEmptyError, setSubjectEmptyError] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [messageEmptyError, setMessageEmptyError] = useState(false);
  // timeline state

  function advanceTimeline() {
    if (timeline < 4) setTimeline(timeline + 1);
  }

  function handleOpen() {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  };

  async function getBuyers({ searchTerm, salesRepId, alphaSort }) {
    const res = await api.get(
      `/draftBuyerList?worksheetId=123&search=${searchTerm}&salesRepId=${salesRepId}&alphasort=${alphaSort}`
    );
  }
  async function uploadImage(formData) {
    const res = await api.post('/uploadAndDeleteFile', formData);
    if (res.status) {
      alert('file uploaded successfuly');
    }
  }

  async function submit() {
    //temp array to store email request data
    var arr = [];

    // add buyers to emailRequests
    selectedBuyers?.forEach((buyer) => {
      arr.push({
        recipientId: buyer?.buyer_id,
        recipientName: buyer?.aname,
        recipientEmail: buyer?.email,
        status: 'sending',
        type: 'buyer',
      });
    });

    //add sales reps to emailRequests
    selectedAssociates?.forEach((salesRep) => {
      arr.push({
        recipientId: salesRep?.id,
        recipientName: salesRep?.name,
        recipientEmail: salesRep?.email,
        status: 'sending',
        type: 'salesRep',
      });
    });

    setEmailRequests(arr);
  }

  useEffect(() => {
    sendEmail(emailRequests[index]).then((res) => {
      let emailRes = emailRequests[index];
      emailRes.status = res.data.status ? 'sent' : 'failed';
      setEmailResponses([...emailResponses, emailRes]);
      if (res.data.status) setEmailsSent(emailsSent + 1);
      if (index < emailRequests.length) {
        setIndex(index + 1);
      }
    });
  }, [emailRequests, index]);

  async function sendEmail(request) {
    if (emailRequests?.length > 0) {
      if (suggestionPdf) {
        let res = api.post(`/sendSuggestionPdfEmail`, {
          copy_reps: request.type === 'salesRep' ? [request.recipientId] : '',
          buyer_id: request.type === 'buyer' ? request.recipientId : '',
          group_ids: request.type === 'group' ? request.recipientId : '',
          message: message,
          subject: subject,
          signature: `${user.name}\n${user.company}\n\n${user.email}`,
          linesheet_id: suggestionId,
          catalog_link: catalogLink,
          pdf_link: pdfLink,
          sender: sender.value,
        });
        return res;
      }

      if (draftLink) {
        let res = api.post(`/sendSharedDraftLink`, {
          copy_reps: request?.type === 'salesRep' ? [request?.recipientId] : '',
          buyer_id: request?.type === 'buyer' ? request?.recipientId : '',
          group_ids: request?.type === 'group' ? request?.recipientId : '',
          worksheet_id: worksheetId,
          worksheet_collection_id: worksheetCollectionId,
          show_inventory: showInventory,
          message: message,
        });
        return res;
      }

      if (catalogLinkEmail) {
        let res = api.post(`/sendCatalogLinkEmail`, {
          copyReps: request.type === 'salesRep' ? request.recipientId : '',
          buyerId: request.type === 'buyer' ? request.recipientId : '',
          groupIds: request.type === 'group' ? request.recipientId : '',
          copySelf: 'y',
          message: message,
          subject: subject,
          linkName: shareLinkName,
          linkUrl: catalogLink,
        });
        return res;
      }
      if (shareCatalogPdf) {
        let res = api.post(`/sendCatalogPdfEmail`, {
          subject: subject,
          message: message,
          catalogLink: window.location.href,
          catalogPdfLink: pdfLink,
          copySelf: 'y',
          copyReps: request.type === 'salesRep' ? request.recipientId : '',
          groupIds: request.type === 'group' ? request.recipientId : '',
          buyerId: request.type === 'buyer' ? request.recipientId : '',
        });
        return res;
      }
      if (sendinviteEmail) {
        let res = await api.post(`/sendInviteEmail`, {
          buyer_id: request.type === 'buyer' ? request.recipientId : '',
          sales_rep_id: selectedSignature.value,
          from_sender: selectedSender.value,
        });
        return res;
      }
    }
  }

  useEffect(() => {
    getBuyers({ draftId: 9339, searchTerm: '', salesRepId: 0, alphaSort: '' });
  }, []);

  //reset state when opening/ closing modal
  useEffect(() => {
    if (!open) {
      setSelectedBuyers([]);
      setSelectedAssociates([]);
      setSubject(null);
      setEditorValue([]);
      setMessage('<p></p>');
      setTimeline(1);
      setEmailRequests([]);
      setEmailResponses([]);
      setSendingEmails(false);
      setEmailsSent(0);
      setSender({ label: 'Sales Rep', value: 'sr' });
      setIndex(0);
      setShowInventory(false);
    }
  }, [open]);

  return {
    selectedBuyers,
    setSelectedBuyers,
    selectedAssociates,
    setSelectedAssociates,
    subject,
    setSubject,
    editorValue,
    setEditorValue,
    message,
    setMessage,
    timeline,
    setTimeline,
    advanceTimeline,
    handleOpen,
    handleClose,
    submit,
    sendingEmails,
    setSendingEmails,
    emailRequests,
    client,
    sender,
    setSender,
    emailsSent,
    emailResponses,
    showInventory,
    setShowInventory,
    uploadImage,
    selectedSender,
    setSelectedSender,
    selectedSignature,
    setSelectedSignature,
    subjectEmptyError,
    setSubjectEmptyError,
    isSelectAll,
    setIsSelectAll,
    messageEmptyError,
    setMessageEmptyError,
  };
}
