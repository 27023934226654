export const style = {
  popoverBox: { width: '460px', padding: '11px 19px 12px 19px' },
  popoverContainer: {
    borderBottom: '1px solid var(--secondary-border-color)',
    paddingBottom: '12px',
  },
  popoverBatchProcess: { color: '#222222', fontWeight: 900, fontSize: '15px' },
  gridContainer: { padding: '10px 0px 10px 0px' },
  popoverItemBox1: {
    width: '12px',
    height: '12px',
    backgroundColor: '#de4c69',
  },
  popoverProcessedOrders: {
    color: 'rgba(77, 70, 99, 0.8)',
    fontWeight: '500',
  },
  popoverItemBox2: {
    width: '12px',
    height: '12px',
    backgroundColor: '#ffc457',
  },
  popoverERP: {
    color: 'rgba(77, 70, 99, 0.8)',
    fontWeight: '500',
  },
  popoverExceptionReport: {
    fontSize: '15px',
    fontWeight: '500',
    color: 'var(--primary-font-color)',
  },
  downloadReportButton: {
    width: '159px',
  },
  actions: {
    height: '24px',
    width: '100px',
    fontSize: '13px',
  },
  gridItem: {
    padding: '0px',
    height: '100%',
    width: 'fit-content',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
