import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';
import { actionLoaderSelector } from '../../../redux/selectors';
import { urlRegex } from '../../../utils/helper';
import { showSnackbar } from '../../../components/snackbar/actions';
import { snackbarTypes } from '../../../constants';
import { WELCOME_BAR_REQUEST } from 'pages/app/actions';
import { welcomeBarDataSelector } from '../../../redux/selectors';
import { responsiveBreakpoints } from 'utils/utils';
import { getWelcomeBarConfig } from 'pages/app/actions';

export const useWelcomeBar = () => {
  const dispatch = useDispatch();
  const welcomeBarConfigLoading = useSelector(
    actionLoaderSelector(WELCOME_BAR_REQUEST)
  );
  const isOrders = useMatch('/order-payment');
  const { mobile, tablet } = responsiveBreakpoints();
  const welcomeBarConfig = useSelector(welcomeBarDataSelector);
  const [hideWelcomeBar, setHideWelcomeBar] = useState(false);

  useEffect(() => {
    if ((mobile || tablet) && isOrders) {
      setHideWelcomeBar(true);
    }
  }, [isOrders, mobile]);

  // useEffect(() => {
  //   if (welcomeBarData) {
  //     setWelcomeBarConfig(welcomeBarData);
  //   }
  // }, [welcomeBarData]);

  useEffect(() => {
    const fetchWelcomeBar = async () => {
      await dispatch(getWelcomeBarConfig());
    };
    fetchWelcomeBar();
  }, []);

  const handleAppBarBtnClick = (url, isNewWindow) => {
    const newWindow = isNewWindow ? '_blank' : '_self';
    if (urlRegex.test(url)) {
      window.open(url, newWindow);
    } else {
      dispatch(
        showSnackbar({ message: 'Invalid URL', type: snackbarTypes.WARNING })
      );
    }
  };

  return {
    welcomeBarConfigLoading,
    welcomeBarConfig,
    handleAppBarBtnClick,
    hideWelcomeBar,
  };
};
