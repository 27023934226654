import {
  api,
  apiEndPoints,
  addActionLoader,
  removeActionLoader,
} from 'api/index';
import { showSnackbar } from 'components/snackbar/actions';
import { snackbarTypes } from 'constants';

const namespace = 'pages/suggestions/';

export const SET_API_ERROR = `${namespace}SET_API_ERROR`;
export const GET_RECEIVED_SUGGESTIONS = `${namespace}GET_RECEIVED_SUGGESTIONS`;
export const GET_SUGGESTION_LIST = `${namespace}GET_SUGGESTION_LIST`;
export const GET_SUGGESTION_DETAILS = `${namespace}GET_SUGGESTION_DETAILS`;
export const REMOVE_COLLECTION = `${namespace}REMOVE_COLLECTION`;
export const REMOVE_ITEM_FROM_SUGGESTION = `${namespace}REMOVE_ITEM_FROM_SUGGESTION`;
export const DUPLICATE_SUGGESTION = `${namespace}DUPLICATE_SUGGESTION`;
export const SUGGESTION_SORT_LIST = `${namespace}SUGGESTION_SORT_LIST`;
export const UPDATE_SUGGESTION = `${namespace}UPDATE_SUGGESTION`;
export const DEPT_COLL_FOR_SUGGESTION = `${namespace}DEPT_COLL_FOR_SUGGESTION`;
export const EXPORT_SUGGESTION_XLS = `${namespace}EXPORT_SUGGESTION_XLS`;
export const SUGGESTION_PDF_OPTIONS = `${namespace}SUGGESTION_PDF_OPTIONS`;
export const CREATE_SUGGESTION_PDF = `${namespace}CREATE_SUGGESTION_PDF`;
export const SAVE_SUGGESTION_VISIBILITY = `${namespace}SAVE_SUGGESTION_VISIBILITY`;
export const REMOVE_BANNER_IMAGE = `${namespace}REMOVE_BANNER_IMAGE`;

const setApiError = (error) => ({
  type: SET_API_ERROR,
  payload: error,
});

export const getSuggestions = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(GET_SUGGESTION_LIST));
    const response = await api.get(
      `${apiEndPoints.suggestionList}?page=${payload?.pageNumber ?? 1}`
    );
    const {
      data,
      data: { status = false },
    } = response;
    if (status) {
      return data;
    }
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(GET_SUGGESTION_LIST));
  }
};

export const getReceivedSuggestions = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(GET_RECEIVED_SUGGESTIONS));
    const response = await api.get(
      `${apiEndPoints.getSharedSuggestions}?page=${payload?.pageNumber ?? 1}`
    );
    const {
      data,
      data: { status = false },
    } = response;
    if (status) {
      return data;
    }
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(GET_RECEIVED_SUGGESTIONS));
  }
};

export const getSuggestionDetails = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(GET_SUGGESTION_DETAILS));
    const response = await api.get(
      `${apiEndPoints.suggestionDetails}?id=${payload?.id}&suggestionSort=${payload?.sortValue}&suggestionLinkFlow=1`
    );
    const {
      data,
      data: { status = false },
    } = response;
    if (status) {
      return data;
    }
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(GET_SUGGESTION_DETAILS));
  }
};

export const removeSuggestionDelivery = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(REMOVE_COLLECTION));
    const response = await api.delete(
      `${apiEndPoints.removeSuggestionDelivery}?suggestionId=${payload?.id}&productCollectionId=${payload?.collectionId}`
    );
    const {
      data,
      data: { status = false },
    } = response;
    if (status) {
      dispatch(
        showSnackbar({
          snackbarMessage:
            response?.data?.message ?? 'Delivery Removed successfully.',
          type: snackbarTypes.SUCCESS,
        })
      );
      return data;
    }
    dispatch(
      showSnackbar({
        snackbarMessage:
          response?.data?.message ??
          'There was an error in removing the delivery',
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(REMOVE_COLLECTION));
  }
};

export const removeItemFromSuggestion =
  (payload, queryClient) => async (dispatch) => {
    try {
      dispatch(addActionLoader(REMOVE_ITEM_FROM_SUGGESTION));
      const response = await api.delete(
        `${apiEndPoints.removeItemFromSuggestion}?suggestionId=${payload?.id}&productId=${payload?.productId}`
      );
      const {
        data,
        data: { status = false },
      } = response;
      if (status) {
        queryClient?.removeQueries(['getSuggestions']);
        return data;
      }
      dispatch(
        showSnackbar({
          snackbarMessage:
            response?.data?.message ??
            'There was an error in removing the item',
          type: snackbarTypes.ERROR,
        })
      );
      return false;
    } catch (error) {
      dispatch(setApiError(error));
      return false;
    } finally {
      dispatch(removeActionLoader(REMOVE_ITEM_FROM_SUGGESTION));
    }
  };

export const duplicateSuggestion = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(DUPLICATE_SUGGESTION));
    const response = await api.post(apiEndPoints.duplicateSuggestion, payload);
    const {
      data,
      data: { status = false, message = 'Suggestion Duplicated successfully' },
    } = response;
    if (status) {
      dispatch(
        showSnackbar({
          snackbarMessage: message ?? 'Delivery Removed successfully.',
          type: snackbarTypes.SUCCESS,
        })
      );
      return data;
    }
    dispatch(
      showSnackbar({
        snackbarMessage:
          message ?? 'An error occurred when duplicating this suggestion',
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(DUPLICATE_SUGGESTION));
  }
};

export const getSuggestionSortList = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(SUGGESTION_SORT_LIST));
    const response = await api.get(
      `${apiEndPoints.suggestionSortList}?linesheetId=${payload?.id}}`
    );
    const {
      data,
      // data: { status = false },
      status,
    } = response;
    if (status == '200') {
      return data;
    }
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(SUGGESTION_SORT_LIST));
  }
};

export const updateSuggestion = (payload, id) => async (dispatch) => {
  try {
    dispatch(addActionLoader(UPDATE_SUGGESTION));
    const response = await api.put(
      `${apiEndPoints.updateSuggestion}?id=${id}`,
      payload
    );
    const {
      data,
      data: { status = false },
    } = response;
    if (status) {
      dispatch(
        showSnackbar({
          snackbarMessage:
            response?.data?.message ?? 'Suggestion Updated successfully.',
          type: snackbarTypes.SUCCESS,
        })
      );
      return data;
    }
    dispatch(
      showSnackbar({
        snackbarMessage:
          response?.data?.message ??
          'There was an error in updating the suggestion',
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(UPDATE_SUGGESTION));
  }
};

export const getDeptCollForSuggestions = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(DEPT_COLL_FOR_SUGGESTION));
    const response = await api.get(
      `${apiEndPoints.deptCollForSuggestions}?linesheetId=${payload?.id}`
    );
    const {
      data,
      data: { status = false },
    } = response;
    if (status) {
      return data;
    }
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(DEPT_COLL_FOR_SUGGESTION));
  }
};

export const getExportSuggestionXls = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(EXPORT_SUGGESTION_XLS));
    const response = await api.get(
      `${apiEndPoints.exportSuggestionXls}?linesheetId=${payload?.id}&upsChecked=${payload?.upsChecked}&sortOption=${payload?.sortOption}&currency_id=${payload?.currencyId}`
    );
    const {
      data,
      data: { status = false, msg: message = '' },
    } = response;
    if (status) {
      dispatch(
        showSnackbar({
          snackbarMessage: message ?? 'Xls exported successfull',
          type: snackbarTypes.SUCCESS,
          snackbarAlign: {
            vertical: 'top',
            horizontal: 'right',
          },
        })
      );
      return data;
    }
    dispatch(
      showSnackbar({
        snackbarMessage: message ?? 'There was an error downloading the file',
        type: snackbarTypes.ERROR,
        snackbarAlign: {
          vertical: 'top',
          horizontal: 'right',
        },
      })
    );
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(EXPORT_SUGGESTION_XLS));
  }
};

export const getSuggestionPdfOptions = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(SUGGESTION_PDF_OPTIONS));
    const response = await api.get(apiEndPoints.suggestionPdfOptions);
    const { data } = response;
    if (data) {
      return data;
    }
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(`SUGGESTION_PDF_OPTIONS`));
  }
};

export const createSuggestionPdf = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(CREATE_SUGGESTION_PDF));
    const response = await api.post(apiEndPoints.createSuggestionPdf, payload);
    const {
      data,
      data: { status = false, msg: message },
    } = response;
    if (status) {
      dispatch(
        showSnackbar({
          snackbarMessage: message ?? 'PDF generated successfully',
          type: snackbarTypes.SUCCESS,
        })
      );
      return data;
    }
    dispatch(
      showSnackbar({
        snackbarMessage: message ?? 'An error occurred when generating the PDF',
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(CREATE_SUGGESTION_PDF));
  }
};

export const saveSuggestionVisibilty = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(SAVE_SUGGESTION_VISIBILITY));
    const response = await api.post(
      apiEndPoints?.saveSuggestionVisibilty,
      payload
    );
    const {
      data,
      data: { status = false, message },
    } = response;
    if (status) {
      dispatch(
        showSnackbar({
          snackbarMessage: message ?? 'Settings updated successfully',
          type: snackbarTypes.SUCCESS,
        })
      );
      return data;
    }
    dispatch(
      showSnackbar({
        snackbarMessage: message ?? 'There was an error updating the settings',
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(SAVE_SUGGESTION_VISIBILITY));
  }
};

export const removeBannerImage = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(REMOVE_BANNER_IMAGE));
    const response = await api.post(apiEndPoints?.removeBannerImage, payload);
    const {
      data,
      data: { status = false, message },
    } = response;
    if (status) {
      dispatch(
        showSnackbar({
          snackbarMessage: message ?? 'File removed successfully',
          type: snackbarTypes.SUCCESS,
          snackbarAlign: {
            vertical: 'top',
            horizontal: 'right',
          },
        })
      );
      return data;
    }
    dispatch(
      showSnackbar({
        snackbarMessage: message ?? 'There was an error removing the image',
        type: snackbarTypes.ERROR,
        snackbarAlign: {
          vertical: 'top',
          horizontal: 'right',
        },
      })
    );
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(REMOVE_BANNER_IMAGE));
  }
};
