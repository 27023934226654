import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MyModal from 'components/modals/modal';
import StatusChip from 'components/status-chip';
import Button from '@mui/material/Button';
import useSendingEmailRecipient from './sending-email-recipient-hook';

export default function SendingEmailRecipient({
  request,
  setEmailSentInfo,
  showInventory,
  selectedSender,
  selectedSignature,
  orderSharePdf,
  orderSharePdfInline,
}) {
  const { status } = useSendingEmailRecipient({
    request,
    setEmailSentInfo,
    showInventory,
    selectedSender,
    selectedSignature,
    orderSharePdf,
    orderSharePdfInline,
  });
  return (
    <Stack direction='row' alignItems='center' justifyContent='space-between'>
      <Typography variant='body1' sx={{ width: '70%' }}>
        <span style={{ fontWeight: '500' }}>{request.recipientName}</span>
        <span> - {request.recipientEmail}</span>
      </Typography>
      <StatusChip label={status} color='#22b5e6' />
    </Stack>
  );
}
SendingEmailRecipient.propTypes = {
  request: PropTypes.object.isRequired,
  setEmailSentInfo: PropTypes.func.isRequired,
  showInventory: PropTypes.bool.isRequired,
  selectedSender: PropTypes.string,
  selectedSignature: PropTypes.string,
  orderSharePdf: PropTypes.bool,
  orderSharePdfInline: PropTypes.bool,
};
SendingEmailRecipient.defaultProps = {
  selectedSender: '',
  selectedSignature: '',
  orderSharePdf: false,
  orderSharePdfInline: false,
};
