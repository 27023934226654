import { useState } from 'react';

export function useProductListItem({ is_draft_product }) {
  // cartFlag state
  const [cartFlag, setCartFlag] = useState(is_draft_product);
  // cartActionLoader State
  const [cartActionLoader, setCartActionLoader] = useState(false);
  return {
    cartFlag,
    setCartFlag,
    cartActionLoader,
    setCartActionLoader,
  };
}
