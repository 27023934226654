import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MyModal from 'components/modals/modal';
import StatusChip from 'components/status-chip';
import Button from '@mui/material/Button';

export default function SendingEmailsModal({
  open,
  setOpen,
  emailRequests,
  emailsSent,
  setOpenParentModal,
  setOpenBackfill,
  totalEmails,
}) {
  var emailsSentText = (
    <Typography
      sx={{
        width: '100%',
        height: '40px',
        backgroundColor: '#E0FFE2',
        textAlign: 'center',
        fontSize: '18px',
        color: '#008800',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px 5px',
      }}
    >
      Emails Sent: {emailsSent} / {totalEmails}
    </Typography>
  );
  const disclaimerText = (
    <Typography
      variant='body1'
      sx={{ marginRight: '20px', whiteSpace: 'nowrap' }}
    >
      Please do not close this modal until all emails are sent
    </Typography>
  );

  const close = (
    <Button
      variant='outlined'
      color='secondary'
      onClick={() => {
        setOpen(false);
        setOpenParentModal(false);
        setOpenBackfill(false);
      }}
    >
      Close
    </Button>
  );

  return (
    <MyModal
      open={open}
      setOpen={setOpen}
      size='large'
      title='Email Sending Status'
      sx={{ width: '780px', height: '50vh' }}
      actions={[disclaimerText, emailsSentText, close]}
    >
      <Stack spacing={3}>
        <Stack spacing={1.5}>
          {emailRequests.map((request, i) => (
            <Stack
              key={i}
              direction='row'
              alignItems='center'
              justifyContent='space-between'
            >
              <Typography variant='body1' sx={{ width: '50%' }}>
                <span style={{ fontWeight: '500' }}>
                  {request.recipientName}
                </span>
                <span> - {request.recipientEmail}</span>
              </Typography>
              <StatusChip label={request.status} color='#22b5e6' />
            </Stack>
          ))}
        </Stack>
      </Stack>
    </MyModal>
  );
}

SendingEmailsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  openParentModal: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setOpenParentModal: PropTypes.func.isRequired,
  emailRequests: PropTypes.array.isRequired,
  emailsSent: PropTypes.number.isRequired,
  setOpenBackfill: PropTypes.func,
  totalEmails: PropTypes.number,
};
SendingEmailsModal.defaultProps = {
  setOpenBackfill: () => {},
};
