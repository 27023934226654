import React from 'react';
import { useMatch } from 'react-router-dom';
import PropTypes from 'prop-types';

import Headers1 from './components/header1';
import Headers2 from './components/header2';
import DepartmentsBar from './department-bar/department-bar';
import ChangePassword from './change-password/change-password';
import { useLoginUserInfo, useLayout } from './header-hooks';
import Header1M from './components/header1-m';
import Header2M from './components/header2-m';
import { responsiveBreakpoints } from 'utils/utils';

export default function Header({ menuValue, setMenuValue }) {
  useLoginUserInfo();
  useLayout();

  //flag to check if the app is on a marketplace page
  const isMarketplace = useMatch(`/marketplace/*`);
  const { theme, mobile, tablet, laptop, desktop } = responsiveBreakpoints();

  return (
    <>
      {mobile || tablet ? (
        menuValue == 4 ? (
          <Header1M setMenuValue={setMenuValue} />
        ) : null
      ) : (
        <Headers1 />
      )}
      {mobile || tablet ? <Header2M /> : <Headers2 />}
      {isMarketplace || mobile || tablet ? null : <DepartmentsBar />}
      <ChangePassword />
    </>
  );
}

Header.propTypes = {
  menuValue: PropTypes.number,
  setMenuValue: PropTypes.func,
};
Header.defaultProps = {
  menuValue: 0,
  setMenuValue: () => {},
};
