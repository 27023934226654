import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  selectClientPublicInfoSelector,
  actionLoaderSelector,
} from 'redux/selectors';
import {
  newAccountRequest,
  NEW_ACCOUNT_REQUEST,
  TNC_REQUEST,
  tncRequest,
} from '../../actions';
import { PrivateScreen } from 'routes/screen-names';

export const useNewAccountForm = (handleReviewMessage) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const clientPublicInfo = useSelector(selectClientPublicInfoSelector);
  const isNewRequestLoading = useSelector(
    actionLoaderSelector(NEW_ACCOUNT_REQUEST)
  );
  const [activeStep, setActiveStep] = useState(1);
  const [isSameAddress, setIsSameAddress] = useState(false);
  const [apiErrorMessage, setErrorApiMessage] = useState();
  const [fieldsError, setFieldsError] = useState([]);
  const [tnc, setTnc] = useState('');
  const [isShowTnc, setIsShowTnc] = useState(false);
  const isTncLoading = useSelector(actionLoaderSelector(TNC_REQUEST));

  const isLastStep =
    activeStep === clientPublicInfo?.request_form_fields?.length;

  const handleSubmitBtnClick = async (values, actions) => {
    try {
      if (!isLastStep) {
        setActiveStep(activeStep + 1);
        actions.setTouched({});
      } else {
        const payload = {
          company_name: values.company_name,
          full_name: values.full_name,
          email: values.email,
          description: values.description,
          website: values.website,
          tax_id: values.tax_id,
          dba_name: values.dba_name,
          phone: values.phone,
          address: values.billing_address,
          address2: values.billing_address_2,
          city: values.billing_city,
          state: values.billing_state,
          zip: values.billing_zip,
          fax: values.fax,
          country: values.billing_country,
          s_address: isSameAddress
            ? values.billing_address
            : values.shipping_address,
          s_address2: isSameAddress
            ? values.billing_address_2
            : values.shipping_address_2,
          s_city: isSameAddress ? values.billing_city : values.shipping_city,
          s_state: isSameAddress ? values.billing_state : values.shipping_state,
          s_zip: isSameAddress ? values.billing_zip : values.shipping_zip,
          s_country: isSameAddress
            ? values.billing_country
            : values.shipping_country,
        };
        const { status, message, token, errors } = await dispatch(
          newAccountRequest(payload)
        );
        if (status) {
          if (token) {
            return navigate(PrivateScreen.home);
          } else {
            return handleReviewMessage(message, status);
          }
        } else {
          if (errors) {
            const message = errors.map((error) => {
              return Object.values(error);
            });
            setFieldsError(message);
          } else {
            setErrorApiMessage(message);
          }
        }
      }
      actions.setSubmitting(false);
    } catch (err) {
      //TODO: Handle error
    }
  };

  const handlePreviousBtnClick = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSameAddressChange = () => {
    setIsSameAddress(!isSameAddress);
  };

  const getInitialValues = (formFields) => {
    try {
      const initialValue = {};
      formFields.map((step) => {
        return step.fields?.forEach((field) => {
          if (field.is_address) {
            if (field.billing_address) {
              field.billing_address.forEach((field) => {
                initialValue[`billing_${field.name}`] = '';
              });
            }
            if (field.shipping_address) {
              field.billing_address.forEach((field) => {
                initialValue[`shipping_${field.name}`] = '';
              });
            }
          } else {
            initialValue[field.name] = '';
          }
        });
      });
      return initialValue;
    } catch (err) {
      // TODO: Handle errors
    }
  };

  const handleCloseError = () => {
    setFieldsError([]);
    setErrorApiMessage(null);
  };

  const handleTermsAndConditionClick = async () => {
    if (clientPublicInfo) {
      setIsShowTnc(true);
      const tnc = await dispatch(tncRequest(clientPublicInfo?.id));
      if (tnc) {
        setTnc(tnc);
      }
    }
  };

  return {
    handleSubmitBtnClick,
    handlePreviousBtnClick,
    handleSameAddressChange,
    isSameAddress,
    activeStep,
    isLastStep,
    isNewRequestLoading,
    apiErrorMessage,
    fieldsError,
    getInitialValues,
    clientPublicInfo,
    handleCloseError,
    isShowTnc,
    setIsShowTnc,
    tnc,
    handleTermsAndConditionClick,
    isTncLoading,
  };
};
