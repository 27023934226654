import React from 'react';
import PropTypes from 'prop-types';

export default function HeaderCartLargeIcon({ fill, sx }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='28'
      height='28'
      viewBox='0 0 28 28'
    >
      <g>
        <path
          fill='rgba(0,0,0,0.6)'
          d='M27.755 6.365a1.094 1.094 0 0 0-.849-.4H7.095l-.081-.746v-.024A5.984 5.984 0 0 0 1.094 0a1.094 1.094 0 0 0 0 2.188 3.791 3.791 0 0 1 3.747 3.277l1.3 11.944a3.286 3.286 0 0 0-1.93 2.99v.054a3.285 3.285 0 0 0 3.281 3.281h.445a3.227 3.227 0 1 0 6.11 0h4.718a3.227 3.227 0 1 0 3.055-2.188H7.492A1.1 1.1 0 0 1 6.4 20.453v-.027-.027A1.1 1.1 0 0 1 7.492 19.3h13.964a5.009 5.009 0 0 0 4.539-3.06 1.094 1.094 0 0 0-2-.879 2.86 2.86 0 0 1-2.536 1.752H8.309l-.976-8.965h18.228l-.535 2.566a1.094 1.094 0 1 0 2.141.447l.81-3.883a1.094 1.094 0 0 0-.222-.913zM21.82 23.734a1.039 1.039 0 1 1-1.039 1.039 1.04 1.04 0 0 1 1.039-1.039zm-10.828 0a1.039 1.039 0 1 1-1.039 1.039 1.04 1.04 0 0 1 1.039-1.039z'
        />
      </g>
    </svg>
  );
}

HeaderCartLargeIcon.propTypes = {
  fill: PropTypes.string,
  sx: PropTypes.object,
};

HeaderCartLargeIcon.defaultProps = {
  fill: 'var(--primary-icon-color)',
  sx: {},
};
