import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import BackArrowIcon from 'assets/icons/back-arrow-icon';
import CloseIcon from '@mui/icons-material/Close';
import { DepartmentDrawerMenu as styles } from '../styles';
import { useDepartmentDrawerMenu } from './department-drawer-menu-hooks';
import SelectedDepartmentDrawerMenus from './selected-department-drawer-menus';
import { useOutsideAlerter } from 'common-hooks/click-outside-handler';

const DepartmentDrawerMenu = ({
  departments,
  departmentsLoading,
  handleCloseMenus,
  handleDepartmentClick,
}) => {
  const {
    selectedDepartment,
    handleSelectDepartment,
    handleBackBtnClick,
    drawerRef,
  } = useDepartmentDrawerMenu();
  useOutsideAlerter(drawerRef, handleCloseMenus);

  return (
    <Box sx={styles.container} ref={drawerRef}>
      <Stack sx={styles.subContaienr}>
        <Stack sx={styles.drawerHead}>
          <IconButton onClick={() => handleBackBtnClick()}>
            <BackArrowIcon />
          </IconButton>
          <Button
            variant='text'
            onClick={() =>
              handleDepartmentClick(
                selectedDepartment ? selectedDepartment : departments[0]
              )
            }
          >
            <Typography variant='h4'>
              {selectedDepartment
                ? selectedDepartment?.genderName
                : departments[0]?.genderName}
            </Typography>
          </Button>
          <IconButton onClick={() => handleCloseMenus()}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />
        <Box sx={{ position: 'relative', overflow: 'auto' }}>
          {!selectedDepartment ? (
            <Box sx={styles.departmentListContainer}>
              {!departmentsLoading ? (
                departments &&
                departments.map((department) => {
                  return (
                    <Button
                      key={department?.genderId}
                      id={department?.genderId}
                      variant='text'
                      sx={[
                        styles.departmentBtn,
                        selectedDepartment?.genderId == department?.genderId &&
                          styles.selectedDepartmentBtn,
                      ]}
                      onClick={() => handleSelectDepartment(department)}
                    >
                      {department?.logo_file && (
                        <img
                          src={department?.logo_file}
                          style={styles.departmentImg}
                        />
                      )}
                      {department?.genderName}
                    </Button>
                  );
                })
              ) : (
                <Box
                  display='flex'
                  flexDirection='column'
                  alignItems='center'
                  gap={2}
                >
                  <CircularProgress size={25} />
                </Box>
              )}
            </Box>
          ) : (
            <SelectedDepartmentDrawerMenus
              selectedDepartment={selectedDepartment}
              handleCloseMenus={handleCloseMenus}
            />
          )}
        </Box>
      </Stack>
    </Box>
  );
};

DepartmentDrawerMenu.propTypes = {
  departments: PropTypes.array.isRequired,
  departmentsLoading: PropTypes.bool.isRequired,
  handleCloseMenus: PropTypes.func.isRequired,
  handleDepartmentClick: PropTypes.func.isRequired,
};

export default DepartmentDrawerMenu;
