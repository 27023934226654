const styles = {
  container: {
    display: 'inline-flex',
    padding: '24px',
    borderRadius: '8px',
    border: '0.7px solid #A9A9A9',
    background: '#FFF',
    boxShadow: '1px 1px 5px 0px rgba(0, 0, 0, 0.05)',
  },

  stepper: {
    '& .Mui-active': {
      '& .MuiStepConnector-line': {
        borderColor: '#0066C0',
      },
    },
    '& .Mui-completed': {
      '& .MuiStepConnector-line': {
        borderColor: '#0066C0',
      },
    },
    '& .MuiStepConnector-vertical': {
      marginLeft: '20px',

      '& .MuiStepConnector-line': {
        minHeight: '32px',
      },
    },

    '& .MuiStepLabel-vertical': {
      py: 0.5,
    },
  },

  stepItem: {
    gap: '32px',
  },

  stepLabel: {
    '& .MuiStepLabel-iconContainer': {
      boxShadow: '0px 1px 5px 0px #0000000D',
      padding: '10px',
      border: '0.7px solid #E7E7E7',
      borderRadius: '4px',
      marginRight: '8px',
    },
  },

  stepTitle: {
    fontSize: '12px',
    fontWeight: 800,
  },

  stepSubtitle: {
    fontSize: '12px',
    fontWeight: 500,
    color: '#000',
  },

  prevStep: {
    color: '#0066C0',
  },

  nextStep: {
    color: '#D7D7D7',
  },
};

export default styles;
