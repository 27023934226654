import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { sentEmailSteps } from 'pages/send-email/constants';
import EditRoundedIcon from 'assets/icons/edit-rounded-icon';
import { responsiveBreakpoints } from 'utils/utils';
import styles from './styles';

function Steps({ step, setStep }) {
  const { mobile, tablet } = responsiveBreakpoints();

  return (
    <Stack height={mobile || tablet ? '100%' : '60vh'} sx={styles.container}>
      <Stack sx={styles.stepItem}>
        <Stepper
          activeStep={step - 1}
          orientation={mobile || tablet ? 'horizontal' : 'vertical'}
          sx={styles.stepper}
        >
          {Object.values(sentEmailSteps).map(
            ({ id, title, subTitle, icon: Icon }) => {
              const stepStyle =
                step < id ? styles.nextStep : step > id ? styles.prevStep : {};
              const StepIcon = () =>
                step > id ? (
                  <EditRoundedIcon fill='white' />
                ) : (
                  <Icon fill={stepStyle?.color} />
                );
              return (
                <Step key={title}>
                  <StepLabel
                    StepIconComponent={StepIcon}
                    onClick={() => step > id && setStep(id)}
                    optional={
                      (!mobile,
                      !tablet ? (
                        <Typography
                          variant='caption'
                          sx={[styles.stepSubtitle, stepStyle]}
                        >
                          {subTitle}
                        </Typography>
                      ) : null)
                    }
                    sx={[
                      styles.stepLabel,
                      step > id && {
                        cursor: 'pointer',
                        '& .MuiStepLabel-iconContainer': {
                          backgroundColor: stepStyle?.color,
                        },
                      },
                    ]}
                  >
                    <Typography sx={[styles.stepTitle, stepStyle]}>
                      {title}
                    </Typography>
                  </StepLabel>
                </Step>
              );
            }
          )}
        </Stepper>
      </Stack>
    </Stack>
  );
}

Steps.propTypes = {
  step: PropTypes.number,
  setStep: PropTypes.func,
};

Steps.defaultProps = {
  step: 1,
};

export default React.memo(Steps);
