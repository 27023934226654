import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Button from '@mui/material/Button';
import MyModal from 'components/modals/modal';
import SelectBuyer from './components/select-buyers';
import CopyAssociate from './components/copy-associates';
import SendMessage from './components/send-message';
import Review from './components/review';
import useShareDraftEmail from './share-hooks';
import SendingEmailsModal from './components/sending-emails-modal';
import SelectOld from 'components/select/select.old';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useSelector } from 'react-redux';
import { loggedInUserSelector } from 'redux/selectors';
import SelectSenderSignature from 'components/Select-Sender-Signature/select-sender-signature';

export default function Share({
  open,
  setOpen,
  worksheetId,
  worksheetCollectionId,
  suggestionPdf,
  draftLink,
  suggestionId,
  catalogLink,
  pdfLink,
  catalogLinkEmail,
  shareLinkName,
  orderLink,
  shareCatalogPdf,
  sendinviteEmail,
}) {
  const {
    selectedBuyers,
    setSelectedBuyers,
    selectedAssociates,
    setSelectedAssociates,
    subject,
    setSubject,
    editorValue,
    setEditorValue,
    message,
    setMessage,
    timeline,
    setTimeline,
    handleClose,
    submit,
    emailRequests,
    sendingEmails,
    setSendingEmails,
    client,
    sender,
    setSender,
    emailsSent,
    emailResponses,
    showInventory,
    setShowInventory,
    uploadImage,
    selectedSender,
    setSelectedSender,
    selectedSignature,
    setSelectedSignature,
    subjectEmptyError,
    setSubjectEmptyError,
    isSelectAll,
    setIsSelectAll,
    messageEmptyError,
    setMessageEmptyError,
  } = useShareDraftEmail({
    open,
    setOpen,
    worksheetId,
    worksheetCollectionId,
    suggestionId,
    catalogLink,
    pdfLink,
    suggestionPdf,
    draftLink,
    catalogLinkEmail,
    shareLinkName,
    shareCatalogPdf,
    sendinviteEmail,
  });
  const nextStep = (
    <Button
      variant='contained'
      onClick={(e) => {
        if (
          timeline == 3 &&
          !draftLink &&
          (subject == null ||
            subject.trim() == '' ||
            message.trim() === '<p></p>'.trim())
        ) {
          if (message.trim() === '<p></p>'.trim()) {
            setMessageEmptyError(true);
          } else {
            setMessageEmptyError(false);
          }
          if (subject == null || subject.trim() == '') {
            setSubjectEmptyError(true);
          } else {
            setSubjectEmptyError(false);
          }
          return;
        } else {
          setSubjectEmptyError(false);
          setMessageEmptyError(false);
        }

        e.preventDefault();
        if (user.roleId === '3' && timeline === 1) {
          setTimeline(timeline + 2);
        } else {
          setTimeline(timeline + 1);
        }
      }}
    >
      Next Step
    </Button>
  );

  const close = (
    <Button
      variant='outlined'
      color='secondary'
      onClick={(e) => {
        e.preventDefault();
        handleClose();
      }}
    >
      Close
    </Button>
  );

  const sendLink = (
    <Button
      disabled={
        selectedBuyers.length > 0 ||
        (sendinviteEmail ? false : selectedAssociates.length > 0)
          ? false
          : true
      }
      variant='contained'
      onClick={(e) => {
        e.preventDefault();
        submit();
        setSendingEmails(true);
        // handleClose();
      }}
    >
      Send
    </Button>
  );
  const back = (
    <Button
      variant='outlined'
      color='secondary'
      onClick={(e) => {
        e.preventDefault();
        if (user.roleId === '3' && timeline === 3) {
          setTimeline(timeline - 2);
        } else {
          setTimeline(timeline - 1);
        }
      }}
    >
      Back
    </Button>
  );

  const user = useSelector(loggedInUserSelector);

  return (
    <MyModal
      open={open}
      setOpen={setOpen}
      size='fullWidth'
      title={
        catalogLinkEmail
          ? 'Send Catalog Link'
          : suggestionPdf
          ? 'Share Suggestion'
          : shareCatalogPdf
          ? 'Share Catalog'
          : orderLink
          ? 'Share Order'
          : sendinviteEmail
          ? 'Send Invite'
          : 'Share Draft Order'
      }
      showCloseIcon
      actions={(() => {
        if (timeline > 1) {
          if (sendinviteEmail && timeline === 2) {
            return [close, back, sendLink];
          }
          if (timeline === 4) {
            return [close, back, sendLink];
          }
          return [close, back, nextStep];
        } else {
          return [close, nextStep];
        }
      })()}
      // actions={timeline === 4 ? [close, sendLink] : [close, nextStep]}
    >
      <SendingEmailsModal
        open={sendingEmails}
        setOpen={setSendingEmails}
        emailRequests={emailResponses}
        emailsSent={emailsSent}
        openParentModal={open}
        setOpenParentModal={setOpen}
        totalEmails={emailRequests.length}
      />
      <Stack sx={{ padding: '18px 25px 18px 25px' }}>
        <Timeline>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot
                color='primary'
                sx={{
                  height: '36px',
                  width: '36px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onClick={(e) => {
                  e.preventDefault();
                  setTimeline(1);
                }}
              >
                {timeline > 1 ? (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='14'
                    height='14'
                    viewBox='0 0 14 14'
                    fill='#fff'
                  >
                    <g
                      id='_38_Question'
                      data-name='38 Question'
                      transform='translate(-0.005 -0.003)'
                    >
                      <path
                        id='Path_20782'
                        data-name='Path 20782'
                        d='M.985,14a.983.983,0,0,1-.972-1.11L.365,10.21a1.116,1.116,0,0,1,.316-.642L9.931.318a1.078,1.078,0,0,1,1.522,0L13.69,2.555a1.078,1.078,0,0,1,0,1.522l-9.25,9.25a1.115,1.115,0,0,1-.642.316l-2.683.351A.993.993,0,0,1,.985,14Zm.723-3.53-.275,2.1,2.1-.275,8.983-8.983L10.692,1.49Z'
                      />
                      <path
                        id='Path_20783'
                        data-name='Path 20783'
                        d='M283.16,84.283a.682.682,0,0,1-.483-.2l-2.794-2.794a.684.684,0,0,1,.968-.967l2.793,2.793a.684.684,0,0,1-.483,1.167Z'
                        transform='translate(-272.03 -77.929)'
                      />
                    </g>
                  </svg>
                ) : (
                  <Typography
                    variant='h2'
                    sx={{ color: 'white', fontSize: '16px' }}
                  >
                    1
                  </Typography>
                )}
              </TimelineDot>
              <TimelineConnector
                sx={{ backgroundColor: '#e3e7ed', opacity: '0.7' }}
              />
            </TimelineSeparator>
            <TimelineContent
              sx={{ flex: 500, paddingLeft: '30px', paddingTop: '20px' }}
            >
              <Stack spacing={1}>
                <Typography variant='h2' sx={{ fontWeight: '900' }}>
                  Select Buyer(s)
                </Typography>
                {/* <Stack sx={[timeline === 1 ? { display: 'block' } : { display: 'none' }]}><SelectBuyer /></Stack> */}
                {timeline === 1 && (
                  <Stack>
                    <SelectBuyer
                      selectedBuyers={selectedBuyers}
                      setSelectedBuyers={setSelectedBuyers}
                      worksheetId={worksheetId}
                      sendinviteEmail={sendinviteEmail}
                      isSelectAll={isSelectAll}
                      setIsSelectAll={setIsSelectAll}
                    />
                  </Stack>
                )}
              </Stack>
            </TimelineContent>
          </TimelineItem>
          {/* //   second Item */}
          {user?.roleId === '3' ? null : (
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot
                  color={timeline < 2 ? 'secondary' : 'primary'}
                  variant={timeline < 2 ? 'outlined' : 'filled'}
                  sx={{
                    height: '36px',
                    width: '36px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    setTimeline(2);
                  }}
                >
                  {timeline > 2 ? (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='14'
                      height='14'
                      viewBox='0 0 14 14'
                      fill='#fff'
                    >
                      <g
                        id='_38_Question'
                        data-name='38 Question'
                        transform='translate(-0.005 -0.003)'
                      >
                        <path
                          id='Path_20782'
                          data-name='Path 20782'
                          d='M.985,14a.983.983,0,0,1-.972-1.11L.365,10.21a1.116,1.116,0,0,1,.316-.642L9.931.318a1.078,1.078,0,0,1,1.522,0L13.69,2.555a1.078,1.078,0,0,1,0,1.522l-9.25,9.25a1.115,1.115,0,0,1-.642.316l-2.683.351A.993.993,0,0,1,.985,14Zm.723-3.53-.275,2.1,2.1-.275,8.983-8.983L10.692,1.49Z'
                        />
                        <path
                          id='Path_20783'
                          data-name='Path 20783'
                          d='M283.16,84.283a.682.682,0,0,1-.483-.2l-2.794-2.794a.684.684,0,0,1,.968-.967l2.793,2.793a.684.684,0,0,1-.483,1.167Z'
                          transform='translate(-272.03 -77.929)'
                        />
                      </g>
                    </svg>
                  ) : (
                    <Typography
                      variant='h2'
                      sx={[
                        { fontWeight: '900' },
                        timeline < 2
                          ? { color: 'var(--secondary-font-color)' }
                          : { color: 'white' },
                      ]}
                    >
                      2
                    </Typography>
                  )}
                </TimelineDot>
                <TimelineConnector
                  sx={{ backgroundColor: '#e3e7ed', opacity: '0.7' }}
                />
              </TimelineSeparator>
              <TimelineContent
                sx={{ flex: 500, paddingLeft: '30px', paddingTop: '20px' }}
              >
                <Stack spacing={2}>
                  {sendinviteEmail ? (
                    <Typography variant='h2' sx={{ fontWeight: '900' }}>
                      Select Sender and Signature
                    </Typography>
                  ) : (
                    <Typography variant='h2' sx={{ fontWeight: '900' }}>
                      Copy Associate(s)
                    </Typography>
                  )}

                  {/* <Stack sx={[timeline === 2 ? { display: 'block' } : { display: 'none' }]}><CopyAssociate /></Stack> */}
                  {timeline === 2 && (
                    <Stack>
                      {sendinviteEmail ? (
                        <SelectSenderSignature
                          selectedSender={selectedSender}
                          setSelectedSender={setSelectedSender}
                          selectedSignature={selectedSignature}
                          setSelectedSignature={setSelectedSignature}
                        />
                      ) : (
                        <CopyAssociate
                          selectedAssociates={selectedAssociates}
                          setSelectedAssociates={setSelectedAssociates}
                        />
                      )}
                    </Stack>
                  )}
                </Stack>
              </TimelineContent>
            </TimelineItem>
          )}
          {/* // second item ends */}
          {/* Third item  */}
          {sendinviteEmail ? null : (
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot
                  color={timeline < 3 ? 'secondary' : 'primary'}
                  variant={timeline < 3 ? 'outlined' : 'filled'}
                  sx={{
                    height: '36px',
                    width: '36px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    setTimeline(3);
                  }}
                >
                  {timeline > 3 ? (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='14'
                      height='14'
                      viewBox='0 0 14 14'
                      fill='#fff'
                    >
                      <g
                        id='_38_Question'
                        data-name='38 Question'
                        transform='translate(-0.005 -0.003)'
                      >
                        <path
                          id='Path_20782'
                          data-name='Path 20782'
                          d='M.985,14a.983.983,0,0,1-.972-1.11L.365,10.21a1.116,1.116,0,0,1,.316-.642L9.931.318a1.078,1.078,0,0,1,1.522,0L13.69,2.555a1.078,1.078,0,0,1,0,1.522l-9.25,9.25a1.115,1.115,0,0,1-.642.316l-2.683.351A.993.993,0,0,1,.985,14Zm.723-3.53-.275,2.1,2.1-.275,8.983-8.983L10.692,1.49Z'
                        />
                        <path
                          id='Path_20783'
                          data-name='Path 20783'
                          d='M283.16,84.283a.682.682,0,0,1-.483-.2l-2.794-2.794a.684.684,0,0,1,.968-.967l2.793,2.793a.684.684,0,0,1-.483,1.167Z'
                          transform='translate(-272.03 -77.929)'
                        />
                      </g>
                    </svg>
                  ) : (
                    <Typography
                      variant='h2'
                      sx={[
                        { fontWeight: '900' },
                        timeline < 3
                          ? { color: 'var(--secondary-font-color)' }
                          : { color: 'white' },
                      ]}
                    >
                      {user?.roleId === '3' ? '2' : '3'}
                    </Typography>
                  )}
                </TimelineDot>
                <TimelineConnector
                  sx={{ backgroundColor: '#e3e7ed', opacity: '0.7' }}
                />
              </TimelineSeparator>
              <TimelineContent
                sx={{ flex: 500, paddingLeft: '30px', paddingTop: '20px' }}
              >
                <Stack spacing={1}>
                  <Typography variant='h2' sx={{ fontWeight: '900' }}>
                    Add Message
                  </Typography>
                  {/* <Stack sx={[timeline === 3 ? { display: 'block' } : { display: 'none' }]}><SendMessage /></Stack> */}
                  {timeline === 3 && (
                    <Stack>
                      <Box sx={{ width: '300px', marginBottom: '5px' }}>
                        {suggestionPdf ? (
                          <SelectOld
                            placeholder='Select Sender'
                            options={[
                              { label: 'Sales Rep', value: 'sr' },
                              { label: `${client.name}`, value: 'brand' },
                            ]}
                            value={sender}
                            setValue={setSender}
                          />
                        ) : null}
                        {draftLink ? (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={showInventory}
                                sx={{ marginRight: '5px' }}
                              />
                            }
                            label='Show Inventory'
                            onClick={(e) => {
                              e.preventDefault();
                              setShowInventory(!showInventory);
                            }}
                            sx={{ marginLeft: 0 }}
                          />
                        ) : null}
                      </Box>
                      <SendMessage
                        editorValue={editorValue}
                        setEditorValue={setEditorValue}
                        message={message}
                        setMessage={setMessage}
                        subject={subject}
                        setSubject={setSubject}
                        setSubjectEmptyError={setSubjectEmptyError}
                        subjectEmptyError={subjectEmptyError}
                        showSubjectField={!draftLink}
                        showPdfTitle={orderLink ? true : false}
                        messageEmptyError={messageEmptyError}
                        setMessageEmptyError={setMessageEmptyError}
                      />
                    </Stack>
                  )}
                </Stack>
              </TimelineContent>
            </TimelineItem>
          )}

          {/* Third item ends */}
          {/* fourth item starts  */}
          {sendinviteEmail ? null : (
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot
                  // color="secondary"
                  // variant="outlined"
                  color={timeline < 4 ? 'secondary' : 'primary'}
                  variant={timeline < 4 ? 'outlined' : 'filled'}
                  sx={{
                    height: '36px',
                    width: '36px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    setTimeline(4);
                  }}
                >
                  <Typography
                    variant='h2'
                    sx={[
                      { fontWeight: '900' },
                      timeline < 4
                        ? { color: 'var(--secondary-font-color)' }
                        : { color: 'white' },
                    ]}
                  >
                    {user?.roleId === '3' ? '3' : '4'}
                  </Typography>
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent
                sx={{ flex: 500, paddingLeft: '30px', paddingTop: '20px' }}
              >
                <Stack spacing={1}>
                  <Typography variant='h2' sx={{ fontWeight: '900' }}>
                    Review &amp; Send
                  </Typography>
                  {/* <Stack sx={[timeline === 3 ? { display: 'block' } : { display: 'none' }]}><SendMessage /></Stack> */}
                  {timeline === 4 && (
                    <Stack>
                      <Review
                        subject={subject}
                        message={message}
                        selectedBuyers={selectedBuyers}
                        selectedAssociates={selectedAssociates}
                        uploadImagefunc={(file) => {
                          let formData = new FormData();
                          if (draftLink) {
                            formData.append('upload_imgfile', true);
                            formData.append('worksheet_id', worksheetId);
                            formData.append(
                              'worksheet_collection_id',
                              worksheetCollectionId
                            );
                            formData.append('type', 'proposal');
                            formData.append('email_imgfile', file);
                          }
                          if (suggestionPdf) {
                            formData.append('upload_imgfile', true);
                            formData.append('suggestion_id', suggestionId);
                            formData.append('type', 'proposal');
                            formData.append('email_imgfile', file);
                          }
                          uploadImage(formData);
                        }}
                        setSelectedBuyers={setSelectedBuyers}
                        setSelectedAssociates={setSelectedAssociates}
                      />
                    </Stack>
                  )}
                </Stack>
              </TimelineContent>
            </TimelineItem>
          )}

          {/* fourth item ends */}
        </Timeline>
      </Stack>
    </MyModal>
  );
}

Share.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  worksheetId: PropTypes.string,
  worksheetCollectionId: PropTypes.string,
  suggestionPdf: PropTypes.bool,
  draftLink: PropTypes.bool,
  suggestionId: PropTypes.string,
  catalogLink: PropTypes.string,
  pdfLink: PropTypes.string,
  catalogLinkEmail: PropTypes.string,
  shareLinkName: PropTypes.string,
  orderLink: PropTypes.string,
  shareCatalogPdf: PropTypes.string,
  sendinviteEmail: PropTypes.bool,
};

Share.defaultProps = {
  suggestionPdfEmail: false,
  draftLink: false,
  worksheetCollectionId: '',
  worksheetId: '',
  suggestionId: '',
  catalogLink: '',
  pdfLink: '',
  catalogLinkEmail: false,
  shareLinkName: '',
  orderLink: '',
  shareCatalogPdf: '',
  sendinviteEmail: false,
};
