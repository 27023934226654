export const loginStyles = {
  container: {
    width: '100%',
  },
  logo: {
    // height: '110px',
    marginBottom: '51px',
    maxWidth: '450px',
    height: '151px',
    objectFit: 'contain',
  },
  mobileLogo: {
    maxWidth: '100%',
    maxHeight: '151px',
    objectFit: 'contain',
  },
  logoSkeleton: {
    marginBottom: '51px',
  },
  defaultWidth: {
    // width: '480px',
    width: '100%',
  },
  stayLoggedInForgotContainer: {
    width: '480px',
    marginTop: '10px',
    marginBottom: '20px',
  },
  mobilestayLoggedInForgotContainer: {
    width: '100%',
    marginTop: '10px',
    marginBottom: '20px',
  },
  checkbox: {
    width: '18px',
    height: '15px',
  },
  tNcCheckbox: {
    width: '18px',
    height: '15px',
    marginRight: '4px',
  },
  checkboxLabel: {
    fontSize: '15px',
    fontWeight: '500',
    textAlign: 'bottom',
    paddingTop: '4px',
  },
  forgotButton: {
    fontSize: '15px',
    fontWeight: '500',
    padding: '0px',
    alignItems: 'bottom',
    height: 'fit-content',
  },
  tncMessage: {
    fontSize: '13px',
    color: 'var(--primary-font-color)',
    textAlign: 'center',
    width: '480px',
    padding: '5px',
  },
  mobileTncMessage: {
    fontSize: '13px',
    color: 'var(--primary-font-color)',
    textAlign: 'center',
    width: '100%',
  },
  tncLink: {
    cursor: 'pointer',
    color: '#0066c0',
  },
  visibilityContainer: {
    marginRight: '14px',
  },
  visibility: {
    color: '#cdccd6',
  },
  visibilityOn: {
    color: 'primary',
  },
};
