// NewRelicInitializer.js

import React, { useEffect } from 'react';
import { BrowserAgent } from '@newrelic/browser-agent';
import { useLocation } from 'react-router-dom';

const NewRelicInitializer = () => {
  const location = useLocation();
  useEffect(() => {
    if (process.env.REACT_APP_NODE_ENV == 'production') {
      // Populate using values in copy-paste JavaScript snippet.
      const options = {
        init: {
          distributed_tracing: { enabled: true },
          privacy: { cookies_enabled: true },
          ajax: { deny_list: ['bam.nr-data.net'] },
        }, // NREUM.init
        info: {
          beacon: 'bam.nr-data.net',
          errorBeacon: 'bam.nr-data.net',
          licenseKey: process.env.REACT_APP_LICENSE_KEY,
          applicationID: process.env.REACT_APP_APPLICATION_ID,
          sa: 1,
        }, // NREUM.info
        loader_config: {
          accountID: process.env.REACT_APP_ACCOUNT_ID,
          trustKey: process.env.REACT_APP_ACCOUNT_ID,
          agentID: process.env.REACT_APP_APPLICATION_ID,
          licenseKey: process.env.REACT_APP_LICENSE_KEY,
          applicationID: process.env.REACT_APP_APPLICATION_ID,
        }, // NREUM.loader_config
      };

      // The agent loader code executes immediately on instantiation.
      new BrowserAgent(options);
    }
  }, [location.pathname]);

  return null; // This component doesn't render anything
};

export default NewRelicInitializer;
