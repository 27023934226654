import React from 'react';
import PropTypes from 'prop-types';

export default function SearchIcon({ fill, style }) {
  return (
    <svg
      id='loupe'
      xmlns='http://www.w3.org/2000/svg'
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill={fill}
      style={style}
    >
      <g>
        <g>
          <path d='M9.688,0a9.688,9.688,0,1,0,9.688,9.688A9.7,9.7,0,0,0,9.688,0Zm0,17.588a7.9,7.9,0,1,1,7.9-7.9A7.909,7.909,0,0,1,9.688,17.588Z' />
        </g>
      </g>
      <g transform='translate(15.084 15.084)'>
        <g>
          <path
            d='M357.7,356.435l-5.127-5.127a.894.894,0,1,0-1.265,1.265l5.127,5.127a.894.894,0,0,0,1.265-1.265Z'
            transform='translate(-351.046 -351.046)'
          />
        </g>
      </g>
    </svg>
  );
}

SearchIcon.propTypes = {
  fill: PropTypes.string,
  style: PropTypes.object,
};

SearchIcon.defaultProps = {
  fill: 'var(--primary-icon-color)',
  style: {},
};
