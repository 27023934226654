/* eslint-disable react/no-unknown-property */
import React from 'react';
import PropTypes from 'prop-types';

export default function NotificationIcon({
  fill,
  width,
  height,
  style,
  active,
  showRedDot,
  showWhiteDot,
}) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 17 19.001'
      fill={active ? 'var(--color-blue-hover)' : fill}
      style={style}
    >
      <path
        d='M16.844,11.617V7.23a6.23,6.23,0,1,0-12.459,0v4.387a2.423,2.423,0,0,0,1.038,4.611h1.8a3.461,3.461,0,0,0,6.783,0h1.8a2.423,2.423,0,0,0,1.038-4.611Zm-6.23,6a2.077,2.077,0,0,1-1.949-1.384h3.9a2.077,2.077,0,0,1-1.949,1.384Zm5.191-2.769H5.423a1.038,1.038,0,0,1-.208-2.056.692.692,0,0,0,.554-.678V7.23a4.845,4.845,0,1,1,9.69,0v4.88a.692.692,0,0,0,.554.678,1.038,1.038,0,0,1-.208,2.056Z'
        transform='translate(-3 0.001)'
        fill={active ? 'var(--color-blue-hover)' : fill}
      />
      {(showRedDot || showWhiteDot) && !active && (
        <circle
          id='Ellipse_53'
          data-name='Ellipse 53'
          cx='4'
          cy='4'
          r='4'
          transform='translate(9)'
          fill={showRedDot ? 'var(--color-red)' : 'var(--color-white)'}
        />
      )}
    </svg>
  );
}

NotificationIcon.propTypes = {
  fill: PropTypes.string,
  style: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.string,
  active: PropTypes.bool,
  showWhiteDot: PropTypes.bool,
  showRedDot: PropTypes.bool,
};

NotificationIcon.defaultProps = {
  fill: 'var(--primary-icon-color)',
  style: {},
  width: '17px',
  height: '19px',
  active: false,
  showWhiteDot: false,
  showRedDot: false,
};
