import React from 'react';
import PropTypes from 'prop-types';

export default function HelpIcon({ fill, width, height, style }) {
  return (
    <svg
      id='question'
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      fill={fill}
      style={style}
      viewBox='0 0 18 18'
    >
      <g>
        <g>
          <path d='M9,0a9,9,0,1,0,9,9A8.995,8.995,0,0,0,9,0ZM9,16.744A7.744,7.744,0,1,1,16.744,9,7.753,7.753,0,0,1,9,16.744Z' />
        </g>
      </g>
      <g transform='translate(7.833 11.388)'>
        <g>
          <path
            d='M223.715,323.924a.912.912,0,0,0,0,1.824.912.912,0,0,0,0-1.824Z'
            transform='translate(-222.815 -323.924)'
          />
        </g>
      </g>
      <g transform='translate(6.554 4.481)'>
        <g>
          <path
            d='M188.755,127.469c-1.6,0-2.334.948-2.334,1.587a.679.679,0,0,0,.711.675c.64,0,.379-.912,1.587-.912.592,0,1.066.261,1.066.806,0,.64-.663,1.007-1.054,1.339a2.168,2.168,0,0,0-.794,1.8c0,.616.166.794.652.794.58,0,.7-.261.7-.486a1.488,1.488,0,0,1,.663-1.481,3.032,3.032,0,0,0,1.327-2.168C191.278,128.31,190.271,127.469,188.755,127.469Z'
            transform='translate(-186.421 -127.469)'
          />
        </g>
      </g>
    </svg>
  );
}

HelpIcon.propTypes = {
  fill: PropTypes.string,
  style: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.string,
};

HelpIcon.defaultProps = {
  fill: 'var(--primary-icon-color)',
  style: {},
  width: '18px',
  height: '18px',
};
