export const style = {
  tableContainer: { border: '1px solid var(--secondary-border-color)' },
  table: {
    borderCollapse: 'unset',
    border: '1px solid var(--secondary-border-color)',
    backgroundColor: 'var(--paper-background-color)',
  },
  tableBorder: { border: '1px solid var(--secondary-border-color)' },
  tableHead: {
    position: 'sticky',
    top: -1,
    backgroundColor: 'var(--modal-header-color)',
    cursor: 'pointer',
    zIndex: 2,
    whiteSpace: 'nowrap',
    minWidth: '200px',
  },
  tableHeadLeftSticky: {
    position: 'sticky',
    left: 0,
    top: -1,
    borderRight: '1px solid var(--primary-border-color)',
    minWidth: '200px',
    cursor: 'pointer',
    backgroundColor: 'var(--modal-header-color)',
    zIndex: 3,
  },
  selectTemplateIconCell: {
    position: 'sticky',
    top: 0,
    right: 0,
    backgroundColor: 'var(--modal-header-color)',
    cursor: 'pointer',
    zIndex: 2,
    width: '43px',
    borderRight: '1px solid var(--primary-border-color)',
    boxShadow: '-2px 0px 3px #0000001A',
  },
  tableInputField: {
    position: 'sticky',
    top: 37,
    cursor: 'pointer',
    zIndex: 2,
    backgroundColor: '#fff',
    minWidth: '200px',
  },
  tableInputFieldLeftSticky: {
    position: 'sticky',
    left: -1,
    top: 37,
    cursor: 'pointer',
    zIndex: 3,
    backgroundColor: '#fff',
    borderRight: '1px solid var(--primary-border-color)',
    minWidth: '200px',
  },
  searchIconVariant: { marginRight: '6px' },
  textField: {
    marginBottom: 0,
    height: '28px',
    fontSize: '13px',
    paddingLeft: '10px',
    backgroundColor: 'inherit',
  },
  menu: { height: '36px' },
  box: { borderRadius: '6px' },
};
