import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Stack, Typography } from '@mui/material';
import CheckCircleIcon from 'assets/icons/check-circle-icon';
import styles from './styles';

function RecipientsAccordion({
  children,
  expanded,
  setExpanded,
  title,
  subTitle,
  isSelected,
}) {
  const open = expanded === title;
  const onOpen = () => setExpanded(title);
  const onClose = () => setExpanded(null);

  return (
    <Accordion expanded={open} sx={styles.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${title}-content`}
        id={`${title}-header`}
        onClick={open ? onClose : onOpen}
        sx={styles.accordionSummary}
      >
        <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: 1.5 }}>
          <CheckCircleIcon {...(isSelected && { fill: '#00C986' })} />
          <Stack sx={{ flexDirection: 'column', gap: 0.5, margin: 0 }}>
            <Typography sx={styles.title}>{title}</Typography>
            <Typography sx={styles.subTitle}>{subTitle}</Typography>
          </Stack>
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ borderTop: '0.7px solid #A9A9A9', padding: 0 }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
}

RecipientsAccordion.propTypes = {
  children: PropTypes.node,
  expanded: PropTypes.string,
  setExpanded: PropTypes.func,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  isSelected: PropTypes.bool,
};

export default React.memo(RecipientsAccordion);
