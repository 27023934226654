import * as Yup from 'yup';

export const quickFormSchema = Yup.object().shape({
  currency: Yup.string().required('Currency is a required field'),
  priceGroup: Yup.string().required('Price Group is a required field'),
  salesRep: Yup.string().required('Sales Rep is a required field'),
  legalName: Yup.string().required('Legal Name is a required field'),
  buyerName: Yup.string().required('Buyer Name is a required field'),
  username: Yup.string().required('Username is a required field'),
  emailAddress: Yup.string()
    .email('Email Address entered is incorrect')
    .required('Buyer Email is a required field'),
  password: Yup.string().required('Password is a required field'),
  confirmPassword: Yup.string().required(
    'Confirm Password is a required field'
  ),
});
