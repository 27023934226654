import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export default function EmptyState({ icon, title, subTitle, sx }) {
  return (
    <Stack spacing={1} justifyContent='center' alignItems='center' sx={sx}>
      {icon}
      <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>
        {title}
      </Typography>
      <Typography sx={{ fontSize: '16px' }}>{subTitle}</Typography>
    </Stack>
  );
}

EmptyState.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  sx: PropTypes.object,
};

EmptyState.defaultProps = {
  sx: {},
};
