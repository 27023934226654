import React from 'react';
import PropTypes from 'prop-types';

export default function BackArrowIcon({ fill, sx }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='7.091'
      height='13'
      viewBox='0 0 7.091 13'
      fill={fill}
      style={sx}
    >
      <path
        fill={fill}
        d='M75.173 6.082l5.91-5.909a.591.591 0 0 1 .836.836L76.427 6.5l5.491 5.491a.591.591 0 1 1-.836.836l-5.909-5.91a.591.591 0 0 1 0-.836z'
        transform='translate(-75)'
      />
    </svg>
  );
}

BackArrowIcon.propTypes = {
  fill: PropTypes.string,
  sx: PropTypes.object,
};
BackArrowIcon.defaultProps = {
  fill: 'var(--primary-icon-color)',
  sx: {},
  width: '7.091',
  height: '13',
};
