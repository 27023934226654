import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { startOfDay } from 'date-fns';

import { showSnackbar } from '../../snackbar/actions';

export default function useScheduleEmailModal({
  setSendScheduleEmail,
  setScheduleDetails,
  sendEmail,
  setIsLoading,
  setShowPreview,
  setShowSendingEmailAlertModal,
}) {
  const dispatch = useDispatch();
  const formikRef = useRef(null);

  const isDateDisabled = (date) => {
    const today = startOfDay(new Date()); // Normalize today to start of the day
    const targetDate = startOfDay(date); // Normalize the passed date to start of the day
    return targetDate < today; // Disable dates before today
  };
  const handleFormikSubmit = async (values) => {
    setSendScheduleEmail(true);
    let detailsObj = {
      timeZoneValue: values?.timeZone?.value,
      dateValue: values?.scheduleDate,
      timeValue: values?.scheduleTime,
      task: 'scheduleEmail',
    };

    setScheduleDetails(detailsObj);

    let response = await sendEmail(true, detailsObj);
    if (response?.data?.status) {
      setIsLoading(false);
      setShowPreview(false);
      setShowSendingEmailAlertModal(true);
    } else {
      setIsLoading(false);
      dispatch(
        showSnackbar({
          snackbarMessage: 'Failed To Send Email',
          type: 'error',
          snackbarAlign: {
            vertical: 'top',
            horizontal: 'right',
          },
        })
      );
    }
  };

  return {
    handleFormikSubmit,
    formikRef,
    isDateDisabled,
  };
}
