import { api, apiEndPoints, addActionLoader, removeActionLoader } from 'api';
import { showSnackbar } from 'components/snackbar/actions';
import { snackbarTypes } from 'constants';

const namespace = 'pages/';

export const SET_API_ERROR = `${namespace}SET_API_ERROR`;
export const ADD_BILLING_ADDRESS = `${namespace}ADD_BILLING_ADDRESS`;
export const EDIT_BILLING_ADDRESS = `${namespace}EDIT_BILLING_ADDRESS`;
export const DELETE_BILLING_ADDRESS = `${namespace}DELETE_BILLING_ADDRESS`;
export const ADD_SHIPPING_ADDRESS = `${namespace}ADD_SHIPPING_ADDRESS`;
export const EDIT_SHIPPING_ADDRESS = `${namespace}EDIT_SHIPPING_ADDRESS`;
export const DELETE_SHIPPING_ADDRESS = `${namespace}DELETE_SHIPPING_ADDRESS`;
export const ADD_PAYMENT_TYPE = `${namespace}ADD_PAYMENT_TYPE`;
export const EDIT_PAYMENT_TYPE = `${namespace}EDIT_PAYMENT_TYPE`;
export const DELETE_PAYMENT_TYPE = `${namespace}DELETE_PAYMENT_TYPE`;
export const GENRATE_STRIPE_TOKEN = `${namespace}GENRATE_STRIPE_TOKEN`;

const setApiError = (error) => ({
  type: SET_API_ERROR,
  payload: error,
});

//  Add new Billing Address
export const addRetailerBillingAddress = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(ADD_BILLING_ADDRESS));
    const response = await api.post(apiEndPoints.newAddBillingRequest, payload);
    const { data, data: { status = false, message = '' } = {} } = response;
    if (status) {
      dispatch(
        showSnackbar({ snackbarMessage: message, type: snackbarTypes.SUCCESS })
      );
      return data;
    }
    dispatch(
      showSnackbar({ snackbarMessage: message, type: snackbarTypes.ERROR })
    );
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(ADD_BILLING_ADDRESS));
  }
};

//  edit  Billing Address
export const editRetailerBillingAddress = (payload, id) => async (dispatch) => {
  try {
    dispatch(addActionLoader(EDIT_BILLING_ADDRESS));
    const response = await api.put(
      apiEndPoints.editBillingRequest(id),
      payload
    );
    const { data, data: { status = false, message = '' } = {} } = response;
    if (status) {
      dispatch(
        showSnackbar({ snackbarMessage: message, type: snackbarTypes.SUCCESS })
      );
      return data;
    }
    dispatch(
      showSnackbar({ snackbarMessage: message, type: snackbarTypes.ERROR })
    );
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(EDIT_BILLING_ADDRESS));
  }
};

//  delete Billing Address
export const deleteBillingAddress = (id) => async (dispatch) => {
  try {
    dispatch(addActionLoader(DELETE_BILLING_ADDRESS));
    const response = await api.delete(apiEndPoints.deleteBillingAddress(id));
    const { data: { status = false, message = '' } = {} } = response;
    if (status) {
      dispatch(
        showSnackbar({ snackbarMessage: message, type: snackbarTypes.SUCCESS })
      );
      return true;
    }
    dispatch(
      showSnackbar({ snackbarMessage: message, type: snackbarTypes.ERROR })
    );
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(DELETE_BILLING_ADDRESS));
  }
};

//  Add new Shipping Address
export const addRetailerShippingAddress = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(ADD_SHIPPING_ADDRESS));
    const response = await api.post(apiEndPoints.newShippingRequest, payload);
    const { data, data: { status = false, message = '' } = {} } = response;
    if (status) {
      dispatch(
        showSnackbar({ snackbarMessage: message, type: snackbarTypes.SUCCESS })
      );
      return data;
    }
    dispatch(
      showSnackbar({ snackbarMessage: message, type: snackbarTypes.ERROR })
    );
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(ADD_SHIPPING_ADDRESS));
  }
};

//  edit  Shipping Address
export const editRetailerShippingAddress =
  (payload, id) => async (dispatch) => {
    try {
      dispatch(addActionLoader(EDIT_SHIPPING_ADDRESS));
      const response = await api.put(
        apiEndPoints.editShippingRequest(id),
        payload
      );
      const { data, data: { status = false, message = '' } = {} } = response;
      if (status) {
        dispatch(
          showSnackbar({
            snackbarMessage: message,
            type: snackbarTypes.SUCCESS,
          })
        );
        return data;
      }
      dispatch(
        showSnackbar({ snackbarMessage: message, type: snackbarTypes.ERROR })
      );
      return false;
    } catch (error) {
      dispatch(setApiError(error));
      return false;
    } finally {
      dispatch(removeActionLoader(EDIT_SHIPPING_ADDRESS));
    }
  };

//  delete shipping Address
export const deleteRetailerShippingAddress = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(DELETE_SHIPPING_ADDRESS));
    const response = await api.delete(
      apiEndPoints.deleteShippingAddress(payload)
    );
    const { data, data: { status = false, message = '' } = {} } = response;
    if (status) {
      dispatch(
        showSnackbar({ snackbarMessage: message, type: snackbarTypes.SUCCESS })
      );
      return data;
    }
    dispatch(
      showSnackbar({ snackbarMessage: message, type: snackbarTypes.ERROR })
    );
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(DELETE_SHIPPING_ADDRESS));
  }
};

//  add Payment Type
export const addPaymentType = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(ADD_PAYMENT_TYPE));
    const response = await api.post(
      apiEndPoints.newPaymentTypeRequest,
      payload
    );
    const { data, data: { status = false, message = '' } = {} } = response;
    if (status) {
      dispatch(
        showSnackbar({ snackbarMessage: message, type: snackbarTypes.SUCCESS })
      );
      return data;
    }
    dispatch(
      showSnackbar({ snackbarMessage: message, type: snackbarTypes.ERROR })
    );
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(ADD_PAYMENT_TYPE));
  }
};

//  edit  Payment Type
export const editPaymentType = (payload, id) => async (dispatch) => {
  try {
    dispatch(addActionLoader(EDIT_PAYMENT_TYPE));
    const response = await api.put(
      apiEndPoints.editPaymentTypeRequest(id),
      payload
    );
    const { data, data: { status = false, message = '' } = {} } = response;
    if (status) {
      dispatch(
        showSnackbar({ snackbarMessage: message, type: snackbarTypes.SUCCESS })
      );
      return data;
    }
    dispatch(
      showSnackbar({ snackbarMessage: message, type: snackbarTypes.ERROR })
    );
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(EDIT_PAYMENT_TYPE));
  }
};

//  delete Payment Type
export const deleteRetailerPaymentType = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(DELETE_PAYMENT_TYPE));
    const response = await api.delete(apiEndPoints.deletePaymentType(payload));
    const { data, data: { status = false, message = '' } = {} } = response;
    if (status) {
      dispatch(
        showSnackbar({ snackbarMessage: message, type: snackbarTypes.SUCCESS })
      );
      return data;
    }
    dispatch(
      showSnackbar({ snackbarMessage: message, type: snackbarTypes.ERROR })
    );
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(DELETE_PAYMENT_TYPE));
  }
};

export const getStripeToken = (payload, publishkey) => async (dispatch) => {
  try {
    dispatch(addActionLoader(GENRATE_STRIPE_TOKEN));
    const response = await api.post(
      'https://api.stripe.com/v1/tokens?Auth',
      payload,
      {
        headers: {
          Authorization: `Bearer ${publishkey}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    const { data } = response;
    if (data) {
      return data;
    }
    dispatch(
      showSnackbar({
        snackbarMessage:
          'Something went wrong while creating stripe token, please try again.',
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    const {
      response: {
        data: {
          error: {
            message = 'Something went wrong while creating stripe token, please try again.',
          } = {},
        } = {},
      } = {},
    } = error;
    dispatch(
      showSnackbar({
        snackbarMessage: message,
        type: snackbarTypes.ERROR,
      })
    );
    dispatch(setApiError(message));
    return false;
  } finally {
    dispatch(removeActionLoader(GENRATE_STRIPE_TOKEN));
  }
};
