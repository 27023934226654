import React from 'react';
import PropTypes from 'prop-types';

export default function LightBulbOnIcon({ fill, style, width, height }) {
  return (
    <svg
      id='lamp-on'
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 20 20'
      style={style}
    >
      <path
        id='Vector'
        d='M3.492,13.37V12.4A7.149,7.149,0,0,1,0,6.587,6.6,6.6,0,0,1,8.075.162a6.487,6.487,0,0,1,4.392,3.392A6.869,6.869,0,0,1,9.683,12.4v.967c0,.242.092.8-.8.8H4.292C3.375,14.17,3.492,13.812,3.492,13.37Z'
        transform='translate(3.425 1.663)'
        fill='none'
        stroke='#ef9c00'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='1.5'
      />
      <path
        id='Vector-2'
        data-name='Vector'
        d='M0,.406a10.673,10.673,0,0,1,5.833,0'
        transform='translate(7.083 17.927)'
        fill='none'
        stroke='#ef9c00'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='1.5'
      />
      <path
        id='Vector-3'
        data-name='Vector'
        d='M0,0H20V20H0Z'
        fill='none'
        opacity='0'
      />
      <path
        id='Vector-4'
        data-name='Vector'
        d='M.977,0,.085,1.55c-.2.342-.033.625.358.625H1.5c.4,0,.558.283.358.625L.977,4.35'
        transform='translate(9.027 5.65)'
        fill='none'
        stroke='#ef9c00'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='1.5'
      />
    </svg>
  );
}

LightBulbOnIcon.propTypes = {
  fill: PropTypes.string,
  style: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.string,
};

LightBulbOnIcon.defaultProps = {
  fill: 'var(--primary-icon-color)',
  style: {},
  width: '20',
  height: '20',
};
