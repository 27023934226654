import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function useDraftsQuickPanel({ getDrafts, getSharedDrafts }) {
  const [selectedId, setSelectedId] = useState(null);
  const [initialDraftName, setInitialDraftName] = useState('');
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showUpdateDraftModal, setShowUpdateDraftModal] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    let quickpanel = true;
    getDrafts(quickpanel);
  }, []);

  return {
    selectedId,
    setSelectedId,
    showDeleteConfirmation,
    setShowDeleteConfirmation,
    navigate,
    initialDraftName,
    setInitialDraftName,
    showUpdateDraftModal,
    setShowUpdateDraftModal,
  };
}
