import React from 'react';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

const NewAccountSkeleton = () => {
  return (
    <Stack spacing={1}>
      <Box>
        <Skeleton variant='text' width='100px' sx={{ fontSize: '14px' }} />
        <Skeleton variant='rounded' width='480px' height='38px' />
      </Box>
      <Box>
        <Skeleton variant='text' width='100px' sx={{ fontSize: '14px' }} />
        <Skeleton variant='rounded' width='480px' height='38px' />
      </Box>
      <Box>
        <Skeleton variant='text' width='100px' sx={{ fontSize: '14px' }} />
        <Skeleton variant='rounded' width='480px' height='38px' />
      </Box>
    </Stack>
  );
};

export default NewAccountSkeleton;
