import { useState, useEffect } from 'react';
import { api } from '../../../api';
import { useDispatch, useSelector } from 'react-redux';
import { createGroup } from 'pages/customers/actions';
import SessionStorageManager from 'utils/session-storage-manager';
import {
  useNavigate,
  useParams,
  useSearchParams,
  createSearchParams,
  useMatch,
} from 'react-router-dom';

export default function useSelectBuyersTable({
  selectedBuyers,
  setSelectedBuyers,
  worksheetId,
  sendinviteEmail,
  isSelectAll,
  setIsSelectAll,
}) {
  const [options, setOptions] = useState([]);
  const [buyersList, setBuyersList] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [alphabetValue, setAlphabetValue] = useState('');
  const [salesRep, setSalesRep] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [groups, setGroups] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);
  const [emailValue, setEmailValue] = useState('');
  const [showCreateCustomerGroupModal, seShowCreateCustomerGroupModal] =
    useState(false);
  const [clearSelectedBuyersList, setClearSelectedBuyersList] = useState(false);
  //state to manage loader
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  async function getGroups() {
    let res = await api.get(`/groups`);
    if (res.data.status) setGroupOptions(res.data.data);
  }

  useEffect(() => {
    getGroups();
  }, []);

  //  logic to handle checkbox
  function handleCheck(value) {
    if (selectedBuyers.some((obj) => obj.buyer_id === value.buyer_id)) {
      setSelectedBuyers(() =>
        selectedBuyers.filter((item) => item.buyer_id !== value.buyer_id)
      );
    } else {
      setSelectedBuyers((prev) => {
        return [...prev, value];
      });
    }
  }
  //    logic to handle onPageChange for pagination
  const onPageChangeHandler = (event, page) => {
    event.preventDefault();
    setPage(page);
    // getBuyers(page, rowsPerPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  // logic ends onpagechange

  //get buyers list
  async function getBuyers({
    id,
    searchTerm,
    salesRepId,
    alphaSort,
    page,
    rowsPerPage,
    groups,
  }) {
    setIsLoading(true);
    let url = '';
    if (groups.length > 0) {
      if (sendinviteEmail) {
        url = `/draftBuyerList?worksheetId=123&search=${searchTerm}&salesRepId=${salesRepId}&alphasort=${alphaSort}&iDisplayStart=${page}&iDisplayLength=${
          isSelectAll ? 'All ' : rowsPerPage
        }&filters={"groupIds":[${groups}]}&sortByEmail=${
          emailValue?.value ?? '99'
        }`;
      } else {
        url = `/draftBuyerList?worksheetId=123&search=${searchTerm}&salesRepId=${salesRepId}&alphasort=${alphaSort}&iDisplayStart=${page}&iDisplayLength=${
          isSelectAll ? 'All ' : rowsPerPage
        }&filters={"groupIds":[${groups}]}`;
      }
    } else {
      if (sendinviteEmail) {
        url = `/draftBuyerList?worksheetId=123&search=${searchTerm}&salesRepId=${salesRepId}&alphasort=${alphaSort}&iDisplayStart=${page}&iDisplayLength=${
          isSelectAll ? 'All ' : rowsPerPage
        }&sortByEmail=${emailValue?.value ?? '99'}`;
      } else {
        url = `/draftBuyerList?worksheetId=123&search=${searchTerm}&salesRepId=${salesRepId}&alphasort=${alphaSort}&iDisplayStart=${page}&iDisplayLength=${
          isSelectAll ? 'All ' : rowsPerPage
        }`;
      }
    }
    const res = await api.get(url);
    let arr = [];
    res?.data?.data?.forEach((buyer) => {
      // TODO : ned to check hardcoded 1 value
      if (buyer.isFromGroup === '1') {
        arr.push(buyer);
      } else if (isSelectAll) {
        arr.push(buyer);
      }
    });
    if (clearSelectedBuyersList) {
      setSelectedBuyers((prev) => [...arr]);
      setClearSelectedBuyersList(false);
    } else {
      setSelectedBuyers((prev) => [...prev, ...arr]);
    }

    // setSelectedBuyers(arr);
    // if (arr.length > 0) {
    //   setIsSelectAll(true);
    // } else {
    //   setIsSelectAll(false);
    // }
    setBuyersList(res.data);
    setIsLoading(false);
  }

  //get sort options
  async function getSalesReps() {
    const res = await api.get(`/getSalesRep`);

    var arr = [];
    res.data?.forEach((op) => {
      arr.push({ label: op.name, value: op.id });
    });
    setOptions(arr);
  }

  useEffect(() => {
    getBuyers({
      id: worksheetId,
      searchTerm: searchValue,
      salesRepId: salesRep?.value ?? '',
      alphaSort: alphabetValue,
      page,
      rowsPerPage,
      groups,
    });
  }, [
    searchValue,
    alphabetValue,
    page,
    rowsPerPage,
    groups,
    emailValue,
    salesRep,
    isSelectAll,
  ]);

  // useEffect(() => {
  //   let arr = [];
  //   if (buyersList) {
  //     buyersList?.data?.forEach((buyer) => {
  //       if (buyer.isFromGroup === '1') arr.push(buyer);
  //     });
  //   }

  //   setSelectedBuyers((prev) => [...prev, ...arr]);
  // }, [groups]);

  useEffect(() => {
    getSalesReps();
  }, []);
  const onCreateGroupSaveHandler = async (payload) => {
    const response = await dispatch(createGroup(payload));
    if (response) {
      seShowCreateCustomerGroupModal(false);
      getGroups();
    }
  };
  return {
    options,
    setOptions,
    handleCheck,
    buyersList,
    searchValue,
    setSearchValue,
    alphabetValue,
    setAlphabetValue,
    salesRep,
    setSalesRep,
    setPage,
    rowsPerPage,
    page,
    setRowsPerPage,
    onPageChangeHandler,
    handleChangeRowsPerPage,
    groups,
    setGroups,
    groupOptions,
    isLoading,
    emailValue,
    setEmailValue,
    isSelectAll,
    setIsSelectAll,
    showCreateCustomerGroupModal,
    seShowCreateCustomerGroupModal,
    onCreateGroupSaveHandler,
    setIsLoading,
    setClearSelectedBuyersList,
  };
}
