import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import isEmpty from 'lodash/isEmpty';
import { useHeader2 } from './hooks';
import { useNavigate } from 'react-router-dom';
import SuggestionsQuickPanel from './suggestions-quick-panel';
import DraftsQuickPanel from './drafts-quick-panel';
import { useMatch } from 'react-router-dom';
import LightBulbIcon from 'assets/icons/light-bulb-icon';
import Search from './search/search';
import { welcomeBarDataSelector } from 'redux/selectors';
import HeaderCartLargeIcon from 'assets/icons/header-cart-large-icon';
import { responsiveBreakpoints } from 'utils/utils';
import { profileRoles } from 'constants/index';
import { Box } from '@mui/material';

function Headers2() {
  const { config, activeDraftSuggestion, user } = useHeader2();
  // eslint-disable-next-line no-unused-vars
  const [showSuggestionsPanel, setShowSuggestionsPanel] = useState(false);
  const [draftsPanel, showDraftsPanel] = useState(false);
  const navigate = useNavigate();
  const [timeoutId, setTimeoutId] = useState(null);

  //flag to check if the app is on a marketplace page
  const isMarketplace = useMatch(`/marketplace/*`);
  const welcomeBarConfig = useSelector(welcomeBarDataSelector);
  const { theme, mobile, tablet, laptop, desktop, mainWidth } =
    responsiveBreakpoints();

  if (!isEmpty(config))
    return (
      <AppBar
        position='static'
        sx={[
          {
            // position: 'sticky',
            // top: '31px',
            // top: `${
            //   !isEmpty(welcomeBarConfig) && welcomeBarConfig.wel_bar_pos == 1
            //     ? `${parseInt(welcomeBarConfig.bar_height) + 31}px`
            //     : '31px'
            // }`,
            height: '60px',
            backgroundColor: 'var(--paper-background-color)',
            boxShadow: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0px',
            // zIndex: zIndex.stickyH2,
          },
          isMarketplace
            ? { borderBottom: '1px solid var(--secondary-border-color)' }
            : null,
        ]}
      >
        <Toolbar
          disableGutters
          sx={{
            justifyContent: 'space-between',
            width: mainWidth,
          }}
        >
          <Box
            sx={{
              width: '48%',
              overflowY: 'scroll',
              height: '60px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Tooltip
              title={config?.logo_alt_text}
              placement='right-end'
              sx={{
                backgroundColor: 'red',
                color: 'red',
              }}
            >
              {!isEmpty(config) && (
                <img
                  src={config.media.logo}
                  alt='logo'
                  style={{
                    // maxWidth: '650px',
                    maxWidth: '620px',
                    // maxWidth: '600px',
                    maxHeight: '50px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    navigate('/');
                    location.reload();
                  }}
                />
              )}
            </Tooltip>
          </Box>
          {isMarketplace ? null : (
            <Stack
              direction='row'
              alignItems='center'
              spacing={2}
              sx={{
                position: 'relative',
              }}
            >
              {/* Search field */}
              <Search />

              {/* suggestion quick panel */}
              {user?.roleId !== '3' || user?.showSuggestions ? (
                <Stack sx={{ position: 'relative' }}>
                  <Stack
                    direction='row'
                    spacing={0}
                    justifyContent='space-between'
                    alignItems='center'
                    // sx={{
                    //   padding: '5px 10px',
                    //   width: '150px',
                    // }}
                    onMouseEnter={(e) => {
                      e.preventDefault();
                      // setShowSuggestionsPanel(true);
                      setTimeoutId(
                        setTimeout(() => setShowSuggestionsPanel(true), 500)
                      );
                    }}
                    onMouseLeave={(e) => {
                      e.preventDefault();
                      setTimeoutId(clearTimeout(timeoutId));
                      if (showSuggestionsPanel) setShowSuggestionsPanel(false);
                    }}
                    onClick={() => {
                      navigate(
                        `/suggestions/${activeDraftSuggestion?.suggestion?.id}`
                      );
                    }}
                  >
                    <Stack
                      direction='row'
                      spacing={1}
                      alignItems='center'
                      sx={{ cursor: 'pointer' }}
                    >
                      <LightBulbIcon
                        style={{ marginTop: '1px' }}
                        fill='rgba(0, 0, 0, 0.6)'
                      />
                      {activeDraftSuggestion?.suggestion?.suggestionCount &&
                        activeDraftSuggestion?.suggestion?.suggestionCount >
                          1 && (
                          <div
                            style={{
                              backgroundColor: 'red',
                              height: '18px',
                              width: 'fit-content',
                              color: '#ffffff',
                              borderRadius: '10px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              padding: '6px',
                              fontSize: '11px',
                              position: 'absolute',
                              top: '0',
                            }}
                          >
                            {activeDraftSuggestion?.suggestion
                              ?.suggestionCount || ''}
                          </div>
                        )}
                      <Stack>
                        <Stack
                          direction='row'
                          spacing={0.5}
                          sx={{
                            marginLeft: '-2px',
                            marginTop: '10px',
                            minHeight: '36px',
                          }}
                        >
                          <Typography
                            noWrap
                            variant='body1'
                            sx={{ maxWidth: '100px' }}
                          >
                            <Typography
                              sx={{ fontWeight: '600', verticalAlign: 'top' }}
                            >
                              Suggestions
                            </Typography>
                            {user?.roleId !== profileRoles?.buyer ? (
                              <Typography
                                sx={{
                                  fontWeight: 'normal',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                }}
                                display='block'
                              >
                                {activeDraftSuggestion?.suggestion?.name
                                  ? activeDraftSuggestion?.suggestion?.name
                                  : 'N/A'}
                              </Typography>
                            ) : null}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                    {/*<ArrowDownIcon sx={{ width: '10px', height: '14px' }} />*/}
                  </Stack>
                  {showSuggestionsPanel ? (
                    <SuggestionsQuickPanel
                      mouseOver={(e) => {
                        e.preventDefault();
                        setShowSuggestionsPanel(true);
                      }}
                      mouseOut={(e) => {
                        e.preventDefault();
                        setShowSuggestionsPanel(false);
                      }}
                      setOpen={setShowSuggestionsPanel}
                      open={showSuggestionsPanel}
                    />
                  ) : null}
                </Stack>
              ) : null}

              {/* drafts quick panel */}
              <Stack
                direction='row'
                spacing={0}
                justifyContent='space-between'
                alignItems='center'
                position='relative'
                // sx={{
                //   padding: '5px 10px',
                // }}
                onMouseEnter={(e) => {
                  e.preventDefault();
                  setTimeoutId(setTimeout(() => showDraftsPanel(true), 500));
                  // showDraftsPanel(true);
                }}
                onMouseLeave={(e) => {
                  e.preventDefault();
                  setTimeoutId(clearTimeout(timeoutId));
                  if (draftsPanel) showDraftsPanel(false);
                }}
                onClick={() => {
                  navigate(`/drafts/${activeDraftSuggestion?.draft?.id}`);
                }}
              >
                <Stack
                  direction='row'
                  spacing={1}
                  alignItems='center'
                  sx={{ cursor: 'pointer', position: 'relative' }}
                >
                  {/* <CartIcon style={{ marginTop: '8px' }} /> */}
                  <IconButton
                    sx={{ marginTop: '8px', height: '21px', width: '21px' }}
                  >
                    {/* <DraftsQuickPanelIcon fill='rgba(0, 0, 0, 0.6)' /> */}
                    <HeaderCartLargeIcon fill='rgba(0, 0, 0, 0.6)' />
                  </IconButton>

                  {activeDraftSuggestion?.draft?.worksheetCount &&
                    activeDraftSuggestion?.draft?.worksheetCount > 1 && (
                      <div
                        style={{
                          backgroundColor: 'red',
                          height: '18px',
                          width: 'fit-content',
                          color: '#ffffff',
                          borderRadius: '10px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          padding: '6px',
                          fontSize: '11px',
                          position: 'absolute',
                          top: '0',
                        }}
                      >
                        {activeDraftSuggestion?.draft?.worksheetCount || ''}
                      </div>
                    )}
                  <Stack>
                    <Stack
                      direction='row'
                      spacing={0.5}
                      sx={{ marginLeft: '-2px', marginTop: '12px' }}
                    >
                      {/*<Typography variant='body1'>Drafts</Typography>*/}
                      <Typography
                        noWrap
                        variant='body1'
                        sx={{ maxWidth: '100px' }}
                      >
                        <Typography sx={{ fontWeight: '600' }}>
                          Draft Orders
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 'normal',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                          display='block'
                        >
                          {activeDraftSuggestion?.draft?.name
                            ? activeDraftSuggestion?.draft?.name
                            : 'N/A'}
                        </Typography>
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
                {/*<ArrowDownIcon sx={{ width: '10px', height: '14px' }} />*/}
              </Stack>

              {draftsPanel ? (
                <DraftsQuickPanel
                  mouseOver={(e) => {
                    e.preventDefault();
                    showDraftsPanel(true);
                  }}
                  mouseOut={(e) => {
                    e.preventDefault();
                    showDraftsPanel(false);
                  }}
                  setOpen={showDraftsPanel}
                  open={showDraftsPanel}
                />
              ) : null}
            </Stack>
          )}
        </Toolbar>
      </AppBar>
    );
}

export default Headers2;
