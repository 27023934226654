const styles = {
  accordion: {
    border: '0.7px solid #A9A9A9',
    boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.05)',
    padding: 0,
    '&:first-of-type': {
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
    },
    '&:last-of-type': {
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px',
    },
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&.Mui-expanded': {
      margin: '0px',
    },
  },

  accordionSummary: {
    padding: '10px 14px',
    height: 'none',
    '& .MuiAccordionSummary-content': { margin: 0 },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: 0,
    },
  },

  title: { fontSize: '14px', fontWeight: 800 },
  subTitle: { fontSize: '12px', color: '#292D32', fontWeight: 500 },
};

export default styles;
