import React from 'react';
import PropTypes from 'prop-types';

export default function WriteMessageIcon({ fill, sx }) {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      style={sx}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9 15.375H5.25C3 15.375 1.5 14.25 1.5 11.625V6.375C1.5 3.75 3 2.625 5.25 2.625H12.75C15 2.625 16.5 3.75 16.5 6.375V8.625'
        stroke={fill}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.75 6.75L10.4025 8.625C9.63 9.24 8.3625 9.24 7.59 8.625L5.25 6.75'
        stroke={fill}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.4073 11.0775L11.7523 13.7325C11.6473 13.8375 11.5498 14.0325 11.5273 14.175L11.3848 15.1875C11.3323 15.555 11.5873 15.81 11.9548 15.7575L12.9673 15.615C13.1098 15.5925 13.3123 15.495 13.4098 15.39L16.0648 12.735C16.5223 12.2775 16.7398 11.745 16.0648 11.07C15.3973 10.4025 14.8648 10.62 14.4073 11.0775Z'
        stroke={fill}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.0244 11.46C14.2494 12.27 14.8794 12.9 15.6894 13.125'
        stroke={fill}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

WriteMessageIcon.propTypes = {
  fill: PropTypes.string,
  sx: PropTypes.object,
};

WriteMessageIcon.defaultProps = {
  fill: '#000000',
  sx: {},
};
