import React from 'react';
import PropTypes from 'prop-types';

export default function LocationIcon({ fill }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='15.351'
      height='19.188'
      viewBox='0 0 15.351 19.188'
      fill={fill}
    >
      <path
        d='M16.675,5A7.675,7.675,0,0,0,11,17.837l5.315,6.183a.48.48,0,0,0,.729,0l5.315-6.183A7.675,7.675,0,0,0,16.675,5Zm4.951,12.213-4.951,5.761-4.951-5.756a6.716,6.716,0,1,1,9.9,0Z'
        transform='translate(-9 -5)'
      />
      <path
        d='M21.358,14a3.358,3.358,0,1,0,3.358,3.358A3.358,3.358,0,0,0,21.358,14Zm0,5.756a2.4,2.4,0,1,1,2.4-2.4A2.4,2.4,0,0,1,21.358,19.756Z'
        transform='translate(-13.683 -9.683)'
      />
    </svg>
  );
}

LocationIcon.propTypes = {
  fill: PropTypes.string,
};

LocationIcon.defaultProps = {
  fill: 'var(--primary-icon-color)',
};
