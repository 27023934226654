export const accountRequestDataLabelsMapping = {
  buyer_name: 'Name',
  buyer_email: 'Email',
  buyer_phone: 'Phone',
  buyer_fax: 'Fax',
  tax_id: 'Tax_id',
  website: 'Website',
  description: 'Description',
  billing_address: 'Billing Adress',
  address: 'Address',
  address2: 'Address2',
  city: 'City',
  state: 'State',
  postal_code: 'Postal Code',
  country: 'Country',
  shipping_address: 'Address',
  shipping_address2: 'Address2',
  shipping_city: 'City',
  shipping_state: 'State',
  shipping_postal_code: 'Postal Code',
  shipping_country: 'Country',
};
