import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { getDepartmentDeliveries } from 'pages/layout/layout-api';
import CategoryComponent from './categoryComponent';
import CircularProgress from '@mui/material/CircularProgress';

const AccordionComponent = ({
  delivery,
  genderId,
  handleDepartmentMenuClick,
}) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <Accordion
      disableGutters
      elevation={0}
      expanded={expanded}
      onChange={() => {
        setExpanded(!expanded);
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          padding: '0px 46px 0px 20px',
          '& .MuiAccordionSummary-content': {
            margin: 0, // Remove the margin
          },
        }}
      >
        <Stack
          direction='column'
          spacing={1}
          onClick={() =>
            handleDepartmentMenuClick({ item: delivery, genderId })
          }
          sx={{ padding: '0px', fontSize: '12px' }}
        >
          <Typography variant='h4'>{delivery?.noteText}</Typography>
          <Typography variant='h4'>{delivery.deliver_from}</Typography>
          <Typography variant='h4'>{delivery.message}</Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: '0px',
        }}
      >
        <Stack sx={{}}>
          {expanded ? (
            <CategoryComponent
              delivery={delivery}
              genderId={genderId}
              handleDepartmentMenuClick={handleDepartmentMenuClick}
            />
          ) : null}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
AccordionComponent.propTypes = {
  delivery: PropTypes.obj,
  genderId: PropTypes.any,
  handleDepartmentMenuClick: PropTypes.func,
};

AccordionComponent.defaultProps = {
  delivery: {},
  genderId: '',
  handleDepartmentMenuClick: () => {},
};

const DeliveryAccordion = ({
  collection,
  genderId,
  handleDepartmentMenuClick,
}) => {
  // fetching and caching deliveries
  const { data: deliveries = [], isLoading: deliveriesLoading } = useQuery(
    ['getDepartmentDeliveries', collection?.name, genderId],
    () => getDepartmentDeliveries(collection?.name, 'delivery', genderId),
    {
      enabled: !!collection?.name && !!genderId,
      cacheTime: 3600000,
      staleTime: 3600000,
      select: (data) => {
        return data?.data?.data || [];
      },
    }
  );
  if (deliveriesLoading) {
    return (
      <CircularProgress
        sx={{
          width: '6px',
          height: '6px',
        }}
      />
    );
  } else {
    return deliveries?.map((delivery, i) => (
      <Stack key={i}>
        <AccordionComponent
          delivery={delivery}
          genderId={genderId}
          handleDepartmentMenuClick={handleDepartmentMenuClick}
        />
      </Stack>
    ));
  }
};
export default DeliveryAccordion;
DeliveryAccordion.propTypes = {
  collection: PropTypes.obj,
  genderId: PropTypes.any,
  handleDepartmentMenuClick: PropTypes.func,
};

DeliveryAccordion.defaultProps = {
  collection: {},
  genderId: '',
  handleDepartmentMenuClick: () => {},
};
