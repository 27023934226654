import * as React from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import useBuyersTable from './buyers-table-hooks';

export default function BuyerTable({
  retailerId,
  selectedBuyers,
  setSelectedBuyers,
}) {
  const { buyers, toggleSelectedBuyers } = useBuyersTable({
    retailerId,
    selectedBuyers,
    setSelectedBuyers,
  });

  return (
    <TableContainer>
      <Table
        size='small'
        sx={{ border: '1px solid var(--secondary-border-color)' }}
      >
        <TableHead>
          <TableRow>
            <TableCell>&nbsp;</TableCell>
            <TableCell>NAME</TableCell>
            <TableCell>EMAIL</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {buyers.map((buyer, i) => {
            return (
              <TableRow
                key={i}
                onClick={(e) => {
                  e.preventDefault();
                  toggleSelectedBuyers(buyer);
                }}
              >
                <TableCell sx={{ width: '20px' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={(() => {
                          var isSelected = false;
                          isSelected = selectedBuyers?.some(
                            (buyerToCheck) =>
                              buyerToCheck.user_id === buyer.user_id
                          );
                          return isSelected;
                        })()}
                      />
                    }
                    label=''
                    sx={{ paddingLeft: '40px' }}
                  />
                </TableCell>
                <TableCell>{buyer?.name}</TableCell>
                <TableCell>{buyer?.email}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

BuyerTable.propTypes = {
  retailerId: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  selectedBuyers: PropTypes.array.isRequired,
  setSelectedBuyers: PropTypes.func.isRequired,
};
