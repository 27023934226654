import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

export default function ApplyButton({ handleSubmit }) {
  return (
    <Button
      variant='contained'
      onClick={(e) => {
        e.preventDefault();
        handleSubmit({ action: 'apply' });
      }}
    >
      Apply
    </Button>
  );
}

ApplyButton.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};
