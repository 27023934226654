import React from 'react';
import PropTypes from 'prop-types';

export default function ArrowPrevIcon({ fill, sx, stroke }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12.621'
      height='22.243'
      viewBox='0 0 12.621 22.243'
    >
      <path
        id='Icon_feather-chevron-left'
        data-name='Icon feather-chevron-left'
        d='M22.5,27l-9-9,9-9'
        transform='translate(-12 -6.879)'
        fill='none'
        stroke={stroke}
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='3'
      />
    </svg>
  );
}

ArrowPrevIcon.propTypes = {
  fill: PropTypes.string,
  sx: PropTypes.object,
  stroke: PropTypes.string,
};
ArrowPrevIcon.defaultProps = {
  fill: 'var(--primary-icon-color)',
  sx: {},
  width: '7.091',
  height: '13',
  stroke: '#000',
};
