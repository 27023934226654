import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import UploadIcon from '../../../assets/icons/upload-icon';
import { LogLevel } from 'constants';
import LoggingService from 'utils/service/LoggingService';

function SectionHeader({ heading }) {
  return (
    <Typography
      variant='h3'
      sx={{
        width: '100%',
        paddingBottom: '4px',
        borderBottom: '1px solid var(--secondary-border-color)',
      }}
    >
      {heading}
    </Typography>
  );
}

SectionHeader.propTypes = {
  heading: PropTypes.string.isRequired,
};

export default function Review({
  subject,
  message,
  selectedAssociates,
  selectedBuyers,
  uploadImagefunc,
  setSelectedBuyers,
  setSelectedAssociates,
}) {
  const [fileName, setFileName] = useState('No File Chosen');
  useEffect(() => {
    try {
      const ConvertStringToHTML = function (str) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(str, 'text/html');
        return doc.body;
      };
      document.getElementById('typo').appendChild(ConvertStringToHTML(message));
    } catch (err) {
      LoggingService.log(
        'useEffect',
        'SectionHeader',
        err,
        LogLevel.ERROR,
        'This error logged from review.jsx file line no 54',
        {}
      );
    }
  }, [message]);
  return (
    <Stack
      spacing={2.5}
      sx={{
        padding: '20px 25px',
        backgroundColor: 'var(--paper-background-color)',
        border: '1px solid var(--secondary-border-color)',
        borderRadius: '6px',
        width: '70vw',
      }}
    >
      <Stack spacing={1}>
        <SectionHeader heading='To: Buyers' />
        <Stack
          direction='row'
          sx={{
            display: 'inline-block',
            width: 'inherit',
            maxHeight: '70px',
            overflowY: 'scroll',
          }}
        >
          {selectedBuyers.map((buyer, i) => (
            <Chip
              key={i}
              label={buyer.aname}
              variant='outlined'
              onDelete={(e) => {
                e.preventDefault();
                setSelectedBuyers(() =>
                  selectedBuyers.filter(
                    (item) => item.buyer_id !== buyer.buyer_id
                  )
                );
              }}
              sx={{
                marginBottom: '5px',
                fontWeight: '700',
                marginRight: '8px',
                width: 'fit-content',
                borderRadius: '4px',
                height: '30px',
                backgroundColor: 'rgba(227, 227, 227, 0.5)',
                borderColor: 'var(--secondary-border-color)',
              }}
            />
          ))}
        </Stack>
      </Stack>
      <Stack spacing={1}>
        <SectionHeader heading='CC: Associates' />
        <Stack
          direction='row'
          sx={{
            display: 'inline-block',
            width: 'inherit',
            maxHeight: '70px',
            overflowY: 'scroll',
          }}
        >
          {selectedAssociates.map((associate, i) => (
            <Chip
              key={i}
              label={`${associate.name} + (${associate.email})`}
              variant='outlined'
              onDelete={(e) => {
                e.preventDefault();
                setSelectedAssociates(() =>
                  selectedAssociates.filter(
                    (item) => item.name !== associate.name
                  )
                );
              }}
              sx={{
                marginBottom: '5px',
                fontWeight: '700',
                marginRight: '8px',
                width: 'fit-content',
                borderRadius: '4px',
                height: '30px',
                backgroundColor: 'rgba(227, 227, 227, 0.5)',
                borderColor: 'var(--secondary-border-color)',
              }}
            />
          ))}
        </Stack>
      </Stack>
      <Stack spacing={1}>
        <SectionHeader heading='Subject' />
        <Typography variant='body1'>{subject}</Typography>
      </Stack>
      {/* textEditor */}
      <Stack spacing={1}>
        <SectionHeader heading='Message' />
        <Typography
          sx={{
            lineHeight: 1,
            fontSize: '15px',
            '& p': { margin: 0 },
          }}
          id='typo'
          variant='body1'
        />
      </Stack>
      <Stack direction='row' spacing={2} alignItems='center'>
        <Button
          variant='contained'
          sx={{ width: 'fit-content' }}
          startIcon={<UploadIcon />}
          onClick={(e) => {
            e.preventDefault();
            document.getElementById('files').click();
          }}
        >
          Upload Image
        </Button>
        <Typography variant='body2'>{`${
          fileName?.name ? fileName?.name : fileName
        }`}</Typography>
        <input
          id='files'
          type='file'
          hidden
          onChange={(e) => {
            uploadImagefunc(e.target.files[0]);
            setFileName(e.target.files[0]);
          }}
        ></input>
      </Stack>
    </Stack>
  );
}

Review.propTypes = {
  subject: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  selectedAssociates: PropTypes.array.isRequired,
  selectedBuyers: PropTypes.array.isRequired,
  uploadImagefunc: PropTypes.func.isRequired,
  setSelectedBuyers: PropTypes.func.isRequired,
  setSelectedAssociates: PropTypes.func.isRequired,
};
