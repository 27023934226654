import React, { useEffect, useState } from 'react';
import { api, apiEndPoints } from 'api';

export const useWatchTour = ({ setWatchTourCompleted }) => {
  const [watchTourData, setWatchTourData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [checked, setChecked] = useState(false);
  const open = anchorEl;
  const id = open ? 'more-options' : undefined;
  useEffect(() => {
    getWatchTourData();
  }, []);
  const getWatchTourData = async () => {
    const res = await api.get(apiEndPoints?.helpTour);
    if (res?.data?.status) {
      setWatchTourData(res?.data?.data);
    }
  };

  const updateTourStatus = async (id) => {
    const res = await api.post(apiEndPoints?.updateTourStatus(id));
    if (!res?.data?.status) {
      setWatchTourCompleted(false);
    }
  };

  // handle click for  popover
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  // handle close for  popover
  const handleClose = () => {
    setAnchorEl(null);
    setChecked(false);
  };
  return {
    watchTourData,
    anchorEl,
    setAnchorEl,
    open,
    id,
    handleClose,
    handleClick,
    updateTourStatus,
    checked,
    setChecked,
  };
};
