import { api, apiEndPoints } from 'api/index';

export const getDepartments = () => api.get(apiEndPoints.departments);

export const getDepartmentCollections = (id) =>
  api.get(apiEndPoints.departmentCatagory(id));

export const getDepartmentDeliveries = (collectionName, type, departmentId) =>
  api.get(
    `${apiEndPoints.deliveries}?collectionName=${encodeURIComponent(
      collectionName
    )}&type=${type}&departmentId=${departmentId}`
  );

export const getDepartmentCatagory = (collectionId, departmentId) =>
  api.get(
    `${apiEndPoints.categories}?collectionId=${collectionId}&departmentId=${departmentId}`
  );
