import UAParser from 'ua-parser-js';
import { api, apiEndPoints } from 'api';
import store from 'redux/configure-store';

class LoggingService {
  log(
    functionName,
    componentName,
    error,
    logType,
    customMessage = '',
    data = {}
  ) {
    try {
      const {
        layoutReducer: { loggedInUserInfo: { username = '' } = {} } = {},
      } = store.getState();
      const parser = new UAParser();
      const result = parser.getResult();

      const logPaylod = {
        website_url: window.location.href,
        user_info: `username: ${username}`,
        error_message: error?.stack ?? error.message,
        component_info: componentName,
        additional_info: {
          functionName,
          logType,
          message: customMessage ?? '',
          data,
          operatingSystem:
            `${result.os.name} ${result.os.version}` ?? 'Unknown OS',
          browser:
            `${result.browser.name} ${result.browser.version}` ??
            'Unknown Browser',
        },
      };
      api.post(apiEndPoints.errorEmail, logPaylod);
    } catch (error) {
      const {
        layoutReducer: { loggedInUserInfo: { username = '' } = {} } = {},
      } = store.getState();
      api.post(apiEndPoints.errorEmail, {
        website_url: window.location.href,
        user_info: `username: ${username}`,
        error_message: error?.stack ?? error.message,
        component_info: 'LoggingService',
        additional_info: {
          functionName: 'log',
          message: 'Something went wrong while logging error',
        },
      });
    }
  }
}

export default new LoggingService();
