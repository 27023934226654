import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Stack, Typography, Grid } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import TextField from 'components/text-field';
import { Formik } from 'formik';
import * as Yup from 'yup';

import useAddContacts from './add-contacts-hook';
import CopySalesTeam from './copy-sales-team';
import SelectSenderSignature from 'components/Select-Sender-Signature/select-sender-signature';
import { responsiveBreakpoints } from 'utils/utils';
import AddExternalContact from 'components/add-external-contact/add-external-contact';

export default function AddContacts({
  selectedAssociates,
  setSelectedAssociates,
  selectedSender,
  setSelectedSender,
  selectedSignature,
  setSelectedSignature,
  showComponents,
  setMessageAndSubject,
  selectedExternalContacts,
  setSelectedExternalContacts,
}) {
  const { user } = useAddContacts({
    selectedAssociates,
    setSelectedAssociates,
  });
  const { mobile } = responsiveBreakpoints();
  return (
    <Grid container sx={{ width: '100%' }}>
      {/* Add contacts grid */}
      {showComponents.addContactsComponent ? (
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            padding: '5px 10px',
          }}
        >
          <AddExternalContact
            selectedItems={selectedExternalContacts}
            setSelectedItems={setSelectedExternalContacts}
          />
        </Grid>
      ) : null}
      {showComponents.copyAssociateComponent ? (
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            padding: mobile ? '5px 0px' : '5px 10px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            variant='body2'
            sx={{ fontWeight: '900', fontSize: '14px', marginBottom: '5px' }}
          >
            Copy Sales Team
          </Typography>
          <Stack sx={{ flex: 1 }}>
            {user?.roleId === '3' ? null : (
              // <CopyAssociate
              //   selectedAssociates={selectedAssociates}
              //   setSelectedAssociates={setSelectedAssociates}
              //   sx={{ height: '267px' }}
              // />
              <CopySalesTeam
                selectedAssociates={selectedAssociates}
                setSelectedAssociates={setSelectedAssociates}
                sx={{
                  height: '307px',
                  border: '1px solid #CDCCD6',
                }}
              />
            )}
          </Stack>
        </Grid>
      ) : null}
      {showComponents?.senderSignatureComponent ? (
        <Grid item xs={12} sm={6}>
          <Stack spacing={2}>
            <Typography variant='h2' sx={{ fontWeight: '900' }}>
              Select Sender and Signature
            </Typography>
            <SelectSenderSignature
              selectedSender={selectedSender}
              setSelectedSender={setSelectedSender}
              selectedSignature={selectedSignature}
              setSelectedSignature={setSelectedSignature}
              setMessageAndSubject={setMessageAndSubject}
            />
          </Stack>
        </Grid>
      ) : null}
    </Grid>
  );
}
AddContacts.propTypes = {
  selectedAssociates: PropTypes.array.isRequired,
  setSelectedAssociates: PropTypes.func.isRequired,
  selectedSender: PropTypes.string,
  setSelectedSender: PropTypes.func,
  selectedSignature: PropTypes.string,
  setSelectedSignature: PropTypes.func,
  showComponents: PropTypes.object,
  setMessageAndSubject: PropTypes.func,
  selectedExternalContacts: PropTypes.array,
  setSelectedExternalContacts: PropTypes.func,
};
AddContacts.defaultProps = {
  selectedAssociates: [],
  setSelectedAssociates: () => {},
  selectedSender: '',
  setSelectedSender: () => {},
  selectedSignature: '',
  setSelectedSignature: () => {},
  showComponents: {},
  setMessageAndSubject: () => {},
  selectedExternalContacts: [],
  setSelectedExternalContacts: () => {},
};
