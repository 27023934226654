const styles = {
  container: {
    padding: '24px',
    borderRadius: '8px',
    gap: 2.5,
  },

  sectionHeader: {
    paddingBottom: '4px',
    fontSize: '12px',
    fontWeight: 400,
  },

  chip: {
    marginBottom: '5px',
    fontSize: '12px',
    fontWeight: '500',
    height: '30px',
    backgroundColor: '#fff',
    borderColor: 'var(--secondary-border-color)',
  },

  recipientsContainer: {
    display: 'inline-block',
    maxHeight: '70px',
    overflowY: 'auto',
    minWidth: '450px',
    flex: 1,
    scrollbarWidth: 'thin',
  },

  attachemntsValueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: '8px',
    columnGap: '10px',
  },

  attachIcon: {
    transform: 'scaleX(-1) rotate(-45deg)',
    color: '#1189F0',
    width: 'auto',
    height: '18px',
  },

  photoIcon: {
    color: '#1189F0',
    width: 'auto',
    height: '18px',
  },
};

export default styles;
