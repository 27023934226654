import { useState, useEffect } from 'react';
import { api } from 'api';
import { useQuery, useMutation } from 'react-query';
export const useSelectSenderSignature = (
  setSelectedSender,
  setSelectedSignature
) => {
  let page = 0;
  let searchTerm = '';
  let sortBy = 'ASC';
  async function getEmailListing() {
    const res = await api.get(
      `/userEmailListing?page=${page}&search=${searchTerm}&sortBy=${sortBy}&limit=10`
    );
    if (res.data.status) return res.data;
  }
  const emailListingQuery = useQuery(
    [page, searchTerm, sortBy],
    getEmailListing,
    {
      onSuccess: (data) => {
        setSelectedSender({
          label: data?.sender[0].name,
          value: data?.sender[0].id,
        });
        setSelectedSignature({
          label: data?.signature[0].name,
          value: data?.signature[0].id,
        });
      },
    }
  );

  return {
    emailListingQuery,
  };
};
