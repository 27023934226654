import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQueryClient } from 'react-query';
import {
  activeDraftSuggestionSelector,
  loggedInUserSelector,
} from '../redux/selectors';
import isEmpty from 'lodash/isEmpty';

import {
  removeGridItemToActiveDraft,
  updateItemSingleSuggestion,
  addProductAndQuantityToDraft,
  updateProductQuantityModalCall,
  fetchProductDetailsById,
} from 'pages/catalog/action';
import { showSnackbar } from 'components/snackbar/actions';
import { useUpdateURLParams } from 'common-hooks/update-query-params-hooks';

export function useAddProductsModal() {
  const { updateURLParams } = useUpdateURLParams();
  // functions and states from catalog hook files
  const [productModalFlag, setProductModalFlag] = useState(false);
  const [singleProductDetails, setSingleProductDetails] = React.useState({});
  const [cartActionLoader, setCartActionLoader] = React.useState({});
  const [suggestionActionLoader, setSuggestionActionLoader] = React.useState(
    {}
  );
  const [cartSuggestionFlags, setCartSuggestionFlags] = useState({
    cart: {},
    suggestion: {},
  });

  const activeDraftSuggestion = useSelector(activeDraftSuggestionSelector);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const cartFlag =
    (singleProductDetails.is_worksheet_item &&
      cartSuggestionFlags.cart[singleProductDetails.id] === undefined) ||
    cartSuggestionFlags.cart[singleProductDetails.id]
      ? true
      : false;

  const suggestionFlag =
    (singleProductDetails.is_suggestion_item &&
      cartSuggestionFlags.suggestion[singleProductDetails.id] === undefined) ||
    cartSuggestionFlags.suggestion[singleProductDetails.id]
      ? true
      : false;

  useEffect(() => {
    if (!isEmpty(singleProductDetails)) {
      setProductModalFlag(true);
    }
  }, [singleProductDetails]);

  // functions and states from catalog hook files

  // remove draft function
  const removeGridProductToDraft = async (payload, action = '') => {
    const updatedCartLoader = { ...cartActionLoader };
    updatedCartLoader[payload.item_id] = true;
    setCartActionLoader({ ...updatedCartLoader });

    const updatedPayload = {
      ...payload,
      worksheet_collection_id: activeDraftSuggestion.draft.id,
    };
    if (!isEmpty(action)) {
      updatedPayload.action = action;
    }
    const data = await dispatch(removeGridItemToActiveDraft(updatedPayload));
    if (data && data.status) {
      updateURLParams({ resetCatalog: true });
      // productListingQuery.refetch();
      const updatedCart = { ...cartSuggestionFlags };
      updatedCart.cart[payload.item_id] = false;
      setCartSuggestionFlags({ ...cartSuggestionFlags });
    } else {
      //
    }
    updatedCartLoader[payload.item_id] = false;
    setCartActionLoader({ ...updatedCartLoader });
  };

  //modify single actionLoader
  const modifyItemSingleSuggestion = async (payload) => {
    if (!activeDraftSuggestion.suggestion.id) {
      dispatch(
        showSnackbar({
          snackbarMessage:
            'No suggestion selected. Please select or create a suggestion',
          type: 'error',
          snackbarAlign: {
            vertical: 'top',
            horizontal: 'center',
          },
        })
      );
      return;
    }
    const updatedSuggestLoader = { ...suggestionActionLoader };
    updatedSuggestLoader[payload.productId] = true;
    setSuggestionActionLoader({ ...updatedSuggestLoader });

    const updatedPayload = {
      ...payload,
      lineSheetId: activeDraftSuggestion.suggestion.id,
    };

    const data = await dispatch(
      updateItemSingleSuggestion(updatedPayload, queryClient)
    );
    if (data && data.message === 'Success') {
      const updatedCart = { ...cartSuggestionFlags };
      updatedCart.suggestion[payload.productId] =
        updatedPayload.isSuggest === 0 ? false : true;
      setCartSuggestionFlags({ ...updatedCart });
    } else {
      //
    }
    updatedSuggestLoader[payload.productId] = false;
    setSuggestionActionLoader({ ...updatedSuggestLoader });
  };
  // add products from modal
  const addProductFromModal = async (payload) => {
    const updatedCartLoader = { ...cartActionLoader };
    updatedCartLoader[payload.item_id] = true;
    setCartActionLoader({ ...updatedCartLoader });
    const updatedPayload = { ...payload };

    let currentPath = window.location.pathname.split('/');
    updatedPayload.worksheet_collection_id = currentPath.some(
      (x) => x === 'order-payment'
    )
      ? parseInt(currentPath[2])
      : parseInt(activeDraftSuggestion.draft.id);

    const res = await dispatch(
      addProductAndQuantityToDraft({ ...updatedPayload })
    );
    if (res.data && res.data.status) {
      updateURLParams({ resetCatalog: true });
      // productListingQuery.refetch();
      const updatedCart = { ...cartSuggestionFlags };
      updatedCart.cart[payload.item_id] = true;
      setCartSuggestionFlags({ ...cartSuggestionFlags });
    }
    updatedCartLoader[payload.item_id] = false;
    setCartActionLoader({ ...updatedCartLoader });
  };
  // udate products from modal
  const updateProductFromModal = async (payload, draftOrder) => {
    const updatedCartLoader = { ...cartActionLoader };
    updatedCartLoader[payload.item_id] = true;
    setCartActionLoader({ ...updatedCartLoader });

    const updatedPayload = {
      ...payload,
      action: 'update',
      // type: 'add_item_to_worksheet',
    };
    let currentPath = window.location.pathname.split('/');
    updatedPayload.worksheet_collection_id = currentPath.some(
      (x) => x === 'order-payment'
    )
      ? parseInt(currentPath[2])
      : activeDraftSuggestion.draft.id;
    /*updatedPayload.worksheet_collection_id = parseInt(
      activeDraftSuggestion.draft.id
    );*/

    const res = await dispatch(
      updateProductQuantityModalCall({ ...updatedPayload })
    );
    if (res.data && res.data.status) {
      const updatedCart = { ...cartSuggestionFlags };
      updatedCart.cart[payload.item_id] = true;
      setCartSuggestionFlags({ ...cartSuggestionFlags });
      updateURLParams({ resetCatalog: true });
      // productListingQuery.refetch();
    }
    updatedCartLoader[payload.item_id] = false;
    setCartActionLoader({ ...updatedCartLoader });
  };
  //get products Modal Details
  const getProductModalDetails = async (id) => {
    let currentPath = window.location.pathname.split('/');
    const wsId = currentPath.some((x) => x === 'order-payment')
      ? parseInt(currentPath[2])
      : parseInt(activeDraftSuggestion.draft.id);
    const response = await dispatch(fetchProductDetailsById(id, wsId));
    if (response.data.status) {
      setSingleProductDetails(response.data);
    }
  };
  return {
    cartFlag,
    suggestionFlag,
    productModalFlag,
    singleProductDetails,
    setProductModalFlag,
    removeGridProductToDraft,
    cartActionLoader,
    modifyItemSingleSuggestion,
    suggestionActionLoader,
    addProductFromModal,
    updateProductFromModal,
    cartSuggestionFlags,
    getProductModalDetails,
    activeDraftSuggestion,
  };
}
