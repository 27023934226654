import { useState, useEffect } from 'react';
import { responsiveBreakpoints } from 'utils/utils';

export const useTiltScreen = () => {
  const [showMessage, setShowMessage] = useState(false);
  const { mobile, tablet } = responsiveBreakpoints();

  useEffect(() => {
    if (mobile || tablet) {
      setShowMessage(window.innerWidth < window.innerHeight);
    }
  }, [mobile, tablet]);

  const updateOrientation = () => {
    setShowMessage(window.innerWidth < window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', updateOrientation);
    return () => {
      window.removeEventListener('resize', updateOrientation);
    };
  }, []);

  return {
    showMessage,
    setShowMessage,
  };
};
