import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Backdrop from '@mui/material/Backdrop';
import IconButton from '@mui/material/IconButton';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EditRoundedIcon from 'assets/icons/edit-rounded-icon';
import styles from './styles';

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => styles.customTooltip);

const EditButtonTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => styles.editButtonTooltip);

function CustomButton({ button, onSaveButton }) {
  const [showEditAction, setShowEditAction] = useState(false);
  const [open, setOpen] = useState(false);

  const validationSchema = Yup.object().shape({
    link: Yup.string()
      .required('Please enter a link.')
      .matches(
        /^(https?:\/\/)?([\w\\-]+\.)+[a-z]{2,6}(\/[\w\-./?%&=]*)?$/i,
        'Invalid URL. Please enter a valid link.'
      ),
    name: Yup.string().required('Button name is required.'),
  });

  const formik = useFormik({
    initialValues: {
      link: button?.link || '',
      name: button?.name || '',
    },
    validationSchema,
    onSubmit: (values) => {
      onSaveButton({ ...values, name: values.name.toUpperCase() });
      setOpen(false);
    },
  });

  const handleButtonClick = (event) => {
    event.stopPropagation();
    setShowEditAction((prev) => !prev);
    formik.setErrors({ link: null, name: null });
  };

  const handleCancel = () => {
    setOpen(false);
    formik.resetForm();
  };

  const onEdit = () => {
    setOpen(true);
    setShowEditAction(false);
  };

  const onClickOutside = () => {
    setOpen(false);
    setShowEditAction(false);
  };

  return (
    <>
      <Backdrop
        open={open || showEditAction}
        onClick={onClickOutside}
        sx={{
          zIndex: 1500,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
      />
      <EditButtonTooltip
        sx={{
          [`&[data-popper-placement*="top"] .MuiTooltip-tooltip`]: {
            margin: '0 !important',
          },
        }}
        open={showEditAction}
        arrow
        placement='top-end'
        disableFocusListener
        disableHoverListener
        disableTouchListener
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [90, 4],
              },
            },
          ],
        }}
        title={
          <Stack>
            <IconButton onClick={onEdit}>
              <EditRoundedIcon fill='#fff' />
            </IconButton>
          </Stack>
        }
      ></EditButtonTooltip>
      <CustomTooltip
        open={open}
        onClose={() => setOpen(false)}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={
          <form onSubmit={formik.handleSubmit}>
            <Stack sx={{ gap: '10px' }}>
              <Stack>
                <InputLabel required sx={{ fontSize: '12px', fontWeight: 400 }}>
                  Enter or Paste a Link
                </InputLabel>
                <TextField
                  id='link'
                  name='link'
                  fullWidth
                  value={formik.values.link}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.link && Boolean(formik.errors.link)}
                  helperText={formik.touched.link && formik.errors.link}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() => formik.setFieldValue('link', '')}
                      >
                        <CancelOutlinedIcon
                          sx={{
                            fontSize: '16px',
                            color: '#292D32',
                          }}
                        />
                      </IconButton>
                    ),
                  }}
                  sx={{
                    ['& .MuiFormHelperText-root']: {
                      margin: 0,
                    },
                  }}
                />
              </Stack>
              <Stack>
                <InputLabel required sx={{ fontSize: '12px', fontWeight: 400 }}>
                  Enter Button Name
                </InputLabel>
                <TextField
                  id='name'
                  name='name'
                  fullWidth
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  sx={{
                    ['& .MuiFormHelperText-root']: {
                      margin: 0,
                    },
                  }}
                />
              </Stack>
              <Stack direction='row' gap={1} justifyContent='end'>
                <Button variant='outlined' size='small' onClick={handleCancel}>
                  Cancel
                </Button>
                <Button type='submit' variant='contained' size='small'>
                  Save
                </Button>
              </Stack>
            </Stack>
          </form>
        }
      >
        <Button
          variant='contained'
          onClick={handleButtonClick}
          sx={{ textTransform: 'uppercase' }}
        >
          {button?.name || 'Your Title Here'}
        </Button>
      </CustomTooltip>
    </>
  );
}

CustomButton.propTypes = {
  button: PropTypes.object,
  onSaveButton: PropTypes.func.isRequired,
};

export default React.memo(CustomButton);
