/* eslint-disable react/no-unknown-property */
import React from 'react';
import PropTypes from 'prop-types';

export default function MyAttacheIconM({
  fill,
  active,
  width,
  height,
  stroke,
}) {
  return (
    <svg
      id='my-attache'
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='25'
      viewBox='0 0 25 25'
    >
      <g id='home-2'>
        <g
          id='Vector'
          fill='none'
          stroke-linecap='round'
          stroke-linejoin='round'
          opacity='0'
        >
          <path d='M0,0H25V25H0Z' stroke='none' />
          <path
            d='M 1 1 L 1 24 L 24 24 L 24 1 L 1 1 M 0 0 L 25 0 L 25 25 L 0 25 L 0 0 Z'
            stroke='none'
            fill='#fff'
          />
        </g>
      </g>
      <g
        id='vuesax_linear_direct'
        data-name='vuesax/linear/direct'
        transform='translate(-428 -185)'
      >
        <g id='direct'>
          <path
            id='Vector-2'
            data-name='Vector'
            d='M7,20h6c5,0,7-2,7-7V7c0-5-2-7-7-7H7C2,0,0,2,0,7v6C0,18,2,20,7,20Z'
            transform='translate(430 188)'
            fill='none'
            stroke='#fff'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='1.2'
          />
          <path
            id='Vector-3'
            data-name='Vector'
            d='M0,.01H3.76A2,2,0,0,1,5.55,1.12l.89,1.79a1.919,1.919,0,0,0,1.8,1.1h3.53A2,2,0,0,0,13.56,2.9l.89-1.79A2,2,0,0,1,16.24,0h3.74'
            transform='translate(430 198.99)'
            fill='none'
            stroke='#fff'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='1.2'
          />
          <path
            id='Vector-4'
            data-name='Vector'
            d='M0,0H3.33'
            transform='translate(438.34 193)'
            fill='none'
            stroke='#fff'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='1.2'
          />
          <path
            id='Vector-5'
            data-name='Vector'
            d='M0,0H5'
            transform='translate(437.5 196)'
            fill='none'
            stroke='#fff'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='1.2'
          />
          <g
            id='Vector-6'
            data-name='Vector'
            transform='translate(428 186)'
            fill='none'
            stroke-linecap='round'
            stroke-linejoin='round'
            opacity='0'
          >
            <path d='M0,0H24V24H0Z' stroke='none' />
            <path
              d='M 1 1 L 1 23 L 23 23 L 23 1 L 1 1 M 0 0 L 24 0 L 24 24 L 0 24 L 0 0 Z'
              stroke='none'
              fill='#fff'
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

MyAttacheIconM.propTypes = {
  fill: PropTypes.string,
  active: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  stroke: PropTypes.string,
};

MyAttacheIconM.defaultProps = {
  fill: 'var(--primary-icon-color)',
  active: 'false',
  width: '34px',
  height: '34px',
  stroke: '#292d32',
};
