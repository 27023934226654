import React from 'react';
import PropTypes from 'prop-types';

export default function SuccessIcon({ fill, sx }) {
  return (
    <svg
      id='tick'
      xmlns='http://www.w3.org/2000/svg'
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill={fill}
      style={sx}
    >
      <g transform='translate(11.546 15.503)'>
        <g>
          <path
            d='M147.6,165.971a1.945,1.945,0,0,0-2.751-.073L132.423,177.68,126.5,171.6a1.946,1.946,0,0,0-2.788,2.715l7.265,7.459a1.947,1.947,0,0,0,2.733.054l13.816-13.1A1.946,1.946,0,0,0,147.6,165.971Z'
            transform='translate(-123.158 -165.364)'
          />
        </g>
      </g>
      <g>
        <g>
          <path d='M24,0A24,24,0,1,0,48,24,24.027,24.027,0,0,0,24,0Zm0,44.108A20.108,20.108,0,1,1,44.108,24,20.131,20.131,0,0,1,24,44.108Z' />
        </g>
      </g>
    </svg>
  );
}

SuccessIcon.propTypes = {
  fill: PropTypes.string,
  sx: PropTypes.object,
};

SuccessIcon.defaultProps = {
  fill: '#32cd32',
  sx: {},
};
