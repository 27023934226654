import React from 'react';
import ErrorFallback from './error-fallback';
import PropTypes from 'prop-types';
import { api, apiEndPoints } from 'api';
import SiteStatusPage from 'pages/site-status-page/site-status-page';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.error('Error', error);
    console.warn('component info', info.componentStack);
    api.post(apiEndPoints.errorEmail, {
      website_url: window.location.href,
      user_info: `username: ${this.props?.loggedInUser}`,
      error_message: error?.stack ?? error.message,
      component_info: info?.componentStack,
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      // return <ErrorFallback />;
      return <SiteStatusPage somethingWentWrong={true} />;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any.isRequired,
  loggedInUser: PropTypes.string.isRequired,
};
