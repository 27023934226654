import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { login, LOGIN_REQUEST, tncRequest, TNC_REQUEST } from '../actions';
import { PrivateScreen, PublicScreen } from 'routes/screen-names';
import LocalStorageManager from 'utils/local-storage-manager';
import { STAY_LOGGED_IN } from 'constants';
import {
  actionLoaderSelector,
  selectClientPublicInfoSelector,
} from 'redux/selectors';
import SessionStorageManager from '../../../utils/session-storage-manager';
import { getCustomerDetails } from 'pages/app/actions';

export const useLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isStayLoggedIn, setIsStayLoggedIn] = useState(
    !!LocalStorageManager.getLocalStorage(STAY_LOGGED_IN)
  );
  const [apiErrorMessage, setErrorApiMessage] = useState();
  const [fieldsError, setFieldsError] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [tnc, setTnc] = useState('');
  const [isShowTnc, setIsShowTnc] = useState(false);
  const isLoginLoading = useSelector(actionLoaderSelector(LOGIN_REQUEST));
  const isTncLoading = useSelector(actionLoaderSelector(TNC_REQUEST));
  const clientPublicInfo = useSelector(selectClientPublicInfoSelector);
  const [acceptTnC, setAcceptTnC] = useState(false);

  useEffect(() => {
    LocalStorageManager.setLocalStorage(STAY_LOGGED_IN, isStayLoggedIn);
  }, [isStayLoggedIn]);

  useEffect(() => {
    const apiError = SessionStorageManager.getSessionStorage('apiError');
    const linkExpiredError =
      SessionStorageManager.getSessionStorage('linkExpired');
    if (apiError != '') {
      setErrorApiMessage(apiError);
      SessionStorageManager.removeSessionStorage('apiError');
    }
    if (linkExpiredError != '') {
      setErrorApiMessage(linkExpiredError);
      SessionStorageManager.removeSessionStorage('linkExpired');
    }
  }, []);

  const handleLoginBtnClick = async (values) => {
    try {
      const payload = {
        username: values.username,
        password: values.password,
      };
      const { status, errors, message } = await dispatch(login(payload));
      if (status) {
        const clientInfoStatus = dispatch(getCustomerDetails());
        if (clientInfoStatus) navigate(PrivateScreen.home, { replace: true });
      } else {
        if (errors) {
          const message = errors.map((error) => {
            return Object.values(error);
          });
          setFieldsError(message);
        } else {
          setErrorApiMessage(message);
        }
      }
    } catch (error) {
      // TODO: handle error
    }
  };

  const handleForgotUsernameAndPswdClick = () => {
    navigate(PublicScreen.recover);
  };

  const handleStayLoggedInChange = () => {
    setIsStayLoggedIn(!isStayLoggedIn);
  };

  const handleShowPasswordClick = () => {
    setShowPassword(!showPassword);
  };

  const handleCloseError = () => {
    setFieldsError([]);
    setErrorApiMessage(null);
    sessionStorage.removeItem('linkExpired');
  };

  const handleTermsAndConditionClick = async () => {
    if (clientPublicInfo) {
      setIsShowTnc(true);
      const tnc = await dispatch(tncRequest(clientPublicInfo?.id));
      if (tnc) {
        setTnc(tnc);
      }
    }
  };
  const handleAcceptTnC = () => {
    setAcceptTnC(!acceptTnC);
  };

  return {
    handleLoginBtnClick,
    handleForgotUsernameAndPswdClick,
    handleStayLoggedInChange,
    handleShowPasswordClick,
    handleCloseError,
    isStayLoggedIn,
    isLoginLoading,
    apiErrorMessage,
    fieldsError,
    clientPublicInfo,
    showPassword,
    isShowTnc,
    setIsShowTnc,
    tnc,
    handleTermsAndConditionClick,
    isTncLoading,
    acceptTnC,
    handleAcceptTnC,
  };
};
