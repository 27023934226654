/* eslint-disable react/no-unknown-property */
import React from 'react';
import PropTypes from 'prop-types';

export default function MyCustomersIconM({
  fill,
  active,
  width,
  height,
  stroke,
}) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
    >
      <g id='my-customers' transform='translate(-620 -252)'>
        <g id='people'>
          <g id='Group'>
            <path
              id='Vector'
              d='M2.67,5.16a.605.605,0,0,0-.19,0,2.585,2.585,0,1,1,.19,0Z'
              transform='translate(635.33 254)'
              fill='none'
              stroke='#fff'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='1.2'
            />
            <path
              id='Vector-2'
              data-name='Vector'
              d='M.03,4.94a5.635,5.635,0,0,0,3.94-.72A1.911,1.911,0,0,0,3.97.8,5.67,5.67,0,0,0,0,.09'
              transform='translate(636.94 261.5)'
              fill='none'
              stroke='#fff'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='1.2'
            />
          </g>
          <g id='Group-2' data-name='Group'>
            <path
              id='Vector-3'
              data-name='Vector'
              d='M2.49,5.16a.605.605,0,0,1,.19,0,2.585,2.585,0,1,0-.19,0Z'
              transform='translate(623.48 254)'
              fill='none'
              stroke='#fff'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='1.2'
            />
            <path
              id='Vector-4'
              data-name='Vector'
              d='M5,4.94a5.635,5.635,0,0,1-3.94-.72,1.911,1.911,0,0,1,0-3.42A5.67,5.67,0,0,1,5.027.09'
              transform='translate(622.003 261.5)'
              fill='none'
              stroke='#fff'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='1.2'
            />
          </g>
          <g id='Group-3' data-name='Group'>
            <path
              id='Vector-5'
              data-name='Vector'
              d='M2.67,5.16a.605.605,0,0,0-.19,0,2.585,2.585,0,1,1,.19,0Z'
              transform='translate(629.33 261.47)'
              fill='none'
              stroke='#fff'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='1.2'
            />
            <path
              id='Vector-6'
              data-name='Vector'
              d='M1.058.795a1.911,1.911,0,0,0,0,3.42,5.677,5.677,0,0,0,5.82,0,1.911,1.911,0,0,0,0-3.42A5.723,5.723,0,0,0,1.058.795Z'
              transform='translate(628.032 268.985)'
              fill='none'
              stroke='#fff'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='1.2'
            />
          </g>
          <path
            id='Vector-7'
            data-name='Vector'
            d='M0,0H24V24H0Z'
            transform='translate(620 252)'
            fill='none'
            opacity='0'
          />
        </g>
      </g>
    </svg>
  );
}

MyCustomersIconM.propTypes = {
  fill: PropTypes.string,
  active: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  stroke: PropTypes.string,
};

MyCustomersIconM.defaultProps = {
  fill: 'var(--primary-icon-color)',
  active: 'false',
  width: '34px',
  height: '34px',
  stroke: '#292d32',
};
