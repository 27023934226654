import * as Yup from 'yup';

export const addBuyerSchema = Yup.object().shape({
  name: Yup.string().required('Name is a required field.'),
  username: Yup.string().required('Username is a required field.'),
  country: Yup.string().required('Country is a required field.'),
  email: Yup.string()
    .email('Email Address is invalid')
    .required('Email Address is required'),
  businessPhone: Yup.string().matches(
    '^[0-9+(0-9) *)#.\\-]*$',
    'Bussiness Phone is an invalid format'
  ),
  businessFax: Yup.string().matches(
    '^[0-9+(0-9) *)#.\\-]*$',
    'Bussiness Fax is an invalid format'
  ),
  mobilePhone: Yup.string().matches(
    '^[0-9+(0-9) *)#.\\-]*$',
    'Mobile Phone is an invalid format'
  ),
});
