import { React } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
// import devlopmentImg from '../assets/images/development.png';
import { Button, Typography, Stack, Link } from '@mui/material';
import PageNotFound from 'assets/icons/page-not-found';
import DisconnectingPowerCord from 'assets/icons/disconnecting-power-cord';
import SiteMaintainance from 'assets/icons/site-maintainance-icon';
import myatoncelogo from '../../assets/images/myatoncelogo.png';

const SiteStatusPage = ({
  isNotFoundPage,
  somethingWentWrong,
  maintenanceMode,
}) => {
  const navigate = useNavigate();
  let primaryText = '';
  let subText = '';
  if (isNotFoundPage) {
    primaryText = 'Page Not Found';
  }
  if (somethingWentWrong) {
    primaryText = 'Whoops, Something went wrong';
    subText = 'Please either refresh the page or return home to try again.';
  }
  if (maintenanceMode) {
    primaryText = 'Website under maintenance';
    subText =
      'This website is currently undergoing scheduled maintenance. We should be back shortly.';
  }
  return (
    <Box
      width='100%'
      height={maintenanceMode ? '100vh' : '550px'}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: '506px',
          rowGap: '28px',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {maintenanceMode ? (
          <img
            src={myatoncelogo}
            style={{
              objectFit: 'contain',
              width: '309px',
              height: '90px',
            }}
          ></img>
        ) : null}
        {isNotFoundPage ? (
          <PageNotFound />
        ) : somethingWentWrong ? (
          <DisconnectingPowerCord />
        ) : (
          <SiteMaintainance />
        )}
        <Stack spacing={isNotFoundPage ? 1 : 2.7} sx={{ textAlign: 'center' }}>
          <Stack spacing={1}>
            <Typography variant='h1' sx={{ fontSize: '25px' }}>
              {primaryText}
            </Typography>
            {subText ? (
              <Typography
                variant='body1'
                sx={{ fontSize: '18px', color: '#6A6A6A' }}
              >
                {subText}
              </Typography>
            ) : null}
          </Stack>
          {maintenanceMode ? (
            <Stack sx={{ alignItems: 'center' }}>
              <Typography variant='body1' sx={{ fontSize: '18px' }}>
                Contact Support:
              </Typography>
              <Link
                sx={{
                  fontSize: '18px',
                  width: 'fit-content',
                }}
                href={`mailto:support@myatonce.com`}
              >
                support@myatonce.com
              </Link>
            </Stack>
          ) : (
            <Link
              onClick={() => {
                window.location.href = '/';
              }}
              sx={{
                fontSize: '18px',
                color: '#0091DE',
                textDecorationColor: '#0091DE',
                cursor: 'pointer',
              }}
            >
              Go Back to Home
            </Link>
          )}
        </Stack>
      </Box>
    </Box>
  );
};
SiteStatusPage.propTypes = {
  isNotFoundPage: PropTypes.bool,
  somethingWentWrong: PropTypes.bool,
  maintenanceMode: PropTypes.bool,
};

SiteStatusPage.defaultProps = {
  isNotFoundPage: false,
  somethingWentWrong: false,
  maintenanceMode: false,
};
export default SiteStatusPage;
