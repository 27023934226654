import {
  api,
  apiEndPoints,
  addActionLoader,
  removeActionLoader,
} from '../../api';
import { setBearerToken } from 'utils/auth';
import { showSnackbar } from 'components/snackbar/actions';
import { snackbarTypes } from 'constants';

const namespace = 'pages/auth/';

export const SET_API_ERROR = `${namespace}SET_API_ERROR`;
export const SET_STAY_LOGGED_IN = `${namespace}SET_STAY_LOGGED_IN`;
export const LOGIN_REQUEST = `${namespace}LOGIN_REQUEST`;
export const RESET_PASSWORD_REQUEST = `${namespace}RESET_PASSWORD_REQUEST`;
export const FORGET_USERNAME_REQUEST = `${namespace}FORGET_USERNAME_REQUEST`;
export const NEW_ACCOUNT_REQUEST = `${namespace}NEW_ACCOUNT_REQUEST`;
export const TNC_REQUEST = `${namespace}TNC_REQUEST`;

const setApiError = (error) => ({
  type: SET_API_ERROR,
  payload: error,
});

export const setStayLoggedIn = (payload) => ({
  type: SET_STAY_LOGGED_IN,
  payload,
});

export const login = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(LOGIN_REQUEST));
    const response = await api.post(apiEndPoints.login, payload);
    const { data, data: { status = false, token = '' } = {} } = response;
    if (status) {
      setBearerToken(token);
    }
    return data;
  } catch (error) {
    dispatch(setApiError(error));
    const { response: { data = {} } = {} } = error;
    return data;
  } finally {
    dispatch(removeActionLoader(LOGIN_REQUEST));
  }
};

export const resetPassword = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(RESET_PASSWORD_REQUEST));
    const response = await api.post(apiEndPoints.resetPassword, payload);
    const { data } = response;
    return data;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(RESET_PASSWORD_REQUEST));
  }
};

export const recoverUsername = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(FORGET_USERNAME_REQUEST));
    const response = await api.post(apiEndPoints.forgotUsername, payload);
    const { data } = response;
    return data;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(FORGET_USERNAME_REQUEST));
  }
};

export const newAccountRequest = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(NEW_ACCOUNT_REQUEST));
    const response = await api.post(apiEndPoints.newRequest, payload);
    const { data, data: { token = '', status = false } = {} } = response;
    if (status && token != '') {
      setBearerToken(token);
      return {
        token,
        status,
      };
    }
    if (!status) {
      dispatch(
        showSnackbar({
          snackbarMessage:
            'Something went wrong, Please check your field and try again.',
          type: snackbarTypes.ERROR,
        })
      );
    }
    return data;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(NEW_ACCOUNT_REQUEST));
  }
};

export const tncRequest = (id) => async (dispatch) => {
  try {
    dispatch(addActionLoader(TNC_REQUEST));
    const response = await api.get(apiEndPoints.termsConditions(id));
    const { data: { termsCondition = '', success = false } = {} } = response;
    if (success) {
      return termsCondition;
    }
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(TNC_REQUEST));
  }
};
