export const addNewRetailerStyles = {
  accountRequestInfo: {
    container: {
      backgroundColor: '#F2F9FF',
      border: '1px solid #B7DDFF',
      borderRadius: '6px',
      width: '100%',
    },
    header: { backgroundColor: '#DFF0FF', padding: '4px 16px' },
    section: {
      overflow: 'hidden',
      flex: 0.4,
      minWidth: '200px',
    },
    sectionItems: {
      fontWeight: 500,
    },
  },
};
