import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { CollectionStyles as styles } from '../styles';
import { Typography } from '@mui/material';

const DepartmentCollection = ({
  loading,
  collections,
  handleSelectCollection,
  handleCollectionClick,
  selectedCollection,
}) => {
  return (
    <Box sx={styles.container}>
      {loading ? (
        <Box display='flex' alignItems='center' height='100%'>
          <CircularProgress sx={styles.loader} />
        </Box>
      ) : (
        collections &&
        collections?.map((collection, index) =>
          collection?.showCollection ? (
            <Typography
              key={collection?.id}
              variant='body2'
              sx={[
                styles.megaMenuCollection,
                selectedCollection?.index == index && styles.selectedCollection,
              ]}
              onClick={(e) => {
                handleCollectionClick(collection);
              }}
              onMouseEnter={() =>
                handleSelectCollection({ ...collection, index })
              }
            >
              {collection.name}
            </Typography>
          ) : null
        )
      )}
    </Box>
  );
};

DepartmentCollection.propTypes = {
  loading: PropTypes.bool.isRequired,
  collections: PropTypes.array.isRequired,
  handleSelectCollection: PropTypes.func.isRequired,
  handleCollectionClick: PropTypes.func.isRequired,
  selectedCollection: PropTypes.object,
};

DepartmentCollection.defaultProps = {
  selectedCollection: {},
};

export default DepartmentCollection;
