import React from 'react';
import './RingingBell.css';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

const RingingBell = () => {
  return (
    <>
      {/*<NotificationsActiveIcon*/}
      {/*  color='primary'*/}
      {/*  fontSize='large'*/}
      {/*  sx={{ mr: 1 }}*/}
      {/*/>*/}
      <NotificationsActiveIcon color='primary' className='ringing-bell' />
      {/*<NotificationsActiveIcon color='primary' className='shake-effect' />*/}
      {/*<NotificationsActiveIcon className='shuffling-bell' />*/}
    </>
  );
};

export default RingingBell;
