export const actionLoaderSelector =
  (action) =>
  ({ actionLoaderReducer: { actionLoading = {} } = {} }) =>
    actionLoading[action];

export const selectSubDomainSelector = ({
  appReducer: { subDomain = '' } = {},
}) => subDomain;

export const selectCustomerDetailSelector = ({
  appReducer: { customerDetail = {} } = {},
}) => customerDetail;

export const selectClientPublicInfoSelector = ({
  appReducer: { clientPublicInfo = {} } = {},
}) => clientPublicInfo;

export const loggedInUserSelector = ({
  layoutReducer: { loggedInUserInfo = {} } = {},
}) => loggedInUserInfo;

export const customerSupportDataSelector = ({
  layoutReducer: { customerSupportData = {} } = {},
}) => customerSupportData;

export const userDefaultsSelector = ({ userDefaultData = {} }) =>
  userDefaultData;

export const activeDraftSuggestionSelector = ({
  userDefaultData: { activeDraftSuggestion = {} } = {},
}) => activeDraftSuggestion;

export const departmentListSelector = ({
  userDefaultData: { departmentList = {} } = {},
}) => departmentList;

export const selectClientOptionsSelector = ({
  appReducer: { clientOptions = {} } = {},
}) => clientOptions;

export const queryClientSelector = ({
  appReducer: { queryClient = {} } = {},
}) => queryClient;

export const welcomeBarDataSelector = ({
  appReducer: { welcomeBarData = {} } = {},
}) => welcomeBarData;

export const updatedSuggestionDetailSelector = ({
  userDefaultData: { updatedSuggestionDetails = {} } = {},
}) => updatedSuggestionDetails;
