export const notificationStyles = {
  container: { borderRadius: '5px', height: '600px', width: '439px' },
  headerContainer: {
    padding: '10px 25px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  notificationCardContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    padding: '13px 14px',
    borderBottom: '1px solid var(--secondary-border-color)',
    backgroundColor: 'var(--color-gray2)',
    cursor: 'pointer',
  },
  notificaitonViewd: {
    backgroundColor: 'var(--paper-background-color)',
  },
  message: { fontSize: '15px', color: 'var(--primary-font-color)' },
  dateAndAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  data: { fontSize: '13px', color: '#4d4964' },
  action: { height: '18px', fontSize: '12px' },
  emptyContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  emptyIcon: {
    backgroundColor: 'var(--paper-background-color)',
    borderRadius: '50%',
    padding: '24px 30px',
    marginBottom: '4px',
  },
  emptyMessage: { color: '#7e7e7e', marginBottom: '18px' },
};
