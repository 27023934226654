import React from 'react';
import PropTypes from 'prop-types';

export default function WatchTourIcon({ fill, sx }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24.043'
      height='19.671'
      viewBox='0 0 24.043 19.671'
    >
      <path
        id='Icon_material-ondemand-video'
        data-name='Icon material-ondemand-video'
        d='M23.357,4.5H3.686A2.178,2.178,0,0,0,1.5,6.686V19.8a2.185,2.185,0,0,0,2.186,2.186H9.15v2.186h8.743V21.986h5.464A2.183,2.183,0,0,0,25.532,19.8l.011-13.114A2.185,2.185,0,0,0,23.357,4.5Zm0,15.3H3.686V6.686H23.357Zm-5.464-6.557-7.65,4.371V8.871Z'
        transform='translate(-1.5 -4.5)'
        fill='#fff'
      />
    </svg>
  );
}

WatchTourIcon.propTypes = {
  fill: PropTypes.string,
  sx: PropTypes.object,
};

WatchTourIcon.defaultProps = {
  fill: '#fff',
  sx: {},
};
