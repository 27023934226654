import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import hexToRgb from 'utils/hexToRgb';
import contrast from 'utils/checkContrast';

export default function StatusChip({ label, color, bgColor, className }) {
  let backgroundColor = '';
  let textColor = '';
  const colorRgb = hexToRgb(color);
  backgroundColor = bgColor
    ? bgColor
    : `rgba(${colorRgb?.[0] ?? 0},${colorRgb?.[1] ?? 0},${
        colorRgb?.[2] ?? 0
      }, 0.2 )`;

  textColor = color;
  // if (contrastWithBlack > contrastWithWhite) {

  // } else {
  //   textColor = `rgba(${colorRgb?.[0] ?? 0},${colorRgb?.[1] ?? 0},${
  //     colorRgb?.[2] ?? 0
  //   }, 0.2 )`;

  //   backgroundColor = color;
  // }
  return (
    <Box
      className={className}
      sx={{
        minWidth: '100px',
        backgroundColor: backgroundColor,
        cursor: 'pointer',
        borderRadius: '4px',
        height: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: '5px',
        paddingRight: '5px',
      }}
    >
      <Typography
        variant='h2'
        sx={{
          fontSize: '12px',
          color: textColor,
          filter: 'brightness(70%)',
          whiteSpace: 'nowrap',
        }}
      >
        {label}
      </Typography>
    </Box>
  );
}

StatusChip.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  className: PropTypes.string,
};

StatusChip.defaultProps = {
  className: 'status-chip',
};
