import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PlaceHolderImage from 'assets/icons/image-loading.svg';
import ErrorImage from 'assets/icons/error-image.png';

const LazyImage = ({ imageUrl, styles }) => {
  const [imgSrc, setImgSrc] = useState(PlaceHolderImage || imageUrl);
  const [isError, setIsError] = useState(false);

  const onLoad = useCallback(() => {
    setImgSrc(imageUrl);
  }, [imageUrl]);

  const onError = useCallback(() => {
    setImgSrc(ErrorImage || PlaceHolderImage);
    setIsError(true);
  }, [ErrorImage, PlaceHolderImage]);

  useEffect(() => {
    const img = new Image();
    img.src = imageUrl;
    img.addEventListener('load', onLoad);
    img.addEventListener('error', onError);
    return () => {
      img.removeEventListener('load', onLoad);
      img.removeEventListener('error', onError);
    };
  }, [imageUrl, onLoad, onError]);

  return (
    <img
      style={{
        ...(isError
          ? {
              height: 'inherit',
              width: 'inherit',
              padding: '20%',
              borderRadius: '6px',
            }
          : {
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              position: 'absolute',
              borderRadius: '6px',
            }),
        ...styles,
      }}
      alt={imageUrl}
      src={imgSrc}
    />
  );
};

LazyImage.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  styles: PropTypes.object,
};

LazyImage.defaultProps = {
  styles: {},
};

export default LazyImage;
