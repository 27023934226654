export const fileLimit = 3;

export const selectBuyerViewByOptions = [
  {
    label: 'All',
    value: '99',
  },
  {
    label: 'Buyers who were sent invites',
    value: '0',
  },
  {
    label: 'Buyers who were not sent invites',
    value: '1',
  },
  {
    label: 'Users who havent logged in',
    value: '2',
  },
  {
    label: 'Buyers with empty email addresses',
    value: '3',
  },
  {
    label: 'Buyers with email addresses',
    value: '4',
  },
];
