import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';

import WatchTourIcon from 'assets/icons/watch-tour-icon';
import { useWatchTour } from './watch-tour-hook';
import WatchTourPopOver from './components/watch-tour-popup';
import { responsiveBreakpoints } from 'utils/utils';

const WatchTour = ({ setWatchTourCompleted, userInfo }) => {
  const {
    watchTourData,
    anchorEl,
    setAnchorEl,
    open,
    id,
    handleClose,
    handleClick,
    updateTourStatus,
    checked,
    setChecked,
  } = useWatchTour({ setWatchTourCompleted });
  const { mobile, tablet } = responsiveBreakpoints();

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: mobile || tablet ? '75px' : '30px',
        left: '35px',
        zIndex: '998',
      }}
      onMouseEnter={() => setChecked(true)}
      onMouseLeave={() => setChecked(false)}
    >
      <Button
        sx={{
          width: 'fit-content',
          height: 'fit-content',
          borderRadius: '39px',
          backgroundColor: 'var(--color-white)',
          boxShadow: '0px 3px 6px #00000029',
          '&:hover': {
            backgroundColor: 'var(--color-white)',
            boxShadow: '0px 3px 6px #00000029',
          },
        }}
        onClick={(e) => handleClick(e)}
      >
        <Collapse orientation='horizontal' in={checked} collapsedSize={50}>
          <Stack direction='row' spacing={1} height='100%' alignItems='center'>
            <IconButton
              sx={{
                width: '50px',
                height: '50px',
                borderRadius: '50%',
                backgroundColor: 'primary.main',
                '&:hover': {
                  backgroundColor: 'primary.main',
                },
              }}
            >
              <WatchTourIcon />
            </IconButton>
            <Typography
              variant='h3'
              sx={{
                backgroundColor: 'var(--color-white)',
                color: '#000000',
                whiteSpace: 'nowrap',
              }}
            >
              Watch Tour
            </Typography>
          </Stack>
        </Collapse>
      </Button>
      <WatchTourPopOver
        id={id}
        anchorEl={anchorEl}
        handleClose={handleClose}
        setWatchTourCompleted={setWatchTourCompleted}
        updateTourStatus={updateTourStatus}
        watchTourData={watchTourData}
        userInfo={userInfo}
        open={open}
        setAnchorEl={setAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        marginTop='-90px'
      />
    </Box>
  );
};
WatchTour.propTypes = {
  setWatchTourCompleted: PropTypes.func,
  userInfo: PropTypes.object,
};
WatchTour.defaultProps = {
  setWatchTourCompleted: () => {},
  userInfo: {},
};
export default WatchTour;
