import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import { clearSnackbarMessage } from './actions';
import { useDispatch } from 'react-redux';

const SnackBar = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const { snackbarMessage, snackbarType, snackbarAlign } = useSelector(
    ({ snackbarReducer }) => snackbarReducer
  );

  const handleClose = (key) => {
    closeSnackbar(key);
  };

  const action = (key) => (
    <CloseIcon onClick={() => handleClose(key)} sx={{ cursor: 'pointer' }} />
  );

  useEffect(() => {
    if (snackbarMessage) {
      enqueueSnackbar(snackbarMessage, {
        variant: snackbarType,
        anchorOrigin: snackbarAlign,
        autoHideDuration: 6000,
        action,
        preventDuplicate: false,
        onClick: () => {}, // Prevent closing on click
      });
      dispatch(clearSnackbarMessage());
    }
  }, [snackbarMessage, snackbarType, snackbarAlign]);

  return null;
};

export default SnackBar;
