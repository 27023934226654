import React from 'react';
import PropTypes from 'prop-types';

export default function CancelIcon({ fill, width, height, style }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 18 18'
      fill={fill}
      style={style}
    >
      <g transform='translate(0 -0.001)'>
        <g transform='translate(0 0.001)'>
          <path
            d='M9.994,9l7.8-7.8A.7.7,0,1,0,16.8.207L9,8.006,1.2.207A.7.7,0,1,0,.206,1.2L8.005,9l-7.8,7.8a.7.7,0,0,0,.994.994L9,10l7.8,7.8a.7.7,0,0,0,.994-.994Z'
            transform='translate(0 -0.001)'
          />
        </g>
      </g>
    </svg>
  );
}

CancelIcon.propTypes = {
  fill: PropTypes.string,
  style: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.string,
};

CancelIcon.defaultProps = {
  fill: 'var(--primary-icon-color)',
  style: {},
  width: '18px',
  height: '18px',
};
