import { useState, useEffect } from 'react';
import { api } from '../../../api';

export default function useCopySalesTeam() {
  const [salesReps, setSalesReps] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  //get sales reps
  async function getSalesReps() {
    const res = await api.get(
      `/getSalesRepAssociateBySalesRep?displayAssosciateTitle=true`
    );

    const result = await api.get(
      `/getSalesRepAssociateBySalesRep?displayAssosciateTitle=`
    );
    // setSalesReps(res.data);
    let data = {
      displayAssosciateTitle: false,
      displayAssosciateTitleData: res?.data /*[
        {
          title: 'Sales Rep',
          data: [
            { id: '1', name: 'Chris Ward', email: 'chrisWard@northwest.com' },
            {
              id: '2',
              name: 'Heather Scaglione',
              email: 'Heather.scaglione@thenortwest.com',
            },
            { id: '3', name: 'Dan Larner', email: 'Dan Larner' },
            { id: '3', name: 'Dan Larner', email: 'chrisWard@northwest.com' },
          ],
        },
        {
          title: 'Sales Associate',
          data: [{ id: '1', name: 'Thomas Pfeffer', email: 'Thomas Pfeffer' }],
        },
      ],*/,
      sortedData: result?.data?.data /*[
        {
          id: '4497',
          name: 'John Smith',
          email: 'john@myatonce.com',
        },
        {
          id: '7119',
          name: 'Zeeshan Mulla',
          email: 'zeeshan@myatonce.com',
        },
        {
          id: '8200',
          name: 'Durgesh Sahani',
          email: 'adarsh@myatonce.com',
        },
        {
          id: '10628',
          name: 'Yuri ',
          email: 'yuri@myatonce.com',
        },
        {
          id: '11065',
          name: 'Dnyaneshwar Ganage',
          email: 'abhishek@myatonce.com',
        },
        {
          id: '4498',
          name: 'Bill Brown',
          email: 'bill@myatonce.com',
        },
        {
          id: '13124',
          name: 'Samantha Chin',
          email: 'SChin@dallascowboys.net',
        },
      ],*/,
    };
    setSalesReps(data);
  }

  useEffect(() => {
    getSalesReps();
  }, []);

  return {
    salesReps,
    searchValue,
    setSearchValue,
  };
}
