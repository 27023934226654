import React from 'react';
import PropTypes from 'prop-types';

import AddNewBuyerModal from 'components/modals/add-buyer/add-new-buyer-modal';
import BuyerBillShipEditRequestModal from './components/buyer-bill-ship-edit-request-modal';
import AddNewRetailerModal from 'components/modals/add-retailer/add-new-retailer-modal';
import AddBillingAddressModal from 'components/modals/add-billing-address/add-billing-address-modal';
import AddShippingAddressModal from 'components/modals/add-shipping-address/add-shipping-address-modal';
// import AddPaymentTypeModal from 'components/modals/add-payment-type/add-payment-type-modal';
import BuyerInfoEditRequestModal from './components/buyer-info-edit-request-modal';
import { useBuyerRequests } from './buyer-requests-hooks';
import { isJsonString } from 'utils/utils';

const BuyerRequests = ({
  callback,
  selectedNotification,
  closeNotification,
  refreshPage,
}) => {
  const {
    isNewRequest,
    setIsNewRequest,
    handleDeclineNewRetailerRequest,
    isNewBuyerRequest,
    setIsNewBuyerRequest,
    selectedNotificaitonMoreInfo,
    handleApproveNewBuyerRequest,
    getCountryList,
    handleCheckBuyerUserName,
    declineBuyerRequest,
    isNewBillingRequest,
    setIsNewBillingRequest,
    handleApproveNewBillingRequest,
    isNewShippingRequest,
    setIsNewShippingRequest,
    handleApproveNewShippingRequest,
    // getMethodList,
    isShippingEditRequest,
    isBillingEditRequest,
    setIsBillingEditRequest,
    setIsShippingEditRequest,
    handleApproveEditShippingRequest,
    handleApproveEditBillingRequest,
    isBuyerInfoEditRequest,
    setIsBuyerInfoEditRequest,
    handleApproveEditBuyerInfoRequest,
  } = useBuyerRequests(
    callback,
    selectedNotification,
    closeNotification,
    refreshPage
  );
  return (
    <>
      {(isShippingEditRequest || isBillingEditRequest) && (
        <BuyerBillShipEditRequestModal
          open={isShippingEditRequest || isBillingEditRequest}
          setOpen={
            isShippingEditRequest
              ? setIsShippingEditRequest
              : setIsBillingEditRequest
          }
          handleApproveClick={
            isShippingEditRequest
              ? handleApproveEditShippingRequest
              : handleApproveEditBillingRequest
          }
          declineBtnClick={declineBuyerRequest}
          isShippingRequest={isShippingEditRequest}
          countryOptions={getCountryList()}
          notificationDetails={selectedNotificaitonMoreInfo}
        />
      )}
      {isNewRequest && (
        <AddNewRetailerModal
          prospect
          edit={false}
          open={isNewRequest}
          setOpen={setIsNewRequest}
          retailerId={selectedNotification?.reference_id}
          callbacks={callback}
          declineBtnClick={handleDeclineNewRetailerRequest}
          notificationId={selectedNotification?.id}
          buyerData={selectedNotificaitonMoreInfo?.buyer_data || {}}
        />
      )}
      {isNewBuyerRequest && (
        <AddNewBuyerModal
          open={isNewBuyerRequest}
          setOpen={setIsNewBuyerRequest}
          id={
            isJsonString(selectedNotificaitonMoreInfo?.form)
              ? JSON.parse(selectedNotificaitonMoreInfo?.form)?.retailerId
              : null
          }
          handleAddBuyerClick={handleApproveNewBuyerRequest}
          handleCheckBuyerUserName={handleCheckBuyerUserName}
          modalTitle={'New Buyer Request'}
          buyer={
            isJsonString(selectedNotificaitonMoreInfo?.form)
              ? JSON.parse(selectedNotificaitonMoreInfo?.form)
              : null
          }
          countryOptions={getCountryList()}
          isNotificationAction
          handleDeclineBtnClick={declineBuyerRequest}
        />
      )}
      {isNewBillingRequest && (
        <AddBillingAddressModal
          open={isNewBillingRequest}
          setOpen={setIsNewBillingRequest}
          id={
            isJsonString(selectedNotificaitonMoreInfo?.form)
              ? JSON.parse(selectedNotificaitonMoreInfo?.form)?.retailerId
              : null
          }
          initialValueForFields={
            isJsonString(selectedNotificaitonMoreInfo?.form)
              ? JSON.parse(selectedNotificaitonMoreInfo?.form)
              : null
          }
          title={'New Billing Address Request'}
          sendApiRequest={handleApproveNewBillingRequest}
          countryOptions={getCountryList()}
          isNotificationAction
          handleDeclineBtnClick={declineBuyerRequest}
        />
      )}
      {isNewShippingRequest && (
        <AddShippingAddressModal
          open={isNewShippingRequest}
          setOpen={setIsNewShippingRequest}
          id={
            isJsonString(selectedNotificaitonMoreInfo?.form)
              ? JSON.parse(selectedNotificaitonMoreInfo?.form)?.retailerId
              : null
          }
          initialValueForFields={
            isJsonString(selectedNotificaitonMoreInfo?.form)
              ? JSON.parse(selectedNotificaitonMoreInfo?.form)
              : null
          }
          title={'New Shipping Address Request'}
          sendApiRequest={handleApproveNewShippingRequest}
          countryOptions={getCountryList()}
          isNotificationAction
          handleDeclineBtnClick={declineBuyerRequest}
        />
      )}
      {isBuyerInfoEditRequest && (
        <BuyerInfoEditRequestModal
          open={isBuyerInfoEditRequest}
          setOpen={setIsBuyerInfoEditRequest}
          handleApproveClick={handleApproveEditBuyerInfoRequest}
          declineBtnClick={declineBuyerRequest}
          countryOptions={getCountryList()}
          notificationDetails={selectedNotificaitonMoreInfo}
          handleCheckBuyerUserName={handleCheckBuyerUserName}
          selectedNotification={selectedNotification}
        />
      )}
    </>
  );
};

BuyerRequests.propTypes = {
  callback: PropTypes.func,
  selectedNotification: PropTypes.object.isRequired,
  closeNotification: PropTypes.func,
  refreshPage: PropTypes.bool,
};

BuyerRequests.defaultProps = {
  callback: () => {},
  closeNotification: () => {},
  refreshPage: false,
};

export default BuyerRequests;
