import React from 'react';
import PropTypes from 'prop-types';

export default function PageNotFound({ fill, sx }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='351.484'
      height='261.904'
      viewBox='0 0 351.484 261.904'
    >
      <g
        id='_404-error'
        data-name='404-error'
        transform='translate(-58.221 -20.338)'
      >
        <path
          id='Path_21269'
          data-name='Path 21269'
          d='M234.447,22.047C162.872,11.568,151.5,52.24,124.686,63.429c-16.162,6.749-63.939,10.124-46.178,96.263,4.44,21.668-28.24,42.981-10.479,72.286,34.811,57.722,132.495,8.7,178.85,23.977,99.993,32.857,122.194-4.44,117.931-65.715-1.776-25.4,17.228-45.823,1.954-82.232C345.452,56.858,299.984,31.638,234.447,22.047Z'
          transform='translate(17.898 0)'
          fill='#e6edf9'
        />
        <g
          id='Group_10083'
          data-name='Group 10083'
          transform='translate(338.308 246.009)'
        >
          <g
            id='Group_10081'
            data-name='Group 10081'
            transform='translate(0 5.151)'
          >
            <g id='Group_10080' data-name='Group 10080'>
              <rect
                id='Rectangle_16925'
                data-name='Rectangle 16925'
                width='46'
                height='30.548'
                transform='translate(0 0.355)'
                fill='#07beb8'
              />
              <path
                id='Path_21270'
                data-name='Path 21270'
                d='M207.9,150.3l23.089,21.49,23.089-21.49'
                transform='translate(-207.722 -150.3)'
                fill='none'
                stroke='#ffba00'
                stroke-linecap='round'
                stroke-width='0.291'
              />
              <line
                id='Line_312'
                data-name='Line 312'
                x2='16.517'
                y2='15.452'
                transform='translate(29.838 15.274)'
                fill='none'
                stroke='#ffba00'
                stroke-linecap='round'
                stroke-width='0.291'
              />
              <line
                id='Line_313'
                data-name='Line 313'
                y1='15.452'
                x2='16.517'
                transform='translate(0.178 15.274)'
                fill='none'
                stroke='#ffba00'
                stroke-linecap='round'
                stroke-width='0.291'
              />
            </g>
          </g>
          <circle
            id='Ellipse_293'
            data-name='Ellipse 293'
            cx='5.683'
            cy='5.683'
            r='5.683'
            transform='translate(40.672 0)'
            fill='#f15a24'
          />
          <g
            id='Group_10082'
            data-name='Group 10082'
            transform='translate(43.691 3.019)'
          >
            <line
              id='Line_314'
              data-name='Line 314'
              x2='5.328'
              y2='5.328'
              fill='none'
              stroke='#ffba00'
              stroke-linecap='round'
              stroke-width='0.291'
            />
            <line
              id='Line_315'
              data-name='Line 315'
              x1='5.328'
              y2='5.328'
              fill='none'
              stroke='#ffba00'
              stroke-linecap='round'
              stroke-width='0.291'
            />
          </g>
        </g>
        <g
          id='Group_10088'
          data-name='Group 10088'
          transform='translate(58.221 200.364)'
        >
          <path
            id='Path_21271'
            data-name='Path 21271'
            d='M51.7,135.955A12.255,12.255,0,1,0,63.955,123.7,12.279,12.279,0,0,0,51.7,135.955Zm6.039,0a6.178,6.178,0,0,1,6.216-6.216,6.216,6.216,0,1,1,0,12.432A6.178,6.178,0,0,1,57.739,135.955Z'
            transform='translate(-48.858 -120.148)'
            fill='#ffba00'
          />
          <g
            id='Group_10084'
            data-name='Group 10084'
            transform='translate(12.432)'
          >
            <path
              id='Path_21272'
              data-name='Path 21272'
              d='M57.633,121.7h4.618l.533,5.151H57.1Z'
              transform='translate(-57.1 -121.7)'
              fill='#ffba00'
            />
            <path
              id='Path_21273'
              data-name='Path 21273'
              d='M57.633,141.451h4.618l.533-5.151H57.1Z'
              transform='translate(-57.1 -110.369)'
              fill='#ffba00'
            />
          </g>
          <g
            id='Group_10085'
            data-name='Group 10085'
            transform='translate(2.664 3.197)'
          >
            <path
              id='Path_21274'
              data-name='Path 21274'
              d='M51.6,126.875l3.375-3.375,3.907,3.2L54.8,130.782Z'
              transform='translate(-51.6 -123.5)'
              fill='#ffba00'
            />
            <path
              id='Path_21275'
              data-name='Path 21275'
              d='M65.607,140.882l3.375-3.375-3.2-3.907L61.7,137.685Z'
              transform='translate(-43.762 -115.662)'
              fill='#ffba00'
            />
          </g>
          <g
            id='Group_10086'
            data-name='Group 10086'
            transform='translate(0 12.965)'
          >
            <path
              id='Path_21276'
              data-name='Path 21276'
              d='M50.1,134.151v-4.618L54.718,129v5.683Z'
              transform='translate(-50.1 -129)'
              fill='#ffba00'
            />
            <path
              id='Path_21277'
              data-name='Path 21277'
              d='M69.751,134.151v-4.618L64.6,129v5.683Z'
              transform='translate(-38.847 -129)'
              fill='#ffba00'
            />
          </g>
          <g
            id='Group_10087'
            data-name='Group 10087'
            transform='translate(2.664 3.197)'
          >
            <path
              id='Path_21278'
              data-name='Path 21278'
              d='M54.975,140.882,51.6,137.507l3.2-3.907,4.085,4.085Z'
              transform='translate(-51.6 -115.662)'
              fill='#ffba00'
            />
            <path
              id='Path_21279'
              data-name='Path 21279'
              d='M68.982,126.875,65.607,123.5,61.7,126.7l4.085,4.085Z'
              transform='translate(-43.762 -123.5)'
              fill='#ffba00'
            />
          </g>
        </g>
        <g
          id='Group_10093'
          data-name='Group 10093'
          transform='translate(78.291 240.148)'
        >
          <path
            id='Path_21280'
            data-name='Path 21280'
            d='M62.8,154.1a8.7,8.7,0,1,0,8.7-8.7A8.833,8.833,0,0,0,62.8,154.1Zm4.085,0a4.44,4.44,0,1,1,4.44,4.44A4.4,4.4,0,0,1,66.885,154.1Z'
            transform='translate(-60.314 -143.091)'
            fill='#0054d4'
          />
          <g
            id='Group_10089'
            data-name='Group 10089'
            transform='translate(9.058 0)'
          >
            <path
              id='Path_21281'
              data-name='Path 21281'
              d='M67.033,144.1h3.2l.355,3.73H66.5Z'
              transform='translate(-66.5 -144.1)'
              fill='#0054d4'
            />
            <path
              id='Path_21282'
              data-name='Path 21282'
              d='M67.033,158.03h3.2l.355-3.73H66.5Z'
              transform='translate(-66.5 -136.184)'
              fill='#0054d4'
            />
          </g>
          <g
            id='Group_10090'
            data-name='Group 10090'
            transform='translate(2.309 2.131)'
          >
            <path
              id='Path_21283'
              data-name='Path 21283'
              d='M62.7,147.609l2.309-2.309,2.842,2.309-3.019,2.842Z'
              transform='translate(-62.7 -145.3)'
              fill='#0054d4'
            />
            <path
              id='Path_21284'
              data-name='Path 21284'
              d='M72.642,157.551l2.309-2.309L72.642,152.4,69.8,155.419Z'
              transform='translate(-57.19 -139.79)'
              fill='#0054d4'
            />
          </g>
          <g
            id='Group_10091'
            data-name='Group 10091'
            transform='translate(0 9.058)'
          >
            <path
              id='Path_21285'
              data-name='Path 21285'
              d='M61.4,152.752v-3.2l3.73-.355v4.085Z'
              transform='translate(-61.4 -149.2)'
              fill='#0054d4'
            />
            <path
              id='Path_21286'
              data-name='Path 21286'
              d='M74.919,152.752v-3.2L71.9,149.2v4.085Z'
              transform='translate(-53.251 -149.2)'
              fill='#0054d4'
            />
          </g>
          <g
            id='Group_10092'
            data-name='Group 10092'
            transform='translate(2.309 2.131)'
          >
            <path
              id='Path_21287'
              data-name='Path 21287'
              d='M65.009,157.551,62.7,155.242l2.131-2.842,3.019,3.019Z'
              transform='translate(-62.7 -139.79)'
              fill='#0054d4'
            />
            <path
              id='Path_21288'
              data-name='Path 21288'
              d='M74.951,147.609,72.642,145.3,69.8,147.609l2.842,2.842Z'
              transform='translate(-57.19 -145.3)'
              fill='#0054d4'
            />
          </g>
        </g>
        <g
          id='Group_10099'
          data-name='Group 10099'
          transform='translate(98.538 86.696)'
        >
          <g id='Group_10097' data-name='Group 10097'>
            <g id='Group_10096' data-name='Group 10096'>
              <g id='Group_10095' data-name='Group 10095'>
                <path
                  id='Path_21290'
                  data-name='Path 21290'
                  d='M72.8,145.616V126.079L118.09,61.43h27.174v63.05h11.9v21.135h-11.9v18.649h-24.51V145.616Zm49.552-57.367L98.553,124.3h23.8V88.248Zm56.124-16.34C184.87,62.5,194.994,57.7,209.2,57.7c14.031,0,24.332,4.8,30.726,14.209s9.591,22.378,9.591,38.718c0,16.517-3.2,29.66-9.591,39.074s-16.517,14.209-30.726,14.209c-14.031,0-24.332-4.8-30.726-14.209s-9.591-22.379-9.591-39.074C169.063,94.287,172.26,81.322,178.476,71.909Zm43.691,16.517c-2.131-5.151-6.394-7.815-12.965-7.815s-10.834,2.664-12.965,7.815-3.2,12.61-3.2,22.2a100.644,100.644,0,0,0,1.066,16.162,20.449,20.449,0,0,0,4.618,10.3c2.309,2.664,5.861,3.907,10.3,3.907s7.992-1.243,10.3-3.907a20.45,20.45,0,0,0,4.618-10.3,100.65,100.65,0,0,0,1.066-16.162C225.364,101.036,224.3,93.754,222.168,88.426Zm40.85,57.189V126.079L307.952,61.43H335.3v63.05H346.67v21.135H335.3v18.649H310.438V145.616ZM312.57,88.248,288.415,124.3H312.57Z'
                  transform='translate(-72.8 -57.7)'
                  fill='#f15a24'
                />
              </g>
            </g>
          </g>
          <g
            id='Group_10098'
            data-name='Group 10098'
            transform='translate(20.247 6.394)'
          >
            <path
              id='Path_21291'
              data-name='Path 21291'
              d='M107.907,63.6l.71,2.664,2.131-1.421-1.421,2.131,2.664.533-2.664.71,1.421,2.131-2.131-1.421-.71,2.664-.533-2.664-2.309,1.421,1.421-2.131L104,67.507l2.487-.533-1.421-2.131,2.309,1.421Z'
              transform='translate(-68.834 -59.515)'
              fill='#fff'
            />
            <path
              id='Path_21292'
              data-name='Path 21292'
              d='M89.007,79.6l.533,2.664,2.309-1.421-1.421,2.131,2.664.71-2.664.533,1.421,2.131L89.54,84.928l-.533,2.664-.533-2.664-2.309,1.421,1.421-2.131L85.1,83.685l2.487-.71-1.421-2.131,2.309,1.421Z'
              transform='translate(-83.502 -47.098)'
              fill='#fff'
            />
            <path
              id='Path_21293'
              data-name='Path 21293'
              d='M88.107,99.1l.71,2.664,2.131-1.421-1.421,2.131,2.664.71-2.664.533,1.421,2.131-2.131-1.421-.71,2.664-.533-2.664-2.131,1.421,1.421-2.131-2.664-.533,2.664-.71-1.421-2.131,2.131,1.421Z'
              transform='translate(-84.2 -31.965)'
              fill='#fff'
            />
            <path
              id='Path_21294'
              data-name='Path 21294'
              d='M110.385,92.1l.533,2.664,2.131-1.421-1.421,2.131,2.664.71-2.664.533,1.421,2.309-2.131-1.421-.533,2.487-.71-2.487-2.131,1.421,1.421-2.309-2.664-.533,2.664-.71-1.421-2.131,2.131,1.421Z'
              transform='translate(-67.049 -37.397)'
              fill='#fff'
            />
            <path
              id='Path_21295'
              data-name='Path 21295'
              d='M138.207,101.4l.533,2.664,2.309-1.421-1.421,2.309,2.486.533-2.486.533,1.421,2.309-2.309-1.421-.533,2.486-.533-2.486-2.309,1.421,1.421-2.309-2.486-.533,2.486-.533-1.421-2.309,2.309,1.421Z'
              transform='translate(-45.319 -30.18)'
              fill='#fff'
            />
            <path
              id='Path_21296'
              data-name='Path 21296'
              d='M135.985,81.1l.533,2.487,2.309-1.421-1.421,2.309,2.486.533-2.486.533,1.421,2.309-2.309-1.421-.533,2.487-.533-2.487-2.309,1.421,1.421-2.309-2.664-.533,2.664-.533-1.421-2.309,2.309,1.421Z'
              transform='translate(-47.181 -45.934)'
              fill='#fff'
            />
            <path
              id='Path_21297'
              data-name='Path 21297'
              d='M157.107,61.3l.71,2.486,2.131-1.421-1.421,2.309,2.664.533-2.664.533,1.421,2.309-2.131-1.421-.71,2.664-.533-2.664-2.309,1.421,1.421-2.309-2.487-.533,2.487-.533-1.421-2.309,2.309,1.421Z'
              transform='translate(-30.651 -61.3)'
              fill='#fff'
            />
            <path
              id='Path_21298'
              data-name='Path 21298'
              d='M168.585,84.6l.533,2.487,2.309-1.421-1.421,2.309,2.486.533-2.486.533,1.421,2.309-2.309-1.421-.533,2.487-.533-2.487-2.309,1.421,1.421-2.309-2.664-.533,2.664-.533-1.421-2.309,2.309,1.421Z'
              transform='translate(-21.882 -43.218)'
              fill='#fff'
            />
            <path
              id='Path_21299'
              data-name='Path 21299'
              d='M159.707,108.7l.71,2.664,2.131-1.421-1.421,2.131,2.664.71-2.664.533,1.421,2.131-2.131-1.421-.71,2.664-.533-2.664-2.131,1.421,1.421-2.131-2.664-.533,2.664-.71-1.421-2.131,2.131,1.421Z'
              transform='translate(-28.633 -24.514)'
              fill='#fff'
            />
            <path
              id='Path_21300'
              data-name='Path 21300'
              d='M189.707,99.1l.71,2.664,2.131-1.421-1.421,2.131,2.664.71-2.664.533,1.421,2.131-2.131-1.421-.71,2.664-.533-2.664-2.309,1.421,1.421-2.131-2.486-.533,2.486-.71-1.421-2.131,2.309,1.421Z'
              transform='translate(-5.351 -31.965)'
              fill='#fff'
            />
            <path
              id='Path_21301'
              data-name='Path 21301'
              d='M199.607,75.5l.71,2.664,2.131-1.421-1.421,2.131,2.664.71-2.664.533,1.421,2.309-2.131-1.421-.71,2.487-.533-2.487-2.309,1.421,1.421-2.309-2.487-.533,2.487-.71-1.421-2.131,2.309,1.421Z'
              transform='translate(2.332 -50.28)'
              fill='#fff'
            />
            <path
              id='Path_21302'
              data-name='Path 21302'
              d='M217.785,64.8l.533,2.486,2.309-1.421-1.421,2.309,2.486.533-2.486.533,1.421,2.309-2.309-1.421-.533,2.486-.71-2.486-2.131,1.421,1.421-2.309-2.664-.533,2.664-.533-1.421-2.309,2.131,1.421Z'
              transform='translate(16.301 -58.584)'
              fill='#fff'
            />
            <path
              id='Path_21303'
              data-name='Path 21303'
              d='M226.107,98.2l.533,2.664,2.309-1.421-1.421,2.309,2.664.533-2.664.533,1.421,2.309-2.309-1.421-.533,2.486-.533-2.486-2.309,1.421,1.421-2.309-2.487-.533,2.487-.533-1.421-2.309,2.309,1.421Z'
              transform='translate(22.898 -32.663)'
              fill='#fff'
            />
            <path
              id='Path_21304'
              data-name='Path 21304'
              d='M211.985,108.7l.533,2.664,2.131-1.421-1.421,2.131,2.664.71-2.664.533,1.421,2.131-2.131-1.421-.533,2.664-.71-2.664-2.131,1.421,1.421-2.131-2.664-.533,2.664-.71-1.421-2.131,2.131,1.421Z'
              transform='translate(11.8 -24.514)'
              fill='#fff'
            />
          </g>
        </g>
        <path
          id='Path_21305'
          data-name='Path 21305'
          d='M112.007,111.9l.533,2.664,2.309-1.421-1.421,2.131,2.487.71-2.487.533,1.421,2.131-2.309-1.421-.533,2.664-.533-2.664-2.309,1.421,1.421-2.131-2.487-.533,2.487-.71-1.421-2.131,2.309,1.421Z'
          transform='translate(53.133 71.059)'
          fill='#fff'
        />
        <g
          id='Group_10108'
          data-name='Group 10108'
          transform='translate(104.754 184.762)'
        >
          <g
            id='Group_10100'
            data-name='Group 10100'
            transform='translate(12.682 63.556)'
          >
            <path
              id='Path_21306'
              data-name='Path 21306'
              d='M100.6,165.04c-6.039,6.039-17.05,4.618-17.05,4.618s-1.243-11.189,4.8-17.05a12.288,12.288,0,0,1,9.591-3.907l6.749,6.749C104.5,159.889,103.261,162.553,100.6,165.04Z'
              transform='translate(-83.44 -148.7)'
              fill='#ff7300'
            />
          </g>
          <g
            id='Group_10101'
            data-name='Group 10101'
            transform='translate(18.39 64.738)'
          >
            <path
              id='Path_21307'
              data-name='Path 21307'
              d='M98.28,160.438c-4.085,4.085-11.544,3.2-11.544,3.2s-.888-7.637,3.2-11.544c1.776-1.776,3.907-3.019,6.571-2.664l4.44,4.44A7.5,7.5,0,0,1,98.28,160.438Z'
              transform='translate(-86.654 -149.366)'
              fill='#ffba00'
            />
          </g>
          <rect
            id='Rectangle_16926'
            data-name='Rectangle 16926'
            width='13.676'
            height='2.309'
            transform='matrix(0.707, 0.707, -0.707, 0.707, 27.1, 60.86)'
            fill='#3376dd'
          />
          <path
            id='Path_21308'
            data-name='Path 21308'
            d='M114.13,158.987c11.012,11.012,15.807,25.4,14.031,37.83a27.706,27.706,0,0,0,4.973-4.085c13.5-13.5,12.61-36.232-1.954-50.8s-37.3-15.452-50.8-1.954a26.408,26.408,0,0,0-4.085,4.973C88.732,143.18,103.119,147.975,114.13,158.987Z'
            transform='translate(-76.3 -99.339)'
            fill='#0054d4'
          />
          <g
            id='Group_10102'
            data-name='Group 10102'
            transform='translate(24.844 0)'
          >
            <path
              id='Path_21309'
              data-name='Path 21309'
              d='M140.477,115.019c-4.085-4.085-13.321-1.954-23.977,4.085L136.392,139C142.431,128.34,144.384,119.1,140.477,115.019Z'
              transform='translate(-69.946 -112.915)'
              fill='#ff7300'
            />
            <path
              id='Path_21310'
              data-name='Path 21310'
              d='M156.734,136.392,136.842,116.5a147.483,147.483,0,0,0-28.417,22.379c-9.058,9.058-18.649,21.313-18.116,30.193L104.34,183.1c8.7.355,21.135-9.058,30.193-18.116A160.182,160.182,0,0,0,156.734,136.392Z'
              transform='translate(-90.288 -110.134)'
              fill='#fcfcfc'
            />
          </g>
          <line
            id='Line_316'
            data-name='Line 316'
            x2='20.958'
            y2='21.135'
            transform='translate(68.912 7.965)'
            fill='none'
            stroke='#ff7300'
            stroke-linecap='round'
            stroke-width='0.291'
          />
          <circle
            id='Ellipse_294'
            data-name='Ellipse 294'
            cx='12.077'
            cy='12.077'
            r='12.077'
            transform='translate(57.012 16.49)'
            fill='#ff7300'
          />
          <circle
            id='Ellipse_295'
            data-name='Ellipse 295'
            cx='8.88'
            cy='8.88'
            r='8.88'
            transform='translate(60.209 19.687)'
            fill='#3376dd'
          />
          <g
            id='Group_10103'
            data-name='Group 10103'
            transform='translate(26.463 41.71)'
          >
            <path
              id='Path_21311'
              data-name='Path 21311'
              d='M111.27,150.964A86.729,86.729,0,0,1,91.2,165.883a88.991,88.991,0,0,1,14.741-20.07c4.085-4.085,9.058-8.7,11.722-9.413l2.842,2.842C119.795,141.906,115.355,146.879,111.27,150.964Z'
              transform='translate(-91.2 -136.4)'
              fill='#0054d4'
            />
          </g>
          <g
            id='Group_10104'
            data-name='Group 10104'
            transform='translate(60.919 20.397)'
          >
            <circle
              id='Ellipse_296'
              data-name='Ellipse 296'
              cx='0.71'
              cy='0.71'
              r='0.71'
              transform='translate(14.919)'
              fill='#ffba00'
            />
            <circle
              id='Ellipse_297'
              data-name='Ellipse 297'
              cx='0.71'
              cy='0.71'
              r='0.71'
              transform='translate(0 14.741)'
              fill='#ffba00'
            />
          </g>
          <g
            id='Group_10105'
            data-name='Group 10105'
            transform='translate(57.9 27.857)'
          >
            <circle
              id='Ellipse_298'
              data-name='Ellipse 298'
              cx='0.71'
              cy='0.71'
              r='0.71'
              transform='translate(20.958)'
              fill='#ffba00'
            />
            <circle
              id='Ellipse_299'
              data-name='Ellipse 299'
              cx='0.71'
              cy='0.71'
              r='0.71'
              fill='#ffba00'
            />
          </g>
          <g
            id='Group_10106'
            data-name='Group 10106'
            transform='translate(60.919 20.397)'
          >
            <circle
              id='Ellipse_300'
              data-name='Ellipse 300'
              cx='0.71'
              cy='0.71'
              r='0.71'
              transform='translate(14.919 14.741)'
              fill='#ffba00'
            />
            <circle
              id='Ellipse_301'
              data-name='Ellipse 301'
              cx='0.71'
              cy='0.71'
              r='0.71'
              fill='#ffba00'
            />
          </g>
          <g
            id='Group_10107'
            data-name='Group 10107'
            transform='translate(68.379 17.378)'
          >
            <circle
              id='Ellipse_302'
              data-name='Ellipse 302'
              cx='0.71'
              cy='0.71'
              r='0.71'
              transform='translate(0 20.958)'
              fill='#ffba00'
            />
            <circle
              id='Ellipse_303'
              data-name='Ellipse 303'
              cx='0.71'
              cy='0.71'
              r='0.71'
              fill='#ffba00'
            />
          </g>
        </g>
        <g
          id='Group_10114'
          data-name='Group 10114'
          transform='translate(100.847 33.236)'
        >
          <g
            id='Group_10109'
            data-name='Group 10109'
            transform='translate(186.998 25.888)'
          >
            <path
              id='Path_21312'
              data-name='Path 21312'
              d='M187.4,109.887c7.637,8.348,19.537,14.386,31.792,20.6,19,9.591,38.718,19.537,47.243,39.251,3.907,8.88,7.992,16.34,12.433,22.023a42.058,42.058,0,0,0,11.012,10.3c6.571,3.907,10.834,3.2,11.012,3.019.178,0,.355-.355.355-.533s-.355-.355-.533-.355c0,0-4.263.888-10.479-3.019-10.479-6.394-18.294-20.958-23.089-31.969-8.7-20.07-28.417-30.016-47.6-39.784-17.761-9.058-34.456-17.406-38.718-33.035-3.019-11.367,14.564-23.267,28.595-32.68,10.834-7.282,17.228-11.9,16.34-15.274a8.1,8.1,0,0,0-4.085-5.151c-4.085-2.131-9.236-.533-9.413-.533s-.355.355-.355.533.355.355.533.355c0,0,4.973-1.6,8.7.533A6.811,6.811,0,0,1,224.7,48.79c.888,3.019-7.282,8.525-15.985,14.386-14.386,9.591-32.147,21.668-28.95,33.745A35.325,35.325,0,0,0,187.4,109.887Z'
              transform='translate(-179.388 -42.176)'
              fill='#ff7300'
            />
          </g>
          <g
            id='Group_10110'
            data-name='Group 10110'
            transform='translate(184.862 10.834)'
          >
            <path
              id='Path_21313'
              data-name='Path 21313'
              d='M179.633,61.051h0a1.451,1.451,0,0,0,1.6-1.421l1.6-24.332a1.451,1.451,0,0,0-1.421-1.6h0a1.451,1.451,0,0,0-1.6,1.421l-1.6,24.332A1.437,1.437,0,0,0,179.633,61.051Z'
              transform='translate(-178.185 -33.7)'
              fill='#ff7300'
            />
            <path
              id='Path_21314'
              data-name='Path 21314'
              d='M178.2,58.577l13.853.888a4.175,4.175,0,0,0,4.44-3.907l1.066-15.629a4.175,4.175,0,0,0-3.907-4.44L179.8,34.6Z'
              transform='translate(-178.173 -33.002)'
              fill='#ff7300'
            />
            <path
              id='Path_21315'
              data-name='Path 21315'
              d='M194.794,55.116l-6.394-.355L189.643,37l6.394.355a4.175,4.175,0,0,1,3.907,4.44l-.71,9.413A4.06,4.06,0,0,1,194.794,55.116Z'
              transform='translate(-170.257 -31.139)'
              fill='#ff7300'
            />
            <path
              id='Path_21316'
              data-name='Path 21316'
              d='M194.6,45.973l5.151.355a2.338,2.338,0,0,0,2.664-2.309h0a2.338,2.338,0,0,0-2.309-2.664L194.955,41Z'
              transform='translate(-165.445 -28.035)'
              fill='#ff7300'
            />
            <line
              id='Line_317'
              data-name='Line 317'
              x1='1.598'
              y2='24.332'
              transform='translate(3.047 1.598)'
              fill='none'
              stroke='#000'
              stroke-linecap='round'
              stroke-width='0.291'
            />
            <line
              id='Line_318'
              data-name='Line 318'
              x1='1.243'
              y2='17.405'
              transform='translate(18.321 6.039)'
              fill='none'
              stroke='#000'
              stroke-linecap='round'
              stroke-width='0.291'
            />
            <line
              id='Line_319'
              data-name='Line 319'
              x1='0.355'
              y2='5.151'
              transform='translate(28.977 12.788)'
              fill='none'
              stroke='#000'
              stroke-linecap='round'
              stroke-width='0.291'
            />
          </g>
          <g
            id='Group_10112'
            data-name='Group 10112'
            transform='translate(111.574 9.267)'
          >
            <path
              id='Path_21317'
              data-name='Path 21317'
              d='M161.49,64.4l-16.34-8.7a10.432,10.432,0,0,1-4.263-13.853l1.776-3.2a10.432,10.432,0,0,1,13.853-4.263l16.34,8.7Z'
              transform='translate(-134.708 -32.466)'
              fill='#ff7300'
            />
            <path
              id='Path_21318'
              data-name='Path 21318'
              d='M142.922,41.666,138.3,39.179a2.442,2.442,0,0,1-1.066-3.375h0a2.442,2.442,0,0,1,3.375-1.066l4.618,2.486Z'
              transform='translate(-136.921 -31.574)'
              fill='#ff7300'
            />
            <g
              id='Group_10111'
              data-name='Group 10111'
              transform='translate(28.38 13.822)'
            >
              <path
                id='Path_21319'
                data-name='Path 21319'
                d='M166.931,47.7l-7.637-4.085,1.6-3.019,7.637,4.085a1.7,1.7,0,0,1,.71,2.309h0A1.871,1.871,0,0,1,166.931,47.7Zm-6.394,11.9L152.9,55.519l1.6-3.019,7.637,4.085a1.7,1.7,0,0,1,.71,2.309h0A1.6,1.6,0,0,1,160.537,59.6Z'
                transform='translate(-152.9 -40.6)'
                fill='#ffba00'
              />
            </g>
            <rect
              id='Rectangle_16927'
              data-name='Rectangle 16927'
              width='13.321'
              height='2.486'
              transform='matrix(-0.882, -0.471, 0.471, -0.882, 31.709, 22.462)'
              fill='#fff'
            />
            <path
              id='Path_21320'
              data-name='Path 21320'
              d='M142.529,57.3h0a2.284,2.284,0,0,1-.888-3.2L152.3,34.029a2.284,2.284,0,0,1,3.2-.888h0a2.283,2.283,0,0,1,.888,3.2l-10.656,20.07A2.44,2.44,0,0,1,142.529,57.3Z'
              transform='translate(-133.508 -32.818)'
              fill='#ffba00'
            />
          </g>
          <g
            id='Group_10113'
            data-name='Group 10113'
            transform='translate(0 12.795)'
          >
            <path
              id='Path_21321'
              data-name='Path 21321'
              d='M74.278,91.631c0,.178.178.178,0,0,.355.178.533.178.71-.178C80.671,80.8,88.131,73.338,97.011,69.43c7.815-3.552,17.228-4.44,27.529-2.842a47.645,47.645,0,0,0,27.707-3.907c8.348-3.907,15.1-10.124,18.826-17.583,2.842-5.506,6.394-8.7,10.3-9.236,5.328-.888,9.591,3.2,9.591,3.2a.5.5,0,0,0,.71-.71c-.178-.178-4.8-4.44-10.479-3.375-4.263.71-7.815,3.907-10.834,9.768a38.5,38.5,0,0,1-18.471,17.05A47.747,47.747,0,0,1,124.718,65.7C101.984,62.149,85.112,70.674,74.1,91.1A1.79,1.79,0,0,0,74.278,91.631Z'
              transform='translate(-74.1 -34.804)'
              fill='#ff7300'
            />
          </g>
          <path
            id='Path_21322'
            data-name='Path 21322'
            d='M168.6,47.2l3.73,18.116,5.328-.178,6.216,19'
            transform='translate(-0.761 -12.389)'
            fill='none'
            stroke='#000'
            stroke-linecap='round'
            stroke-width='0.291'
          />
          <path
            id='Path_21323'
            data-name='Path 21323'
            d='M172.829,47.6l-10.124,9.236,2.842,1.954L157.2,65.183'
            transform='translate(-9.608 -12.079)'
            fill='none'
            stroke='#000'
            stroke-linecap='round'
            stroke-width='0.291'
          />
          <path
            id='Path_21324'
            data-name='Path 21324'
            d='M173.5,47.1l7.46,10.3,3.019-.888,6.039,7.282'
            transform='translate(3.041 -12.467)'
            fill='none'
            stroke='#000'
            stroke-linecap='round'
            stroke-width='0.291'
          />
          <line
            id='Line_320'
            data-name='Line 320'
            y1='15.452'
            x2='10.124'
            transform='translate(176.541)'
            fill='none'
            stroke='#000'
            stroke-linecap='round'
            stroke-width='0.291'
          />
          <line
            id='Line_321'
            data-name='Line 321'
            x1='9.946'
            y1='14.031'
            transform='translate(152.92 2.309)'
            fill='none'
            stroke='#000'
            stroke-linecap='round'
            stroke-width='0.291'
          />
          <line
            id='Line_322'
            data-name='Line 322'
            y1='8.703'
            transform='translate(170.503 7.637)'
            fill='none'
            stroke='#000'
            stroke-linecap='round'
            stroke-width='0.291'
          />
        </g>
        <g
          id='Group_10115'
          data-name='Group 10115'
          transform='translate(255.72 198.056)'
        >
          <path
            id='Path_21325'
            data-name='Path 21325'
            d='M161.655,158.875c-2.486-4.263,8.348-14.919,24.155-24.155,15.807-9.058,30.548-13.143,33.035-8.88'
            transform='translate(-161.3 -117.492)'
            fill='none'
            stroke='#000'
            stroke-linecap='round'
            stroke-width='0.291'
          />
          <circle
            id='Ellipse_304'
            data-name='Ellipse 304'
            cx='24.865'
            cy='24.865'
            r='24.865'
            transform='translate(4.085)'
            fill='#ffba00'
          />
          <path
            id='Path_21326'
            data-name='Path 21326'
            d='M177.421,122.809s6.216,14.031-13.321,28.062M186.3,120.5s2.664,11.722-.888,17.761-12.965,15.274-15.807,23.444m23.622-40.85s-1.954,17.05,18.649,17.583m-24.687,31.614s5.506-15.1,21.668-11.367m-33.923,7.637s8.525-28.24,36.942-19'
            transform='translate(-159.127 -120.322)'
            fill='none'
            stroke='#fff'
            stroke-linecap='round'
            stroke-width='0.291'
          />
          <path
            id='Path_21327'
            data-name='Path 21327'
            d='M218.689,125.1c2.486,4.263-8.348,14.919-24.155,24.155-15.807,9.058-30.548,13.143-33.035,8.88'
            transform='translate(-161.145 -116.752)'
            fill='none'
            stroke='#000'
            stroke-linecap='round'
            stroke-width='0.291'
          />
        </g>
      </g>
    </svg>
  );
}

PageNotFound.propTypes = {
  fill: PropTypes.string,
  sx: PropTypes.object,
};

PageNotFound.defaultProps = {
  fill: 'var(--primary-icon-color)',
  sx: {},
};
