import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Formik } from 'formik';
import Typography from '@mui/material/Typography';
import TextField from 'components/text-field';
import MyModal from 'components/modals/modal';
import { useChangePasswordHooks } from './change-password-hooks';
import { changePasswordSchema } from './validation-schema';
import { undefinedCheck } from 'utils/helper';

const ChangePassword = () => {
  const {
    handleSavePasswordBtnClick,
    loggedInUserData,
    passwordChangeLoading,
    openPassword,
  } = useChangePasswordHooks();

  return (
    <MyModal
      disableScrollLock={false}
      open={undefinedCheck(openPassword, false)}
      setOpen={() => {}}
      size='medium'
      title='Change Password'
      showCloseIcon={false}
    >
      <Formik
        validateOnMount
        validationSchema={changePasswordSchema}
        initialValues={{
          newpassword: '',
          confirmpassword: '',
        }}
        onSubmit={handleSavePasswordBtnClick}
      >
        {({ handleSubmit, ...rest }) => (
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '15px 25px',
              }}
            >
              <Typography
                variant='h2'
                sx={{
                  paddingBottom: '15px',
                }}
              >
                Welcome, {loggedInUserData?.name}
              </Typography>

              <Typography
                variant='h3'
                sx={{
                  paddingBottom: '15px',
                }}
              >
                Please change your password to continue.
              </Typography>

              <Grid container sx={{}} columnSpacing={3} rowSpacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id='newpassword'
                    name='newpassword'
                    label='Enter New Password'
                    placeholder='Enter New Password'
                    type='password'
                    value={rest.values.newpassword}
                    onChange={rest.handleChange}
                    error={
                      rest.touched.newpassword &&
                      Boolean(rest.errors.newpassword)
                    }
                    helperText={
                      rest.touched.newpassword && rest.errors.newpassword
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <TextField
                    id='confirmpassword'
                    name='confirmpassword'
                    label='Confirm Password'
                    placeholder='Confirm Password'
                    type='password'
                    value={rest.values.confirmpassword}
                    onChange={rest.handleChange}
                    error={
                      rest.touched.confirmpassword &&
                      Boolean(rest.errors.confirmpassword)
                    }
                    helperText={
                      rest.touched.confirmpassword &&
                      rest.errors.confirmpassword
                    }
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '10px',
                  }}
                >
                  <Button
                    variant='contained'
                    type='submit'
                    disabled={passwordChangeLoading}
                  >
                    Save
                  </Button>
                  {/* <Button
                    variant='outlined'
                    onClick={handleCloseChangePassword}
                    disabled={passwordChangeLoading}
                  >
                    Cancel
                  </Button> */}
                </Grid>
              </Grid>
            </Box>
          </form>
        )}
      </Formik>
    </MyModal>
  );
};

ChangePassword.propTypes = {
  showChangePassword: PropTypes.bool,
};

ChangePassword.defaultProps = {
  showChangePassword: false,
};

export default ChangePassword;
