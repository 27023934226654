import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MyModal from 'components/modals/modal';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import { CircularProgress } from '@mui/material';
import BuyerEmptyStateIcon from 'assets/icons/buyer-empty-state-icon';
import EmptyState from 'components/empty-state';
import useEmailStatusModal from './email-status-modal-hooks';
import StatusChip from 'components/status-chip';

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0}>
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0}>
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const EmailStatusModal = ({ open, setOpen, notificationId }) => {
  const {
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    onPageChangeHandler,
    handleChangeRowsPerPage,
    emailData,
    isLoading,
    setIsLoading,
  } = useEmailStatusModal({ notificationId });
  const close = (
    <Button
      variant='outlined'
      color='secondary'
      onClick={(e) => {
        handleClose();
      }}
    >
      Close
    </Button>
  );
  const done = (
    <Button
      variant='contained'
      onClick={(e) => {
        handleClose();
      }}
    >
      Done
    </Button>
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <MyModal
      open={open}
      setOpen={setOpen}
      size='fullWidth'
      title={emailData.type ? `${emailData.type} Sent List` : 'Sent List'}
      showCloseIcon
      actions={[close, done]}
      // actions={timeline === 4 ? [close, sendLink] : [close, nextStep]}
    >
      <Box sx={{ padding: '18px 25px 18px 25px' }}>
        <TableContainer
          sx={{
            maxHeight: '400px',
            backgroundColor: 'var(--paper-background-color)',
          }}
        >
          <Table
            size='small'
            sx={{ border: '1px solid var(--secondary-border-color)' }}
          >
            <TableHead>
              <TableRow>
                <TableCell>ACCOUNT NAME</TableCell>
                <TableCell>ACCOUNT#</TableCell>
                <TableCell>EMAIL</TableCell>
                <TableCell>PHONE</TableCell>
                <TableCell>BUYER NAME</TableCell>
                <TableCell>STATUS</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colspan='8'>
                    <Box
                      sx={{
                        height: '400px',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  </TableCell>
                </TableRow>
              ) : null}

              {emailData?.data &&
                emailData?.data?.map((buyer, i) => (
                  <TableRow key={i}>
                    {/* <TableCell sx={{ fontWeight: 900 }}>{row.accname}</TableCell> */}
                    <TableCell sx={{ fontWeight: '900px' }}>
                      {buyer.account}
                    </TableCell>
                    <TableCell>{buyer.accountno}</TableCell>
                    <TableCell>{buyer.email}</TableCell>
                    <TableCell>{buyer.phoneNo}</TableCell>
                    <TableCell>{buyer.buyerName}</TableCell>
                    <TableCell>
                      <StatusChip
                        label={`${buyer.status == '1' ? 'Sent' : 'Failed'}`}
                        color={`${buyer.status == '1' ? '#32cd32' : '#ff2b1c'}`}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              {emailData?.data?.length === 0 ? (
                <TableCell colspan='8'>
                  {' '}
                  <EmptyState
                    title='No Records found'
                    subTitle='We couldnt find any Records. Try adjusting your filters to display results '
                    icon={<BuyerEmptyStateIcon />}
                    sx={{
                      border: '1px solid var(--secondary-border-color)',
                      height: '210px',
                    }}
                  />
                </TableCell>
              ) : null}
            </TableFooter>
          </Table>
        </TableContainer>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'right',
            border: '1px solid var(--secondary-border-color)',
          }}
        >
          <TablePagination
            rowsPerPageOptions={[
              25, 50, 100 /*{ label: 'All', value: buyersList?.count }*/,
            ]}
            count={emailData?.count}
            onPageChange={onPageChangeHandler}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page}
            ActionsComponent={TablePaginationActions}
            border={0}
          />
        </Box>
      </Box>
    </MyModal>
  );
};

EmailStatusModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  notificationId: PropTypes.string,
};
EmailStatusModal.defaultProps = {
  open: false,
  setOpen: () => {},
  notificationId: '',
};
export default EmailStatusModal;
