import { useState, useEffect, useCallback } from 'react';
import { api, apiEndPoints } from '../../../api';
import {
  useNavigate,
  useParams,
  useSearchParams,
  createSearchParams,
  useMatch,
} from 'react-router-dom';
import _debounce from 'lodash/debounce';
import { LogLevel } from 'constants';
import LoggingService from 'utils/service/LoggingService';

export default function useCreateCustomerModal(data, edit, open, setOpen) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedBuyers, setSelectedBuyers] = useState([]);
  const [showEditGroupDetails, setShowEditGroupDetails] = useState(false);
  const [buyersData, setBuyersData] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [globalSearchValue, setGlobalSearchValue] = useState('');
  const [groupNameValue, setGroupNameValue] = useState('Group Name ##');
  const [groupDescriptionValue, setGroupDescriptionValue] = useState(
    'Your description here'
  );
  const [columnSearchValues, setColumnSearchValues] = useState([]);
  const [salesRep, setSalesRep] = useState([]);
  const [priceGroup, setPriceGroup] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [showBuyers, setShowBuyers] = useState('');
  const [filterString, setFilterString] = useState(
    edit ? `{"groupIds":["${data?.id}"]}` : ''
  );
  const [groups, setGroups] = useState([]);
  const [onFirstRender, setOnFirstRender] = useState(true);
  //  sort columns

  const [sortColumnValue, setSortColumnValue] = useState({
    columnName: '',
    sortValue: '',
  });

  // zero selected buyers alert modal state
  const [showZeroBuyerError, setShowZeroBuyerError] = useState(false);

  const [isTableDataLoading, setIsTableDataLoading] = useState(false);

  const [isSelectAll, setIsSelectAll] = useState(false);
  const [isAllBuyerSelected, setIsAllBuyerSelected] = useState(false);

  useEffect(() => {
    if (edit) {
      setGroupNameValue(data?.name);
      setGroupDescriptionValue(data?.description);
    } else {
      setGroupNameValue('Group Name ##');
      setGroupDescriptionValue('Your description here');
    }
  }, [open, data]);
  const [showApplyFiltersModal, setShowApplyFiltersModal] = useState(false);
  useEffect(() => {
    setSearchParams((prevParams) => {
      const updatedParams = createSearchParams({
        ...Object.fromEntries(prevParams), // Spread the existing search params
        gfilters: '',
        gFilterCount: '',
        gClmnSrchV: '',
        isGroup: true,
        // Update the filtersCount property
      });
      return updatedParams;
    });
    let payload = {
      page,
      rowsPerPage,
      globalSearchValue,
      filterString,
      sortByColumn: sortColumnValue?.columnName,
      sortValue: sortColumnValue?.sortValue,
    };
    getBuyers(payload);
  }, [data]);

  //set selected buyers on initial load
  // useEffect(() => {
  //   const arr = data?.buyer_ids?.split(',');
  //   if (Array.isArray(arr)) {
  //     arr.length >= rowsPerPage ? setIsSelectAll(true) : null;
  //     setSelectedBuyers(arr);
  //   }
  // }, [data]);

  async function getBuyers(payload, clearSelectedBuyers = false) {
    if (edit && data?.id) {
      const res = await api.get(
        `${apiEndPoints.buyerList}?page=${payload?.page + 1}&limit=${
          payload?.rowsPerPage
        }&search=${payload?.globalSearchValue}&filters=${
          payload?.filterString
        }&sortByColumn=${payload?.sortByColumn}&sortBy=${
          payload?.sortValue
        }&showBuyerImage=true&isFromGroups=true`
      );

      if (res) {
        setIsTableDataLoading(false);
        setBuyersData(res?.data);

        if (clearSelectedBuyers) {
          // this if will work if user is selecting Groups
          setSelectedBuyers([]);
          setIsSelectAll(false);
          setIsAllBuyerSelected(false);
        } else {
          if (isAllBuyerSelected) {
            let buyerArray = [];
            res?.data?.data?.forEach((buyer) => {
              buyerArray.push(buyer?.id);
            });

            let uniqueBuyerIds = {};
            let buyersArray = [...selectedBuyers, ...buyerArray];
            let uniqueArray = buyersArray.filter((obj) => {
              if (!uniqueBuyerIds[obj]) {
                uniqueBuyerIds[obj] = true;
                return true;
              }
              return false;
            });
            setSelectedBuyers(uniqueArray);
          } else {
            if (onFirstRender) {
              const arr = data?.buyer_ids?.split(',');
              if (Array.isArray(arr)) {
                if (arr.length == res?.data?.count) {
                  setIsAllBuyerSelected(true);
                }
                if (arr.length >= rowsPerPage) setIsSelectAll(true);
                setSelectedBuyers(arr);
              }
              setOnFirstRender(false);
            } else {
              let allPresent = res?.data?.data?.every((buyer) =>
                selectedBuyers?.some(
                  (selectedBuyer) => selectedBuyer === buyer?.id
                )
              );
              if (allPresent) {
                setIsSelectAll(true);
              } else {
                setIsSelectAll(false);
              }
            }
          }
        }
      } else {
        setIsTableDataLoading(false);
      }
    } else {
      const res = await api.get(
        `${apiEndPoints.buyerList}?page=${payload?.page + 1}&limit=${
          payload?.rowsPerPage
        }&search=${payload?.globalSearchValue}&filters=${
          payload?.filterString
        }&sortByColumn=${payload?.sortByColumn}&sortBy=${
          payload?.sortValue
        }&showBuyerImage=true&isFromGroups=true`
      );
      if (res) {
        setIsTableDataLoading(false);
        setBuyersData(res?.data);
        if (clearSelectedBuyers) {
          // this if will work if user is selecting Groups
          setSelectedBuyers([]);
          setIsSelectAll(false);
          setIsAllBuyerSelected(false);
        } else {
          if (isAllBuyerSelected) {
            let buyerArray = [];
            res?.data?.data?.forEach((buyer) => {
              buyerArray.push(buyer?.id);
            });

            let uniqueBuyerIds = {};
            let buyersArray = [...selectedBuyers, ...buyerArray];
            let uniqueArray = buyersArray.filter((obj) => {
              if (!uniqueBuyerIds[obj]) {
                uniqueBuyerIds[obj] = true;
                return true;
              }
              return false;
            });
            setSelectedBuyers(uniqueArray);
          } else {
            let allPresent = res?.data?.data?.every((buyer) =>
              selectedBuyers?.some(
                (selectedBuyer) => selectedBuyer === buyer?.id
              )
            );
            if (allPresent) {
              setIsSelectAll(true);
            } else {
              setIsSelectAll(false);
            }
          }
        }
      } else {
        setIsTableDataLoading(false);
      }
    }
  }
  const getBuyerByDebounce = useCallback(
    _debounce((payload, clearSelectedBuyers) => {
      getBuyers(payload, clearSelectedBuyers);
    }, 1000),
    [data, edit, isSelectAll, isAllBuyerSelected, selectedBuyers]
  );
  // sort buyers column

  //  logic to post group API
  async function onSaveHandler(setOpen) {
    let transdata = {
      name: groupNameValue,
      description: groupDescriptionValue,
      buyerIds: selectedBuyers,
    };
    const res = await api
      .post(apiEndPoints.groupList, transdata)
      .then((res) => {})
      .catch((error) => {});
    setOpen(false);
  }
  //    logic to handle onPageChange for pagination
  const onPageChangeHandler = (event, page) => {
    event.preventDefault();
    setPage(page);
    let payload = {
      page: page,
      rowsPerPage,
      globalSearchValue,
      filterString,
      sortByColumn: sortColumnValue?.columnName,
      sortValue: sortColumnValue?.sortValue,
    };
    setIsTableDataLoading(true);
    getBuyerByDebounce(payload);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    let payload = {
      page,
      rowsPerPage: parseInt(event.target.value, 10),
      globalSearchValue,
      filterString,
      sortByColumn: sortColumnValue?.columnName,
      sortValue: sortColumnValue?.sortValue,
    };
    setIsTableDataLoading(true);
    getBuyerByDebounce(payload);
  };
  // logic ends onpagechange
  //  logic to handle checkbox
  //  logic to handle checkbox
  function handleCheck(value) {
    if (selectedBuyers.some((obj) => obj === value.id)) {
      setSelectedBuyers(() =>
        selectedBuyers.filter((item) => item !== value.id)
      );
      if (isSelectAll) {
        setIsSelectAll(false);
      }
      if (isAllBuyerSelected) {
        setIsAllBuyerSelected(false);
      }
    } else {
      let temporarySelectedBuyersList = [...selectedBuyers];
      temporarySelectedBuyersList?.push(value.id);
      let allPresent = buyersData?.data?.every((buyer) =>
        temporarySelectedBuyersList?.some(
          (selectedBuyer) => selectedBuyer === buyer?.id
        )
      );
      if (allPresent) {
        if (temporarySelectedBuyersList?.length == buyersData?.count) {
          setIsAllBuyerSelected(true);
        }
        setIsSelectAll(true);
      } else {
        setIsSelectAll(false);
      }
      setSelectedBuyers((prev) => {
        return [...prev, value?.id];
      });
    }
  }
  const createColumnJsonString = (searchValues) => {
    let filters = '{';
    // if (!edit) filters = '{';
    // get column search filters
    searchValues?.forEach((obj) => {
      filters = filters.concat(`"${obj.label}":"${obj.value}",`);
    });

    filters = filters.substring(0, filters.length - 1);
    // if (!edit)
    filters = filters.concat('}');
    applyColumnSearchValues(filters);
  };

  const applyColumnSearchValues = (jsonFilterString) => {
    setSearchParams((prevParams) => {
      const updatedParams = createSearchParams({
        ...Object.fromEntries(prevParams), // Spread the existing search params
        gClmnSrchV: jsonFilterString ?? '', // Update the filtersCount property
      });
      return updatedParams;
    });
    let groupIdJSON = data?.id
      ? JSON.parse(`{"groupIds":["${data?.id}"]}`)
      : {};
    let columnSearchValues = JSON.parse(jsonFilterString);
    let filterString = searchParams?.get('gfilters')
      ? JSON.parse(searchParams?.get('gfilters'))
      : {};

    let combinedObject = searchParams?.get('gfilters')
      ? { ...groupIdJSON, ...columnSearchValues, ...filterString }
      : { ...groupIdJSON, ...columnSearchValues };
    let jsonString = JSON.stringify(combinedObject);
    setFilterString(jsonString);
    setPage(0);
    let payload = {
      page: 0,
      rowsPerPage,
      globalSearchValue,
      filterString: jsonString,
      sortByColumn: sortColumnValue?.columnName,
      sortValue: sortColumnValue?.sortValue,
    };
    setIsTableDataLoading(true);
    getBuyerByDebounce(payload);
  };

  //apply filters
  function applyFilters(obj, filtersCount) {
    setSearchParams((prevParams) => {
      const updatedParams = createSearchParams({
        ...Object.fromEntries(prevParams), // Spread the existing search params
        gfilters: obj ?? '',
        gFilterCount: filtersCount ?? '', // Update the filtersCount property
      });
      return updatedParams;
    });
    let groupIdJSON = data?.id
      ? JSON.parse(`{"groupIds":["${data?.id}"]}`)
      : {};
    let columnSearchValues = searchParams.get('gClmnSrchV')
      ? JSON.parse(searchParams.get('gClmnSrchV'))
      : '';
    let filterString = obj ? JSON.parse(obj) : {};

    let combinedObject = searchParams.get('gClmnSrchV')
      ? edit
        ? { ...groupIdJSON, ...columnSearchValues, ...filterString }
        : { ...columnSearchValues, ...filterString }
      : edit
      ? { ...groupIdJSON, ...filterString }
      : { ...filterString };
    let jsonString = JSON.stringify(combinedObject);
    setFilterString(jsonString);
    let payload = {
      page,
      rowsPerPage,
      globalSearchValue,
      filterString: jsonString,
      sortByColumn: sortColumnValue?.columnName,
      sortValue: sortColumnValue?.sortValue,
    };
    setIsTableDataLoading(true);
    const clearSelectedBuyers = true;
    getBuyerByDebounce(payload, clearSelectedBuyers);
  }
  function clearFilters() {
    try {
      let arrColumnValues = [];
      arrColumnValues = columnSearchValues?.map((column) => {
        if (typeof column.value === 'object') {
          column.value.label = '';
          column.value.value = '';
        } else {
          column.value = '';
        }

        if (document.getElementById('box_' + column.label)) {
          document.getElementById('box_' + column.label).style.backgroundColor =
            '';
        }
        return column;
      });
      setColumnSearchValues(arrColumnValues);
      setGlobalSearchValue('');
      setSortColumnValue({
        columnName: '',
        sortValue: '',
      });
      let filterString = searchParams?.get('gfilters')
        ? JSON.parse(searchParams?.get('gfilters'))
        : {};
      let jsonString = JSON.stringify(filterString);
      setFilterString(jsonString);
      setSearchParams((prevParams) => {
        const updatedParams = createSearchParams({
          ...Object.fromEntries(prevParams), // Spread the existing search params
          gClmnSrchV: '',
          // Update the filtersCount property
        });
        return updatedParams;
      });
      let payload = {
        page: page,
        rowsPerPage,
        globalSearchValue: '',
        filterString: searchParams?.get('gfilters') ? jsonString : '',
        sortByColumn: '',
        sortValue: '',
      };
      setIsTableDataLoading(true);
      const clearSelectedBuyers = true;
      getBuyerByDebounce(payload, clearSelectedBuyers);
    } catch (err) {
      LoggingService.log(
        'clearFilters',
        'CreateCustomerGroupModal',
        err,
        LogLevel.ERROR,
        'This error logged from create-customer-group-modal.jsx file line no 123',
        {}
      );
    }
  }
  const handleClose = () => {
    setSearchParams((prevParams) => {
      // Convert prevParams to an object and delete the specified keys
      const paramsObject = Object.fromEntries(prevParams);
      delete paramsObject.gfilters;
      delete paramsObject.gFilterCount;
      delete paramsObject.gClmnSrchV;
      delete paramsObject.isGroup;
      // Create new search params without the removed keys
      const updatedParams = createSearchParams(paramsObject);
      return updatedParams;
    });

    setFilterString('');
    setOpen(false);
  };
  return {
    onPageChangeHandler,
    handleChangeRowsPerPage,
    setPage,
    page,
    getBuyers,
    rowsPerPage,
    handleCheck,
    selectedBuyers,
    setSelectedBuyers,
    showEditGroupDetails,
    setShowEditGroupDetails,
    buyersData,
    globalSearchValue,
    setGlobalSearchValue,
    groupNameValue,
    setGroupNameValue,
    groupDescriptionValue,
    setGroupDescriptionValue,
    onSaveHandler,
    showApplyFiltersModal,
    setShowApplyFiltersModal,
    showZeroBuyerError,
    setShowZeroBuyerError,
    showBuyers,
    setShowBuyers,
    groups,
    setGroups,
    columnSearchValues,
    setColumnSearchValues,
    salesRep,
    setSalesRep,
    priceGroup,
    setPriceGroup,
    currency,
    setCurrency,
    filterString,
    setFilterString,
    applyFilters,
    isSelectAll,
    setIsSelectAll,
    handleClose,
    getBuyerByDebounce,
    isTableDataLoading,
    setIsTableDataLoading,
    sortColumnValue,
    setSortColumnValue,
    createColumnJsonString,
    clearFilters,
    isAllBuyerSelected,
    setIsAllBuyerSelected,
  };
}
