import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import HeaderCartLargeIcon from 'assets/icons/header-cart-large-icon';

import TextField from 'components/text-field';
import MyModal from '../modal';
import { useUpdateURLParams } from 'common-hooks/update-query-params-hooks';

export default function CreateNewDraftModal({
  open,
  setOpen,
  createDraft,
  edit,
  updateDraft,
  worksheetCollectionId,
  initialDraftName,
  quickPanelHandleClose,
  redirect,
}) {
  const { updateURLParams } = useUpdateURLParams();

  const handleClose = () => {
    setName('');
    setOpen(false);
  };

  const [name, setName] = useState('');
  const [error, setError] = useState({ status: false, message: '' });

  useEffect(() => {
    if (initialDraftName) setName(initialDraftName);
    else setName('New Draft');
  }, [initialDraftName]);

  function validateAndSubmit() {
    if (name === '') {
      setError({ status: true, message: 'Draft name cannot be empty.' });
    } else if (name === initialDraftName) {
      setError({
        status: true,
        message: 'Draft name cannot be the same as before.',
      });
    } else {
      if (edit) updateDraft(name, worksheetCollectionId);
      else {
        if (redirect) {
          updateURLParams({ resetCatalog: true });
          createDraft(name, quickPanelHandleClose, true);
        } else {
          createDraft(name, quickPanelHandleClose, false);
        }
      }

      setName('');
      handleClose();
    }
  }

  function resetError() {
    setError({ status: false, message: '' });
  }
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      validateAndSubmit();
    }
  };
  return (
    <MyModal
      open={open}
      setOpen={setOpen}
      size='large'
      showCloseIcon
      // title={}
      title={edit ? 'Update Draft' : 'New Draft'}
      editIconButton={
        <IconButton sx={{ height: '14px', width: '14px', marginLeft: '8px' }}>
          <HeaderCartLargeIcon fill='rgba(0, 0, 0, 0.6)' />
        </IconButton>
      }
    >
      <Stack
        spacing={2}
        justifyContent='center'
        alignItems='center'
        sx={{ position: 'relative' }}
      >
        <TextField
          autoFocus
          onFocus={(e) => {
            e.target.select();
          }}
          label='Draft Name'
          value={name}
          onChange={(e) => {
            setName(e.target.value);
            resetError();
          }}
          onKeyPress={handleKeyPress}
          error={error.status}
          helperText={error.message}
          sx={{ width: '360px' }}
        />

        <Stack direction='row' spacing={2}>
          <Button
            variant='outlined'
            color='secondary'
            onClick={(e) => {
              e.preventDefault();
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            onClick={(e) => {
              e.preventDefault();
              validateAndSubmit();
            }}
          >
            {edit ? 'Save Changes' : 'Create'}
          </Button>
        </Stack>
      </Stack>
    </MyModal>
  );
}

CreateNewDraftModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  createDraft: PropTypes.func,
  updateDraft: PropTypes.func,
  edit: PropTypes.bool,
  worksheetCollectionId: PropTypes.string.isRequired,
  initialDraftName: PropTypes.string,
  quickPanelHandleClose: PropTypes.func,
  redirect: PropTypes.bool,
};

CreateNewDraftModal.defaultProps = {
  edit: false,
  worksheetCollectionId: '',
  createDraft: () => {},
  updateDraft: () => {},
  initialDraftName: '',
  quickPanelHandleClose: () => {},
  redirect: false,
};
