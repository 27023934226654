import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { api } from 'api';
import { useQuery } from 'react-query';
import { showSnackbar } from 'components/snackbar/actions';
import { snackbarTypes } from 'constants/index';
import { setISODay } from 'date-fns';

export default function useSendShopifyOrderManually({ open, setOpen }) {
  const [orderNumber, setOrderNumber] = useState();
  const [selectedOrder, setSelectedOrder] = useState();
  const [initialValues, setInitialValues] = useState({
    shopifyStore: '',
    shopifyOrderId: '',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isSendApiLoading, setIsSendApiLoading] = useState(false);
  const dispatch = useDispatch();
  async function getClientMarketPlacesList() {
    const res = await api.get(`/clientMarketPlaces?marketplace_id=1`);
    if (res.data.success) {
      setIsLoading(false);
      if (res?.data?.data?.length == 1) {
        setInitialValues({
          shopifyStore: res?.data?.data[0],
          shopifyOrderId: '',
        });
      }
      return res?.data?.data;
    } else {
      setIsLoading(false);
      return [];
    } // or handle the case where status is falsy
  }
  const { data: shopifyList } = useQuery(
    ['clientMarketPlaces', open],
    getClientMarketPlacesList,
    {
      staleTime: 0, // Set staleTime to 0
      cacheTime: 0,
    }
  );

  const sendShopifyOrder = async (values) => {
    setIsSendApiLoading(true);
    const res = await api.post('sendOrderToShopify', {
      shop_name: values?.shopifyStore?.value ?? '',
      order_id: values?.shopifyOrderId ?? '',
    });
    if (res?.data?.status) {
      setIsSendApiLoading(false);
      dispatch(
        showSnackbar({
          snackbarMessage: res?.data?.message,
          type: snackbarTypes.SUCCESS,
        })
      );
      setOpen(false);
    } else {
      setIsSendApiLoading(false);
      dispatch(
        showSnackbar({
          snackbarMessage: res?.data?.message,
          type: snackbarTypes.ERROR,
        })
      );
    }
  };

  return {
    orderNumber,
    setOrderNumber,
    selectedOrder,
    setSelectedOrder,
    shopifyList,
    sendShopifyOrder,
    initialValues,
    isLoading,
    isSendApiLoading,
  };
}
