import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MyModal from 'components/modals/modal';
import Button from '@mui/material/Button';
import SendingEmailRecipient from './sending-email-recipient';
import { responsiveBreakpoints } from 'utils/utils';

export default function SendingEmailsModal({
  open,
  setOpen,
  emailRequests,
  setOpenParentModal,
  setOpenBackfill,
  showInventory,
  selectedSender,
  selectedSignature,
  orderSharePdf,
  orderSharePdfInline,
}) {
  const [emailSentInfo, setEmailSentInfo] = useState(0);
  const { mobile } = responsiveBreakpoints();
  useEffect(() => {
    if (!open) {
      setEmailSentInfo(0);
    }
    return () => {
      // You can perform cleanup here if needed
      setEmailSentInfo(0);
    };
  }, [open]);
  const handleClose = (bool) => {
    setOpenParentModal(false);
    setOpenBackfill(false);
    setEmailSentInfo(0);
    setOpen(bool);
  };
  var emailsSentText = (
    <Typography
      sx={{
        width: '100%',
        height: '40px',
        backgroundColor: '#E0FFE2',
        textAlign: 'center',
        fontSize: '18px',
        color: '#008800',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px 8px',
      }}
    >
      Emails Sent: {emailSentInfo} / {emailRequests?.length}
    </Typography>
  );
  const disclaimerText = (
    <Typography
      variant='body1'
      sx={{ marginRight: '20px', whiteSpace: 'nowrap' }}
    >
      Please do not close this modal until all emails are sent
    </Typography>
  );

  const close = (
    <Button
      variant='outlined'
      color='secondary'
      onClick={() => {
        handleClose();
      }}
    >
      Close
    </Button>
  );

  return (
    <MyModal
      open={open}
      setOpen={(bool) => {
        handleClose(bool);
      }}
      size='large'
      title='Email Sending Status'
      sx={
        mobile
          ? { width: '100%', height: '50vh' }
          : { width: '780px', height: '50vh' }
      }
      actions={[disclaimerText, emailsSentText, close]}
    >
      <Stack spacing={3}>
        <Stack spacing={1.5}>
          {emailRequests.map((request, i) => (
            <Stack key={i}>
              <SendingEmailRecipient
                request={request}
                setEmailSentInfo={setEmailSentInfo}
                showInventory={showInventory}
                selectedSender={selectedSender}
                selectedSignature={selectedSignature}
                orderSharePdf={orderSharePdf}
                orderSharePdfInline={orderSharePdfInline}
              />
            </Stack>
          ))}
        </Stack>
      </Stack>
    </MyModal>
  );
}

SendingEmailsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  openParentModal: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setOpenParentModal: PropTypes.func.isRequired,
  emailRequests: PropTypes.array.isRequired,
  setOpenBackfill: PropTypes.func,
  showInventory: PropTypes.bool,
  selectedSender: PropTypes.string,
  selectedSignature: PropTypes.string,
  orderSharePdf: PropTypes.bool,
  orderSharePdfInline: PropTypes.bool,
};
SendingEmailsModal.defaultProps = {
  selectedSender: '',
  selectedSignature: '',
  setOpenParentModal: () => {},
  setOpenBackfill: () => {},
  orderSharePdf: false,
  orderSharePdfInline: false,
};
