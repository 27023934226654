import { useState, useEffect, useCallback } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { PrivateScreen } from 'routes/screen-names';
import { selectCustomerDetailSelector } from 'redux/selectors';
import { getDepartments } from '../layout-api';
import { setDepartmentListItems } from '../../../redux/actions/global-actions';

import { useDispatch } from 'react-redux';
import SessionStorageManager from 'utils/session-storage-manager';

export const useDepartmentsBar = () => {
  const navigate = useNavigate();
  const customerDetail = useSelector(selectCustomerDetailSelector);
  const [openMegaMenu, setOpenMegaMenu] = useState(false);
  const [openMoreMenu, setOpenMoreMenu] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState();
  const [menuButtonXPosition, setMenuButtonXPosition] = useState(0);
  const [timeoutId, setTimeoutId] = useState(null);
  const [departmentContainerWidth, setDepartmentContainerWidth] =
    useState('100%');

  const { data: departments = [], isLoading: departmentsLoading } = useQuery(
    ['getDepartments'],
    () => getDepartments(),
    {
      cacheTime: 3600000,
      staleTime: 3600000,
      select: (data) => {
        if (data?.data?.data?.length <= 0 || !data?.data?.data) {
          SessionStorageManager.setSessionStorage('no_departments', true);
        } else SessionStorageManager.removeSessionStorage('no_departments');
        return data?.data?.data || [];
      },
    }
  );
  useEffect(() => {
    if (departments) {
      const parent = document.querySelector('.header2-container');
      const children = parent?.querySelectorAll('.child-element');
      let totalWidth = 0;
      let bool = true;
      let tempValue = 0;
      let lastChild;
      let x = 0;

      if (children) {
        children.forEach((child) => {
          totalWidth += child.getBoundingClientRect().width + 25;

          // tempValue = totalWidth;
          if (bool && totalWidth >= parent.getBoundingClientRect().width) {
            // tempValue = totalWidth - child.offsetWidth;
            if (x + 75.1 + 25 <= parent.getBoundingClientRect().width) {
              tempValue = x;
            } else {
              tempValue = x - lastChild.getBoundingClientRect().width - 25;
            }
            bool = false;
          }
          lastChild = child;
          x += child.getBoundingClientRect().width + 25;
        });
      }

      if (tempValue > 0) setDepartmentContainerWidth(tempValue);
      else setDepartmentContainerWidth(totalWidth);
      if (totalWidth >= parent?.getBoundingClientRect().width) {
        document.getElementById('and-more').style.display = 'block';
      }
    }
  }, [departments]);

  const handleOpenMegaMenu = useCallback(
    debounce(
      ({ department, menuButtonXPosition, closeDepartmentMenu = false }) => {
        try {
          if (closeDepartmentMenu) {
            setOpenMegaMenu(false);
            setSelectedDepartment();
          } else {
            setOpenMoreMenu(false);
            setOpenMegaMenu(true);
            setSelectedDepartment(department);
            setMenuButtonXPosition(menuButtonXPosition);
          }
        } catch (err) {
          console.error(err);
        }
      },
      500
    ),
    []
  );

  // const handleOpenMegaMenu = (department, menuButtonXPosition) => {
  //   setOpenMoreMenu(false);
  //   setOpenMegaMenu(true);
  //   setSelectedDepartment(department);
  //   setMenuButtonXPosition(menuButtonXPosition);
  // };

  const handleCloseMegaMenu = () => {
    setOpenMegaMenu(false);
    setSelectedDepartment();
  };

  const handleOpenMoreMenu = () => {
    setOpenMegaMenu(false);
    setOpenMoreMenu(true);
  };

  const handleCloseMoreMenu = () => {
    setOpenMoreMenu(false);
  };

  const handleShopByLookBookClick = () => {
    navigate(PrivateScreen.lookbooks);
  };

  const dispatch = useDispatch();

  const handleDepartmentClick = useCallback(
    debounce((department) => {
      dispatch(
        setDepartmentListItems([
          {
            genderId: department.genderId,
            collectionId: department.col,
          },
        ])
      );
      SessionStorageManager.removeSessionStorage('filters');
      SessionStorageManager.removeSessionStorage('searchFilters');

      handleCloseMegaMenu();
      handleCloseMoreMenu();
      navigate({
        pathname: '/catalog',
        search: `?gndr=${department.genderId}&cl=${department.col}&resetCatalog=true&start=0`,
      });
    }, 1000), // wait for 1s to start
    [] // dependencies for useCallback
  );

  return {
    departments,
    departmentsLoading,
    handleOpenMegaMenu,
    handleCloseMegaMenu,
    handleOpenMoreMenu,
    handleCloseMoreMenu,
    openMegaMenu,
    openMoreMenu,
    selectedDepartment,
    customerDetail,
    handleShopByLookBookClick,
    handleDepartmentClick,
    menuButtonXPosition,
    timeoutId,
    setTimeoutId,
    departmentContainerWidth,
  };
};
