import { useState, useRef } from 'react';
import { useMutation } from 'react-query';
import { api } from 'api';
import { useSelector } from 'react-redux';
import { loggedInUserSelector } from 'redux/selectors';
import { showSnackbar } from 'components/snackbar/actions';
import { snackbarTypes } from 'constants/index';
import { useDispatch } from 'react-redux';
export default function useAddContacts() {
  const user = useSelector(loggedInUserSelector);

  return {
    user,
  };
}
