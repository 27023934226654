import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Button } from '@mui/material';
import GridViewIcon from 'assets/icons/grid-view-icon';
import OrderEntryViewIcon from 'assets/icons/order-entry-view-icon';

const ViewToggleButton = ({ viewType, currentView, setView, Icon }) => {
  const isActive = viewType === currentView;

  const handleClick = () => {
    if (isActive) return;
    setView(viewType);
  };

  const iconStyle = {
    fill: isActive ? 'var(--theme-color)' : '#333',
    opacity: isActive ? 1 : 0.5,
  };

  return (
    <Button onClick={handleClick}>
      <Icon sx={iconStyle} />
    </Button>
  );
};

ViewToggleButton.propTypes = {
  viewType: PropTypes.string.isRequired,
  currentView: PropTypes.string.isRequired,
  setView: PropTypes.func.isRequired,
  Icon: PropTypes.any.isRequired,
};

const ProductViewToggle = ({ productViewStyle, setProductViewStyle }) => {
  return (
    <ButtonGroup size='small' color='secondary'>
      <ViewToggleButton
        viewType='GRID'
        currentView={productViewStyle}
        setView={setProductViewStyle}
        Icon={GridViewIcon}
      />
      <ViewToggleButton
        viewType='LIST'
        currentView={productViewStyle}
        setView={setProductViewStyle}
        Icon={OrderEntryViewIcon}
      />
    </ButtonGroup>
  );
};

ProductViewToggle.propTypes = {
  productViewStyle: PropTypes.string.isRequired,
  setProductViewStyle: PropTypes.func.isRequired,
};
ProductViewToggle.defaultProps = {};

export default ProductViewToggle;
