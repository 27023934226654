import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const MyRadio = ({
  label,
  options,
  row,
  required,
  onChange,
  defaultValue,
  value,
  disabled,
  id,
  name,
  formikOnChange,
}) => {
  return (
    <Box>
      {label ? (
        <InputLabel required={required} sx={{ marginBottom: '8px' }}>
          {label}
        </InputLabel>
      ) : null}
      <RadioGroup
        id={id}
        name={name}
        row={row}
        defaultValue={defaultValue}
        value={value}
        onChange={(e) => {
          formikOnChange(e);
          onChange(e.target.value);
        }}
      >
        {options.map((item) => {
          return (
            <Tooltip
              key={item?.value}
              title={item?.tooltipText}
              arrow
              disableGutters
              placement='bottom-start'
              PopperProps={{ sx: { background: 'transparent' } }}
            >
              <FormControlLabel
                key={item.value}
                value={item.value}
                control={
                  <Radio
                    disabled={disabled}
                    sx={{ padding: '0px 8px 0px 0px' }}
                  />
                }
                label={item.label}
                sx={{ margin: '0px 18px 0px 0px' }}
              />
            </Tooltip>
          );
        })}
      </RadioGroup>
    </Box>
  );
};

MyRadio.propTypes = {
  label: PropTypes.string,
  row: PropTypes.bool,
  options: PropTypes.array.isRequired,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  defaultValue: PropTypes.any,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  formikOnChange: PropTypes.func,
};

MyRadio.defaultProps = {
  row: false,
  label: null,
  required: false,
  defaultValue: null,
  value: null,
  onChange: () => {},
  disabled: false,
  id: '',
  name: '',
  formikOnChange: () => {},
};

export default MyRadio;
