import { CLEAR_SNACKBAR_MESSAGE, SNACKBAR_SHOW } from './actions';

const initialState = {
  snackbarMessage: '',
  snackbarType: 'success',
  snackbarAlign: {
    vertical: 'top',
    horizontal: 'right',
  },
};

const snackbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SNACKBAR_SHOW:
      return {
        ...state,
        snackbarMessage: action.payload.snackbarMessage,
        snackbarType: action.payload.type,
        snackbarAlign: action.payload.snackbarAlign
          ? action.payload.snackbarAlign
          : initialState.snackbarAlign,
      };
    case CLEAR_SNACKBAR_MESSAGE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default snackbarReducer;
