/* eslint-disable react/no-unknown-property */
import React from 'react';
import PropTypes from 'prop-types';

export default function LogoutIconM({ fill, active, width, height, stroke }) {
  return (
    <svg
      id='login'
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
    >
      <path
        id='Vector'
        d='M0,4.225C.258,1.225,1.8,0,5.175,0h.108C9.008,0,10.5,1.492,10.5,5.217V10.65c0,3.725-1.492,5.217-5.217,5.217H5.175c-3.35,0-4.892-1.208-5.167-4.158'
        transform='translate(7.417 2.075)'
        fill='none'
        stroke={stroke}
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='1.2'
      />
      <path
        id='Vector-2'
        data-name='Vector'
        d='M0,0H10.733'
        transform='translate(1.667 10)'
        fill='none'
        stroke={stroke}
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='1.2'
      />
      <path
        id='Vector-3'
        data-name='Vector'
        d='M0,0,2.792,2.792,0,5.583'
        transform='translate(10.542 7.208)'
        fill='none'
        stroke={stroke}
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='1.2'
      />
      <path
        id='Vector-4'
        data-name='Vector'
        d='M0,0H20V20H0Z'
        transform='translate(20 20) rotate(180)'
        fill='none'
        opacity='0'
      />
    </svg>
  );
}

LogoutIconM.propTypes = {
  fill: PropTypes.string,
  active: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  stroke: PropTypes.string,
};

LogoutIconM.defaultProps = {
  fill: 'var(--primary-icon-color)',
  active: 'false',
  width: '34px',
  height: '34px',
  stroke: '#f02711',
};
