export const fieldsName = {
  contactName: 'contactName',
  billingIdCode: 'billingIdCode',
  emailId: 'emailId',
  addressLineOne: 'addressLineOne',
  addressLine2: 'addressLine2',
  city: 'city',
  state: 'state',
  zipCode: 'zipCode',
  country: 'country',
  status: true,
};
