import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

export default function DeclineButton({ onClick }) {
  return (
    <Button variant='outlined' color='error' onClick={onClick}>
      Decline
    </Button>
  );
}

DeclineButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
