import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';

const BuyerRetailerInfoCard = ({ buyerUserData, retailerData }) => {
  return (
    <>
      <Grid item xs={12} sm={6} sx={{ paddingBottom: '12px' }}>
        <Stack spacing={1}>
          <Typography
            sx={{
              fontSize: '17px',
              fontWeight: 700,
              color: 'var(--primary-font-color)',
            }}
          >
            Buyer
          </Typography>
          <Box
            sx={{
              width: '355px',
              height: '98px',
              padding: '17px 20px 0 20px',
              display: 'flex',
              border: '1px solid var(--secondary-border-color)',
              borderRadius: '6px',
            }}
          >
            <Stack flexDirection='row' gap={2}>
              <Avatar
                name={buyerUserData?.name}
                src={buyerUserData?.profile_photo}
                height={46}
                width={46}
              />
              <Stack>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 900,
                    color: 'var(--primary-font-color)',
                  }}
                >
                  {buyerUserData?.name}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '15px',
                    fontWeight: 500,
                    color: 'var(--primary-font-color)',
                    opacity: 0.7,
                  }}
                >
                  {buyerUserData?.username}
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6} sx={{ paddingBottom: '12px' }}>
        <Stack spacing={1}>
          <Typography
            sx={{
              fontSize: '17px',
              fontWeight: 700,
              color: 'var(--primary-font-color)',
            }}
          >
            Retailer
          </Typography>
          <Box
            sx={{
              width: '355px',
              minHeight: '98px',
              padding: '17px 20px 0 20px',
              display: 'flex',
              border: '1px solid var(--secondary-border-color)',
              borderRadius: '6px',
            }}
          >
            <Stack flexDirection='row' gap={2}>
              <Avatar
                name={retailerData?.name}
                src={retailerData?.profile_photo}
                height={46}
                width={46}
              />
              <Stack>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 900,
                    color: 'var(--primary-font-color)',
                  }}
                >
                  {retailerData?.name}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '15px',
                    fontWeight: 500,
                    color: 'var(--primary-font-color)',
                    opacity: 0.7,
                  }}
                >
                  {retailerData?.address}
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Grid>
    </>
  );
};

BuyerRetailerInfoCard.propTypes = {
  retailerData: PropTypes.object.isRequired,
  buyerUserData: PropTypes.object.isRequired,
};

export default BuyerRetailerInfoCard;
