import React, { useRef } from 'react';
import * as Yup from 'yup';
import { api, apiEndPoints } from '../../../../api';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import MyModal from '../../modal';
import TextField from '../../../text-field';
import Textarea from '../../../textarea';
import Button from '@mui/material/Button';
import { Formik } from 'formik';
import { fieldsName } from './constant';
import { editGroupDetailsModalSchema } from './validation-schema';
import { checkGroupName } from '../../../../pages/customers/actions';

export default function EditGroupDetailsModal({
  open,
  setOpen,
  onSubmithandler,
  data,
}) {
  const formikRef = useRef(null);
  const dispatch = useDispatch();
  const { groupName, groupDesc } = fieldsName;
  //  since unable to pass state to hooks wrote function here
  const saveChangeButtonHandler = () => {
    formikRef.current.submitForm();
  };
  const onSubmitFormikHandler = (values) => {
    onSubmithandler(values);
    setOpen(!open);
  };

  const close = (
    <Button
      variant='outlined'
      color='secondary'
      onClick={() => {
        setOpen(false);
      }}
    >
      Close
    </Button>
  );

  const save = (
    <Button variant='contained' onClick={saveChangeButtonHandler}>
      Save Changes
    </Button>
  );
  const handleCheckBuyerUserName = async (username, setFieldError) => {
    if (username !== '' && username !== data?.name) {
      let payload = {
        groupName: username,
      };
      const data = await dispatch(checkGroupName(payload));
      if (data.data.success === false) {
        setFieldError('groupName', data.data.message);
      }
    }
  };
  return (
    <MyModal
      open={open}
      setOpen={setOpen}
      size='large'
      title='Edit Group Details'
      showCloseIcon
      actions={[close, save]}
    >
      <Formik
        innerRef={formikRef}
        validateOnMount
        validationSchema={editGroupDetailsModalSchema}
        initialValues={{
          groupName: data?.name,
          groupDesc: data?.description,
        }}
        onSubmit={onSubmitFormikHandler}
      >
        {({ handleSubmit, setFieldError, ...rest }) => (
          <form onSubmit={handleSubmit}>
            <TextField
              required
              id={groupName}
              name={groupName}
              label='Group Name'
              placeholder='Enter Group Name'
              value={rest.values.groupName}
              onChange={(e) => {
                rest.handleChange(e);
                // handleCheckBuyerUserName(e.target.value, setFieldError);
              }}
              error={rest.touched.groupName && Boolean(rest.errors.groupName)}
              helperText={rest.touched.groupName && rest.errors.groupName}
              onBlur={(e) => {
                rest.handleBlur(e);
                handleCheckBuyerUserName(rest.values.groupName, setFieldError);
              }}
              sx={{ width: '730px' }}
            />
            <Textarea
              id={groupDesc}
              name={groupDesc}
              label='Enter Group Description'
              placeholder='Enter Group Description'
              value={rest.values.groupDesc}
              onChange={rest.handleChange}
              error={rest.touched.groupDesc && Boolean(rest.errors.groupDesc)}
              helperText={rest.touched.groupDesc && rest.errors.groupDesc}
              rows={10}
              sx={{ marginTop: '10px' }}
            />
          </form>
        )}
      </Formik>
    </MyModal>
  );
}

EditGroupDetailsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onSubmithandler: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
  data: PropTypes.object,
};
