import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import MyModal from 'components/modals/modal';
import { responsiveBreakpoints } from 'utils/utils';
import { useSanitizeHTML } from 'common-hooks/sanitize-html';

const Tnc = ({ open, setOpen, tnc }) => {
  const { mobile, tablet } = responsiveBreakpoints();
  const { cleanHTML: cleanTnc } = useSanitizeHTML(tnc);
  const close = (
    <Button variant='contained' color='primary' onClick={() => setOpen(false)}>
      Close
    </Button>
  );

  return (
    <MyModal
      open={open}
      setOpen={setOpen}
      size='medium'
      title='Terms & Conditions'
      showCloseIcon
      actions={[close]}
      sx={{
        height: mobile || tablet ? '60vh' : '40vh',
        width: mobile || tablet ? '90vw' : '40vw',
      }}
    >
      <div dangerouslySetInnerHTML={{ __html: cleanTnc }}></div>
    </MyModal>
  );
};

Tnc.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  tnc: PropTypes.string.isRequired,
};

Tnc.defaultProps = {};

export default Tnc;
