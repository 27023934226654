import React from 'react';
import PropTypes from 'prop-types';

export default function CheckCircleIcon({ fill, sx }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='22'
      height='23'
      viewBox='0 0 22 23'
      fill='none'
      style={sx}
    >
      <path
        d='M14.9549 9.10263C15.205 8.81691 15.176 8.38255 14.8903 8.1325C14.6045 7.88246 14.1702 7.91147 13.9202 8.1972L9.52751 13.2174L7.28757 11.5374C6.98376 11.3096 6.55286 11.3712 6.32504 11.6749C6.09722 11.9787 6.15878 12.4096 6.46254 12.6374L9.21257 14.6999C9.50022 14.9157 9.90564 14.8733 10.1424 14.6026L14.9549 9.10263Z'
        fill={fill}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11 1.7749C5.68426 1.7749 1.375 6.08416 1.375 11.3999C1.375 16.7157 5.68426 21.0249 11 21.0249C16.3158 21.0249 20.625 16.7157 20.625 11.3999C20.625 6.08416 16.3158 1.7749 11 1.7749ZM2.75 11.3999C2.75 6.84355 6.44365 3.1499 11 3.1499C15.5563 3.1499 19.25 6.84355 19.25 11.3999C19.25 15.9562 15.5563 19.6499 11 19.6499C6.44365 19.6499 2.75 15.9562 2.75 11.3999Z'
        fill={fill}
      />
    </svg>
  );
}

CheckCircleIcon.propTypes = {
  fill: PropTypes.string,
  sx: PropTypes.object,
};

CheckCircleIcon.defaultProps = {
  fill: '#AAAAAA',
  sx: {},
};
