/* eslint-disable react/no-unknown-property */
import React from 'react';
import PropTypes from 'prop-types';

export default function DraftsQuickPanelIconM({
  fill,
  active,
  width,
  height,
  stroke,
}) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='25'
      viewBox='0 0 25 25'
    >
      <g
        id='Group_8978'
        data-name='Group 8978'
        transform='translate(-1095.597 -32.43)'
      >
        <g id='Group_9054' data-name='Group 9054'>
          <g
            id='vuesax_linear_bag-2'
            data-name='vuesax/linear/bag-2'
            transform='translate(1095.597 32.43)'
          >
            <g id='bag-2'>
              <path
                id='Vector'
                d='M0,5.662v-.97A4.773,4.773,0,0,1,4.06.022,4.5,4.5,0,0,1,9,4.5v1.38'
                transform='translate(8 2.008)'
                fill='none'
                stroke={stroke}
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='1.2'
              />
              <path
                id='Vector-2'
                data-name='Vector'
                d='M6.079,14h6.343c4.25,0,5.011-1.61,5.233-3.57l.793-6C18.733,1.99,17.993,0,13.479,0H5.022C.508,0-.232,1.99.054,4.43l.793,6C1.069,12.39,1.83,14,6.079,14Z'
                transform='translate(3.249 9)'
                fill='none'
                stroke={stroke}
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='1.2'
              />
              <path
                id='Vector-3'
                data-name='Vector'
                d='M.495.5H.5'
                transform='translate(15.647 12)'
                fill='none'
                stroke={stroke}
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='1.2'
              />
              <path
                id='Vector-4'
                data-name='Vector'
                d='M.495.5H.5'
                transform='translate(8.354 12)'
                fill='none'
                stroke={stroke}
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='1.2'
              />
              <path
                id='Vector-5'
                data-name='Vector'
                d='M0,0H25V25H0Z'
                fill='none'
                opacity='0'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

DraftsQuickPanelIconM.propTypes = {
  fill: PropTypes.string,
  active: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  stroke: PropTypes.string,
};

DraftsQuickPanelIconM.defaultProps = {
  fill: 'var(--primary-icon-color)',
  active: 'false',
  width: '34px',
  height: '34px',
  stroke: '#292d32',
};
