import React from 'react';
import './growing-spinner.css';
import IconButton from '@mui/material/IconButton';
import { Badge } from '@mui/material';
import PropTypes from 'prop-types';

const handleBadgeClick = (section) => {
  sessionStorage.setItem(section, true);
};

const GrowingSpinner = ({ section, sx }) => {
  return (
    <>
      {!sessionStorage.getItem(section) && (
        <IconButton color='inherit' className='ringing-bell'>
          <Badge
            badgeContent={''}
            color='error'
            style={sx}
            onClick={() => {
              handleBadgeClick(section);
            }}
          ></Badge>
        </IconButton>
      )}
    </>
  );
};

GrowingSpinner.propTypes = {
  section: PropTypes.string,
  sx: PropTypes.object,
};

GrowingSpinner.defaultProps = {
  section: '',
  sx: {},
};

export default GrowingSpinner;
