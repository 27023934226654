import { useState, useEffect, useRef } from 'react';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import { api } from 'api';
import { snackbarTypes } from 'constants/index';
import { showSnackbar } from 'components/snackbar/actions';
export default function useAddExternalContact({
  selectedItems,
  setSelectedItems,
}) {
  const [emailData, setEmailData] = useState([]);
  const formikRef = useRef();
  const dispatch = useDispatch();
  const tableContainerRef = useRef();
  const onSubmitFormikHandler = (values) => {
    let payload = {
      email: values.email,
    };
    addContacts.mutate(payload);
  };
  const getExternalSalesRepData = async () => {
    const res = await api.get(`getSalesRepExternalContacts`);
    if (res.data.status) {
      setEmailData(res?.data?.data);
      return res?.data?.data;
    }
    return {};
  };
  useEffect(() => {
    getExternalSalesRepData();
  }, []);
  // Add Contacts

  const addContacts = useMutation({
    mutationFn: (payload) => {
      return api.post('addSalesRepExternalContact', payload);
    },
    onSuccess: (data) => {
      if (data?.data?.status) {
        setEmailData((prev) => {
          return [
            {
              id: data?.data?.data?.id,
              contact_email: data?.data?.data?.contact_email,
            },
            ...prev,
          ];
        });
        setSelectedItems((prev) => {
          return [
            ...prev,
            {
              id: data?.data?.data?.id,
              contact_email: data?.data?.data?.contact_email,
            },
          ];
        });
        tableContainerRef.current.scrollTop = 0;
        formikRef?.current?.setFieldValue('email', '');
        formikRef?.current?.setFieldTouched('email', false);
      } else {
        dispatch(
          showSnackbar({
            snackbarMessage: data?.data?.message ?? 'Somthing went wrong',
            type: snackbarTypes.ERROR,
          })
        );
      }
      // getPdfDataQuery.refetch();
    },
  });

  // ======================> Remove Email ID <=========================================
  const deleteEmailQuery = useMutation({
    mutationFn: () => {
      return api.delete('order/removeContacts', {
        data: {
          ids: (() => {
            let updatedArray = [];
            selectedItems.forEach((item) => {
              updatedArray.push(parseInt(item.id));
            });
            return updatedArray;
          })(),
        },
      });
    },
    onSuccess: (data) => {
      //   getPdfDataQuery.refetch();
      setEmailData((prev) =>
        prev.filter(
          (item) => !selectedItems.some((selected) => selected.id === item.id)
        )
      );
      setSelectedItems([]);
    },
  });
  return {
    onSubmitFormikHandler,
    addContacts,
    deleteEmailQuery,
    emailData,
    formikRef,
    tableContainerRef,
  };
}
