export const alertModalStyles = {
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    backgroundColor: 'var(--paper-background-color)',
    border: 0,
    outline: 'none',
    borderRadius: '6px',
    margin: '0 100px 0 100px',
    padding: '49px 74px 48px 74px',
    width: '480px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  title: {
    fontWeight: 500,
    color: 'var(--primary-font-color)',
  },
  message: {
    fontWeight: 500,
    color: 'rgba(51, 51, 51, 0.75)',
  },
};
