import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SelectOld from '../../select/select.old';
import MyModal from '../modal';
import { Close } from '@mui/icons-material';
import { api } from '../../../api';

export default function CreateOfflineCatalogModal({ open, setOpen }) {
  const [collections, setCollections] = useState();
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState({ label: 'Select Collection', value: '' });
  useEffect(() => {
    getCollectionApi();
  }, [open]);

  const getCollectionApi = async () => {
    const res = await api.get('/offlineCollections');
    if (res) {
      setCollections(res.data.data);
      let option = [];
      res?.data?.data.forEach((item) => {
        option.push({
          label: item.name,
          value: item.id,
        });
      });
      setOptions(option);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <MyModal
      open={open}
      setOpen={setOpen}
      size='large'
      showCloseIcon
      sx={{ height: '300px', width: '500px' }}
    >
      <Stack
        spacing={7}
        justifyContent='center'
        alignItems='center'
        sx={{ position: 'relative' }}
      >
        <Stack spacing={2} justifyContent='center' alignItems='center'>
          <Stack direction='row' spacing={2}>
            <IconButton
              sx={{ position: 'absolute', top: 0, right: 0 }}
              onClick={(e) => {
                e.preventDefault();
                // setName('');
                handleClose();
              }}
            >
              <Close />
            </IconButton>
            <Typography variant='h1'>CREATE OFFLINE CATALOG</Typography>
          </Stack>
          <Stack>
            <Typography variant='subtitle1'>
              Collection To Use For Catalog (All Deliveries Are Included)
            </Typography>
          </Stack>
          <Stack>
            <SelectOld
              sx={{ width: '200px' }}
              value={value}
              setValue={setValue}
              options={options}
            />
          </Stack>
        </Stack>

        <Stack direction='row' spacing={2}>
          <Button
            variant='outlined'
            color='secondary'
            onClick={(e) => {
              e.preventDefault();
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            onClick={(e) => {
              e.preventDefault();
              //   validateAndSubmit();
            }}
          >
            Create
          </Button>
        </Stack>
      </Stack>
    </MyModal>
  );
}

CreateOfflineCatalogModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  createDraft: PropTypes.func,
  updateDraft: PropTypes.func,
  edit: PropTypes.bool,
  worksheetCollectionId: PropTypes.string.isRequired,
  initialDraftName: PropTypes.string,
  quickPanelHandleClose: PropTypes.func,
  redirect: PropTypes.bool,
};

CreateOfflineCatalogModal.defaultProps = {
  edit: false,
  worksheetCollectionId: '',
  createDraft: () => {},
  updateDraft: () => {},
  initialDraftName: '',
  quickPanelHandleClose: () => {},
  redirect: false,
};
