import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCustomerDetailSelector } from 'redux/selectors';
import PropTypes from 'prop-types';
import format from 'date-fns/format'; // Import format from date-fns

import InputLabel from '@mui/material/InputLabel';
import { TextField } from '@mui/material';
import { Box } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import DateFnsUtils from '@date-io/date-fns';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';

const CustomDatePicker = ({
  label,
  sx,
  onChange,
  value,
  shouldDisableDate,
  disabled,
  readOnly,
  marginBottom,
  showPopupActions,
  id,
  name,
  required,
  error,
  helperText,
}) => {
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const clientInfo = useSelector(selectCustomerDetailSelector);

  return (
    <Box
      sx={{
        ...sx,
      }}
      marginBottom={marginBottom}
    >
      {label ? <InputLabel required={required}>{label}</InputLabel> : null}
      <LocalizationProvider dateAdapter={DateFnsUtils}>
        <DatePicker
          inputFormat={clientInfo?.dateformat?.dateFnsFormat}
          id={id}
          name={name}
          open={openDatePicker}
          onClose={() => setOpenDatePicker(false)}
          value={
            value
              ? format(new Date(value), clientInfo?.dateformat?.dateFnsFormat)
              : ''
          } // Format the value for display
          onChange={onChange}
          shouldDisableDate={shouldDisableDate}
          disabled={disabled}
          readOnly={readOnly}
          closeOnSelect
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                disabled={disabled}
                readOnly={true}
                value={
                  value
                    ? format(
                        new Date(value),
                        clientInfo?.dateformat?.dateFnsFormat
                      )
                    : ''
                } // Format the value for display
                name={name}
                error={error}
                disableError
                placeholder={
                  params.inputProps.placeholder ||
                  clientInfo?.dateformat?.dateFnsFormat.toLowerCase()
                }
                inputProps={{}}
                InputProps={{
                  style: {
                    padding: '0 0 0 8px',
                  },
                  endAdornment: (
                    <InputAdornment position='end'>
                      <CalendarTodayIcon
                        sx={{
                          height: '15px',
                          marginRight: '3px',
                          cursor: 'pointer',
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                onClick={() => setOpenDatePicker(true)}
              />
            );
          }}
          componentsProps={{
            actionBar: {
              // The actions will be the same between desktop and mobile
              actions: showPopupActions ? ['clear', 'accept'] : [],
            },
          }}
        />
      </LocalizationProvider>
      {error ? (
        <Typography variant='body2' sx={{ color: 'var(--error-color)' }}>
          {helperText}
        </Typography>
      ) : null}
    </Box>
  );
};
CustomDatePicker.propTypes = {
  label: PropTypes.string,
  sx: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.string,
  shouldDisableDate: PropTypes.func,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  marginBottom: PropTypes.string,
  showPopupActions: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};
CustomDatePicker.defaultProps = {
  label: '',
  sx: {},
  onChange: () => {},
  value: '',
  shouldDisableDate: () => {},
  disabled: false,
  readOnly: false,
  marginBottom: '',
  showPopupActions: false,
  id: '',
  name: '',
  required: false,
  error: false,
  helperText: '',
};
export default CustomDatePicker;
