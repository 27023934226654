import React, { useState } from 'react';
import { selectClientOptionsSelector } from 'redux/selectors';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import TextField from 'components/text-field';
import MyModal from 'components/modals/modal';
import Textarea from 'components/textarea';

import { isEnteredValueExceeded } from 'utils/utils';

export default function CreateNewSuggestionModal({
  open,
  setOpen,
  edit,
  initialValues,
  createSuggestion,
  updateSuggestion,
  id,
  quickPanelHandleClose,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  const [name, setName] = useState(initialValues.name ?? '');
  const [note, setNote] = useState(initialValues.description ?? '');
  const [error, setError] = useState({ status: false, message: '' });
  const clientOptions = useSelector(selectClientOptionsSelector);

  function validateAndSubmit() {
    if (name === '') {
      setError({ status: true, message: 'Suggestion name cannot be empty.' });
    } else if (
      isEnteredValueExceeded(name, clientOptions?.suggestionPdfTitleMaxLength)
    ) {
      setError({
        status: true,
        message: `Suggestion name cannot exceede ${clientOptions?.suggestionPdfTitleMaxLength} characters`,
      });
    } else {
      if (edit) {
        updateSuggestion(id, name, note);
      } else createSuggestion(name, note, quickPanelHandleClose);
      handleClose();
    }
  }

  function resetError() {
    setError({ status: false, message: '' });
  }

  return (
    <MyModal
      open={open}
      setOpen={(bool) => {
        setOpen(bool), setError({ status: false, message: '' });
      }}
      size='large'
      showCloseIcon
    >
      <Stack spacing={2} justifyContent='center' alignItems='center'>
        <Typography variant='h1'>
          {edit ? 'Edit Suggestion' : 'Create New Suggestion'}
        </Typography>
        <TextField
          autoFocus
          onFocus={(e) => {
            e.target.select();
          }}
          label='Suggestion Name'
          placeholder='Enter suggestion name'
          value={name}
          onChange={(e) => {
            setName(e.target.value);
            if (
              error.status &&
              e.target.value < clientOptions?.suggestionPdfTitleMaxLength
            )
              resetError();
          }}
          error={error.status}
          helperText={error.message}
          sx={{ width: '360px' }}
        />
        <Textarea
          rows={4}
          label='Note'
          value={note}
          onChange={(e) => {
            setNote(e.target.value);
          }}
          placeholder='Enter note'
          sx={{ width: '360px' }}
        />
        <Stack direction='row' spacing={2}>
          <Button
            variant='outlined'
            color='secondary'
            onClick={(e) => {
              e.preventDefault();
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            onClick={(e) => {
              e.preventDefault();
              validateAndSubmit();
            }}
          >
            {edit ? 'Save Changes' : 'Create'}
          </Button>
        </Stack>
      </Stack>
    </MyModal>
  );
}

CreateNewSuggestionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  edit: PropTypes.bool,
  initialValues: PropTypes.object,
  createSuggestion: PropTypes.func,
  updateSuggestion: PropTypes.func,
  quickPanelHandleClose: PropTypes.func,
  id: PropTypes.string,
};

CreateNewSuggestionModal.defaultProps = {
  edit: false,
  initialValues: { name: '', note: '' },
  createSuggestion: () => {},
  updateSuggestion: () => {},
  quickPanelHandleClose: () => {},
  id: '',
};
