import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Box, Divider } from '@mui/material';

import LightBulbOnIcon from '../../../assets/icons/light-bulb-on-icon';
import no_image_cdn from '../../../assets/images/no_image_cdn.png';
import no_image from '../../../assets/images/no_image.png';

export default function ProductCard({
  id,
  image,
  name,
  style,
  cost,
  discountedCost,
  msrp,
  isInSuggestion,
  removeProductFromSuggestion,
  userCurrencySymbol,
  productImageLinkTag,
  showSuggestionIcon,
  quantity,
  settings,
}) {
  const [inSuggestion, setInSuggestion] = useState(isInSuggestion);

  // cdn broken image logic
  let error_image = productImageLinkTag ? no_image_cdn : no_image;
  const handleImageError = (e) => {
    e.target.onerror = null;
    e.target.src = error_image;
  };

  return (
    <Card
      elevation={0}
      sx={[
        {
          width: '95%',
          maxWidth: '242px',
          minHeight: '284px',
          position: 'relative',
          border: '1px solid #E0E0E0',
          height: '100%',
        },
      ]}
    >
      <Box sx={{ width: '100%', height: '188px', padding: '7px 17px' }}>
        <CardMedia
          component='img'
          image={image}
          onError={handleImageError}
          sx={[
            {
              // borderBottom: '1px solid var(--secondary-border-color)',
              height: '100%',
              width: '100%',
              objectFit: 'contain',
            },
          ]}
        />
      </Box>
      <Divider
        sx={{
          width: '100%',
          backgroundColor: 'var(--secondary-border-color)',
        }}
      />
      <CardContent sx={{ padding: '19px 12.74 15px 12.5ox' }}>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='start'
          columnGap='8px'
        >
          <Stack>
            <Typography
              variant='body1'
              sx={{
                fontWeight: '900',
                lineHeight: '24px',
              }}
            >
              {name}
            </Typography>
            <Typography
              variant='subtitle2'
              sx={{
                lineHeight: '24px',
                fontWeight: '500',
              }}
            >
              {style}
            </Typography>
          </Stack>
          {showSuggestionIcon ? (
            <Stack
              sx={[
                {
                  padding: '4px',
                  borderRadius: '20px',
                  background: '#ef9c002b',
                },
              ]}
              direction='row'
            >
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  setInSuggestion(!inSuggestion);
                  removeProductFromSuggestion(id);
                }}
              >
                <LightBulbOnIcon
                  fill='var(--theme-color)'
                  width='25'
                  height='25'
                />
                {/*<SuggestionIcon*/}
                {/*  //fill={inSuggestion ? 'var(--theme-color)' : '#9490a1'}*/}
                {/*  fill='var(--theme-color)'*/}
                {/*/>*/}
              </IconButton>
            </Stack>
          ) : null}
        </Stack>
        <Stack direction='row'>
          <Box sx={{ width: '50%' }}>
            {settings?.showPrice ? (
              discountedCost > 0 ? (
                <Typography
                  variant='subtitle2'
                  sx={{
                    color: 'var(--primary-font-color)',
                    fontSize: '12px',
                    lineHeight: '24px',
                    fontWeight: '700',
                  }}
                >
                  <span style={{ fontWeight: 300 }}>COST: {''}</span>
                  <span
                    style={{
                      textDecoration: 'line-through',
                    }}
                  >
                    {userCurrencySymbol}
                    {cost}
                  </span>{' '}
                  /{' '}
                  <span
                    style={{
                      color: discountedCost > 0 ? 'red' : '',
                    }}
                  >
                    {userCurrencySymbol}
                    {discountedCost}
                  </span>
                </Typography>
              ) : (
                <Typography
                  variant='subtitle2'
                  sx={{
                    color: 'var(--primary-font-color)',
                    fontSize: '12px',
                    lineHeight: '24px',
                    fontWeight: '700',
                  }}
                >
                  <span style={{ fontWeight: 300 }}>COST: </span>
                  <span>
                    {userCurrencySymbol}
                    {cost}
                  </span>
                </Typography>
              )
            ) : null}
          </Box>
          {settings?.showMSRP ? (
            <Box
              sx={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}
            >
              <Typography
                variant='subtitle2'
                sx={{
                  color: 'var(--primary-font-color)',
                  fontSize: '12px',
                  lineHeight: '24px',
                  fontWeight: '700',
                }}
              >
                <span style={{ fontWeight: 300 }}>MSRP: </span>
                <span>
                  {userCurrencySymbol}
                  {msrp}
                </span>
              </Typography>
            </Box>
          ) : null}
        </Stack>
        {settings?.quantity ? (
          <Box>
            <Typography
              variant='subtitle2'
              sx={{
                color: 'var(--primary-font-color)',
                fontSize: '12px',
                lineHeight: '24px',
                fontWeight: '900',
              }}
            >
              <span style={{ fontWeight: 300 }}>Qty: </span>
              <span>{quantity}</span>
            </Typography>
          </Box>
        ) : null}
      </CardContent>
    </Card>
  );
}

ProductCard.propTypes = {
  id: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  style: PropTypes.string.isRequired,
  cost: PropTypes.number.isRequired,
  msrp: PropTypes.number.isRequired,
  discountedCost: PropTypes.number,
  isInSuggestion: PropTypes.bool.isRequired,
  removeProductFromSuggestion: PropTypes.func.isRequired,
  userCurrencySymbol: PropTypes.string,
  productImageLinkTag: PropTypes.number,
  showSuggestionIcon: PropTypes.bool,
  quantity: PropTypes.string,
  settings: PropTypes.object,
};

ProductCard.defaultProps = {
  userCurrencySymbol: '$',
  discountedCost: 0,
  showSuggestionIcon: false,
  quantity: '',
  settings: {
    showMSRP: true,
    showPrice: true,
  },
};
