import React from 'react';
import PropTypes from 'prop-types';
import MyModal from '../modal';
import useRequestInfoModal from './request-info-model-hooks';
import TextField from '../../text-field';
import Textarea from '../../textarea';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

export default function AddRequestInfoModal({
  open,
  setOpen,
  data,
  sendApiFunc,
}) {
  const { notes, setNotes, firstSalesRepForBuyer, date } = useRequestInfoModal(
    data,
    open
  );

  const close = (
    <Button
      variant='outlined'
      color='secondary'
      onClick={(e) => {
        e.preventDefault();
        setOpen(false);
      }}
    >
      Cancel
    </Button>
  );

  const add = (
    <Button
      variant='contained'
      onClick={(e) => {
        sendApiFunc(notes, setOpen);
      }}
    >
      Confirm
    </Button>
  );

  return (
    <MyModal
      open={open}
      setOpen={setOpen}
      size='large'
      title='PRODUCT INFORMATION REQUEST EMAIL'
      showCloseIcon
      actions={[close, add]}
    >
      <Stack direction='row' spacing={2}>
        <div>To: {firstSalesRepForBuyer}</div>
        <div>Date: {date}</div>
      </Stack>

      <Textarea
        placeholder='Comments'
        rows={10}
        value={notes}
        sx={{ width: '730px' }}
        onChange={(e) => setNotes(e.target.value)}
      />
    </MyModal>
  );
}

AddRequestInfoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  sendApiFunc: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
};
