import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import moment from 'moment/moment';
import { Formik } from 'formik';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from 'components/text-field';
import MyModal from 'components/modals/modal';
import SelectOld from 'components/select/select.old';
import { fieldsName } from './buyer-edit-request-modal-helper';
import BuyerRetailerInfoCard from './buyer-retailer-info-cards';
import ArrowNextIcon from 'assets/icons/arrow-next-icon';
import ArrowPrevIcon from 'assets/icons/arrow-prev-icon';

const BuyerBillShipEditRequestModal = ({
  open,
  setOpen,
  handleApproveClick,
  declineBtnClick,
  isShippingRequest,
  countryOptions,
  notificationDetails,
  isCustomTitle,
  customTitle,
}) => {
  const [initialValue, setInitialValue] = useState({
    id: '',
    retailerId: '',
    name: '',
    street: '',
    street2: '',
    city: '',
    state: '',
    zip: '',
    status: '',
    active: '',
    country: '',
    shippingCode: '',
    attention: '',
    billingCode: '',
    email: '',
    billingId: '',
    shippingAddressTableId: '',
  });
  const formikRef = useRef(null);
  const { name, street, street2, city, state, zip, country, billingId, email } =
    fieldsName;
  const {
    buyerUserData,
    currentData,
    formRequestData,
    retailerData,
    created_at,
  } = notificationDetails;
  useEffect(() => {
    let obj = {
      id: formRequestData?.id,
      retailerId: formRequestData?.retailerId,
      name: formRequestData?.name,
      street: formRequestData?.street,
      street2: formRequestData?.street2,
      city: formRequestData?.city,
      state: formRequestData?.state,
      zip: formRequestData?.zip,
      status: formRequestData?.status,
      active: formRequestData?.active,
      country:
        formRequestData?.country ||
        countryOptions.find((c) => c.value == 'US')?.value,
      shippingCode: formRequestData?.shippingCode,
      attention: formRequestData?.attention,
      billingCode: formRequestData?.billingCode,
      email: formRequestData?.email,
      billingId: formRequestData?.billingCode,
      shippingAddressTableId: formRequestData?.shippingAddressTableId,
    };
    setInitialValue(obj);
  }, [notificationDetails]);

  const decline = (
    <Button
      variant='outlined'
      color='error'
      onClick={() => {
        declineBtnClick();
        setOpen(false);
      }}
    >
      Decline
    </Button>
  );

  const approve = (
    <Button variant='contained' onClick={() => formikRef.current.submitForm()}>
      Approve
    </Button>
  );

  return (
    <MyModal
      open={open}
      setOpen={setOpen}
      size='large'
      title='Buyer Edit Request'
      showCloseIcon
      actions={[decline, approve]}
      sx={{ width: '780px', height: '80vh' }}
      customTitle={customTitle}
      isCustomTitle={isCustomTitle}
    >
      <Grid container columnSpacing={3}>
        <BuyerRetailerInfoCard
          buyerUserData={buyerUserData}
          retailerData={retailerData}
        />
        <Grid item xs={12} sm={12} sx={{ paddingBottom: '28px' }}>
          <Typography
            variant='body1'
            sx={{ color: 'var(--secondary-font-color)' }}
          >
            Request On:{' '}
            <span
              style={{ color: 'var(--primary-font-color)', fontWeight: 500 }}
            >
              {moment(created_at).format('MMM Do,  YYYY  HH:MM')}
            </span>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography
            variant='h2'
            sx={{
              fontSize: '16px',
              borderBottom: '1px solid var(--secondary-border-color)',
              padding: '0 0 10px 0',
              marginBottom: '16px',
            }}
          >
            Changes Requested
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              border: '1px solid var(--secondary-border-color)',
              borderRadius: '6px',
              backgroundColor: 'var(--paper-background-color)',
            }}
          >
            <Typography
              variant='h2'
              sx={{
                fontSize: '16px',
                borderBottom: '1px solid var(--secondary-border-color)',
                padding: '13px 0 13px 20px',
              }}
            >
              Current {isShippingRequest ? 'Shipping' : 'Billing'} Address
            </Typography>

            <Stack spacing={4.6} sx={{ padding: '20px' }}>
              <Stack spacing={0}>
                <Typography
                  variant='body1'
                  sx={{ color: 'var(--secondary-font-color)' }}
                >
                  {isShippingRequest ? 'Location Name' : 'Contact Name'}
                </Typography>
                <Typography
                  variant='body1'
                  sx={{ fontWeight: 500, height: '1.4375em' }}
                >
                  {currentData?.name || 'Not available'}
                </Typography>
              </Stack>

              <Stack
                spacing={0}
                sx={{ display: !isShippingRequest ? 'block' : 'none' }}
              >
                <Typography
                  variant='body1'
                  sx={{ color: 'var(--secondary-font-color)' }}
                >
                  {!isShippingRequest ? 'Billing ID / CODE' : ''}
                </Typography>
                <Typography
                  variant='body1'
                  sx={{ fontWeight: 500, height: '1.4375em' }}
                >
                  {currentData?.billingId || 'Not available'}
                </Typography>
              </Stack>

              <Stack
                spacing={0}
                sx={{ display: !isShippingRequest ? 'block' : 'none' }}
              >
                <Typography
                  variant='body1'
                  sx={{ color: 'var(--secondary-font-color)' }}
                >
                  {!isShippingRequest ? 'Email' : ''}
                </Typography>
                <Typography
                  variant='body1'
                  sx={{ fontWeight: 500, height: '1.4375em' }}
                >
                  {currentData?.email || 'Not available'}
                </Typography>
              </Stack>

              <Stack spacing={0}>
                <Typography
                  variant='body1'
                  sx={{ color: 'var(--secondary-font-color)' }}
                >
                  Address 1
                </Typography>
                <Typography
                  variant='body1'
                  sx={{ fontWeight: 500, height: '1.4375em' }}
                >
                  {currentData?.street || 'Not available'}
                </Typography>
              </Stack>

              <Stack spacing={0}>
                <Typography
                  variant='body1'
                  sx={{ color: 'var(--secondary-font-color)' }}
                >
                  Address 2
                </Typography>
                <Typography
                  variant='body1'
                  sx={{ fontWeight: 500, height: '1.4375em' }}
                >
                  {currentData?.street2 || 'Not available'}
                </Typography>
              </Stack>

              <Stack spacing={0}>
                <Typography
                  variant='body1'
                  sx={{ color: 'var(--secondary-font-color)' }}
                >
                  City
                </Typography>
                <Typography
                  variant='body1'
                  sx={{ fontWeight: 500, height: '1.4375em' }}
                >
                  {currentData?.city || 'Not available'}
                </Typography>
              </Stack>

              <Stack spacing={0}>
                <Typography
                  variant='body1'
                  sx={{ color: 'var(--secondary-font-color)' }}
                >
                  State / Province
                </Typography>
                <Typography
                  variant='body1'
                  sx={{ fontWeight: 500, height: '1.4375em' }}
                >
                  {currentData?.state || 'Not available'}
                </Typography>
              </Stack>

              <Stack spacing={0}>
                <Typography
                  variant='body1'
                  sx={{ color: 'var(--secondary-font-color)' }}
                >
                  Zip / Postal Code
                </Typography>
                <Typography
                  variant='body1'
                  sx={{ fontWeight: 500, height: '1.4375em' }}
                >
                  {currentData?.zip || 'Not available'}
                </Typography>
              </Stack>

              <Stack spacing={0}>
                <Typography
                  variant='body1'
                  sx={{ color: 'var(--secondary-font-color)' }}
                >
                  Country
                </Typography>
                <Typography
                  variant='body1'
                  sx={{ fontWeight: 500, height: '1.4375em' }}
                >
                  {currentData?.country || 'Not available'}
                </Typography>
              </Stack>
              <Stack spacing={0}>
                <Typography
                  variant='body1'
                  sx={{ color: 'var(--secondary-font-color)' }}
                >
                  Status
                </Typography>
                <Typography
                  variant='body1'
                  sx={{ fontWeight: 500, height: '1.4375em' }}
                >
                  {isShippingRequest
                    ? currentData?.active
                      ? 'Active'
                      : 'Inactive'
                    : currentData?.status
                    ? 'Active'
                    : 'Inactive'}
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              border: '1px solid var(--secondary-border-color)',
              borderRadius: '6px',
              backgroundColor: 'var(--paper-background-color)',
            }}
          >
            <Typography
              variant='h2'
              sx={{
                fontSize: '16px',
                borderBottom: '1px solid var(--secondary-border-color)',
                padding: '13px 0 13px 20px',
              }}
            >
              New {isShippingRequest ? 'Shipping' : 'Billing'} Address
            </Typography>

            <Stack spacing={2} sx={{ padding: '20px' }}>
              <Formik
                innerRef={formikRef}
                validateOnMount
                // validationSchema={}
                initialValues={initialValue}
                enableReinitialize={true}
                onSubmit={handleApproveClick}
              >
                {({ handleSubmit, ...rest }) => (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      label={
                        isShippingRequest ? 'Location Name' : 'Contact Name'
                      }
                      placeholder={
                        isShippingRequest
                          ? 'Enter Location Name'
                          : 'Enter Contact Name'
                      }
                      id={name}
                      name={name}
                      value={rest.values.name}
                      onChange={rest.handleChange}
                      error={rest.touched.name && Boolean(rest.errors.name)}
                      helperText={rest.touched.name && rest.errors.name}
                      sx={{
                        marginBottom: '17px',
                        color:
                          currentData[name] != formRequestData[name]
                            ? '#ff0033'
                            : 'inherit',
                      }}
                    />

                    <TextField
                      label={!isShippingRequest ? 'Billing ID / CODE' : ''}
                      placeholder={isShippingRequest ? 'Billing ID / CODE' : ''}
                      id={billingId}
                      name={billingId}
                      value={rest.values.billingId}
                      onChange={rest.handleChange}
                      error={
                        rest.touched.name && Boolean(rest.errors.billingId)
                      }
                      helperText={rest.touched.name && rest.errors.billingId}
                      sx={{
                        marginBottom: '17px',
                        color:
                          currentData[billingId] != formRequestData[billingId]
                            ? '#ff0033'
                            : 'inherit',
                        display: !isShippingRequest ? 'block' : 'none',
                      }}
                    />

                    <TextField
                      label={!isShippingRequest ? 'Email' : ''}
                      placeholder={isShippingRequest ? 'Email' : ''}
                      id={email}
                      name={email}
                      value={rest.values.email}
                      onChange={rest.handleChange}
                      error={rest.touched.name && Boolean(rest.errors.email)}
                      helperText={rest.touched.name && rest.errors.email}
                      sx={{
                        marginBottom: '17px',
                        color:
                          currentData[email] != formRequestData[email]
                            ? '#ff0033'
                            : 'inherit',
                        display: !isShippingRequest ? 'block' : 'none',
                      }}
                    />

                    <TextField
                      placeholder='Enter Address Line 1'
                      label='Address Line 1'
                      id={street}
                      name={street}
                      value={rest.values.street}
                      onChange={rest.handleChange}
                      error={rest.touched.street && Boolean(rest.errors.street)}
                      helperText={rest.touched.street && rest.errors.street}
                      sx={{
                        marginBottom: '17px',
                        color:
                          currentData[street] != formRequestData[street]
                            ? '#ff0033'
                            : 'inherit',
                      }}
                    />
                    <TextField
                      placeholder='Enter Address Line 2'
                      label='Address Line 2'
                      id={street2}
                      name={street2}
                      value={rest.values.street2}
                      onChange={rest.handleChange}
                      error={
                        rest.touched.street2 && Boolean(rest.errors.street2)
                      }
                      helperText={rest.touched.street2 && rest.errors.street2}
                      sx={{
                        marginBottom: '17px',
                        color:
                          currentData[street2] != formRequestData[street2]
                            ? '#ff0033'
                            : 'inherit',
                      }}
                    />
                    <TextField
                      placeholder='Enter City'
                      label='City'
                      id={city}
                      name={city}
                      value={rest.values.city}
                      onChange={rest.handleChange}
                      error={rest.touched.city && Boolean(rest.errors.city)}
                      helperText={rest.touched.city && rest.errors.city}
                      sx={{
                        marginBottom: '17px',
                        color:
                          currentData[city] != formRequestData[city]
                            ? '#ff0033'
                            : 'inherit',
                      }}
                    />
                    <TextField
                      placeholder='Enter State / Province'
                      label='State / Province'
                      id={state}
                      name={state}
                      value={rest.values.state}
                      onChange={rest.handleChange}
                      error={rest.touched.state && Boolean(rest.errors.state)}
                      helperText={rest.touched.state && rest.errors.state}
                      sx={{
                        marginBottom: '17px',
                        color:
                          currentData[state] != formRequestData[state]
                            ? '#ff0033'
                            : 'inherit',
                      }}
                    />
                    <TextField
                      placeholder='Enter Zip / Postal Code'
                      label='Zip / Postal Code'
                      id={zip}
                      name={zip}
                      value={rest.values.zip}
                      onChange={rest.handleChange}
                      error={rest.touched.zip && Boolean(rest.errors.zip)}
                      helperText={rest.touched.zip && rest.errors.zip}
                      sx={{
                        marginBottom: '17px',
                        color:
                          currentData[zip] != formRequestData[zip]
                            ? '#ff0033'
                            : 'inherit',
                      }}
                    />
                    <TextField
                      id='country'
                      name='country'
                      label='Country'
                      placeholder='Enter Country'
                      value={rest?.values?.country}
                      onChange={rest?.handleChange}
                      error={
                        rest?.touched?.country && Boolean(rest?.errors?.country)
                      }
                      helperText={
                        rest?.touched?.country && rest?.errors?.country
                      }
                      sx={{
                        marginBottom: '17px',
                        color:
                          currentData[country] != formRequestData[country]
                            ? '#ff0033'
                            : 'inherit',
                      }}
                    />
                    <TextField
                      readOnly
                      label='Status'
                      placeholder='Enter Status'
                      value={
                        isShippingRequest
                          ? formRequestData?.active
                            ? 'Active'
                            : 'Inactive'
                          : formRequestData?.status
                          ? 'Active'
                          : 'Inactive'
                      }
                      sx={{
                        color:
                          currentData?.active != formRequestData?.active ||
                          currentData?.status != formRequestData?.status
                            ? '#ff0033'
                            : 'inherit',
                      }}
                    />
                  </form>
                )}
              </Formik>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </MyModal>
  );
};

BuyerBillShipEditRequestModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  handleApproveClick: PropTypes.func.isRequired,
  declineBtnClick: PropTypes.func.isRequired,
  isShippingRequest: PropTypes.func.isRequired,
  countryOptions: PropTypes.array.isRequired,
  notificationDetails: PropTypes.object.isRequired,
  isCustomTitle: PropTypes.bool,
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  onCustomTitlePageChange: PropTypes.func,
  customTitle: PropTypes.node,
};
BuyerBillShipEditRequestModal.defaultProps = {
  isCustomTitle: false,
  customTitle: <></>,
};

export default BuyerBillShipEditRequestModal;
