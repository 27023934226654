export const suggestionEmailPayloadSelector = ({
  sendEmailReducer: { suggestionEmailPayload = [] } = {},
}) => suggestionEmailPayload;

export const catalogPdfPayloadSelector = ({
  sendEmailReducer: { catalogPdfPayload = [] } = {},
}) => catalogPdfPayload;

export const catalogLinkPayloadSelector = ({
  sendEmailReducer: { catalogLinkPayload = [] } = {},
}) => catalogLinkPayload;

export const draftLinkPayloadSelector = ({
  sendEmailReducer: { draftLinkPayload = [] } = {},
}) => draftLinkPayload;

export const orderSharePdfPayloadSelector = ({
  sendEmailReducer: { orderSharePdfPayload = [] } = {},
}) => orderSharePdfPayload;
