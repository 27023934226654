import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Stack, Typography, Box, Checkbox, InputLabel } from '@mui/material';

import TextField from 'components/text-field';
import SelectOld from 'components/select/select.old';

import { stepsStyle as styles } from '../styles';

import { getStateData, getCityData, getCountryData } from 'utils/utils';

import { additionalSettingsFieldMapping } from '../constants';
const Steps = ({
  requestFormFields,
  rest,
  isSameAddress,
  handleSameAddressChange,
  activeStep,
  additionalSettings,
}) => {
  const [countryOpt, setCountryOpt] = useState([]);
  const [stateOpt, setStateOpt] = useState([]);
  const [cityOpt, setCityOpt] = useState([]);

  useEffect(() => {
    getCountryDataFunc();
    if (rest?.values?.country) {
      let payload = {
        country: rest?.values?.country,
      };
      getStateDataFunc(payload);
    }
    if (rest?.values?.state) {
      let payload = {
        country: rest?.values?.country,
        state: rest?.values?.state,
      };
      getCityDataFunc(payload);
    }
  }, []);

  const getCountryDataFunc = async () => {
    let data = await getCountryData();
    setCountryOpt(data?.data?.data);
  };
  const getStateDataFunc = async (payload) => {
    let data = await getStateData({ payload });
    setStateOpt(data?.data?.data);
  };
  const getCityDataFunc = async (payload) => {
    const data = await getCityData({ payload });
    setCityOpt(data?.data?.data);
  };

  const lengthValidationFields = Object.keys(additionalSettings)?.map(
    (key) => additionalSettingsFieldMapping[key]
  );

  const copyBillingAddress = (fields) => {
    if (!isSameAddress) {
      fields.map((field) => {
        let value = rest.values[`billing_${field.name}`];
        rest.setFieldValue(`shipping_${field.name}`, value);
      });
    } else {
      fields.map((field) => {
        rest.setFieldValue(`shipping_${field.name}`, '');
      });
    }
  };

  const handleBillingBlur = (fieldName, value) => {
    if (isSameAddress) {
      rest.setFieldValue(`shipping_${fieldName}`, value);
    }
  };

  const handleFieldChange = (e, field, addressType) => {
    if (lengthValidationFields.includes(field.name)) {
      const maxCharacter_length =
        additionalSettings?.[additionalSettingsFieldMapping[field.name][0]] == 0
          ? additionalSettings?.[additionalSettingsFieldMapping[field.name][1]]
          : additionalSettings?.[additionalSettingsFieldMapping[field.name][0]];

      if (
        e.target.value.length <= maxCharacter_length ||
        maxCharacter_length == undefined
      ) {
        rest.setFieldValue(`${addressType}_${field.name}`, e.target.value);
        rest.setFieldError(`${addressType}_${field.name}`, '');
        if (e.target.value.length > 1) {
          rest.setFieldTouched(`${addressType}_${field.name}`, true);
        }
      } else {
        rest.setFieldError(
          `${addressType}_${field.name}`,
          `${field.label} name cannot exceed ${maxCharacter_length} characters`
        );
      }
    } else {
      rest.handleChange(e);
    }
  };

  return requestFormFields.length > 0
    ? requestFormFields.map((step) => (
        <Stack spacing={2} key={step.step} marginBottom='15px'>
          {step.step === activeStep &&
            step.fields.map((field) =>
              field?.is_address ? (
                <Box key={'billing-address'}>
                  <Typography sx={styles.sectionTitle}>
                    Billing Address
                  </Typography>
                  {field?.billing_address.map((addressField) => {
                    if (
                      additionalSettings?.csc_dropdown == 1 &&
                      ['country', 'state', 'city'].includes(addressField.name)
                    ) {
                      return (
                        <SelectOld
                          disabled={isSameAddress}
                          required={addressField?.constraints?.required}
                          key={`billing_${addressField.name}`}
                          id={`billing_${addressField.name}`}
                          name={`billing_${addressField.name}`}
                          label={addressField.label}
                          placeholder={addressField.label}
                          sx={styles.defaultWidth}
                          marginBottom='16px'
                          value={{
                            label:
                              rest?.values?.[`billing_${addressField.name}`],
                          }}
                          showOptionLabel
                          setFormikValue={(id, option) => {
                            if (
                              rest?.values?.[`billing_${addressField.name}`] !==
                              option?.value
                            ) {
                              rest.setFieldValue(id, option?.value);
                              if (addressField.name == 'country') {
                                let payload = {
                                  country: option?.value,
                                };
                                getStateDataFunc(payload);
                              } else if (addressField.name == 'state') {
                                let payload = {
                                  country: rest.values['billing_country'],
                                  state: option?.value,
                                };
                                getCityDataFunc(payload);
                              }
                            }
                          }}
                          error={
                            rest?.touched?.[`billing_${addressField.name}`] &&
                            Boolean(
                              rest?.errors?.[`billing_${addressField.name}`]
                            )
                          }
                          helperText={
                            rest?.touched?.[`billing_${addressField.name}`] &&
                            rest?.errors?.[`billing_${addressField.name}`]
                          }
                          allowRemoveIcon={false}
                          options={(() => {
                            var arr = [];
                            const opt =
                              addressField.name == 'country'
                                ? countryOpt
                                : addressField.name == 'state'
                                ? stateOpt
                                : addressField.name == 'city'
                                ? cityOpt
                                : [];
                            opt?.forEach((option) => {
                              arr.push({
                                label: option.name,
                                value:
                                  addressField.name == 'city'
                                    ? option.name
                                    : option.code,
                              });
                            });
                            return arr;
                          })()}
                        />
                      );
                    } else {
                      return (
                        <TextField
                          required={addressField?.constraints?.required}
                          key={`billing_${addressField.name}`}
                          id={`billing_${addressField.name}`}
                          name={`billing_${addressField.name}`}
                          label={addressField.label}
                          placeholder={addressField.label}
                          value={rest.values[`billing_${addressField.name}`]}
                          onChange={(e) => {
                            handleFieldChange(e, addressField, 'billing');
                            handleBillingBlur(
                              addressField.name,
                              e?.target?.value
                            );
                          }}
                          error={
                            rest.touched[`billing_${addressField.name}`] &&
                            Boolean(rest.errors[`billing_${addressField.name}`])
                          }
                          helperText={
                            rest.touched[`billing_${addressField.name}`] &&
                            rest.errors[`billing_${addressField.name}`]
                          }
                          sx={styles.defaultWidth}
                          marginBottom='16px'
                        />
                      );
                    }
                  })}
                  <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    sx={styles.shippingContainer}
                  >
                    <Typography sx={styles.sectionTitle}>
                      Shipping Address
                    </Typography>
                    <Stack direction='row' spacing={1} alignItems='center'>
                      <Checkbox
                        sx={styles.sameAddCheckbox}
                        checked={isSameAddress}
                        onChange={() => {
                          handleSameAddressChange();
                          copyBillingAddress(field?.shipping_address);
                        }}
                      />
                      <InputLabel sx={styles.sectionTitle}>
                        Same as Billing Address
                      </InputLabel>
                    </Stack>
                  </Stack>

                  {field?.shipping_address.map((addressField) => {
                    if (
                      additionalSettings?.csc_dropdown == 1 &&
                      ['country', 'state', 'city'].includes(addressField.name)
                    ) {
                      return (
                        <SelectOld
                          disabled={isSameAddress}
                          required={addressField?.constraints?.required}
                          key={`shipping_${addressField.name}`}
                          id={`shipping_${addressField.name}`}
                          name={`shipping_${addressField.name}`}
                          label={addressField.label}
                          placeholder={addressField.label}
                          sx={styles.defaultWidth}
                          marginBottom='16px'
                          value={{
                            label:
                              rest?.values?.[`shipping_${addressField.name}`],
                          }}
                          showOptionLabel
                          setFormikValue={(id, option) => {
                            if (
                              rest?.values?.[
                                `shipping_${addressField.name}`
                              ] !== option?.value
                            ) {
                              rest.setFieldValue(id, option?.value);
                              if (addressField.name == 'country') {
                                let payload = {
                                  country: option?.value,
                                };
                                getStateDataFunc(payload);
                              } else if (addressField.name == 'state') {
                                let payload = {
                                  country: rest.values['shipping_country'],
                                  state: option?.value,
                                };
                                getCityDataFunc(payload);
                              }
                            }
                          }}
                          error={
                            rest?.touched?.[`shipping_${addressField.name}`] &&
                            Boolean(
                              rest?.errors?.[`shipping_${addressField.name}`]
                            )
                          }
                          helperText={
                            rest?.touched?.[`shipping_${addressField.name}`] &&
                            rest?.errors?.[`shipping_${addressField.name}`]
                          }
                          allowRemoveIcon={false}
                          options={(() => {
                            var arr = [];
                            const opt =
                              addressField.name == 'country'
                                ? countryOpt
                                : addressField.name == 'state'
                                ? stateOpt
                                : addressField.name == 'city'
                                ? cityOpt
                                : [];
                            opt?.forEach((option) => {
                              arr.push({
                                label: option.name,
                                value:
                                  addressField.name == 'city'
                                    ? option.name
                                    : option.code,
                              });
                            });
                            return arr;
                          })()}
                        />
                      );
                    } else {
                      return (
                        <TextField
                          disabled={isSameAddress}
                          required={addressField?.constraints?.required}
                          key={`shipping_${addressField.name}`}
                          id={`shipping_${addressField.name}`}
                          name={`shipping_${addressField.name}`}
                          label={addressField.label}
                          placeholder={addressField.label}
                          value={rest.values[`shipping_${addressField.name}`]}
                          onChange={(e) =>
                            handleFieldChange(e, addressField, 'shipping')
                          }
                          error={
                            rest.touched[`shipping_${addressField.name}`] &&
                            Boolean(
                              rest.errors[`shipping_${addressField.name}`]
                            )
                          }
                          helperText={
                            rest.touched[`shipping_${addressField.name}`] &&
                            rest.errors[`shipping_${addressField.name}`]
                          }
                          sx={styles.defaultWidth}
                          marginBottom='16px'
                        />
                      );
                    }
                  })}
                </Box>
              ) : (
                <TextField
                  required={field?.constraints?.required}
                  key={field.name}
                  id={field.name}
                  name={field.name}
                  label={field.label}
                  placeholder={field.label}
                  value={rest.values[`${field.name}`]}
                  onChange={rest.handleChange}
                  error={
                    rest.touched[`${field.name}`] &&
                    Boolean(rest.errors[`${field.name}`])
                  }
                  helperText={
                    rest.touched[`${field.name}`] &&
                    rest.errors[`${field.name}`]
                  }
                  sx={styles.defaultWidth}
                />
              )
            )}
        </Stack>
      ))
    : null;
};

Steps.propTypes = {
  requestFormFields: PropTypes.array.isRequired,
  rest: PropTypes.any.isRequired,
  isSameAddress: PropTypes.bool.isRequired,
  handleSameAddressChange: PropTypes.func.isRequired,
  activeStep: PropTypes.number.isRequired,
  additionalSettings: PropTypes.object.isRequired,
};

export default Steps;
