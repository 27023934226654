import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import MyModal from 'components/modals/modal';
import TextField from 'components/text-field';
import { responsiveBreakpoints } from 'utils/utils';
import SelectOld from 'components/select/select.old';
import useSendShopifyOrderManually from './send-shopify-order-manually-hooks';
import { fieldsName } from './constant';
import { Formik } from 'formik';
import { sendShopifyOrderSchema } from './validation-schema';

export default function SendShopifyOrderManually({ open, setOpen }) {
  const { mobile } = responsiveBreakpoints();
  const {
    orderNumber,
    setOrderNumber,
    selectedOrder,
    setSelectedOrder,
    shopifyList,
    sendShopifyOrder,
    initialValues,
    isLoading,
    isSendApiLoading,
  } = useSendShopifyOrderManually({ open, setOpen });
  const { shopifyStore, shopifyOrderId } = fieldsName;

  const Send = (
    <LoadingButton
      variant='contained'
      loading={isSendApiLoading}
      disabled={isSendApiLoading}
      onClick={(e) => {
        e.preventDefault();
        formikRef.current.submitForm();
      }}
    >
      Send
    </LoadingButton>
  );
  const formikRef = useRef(null);
  return (
    <MyModal
      open={open}
      setOpen={setOpen}
      showCloseIcon
      actions={[Send]}
      size={mobile ? 'fullWidth' : 'large'}
      title='Manual Transfer Shopify Order'
      sx={mobile ? { width: '100%' } : { width: '44vw' }}
      // sx={{ width: '780px' }}
    >
      <Box sx={[mobile ? { padding: '20px' } : null]}>
        <Formik
          innerRef={formikRef}
          validateOnMount
          validationSchema={sendShopifyOrderSchema}
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={sendShopifyOrder}
        >
          {({ handleSubmit, setFieldValue, ...rest }) => {
            return (
              <Stack spacing={2}>
                <SelectOld
                  //   sx={{ width: '180px' }}
                  id={shopifyStore}
                  name={shopifyStore}
                  required
                  allowRemoveIcon={false}
                  label='Shopify Stores'
                  placeholder='Select a shopify store'
                  value={rest?.values?.shopifyStore}
                  setFormikValue={setFieldValue}
                  options={shopifyList}
                  error={
                    rest.touched.shopifyStore &&
                    Boolean(rest.errors.shopifyStore)
                  }
                  helperText={
                    rest.touched.shopifyStore && rest.errors.shopifyStore
                  }
                  isLoading={isLoading}
                />
                <TextField
                  id={shopifyOrderId}
                  name={shopifyOrderId}
                  required
                  label='Shopify Order ID'
                  placeholder='Enter Order Id'
                  value={rest?.values?.shopifyOrderId}
                  onChange={rest.handleChange}
                  error={
                    rest.touched.shopifyOrderId &&
                    Boolean(rest.errors.shopifyOrderId)
                  }
                  helperText={
                    rest.touched.shopifyOrderId && rest.errors.shopifyOrderId
                  }
                />
              </Stack>
            );
          }}
        </Formik>
      </Box>
    </MyModal>
  );
}

SendShopifyOrderManually.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
