import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import MyModal from 'components/modals/modal';
import { responsiveBreakpoints } from 'utils/utils';

import Select from '../select/select';
import { useApplyFiltersModal } from './apply-filters-modal-hooks';
import MultiSelect from '../select/multi-select';
import ApplyButton from './components/apply-button';
import ResetFiltersButton from './components/reset-filters-button';

export default function ApplyFiltersModal({
  open,
  setOpen,
  filtersToEnable,
  applyFilters,
  filters,
  setFilters,
}) {
  const { mobile, tablet, laptop } = responsiveBreakpoints();

  const {
    salesRep,
    priceGroup,
    paymentTerms,
    shippingMethod,
    sortBy,
    showRetailersAndBuyersWith,
    groups,
    currency,
    showBuyers,
    leagues,
    brands,
    setFilter, // Unified setter function for all filters
    handleSubmit,
    supportData,
  } = useApplyFiltersModal({ applyFilters, setOpen, filters, setFilters });

  return (
    <MyModal
      open={open}
      setOpen={setOpen}
      size='small'
      title='Apply Filters'
      showCloseIcon
      actions={[
        <ResetFiltersButton key='resetFilter' handleSubmit={handleSubmit} />,
        <ApplyButton key='applyFilter' handleSubmit={handleSubmit} />,
      ]}
      sx={
        mobile || tablet
          ? { width: '100%' }
          : laptop
          ? { width: '900px' }
          : { width: '1030px' }
      }
    >
      <Grid container columnSpacing={2}>
        {/*
        
        sales rep filter 
        
        */}
        {filtersToEnable?.salesRep ? (
          <Grid item xs={12} sm={6} md={4}>
            <MultiSelect
              label='Sales Rep'
              labelAccessor='name'
              placeholder='Select Sales Rep'
              value={salesRep}
              valueAccessor='id'
              setValue={(value) =>
                setFilters((prev) => ({ ...prev, ['salesRep']: value }))
              } // Using setFilter for updating state
              options={supportData?.sales_rep}
              sx={{ marginBottom: '15px' }}
            />
          </Grid>
        ) : null}

        {/*
        
        price group filter 
        
        */}
        {filtersToEnable?.priceGroup ? (
          <Grid item xs={12} sm={6} md={4}>
            <MultiSelect
              label='Price Group'
              labelAccessor='name'
              placeholder='Select Price Group'
              value={priceGroup}
              valueAccessor='id'
              setValue={(value) =>
                setFilters((prev) => ({ ...prev, ['priceGroup']: value }))
              } // Using setFilter for updating state
              options={supportData?.price_groups}
              sx={{ marginBottom: '15px' }}
            />
          </Grid>
        ) : null}

        {/*
        
        payment terms filter 
        
        */}
        {filtersToEnable?.paymentTerms ? (
          <Grid item xs={12} sm={6} md={4}>
            <MultiSelect
              label='Payment Terms'
              labelAccessor='name'
              placeholder='Select Payment Terms'
              value={paymentTerms}
              valueAccessor='id'
              setValue={(value) =>
                setFilters((prev) => ({ ...prev, ['paymentTerms']: value }))
              } // Using setFilter for updating state
              options={supportData?.payment_method}
              sx={{ marginBottom: '15px' }}
            />
          </Grid>
        ) : null}

        {/*
        
       shipping method filter 
        
        */}
        {filtersToEnable?.shippingMethod ? (
          <Grid item xs={12} sm={6} md={4}>
            <MultiSelect
              label='Shipping Method'
              labelAccessor='name'
              placeholder='Select Shipping Method'
              value={shippingMethod}
              valueAccessor='id'
              setValue={(value) =>
                setFilters((prev) => ({ ...prev, ['shippingMethod']: value }))
              } // Using setFilter for updating state
              options={supportData?.shipping_method}
              sx={{ marginBottom: '15px' }}
            />
          </Grid>
        ) : null}

        {/*
        
        groups filter 
        
        */}
        {filtersToEnable?.groups ? (
          <Grid item xs={12} sm={6} md={4}>
            <MultiSelect
              label='Groups'
              labelAccessor='name'
              placeholder='Select Groups'
              value={groups}
              valueAccessor='id'
              setValue={(value) =>
                setFilters((prev) => ({ ...prev, ['groups']: value }))
              } // Using setFilter for updating state
              options={supportData?.groups}
              sx={{ marginBottom: '15px' }}
            />
          </Grid>
        ) : null}

        {/*
        
        currency filter 
        
        */}
        {filtersToEnable?.currency ? (
          <Grid item xs={12} sm={6} md={4}>
            <MultiSelect
              label='Currency'
              labelAccessor='name'
              placeholder='Select Currency'
              value={currency}
              valueAccessor='id'
              setValue={(value) =>
                setFilters((prev) => ({ ...prev, ['currency']: value }))
              } // Using setFilter for updating state
              options={supportData?.client_currency}
              sx={{ marginBottom: '15px' }}
            />
          </Grid>
        ) : null}

        {/*
        
        sort by filter 
        
        */}
        {filtersToEnable?.sortBy ? (
          <Grid item xs={12} sm={6} md={4}>
            <Select
              label='Sort By'
              placeholder='Select Sort By'
              value={sortBy}
              setValue={(value) =>
                setFilters((prev) => ({ ...prev, ['sortBy']: value }))
              } // Using setFilter for updating state
              options={[
                { label: 'Albphabetically', value: 'alphabetically' },
                { label: 'Recently Created', value: 'recentlyCreated' },
                { label: 'Recently Updated', value: 'recentlyUpdated' },
              ]}
              sx={{ marginBottom: '15px' }}
            />
          </Grid>
        ) : null}

        {/*
        
        show retailers  filter 
        
        */}
        {filtersToEnable?.showRetailersAndBuyersWith ? (
          <Grid item xs={12} sm={6} md={4}>
            <Select
              label='Show Retailers & Buyers with'
              labelAccessor='label'
              valueAccessor='value'
              value={showRetailersAndBuyersWith}
              placeholder='Select Show Retailers & Buyers With'
              setValue={(value) =>
                setFilters((prev) => ({
                  ...prev,
                  ['showRetailersAndBuyersWith']: value,
                }))
              }
              options={[
                {
                  label: 'Retailers with no buyers',
                  value: '2',
                },
                {
                  label: 'Buyers with no email address',
                  value: '3',
                },
                {
                  label: 'Buyers with email address',
                  value: '4',
                },
                {
                  label: 'Retailers with no orders',
                  value: '5',
                },
                {
                  label: 'Retailers with orders',
                  value: '6',
                },
              ]}
              sx={{ marginBottom: '15px' }}
            />
          </Grid>
        ) : null}

        {/*
        
        show buyers filter 
        
        */}
        {/* {filtersToEnable?.showBuyers ? (
          <Grid item xs={12} sm={6} md={4}>
            <MultiSelect
              label='Show Buyers'
              labelAccessor='name'
              value={showBuyers}
              placeholder='Select Show Buyers'
              valueAccessor='id'
              setValue={(value) =>
                setFilters((prev) => ({ ...prev, ['showBuyers']: value }))
              } // Using setFilter for updating state
              options={supportData?.showBuyers}
              sx={{ marginBottom: '15px' }}
            />
          </Grid>
        ) : null} */}

        {/*

        leaguesAndBrands : leagues

        */}
        {filtersToEnable?.leaguesAndBrands && supportData?.leagues ? (
          <Grid item xs={12} sm={6} md={4}>
            <MultiSelect
              label='Leagues'
              labelAccessor='name'
              value={leagues}
              placeholder='Select Leagues'
              valueAccessor='id'
              setValue={(value) => setFilter('leagues', value)} // Using setFilter for updating state
              options={supportData?.leagues}
              sx={{ marginBottom: '15px' }}
            />
          </Grid>
        ) : null}

        {/*

        leaguesAndBrands : brands

        */}
        {filtersToEnable?.leaguesAndBrands && supportData?.brands ? (
          <Grid item xs={12} sm={6} md={4}>
            <MultiSelect
              label='Brands'
              labelAccessor='name'
              value={brands}
              placeholder='Select Brands'
              valueAccessor='id'
              setValue={(value) => setFilter('brands', value)} // Using setFilter for updating state
              options={supportData?.brands}
              sx={{ marginBottom: '15px' }}
            />
          </Grid>
        ) : null}
      </Grid>
    </MyModal>
  );
}

ApplyFiltersModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  filtersToEnable: PropTypes.object.isRequired,
  applyFilters: PropTypes.func.isRequired,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
};
ApplyFiltersModal.defaultProps = {
  filters: {},
  setFilters: () => {},
};
