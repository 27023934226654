import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '../../text-field';
import Select from '../../select/select';
import { useSendInviteEmail } from './hooks';

export default function FilterOptions({
  searchTerm,
  setSearchTerm,
  sortBy,
  setSortBy,
  sender,
  setSender,
  senderOptions,
  signature,
  setSignature,
  signatureOptions,
  selectAllBuyers,
}) {
  const { selectAll, setSelectAll } = useSendInviteEmail();

  return (
    <Stack
      spacing={7}
      direction='row'
      alignItems='center'
      justifyContent='space-between'
      marginBottom='20px'
    >
      <Stack direction='row' spacing={2}>
        {/* <FormControlLabel
          control={
            <Checkbox
              checked={selectAll}
              indeterminate={selectAll}
              onClick={(e) => {
                e.preventDefault();
                setSelectAll(!selectAll);
                selectAllBuyers();
              }}
            />
          }
          label='Select All'
        /> */}
        <TextField
          placeholder='Search'
          value={searchTerm}
          onChange={(e) => {
            e.preventDefault();
            setSearchTerm(e.target.value);
          }}
          sx={{ width: '340px', marginBottom: 0 }}
        />
      </Stack>
      <Stack direction='row' alignItems='center' spacing={2}>
        <Select
          allowRemoveIcon={false}
          variant='outlined'
          color='secondary'
          placeholder='Sender'
          value={sender}
          setValue={setSender}
          options={senderOptions}
          valueAccessor='id'
          labelAccessor='name'
        />
        <Select
          allowRemoveIcon={false}
          variant='outlined'
          color='secondary'
          placeholder='Signature'
          value={signature}
          setValue={setSignature}
          options={signatureOptions}
          valueAccessor='id'
          labelAccessor='name'
        />
        <Select
          variant='outlined'
          color='secondary'
          placeholder='Sort by'
          value={sortBy}
          setValue={setSortBy}
          options={[
            {
              label: 'All',
              value: '99',
            },
            {
              label: 'Emails not sent',
              value: '0',
            },
            {
              label: 'Emails sent',
              value: '1',
            },
            {
              label: 'Users who havent logged in',
              value: '2',
            },
            {
              label: 'Users with no email set',
              value: '3',
            },
          ]}
        />
      </Stack>
    </Stack>
  );
}

FilterOptions.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  setSortBy: PropTypes.func.isRequired,
  sender: PropTypes.string.isRequired,
  setSender: PropTypes.func.isRequired,
  signature: PropTypes.string.isRequired,
  setSignature: PropTypes.func.isRequired,
  senderOptions: PropTypes.array.isRequired,
  signatureOptions: PropTypes.array.isRequired,
  selectAllBuyers: PropTypes.func.isRequired,
};
