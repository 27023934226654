import { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useHistory } from 'react-router-dom';
import { getHeader1Menu } from '../actions';
import { HEADER_MENU_REQUEST } from '../constants';
import {
  actionLoaderSelector,
  activeDraftSuggestionSelector,
} from '../../../redux/selectors';
import { selectCustomerDetailSelector } from '../../../redux/selectors';
import { getActiveDraftSuggestion } from '../../../redux/actions/global-actions';
// import ScreenNames from '../../../routes/screen-names';
import { header1MenuSelector } from '../selectors';
import { loggedInUserSelector } from 'pages/profile/selectors';
import { api } from 'api';
import { PrivateScreen } from 'routes/screen-names';
import { setBearerToken } from 'utils/auth';

export const useHeader1 = ({ isMarketplace }) => {
  const [showMenuItemsOnScroll, setShowMenuItemsOnScroll] = useState(false);
  const [isOverLayLoading, setIsOverlayLoading] = useState(false);
  const headerMenuLoading = useSelector(
    actionLoaderSelector(HEADER_MENU_REQUEST)
  );
  const activeDraftSuggestion = useSelector(activeDraftSuggestionSelector);
  const dispatch = useDispatch();
  let headerMenu = useSelector(header1MenuSelector);
  const clientInfo = useSelector(selectCustomerDetailSelector);

  if (isMarketplace) {
    headerMenu = {
      leftHeaderMenu: [
        {
          hasSubOption: clientInfo?.showShopifyWebhookOrders ? true : false,
          name: 'ORDERS',
          redirectTo: '/marketplace/orders',
          subOption: [
            {
              isSection: false,
              name: 'Shopify Orders',
              redirectTo: '/marketplace/shopify-orders',
            },
          ],
        },
        {
          hasSubOption: false,
          name: 'ACCOUNTING',
          redirectTo: '/marketplace/accounting',
        },
        {
          hasSubOption: true,
          name: 'UTILITIES',
          redirectTo: '/marketplace/warehouses',
          subOption: [
            {
              isSection: false,
              name: 'Manage Warehouses',
              redirectTo: '/marketplace/warehouses',
            },
            {
              isSection: false,
              name: 'Marketplace Inventory',
              redirectTo: '/marketplace/inventory',
            },
            {
              isSection: false,
              name: 'Ship Via Settings',
              redirectTo: '/marketplace/ship-via-settings',
            },
            {
              isSection: false,
              name: 'Send Invoices',
              redirectTo: '/marketplace/send-invoices',
            },
            {
              isSection: false,
              name: 'Send Shopify Orders Manually',
              redirectTo: '/marketplace/send-invoices',
            },
          ],
        },
      ],
      rightHeaderMenu: [headerMenu?.rightHeaderMenu?.[2]],
    };
  }

  useEffect(() => {
    dispatch(getActiveDraftSuggestion());

    const fetchHeaderMenu = async () => {
      await dispatch(getHeader1Menu());
    };
    fetchHeaderMenu();
  }, [activeDraftSuggestion?.draft?.id, activeDraftSuggestion?.suggestion?.id]);

  useEffect(() => {}, [header1MenuSelector]);

  // scroll function
  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      //console.log('scrollY', scrollY);
      const threshold = 100; // Adjust this value to change the scroll threshold

      if (scrollY >= threshold) {
        setShowMenuItemsOnScroll(true);
      } else {
        setShowMenuItemsOnScroll(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const onClickLinkedBuyerAccount = useCallback(async ({ userId }) => {
    setIsOverlayLoading(true);
    const res = await api.post('switchAccount', {
      userId: userId,
    });
    if (res?.data?.status) {
      setBearerToken(res?.data?.token);
      window.location.href = PrivateScreen.home;
      setIsOverlayLoading(false);
    } else {
      setIsOverlayLoading(false);
    }
  }, []);
  return {
    headerMenu,
    headerMenuLoading,
    showMenuItemsOnScroll,
    activeDraftSuggestion,
    onClickLinkedBuyerAccount,
    isOverLayLoading,
  };
};

export const useHeader2 = () => {
  const [isMySuggestionSelect, setMySuggestonSelect] = useState(false);
  const [isMyCartSelect, setIsMyCartSelect] = useState(false);
  const activeDraftSuggestion = useSelector(activeDraftSuggestionSelector);
  const config = useSelector(selectCustomerDetailSelector);
  const user = useSelector(loggedInUserSelector);
  const handleMySuggestionClick = () => {
    setMySuggestonSelect(!isMySuggestionSelect);
  };

  const handleMyCartClick = () => {
    setIsMyCartSelect(!isMyCartSelect);
  };

  const handleMyCartClose = () => {
    setIsMyCartSelect(false);
  };

  const handleMySuggestionClose = () => {
    setMySuggestonSelect(false);
  };

  return {
    handleMySuggestionClick,
    handleMySuggestionClose,
    isMySuggestionSelect,
    handleMyCartClick,
    handleMyCartClose,
    isMyCartSelect,
    config,
    activeDraftSuggestion,
    user,
  };
};
export const useHeader2M = () => {
  const [showSearchUI, setShowSearchUI] = useState(false);
  const [showDepartmentMobileMenu, setShowDepartmentMobileMenu] =
    useState(false);
  return {
    showSearchUI,
    setShowSearchUI,
    showDepartmentMobileMenu,
    setShowDepartmentMobileMenu,
  };
};

export const useHeader3 = () => {
  const [isAllProductHover, setIsAllProductHover] = useState(false);
  const [selectProduct, setSelectProduct] = useState([]);
  const [delivery, setDelivery] = useState({ id: '', delivery: [] });
  const [categories, setCategories] = useState({ categories: [] });

  const getCategoryByDate = (id) => {
    const selectedDate = delivery.delivery.find((date) => date.id === id);
    const selectedCategories = {
      categories: selectedDate.categories,
    };
    setCategories(selectedCategories);
  };

  const handleProductOptionOut = () => {
    setIsAllProductHover(false);
  };

  const handleProductOptionHover = () => {
    setIsAllProductHover(true);
  };

  // const handleNavigationClick = (path) => {
  //   switch (path) {
  //     case 'ShopByLookbooks':
  //       history.push(ScreenNames.ShopByLookbooks);
  //       break;

  //     default:
  //       history.push(ScreenNames.Home);
  //   }
  // };

  return {
    isAllProductHover,
    handleProductOptionOut,
    handleProductOptionHover,
    selectProduct,
    delivery,
    categories,
    getCategoryByDate,
  };
};

export const useBuyerEditRequest = () => {
  // const dispatch = useDispatch();
  const [isBuyerEditRequestOpen, setIsBuyerEditRequestOpen] = useState(false);
  const [buyerEditDetail, setBuyerEditDetail] = useState();
  const buyerEditRequestForm = useRef();
  const isApproved = false;
  const isDeclined = false;

  const toggleBuyerEditRequestModal = () => {
    setIsBuyerEditRequestOpen(!isBuyerEditRequestOpen);
  };

  const handleNotificationClick = async () => {
    // fetch buyer Edit details
    // TODO: API pending
    toggleBuyerEditRequestModal();
    setBuyerEditDetail({});
  };

  const handleDeclineClick = () => {
    toggleBuyerEditRequestModal();
  };

  const handleApproveClick = () => {
    if (buyerEditRequestForm.current) {
      buyerEditRequestForm.current.handleSubmit();
    }
  };

  const handleFormSubmit = (data) => {
    toggleBuyerEditRequestModal();
  };

  return {
    isBuyerEditRequestOpen,
    buyerEditDetail,
    handleNotificationClick,
    toggleBuyerEditRequestModal,
    handleDeclineClick,
    handleApproveClick,
    handleFormSubmit,
    isApproved,
    isDeclined,
    buyerEditRequestForm,
  };
};
