/* eslint-disable react/no-unknown-property */
import React from 'react';
import PropTypes from 'prop-types';

export default function MyOrdersIconM({ fill, active, width, height, stroke }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
    >
      <g id='my-orders' transform='translate(-556 -636)'>
        <path
          id='Vector'
          d='M20,10V7c0-5-2-7-7-7H7C2,0,0,2,0,7v6c0,5,2,7,7,7h3'
          transform='translate(558 638)'
          fill='none'
          stroke='#fff'
          stroke-linecap='round'
          stroke-linejoin='round'
          stroke-width='1.2'
        />
        <path
          id='Vector-2'
          data-name='Vector'
          d='M7.95,4.823l-1.63.55a1.505,1.505,0,0,0-.96.96l-.55,1.63a1.516,1.516,0,0,1-2.89-.03L.07,1.983A1.519,1.519,0,0,1,1.97.073l5.96,1.85A1.526,1.526,0,0,1,7.95,4.823Z'
          transform='translate(569.01 649.017)'
          fill='none'
          stroke='#fff'
          stroke-linecap='round'
          stroke-linejoin='round'
          stroke-width='1.2'
        />
        <path
          id='Vector-3'
          data-name='Vector'
          d='M0,0H24V24H0Z'
          transform='translate(556 636)'
          fill='none'
          opacity='0'
        />
      </g>
    </svg>
  );
}

MyOrdersIconM.propTypes = {
  fill: PropTypes.string,
  active: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  stroke: PropTypes.string,
};

MyOrdersIconM.defaultProps = {
  fill: 'var(--primary-icon-color)',
  active: 'false',
  width: '34px',
  height: '34px',
  stroke: '#292d32',
};
