const styles = {
  dropContainer: {
    borderRadius: '8px',
    textAlign: 'center',
    cursor: 'pointer',
    position: 'relative',
    width: '100%',
    height: '239px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& label': { cursor: 'pointer' },
  },

  deleteIconButton: {
    position: 'absolute',
    top: '8px',
    right: '8px',
    color: '#fff',
    cursor: 'pointer',
    borderRadius: '4px',
    padding: '2px',
    '&:hover': {
      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    },
    zIndex: 99,
  },

  labelBox: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 10,
  },
};

export default styles;
