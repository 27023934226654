import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import MyModal from 'components/modals/modal';

import { notificationStyles as styles } from './styles';
import { useNotifications } from './notifications-hooks';
import NotificationIcon from 'assets/icons/notification-icon';
import NotificationSkeleton from './notification-skeleton';
import moment from 'moment/moment';
import BuyerRequests from 'components/buyer-requests/buyer-requests';
import { undefinedCheck } from 'utils/helper';

const NotificationsModal = ({ open, setOpen }) => {
  const {
    closeNotification,
    notifications,
    notificationsLoading,
    handleMarkAllAsReadClick,
    handleDismissAllClick,
    fetchNotifications,
    handleNotificationClick,
    selectedNotification,
    showDismissAll,
    showMarkAllAsRead,
    requestActionCallback,
  } = useNotifications({ setOpen });
  const customizeTitle = (
    <Stack
      sx={{
        padding: '10px 5px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
      }}
    >
      <Typography variant='h2' onClick={handleDismissAllClick}>
        Notifications
      </Typography>
      {showDismissAll && (
        <Typography
          variant='h2'
          sx={{
            color: '#0066c0',
            paddingTop: '1px',
          }}
          onClick={handleDismissAllClick}
        >
          Dismiss All
        </Typography>
      )}
      {showMarkAllAsRead && (
        <Typography
          variant='h2'
          sx={{
            color: '#0066c0',
            paddingTop: '1px',
          }}
          onClick={handleMarkAllAsReadClick}
        >
          Mark all as read
        </Typography>
      )}
      <Stack>
        <IconButton
          sx={{
            width: 'fit-content',
            marginLeft: 'auto',
          }}
          onClick={(e) => {
            e.preventDefault();
            setOpen(false);
          }}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
    </Stack>
  );

  return (
    <MyModal
      open={open}
      setOpen={setOpen}
      customTitle={customizeTitle}
      isCustomTitle
      size='full-width'
      sx={{ height: '90vh', width: '100vw' }}

      // actions={timeline === 4 ? [close, sendLink] : [close, nextStep]}
    >
      <BuyerRequests
        selectedNotification={undefinedCheck(selectedNotification, {})} // Added to remove warning from the console
        callback={requestActionCallback}
        closeNotification={closeNotification}
      />
      <Stack
        sx={{
          borderRadius: '5px',
          height: '100%',
          width: '100vw',
        }}
      >
        {notificationsLoading ? (
          <NotificationSkeleton />
        ) : (
          <Stack sx={{ flex: 1 }}>
            {notifications?.length > 0 ? (
              notifications.map((notification) => {
                return (
                  <Stack
                    onClick={() => handleNotificationClick(notification)}
                    sx={[
                      styles.notificationCardContainer,
                      notification.read == 1 ? styles.notificaitonViewd : '',
                    ]}
                    key={notification.id}
                  >
                    <Avatar
                      alt=''
                      src={notification?.sender_profile_photo}
                      height='38px'
                      width='38px'
                    />
                    <Stack flex={1}>
                      <Typography sx={styles.message}>
                        {notification?.message}
                      </Typography>
                      <Stack sx={styles.dateAndAction}>
                        <Typography sx={styles.date}>
                          {moment(notification?.created_at).format(
                            'MMM Do, YYYY [at] hh:mm a'
                          )}
                        </Typography>
                        {notification?.status && (
                          <Button
                            variant={
                              notification?.status == 'Review Request' ||
                              (notification?.status == 'Approved' &&
                                notification.is_action == 1)
                                ? 'text'
                                : 'outlined'
                            }
                            size='small'
                            color={
                              notification?.status == 'Declined'
                                ? 'warning'
                                : 'success'
                            }
                            sx={[
                              styles.action,
                              notification?.status == 'Review Request' && {
                                color: '#0066c0',
                              },
                              notification?.status == 'Approved' &&
                                notification.is_action == 1 && {
                                  color: '#0066c0',
                                },
                            ]}
                          >
                            {notification?.status == 'Approved' &&
                            notification.is_action == 1
                              ? 'Review Request'
                              : notification?.status}
                          </Button>
                        )}
                      </Stack>
                    </Stack>
                  </Stack>
                );
              })
            ) : (
              <Stack sx={styles.emptyContainer}>
                <Stack sx={styles.emptyIcon}>
                  <NotificationIcon
                    fill='var(--primary-icon-color)'
                    active={false}
                    height='28px'
                    width='23px'
                  />
                </Stack>
                <Typography variant='h2' sx={{ marginBottom: '5px' }}>
                  No notifications yet
                </Typography>
                <Typography sx={styles.emptyMessage}>
                  When you get notifications, they’ll show up here
                </Typography>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={fetchNotifications}
                >
                  Refresh
                </Button>
              </Stack>
            )}
          </Stack>
        )}
      </Stack>
    </MyModal>
  );
};
NotificationsModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

NotificationsModal.defaultProps = {
  open: false,
  setOpen: () => {},
};
export default NotificationsModal;
