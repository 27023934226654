import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { departmentListSelector } from 'redux/selectors/index';
import { useURLSearchParams } from 'common-hooks/url-params-listener-hooks';
import { getAdvanceSearchFilters } from '../../actions';
import { useUpdateURLParams } from 'common-hooks/update-query-params-hooks';

export const useSearchHooks = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    cl = null,
    gndr = null,
    search = null,
    searchfilters = null,
  } = useURLSearchParams();
  const { updateURLParams } = useUpdateURLParams();
  const qs = require('qs');
  const departmentList = useSelector(departmentListSelector);
  const [searchFilters, setSearchFilters] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchTypes, setSearchTypes] = useState([]);

  useEffect(() => {
    setSearchTypes(searchfilters ? searchfilters.split('|') : []);
    setSearchText(search || '');
  }, [search, searchfilters]);

  useEffect(() => {
    getSearchFilters();
  }, []);

  const getSearchFilters = async () => {
    const response = await dispatch(getAdvanceSearchFilters());
    if (response.data && response.data.advancedFilters) {
      const filteredFilters = response.data.advancedFilters.filter(
        (filter) => filter.displayName !== 'Collection'
      );

      setSearchFilters([...filteredFilters]);
    }
  };

  const onApplySearch = () => {
    if (isEmpty(searchText)) {
      return;
    }
    const updatedFilters = searchTypes.join('|');

    const queryObj = {
      cl: cl || departmentList?.commonCollectionId,
      gndr: gndr || departmentList?.commonGenderId,
      search: searchText,
      resetCatalog: true,
      fetchLeftNav: true,
      start: 0,
    };
    if (updatedFilters) queryObj['searchfilters'] = updatedFilters;
    const queryPrams = `/catalog?${qs.stringify(queryObj)}`;
    navigate(queryPrams);
  };

  const onClearFilters = () => {
    setSearchText('');
    setSearchTypes([]);
    if (location.pathname.includes('/catalog')) {
      return updateURLParams({
        search: null,
        searchfilters: null,
        resetCatalog: true,
        start: 0,
        fetchProduct: true,
        srch: null,
      });
    }
    updateURLParams({
      search: null,
      searchfilters: null,
    });
  };

  return {
    searchFilters,
    searchTypes,
    setSearchTypes,
    setSearchText,
    searchText,
    onApplySearch,
    onClearFilters,
  };
};
