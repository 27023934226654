import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { splashStyles as styles } from './styles';
import useTheme from '@mui/material/styles/useTheme';

const Splash = ({ splashImage }) => {
  const theme = useTheme();
  if (splashImage)
    return <Box sx={styles.container(splashImage, theme)}>&nbsp;</Box>;
  return <Skeleton variant='rectangular' width='50vw' height='100vh' />;
};

Splash.propTypes = {
  splashImage: PropTypes.string.isRequired,
};

export default Splash;
