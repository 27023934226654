import React from 'react';
import PropTypes from 'prop-types';

import {
  Stack,
  Button,
  IconButton,
  Typography,
  Paper,
  Grid,
  Box,
  Fade,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from '@mui/material';

import Delete from '@mui/icons-material/Delete';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AddIcon from '@mui/icons-material/Add';

import SuggestionsQuickPanelIcon from '../../../assets/icons/suggestions-quick-panel-icon';
import CreateNewSuggestionModal from 'components/modals/create-suggestion/create-new-suggestion-modal';
import Confirm from 'components/modals/confirmation-modal';
import AlertModal from 'components/modals/alert/alert-modal';
import EmptyState from 'components/empty-state';
import BuyerEmptyStateIcon from 'assets/icons/buyer-empty-state-icon';

import useSuggestionsQuickPanel from './suggestions-quick-panel-hooks';
import useCustomSuggestion from 'pages/suggestions/custom-suggestion-hooks';

import { zIndex } from 'constants/zIndex';
import { profileRoles } from 'constants/index';

export default function SuggestionsQuickPanel({
  mouseOver,
  mouseOut,
  setOpen,
  open,
}) {
  const {
    deleteSuggestion,
    createSuggestion,
    setActiveSuggestion,
    bulkDeleteSuggestions,
  } = useCustomSuggestion();

  const {
    showNewSuggestionModal,
    setShowNewSuggestionModal,
    showDeleteConfirmation,
    setShowDeleteConfirmation,
    selectedId,
    setSelectedId,
    navigate,
    isSelected,
    toggleSelected,
    selectedSuggestions,
    setSelectedSuggestions,
    showBulkDeleteConfirm,
    setShowBulkDeleteConfirm,
    showBulkDeleteAlert,
    setShowBulkDeleteAlert,
    selectAllChecked,
    sharedSuggestionsData,
    suggestionData,
    suggestionsLoading,
    ROLE_ID,
  } = useSuggestionsQuickPanel();

  const confirmDelete = (
    <Button
      variant='contained'
      onClick={(e) => {
        e.preventDefault();
        deleteSuggestion(selectedId, setOpen);
        setShowDeleteConfirmation(false);
      }}
    >
      Yes, Delete
    </Button>
  );

  const confirmBulkDelete = (
    <Button
      variant='contained'
      onClick={(e) => {
        e.preventDefault();
        bulkDeleteSuggestions(selectedSuggestions, setOpen);
        setShowBulkDeleteConfirm(false);
      }}
    >
      Yes, Delete
    </Button>
  );

  return (
    <Fade in={open} timeout={700}>
      <Paper
        elevation={3}
        sx={{
          width: '460px',
          position: 'absolute',
          top: '46px',
          right: 0,
          borderRadius: '6px',
          height: '570px',
          zIndex: zIndex.popover,
          overflow: 'hidden',
          border: '1px solid var(--secondary-border-color)',
          backgroundColor: 'var(--paper-background-color)',
        }}
        onMouseOver={mouseOver}
        onMouseOut={mouseOut}
      >
        {/* create suggestion */}
        {showNewSuggestionModal ? (
          <CreateNewSuggestionModal
            open={showNewSuggestionModal}
            setOpen={setShowNewSuggestionModal}
            createSuggestion={createSuggestion}
            quickPanelHandleClose={setOpen}
          />
        ) : null}

        {/* delete confirmation */}
        {showDeleteConfirmation ? (
          <Confirm
            open={showDeleteConfirmation}
            setOpen={setShowDeleteConfirmation}
            title='Are you sure?'
            message='Are you sure you want to delete this suggestion.'
            alertInfoMessage='Please note that deleting a suggestion will also remove it for the buyer with whom the suggestion was shared.'
            action={confirmDelete}
          />
        ) : null}

        {/* bulk delete confirmation */}
        {showBulkDeleteConfirm ? (
          <Confirm
            open={showBulkDeleteConfirm}
            setOpen={setShowBulkDeleteConfirm}
            title='Are you sure?'
            message='Are you sure you want to delete selected suggestions.'
            alertInfoMessage='Please note that deleting a suggestion will also remove it for the buyer with whom the suggestion was shared.'
            action={confirmBulkDelete}
          />
        ) : null}

        {/* bulk delete alert */}
        <AlertModal
          open={showBulkDeleteAlert}
          setOpen={setShowBulkDeleteAlert}
          title='Please select at least one checkbox to delete selected suggestions'
          type='warning'
        />
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          sx={{
            borderBottom: '1px solid var(--secondary-border-color)',
            padding: '0 20px 0 20px',
            backgroundColor: 'var(--modal-header-color)',
            minHeight: '39px',
          }}
        >
          <Stack direction='row' alignItems='center'>
            <Typography
              variant='body2'
              sx={{ fontSize: '14px', fontWeight: 700 }}
            >
              Suggestions
            </Typography>
            {ROLE_ID !== profileRoles?.buyer ? (
              <Button
                variant='text'
                sx={{ fontSize: '14px', color: '#0066c0' }}
                onClick={(e) => {
                  e.preventDefault();
                  setShowNewSuggestionModal(true);
                }}
              >
                <AddIcon fill='#0066c0' fontSize='10px' />
                New
              </Button>
            ) : null}
          </Stack>
          <Stack sx={{ paddingRight: '31px' }}>
            {ROLE_ID !== profileRoles?.buyer ? (
              <FormControlLabel
                label={
                  <Typography sx={{ fontSize: 14 }}>{`${
                    selectedSuggestions?.length
                  }/${suggestionData?.count ?? 0} Selected`}</Typography>
                }
                labelPlacement='start'
                control={
                  <Checkbox
                    checked={selectAllChecked}
                    sx={{ marginLeft: '5px', width: '18px', height: '18px' }}
                    onClick={(e) => {
                      if (e.target.checked) {
                        var arr = [];
                        suggestionData?.data?.forEach((suggestion) => {
                          arr.push(suggestion.id);
                        });
                        setSelectedSuggestions(arr);
                      } else {
                        setSelectedSuggestions([]);
                      }
                    }}
                  />
                }
              />
            ) : null}
          </Stack>
        </Stack>
        {!suggestionsLoading ? (
          <>
            {suggestionData?.data?.length > 0 ||
            sharedSuggestionsData.length > 0 ? (
              <Stack
                sx={{
                  overflow: 'scroll',
                  marginRight: '-4px',
                  maxHeight: '500px',
                }}
              >
                {suggestionData?.data?.map((item, i) => (
                  <Grid
                    key={i}
                    container
                    sx={[
                      {
                        padding: '0 20px 5px 20px',
                        borderBottom: '1px solid var(--secondary-border-color)',
                      },
                      {
                        '&:hover': {
                          backgroundColor: '#f4f5f7',
                          cursor: 'pointer',
                        },
                      },
                      item.active === 'true'
                        ? {
                            borderLeft: '3px solid',
                            borderColor: 'primary.main',
                            borderBottom:
                              '1px solid var(--secondary-border-color)',
                          }
                        : {},
                    ]}
                  >
                    <Grid
                      item
                      xs={1}
                      sm={1}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        paddingTop: '7px',
                      }}
                    >
                      <SuggestionsQuickPanelIcon
                        active={item.active === 'true'}
                        fill='rgba(0, 0, 0, 0.6)'
                      />
                    </Grid>
                    <Grid
                      item
                      xs={9}
                      sm={9}
                      style={{
                        padding: '7px 0 4px 14px',
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveSuggestion(item.id);
                        navigate(`/suggestions/${item.id}`);
                      }}
                    >
                      <Typography
                        variant='body2'
                        textAlign='left'
                        noWrap
                        sx={[
                          item.active === 'true'
                            ? { color: 'primary.main' }
                            : { color: 'var(--primary-font-color)' },
                          {
                            fontSize: '13px',
                            fontWeight: 700,
                          },
                        ]}
                      >
                        {item.name}
                      </Typography>

                      <Stack
                        direction='row'
                        spacing={2}
                        sx={{
                          padding: '5px 0 1px 0',
                        }}
                      >
                        <Typography
                          variant='subtitle2'
                          style={{
                            color: 'rgba(77, 70, 99, 0.7)',
                          }}
                        >
                          ITEMS:{' '}
                          <span
                            style={{
                              fontSize: '12px',
                              color: 'var(--primary-font-color)',
                            }}
                          >
                            {item.products}
                          </span>
                        </Typography>
                        <Typography
                          variant='subtitle2'
                          style={{
                            color: 'rgba(77, 70, 99, 0.7)',
                          }}
                        >
                          DELIVERIES:{' '}
                          <span
                            style={{
                              fontSize: '12px',
                              color: 'var(--primary-font-color)',
                            }}
                          >
                            {item.deliveries}
                          </span>
                        </Typography>
                      </Stack>
                      <Typography
                        textAlign='left'
                        sx={{
                          fontSize: '12px',
                          color: 'rgba(77, 70, 99, 0.7)',
                        }}
                      >
                        <AccessTimeIcon
                          sx={{
                            width: '12px',
                            height: '12px',
                            position: 'relative',
                            top: '2px',
                          }}
                        />{' '}
                        Created on {item.created}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      sm={1}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <IconButton
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectedId(item.id);
                          setShowDeleteConfirmation(true);
                        }}
                      >
                        <Delete
                          fontSize='small'
                          sx={{ color: 'rgba(255, 0, 0, 0.6)' }}
                        />
                      </IconButton>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      sm={1}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Checkbox
                        checked={isSelected(item.id)}
                        sx={{ width: '18px', height: '18px' }}
                        onClick={(e) => {
                          e.preventDefault();
                          toggleSelected(item.id);
                        }}
                      />
                    </Grid>
                  </Grid>
                ))}
                {/* received suggestions */}
                {sharedSuggestionsData?.length > 0 ? (
                  <>
                    {ROLE_ID !== profileRoles?.buyer ? (
                      <Typography
                        variant='body2'
                        sx={{
                          fontSize: '13px',
                          fontWeight: 700,
                          padding: '10px 20px',
                        }}
                      >
                        Received Suggestions
                      </Typography>
                    ) : null}

                    {sharedSuggestionsData?.map((item, i) => (
                      <Grid
                        key={i}
                        container
                        sx={[
                          {
                            padding: '0 20px 5px 20px',
                            borderBottom:
                              '1px solid var(--secondary-border-color)',
                          },
                          {
                            '&:hover': {
                              backgroundColor: '#f4f5f7',
                              cursor: 'pointer',
                            },
                          },
                          item.active === 'true'
                            ? {
                                borderLeft: '3px solid',
                                borderColor: 'primary.main',
                                borderBottom:
                                  '1px solid var(--secondary-border-color)',
                              }
                            : {},
                        ]}
                      >
                        <Grid
                          item
                          xs={1}
                          sm={1}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            paddingTop: '7px',
                          }}
                        >
                          <SuggestionsQuickPanelIcon
                            active={item.active === 'true'}
                            fill='rgba(0, 0, 0, 0.6)'
                          />
                        </Grid>
                        <Grid
                          item
                          xs={9}
                          sm={9}
                          style={{
                            padding: '7px 0 4px 14px',
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            // setActiveSuggestion(item.id);
                            navigate(`/suggestions/${item.id}`);
                          }}
                        >
                          <Typography
                            variant='body2'
                            textAlign='left'
                            noWrap
                            sx={[
                              item.active === 'true'
                                ? { color: 'primary.main' }
                                : { color: 'var(--primary-font-color)' },
                              {
                                fontSize: '13px',
                                fontWeight: 700,
                              },
                            ]}
                          >
                            {item.name}
                          </Typography>

                          <Stack
                            direction='row'
                            spacing={2}
                            sx={{
                              padding: '5px 0 1px 0',
                            }}
                          >
                            <Typography
                              variant='subtitle2'
                              style={{
                                color: 'rgba(77, 70, 99, 0.7)',
                              }}
                            >
                              ITEMS:{' '}
                              <span
                                style={{
                                  fontSize: '12px',
                                  color: 'var(--primary-font-color)',
                                }}
                              >
                                {item.products}
                              </span>
                            </Typography>
                            <Typography
                              variant='subtitle2'
                              style={{
                                color: 'rgba(77, 70, 99, 0.7)',
                              }}
                            >
                              DELIVERIES:{' '}
                              <span
                                style={{
                                  fontSize: '12px',
                                  color: 'var(--primary-font-color)',
                                }}
                              >
                                {item.deliveries}
                              </span>
                            </Typography>
                          </Stack>
                          <Typography
                            textAlign='left'
                            sx={{
                              fontSize: '12px',
                              color: 'rgba(77, 70, 99, 0.7)',
                            }}
                          >
                            <AccessTimeIcon
                              sx={{
                                width: '12px',
                                height: '12px',
                                position: 'relative',
                                top: '2px',
                              }}
                            />{' '}
                            Shared By {item.shared_by} on {item?.shared_at}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          sm={1}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {/* <IconButton
                            onClick={(e) => {
                              e.preventDefault();
                              setSelectedId(item.id);
                              setShowDeleteConfirmation(true);
                            }}
                          >
                            <Delete
                              fontSize='small'
                              sx={{ color: 'rgba(0, 0, 0, 0.6)' }}
                            />
                          </IconButton> */}
                        </Grid>
                        {/* <Grid
                          item
                          xs={1}
                          sm={1}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Checkbox
                            checked={isSelected(item.id)}
                            sx={{ width: '18px', height: '18px' }}
                            onClick={(e) => {
                              e.preventDefault();
                              toggleSelected(item.id);
                            }}
                          />
                        </Grid> */}
                      </Grid>
                    ))}
                  </>
                ) : null}
              </Stack>
            ) : (
              <Box
                sx={{
                  width: '100%',
                  height: '500px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <EmptyState
                  title='No suggestions created yet'
                  subTitle=''
                  icon={<BuyerEmptyStateIcon />}
                />
              </Box>
            )}
          </>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <Stack
          sx={{
            borderTop: '1px solid rgba(205, 204, 214, 0.7)',
            position: 'absolute',
            bottom: 0,
            width: '100%',
            padding: '0 10px',
            backgroundColor: 'var(--modal-header-color)',
          }}
          direction='row'
          justifyContent='space-between'
        >
          <Button
            variant='text'
            sx={{
              fontSize: '12px',
              color: '#0066c0',
              fontWeight: '500',
            }}
            onClick={(e) => {
              e.preventDefault();
              navigate('/suggestions');
            }}
          >
            View All Suggestions
          </Button>
          {ROLE_ID !== profileRoles?.buyer ? (
            <Button
              variant='text'
              sx={{
                color: 'var(--error-color)',
                fontSize: '12px',
                fontWeight: '500',
              }}
              onClick={(e) => {
                e.preventDefault();
                if (selectedSuggestions.length === 0) {
                  setShowBulkDeleteAlert(true);
                } else {
                  setShowBulkDeleteConfirm(true);
                }
              }}
            >
              Delete Suggestions
            </Button>
          ) : null}
        </Stack>
      </Paper>
    </Fade>
  );
}

SuggestionsQuickPanel.propTypes = {
  mouseOver: PropTypes.func.isRequired,
  mouseOut: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
