const namespace = 'components/snackbar';

export const SNACKBAR_SHOW = `${namespace}/SNACKBAR_SHOW`;
export const CLEAR_SNACKBAR_MESSAGE = `${namespace}/CLEAR_SNACKBAR_MESSAGE`;

export const showSnackbar = (payload) => ({
  type: SNACKBAR_SHOW,
  payload,
});
export const clearSnackbarMessage = () => ({
  type: CLEAR_SNACKBAR_MESSAGE,
});
