import React from 'react';
import PropTypes from 'prop-types';

export default function SiteMaintainance({ fill, sx }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='352.777'
      height='213.078'
      viewBox='0 0 352.777 213.078'
    >
      <g
        id='Computer_troubleshooting-pana'
        data-name='Computer troubleshooting-pana'
        transform='translate(-33.85 -42.46)'
      >
        <g
          id='freepik--background-complete--inject-10'
          transform='translate(38.458 42.46)'
        >
          <path
            id='Path_20968'
            data-name='Path 20968'
            d='M356.032,144.226a3.7,3.7,0,0,1-1.228-.207l-6.336-2.2a3.777,3.777,0,0,1-2.461-2.844l-1.269-6.6a3.762,3.762,0,0,1,1.233-3.554l5.072-4.393a3.782,3.782,0,0,1,3.7-.715l6.341,2.2a3.766,3.766,0,0,1,2.466,2.844l1.264,6.59a3.787,3.787,0,0,1-1.228,3.559l-5.077,4.393a3.772,3.772,0,0,1-2.477.917Zm-6.01-6.248,5.927,2.072,4.746-4.1-1.186-6.16-5.927-2.072-4.741,4.109Z'
            transform='translate(-188.243 -81.521)'
            fill='#f5f5f5'
          />
          <path
            id='Path_20969'
            data-name='Path 20969'
            d='M502.361,113.331a3.7,3.7,0,0,1-1.228-.207l-3.212-1.114a3.767,3.767,0,0,1-2.461-2.844l-.642-3.337a3.772,3.772,0,0,1,1.228-3.554l2.591-2.228a3.767,3.767,0,0,1,3.694-.71l3.207,1.114A3.767,3.767,0,0,1,508,103.3l.642,3.337a3.761,3.761,0,0,1-1.233,3.554l-2.59,2.228A3.8,3.8,0,0,1,502.361,113.331Zm-2.875-5.181,2.8.969,2.233-1.938-.56-2.9-2.793-.969-2.238,1.969Z'
            transform='translate(-260.567 -69.77)'
            fill='#f5f5f5'
          />
          <path
            id='Path_20970'
            data-name='Path 20970'
            d='M676.461,210.669a2.746,2.746,0,0,1-2.129-1.036l-1.808-2.259a2.736,2.736,0,0,1-.414-2.694l1.036-2.694a2.725,2.725,0,0,1,2.124-1.7l2.86-.44a2.741,2.741,0,0,1,2.544.99l1.808,2.259a2.735,2.735,0,0,1,.414,2.694l-1.036,2.694a2.72,2.72,0,0,1-2.129,1.7l-2.86.44A2.675,2.675,0,0,1,676.461,210.669Zm2.1-8.771h-.1l-2.86.44a.663.663,0,0,0-.518.409l-1.036,2.694a.653.653,0,0,0,.1.648l1.808,2.259a.673.673,0,0,0,.611.238l2.86-.44a.653.653,0,0,0,.518-.409l1.036-2.694a.664.664,0,0,0-.1-.648h0l-1.808-2.259A.668.668,0,0,0,678.559,201.9Z'
            transform='translate(-345.948 -118.288)'
            fill='#e0e0e0'
          />
          <path
            id='Path_20971'
            data-name='Path 20971'
            d='M50.493,245.925a3.533,3.533,0,0,1-2.741-1.311L43.5,239.309a3.513,3.513,0,0,1-.518-3.461l2.482-6.336a3.513,3.513,0,0,1,2.73-2.192l6.735-1.036a3.518,3.518,0,0,1,3.264,1.275l4.212,5.295a3.518,3.518,0,0,1,.518,3.466l-2.435,6.342a3.513,3.513,0,0,1-2.736,2.2l-6.735,1.036a3.025,3.025,0,0,1-.528.031Zm-4.114-8.808,4.145,5.181,6.58-1.005,2.414-6.217-4.145-5.181-6.58,1.01ZM59.58,235.153Z'
            transform='translate(-42.744 -131.026)'
            fill='#e0e0e0'
          />
          <path
            id='Path_20972'
            data-name='Path 20972'
            d='M82.757,163.092h-4a2.73,2.73,0,0,1-2.363-1.347l-2.005-3.466a2.761,2.761,0,0,1,0-2.73l2.005-3.466a2.725,2.725,0,0,1,2.363-1.363h4a2.725,2.725,0,0,1,2.363,1.363l2.005,3.445a2.761,2.761,0,0,1,0,2.73l-2.005,3.487A2.736,2.736,0,0,1,82.757,163.092Zm-4-10.32a.663.663,0,0,0-.57.326l-2,3.466a.653.653,0,0,0,0,.658l2,3.487a.668.668,0,0,0,.57.332h4a.668.668,0,0,0,.58-.332l2-3.466a.653.653,0,0,0,0-.658l-2-3.487a.663.663,0,0,0-.57-.326Z'
            transform='translate(-57.818 -94.631)'
            fill='#ebebeb'
          />
          <path
            id='Path_20973'
            data-name='Path 20973'
            d='M191.784,71.727l-7.813-4.917-9.59-16.185L161.04,42.46l-2.58,2.793,1.352,1.824,4.212,5.647,16.719,22.444,6.818,3.751Z'
            transform='translate(-98.508 -42.46)'
            fill='#ebebeb'
          />
          <path
            id='Path_20974'
            data-name='Path 20974'
            d='M175.851,88.371l-2.238-7.45L149.044,52.85l-3.274,1.938,5.186,14.761,14.263,14.009,3.2,8.745Z'
            transform='translate(-92.393 -47.467)'
            fill='#ebebeb'
          />
          <path
            id='Path_20975'
            data-name='Path 20975'
            d='M224.72,101.63S252.464,116.857,258.055,122s18.651,26.542,17.325,29.278-2.342,3.109-5.352.456-12.74-17.315-15.351-19.688-35.117-21.413-35.117-21.413Z'
            transform='translate(-127.952 -70.974)'
            fill='#e0e0e0'
          />
          <circle
            id='Ellipse_69'
            data-name='Ellipse 69'
            cx='8.787'
            cy='8.787'
            r='8.787'
            transform='translate(69.169 19.605)'
            fill='#ebebeb'
          />
          <path
            id='Path_20976'
            data-name='Path 20976'
            d='M182.753,97.482a13.075,13.075,0,0,0,2.145.223,8.393,8.393,0,0,0,2.487-.368,8.7,8.7,0,0,0,6.217-9.165,8.237,8.237,0,0,0-.642-2.435,9.477,9.477,0,0,0-.56-1.155,8.957,8.957,0,0,0-.762-1.072,8.611,8.611,0,0,0-9.9-2.59,10.309,10.309,0,0,0-1.181.565,9.631,9.631,0,0,0-1.036.741,8.057,8.057,0,0,0-1.694,1.824,8.686,8.686,0,0,0,.943,11.02,8.445,8.445,0,0,0,1.995,1.554,13.472,13.472,0,0,0,1.984.85.554.554,0,0,1-.14,0l-.4-.114a3.993,3.993,0,0,1-.642-.223,6.275,6.275,0,0,1-.845-.394,8.29,8.29,0,0,1-2.072-1.518,8.789,8.789,0,0,1-1.036-11.269,8.435,8.435,0,0,1,1.741-1.886,8.238,8.238,0,0,1,2.306-1.352,8.875,8.875,0,0,1,10.2,2.653,9.946,9.946,0,0,1,.777,1.109,9.374,9.374,0,0,1,.575,1.2,8.192,8.192,0,0,1,.6,2.487,8.808,8.808,0,0,1-3.383,7.813,8.714,8.714,0,0,1-3.015,1.523,8.186,8.186,0,0,1-2.528.326,6.142,6.142,0,0,1-.933-.067,4.212,4.212,0,0,1-.679-.1l-.409-.1A.472.472,0,0,1,182.753,97.482Z'
            transform='translate(-106.978 -60.588)'
            fill='#e0e0e0'
          />
          <path
            id='Path_20977'
            data-name='Path 20977'
            d='M187.348,97.443a4.963,4.963,0,1,1,6.046-3.554A4.963,4.963,0,0,1,187.348,97.443Z'
            transform='translate(-110.636 -64.248)'
            fill='#e0e0e0'
          />
          <path
            id='Path_20978'
            data-name='Path 20978'
            d='M187.254,97.324c0-.036.43.1,1.207.1a4.937,4.937,0,0,0,4.425-2.839,4.85,4.85,0,0,0-.648-5.108,4.8,4.8,0,0,0-5.523-1.435,4.419,4.419,0,0,0-1.249.715,4.5,4.5,0,0,0-.948,1.036,4.9,4.9,0,0,0-.855,2.4,4.823,4.823,0,0,0,.352,2.207,4.932,4.932,0,0,0,2.129,2.425,10.506,10.506,0,0,0,1.109.518,3.741,3.741,0,0,1-1.161-.409,4.937,4.937,0,0,1-2.254-2.44,4.989,4.989,0,0,1-.4-2.3,5.093,5.093,0,0,1,.87-2.534,4.834,4.834,0,0,1,.969-1.1A4.782,4.782,0,0,1,186.6,87.8a5.071,5.071,0,0,1,5.036,8.7,4.958,4.958,0,0,1-3.16,1.036,3.7,3.7,0,0,1-1.218-.207Z'
            transform='translate(-110.541 -64.13)'
            fill='#e0e0e0'
          />
          <rect
            id='Rectangle_4540'
            data-name='Rectangle 4540'
            width='14.854'
            height='8.036'
            rx='4.018'
            transform='translate(85.862 38.537) rotate(-60.12)'
            fill='#ebebeb'
          />
          <path
            id='Path_20979'
            data-name='Path 20979'
            d='M211.64,102.747a5.139,5.139,0,0,0-.43,1.456,3.983,3.983,0,0,0,.233,1.746,4.078,4.078,0,0,0,.518.964,3.777,3.777,0,0,0,.9.834,5.555,5.555,0,0,0,1.212.617,3.7,3.7,0,0,0,4.181-1.332c1.192-1.984,2.451-4.259,3.808-6.6a3.71,3.71,0,0,0,0-3.627,3.627,3.627,0,0,0-1.171-1.326,4.477,4.477,0,0,0-1.591-.7,3.715,3.715,0,0,0-3.041.725,7.617,7.617,0,0,0-1.648,2.311l-2.135,3.627-.591.979a1.96,1.96,0,0,1-.223.332,3.4,3.4,0,0,1,.176-.363c.14-.249.321-.585.549-1,.518-.891,1.2-2.135,2.072-3.663l.684-1.212a4.1,4.1,0,0,1,.99-1.181,3.932,3.932,0,0,1,3.2-.8,4.694,4.694,0,0,1,1.668.725,3.839,3.839,0,0,1,1.321,1.394,3.948,3.948,0,0,1,0,3.906c-1.363,2.347-2.59,4.585-3.839,6.611a3.984,3.984,0,0,1-2.922,1.591,4.036,4.036,0,0,1-1.528-.207,5.819,5.819,0,0,1-1.249-.663,3.938,3.938,0,0,1-.938-.886,4.285,4.285,0,0,1-.518-1.036,3.741,3.741,0,0,1,.135-2.912A2.116,2.116,0,0,1,211.64,102.747Z'
            transform='translate(-123.876 -67.521)'
            fill='#e0e0e0'
          />
          <path
            id='Path_20980'
            data-name='Path 20980'
            d='M191.47,134.186s10.512,29.853,14.678,36.2S229.3,193.1,232.213,192.244s3.425-1.808,1.321-5.181-15.025-15.382-16.931-18.351-15.444-38.132-15.444-38.132Z'
            transform='translate(-114.416 -84.925)'
            fill='#e0e0e0'
          />
          <rect
            id='Rectangle_4541'
            data-name='Rectangle 4541'
            width='14.854'
            height='8.036'
            rx='4.018'
            transform='matrix(0.937, -0.351, 0.351, 0.937, 72.695, 44.994)'
            fill='#ebebeb'
          />
          <path
            id='Path_20981'
            data-name='Path 20981'
            d='M194.28,121.559a1.823,1.823,0,0,1,.373-.145,3.818,3.818,0,0,1,1.145-.145,3.9,3.9,0,0,1,1.751.482,4.326,4.326,0,0,1,.912.7,4.144,4.144,0,0,1,.725,1.067,5.6,5.6,0,0,1,.446,1.342,3.891,3.891,0,0,1-.041,1.554,3.969,3.969,0,0,1-2.072,2.622c-2.2.886-4.606,1.751-7.145,2.715a3.984,3.984,0,0,1-3.86-.622,3.938,3.938,0,0,1-1.161-1.554,4.663,4.663,0,0,1-.446-1.782,3.927,3.927,0,0,1,1.306-3.057,4.093,4.093,0,0,1,1.326-.782l1.306-.482,3.948-1.43,1.078-.378a2.175,2.175,0,0,1,.383-.114,1.727,1.727,0,0,1-.363.166l-1.036.425-3.917,1.513a7.772,7.772,0,0,0-2.549,1.249,3.735,3.735,0,0,0-1.212,2.886,4.565,4.565,0,0,0,.44,1.684,3.627,3.627,0,0,0,1.088,1.43,3.709,3.709,0,0,0,3.6.575c2.523-.958,4.974-1.834,7.129-2.684a3.725,3.725,0,0,0,1.995-3.906,5.538,5.538,0,0,0-.42-1.3,3.87,3.87,0,0,0-.674-1.036,3.989,3.989,0,0,0-.86-.684,3.86,3.86,0,0,0-1.684-.518A5.021,5.021,0,0,0,194.28,121.559Z'
            transform='translate(-111.252 -80.438)'
            fill='#e0e0e0'
          />
        </g>
        <g id='freepik--Floor--inject-10' transform='translate(33.85 255.268)'>
          <path
            id='Path_20982'
            data-name='Path 20982'
            d='M386.627,453.345c0,.073-78.979.135-176.381.135s-176.4-.062-176.4-.135,78.963-.135,176.4-.135S386.627,453.272,386.627,453.345Z'
            transform='translate(-33.85 -453.21)'
            fill='#263238'
          />
        </g>
        <g
          id='freepik--Screwdriver--inject-10'
          transform='translate(40.005 129.314)'
        >
          <path
            id='Path_20983'
            data-name='Path 20983'
            d='M87,277.015c.14-.435,16.284-53.209,16.284-53.209l-.4-6.554L107.1,210.3l5.243,1.736-.715,7.771-3.73,5.668L91.663,278.533Z'
            transform='translate(-65.618 -210.196)'
            fill='#e0e0e0'
          />
          <path
            id='Path_20984'
            data-name='Path 20984'
            d='M87,276.919l.311.088.9.28,3.456,1.083-.057.031c2.627-8.606,8.7-28.521,16.18-53.043h0l3.725-5.663v.057c.228-2.482.471-5.119.715-7.771l.093.14-.435-.14-4.808-1.591.155-.057-4.223,6.953v-.078q.2,3.352.4,6.554v.047c-4.72,15.331-8.808,28.609-11.714,38.059-1.461,4.73-2.632,8.5-3.435,11.092-.4,1.3-.715,2.3-.927,2.979l-.243.772-.088.264a2.3,2.3,0,0,1,.067-.254c.052-.181.124-.435.223-.762.207-.684.518-1.679.891-2.964.782-2.59,1.922-6.362,3.352-11.087,2.891-9.481,6.942-22.8,11.626-38.179v.047q-.2-3.2-.409-6.554v-.078l4.222-6.953.052-.093.1.036,4.813,1.585.43.145.1.031v.109c-.244,2.668-.487,5.305-.72,7.771v.062l-3.725,5.668v-.036c-7.559,24.47-13.683,44.359-16.336,52.965v.047h-.047l-3.461-1.145-.876-.3Z'
            transform='translate(-65.618 -210.1)'
            fill='#263238'
          />
          <path
            id='Path_20985'
            data-name='Path 20985'
            d='M63.221,339.185l-7.253,9.8L45.828,385.773s-1.456,7.5,6.829,9.631c9.326,2.394,11.45-4.735,11.45-4.735l9.844-35.92-.1-12.455,2.43-2.741a1.036,1.036,0,0,0-.44-1.653L64.19,334.056a1.036,1.036,0,0,0-1.342,1.072Z'
            transform='translate(-45.73 -269.812)'
            fill='#b6b6b6'
          />
          <path
            id='Path_20986'
            data-name='Path 20986'
            d='M90.058,347.63c-.026.073-2.466-.637-5.45-1.585s-5.393-1.777-5.367-1.85,2.461.642,5.45,1.591S90.078,347.558,90.058,347.63Z'
            transform='translate(-61.878 -274.718)'
            fill='#263238'
          />
          <path
            id='Path_20987'
            data-name='Path 20987'
            d='M83.663,368.759a3.452,3.452,0,0,1-.725-.124c-.466-.1-1.129-.259-1.953-.466-1.648-.409-3.906-1.036-6.367-1.824s-4.663-1.585-6.253-2.2c-.787-.311-1.425-.57-1.86-.756a3.692,3.692,0,0,1-.663-.316,3.768,3.768,0,0,1,.7.218l1.886.673c1.6.57,3.8,1.342,6.264,2.124s4.709,1.44,6.336,1.9l1.933.544A4.011,4.011,0,0,1,83.663,368.759Z'
            transform='translate(-55.421 -283.817)'
            fill='#263238'
          />
          <g
            id='Group_7912'
            data-name='Group 7912'
            transform='translate(2.775 82.523)'
            opacity='0.3'
          >
            <rect
              id='Rectangle_4542'
              data-name='Rectangle 4542'
              width='3.684'
              height='35.484'
              rx='1.842'
              transform='translate(9.936 0) rotate(16.26)'
            />
          </g>
          <g
            id='Group_7913'
            data-name='Group 7913'
            transform='translate(7.973 84.208)'
            opacity='0.3'
          >
            <rect
              id='Rectangle_4543'
              data-name='Rectangle 4543'
              width='3.684'
              height='35.484'
              rx='1.842'
              transform='translate(9.936 0) rotate(16.26)'
            />
          </g>
          <g
            id='Group_7914'
            data-name='Group 7914'
            transform='translate(12.526 85.751)'
            opacity='0.3'
          >
            <rect
              id='Rectangle_4544'
              data-name='Rectangle 4544'
              width='3.684'
              height='35.484'
              rx='1.842'
              transform='translate(10.072 0) rotate(16.49)'
            />
          </g>
          <path
            id='Path_20988'
            data-name='Path 20988'
            d='M123.058,238.14a10.819,10.819,0,0,1-2.311-.658,10.361,10.361,0,0,1-2.217-.912,11.2,11.2,0,0,1,2.306.658A10.5,10.5,0,0,1,123.058,238.14Z'
            transform='translate(-80.813 -222.856)'
            fill='#263238'
          />
          <path
            id='Path_20989'
            data-name='Path 20989'
            d='M128.519,212.04a4.21,4.21,0,0,1-.181.725c-.15.518-.347,1.171-.591,1.979l-2.072,6.735-.041.13-.13-.041-2.388-.834-2.456-.86-.119-.041.036-.119c.746-2.663,1.4-5,1.891-6.735.238-.8.43-1.466.58-1.964a4.748,4.748,0,0,1,.238-.7,4.207,4.207,0,0,1-.15.731l-.518,1.984c-.461,1.725-1.083,4.067-1.8,6.735l-.083-.161,2.461.86,2.383.834-.171.088,2.176-6.689c.269-.8.518-1.456.663-1.953A4.441,4.441,0,0,1,128.519,212.04Z'
            transform='translate(-81.781 -210.201)'
            fill='#263238'
          />
        </g>
        <g id='freepik--Gears--inject-10' transform='translate(190.788 56.687)'>
          <path
            id='Path_20990'
            data-name='Path 20990'
            d='M420.322,92.659a3.7,3.7,0,0,1-1.228-.207l-6.347-2.2a3.777,3.777,0,0,1-2.461-2.844l-1.269-6.6a3.761,3.761,0,0,1,1.233-3.554l5.077-4.393a3.772,3.772,0,0,1,3.694-.71l6.341,2.2a3.777,3.777,0,0,1,2.466,2.844l1.269,6.6a3.787,3.787,0,0,1-1.233,3.554l-5.077,4.393A3.772,3.772,0,0,1,420.322,92.659Zm-6.01-6.248,5.932,2.072,4.741-4.1L423.8,78.194l-5.927-2.072-4.751,4.145Z'
            transform='translate(-371.55 -70.899)'
            fill='#e0e0e0'
          />
          <path
            id='Path_20991'
            data-name='Path 20991'
            d='M420.322,92.659a3.7,3.7,0,0,1-1.228-.207l-6.347-2.2a3.777,3.777,0,0,1-2.461-2.844l-1.269-6.6a3.761,3.761,0,0,1,1.233-3.554l5.077-4.393a3.772,3.772,0,0,1,3.694-.71l6.341,2.2a3.777,3.777,0,0,1,2.466,2.844l1.269,6.6a3.787,3.787,0,0,1-1.233,3.554l-5.077,4.393A3.772,3.772,0,0,1,420.322,92.659Zm-6.01-6.248,5.932,2.072,4.741-4.1L423.8,78.194l-5.927-2.072-4.751,4.145Z'
            transform='translate(-371.55 -70.899)'
            fill='none'
          />
          <path
            id='Path_20992'
            data-name='Path 20992'
            d='M418.107,90.189a3.314,3.314,0,0,1-.1-.409l-.259-1.218c-.223-1.093-.518-2.632-.917-4.513a.1.1,0,0,1,.031-.1l4.725-4.145a.135.135,0,0,1,.124,0l5.927,2.072.093.1h0v.192l.041.2.078.4c.052.264.1.518.15.8.1.518.2,1.036.3,1.554q.3,1.554.57,2.989a.124.124,0,0,1-.041.119L424.1,92.333a.1.1,0,0,1-.1,0l-4.336-1.554-1.166-.435a2.932,2.932,0,0,1-.394-.161,3.147,3.147,0,0,1,.435.124l1.2.383,4.331,1.451h-.1L428.7,88l-.041.124c-.187-.964-.383-1.958-.58-2.989-.1-.518-.2-1.036-.306-1.554-.052-.264-.1-.518-.15-.8l-.078-.4-.041-.2v-.192h0s.166.192.083.1l-5.922-2.072h.124L417.025,84.1l.036-.1c.337,1.876.617,3.4.813,4.5.088.518.161.927.212,1.238A2.486,2.486,0,0,1,418.107,90.189Z'
            transform='translate(-375.345 -74.677)'
            fill='#263238'
          />
          <path
            id='Path_20993'
            data-name='Path 20993'
            d='M420.2,92.552a3.716,3.716,0,0,0,.642-.083,3.771,3.771,0,0,0,1.653-.762l5.155-4.513A3.678,3.678,0,0,0,428.8,85.34a3.8,3.8,0,0,0,.124-1.166,10.885,10.885,0,0,0-.207-1.218c-.326-1.673-.674-3.456-1.036-5.331a5.259,5.259,0,0,0-.378-1.363,3.627,3.627,0,0,0-2.072-1.9l-1.461-.518-3-1.036-1.554-.518a4.176,4.176,0,0,0-1.554-.326,3.684,3.684,0,0,0-1.554.342,5.118,5.118,0,0,0-1.285.917l-2.4,2.072L410.109,77.3a3.658,3.658,0,0,0-1.181,2.5,5.371,5.371,0,0,0,.155,1.373c.088.461.171.912.259,1.357.166.891.332,1.751.487,2.59s.306,1.648.466,2.4a3.7,3.7,0,0,0,1.161,1.844,3.772,3.772,0,0,0,.865.554c.306.135.632.233.933.347l1.736.611,2.777.984a14.8,14.8,0,0,0,1.762.585c.414.078.642.073.642.083a2.71,2.71,0,0,1-.653,0,13.317,13.317,0,0,1-1.782-.544l-2.767-.948-1.741-.591c-.311-.109-.627-.2-.953-.342a3.99,3.99,0,0,1-.917-.575,3.891,3.891,0,0,1-1.238-1.932c-.176-.788-.316-1.585-.482-2.42s-.332-1.7-.518-2.59q-.135-.658-.264-1.352a5.572,5.572,0,0,1-.166-1.43,3.922,3.922,0,0,1,1.259-2.679l2.311-2.01,2.4-2.072c.207-.176.4-.352.622-.518a4.269,4.269,0,0,1,.725-.435,3.9,3.9,0,0,1,1.648-.363,4.451,4.451,0,0,1,1.642.342l1.554.518,3,1.036,1.461.518a3.918,3.918,0,0,1,1.331.8,3.845,3.845,0,0,1,.9,1.233,5.425,5.425,0,0,1,.394,1.425q.518,2.824,1.01,5.336a10.109,10.109,0,0,1,.2,1.249,3.891,3.891,0,0,1-1.368,3.16l-5.212,4.45a3.819,3.819,0,0,1-1.715.736A2.664,2.664,0,0,1,420.2,92.552Z'
            transform='translate(-371.424 -70.792)'
            fill='#263238'
          />
          <path
            id='Path_20994'
            data-name='Path 20994'
            d='M341.446,81a2.73,2.73,0,0,1-2.135-1.036L337.5,77.71a2.746,2.746,0,0,1-.414-2.7l1.036-2.694a2.73,2.73,0,0,1,2.129-1.7l2.855-.44a2.741,2.741,0,0,1,2.544.99l1.808,2.259a2.735,2.735,0,0,1,.414,2.694l-1.036,2.7a2.741,2.741,0,0,1-2.114,1.72l-2.86.435a2.773,2.773,0,0,1-.42.031Zm2.072-8.771h-.1l-2.839.43a.663.663,0,0,0-.518.409l-1.036,2.7a.663.663,0,0,0,.1.648l1.808,2.254a.663.663,0,0,0,.611.238l2.86-.44a.658.658,0,0,0,.518-.409l1.036-2.694a.653.653,0,0,0-.1-.648l-1.808-2.254a.663.663,0,0,0-.513-.233Z'
            transform='translate(-336.831 -70.026)'
            fill='#e0e0e0'
          />
          <path
            id='Path_20995'
            data-name='Path 20995'
            d='M341.446,81a2.73,2.73,0,0,1-2.135-1.036L337.5,77.71a2.746,2.746,0,0,1-.414-2.7l1.036-2.694a2.73,2.73,0,0,1,2.129-1.7l2.855-.44a2.741,2.741,0,0,1,2.544.99l1.808,2.259a2.735,2.735,0,0,1,.414,2.694l-1.036,2.7a2.741,2.741,0,0,1-2.114,1.72l-2.86.435a2.773,2.773,0,0,1-.42.031Zm2.072-8.771h-.1l-2.839.43a.663.663,0,0,0-.518.409l-1.036,2.7a.663.663,0,0,0,.1.648l1.808,2.254a.663.663,0,0,0,.611.238l2.86-.44a.658.658,0,0,0,.518-.409l1.036-2.694a.653.653,0,0,0-.1-.648l-1.808-2.254a.663.663,0,0,0-.513-.233Z'
            transform='translate(-336.831 -70.026)'
            fill='none'
          />
          <path
            id='Path_20996'
            data-name='Path 20996'
            d='M345.353,74.154a.373.373,0,0,1,.223,0,.621.621,0,0,1,.244.13,3.386,3.386,0,0,1,.249.275l1.471,1.767.238.3a.746.746,0,0,1,.145.42,1.035,1.035,0,0,1-.1.43c-.047.13-.1.259-.145.394-.207.518-.425,1.1-.653,1.7-.057.15-.114.3-.176.456a.777.777,0,0,1-.383.43,2.041,2.041,0,0,1-.518.119l-.518.083c-.7.1-1.383.223-2.16.321a.787.787,0,0,1-.591-.264l-.342-.43-.668-.829-.637-.808-.161-.2a.866.866,0,0,1-.145-.243.8.8,0,0,1,0-.57l.674-1.694.306-.767a1.834,1.834,0,0,1,.176-.373.735.735,0,0,1,.332-.259c.995-.181,1.751-.254,2.3-.326a3.943,3.943,0,0,1,.845-.073.943.943,0,0,1-.212.052l-.611.124c-.518.1-1.337.218-2.243.4a.6.6,0,0,0-.244.2,2.122,2.122,0,0,0-.145.337l-.29.772-.653,1.7a.554.554,0,0,0,0,.389,1.771,1.771,0,0,0,.264.368l.663.813.668.829.337.42a.518.518,0,0,0,.389.171l2.119-.316.518-.078a1.719,1.719,0,0,0,.461-.093.518.518,0,0,0,.264-.29c.057-.155.119-.306.176-.456.238-.591.461-1.161.674-1.694a4.145,4.145,0,0,0,.155-.389.886.886,0,0,0,.1-.347.632.632,0,0,0-.1-.311l-.228-.29-1.4-1.819A.876.876,0,0,0,345.353,74.154Z'
            transform='translate(-338.645 -71.952)'
            fill='#263238'
          />
          <path
            id='Path_20997'
            data-name='Path 20997'
            d='M341.378,80.893a2.376,2.376,0,0,1,.342-.052l.99-.171,1.554-.264a3.788,3.788,0,0,0,.943-.228,2.591,2.591,0,0,0,.881-.6,2.741,2.741,0,0,0,.642-1.005c.15-.394.311-.808.471-1.238.285-.865.865-1.751.653-2.741a2.751,2.751,0,0,0-.663-1.347l-1.036-1.285-.518-.663a2.983,2.983,0,0,0-.58-.591,2.59,2.59,0,0,0-1.554-.518,5.611,5.611,0,0,0-.86.1l-.865.13-1.684.264a2.59,2.59,0,0,0-1.347.767,2.69,2.69,0,0,0-.435.622c-.109.223-.2.482-.29.715l-.554,1.4a7.376,7.376,0,0,0-.456,1.316,2.73,2.73,0,0,0,.71,2.274l1.3,1.658c.2.238.357.477.544.658a2.683,2.683,0,0,0,.565.425,3.29,3.29,0,0,0,1.28.373h-.352a2.627,2.627,0,0,1-.974-.285,3.017,3.017,0,0,1-.606-.425,8.29,8.29,0,0,1-.575-.658l-1.332-1.632a2.912,2.912,0,0,1-.777-2.425,7.213,7.213,0,0,1,.451-1.373l.544-1.4c.1-.244.176-.482.3-.736a2.96,2.96,0,0,1,.472-.689,2.9,2.9,0,0,1,1.44-.844c.57-.1,1.124-.176,1.7-.269l.865-.13a5.446,5.446,0,0,1,.9-.1,2.829,2.829,0,0,1,1.715.56,3.171,3.171,0,0,1,.627.637l.518.663c.352.435.694.87,1.036,1.29a2.948,2.948,0,0,1,.71,1.466,2.829,2.829,0,0,1-.166,1.554c-.176.451-.352.891-.518,1.321s-.332.834-.487,1.233a2.875,2.875,0,0,1-.668,1.036,3.041,3.041,0,0,1-1.943.844l-1.554.218c-.43.057-.767.1-.995.119a1.514,1.514,0,0,1-.357.031Z'
            transform='translate(-336.763 -69.92)'
            fill='#263238'
          />
          <path
            id='Path_20998'
            data-name='Path 20998'
            d='M440.329,78.05a7.86,7.86,0,0,1-1.337,1.7,6.454,6.454,0,0,1-1.632,1.435,14.147,14.147,0,0,1,1.435-1.611A9.579,9.579,0,0,1,440.329,78.05Z'
            transform='translate(-385.241 -73.838)'
            fill='#263238'
          />
          <path
            id='Path_20999'
            data-name='Path 20999'
            d='M424.34,72.3a6.181,6.181,0,0,1,.689,1.958,6.1,6.1,0,0,1,.275,2.072,11.535,11.535,0,0,1-.518-2A11.312,11.312,0,0,1,424.34,72.3Z'
            transform='translate(-378.967 -71.067)'
            fill='#263238'
          />
          <path
            id='Path_21000'
            data-name='Path 21000'
            d='M413.2,86.633a12.233,12.233,0,0,1-1.99-.57,13.054,13.054,0,0,1-1.969-.653,9.533,9.533,0,0,1,3.958,1.223Z'
            transform='translate(-371.69 -77.385)'
            fill='#263238'
          />
          <path
            id='Path_21001'
            data-name='Path 21001'
            d='M414.75,102.527a6.217,6.217,0,0,1,1.036-1.482,6.352,6.352,0,0,1,1.264-1.306,5.947,5.947,0,0,1-1.036,1.482,6.353,6.353,0,0,1-1.264,1.306Z'
            transform='translate(-374.345 -84.29)'
            fill='#263238'
          />
          <path
            id='Path_21002'
            data-name='Path 21002'
            d='M431.134,107.448a5.388,5.388,0,0,1-.363-1.668,5.333,5.333,0,0,1-.1-1.71,5.553,5.553,0,0,1,.363,1.673A5.482,5.482,0,0,1,431.134,107.448Z'
            transform='translate(-382.005 -86.377)'
            fill='#263238'
          />
          <path
            id='Path_21003'
            data-name='Path 21003'
            d='M443.064,96.72a4.777,4.777,0,0,1-1.528-.446,4.663,4.663,0,0,1-1.435-.694A9.554,9.554,0,0,1,443.064,96.72Z'
            transform='translate(-386.561 -82.286)'
            fill='#263238'
          />
        </g>
        <g
          id='freepik--Character--inject-10'
          transform='translate(80.349 53.397)'
        >
          <path
            id='Path_21004'
            data-name='Path 21004'
            d='M644.34,141.258a96.16,96.16,0,0,0,9.751-13.263,33.408,33.408,0,0,0,4.8-15.584c0-.834-.337-6.611-1.14-6.844-.57-.166-.964,4.937-1.43,5.305-4.476,3.5-7.383,8.7-9.056,14.128a74.522,74.522,0,0,0-2.927,16.258'
            transform='translate(-374.546 -83.806)'
            fill='#263238'
          />
          <path
            id='Path_21005'
            data-name='Path 21005'
            d='M555.8,157.747c1.4-3.627,8.289-21.76,8.253-21.76s-11.87-6.176-7.771-18.755c1.969-6.077,7.041-18.258,11.756-29.231a24.635,24.635,0,0,1,30.511-13.6l1.207.409c12.776,5.181,15.543,18.895,10.015,31.521l-25.387,58.8-22.247-6.875Z'
            transform='translate(-331.693 -68.163)'
            fill='#ffbf9d'
          />
          <path
            id='Path_21006'
            data-name='Path 21006'
            d='M570.938,194.5a32.129,32.129,0,0,0,17.931.684s-7.056,7.974-19.17,2.627Z'
            transform='translate(-338.577 -126.666)'
            fill='#ff9a6c'
          />
          <path
            id='Path_21007'
            data-name='Path 21007'
            d='M579.632,124.173a1.891,1.891,0,0,0,1.114,2.388,1.808,1.808,0,0,0,2.388-.99,1.886,1.886,0,0,0-1.114-2.388,1.8,1.8,0,0,0-2.388.99Z'
            transform='translate(-343.312 -92.227)'
            fill='#263238'
          />
          <path
            id='Path_21008'
            data-name='Path 21008'
            d='M581.5,112.752c.145.311,1.839-.238,3.761.42s3.01,2.072,3.3,1.927c.14-.067.057-.565-.409-1.223a5.15,5.15,0,0,0-2.539-1.829,4.953,4.953,0,0,0-3.109-.041C581.755,112.26,581.428,112.612,581.5,112.752Z'
            transform='translate(-344.259 -86.801)'
            fill='#263238'
          />
          <path
            id='Path_21009'
            data-name='Path 21009'
            d='M612.221,137.28a1.881,1.881,0,0,0,1.114,2.383,1.8,1.8,0,0,0,2.388-.99,1.891,1.891,0,0,0-1.114-2.388,1.808,1.808,0,0,0-2.389.995Z'
            transform='translate(-359.017 -98.542)'
            fill='#263238'
          />
          <path
            id='Path_21010'
            data-name='Path 21010'
            d='M617.391,126.541c.14.311,1.839-.233,3.761.42s3.005,2.072,3.3,1.933c.135-.073.057-.57-.409-1.223a5.181,5.181,0,0,0-2.544-1.834,4.938,4.938,0,0,0-3.109-.041C617.66,126.049,617.319,126.4,617.391,126.541Z'
            transform='translate(-361.555 -93.445)'
            fill='#263238'
          />
          <path
            id='Path_21011'
            data-name='Path 21011'
            d='M590.218,136.855a13.656,13.656,0,0,0-2.938-1.616c-.471-.218-.907-.471-.876-.839a2.948,2.948,0,0,1,.819-1.342l2.72-3.254c3.818-4.663,6.761-8.533,6.569-8.688s-3.435,3.492-7.253,8.139c-.927,1.15-1.818,2.243-2.663,3.3a3.126,3.126,0,0,0-.907,1.844,1.311,1.311,0,0,0,.565.99,3.683,3.683,0,0,0,.8.4A13,13,0,0,0,590.218,136.855Z'
            transform='translate(-346.283 -91.299)'
            fill='#263238'
          />
          <path
            id='Path_21012'
            data-name='Path 21012'
            d='M597.182,157.755c-.311-.119-1.036,1.969-3.305,2.844s-4.44-.1-4.554.186c-.073.119.363.57,1.29.917a5.528,5.528,0,0,0,3.761-.036,4.792,4.792,0,0,0,2.637-2.43C597.384,158.4,597.327,157.792,597.182,157.755Z'
            transform='translate(-348.029 -108.956)'
            fill='#263238'
          />
          <path
            id='Path_21013'
            data-name='Path 21013'
            d='M622.69,111.87c0,.585,2.021.99,4.186,2.021s3.787,2.269,4.243,1.9c.207-.181.057-.782-.518-1.554a8.056,8.056,0,0,0-6.419-2.989C623.25,111.321,622.7,111.6,622.69,111.87Z'
            transform='translate(-364.113 -86.546)'
            fill='#263238'
          />
          <path
            id='Path_21014'
            data-name='Path 21014'
            d='M587.21,100.758c.192.554,1.668.518,3.269,1.036s2.85,1.29,3.311.938c.212-.176.15-.684-.3-1.3a4.989,4.989,0,0,0-5.305-1.611C587.469,100.105,587.132,100.494,587.21,100.758Z'
            transform='translate(-347.01 -80.936)'
            fill='#263238'
          />
          <path
            id='Path_21015'
            data-name='Path 21015'
            d='M647.946,154.5c.249,0,9.466.357,6.305,8.808s-11.02,3.549-10.942,3.3S647.946,154.5,647.946,154.5Z'
            transform='translate(-374.049 -107.39)'
            fill='#ffbf9d'
          />
          <path
            id='Path_21016'
            data-name='Path 21016'
            d='M650.593,167.828c.047,0,.109.161.316.368a1.554,1.554,0,0,0,1.093.435c1.036,0,2.3-1.135,2.912-2.647a5.182,5.182,0,0,0,.378-2.269,1.8,1.8,0,0,0-.689-1.554.8.8,0,0,0-1.036.078c-.2.2-.212.383-.259.383s-.13-.212.067-.518a1.037,1.037,0,0,1,.518-.409,1.2,1.2,0,0,1,.9.088,2.222,2.222,0,0,1,1.036,1.891,5.4,5.4,0,0,1-.373,2.554c-.694,1.684-2.161,2.958-3.518,2.855a1.71,1.71,0,0,1-1.269-.731C650.531,168.046,650.557,167.834,650.593,167.828Z'
            transform='translate(-377.545 -110.833)'
            fill='#ff9a6c'
          />
          <path
            id='Path_21017'
            data-name='Path 21017'
            d='M600.518,74.827c-1.078-1.275,4.72-4.145,8.663-4.362,5.958-.352,14.253,1.2,18.418,4.326,5.026,3.756,8.626,8.357,9.486,14.569a35.6,35.6,0,0,1-2.777,18.387,10.8,10.8,0,0,1-3.171,4.7c-1.891,1.414-4.606,1.72-5.5,4.336a3.782,3.782,0,0,1-1.865,2.238c-2.176,1.036-3.471-1.218-3.746-3.109-.586-4.02.72-8.046,2.01-11.916a26.23,26.23,0,0,1,3.627-7.823c.953-1.249,2.124-2.321,3.01-3.627a5.5,5.5,0,0,0,1.036-4.445,5.345,5.345,0,0,0-.42-.958,9.435,9.435,0,0,0-8.093-5.015c-5.4-.14-14.507-.746-15.983-3.171-2.16-3.492-2.311-5.285-2.311-5.285Z'
            transform='translate(-353.365 -66.869)'
            fill='#263238'
          />
          <path
            id='Path_21018'
            data-name='Path 21018'
            d='M604.847,66.321c.544.275,13.274,8.139,13.191,19.61s-2.181,14.279-2.181,14.279a7.056,7.056,0,0,1,1.036,1.736c.342.87-1.611,3.824-3.285,2.839-11.429-10.605-27.179-16.7-45.147-22.278l-13.553-4.855c-3.969-.865-4.958-2.342-3.02-2.741,1.487-.311,13.8-.933,13.8-.933l2.658-.819,8.289-5.86s-.321-1.326,1.363-1.756S594.143,60.881,604.847,66.321Z'
            transform='translate(-329.53 -63.57)'
            fill='#b6b6b6'
          />
          <path
            id='Path_21019'
            data-name='Path 21019'
            d='M584.52,82.12s.181.073.518.187l1.554.487c1.337.4,3.238,1.072,5.58,1.933a111.456,111.456,0,0,1,17.465,8.538A170.908,170.908,0,0,1,625.7,104.279c1.99,1.508,3.57,2.761,4.663,3.627l1.269.99c.29.223.451.332.451.332s-.14-.135-.425-.373l-1.238-1.036c-1.083-.881-2.653-2.155-4.627-3.678a159.237,159.237,0,0,0-16.061-11.087,106.421,106.421,0,0,0-17.553-8.492,80.082,80.082,0,0,0-7.657-2.44Z'
            transform='translate(-345.719 -72.509)'
            fill='#263238'
          />
          <g
            id='Group_7915'
            data-name='Group 7915'
            transform='translate(254.23 4.455)'
            opacity='0.3'
          >
            <path
              id='Path_21020'
              data-name='Path 21020'
              d='M645.925,80.889s-14.051-7.523-21.812-6.611l.912-.124-7.253.6c-1.228.1-2.632.14-3.471-.756a31.178,31.178,0,0,1,11.346-1.829C632.594,72.206,642.79,74.672,645.925,80.889Z'
              transform='translate(-614.3 -72.169)'
            />
          </g>
          <path
            id='Path_21021'
            data-name='Path 21021'
            d='M639.461,80.354a11.308,11.308,0,0,0-1.036-1.331,19.634,19.634,0,0,0-3.409-3.078,23.171,23.171,0,0,0-6.057-3.109,19.516,19.516,0,0,0-8.243-1.145,34.286,34.286,0,0,0-4.269.7c-1.358.306-2.679.518-3.927.881a21.072,21.072,0,0,1-3.513.839,6.787,6.787,0,0,1-3.078-.409c-3.673-1.207-5.658-2.736-5.409-2.907a.6.6,0,0,0,.192.389,4.916,4.916,0,0,0,.995.751,21.328,21.328,0,0,0,4.176,1.922,7.025,7.025,0,0,0,3.15.446,21.2,21.2,0,0,0,3.554-.813c1.223-.337,2.559-.565,3.917-.87a36.337,36.337,0,0,1,4.238-.684,19.641,19.641,0,0,1,8.15,1.114,23.707,23.707,0,0,1,6.031,3.031,20.8,20.8,0,0,1,3.435,3c.368.4.643.72.819.943A3.452,3.452,0,0,0,639.461,80.354Z'
            transform='translate(-353.419 -67.054)'
            fill='#263238'
          />
          <g
            id='Group_7916'
            data-name='Group 7916'
            transform='translate(268.126 13.88)'
            opacity='0.3'
          >
            <path
              id='Path_21022'
              data-name='Path 21022'
              d='M661.336,103.582c-2.663-.938-4.445-2.321-6.9-3.813-2.59-1.585-5.238-3.036-7.771-4.746-1.922-1.3-4.248-2.663-5.1-4.663a3.259,3.259,0,0,0-.047,3.207,16.111,16.111,0,0,0,3.834,3.554c5.6,3.808,9.492,6.409,15.75,9.056a19.54,19.54,0,0,0,.228-2.59'
              transform='translate(-641.12 -90.36)'
            />
          </g>
          <path
            id='Path_21023'
            data-name='Path 21023'
            d='M661.612,96.593c1.44,1.311,2.306,2.16,1.86,3.627-.368,1.212-1.114,2.59-2.368,2.4a3.057,3.057,0,0,1-1.036-.456L642.909,92.116a2.844,2.844,0,0,1-1.42-1.368A1.971,1.971,0,0,1,643,88.438a5.213,5.213,0,0,1,3.031.342,64.86,64.86,0,0,1,15.584,7.808'
            transform='translate(-373.135 -75.501)'
            fill='#b6b6b6'
          />
          <path
            id='Path_21024'
            data-name='Path 21024'
            d='M641.6,90.542a1.9,1.9,0,0,1-.218-.974,2.44,2.44,0,0,1,1.554-2.072,6.027,6.027,0,0,1,3.984.456c1.43.58,3.041,1.238,4.663,1.989a51.81,51.81,0,0,1,8.067,4.476c.974.668,1.725,1.259,2.249,1.668l.591.482a1.253,1.253,0,0,0,.218.155,1.863,1.863,0,0,0-.187-.192l-.565-.518c-.518-.43-1.238-1.036-2.207-1.736a47.388,47.388,0,0,0-8.067-4.554c-1.648-.756-3.238-1.409-4.7-1.984a6.156,6.156,0,0,0-4.145-.4,2.523,2.523,0,0,0-1.585,2.223,1.637,1.637,0,0,0,.161.751.864.864,0,0,0,.187.233Z'
            transform='translate(-373.057 -74.921)'
            fill='#263238'
          />
          <path
            id='Path_21025'
            data-name='Path 21025'
            d='M592.788,74.36,604.45,78.1l-2.1,2.927-12.5-4.59Z'
            transform='translate(-348.287 -68.77)'
            fill='#fff'
          />
          <path
            id='Path_21026'
            data-name='Path 21026'
            d='M602.029,95.578c.026-.062-4.88-2.59-10.968-5.606S580,84.517,580,84.579s4.88,2.59,10.968,5.606S602,95.635,602.029,95.578Z'
            transform='translate(-343.541 -73.694)'
            fill='#263238'
          />
          <path
            id='Path_21027'
            data-name='Path 21027'
            d='M538.889,208.823l.155-7.657L512.839,210.9s-4.844-15.735-4.844-16.475l-5.808,3.627-9.652-4.145-9.652-4.766L463.36,223.334l-20.48,60.379L491.125,315.9l35.78-38.339,21.76-33.158,11.958-20.993Z'
            transform='translate(-277.462 -124.083)'
            fill='#b6b6b6'
          />
          <path
            id='Path_21028'
            data-name='Path 21028'
            d='M565.165,219.569a5.251,5.251,0,0,1-.036-.767c0-.518,0-1.254-.031-2.145l.119.073a32.833,32.833,0,0,0-7.891,5.4l-.207.2v-.285c0-.342-.042-.7-.062-1.072-.212-3.756-.389-7.155-.518-9.621-.052-1.2-.093-2.186-.124-2.917v-.793a.14.14,0,1,1,.052,0c0,.207.042.471.073.788.052.73.119,1.715.2,2.912.166,2.461.368,5.86.58,9.616,0,.368.041.731.057,1.072l-.223-.093a30.963,30.963,0,0,1,8-5.357l.124-.052v.13c0,.891-.036,1.611-.047,2.145A5.232,5.232,0,0,1,565.165,219.569Z'
            transform='translate(-332.119 -132.865)'
            fill='#263238'
          />
          <path
            id='Path_21029'
            data-name='Path 21029'
            d='M602.095,227.84a1.469,1.469,0,0,1-.3.161l-.876.42-3.243,1.487-10.756,4.808-5.108,2.259-.218.093.042-.233a40.261,40.261,0,0,0,.591-7.357l.119.073-2.419,1.176-.648.3c-.145.067-.223.1-.228.088a.98.98,0,0,1,.207-.129l.627-.342,2.388-1.243.114-.062v.14a37.639,37.639,0,0,1-.518,7.424l-.176-.14,5.1-2.269,10.787-4.741,3.285-1.409.9-.368A1.458,1.458,0,0,1,602.095,227.84Z'
            transform='translate(-343.083 -142.732)'
            fill='#263238'
          />
          <rect
            id='Rectangle_4545'
            data-name='Rectangle 4545'
            width='212.44'
            height='151.74'
            rx='14.57'
            transform='translate(8.305 46.789)'
            fill='#455a64'
          />
          <path
            id='Path_21030'
            data-name='Path 21030'
            d='M123.6,427.84H353.765V431.9a9.222,9.222,0,0,1-9.222,9.222H132.822A9.222,9.222,0,0,1,123.6,431.9Z'
            transform='translate(-123.6 -239.113)'
            fill='#455a64'
          />
          <rect
            id='Rectangle_4546'
            data-name='Rectangle 4546'
            width='200.441'
            height='128.141'
            transform='translate(14.009 52.82)'
            fill='#263238'
          />
          <path
            id='Path_21031'
            data-name='Path 21031'
            d='M304.23,425.1l2.855,4.906h40.189l2.653-4.906Z'
            transform='translate(-210.646 -237.793)'
            fill='#263238'
          />
          <rect
            id='Rectangle_4547'
            data-name='Rectangle 4547'
            width='192.597'
            height='123.39'
            rx='3.27'
            transform='translate(18.133 57.156)'
            fill='#ebebeb'
          />
          <path
            id='Path_21032'
            data-name='Path 21032'
            d='M350.807,306.039H158.21l.2-114.349h192.4Z'
            transform='translate(-140.279 -125.312)'
            fill='#fafafa'
          />
          <path
            id='Path_21033'
            data-name='Path 21033'
            d='M186.892,181.543a1.585,1.585,0,1,1-1.585-1.663A1.627,1.627,0,0,1,186.892,181.543Z'
            transform='translate(-152.574 -119.62)'
            fill='#263238'
          />
          <path
            id='Path_21034'
            data-name='Path 21034'
            d='M168.333,181.543a1.585,1.585,0,1,1-1.585-1.663A1.627,1.627,0,0,1,168.333,181.543Z'
            transform='translate(-143.63 -119.62)'
            fill='#263238'
          />
          <path
            id='Path_21035'
            data-name='Path 21035'
            d='M177.873,181.543a1.585,1.585,0,1,1-1.585-1.663A1.627,1.627,0,0,1,177.873,181.543Z'
            transform='translate(-148.227 -119.62)'
            fill='#263238'
          />
          <path
            id='Path_21036'
            data-name='Path 21036'
            d='M350.247,424.96c0,.083-47.147.145-105.3.145s-105.314-.062-105.314-.145,47.147-.15,105.314-.15S350.247,424.877,350.247,424.96Z'
            transform='translate(-131.325 -237.653)'
            fill='#263238'
          />
          <path
            id='Path_21037'
            data-name='Path 21037'
            d='M277.963,220.449a41.089,41.089,0,0,1,10.02-4.352l2.072-9.922,16.755-.3,2.414,9.844a41.259,41.259,0,0,1,10.165,4l8.471-5.549,12.056,11.642-5.243,8.668a41.039,41.039,0,0,1,4.352,10.025l9.916,2.072.3,16.755-9.844,2.414a41.053,41.053,0,0,1-4,10.134l5.554,8.471L329.3,296.412l-8.657-5.248a41.036,41.036,0,0,1-10.025,4.352l-2.072,9.922-16.76.29-2.409-9.844a40.977,40.977,0,0,1-10.139-3.974l-8.471,5.554-12.056-11.642,5.248-8.657A41.055,41.055,0,0,1,259.6,267.14l-9.916-2.072-.3-16.755,9.844-2.414a41.175,41.175,0,0,1,4-10.165l-5.549-8.471,11.642-12.056Zm8.932,22.325a12.716,12.716,0,1,1,0,.016Z'
            transform='translate(-184.219 -132.15)'
            fill='#e0e0e0'
          />
          <path
            id='Path_21038'
            data-name='Path 21038'
            d='M430.647,232.645a14.343,14.343,0,0,1,1.166-3.58l-2.072-2.8,3.751-4.393L436.6,223.5a14.177,14.177,0,0,1,3.352-1.7l.518-3.456,5.756-.451,1.036,3.331a14.15,14.15,0,0,1,3.58,1.171l2.8-2.072,4.393,3.756-1.627,3.109a14.135,14.135,0,0,1,1.71,3.357l3.451.518.451,5.761-3.331,1.036a14.017,14.017,0,0,1-1.171,3.58l2.072,2.8-3.751,4.388L452.728,247a14.135,14.135,0,0,1-3.357,1.71l-.518,3.456-5.756.451-1.036-3.337a14.141,14.141,0,0,1-3.58-1.166l-2.8,2.072-4.383-3.8,1.627-3.109a14.232,14.232,0,0,1-1.71-3.357l-3.451-.518-.451-5.761,3.331-1.036Zm7.808,1.746a6.217,6.217,0,1,0,5.1-5.533,6.217,6.217,0,0,0-5.1,5.533Z'
            transform='translate(-269.959 -137.937)'
            fill='#e0e0e0'
          />
          <path
            id='Path_21039'
            data-name='Path 21039'
            d='M186.755,339.432a16.579,16.579,0,0,1,.72-4.388l-2.938-2.865,3.6-5.792,3.9,1.347a16.754,16.754,0,0,1,3.627-2.59l-.031-4.119,6.642-1.554,1.8,3.7a16.544,16.544,0,0,1,4.388.72l2.9-2.938,5.792,3.6-1.347,3.9a16.753,16.753,0,0,1,2.591,3.627l4.119-.031,1.554,6.637-3.71,1.788a16.8,16.8,0,0,1-.725,4.388l2.938,2.891-3.6,5.8-3.9-1.347a16.631,16.631,0,0,1-3.627,2.59l.031,4.119-6.637,1.554-1.8-3.7a16.578,16.578,0,0,1-4.373-.746l-2.891,2.938-5.8-3.6,1.347-3.9a16.755,16.755,0,0,1-2.59-3.627l-4.145.031-1.554-6.637,3.7-1.8Zm9.6,1.865a17.611,17.611,0,1,1,0,.016Z'
            transform='translate(-152.239 -186.889)'
            fill='#e0e0e0'
          />
          <path
            id='Path_21040'
            data-name='Path 21040'
            d='M210.907,240.838a10.437,10.437,0,0,1,.881-2.7l-1.554-2.114,2.829-3.311,2.331,1.228a10.77,10.77,0,0,1,2.528-1.29l.383-2.591,4.342-.342.777,2.513a10.843,10.843,0,0,1,2.7.881l2.114-1.554,3.305,2.829-1.223,2.331a10.558,10.558,0,0,1,1.285,2.528l2.59.378.342,4.342-2.508.756a10.76,10.76,0,0,1-.876,2.7l1.554,2.109-2.829,3.311-2.331-1.228a10.574,10.574,0,0,1-2.528,1.29l-.378,2.59-4.342.337-.782-2.513a10.5,10.5,0,0,1-2.7-.881l-2.109,1.554-3.311-2.829,1.223-2.331a10.559,10.559,0,0,1-1.285-2.528l-2.59-.383-.337-4.342,2.513-.777Zm5.88,2.109a4.663,4.663,0,1,0,1.1-3.4A4.663,4.663,0,0,0,216.788,242.947Z'
            transform='translate(-164.47 -143.638)'
            fill='#ebebeb'
          />
          <path
            id='Path_21041'
            data-name='Path 21041'
            d='M432.151,332.52a9.37,9.37,0,0,1,.756-2.321l-1.352-1.813,2.43-2.844,2.005,1.036a9.127,9.127,0,0,1,2.171-1.109l.326-2.238,3.73-.29.668,2.16a9.157,9.157,0,0,1,2.321.756l1.813-1.352,2.844,2.43-1.036,2a9.165,9.165,0,0,1,1.109,2.176l2.238.326.29,3.73-2.161.668a9.156,9.156,0,0,1-.756,2.321l1.352,1.813-2.43,2.839-2-1.036a9.359,9.359,0,0,1-2.176,1.109l-.326,2.233-3.73.29-.668-2.155a9.374,9.374,0,0,1-2.321-.756l-1.813,1.352-2.855-2.43,1.036-2a9.13,9.13,0,0,1-1.109-2.171l-2.233-.326-.3-3.73,2.16-.668Zm5.051,1.808a4.015,4.015,0,1,0,3.668-4.311,4.015,4.015,0,0,0-3.668,4.311Z'
            transform='translate(-271.245 -188.561)'
            fill='#ebebeb'
          />
          <path
            id='Path_21042'
            data-name='Path 21042'
            d='M313.737,294.684H287.972a8.652,8.652,0,0,1-7.487-12.952l12.89-22.325,12.885-22.325a8.652,8.652,0,0,1,14.978,0l12.89,22.325,12.89,22.325a8.652,8.652,0,0,1-7.492,12.952Z'
            transform='translate(-198.652 -145.105)'
            fill='#b6b6b6'
          />
          <path
            id='Path_21043'
            data-name='Path 21043'
            d='M347.146,289.052s.067.129.2.4a3.544,3.544,0,0,1-.342,3.326,3.5,3.5,0,0,1-.969.964,3.16,3.16,0,0,1-1.482.487c-4.393.047-10.735.036-18.579.057l-27.552.031h-3.937a3.393,3.393,0,0,1-3.176-2.3,3.7,3.7,0,0,1-.13-2.072c.041-.171.1-.342.155-.518a3.44,3.44,0,0,1,.228-.482l.518-.907c.663-1.218,1.337-2.44,2.01-3.678l8.352-15.33,8.4-15.32q2.026-3.715,3.989-7.29c.332-.591.637-1.186.99-1.772a3.984,3.984,0,0,1,1.451-1.44,4.264,4.264,0,0,1,3.9-.176,4.078,4.078,0,0,1,1.554,1.233,15.549,15.549,0,0,1,.958,1.673c.606,1.109,1.207,2.2,1.793,3.28q1.772,3.233,3.425,6.274c2.212,4.046,4.269,7.818,6.145,11.258,3.751,6.885,6.782,12.46,8.88,16.32l2.4,4.44c.269.518.477.891.617,1.161l.207.4s-.083-.129-.228-.389l-.642-1.145-2.451-4.419c-2.114-3.849-5.181-9.4-8.953-16.273l-6.171-11.248-3.44-6.264c-.591-1.078-1.186-2.171-1.8-3.28a16.692,16.692,0,0,0-.948-1.627,3.855,3.855,0,0,0-1.451-1.155,4.01,4.01,0,0,0-3.668.166,3.788,3.788,0,0,0-1.357,1.357c-.342.565-.653,1.171-.979,1.762l-3.989,7.29c-2.7,4.953-5.513,10.077-8.383,15.33l-8.388,15.32c-.679,1.238-1.347,2.466-2.015,3.679a7.716,7.716,0,0,0-.855,1.834,3.445,3.445,0,0,0,.119,1.943,3.108,3.108,0,0,0,2.932,2.129h3.938l27.552.026,18.574.026a3.051,3.051,0,0,0,1.435-.456,3.435,3.435,0,0,0,.958-.933,3.534,3.534,0,0,0,.611-1.99A3.9,3.9,0,0,0,347.146,289.052Z'
            transform='translate(-204.311 -149.865)'
            fill='#263238'
          />
          <path
            id='Path_21044'
            data-name='Path 21044'
            d='M337.67,301.232c0-2.285,1.824-3.989,4.419-3.989s4.378,1.7,4.378,3.989a4.1,4.1,0,0,1-4.378,4.067A4.155,4.155,0,0,1,337.67,301.232Zm.041-23.4h8.756L345,295.072h-5.849Z'
            transform='translate(-226.761 -166.823)'
            fill='#fff'
          />
          <path
            id='Path_21045'
            data-name='Path 21045'
            d='M340.144,235.617a6.994,6.994,0,0,0-1.637-.342,5.834,5.834,0,0,0-3.741,1.254,9.274,9.274,0,0,0-2.451,3.171c-.471.933-.694,1.554-.736,1.528a1.554,1.554,0,0,1,.109-.446,10.882,10.882,0,0,1,.456-1.166,8.73,8.73,0,0,1,2.471-3.305,5.7,5.7,0,0,1,3.917-1.223,3.838,3.838,0,0,1,1.228.3C340.015,235.508,340.154,235.6,340.144,235.617Z'
            transform='translate(-223.826 -146.219)'
            fill='#fff'
          />
          <path
            id='Path_21046'
            data-name='Path 21046'
            d='M327.31,252.23a3.569,3.569,0,0,1,.565-1.243c.378-.637.736-1.124.8-1.088a3.523,3.523,0,0,1-.57,1.223,3.57,3.57,0,0,1-.8,1.109Z'
            transform='translate(-221.768 -153.361)'
            fill='#fff'
          />
          <g
            id='Group_7917'
            data-name='Group 7917'
            transform='translate(82.657 101.386)'
            opacity='0.3'
          >
            <path
              id='Path_21047'
              data-name='Path 21047'
              d='M328.5,259.26l-40.147,24.734-5.212,9.036,48.193-28.863Z'
              transform='translate(-283.14 -259.26)'
              fill='#fff'
            />
          </g>
          <g
            id='Group_7918'
            data-name='Group 7918'
            transform='translate(81.124 112.349)'
            opacity='0.3'
          >
            <path
              id='Path_21048'
              data-name='Path 21048'
              d='M333.394,280.42,280.18,311.806a5.7,5.7,0,0,0,1.155,2.238l53-32Z'
              transform='translate(-280.18 -280.42)'
              fill='#fff'
            />
          </g>
          <path
            id='Path_21049'
            data-name='Path 21049'
            d='M550.283,323.14a15.678,15.678,0,0,0-16.4-4.393l-4.958-5.44-.689-.772L489,269.486a15.672,15.672,0,0,0-16.579-21.729l-1.917.762,6.668,4.471-.357,7.279-5.393,4.9-7.279-.357-4.756-5.243-1.964.772c-.565,4.388,2.378,9.922,5.585,13.471a15.667,15.667,0,0,0,16.372,4.4l39.893,43.769,1.223,1.363,3.782,4.145a15.657,15.657,0,0,0,2.793,16.693c3.191,3.523,7.43,5.875,11.838,5.756l1.969-.767-4.761-5.248.357-7.253,5.393-4.9,7.253.352,2.787,6.015,1.969-.772A15.663,15.663,0,0,0,550.283,323.14Z'
            transform='translate(-284.438 -152.256)'
            fill='#e0e0e0'
          />
          <g
            id='Group_7919'
            data-name='Group 7919'
            transform='translate(172.917 95.347)'
            opacity='0.3'
          >
            <path
              id='Path_21050'
              data-name='Path 21050'
              d='M550.283,323.14a15.678,15.678,0,0,0-16.4-4.393l-4.958-5.44-.689-.772L489,269.486a15.672,15.672,0,0,0-16.579-21.729l-1.917.762,6.668,4.471-.357,7.279-5.393,4.9-7.279-.357-4.756-5.243-1.964.772c-.565,4.388,2.378,9.922,5.585,13.471a15.667,15.667,0,0,0,16.372,4.4l39.893,43.769,1.223,1.363,3.782,4.145a15.657,15.657,0,0,0,2.793,16.693c3.191,3.523,7.43,5.875,11.838,5.756l1.969-.767-4.761-5.248.357-7.253,5.393-4.9,7.253.352,2.787,6.015,1.969-.772A15.663,15.663,0,0,0,550.283,323.14Z'
              transform='translate(-457.355 -247.603)'
            />
          </g>
          <path
            id='Path_21051'
            data-name='Path 21051'
            d='M547.986,324.624a15.662,15.662,0,0,0-16.393-4.388l-4.963-5.445-.689-.772L486.705,270.97a15.667,15.667,0,0,0-16.579-21.724l4.756,5.243-.352,7.253-5.4,4.917-7.253-.352-4.756-5.243a15.662,15.662,0,0,0,19.988,18.62L517,323.453l1.228,1.363,3.782,4.145a15.667,15.667,0,0,0,16.579,21.7l-4.761-5.253.352-7.253,5.4-4.9,7.253.358,4.756,5.243a15.641,15.641,0,0,0-3.6-14.237Z'
            transform='translate(-284.109 -152.973)'
            fill='#e0e0e0'
          />
          <path
            id='Path_21052'
            data-name='Path 21052'
            d='M547.826,324.5s.228.249.658.751a16.4,16.4,0,0,1,1.622,2.445,15.677,15.677,0,0,1,1.4,11.041v.1l-.073-.078-4.761-5.238.041.026-7.253-.347h.052l-5.388,4.9v-.057c-.1,2.259-.223,4.72-.347,7.279v-.067l4.72,5.207.124.135-.181.026A15.763,15.763,0,0,1,521.719,328.8v.124l-44.914-49.266.14.036a15.978,15.978,0,0,1-8.756.249,15.8,15.8,0,0,1-11.4-19.03l.062-.249.171.186,4.756,5.243-.093-.041,7.279.352-.1.036,5.43-4.9-.047.093c.119-2.456.238-4.875.358-7.279l.031.093-4.756-5.243-.161-.181.238-.031A15.794,15.794,0,0,1,487.892,263.1a16.061,16.061,0,0,1-1.218,7.771v-.124l44.841,49.3h-.062a15.709,15.709,0,0,1,12.9,1.518,16.467,16.467,0,0,1,2.715,2.072c.518.554.8.839.8.839l-.819-.819a17.329,17.329,0,0,0-2.725-2.072,15.688,15.688,0,0,0-12.833-1.461h-.036V320.1L486.53,270.88l-.052-.057.031-.067a15.87,15.87,0,0,0,1.192-7.678,15.556,15.556,0,0,0-17.657-13.88l.078-.212,4.694,5.295.036.036v.057q-.171,3.6-.352,7.253v.057l-.041.036-5.393,4.9-.041.036h-.057l-7.253-.357h-.057l-.036-.041-4.761-5.243.233-.057a15.543,15.543,0,0,0,19.8,18.486h.078l.062.062c16.31,17.916,31.6,34.687,44.883,49.286l.052.057-.031.073a15.56,15.56,0,0,0,16.481,21.558l-.062.161-4.751-5.243-.026-.031v-.073c.124-2.554.243-5.015.357-7.253v-.062l5.4-4.886h.031l7.253.363h0c1.741,1.927,3.342,3.694,4.746,5.254h-.093a15.653,15.653,0,0,0-1.342-10.989,17.267,17.267,0,0,0-1.591-2.456A7.858,7.858,0,0,0,547.826,324.5Z'
            transform='translate(-283.949 -152.852)'
            fill='#263238'
          />
          <path
            id='Path_21053'
            data-name='Path 21053'
            d='M503.537,296.169a1.367,1.367,0,0,1-.171.389,9.12,9.12,0,0,1-.606.974,12.564,12.564,0,0,1-5.937,4.565,8.578,8.578,0,0,1-1.1.332,1.3,1.3,0,0,1-.414.067c0-.047.565-.2,1.446-.575a14.32,14.32,0,0,0,5.854-4.5C503.2,296.662,503.5,296.149,503.537,296.169Z'
            transform='translate(-302.728 -175.66)'
            fill='#263238'
          />
          <path
            id='Path_21054'
            data-name='Path 21054'
            d='M557.729,324.256l-14.9-6.943-13.32-1.663,4.891,4.145s-17.222,6-17.522,7.357,1.357,4.989,4.393,4.316a5.161,5.161,0,0,0-.995,4.357c.71,2.285,2.28,2.715,5.181,2.181,0,0-1.969,2.948-.425,4.45a5.077,5.077,0,0,0,3.948,1.124s.031,3.59,1.927,3.9a31.059,31.059,0,0,0,9.155-.663l10.025-3.109,6.5-1.456Z'
            transform='translate(-313.105 -185.048)'
            fill='#ffbf9d'
          />
          <path
            id='Path_21055'
            data-name='Path 21055'
            d='M534.39,341.016c.057.2-1.927.943-4.425,1.673s-4.58,1.155-4.637.958,1.927-.948,4.425-1.673S534.333,340.819,534.39,341.016Z'
            transform='translate(-317.194 -197.249)'
            fill='#ff9a6c'
          />
          <path
            id='Path_21056'
            data-name='Path 21056'
            d='M540.967,354.679c.057.2-1.513.844-3.513,1.451s-3.673.927-3.735.731,1.518-.845,3.518-1.445S540.909,354.487,540.967,354.679Z'
            transform='translate(-321.236 -203.83)'
            fill='#ff9a6c'
          />
          <path
            id='Path_21057'
            data-name='Path 21057'
            d='M546.344,365.964c.036.223-1.394.518-3.072.943s-2.989,1.036-3.109.824,1.114-1.067,2.9-1.554S546.318,365.782,546.344,365.964Z'
            transform='translate(-324.339 -209.224)'
            fill='#ff9a6c'
          />
          <path
            id='Path_21058'
            data-name='Path 21058'
            d='M547.55,322.454c.057.2-1.9.912-4.316,1.746s-4.393,1.471-4.476,1.28,1.793-1.145,4.238-1.984S547.5,322.257,547.55,322.454Z'
            transform='translate(-323.664 -188.303)'
            fill='#ff9a6c'
          />
          <path
            id='Path_21059'
            data-name='Path 21059'
            d='M585.555,329.74l32.272,11.688-1.886,22.149L580.41,349.251Z'
            transform='translate(-343.738 -191.838)'
            fill='#ffbf9d'
          />
          <path
            id='Path_21060'
            data-name='Path 21060'
            d='M647.461,254.93a32.865,32.865,0,0,1,6.658,12.165c2.451,7.818,13.989,40.961,13.709,51.5-.306,10.906-7.647,18.688-23.781,14.377-9.626-2.59-21.1-6.735-21.1-6.735l8.155-26.806,6.378,1.642-10.3-19.335Z'
            transform='translate(-364.238 -155.787)'
            fill='#b6b6b6'
          />
          <path
            id='Path_21061'
            data-name='Path 21061'
            d='M637.258,279.38c.062.047-4.4,6.4-9.968,14.2s-10.118,14.1-10.181,14.046,4.4-6.4,9.968-14.2S637.2,279.338,637.258,279.38Z'
            transform='translate(-361.423 -167.569)'
            fill='#263238'
          />
          <path
            id='Path_21062'
            data-name='Path 21062'
            d='M660.015,345.974s-.28-.135-.782-.368a14.685,14.685,0,0,0-2.212-.782c-.958-.264-2.119-.518-3.409-.793l-4.186-.922c-2.969-.663-5.658-1.28-7.6-1.741l-2.3-.56a4.863,4.863,0,0,1-.834-.238,5.419,5.419,0,0,1,.855.14l2.316.471c1.953.414,4.663,1,7.616,1.663l4.181.948c1.285.29,2.445.56,3.4.85a12.713,12.713,0,0,1,2.207.87,5.287,5.287,0,0,1,.554.326A.864.864,0,0,1,660.015,345.974Z'
            transform='translate(-371.828 -197.057)'
            fill='#263238'
          />
          <g
            id='Group_7920'
            data-name='Group 7920'
            transform='translate(272.996 144.901)'
            opacity='0.3'
          >
            <path
              id='Path_21063'
              data-name='Path 21063'
              d='M673.959,348.446a3.994,3.994,0,0,0-2.217-2.964,10.74,10.74,0,0,0-3.71-.995,171.911,171.911,0,0,0-17.512-1.238l.518.233a109.177,109.177,0,0,1,21.112,6.155,1.627,1.627,0,0,0,1.223.145A1.28,1.28,0,0,0,673.959,348.446Z'
              transform='translate(-650.52 -343.25)'
            />
          </g>
          <g
            id='Group_7921'
            data-name='Group 7921'
            transform='translate(142.481 46.923)'
            opacity='0.3'
          >
            <path
              id='Path_21064'
              data-name='Path 21064'
              d='M419.607,154.543c-5.44-.927-11.947.653-17.387-.275a3.625,3.625,0,0,0-2.414.15,3.016,3.016,0,0,0-1.2,2.59,9.844,9.844,0,0,0,3.109,7.336,13.932,13.932,0,0,0,7.31,3.492,10.05,10.05,0,0,0,6.44-.751C418.866,165.242,420.5,158.278,419.607,154.543Z'
              transform='translate(-398.608 -154.137)'
            />
          </g>
          <path
            id='Path_21065'
            data-name='Path 21065'
            d='M394.706,155.062s-3.3-3.87-3.777-5.652a30.133,30.133,0,0,1,0-6.59,3.627,3.627,0,0,1,2.357-3.212,1.2,1.2,0,0,1,1.228.1,12.335,12.335,0,0,1,1.5-1.689c1.3-1.326,1.912-2.223,4.761-1.4,0,0,2.1-2.212,3.305-2.046a18.657,18.657,0,0,1,2.4.518s4.6-.2,5.538,1.264a4.212,4.212,0,0,0,2.072,1.383,5.7,5.7,0,0,1,3.279,2.989,11.869,11.869,0,0,1,.917,4l-4.663.186s.332,10.73-.844,11.6-1.622.554-1.622.554-.9,2.518-4.393.658c0,0-2.9.876-4.145-.321s-5.792-12.693-5.792-12.693l.129,5.7s2.4,3.694,1.223,4.559S394.706,155.062,394.706,155.062Z'
            transform='translate(-252.339 -97.782)'
            fill='#ffbf9d'
          />
          <path
            id='Path_21066'
            data-name='Path 21066'
            d='M409.63,146.06a3.629,3.629,0,0,1,.264.674l.632,1.865c.518,1.554,1.228,3.772,1.984,6.191a51.713,51.713,0,0,0,2.1,6.134,13.176,13.176,0,0,0,.953,1.71c.254.389.415.585.4.6s-.057-.036-.14-.13a4.787,4.787,0,0,1-.342-.409,10.448,10.448,0,0,1-1.036-1.689,44.821,44.821,0,0,1-2.186-6.134c-.767-2.414-1.435-4.621-1.912-6.217-.218-.762-.4-1.394-.549-1.891A3.933,3.933,0,0,1,409.63,146.06Z'
            transform='translate(-261.439 -103.322)'
            fill='#ff9a6c'
          />
          <path
            id='Path_21067'
            data-name='Path 21067'
            d='M420.7,143.5a3.454,3.454,0,0,1,.238.694l.554,1.922c.451,1.622,1.036,3.87,1.72,6.357s1.238,4.746,1.642,6.383c.192.777.347,1.43.471,1.938a3.6,3.6,0,0,1,.135.725,4.554,4.554,0,0,1-.238-.694c-.145-.518-.326-1.15-.549-1.922-.456-1.622-1.067-3.87-1.72-6.357s-1.238-4.746-1.648-6.383c-.187-.782-.347-1.43-.466-1.943A3.851,3.851,0,0,1,420.7,143.5Z'
            transform='translate(-266.773 -102.089)'
            fill='#ff9a6c'
          />
        </g>
      </g>
    </svg>
  );
}

SiteMaintainance.propTypes = {
  fill: PropTypes.string,
  sx: PropTypes.object,
};

SiteMaintainance.defaultProps = {
  fill: 'var(--primary-icon-color)',
  sx: {},
};
