import React from 'react';
import Avatar from '@mui/material/Avatar';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const NotificationSkeleton = () => {
  return (
    <>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
          padding: '13px 14px',
          width: '100%',
          borderBottom: '1px solid var(--secondary-border-color)',
          backgroundColor: '#f4f5f7',
        }}
      >
        <Stack>
          <Skeleton variant='circular'>
            <Avatar />
          </Skeleton>
        </Stack>
        <Stack sx={{ flex: 1 }}>
          <Skeleton variant='rectangular' height='42px' />
          <Skeleton />
        </Stack>
      </Stack>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
          padding: '13px 14px',
          width: '100%',
          borderBottom: '1px solid var(--secondary-border-color)',
          backgroundColor: '#f4f5f7',
        }}
      >
        <Stack>
          <Skeleton variant='circular'>
            <Avatar />
          </Skeleton>
        </Stack>
        <Stack sx={{ flex: 1 }}>
          <Skeleton variant='rectangular' height='42px' />
          <Skeleton />
        </Stack>
      </Stack>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
          padding: '13px 14px',
          width: '100%',
          borderBottom: '1px solid var(--secondary-border-color)',
          backgroundColor: '#f4f5f7',
        }}
      >
        <Stack>
          <Skeleton variant='circular'>
            <Avatar />
          </Skeleton>
        </Stack>
        <Stack sx={{ flex: 1 }}>
          <Skeleton variant='rectangular' height='42px' />
          <Skeleton />
        </Stack>
      </Stack>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
          padding: '13px 14px',
          width: '100%',
          borderBottom: '1px solid var(--secondary-border-color)',
          backgroundColor: '#f4f5f7',
        }}
      >
        <Stack>
          <Skeleton variant='circular'>
            <Avatar />
          </Skeleton>
        </Stack>
        <Stack sx={{ flex: 1 }}>
          <Skeleton variant='rectangular' height='42px' />
          <Skeleton />
        </Stack>
      </Stack>
    </>
  );
};

export default NotificationSkeleton;
