import { useEffect, useState } from 'react';
import { api } from 'api';
import { useParams } from 'react-router-dom';
import { useURLSearchParams } from 'common-hooks/url-params-listener-hooks';
import { getImageDisplayType, isImageHasBackground } from 'utils/helper';
import { useSelector } from 'react-redux';
import { selectCustomerDetailSelector } from 'redux/selectors';

export default function useViewSuggestions() {
  const [suggestionDetails, setSuggestionDetails] = useState(null);
  const [isSuggestionDetailsLoading, setIsSuggestionDetailsLoading] =
    useState(true);
  const [productViewStyle, setProductViewStyle] = useState('GRID');
  const [isDownloadButtonLoading, setIsDownloadButtonLoading] = useState(false);
  const { hash } = useURLSearchParams();
  const clientInfo = useSelector(selectCustomerDetailSelector);
  const aspectRatio = getImageDisplayType(clientInfo?.id);
  const isImageEditedByClient = isImageHasBackground(clientInfo?.id);

  const fetchSuggestions = async ({ download = false }) => {
    if (download) setIsDownloadButtonLoading(true);
    const res = await api.get(
      `guestSuggestionDetails?hash=${hash}&download=${
        download ? '1' : ''
      }&gridlineview=${
        download
          ? productViewStyle == 'LIST'
            ? 'line'
            : productViewStyle.toLowerCase()
          : ''
      }`
    );
    if (res?.data?.status) {
      if (download) {
        var a = document.createElement('a');
        a.setAttribute('download', true);
        a.href = res?.data?.pdf_link;
        document.body.appendChild(a);
        a.click();
        a.remove();
        setIsDownloadButtonLoading(false);
      } else {
        setProductViewStyle(
          res?.data?.gridlineview == 'grid' ? 'GRID' : 'LIST'
        );
        setSuggestionDetails(res?.data);
      }

      setIsSuggestionDetailsLoading(false);
    } else {
      if (download) setIsDownloadButtonLoading(false);
      setIsSuggestionDetailsLoading(false);
    }
  };
  useEffect(() => {
    if (hash) fetchSuggestions({ download: false });
  }, [hash]);
  return {
    suggestionDetails,
    isSuggestionDetailsLoading,
    productViewStyle,
    setProductViewStyle,
    aspectRatio,
    isImageEditedByClient,
    fetchSuggestions,
    isDownloadButtonLoading,
  };
}
