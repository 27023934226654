import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectCustomerDetailSelector } from 'redux/selectors';
import {
  getDepartmentCollections,
  getDepartmentDeliveries,
  getDepartmentCatagory,
} from '../layout-api';
import SessionStorageManager from 'utils/session-storage-manager';

export const useDepartmentSubMenu = (selectedDepartment, handleCloseMenus) => {
  const { genderId } = selectedDepartment;
  const navigate = useNavigate();
  const customerDetail = useSelector(selectCustomerDetailSelector);
  const [selectedCollection, setSelectedCollection] = useState();
  const [selectedDelivery, setSelectedDelivery] = useState();

  // reseting selected collection and delivery on department change
  useEffect(() => {
    setSelectedCollection();
    setSelectedDelivery();
  }, [genderId]);

  // reseting selected delivery on collection change
  useEffect(() => {
    setSelectedDelivery();
  }, [selectedCollection]);

  // fetching and caching collection
  const { data: collections = [], isLoading: collectionsLoading } = useQuery(
    ['getDepartmentCollections', genderId],
    () => getDepartmentCollections(genderId),
    {
      disabled: !!genderId,
      cacheTime: 3600000,
      staleTime: 3600000,
      select: (data) => {
        return data?.data?.data || [];
      },
    }
  );

  // fetching and caching deliveries
  const { data: deliveries = [], isLoading: deliveriesLoading } = useQuery(
    ['getDepartmentDeliveries', selectedCollection?.name, genderId],
    () =>
      getDepartmentDeliveries(selectedCollection?.name, 'delivery', genderId),
    {
      enabled: !!selectedCollection?.name && !!genderId,
      cacheTime: 3600000,
      staleTime: 3600000,
      select: (data) => {
        return data?.data?.data || [];
      },
    }
  );

  // fetching and caching categories
  const { data: categories = [], isLoading: categoriesLoading } = useQuery(
    ['getDepartmentCatagory', genderId, selectedDelivery?.id],
    () => getDepartmentCatagory(selectedDelivery?.id, genderId),
    {
      enabled: !!selectedDelivery?.id && !!genderId,
      cacheTime: 3600000,
      staleTime: 3600000,
      select: (data) => {
        return data?.data?.data || [];
      },
    }
  );

  const handleSelectCollection = (collection) => {
    setSelectedCollection(collection);
  };

  const handleSelectDelivery = (delivery) => {
    setSelectedDelivery(delivery);
  };

  const handleDepartmentMenuClick = (item) => {
    let navigationLink = '';
    if (Object.keys(item).length == 4) {
      // on click of categories
      let filter = `{"filter1":"${item?.id}","cfilter1":"${item?.id}"}`;
      navigationLink = `?gndr=${genderId}&cl=${item?.cl}&filters=${filter}&resetCatalog=true&start=0&resetCatalog=true&fetchProduct=true`;
    } else {
      navigationLink = `?gndr=${genderId}&cl=${item?.id}&resetCatalog=true&start=0&resetCatalog=true&fetchProduct=true`;
    }

    handleCloseMenus();
    navigate({
      pathname: '/catalog',
      search: navigationLink,
    });
  };

  return {
    collections,
    collectionsLoading,
    deliveries,
    deliveriesLoading,
    handleSelectCollection,
    handleSelectDelivery,
    selectedCollection,
    selectedDelivery,
    customerDetail,
    categories,
    categoriesLoading,
    handleDepartmentMenuClick,
  };
};
