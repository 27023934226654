import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import { useDepartmentSubMenu } from '../department-sub-menu-hooks';
import DepartmentCategories from '../components/department-categories';
import DepartmentCollection from '../components/department-collection';
import DepartmentDeliveries from '../components/department-deliveries';
import { MegaMenuStyles as styles } from '../styles';
import { zIndex } from 'constants/zIndex';
import { undefinedCheck } from '../../../../utils/helper';

const DepartmentMegaMenu = ({
  selectedDepartment,
  handleCloseMenus,
  menuButtonXPosition,
  open,
}) => {
  if (!selectedDepartment) return;
  const {
    collections,
    collectionsLoading,
    deliveries,
    deliveriesLoading,
    handleSelectCollection,
    selectedCollection,
    selectedDelivery,
    handleSelectDelivery,
    categories,
    categoriesLoading,
    handleDepartmentMenuClick,
  } = useDepartmentSubMenu(selectedDepartment, handleCloseMenus);

  return (
    <Fade in={open} timeout={700}>
      <Grid
        container
        spacing={2}
        style={{
          width: '700px',
          minHeight: '300px',
          maxHeight: '426px',
          backgroundColor: 'var(--paper-background-color)',
          position: 'absolute',
          top: '55px',
          left: menuButtonXPosition + 'px',
          zIndex: zIndex.popover,
          boxShadow: '0 3px 18px -12px rgba(0, 0, 0, 0.9)',
          boxSizing: 'border-box',
          marginLeft: '0px',
        }}
      >
        <Grid container margin='0px' sx={styles.megaMenuHeader}>
          <Grid item xs={0} sm={4} sx={styles.collectionGridItem}>
            <Typography variant='body2' sx={{ fontWeight: 700 }}>
              COLLECTIONS
            </Typography>
          </Grid>
          <Grid item xs={0} sm={4} sx={styles.collectionGridItem}>
            <Typography variant='body2' sx={{ fontWeight: 700 }}>
              DELIVERIES
            </Typography>
          </Grid>
          <Grid item xs={0} sm={4} sx={styles.deliveryGridItem}>
            <Typography variant='body2' sx={{ fontWeight: 700 }}>
              {categories.label_name ? categories.label_name : 'CATEGORIES'}
            </Typography>
          </Grid>
        </Grid>
        <Divider sx={styles.divider} />
        <Grid container margin='0px' sx={styles.menuContainer}>
          <Grid item xs={0} sm={4} sx={styles.collectionGrid}>
            <DepartmentCollection
              loading={collectionsLoading}
              collections={collections}
              handleSelectCollection={handleSelectCollection}
              handleCollectionClick={handleDepartmentMenuClick}
              selectedCollection={selectedCollection}
            />
          </Grid>
          <Grid item xs={0} sm={4} sx={styles.deliveriesGrid}>
            <DepartmentDeliveries
              loading={deliveriesLoading}
              deliveries={deliveries}
              handleSelectDelivery={handleSelectDelivery}
              handleDeliveryClick={handleDepartmentMenuClick}
              selectedDelivery={selectedDelivery}
            />
          </Grid>
          <Grid item xs={0} sm={4} sx={styles.categoriesGrid}>
            <DepartmentCategories
              categories={undefinedCheck(categories?.categories_data, [])}
              loading={categoriesLoading}
              handleCategoryClick={handleDepartmentMenuClick}
            />
          </Grid>
        </Grid>
      </Grid>
    </Fade>
  );
};

DepartmentMegaMenu.propTypes = {
  selectedDepartment: PropTypes.object.isRequired,
  handleCloseMenus: PropTypes.func.isRequired,
  menuButtonXPosition: PropTypes.any.isRequired,
  open: PropTypes.bool.isRequired,
};

export default DepartmentMegaMenu;
