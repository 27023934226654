import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import SuccessIcon from '../../../assets/icons/success-icon';
import AuthPage from '../components/auth-page';
import { recoverConfirmationStyles as styles } from './styles';

const RecoverConfirmation = ({ logo, message }) => {
  return (
    <AuthPage
      height='100vh'
      showBackToLogin
      childrenStyle={{ margin: 'auto 0' }}
    >
      <Stack sx={styles.container} spacing={2}>
        <Stack spacing={2} justifyContent='center' alignItems='center'>
          <SuccessIcon sx={styles.successIcon} />
          <Typography sx={styles.message}>{message}</Typography>
        </Stack>
      </Stack>
    </AuthPage>
  );
};

RecoverConfirmation.propTypes = {
  logo: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default RecoverConfirmation;
