import { React, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import BackArrowIcon from '../assets/icons/back-arrow-icon';
import { responsiveBreakpoints } from 'utils/utils';
import TopNotification from 'pages/home/components/notification/top-notification';

export default function Page({
  children,
  heading,
  subHeading,
  subHeading2,
  actions,
  backButtonText,
  backButtonHref,
  backgroundColor,
  backButtonFunc,
  catalogPage,
  isLoading,
  placeActionsbesideHeading,
  topNotificationProperties,
}) {
  const [showMobileViewNotification, setShowMobileViewNotification] = useState(
    topNotificationProperties?.show ?? false
  );
  const navigate = useNavigate();
  const { mobile, tablet, mainWidth } = responsiveBreakpoints();

  const loader = (
    <Stack alignItems='center' marginTop={20}>
      <CircularProgress color='primary' />
    </Stack>
  );

  // Conditionally applied container styles
  let conditionalContainerStyle = {};
  if (catalogPage) {
    conditionalContainerStyle = {
      padding: mobile || tablet ? '0px 15px 30px 15px' : '0px ',
      width: mobile || tablet ? '100vw' : 'auto',
    };
  } else if (backButtonHref && backButtonText) {
    conditionalContainerStyle = {
      padding: mobile || tablet ? '10px 15px 30px 15px' : '0px 35px 30px 35px',
    };
  } else {
    conditionalContainerStyle = {
      padding: mobile
        ? '20px 12px 30px 12px'
        : tablet
        ? '20px 35px 30px 35px'
        : '20px 0px 30px 0px',
      width: tablet ? '100vw' : 'auto',
    };
  }

  return (
    <Box
      sx={[
        {
          backgroundColor: backgroundColor ?? 'var(--page-background-color)',
          minHeight: '600px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        },
        conditionalContainerStyle,
      ]}
    >
      {showMobileViewNotification ? (
        <TopNotification
          startIcon={topNotificationProperties?.startIcon}
          message={topNotificationProperties?.message}
          hrefIcon={topNotificationProperties?.hrefIcon}
          onClickCloseIcon={() => {
            setShowMobileViewNotification(false);
          }}
        />
      ) : null}
      <Box
        sx={{
          width: mobile ? '100%' : tablet ? '100%' : mainWidth,
        }}
      >
        <Stack
          sx={[
            mobile
              ? {
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  height: 'fit-content',
                  marginBottom: '8px',
                  flexWrap: 'wrap',
                }
              : { display: 'flex', flexDirection: 'row' },
          ]}
        >
          {backButtonText && backButtonHref ? (
            <Stack
              spacing={1}
              direction='row'
              alignItems='center'
              onClick={(e) => {
                e.preventDefault();
                backButtonFunc ? backButtonFunc() : null;
                navigate(backButtonHref);
              }}
              sx={[
                {
                  marginBottom: '8px',
                  cursor: 'pointer',
                  width: 'fit-content',
                  marginTop: '10px',
                },
              ]}
            >
              <BackArrowIcon />
              <Typography
                variant='body2'
                sx={{
                  color: 'var(--link-color)',
                  lineHeight: '1.64',
                }}
              >
                {backButtonText}
              </Typography>
            </Stack>
          ) : null}
          {/* page actions buttons  for mobile*/}
          {mobile && !placeActionsbesideHeading && actions?.length > 0 ? (
            <Stack
              direction='row'
              sx={{
                flexGrow: '1',
                // justifyContent: 'flex-end',
                // alignItems: 'end',
                width: '100%',
                marginBottom: '6px',
              }}
            >
              <Stack
                direction='row'
                spacing={2}
                sx={{
                  width: '100%',
                  overflowX: 'auto',
                }}
              >
                {actions?.map((item, index) => {
                  return (
                    <Box
                      component='span'
                      key={`${index}+1`}
                      sx={{ fontSize: '12px', whiteSpace: 'nowrap' }}
                    >
                      {item}
                    </Box>
                  );
                })}
              </Stack>
            </Stack>
          ) : null}
        </Stack>
        {heading || subHeading || actions.length > 0 || subHeading2 ? (
          <Stack
            flexDirection='row'
            flexWrap='wrap-reverse'
            justifyContent={!mobile ? 'space-between' : 'space-between'}
            alignItems='center'
            marginBottom='10px'
            width='100%'
            gap={1}
          >
            <Stack sx={{ maxWidth: '100%' }}>
              <Typography
                variant='h1'
                sx={[
                  mobile
                    ? {
                        fontSize: '18px',
                        whiteSpace: 'wrap',
                      }
                    : {
                        fontSize: '20px',
                        whiteSpace: 'wrap',
                        lineHeight: '30px',
                      },
                ]}
              >
                {heading}
              </Typography>
              {subHeading ? (
                <Stack direction='row' justifyContent='space-between'>
                  <Typography
                    variant='subtitle1'
                    color='#333333BF'
                    sx={{ maxWidth: '100%' }}
                  >
                    {subHeading}
                  </Typography>
                </Stack>
              ) : null}
            </Stack>
            {!mobile || placeActionsbesideHeading ? (
              <Stack
                direction='row'
                spacing={1}
                alignItems={'end'}
                sx={{
                  marginBottom: '0px',
                }}
              >
                <Stack
                  direction='row'
                  spacing={2}
                  sx={{
                    width: '100%',
                    overflowX: 'auto',
                  }}
                >
                  {actions?.map((item, index) => {
                    if (!item) return null;
                    return (
                      <Box
                        component='span'
                        key={`${index}+1`}
                        sx={[
                          mobile
                            ? { fontSize: '12px', whiteSpace: 'nowrap' }
                            : null,
                        ]}
                      >
                        {item}
                      </Box>
                    );
                  })}
                </Stack>

                {subHeading2 && (
                  <Typography variant='subtitle1'>{subHeading2}</Typography>
                )}
              </Stack>
            ) : null}
          </Stack>
        ) : null}
        {isLoading ? loader : children}
      </Box>
    </Box>
  );
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  subHeading2: PropTypes.any,
  actions: PropTypes.array,
  backButtonText: PropTypes.string,
  backButtonHref: PropTypes.string,
  backgroundColor: PropTypes.string,
  backButtonFunc: PropTypes.func,
  catalogPage: PropTypes.bool,
  isLoading: PropTypes.bool, // to show a page loader
  placeActionsbesideHeading: PropTypes.bool,
  topNotificationProperties: PropTypes.object,
};

Page.defaultProps = {
  heading: '',
  subHeading: '',
  subHeading2: '',
  actions: [],
  backButtonText: null,
  backButtonHref: null,
  backgroundColor: null,
  backButtonFunc: () => {},
  catalogPage: false,
  isLoading: false,
  placeActionsbesideHeading: false,
  topNotificationProperties: {},
};
