import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { api } from 'api';
import { useDispatch } from 'react-redux';
import { getActiveDraftSuggestion } from 'redux/actions/global-actions';

export default function useCreateOverrideOrder(setOpen) {
  const [overrideOrderName, setOverrideOrderName] = useState('');
  const navigate = useNavigate();
  //redux dispatch
  const dispatch = useDispatch();

  async function createOverrideOrder() {
    const res = await api.post(`overrideorder`, {
      order_name: overrideOrderName,
    });

    if (res.data.status) {
      setOpen(false);
      //update active draft
      dispatch(getActiveDraftSuggestion());

      navigate(`/drafts/${res.data.worksheet_collection_id}`);
    }
  }
  return {
    overrideOrderName,
    setOverrideOrderName,
    createOverrideOrder,
  };
}
