import React from 'react';
import PropTypes from 'prop-types';

export default function LightBulbIcon({ fill, width, height, sx, style }) {
  return (
    <svg
      id='lamp-on'
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 23.329 23.329'
      sx={sx}
      style={style}
      fill={fill}
    >
      <path
        id='Vector'
        d='M4.073,15.6V14.468A8.339,8.339,0,0,1,0,7.683,7.7,7.7,0,0,1,9.419.189a7.567,7.567,0,0,1,5.123,3.956A8.013,8.013,0,0,1,11.3,14.458v1.128c0,.282.107.933-.933.933H5.006C3.937,16.529,4.073,16.111,4.073,15.6Z'
        transform='translate(3.995 1.94)'
        fill='none'
        stroke={fill}
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
      />
      <path
        id='Vector-2'
        data-name='Vector'
        d='M0,.474a12.45,12.45,0,0,1,6.8,0'
        transform='translate(8.262 20.911)'
        fill='none'
        stroke={fill}
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
      />
      <path
        id='Vector-3'
        data-name='Vector'
        d='M0,0H23.329V23.329H0Z'
        fill='none'
        opacity='0'
      />
      <path
        id='Vector-4'
        data-name='Vector'
        d='M1.139,0,.1,1.808c-.233.4-.039.729.418.729H1.752c.467,0,.651.33.418.729L1.139,5.074'
        transform='translate(10.529 6.59)'
        fill='none'
        stroke={fill}
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        opacity='0'
      />
      <path
        id='Vector-5'
        data-name='Vector'
        d='M.977,0,.085,1.55c-.2.342-.033.625.358.625H1.5c.4,0,.558.283.358.625L.977,4.35'
        transform='translate(10.692 8.024)'
        fill='none'
        stroke={fill}
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='1.5'
      />
    </svg>
  );
}

LightBulbIcon.propTypes = {
  fill: PropTypes.string,
  sx: PropTypes.object,
  style: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.string,
};

LightBulbIcon.defaultProps = {
  fill: '#292d32',
  sx: {},
  style: {},
  width: '23.289',
  height: '23.289',
};
