import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMatch } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';

import HamburgerIconM from 'assets/icons/icons-for-mobile/hamburger-icon-m';
import SearchIcon from 'assets/icons/search-icon';
import Search from './search/search';
import DepartmentMenuMobile from './department-mobile/department-m';
import CancelIcon from 'assets/icons/cancel-icon';

import { useHeader2, useHeader2M } from './hooks';

const Header2M = () => {
  const { config } = useHeader2();
  const {
    showSearchUI,
    setShowSearchUI,
    showDepartmentMobileMenu,
    setShowDepartmentMobileMenu,
  } = useHeader2M();
  const navigate = useNavigate();
  const isMarketplace = useMatch(`/marketplace/*`);
  return (
    <Box
      // position='static'
      sx={[
        {
          height: 'fit-content',
          backgroundColor: 'var(--paper-background-color)',
          boxShadow: 0,
          justifyContent: 'center',
          padding: '4px 21px 4px 21px',
          borderBottom: '1px solid var(--secondary-border-color)',
        },
        isMarketplace
          ? { borderBottom: '1px solid var(--secondary-border-color)' }
          : null,
      ]}
    >
      <Stack
        direction='row'
        justifyContent='space-between'
        sx={{ height: 'fit-content', alignItems: 'center' }}
      >
        <Stack
          direction='row'
          spacing={2}
          height='100%'
          alignItems='center'
          sx={{ minHeight: '50px', width: '100%' }}
        >
          <IconButton
            onClick={() => {
              setShowDepartmentMobileMenu(true);
            }}
          >
            <HamburgerIconM />
          </IconButton>
          <Box sx={{ width: '85%', overflowX: 'scroll' }}>
            {!isEmpty(config) && (
              <img
                src={config.media.logo}
                alt='logo'
                style={{ maxHeight: '50px', maxWidth: '650px' }}
                onClick={() => {
                  navigate('/');
                  location.reload();
                }}
              />
            )}
          </Box>
        </Stack>
        <Stack direction='row' height='100%' alignItems='center'>
          <IconButton
            onClick={() => {
              setShowSearchUI(!showSearchUI);
            }}
          >
            {showSearchUI ? (
              <CancelIcon height='22px' />
            ) : (
              <SearchIcon fill='#4d4663' />
            )}
          </IconButton>
        </Stack>
      </Stack>
      {showSearchUI ? (
        <Stack sx={{ padding: '10px 0px' }}>
          <Search />
        </Stack>
      ) : null}
      <DepartmentMenuMobile
        open={showDepartmentMobileMenu}
        setOpen={setShowDepartmentMobileMenu}
      />
    </Box>
  );
};

export default Header2M;
