import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import useCopyAssociates from './copy-associates-hooks';

export default function CopyAssociate({
  selectedAssociates,
  setSelectedAssociates,
  sx,
  worksheetCollectionId,
}) {
  const [isSelectAll, setIsSelectAll] = useState(false);

  function handleCheck(value) {
    if (selectedAssociates.some((obj) => obj.name === value.name)) {
      setSelectedAssociates(() =>
        selectedAssociates.filter((item) => item.name !== value.name)
      );
    } else {
      setSelectedAssociates((prev) => {
        return [...prev, value];
      });
    }
  }
  useEffect(() => {}, [selectedAssociates]);

  const { salesReps } = useCopyAssociates({ worksheetCollectionId });

  // dummy data ends
  return (
    <Box sx={{ height: '100%' }}>
      <TableContainer sx={{ height: '100%', ...sx }}>
        <Table
          size='small'
          sx={{ border: '1px solid var(--secondary-border-color)' }}
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <Stack direction='row' spacing={1}>
                  <Checkbox
                    checked={isSelectAll}
                    onClick={(e) => {
                      if (isSelectAll) {
                        setSelectedAssociates([]);
                      } else {
                        salesReps.forEach((buyer) => {
                          handleCheck(buyer);
                        });
                      }
                      setIsSelectAll(!isSelectAll);
                    }}
                  />
                  <span>ASSOCIATE NAME</span>
                </Stack>
              </TableCell>
              <TableCell>EMAIL ADDRESS</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {salesReps.map((row, i) => (
              <TableRow key={i}>
                {/* <TableCell sx={{ fontWeight: 900 }}>{row.accname}</TableCell> */}
                <TableCell sx={{ fontWeight: '900px' }}>
                  <Stack direction='row'>
                    <Checkbox
                      checked={(() => {
                        var x = false;
                        selectedAssociates.forEach((item) => {
                          if (item.name === row.name) x = true;
                        });
                        return x;
                      })()}
                      onClick={(e) => {
                        e.preventDefault();
                        handleCheck(row);
                      }}
                    />
                    <Typography>{row.name}</Typography>
                  </Stack>
                </TableCell>
                <TableCell>{row.email}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Pagination table ends */}
    </Box>
  );
}
CopyAssociate.propTypes = {
  selectedAssociates: PropTypes.array.isRequired,
  setSelectedAssociates: PropTypes.func.isRequired,
  sx: PropTypes.object.isRequired,
  worksheetCollectionId: PropTypes.any,
};
CopyAssociate.defaultProps = {
  sx: {},
  worksheetCollectionId: '',
};
