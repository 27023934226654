import {
  api,
  apiEndPoints,
  addActionLoader,
  removeActionLoader,
} from '../../api/index';
import { showSnackbar } from '../../components/snackbar/actions';
import { snackbarTypes } from '../../constants';
import { ADD_NOTES } from '../customers/actions';

const namespace = 'pages/catalog/';

export const SET_API_ERROR = `${namespace}SET_API_ERROR`;
export const SET_SUGGESTION_EMAIL_PAYLOAD = `${namespace}SET_SUGGESTION_EMAIL_PAYLOAD`;
export const PRODUCT_LEFT_NAV_REQUEST = `${namespace}PRODUCT_LEFT_NAV_REQUEST`;
export const SET_CATALOGPDF_PAYLOAD = `${namespace}SET_CATALOGPDF_PAYLOAD`;
export const SET_CATALOGLINK_PAYLOAD = `${namespace}SET_CATALOGLINK_PAYLOAD`;
export const SET_DRAFTLINK_PAYLOAD = `${namespace}SET_DRAFTLINK_PAYLOAD`;
export const SET_ORDERSHAREPDF_PAYLOAD = `${namespace}SET_ORDERSHAREPDF_PAYLOAD`;

const setApiError = (error) => ({
  type: SET_API_ERROR,
  payload: error,
});

export const setSuggestionEmailPayload = (payload) => ({
  type: SET_SUGGESTION_EMAIL_PAYLOAD,
  payload,
});

export const setCatalogPdfPayload = (payload) => ({
  type: SET_CATALOGPDF_PAYLOAD,
  payload,
});
export const setCatalogLinkPayload = (payload) => ({
  type: SET_CATALOGLINK_PAYLOAD,
  payload,
});
export const setDraftLinkPayload = (payload) => ({
  type: SET_DRAFTLINK_PAYLOAD,
  payload,
});
export const setOrderSharePdfPayload = (payload) => ({
  type: SET_ORDERSHAREPDF_PAYLOAD,
  payload,
});
