import axios from 'axios';
import { getBearerToken, logOut } from 'utils/auth';
import { AUTH_HEADER_NAME, API_URL } from 'constants';
import { getSubDomain } from 'utils/helper';
import { PublicScreen } from 'routes/screen-names';
import { MTATONCE_URL } from 'constants/index';

export const activeRequests = new Map();

const createAPI = () => {
  const customerApiUrl = API_URL.replace('{subdomain}', getSubDomain());

  const headers = {
    'Content-Type': 'application/json',
  };

  const api = axios.create({
    baseURL: customerApiUrl,
    headers,
  });

  api.interceptors.request.use(async (config) => {
    const source = axios.CancelToken.source();
    config.cancelToken = source.token;

    // Save or remember any custom headers provided in the request
    const requestSpecificHeaders = config.headers || {};

    const bearerToken = await getBearerToken();

    // Set default headers and override them with request-specific headers
    config.headers = {
      ...headers, // Default header
      ...(bearerToken ? { [`${AUTH_HEADER_NAME}`]: bearerToken } : {}), // Add auth header if token is available
      ...requestSpecificHeaders, // Override with request-specific headers
    };

    activeRequests.set(config.url, source);
    return config;
  });

  api.interceptors.response.use(
    (response) => {
      activeRequests.delete(response.config?.url);
      return response;
    },
    (error) => {
      activeRequests.delete(error.config?.url);
      if (!axios.isCancel(error)) {
        if (
          (error.response.status === 403 || error.response.status === 401) &&
          !Object.values(PublicScreen).includes(window.location.pathname)
        ) {
          logOut(error?.response?.data?.message);
        }
        if (error.response.status === 404) {
          window.open(MTATONCE_URL, '_self');
        }
        throw error;
      }
    }
  );
  return api;
};

export const cancelRequest = (url) => {
  for (let [key, value] of activeRequests.entries()) {
    if (key.startsWith(url)) {
      // Cancel the request
      value.cancel('Request cancelled.');
      // Remove the request from activeRequests
      activeRequests.delete(key);
    }
  }
};

export const cancelMultipleRequest = (urlList = []) => {
  for (let [key, value] of activeRequests.entries()) {
    urlList.forEach((url, index) => {
      if (key.startsWith(url)) {
        // Cancel the request
        value.cancel('Request cancelled.');
        // Remove the request from activeRequests
        activeRequests.delete(key);
        urlList.splice(index, 1);
      }
    });
  }
};

export const cancelAllRequest = () => {
  activeRequests.forEach((cancel) => cancel.cancel('Request cancelled'));
};

export default createAPI();
