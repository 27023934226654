import hexToRgb from './hexToRgb';

//calculate luminance
function luminance(r, g, b) {
  var a = [r, g, b].map(function (v) {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

//calculate contrast
export default function contrast(hex1, hex2) {
  //convert hex to rgb
  let rgb1 = hexToRgb(hex1);
  let rgb2 = hexToRgb(hex2);

  if (rgb1 && rgb2) {
    //get luminance
    var lum1 = luminance(rgb1[0], rgb1[1], rgb1[2]);
    var lum2 = luminance(rgb2[0], rgb2[1], rgb2[2]);

    //calculate contrast
    var brightest = Math.max(lum1, lum2);
    var darkest = Math.min(lum1, lum2);

    return (brightest + 0.05) / (darkest + 0.05);
  }
}
