import * as Yup from 'yup';

const phoneNumberRegEx =
  // eslint-disable-next-line no-useless-escape
  /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i;
const getRequiredFields = (fields) => {
  return fields.filter(
    (field) => field.visible && field?.constraints?.required
  );
};

const addValidation = (field) => {
  switch (field?.name) {
    case 'email': {
      return Yup.string()
        .email(`${field.label} is Invalid`)
        .required(`${field.label} is required`);
    }
    case 'phone': {
      return Yup.string()
        .required(`${field.label} is required`)
        .min(10, 'Invalid Phone Number')
        .matches(phoneNumberRegEx, 'Invalid Phone Number');
    }
    default: {
      return Yup.string().required(`${field.label} is required`);
    }
  }
};

export const newAccountFormSchema = (formFields = [], activeStep) => {
  const validatedFields = {};
  formFields.forEach((step) => {
    if (step.step === activeStep) {
      step.fields.forEach((field) => {
        if (field.is_address) {
          if (field.billing_address) {
            getRequiredFields(field.billing_address).forEach(
              (requiredField) => {
                validatedFields[`billing_${requiredField.name}`] =
                  addValidation(requiredField);
              }
            );
          }
          if (field.shipping_address) {
            getRequiredFields(field.shipping_address).forEach(
              (requiredField) => {
                validatedFields[`shipping_${requiredField.name}`] =
                  addValidation(requiredField);
              }
            );
          }
        } else if (field.visible && field?.constraints?.required) {
          validatedFields[field.name] = addValidation(field);
        } else {
          if (field.visible) {
            switch (field?.name) {
              case 'email': {
                validatedFields[field.name] = Yup.string().email(
                  `${field.label} is Invalid`
                );
                break;
              }
              case 'phone': {
                validatedFields[field.name] = Yup.string()
                  .min(10, 'Invalid Phone Number')
                  .matches(phoneNumberRegEx, 'Invalid Phone Number');
                break;
              }
            }
          }
        }
      });
    }
  });
  return Yup.object().shape({
    ...validatedFields,
  });
};
