const namespace = 'components/infoModal';

export const INFO_MODAL_SHOW = `${namespace}/INFO_MODAL_SHOW`;
export const INFO_MODAL_CLEAR = `${namespace}/INFO_MODAL_CLEAR`;

export const showInfoModal = (payload) => ({
  type: INFO_MODAL_SHOW,
  payload,
});

export const closeInfoModal = () => ({
  type: INFO_MODAL_CLEAR,
});
