import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import MyModal from '../modal';
import useScheduleEmailModal from './schedule-email-modal-hooks';
import { responsiveBreakpoints } from 'utils/utils';
import CustomDatePicker from 'components/date-picker';
import { scheduleEmailModalValidationSchema } from './schedule-email-modal-validation-schema';
import { fieldsName } from './constants';

import { style } from '../../../pages/customers/components/buyers-table/styles';
import CustomTimePicker from '../../time-picker';
import TimezoneAutoSearch from '../timezone/timezone-autosearch';

export default function ScheduleEmailModal({
  open,
  setOpen,
  timeZoneOptions,
  selectedTimeZone,
  setSendScheduleEmail,
  setScheduleDetails,
  sendEmail,
  setIsLoading,
  setShowPreview,
  setShowSendingEmailAlertModal,
}) {
  const { handleFormikSubmit, formikRef, isDateDisabled } =
    useScheduleEmailModal({
      setSendScheduleEmail,
      setScheduleDetails,
      sendEmail,
      setIsLoading,
      setShowPreview,
      setShowSendingEmailAlertModal,
    });
  const { mobile } = responsiveBreakpoints();
  const { timeZone, scheduleDate, scheduleTime } = fieldsName;

  const add = (
    <Button
      variant='contained'
      onClick={async () => {
        formikRef.current.submitForm();
      }}
    >
      Schedule Email
    </Button>
  );

  const close = (
    <Button
      variant='outlined'
      color='secondary'
      onClick={(e) => {
        e.preventDefault();
        setOpen(false);
      }}
    >
      Cancel
    </Button>
  );

  return (
    <MyModal
      open={open}
      setOpen={setOpen}
      size='small'
      title='Schedule Email'
      showCloseIcon
      actions={[close, add]}
      sx={
        mobile
          ? { width: '100%', height: '100%', padding: '12px' }
          : { width: '30vw' }
      }
    >
      <Formik
        innerRef={formikRef}
        validateOnMount
        validationSchema={scheduleEmailModalValidationSchema}
        initialValues={{
          timeZone: selectedTimeZone,
          scheduleDate: null,
          scheduleTime: null,
        }}
        enableReinitialize
        onSubmit={handleFormikSubmit}
      >
        {({ handleSubmit, setFieldValue, ...rest }) => {
          return (
            <Grid container rowGap='16px'>
              <Grid item xs={12} sm={12}>
                <TimezoneAutoSearch
                  required
                  id={timeZone}
                  label='Select Time Zone'
                  options={timeZoneOptions}
                  placeholder='Select Time Zone'
                  value={rest.values.timeZone}
                  setFormikValue={setFieldValue}
                  error={
                    rest?.touched?.timeZone && Boolean(rest?.errors?.timeZone)
                  }
                  helperText={rest?.touched?.timeZone && rest?.errors?.timeZone}
                />
              </Grid>
              <Grid item xs={12} sm={6} paddingRight='8px'>
                <CustomDatePicker
                  required
                  id={scheduleDate}
                  label={'Schedule Date'}
                  showPopupActions
                  shouldDisableDate={isDateDisabled}
                  sx={{
                    ...style.tableCellTextField,
                    '& .MuiFormControl-root .MuiOutlinedInput-root': {
                      height: '100%',
                    },
                  }}
                  value={rest.values.scheduleDate}
                  onChange={(e) => {
                    let dateValues = '';
                    if (e) {
                      dateValues = e
                        .toLocaleDateString('en-GB')
                        .split('/')
                        .reverse()
                        .join('-');
                    }
                    setFieldValue(scheduleDate, dateValues);
                  }}
                  error={
                    rest?.touched?.scheduleDate &&
                    Boolean(rest?.errors?.scheduleDate)
                  }
                  helperText={
                    rest?.touched?.scheduleDate && rest?.errors?.scheduleDate
                  }
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <CustomTimePicker
                  required
                  id={scheduleTime}
                  label='Schedule Time'
                  format={'HH:mm'}
                  views={['hours', 'minutes']}
                  value={rest.values.scheduleTime}
                  onChange={(e) => {
                    setFieldValue(scheduleTime, e);
                  }}
                  error={
                    rest?.touched?.scheduleTime &&
                    Boolean(rest?.errors?.scheduleTime)
                  }
                  helperText={
                    rest?.touched?.scheduleTime && rest?.errors?.scheduleTime
                  }
                />
              </Grid>
            </Grid>
          );
        }}
      </Formik>
    </MyModal>
  );
}

ScheduleEmailModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  timeZoneOptions: PropTypes.object,
  totalProducts: PropTypes.number,
  setSendScheduleEmail: PropTypes.func,
  setScheduleDetails: PropTypes.func,
  sendEmail: PropTypes.func,
  setIsLoading: PropTypes.func,
  setShowPreview: PropTypes.func,
  setShowSendingEmailAlertModal: PropTypes.func,
  selectedTimeZone: PropTypes.string,
};
