import React from 'react';
import PropTypes from 'prop-types';

export default function ResizeIcon({ fill, sx }) {
  return (
    <svg
      width='10'
      height='10'
      viewBox='0 0 10 10'
      fill='none'
      style={sx}
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_51_1486)'>
        <path
          d='M0 4.375H10V5.625H0V4.375ZM4.375 3.75H5.625V1.875H6.875L5 0L3.125 1.875H4.375V3.75ZM5.625 6.25H4.375V8.125H3.125L5 10L6.875 8.125H5.625V6.25Z'
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id='clip0_51_1486'>
          <rect width='10' height='10' fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
}

ResizeIcon.propTypes = {
  fill: PropTypes.string,
  sx: PropTypes.object,
};

ResizeIcon.defaultProps = {
  fill: 'white',
  sx: {},
};
