import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { CategoriesStyles as styles } from '../styles';

const DepartmentCategories = ({ loading, categories, handleCategoryClick }) => {
  return loading ? (
    <Box display='flex' alignItems='center' height='100%'>
      <CircularProgress />
    </Box>
  ) : (
    categories &&
      categories?.map((category) => (
        <Button
          key={category?.id}
          variant='text'
          sx={[styles.megaMenuCatagories]}
          onClick={() => handleCategoryClick(category)}
        >
          {category.name}
        </Button>
      ))
  );
};

DepartmentCategories.propTypes = {
  loading: PropTypes.bool.isRequired,
  categories: PropTypes.array.isRequired,
  handleCategoryClick: PropTypes.func.isRequired,
};

export default DepartmentCategories;
