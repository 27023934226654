export const BEARER_TOKEN = 'BEARER_TOKEN';
export const ADMIN = 'Admin';
export const AUTH_HEADER_NAME = 'Authorization';
export const API_URL = `${process.env.REACT_APP_BASE_URL}`;
export const CDN_URL = `${process.env.REACT_APP_CDN_URL}`;
export const SALT = 'abcdefghijk';
export const STAY_LOGGED_IN = 'STAY_LOGGED_IN';
export const MTATONCE_URL = 'https://about.myatonce.com';
export const APP_NAME = 'MyAtOnce';
export const TNC_URL = 'www.google.com';
export const INITIAL_PATHNAME = 'pathname';
export const INITIAL_SEARCH = 'search';
export const CHANGE_PASSWORD_REQUEST = 'force_change_password';

export const paymentTypeList = {
  card_connect: 'card_connect',
  authorize_net: 'authorize_net',
  stripe: 'stripe',
};

export const snackbarTypes = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
};

export const infoModalTypes = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
};

export const profileRoles = {
  admin: '1',
  client: '2',
  buyer: '3',
  sales_rep: '4',
};

export const NOTIFICATION_TYPE = {
  BUYER_EDIT_SHIIPING: '1',
  BUYER_EDIT_BILLING: '2',
  NEW_ACCOUNT_CREATION: '3',
  NEW_BUYER_FROM_ANOTHER_BUYER: '4',
  UPDATE_RETAILER_FROM_ANOTHER_BUYER: '5',
  SUGGESTION_FROM_SALES_REP_TO_ANOTHER_SALES_REP: '6',
  SHARED_DRAFT_FROM_SALES_REP_WITH_BUYER: '7',
  ORDER_SUBMITTED_TO_SALES_REP_FROM_BUYER: '8',
  NEW_BUYER_ACCOUNT_REQUEST_APPROVED: '9',
  BUYER_NEW_BILLING_ADDRESS_REQUEST: '10',
  BUYER_NEW_SHIPPING_ADDRESS_REQUEST: '11',
  BUYER_NEW_PAYMENT_TYPE_REQUEST: '12',
  BUYER_NEW_USER_REQUEST_APPROVED: '13',
  BUYER_NEW_BILLING_ADDRESS_REQUEST_APPROVED: '14',
  BUYER_NEW_SHIPPING_ADDRESS_REQUEST_APPROVED: '15',
  BUYER_NEW_PAYMENT_TYPE_REQUEST_APPROVED: '16',
  BUYER_NEW_USER_REQUEST_DECLINED: '17',
  BUYER_NEW_BILLING_ADDRESS_REQUEST_DECLINED: '18',
  BUYER_NEW_SHIPPING_ADDRESS_REQUEST_DECLINED: '19',
  BUYER_NEW_PAYMENT_TYPE_REQUEST_DECLINED: '20',
  BUYER_EDIT_BILLING_ADDRESS_REQUEST: '21',
  BUYER_EDIT_SHIPPING_ADDRESS_REQUEST: '22',
  BUYER_EDIT_PAYMENT_TYPE_REQUEST: '23',
  BUYER_EDIT_BILLING_ADDRESS_REQUEST_APPROVED: '24',
  BUYER_EDIT_SHIPPING_ADDRESS_REQUEST_APPROVED: '25',
  BUYER_EDIT_PAYMENT_TYPE_REQUEST_APPROVED: '26',
  BUYER_EDIT_BILLING_ADDRESS_REQUEST_DECLINED: '27',
  BUYER_EDIT_SHIPPING_ADDRESS_REQUEST_DECLINED: '28',
  BUYER_EDIT_PAYMENT_TYPE_REQUEST_DECLINED: '29',
  BUYER_EDIT_USER_PROFILE_REQUEST: '30',
  BUYER_EDIT_USER_PROFILE_REQUEST_APPROVED: '31',
  BUYER_EDIT_USER_PROFILE_REQUEST_DECLINED: '32',
  BUYER_EDIT_RETAILER_PROFILE_REQUEST: '33',
};
export const containerSize = {
  mainWidth: '1355px',
};

export const defaultMessages = {
  FAILED_TO_ADD_RETAILER: 'Failed to update',
  FAILED_TO_EDI_RETAILER: 'Failed to update',
};
export const clientIds = {
  dalascowboys: '208',
  theNorthwest: '140',
};

export const LogLevel = {
  INFO: 'INFO',
  WARN: 'WARN',
  ERROR: 'ERROR',
  TRACE: 'TRACE',
  DEBUG: 'DEBUG',
  EXCEP: 'EXCEP',
  METRIC: 'METRIC',
};
