import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { loggedInUserSelector } from 'redux/selectors';

import { useQuery } from 'react-query';

import {
  getSuggestions,
  getReceivedSuggestions,
} from 'pages/suggestions/actions';

export default function useSuggestionsQuickPanel() {
  const [initialValues, setInitialValues] = useState({});
  const [showNewSuggestionModal, setShowNewSuggestionModal] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showEditSuggestion, setShowEditSuggestion] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [selectedSuggestions, setSelectedSuggestions] = useState([]);
  const [showBulkDeleteConfirm, setShowBulkDeleteConfirm] = useState(false);
  const [showBulkDeleteAlert, setShowBulkDeleteAlert] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const dispatch = useDispatch();
  const userConfig = useSelector(loggedInUserSelector);
  const ROLE_ID = userConfig.roleId;
  const pageNumber = 1;

  const navigate = useNavigate();

  const {
    data: sharedSuggestionsData = [],
    isLoading: sharedSuggestionLoading,
  } = useQuery(
    ['getReceivedSuggestions', pageNumber],
    () => dispatch(getReceivedSuggestions({ pageNumber })),
    {
      select: (data) => {
        return data?.data || [];
      },
    }
  );
  const {
    data: suggestionData = [],
    isLoading: suggestionsLoading,
    ...rest
  } = useQuery(
    ['getSuggestions', pageNumber],
    () => dispatch(getSuggestions({ pageNumber })),
    {
      cacheTime: 3600000,
      staleTime: 3600000,

      select: (data) => {
        return data || [];
      },
    }
  );
  //check or uncheck suggestion
  function toggleSelected(idToToggle) {
    const isAlreadySelected = isSelected(idToToggle);
    var arr = [];

    if (isAlreadySelected) {
      selectedSuggestions.forEach((id) => {
        if (id !== idToToggle) {
          arr.push(id);
        }
      });
    } else {
      arr = selectedSuggestions.slice();
      arr.push(idToToggle);
    }
    setSelectedSuggestions(arr);
  }

  //check if suggestion is already selected
  function isSelected(idToCheck) {
    const isAlreadySelected = selectedSuggestions.some(
      (id) => id === idToCheck
    );
    return isAlreadySelected;
  }

  //on chaging selected suggestions, check or uncheck select all
  useEffect(() => {
    if (selectedSuggestions?.length === suggestionData?.data?.length) {
      setSelectAllChecked(true);
    } else {
      setSelectAllChecked(false);
    }
  }, [selectedSuggestions]);

  return {
    initialValues,
    setInitialValues,
    showNewSuggestionModal,
    setShowNewSuggestionModal,
    showDeleteConfirmation,
    setShowDeleteConfirmation,
    selectedId,
    setSelectedId,
    navigate,
    showEditSuggestion,
    setShowEditSuggestion,
    isSelected,
    toggleSelected,
    selectedSuggestions,
    setSelectedSuggestions,
    showBulkDeleteConfirm,
    setShowBulkDeleteConfirm,
    showBulkDeleteAlert,
    setShowBulkDeleteAlert,
    selectAllChecked,
    setSelectAllChecked,
    sharedSuggestionsData,
    suggestionData,
    suggestionsLoading,
    ROLE_ID,
  };
}
