/* eslint-disable react/no-unknown-property */
import React from 'react';
import PropTypes from 'prop-types';

export default function SuggestionsQuickPanelIconM({
  fill,
  active,
  width,
  height,
  stroke,
}) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='25'
      viewBox='0 0 25 25'
    >
      <g
        id='Group_8977'
        data-name='Group 8977'
        transform='translate(-1096.718 -36.58)'
      >
        <g id='Group_9053' data-name='Group 9053'>
          <g
            id='vuesax_linear_lamp-on'
            data-name='vuesax/linear/lamp-on'
            transform='translate(1096.718 36.58)'
          >
            <g id='lamp-on'>
              <path
                id='Vector'
                d='M4.365,16.713V15.5A8.937,8.937,0,0,1,0,8.233,8.249,8.249,0,0,1,10.094.2a8.109,8.109,0,0,1,5.49,4.24c2.177,4.375-.115,9.021-3.479,11.052V16.7c0,.3.115,1-1,1H5.365C4.219,17.713,4.365,17.265,4.365,16.713Z'
                transform='translate(4.281 2.079)'
                fill='none'
                stroke={stroke}
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='1.2'
              />
              <path
                id='Vector-2'
                data-name='Vector'
                d='M0,.508a13.342,13.342,0,0,1,7.292,0'
                transform='translate(8.854 22.409)'
                fill='none'
                stroke={stroke}
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='1.2'
              />
              <path
                id='Vector-3'
                data-name='Vector'
                d='M0,0H25V25H0Z'
                fill='none'
                opacity='0'
              />
              <path
                id='Vector-4'
                data-name='Vector'
                d='M1.221,0,.106,1.938c-.25.427-.042.781.448.781H1.877c.5,0,.7.354.448.781l-1.1,1.938'
                transform='translate(11.283 7.062)'
                fill='none'
                stroke={stroke}
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='1.2'
                opacity='0'
              />
              <path
                id='Vector-5'
                data-name='Vector'
                d='M1.047,0,.091,1.661c-.214.366-.036.67.384.67H1.609c.429,0,.6.3.384.67L1.047,4.662'
                transform='translate(11.458 8.599)'
                fill='none'
                stroke={stroke}
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='1.2'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

SuggestionsQuickPanelIconM.propTypes = {
  fill: PropTypes.string,
  active: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  stroke: PropTypes.string,
};

SuggestionsQuickPanelIconM.defaultProps = {
  fill: 'var(--primary-icon-color)',
  active: 'false',
  width: '34px',
  height: '34px',
  stroke: '#292d32',
};
