import { useState, useEffect } from 'react';
import { api } from '../../../api';

export default function useCopyAssociates({ worksheetCollectionId }) {
  const [salesReps, setSalesReps] = useState([]);

  //get sales reps
  async function getSalesReps() {
    let apiURL = worksheetCollectionId
      ? `/getSalesRepAssociate/${worksheetCollectionId}`
      : `/getSalesRepAssociate`;
    const res = await api.get(apiURL);
    setSalesReps(res.data);
  }

  useEffect(() => {
    getSalesReps();
  }, []);

  return {
    salesReps,
  };
}
