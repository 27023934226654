import React from 'react';
import PropTypes from 'prop-types';

export default function UploadIcon({ fill, sx }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='15.021'
      height='15'
      viewBox='0 0 15.021 15'
      fill={fill}
      style={sx}
    >
      <g id='upload' transform='translate(0 -0.358)'>
        <g id='Group_6505' data-name='Group 6505' transform='translate(0 9.52)'>
          <g id='Group_6504' data-name='Group 6504'>
            <path
              id='Path_20784'
              data-name='Path 20784'
              d='M13.847,312.642v4.078a.587.587,0,0,1-.587.587H1.76a.587.587,0,0,1-.587-.587v-4.078H0v4.078a1.762,1.762,0,0,0,1.76,1.76h11.5a1.762,1.762,0,0,0,1.76-1.76v-4.078Z'
              transform='translate(0 -312.642)'
            />
          </g>
        </g>
        <g
          id='Group_6507'
          data-name='Group 6507'
          transform='translate(3.864 0.358)'
        >
          <g id='Group_6506' data-name='Group 6506' transform='translate(0 0)'>
            <path
              id='Path_20785'
              data-name='Path 20785'
              d='M135.362.358,131.716,4l.83.83,2.23-2.23v9.145h1.174V2.6l2.23,2.23.83-.83Z'
              transform='translate(-131.716 -0.358)'
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

UploadIcon.propTypes = {
  fill: PropTypes.string,
  sx: PropTypes.object,
};

UploadIcon.defaultProps = {
  fill: '#fff',
  sx: {},
};
