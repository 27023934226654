import React from 'react';
import PropTypes from 'prop-types';

export default function GridViewIcon({ fill, sx }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill={fill}
      style={sx}
    >
      <g>
        <path d='M2.5,0H.5A.5.5,0,0,0,0,.5v2A.5.5,0,0,0,.5,3h2A.5.5,0,0,0,3,2.5V.5A.5.5,0,0,0,2.5,0Z' />
        <path
          d='M2.5,9H.5a.5.5,0,0,0-.5.5v2a.5.5,0,0,0,.5.5h2a.5.5,0,0,0,.5-.5v-2A.5.5,0,0,0,2.5,9Z'
          transform='translate(0 -4.5)'
        />
        <path
          d='M2.5,18H.5a.5.5,0,0,0-.5.5v2a.5.5,0,0,0,.5.5h2a.5.5,0,0,0,.5-.5v-2A.5.5,0,0,0,2.5,18Z'
          transform='translate(0 -9)'
        />
        <path
          d='M11.5,0h-2A.5.5,0,0,0,9,.5v2a.5.5,0,0,0,.5.5h2a.5.5,0,0,0,.5-.5V.5A.5.5,0,0,0,11.5,0Z'
          transform='translate(-4.5)'
        />
        <path
          d='M11.5,9h-2a.5.5,0,0,0-.5.5v2a.5.5,0,0,0,.5.5h2a.5.5,0,0,0,.5-.5v-2A.5.5,0,0,0,11.5,9Z'
          transform='translate(-4.5 -4.5)'
        />
        <path
          d='M11.5,18h-2a.5.5,0,0,0-.5.5v2a.5.5,0,0,0,.5.5h2a.5.5,0,0,0,.5-.5v-2A.5.5,0,0,0,11.5,18Z'
          transform='translate(-4.5 -9)'
        />
        <path
          d='M20.5,0h-2a.5.5,0,0,0-.5.5v2a.5.5,0,0,0,.5.5h2a.5.5,0,0,0,.5-.5V.5A.5.5,0,0,0,20.5,0Z'
          transform='translate(-9)'
        />
        <path
          d='M20.5,9h-2a.5.5,0,0,0-.5.5v2a.5.5,0,0,0,.5.5h2a.5.5,0,0,0,.5-.5v-2A.5.5,0,0,0,20.5,9Z'
          transform='translate(-9 -4.5)'
        />
        <path
          d='M20.5,18h-2a.5.5,0,0,0-.5.5v2a.5.5,0,0,0,.5.5h2a.5.5,0,0,0,.5-.5v-2A.5.5,0,0,0,20.5,18Z'
          transform='translate(-9 -9)'
        />
      </g>
    </svg>
  );
}

GridViewIcon.propTypes = {
  fill: PropTypes.string,
  sx: PropTypes.object,
};

GridViewIcon.defaultProps = {
  fill: 'var(--primary-icon-color)',
  sx: {},
};
