import React from 'react';
import PropTypes from 'prop-types';

export default function WarningIcon({ fill, width, height, sx }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 52.508 48'
      style={sx}
      fill={fill}
    >
      <g transform='translate(0 -20.882)'>
        <g transform='translate(0 20.882)'>
          <path
            d='M220.923,333.382a2.7,2.7,0,0,0,0,5.4,2.7,2.7,0,0,0,0-5.4Z'
            transform='translate(-194.67 -299.651)'
          />
          <path
            d='M51.23,64.176a9.215,9.215,0,0,0,.022-9.326l-16.9-29.273a9.313,9.313,0,0,0-16.169-.011L1.254,54.872a9.348,9.348,0,0,0,8.1,14.01H43.113A9.3,9.3,0,0,0,51.23,64.176ZM47.56,62.06A5.1,5.1,0,0,1,43.1,64.64H9.339a5.031,5.031,0,0,1-4.4-2.526,5.108,5.108,0,0,1-.011-5.149L21.849,27.671a5.083,5.083,0,0,1,8.829.011L47.592,56.976A5.04,5.04,0,0,1,47.56,62.06Z'
            transform='translate(0 -20.882)'
          />
          <path
            d='M219.808,157.252a2.935,2.935,0,0,0-2.083,2.947c.065.853.119,1.716.183,2.569.184,3.249.367,6.433.55,9.682a1.977,1.977,0,0,0,2.018,1.9,2.018,2.018,0,0,0,2.018-1.964c0-.669,0-1.284.065-1.964.119-2.083.248-4.166.367-6.25.065-1.349.184-2.7.248-4.048a3.333,3.333,0,0,0-.248-1.349A2.705,2.705,0,0,0,219.808,157.252Z'
            transform='translate(-194.224 -142.465)'
          />
        </g>
      </g>
    </svg>
  );
}

WarningIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  sx: PropTypes.object,
};

WarningIcon.defaultProps = {
  fill: '#ff0033',
  width: '52.508',
  height: '48',
  sx: {},
};
