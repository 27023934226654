import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircularProgress from '@mui/material/CircularProgress';

import { getDepartmentCollections } from 'pages/layout/layout-api';
import DeliveryAccordion from './deliveryAccordion';

const AccordionComponent = ({
  collection,
  genderId,
  handleDepartmentMenuClick,
}) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <Accordion
      disableGutters
      elevation={0}
      expanded={expanded}
      onChange={() => {
        setExpanded(!expanded);
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          padding: '0px 25px 0px 12px',
          '& .MuiAccordionSummary-content': {
            margin: 0, // Remove the margin
          },
        }}
      >
        <Stack
          direction='row'
          onClick={() => {
            handleDepartmentMenuClick({ item: collection, genderId });
          }}
        >
          <Typography variant='h4'>{collection.name}</Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: '0px',
        }}
      >
        <Stack sx={{}}>
          {expanded ? (
            <DeliveryAccordion
              collection={collection}
              genderId={genderId}
              handleDepartmentMenuClick={handleDepartmentMenuClick}
            />
          ) : null}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
AccordionComponent.propTypes = {
  collection: PropTypes.obj,
  genderId: PropTypes.any,
  handleDepartmentMenuClick: PropTypes.func,
};

AccordionComponent.defaultProps = {
  collection: {},
  genderId: '',
  handleDepartmentMenuClick: () => {},
};
const CollectionsAccordion = ({ item, handleDepartmentMenuClick }) => {
  const { data: collections = [], isLoading: collectionsLoading } = useQuery(
    ['getDepartmentCollections', item?.genderId],
    () => getDepartmentCollections(item?.genderId),
    {
      disabled: !!item?.genderId,
      cacheTime: 3600000,
      staleTime: 3600000,
      select: (data) => {
        return data?.data?.data || [];
      },
    }
  );
  if (collectionsLoading) {
    return (
      <CircularProgress
        sx={{
          width: '6px',
          height: '6px',
        }}
      />
    );
  } else {
    return collections?.map((collection, i) => {
      if (collection?.showCollection)
        return (
          <Stack key={i}>
            <AccordionComponent
              collection={collection}
              genderId={item?.genderId}
              handleDepartmentMenuClick={handleDepartmentMenuClick}
            />
          </Stack>
        );
    });
  }
};
export default CollectionsAccordion;
CollectionsAccordion.propTypes = {
  item: PropTypes.obj,
  handleDepartmentMenuClick: PropTypes.func,
};

CollectionsAccordion.defaultProps = {
  item: {},
  handleDepartmentMenuClick: () => {},
};
