// Default state for all filters
export const defaultFilterState = {
  salesRep: [],
  priceGroup: [],
  paymentTerms: [],
  shippingMethod: [],
  groups: [],
  sortBy: '',
  showRetailersAndBuyersWith: '',
  showBuyers: [],
  currency: [],
};
