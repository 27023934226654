import { useMemo } from 'react';
import DOMPurify from 'dompurify';

export const useSanitizeHTML = (dirtyHTML, config = {}) => {
  const cleanHTML = useMemo(() => {
    return DOMPurify.sanitize(dirtyHTML, config);
  }, [dirtyHTML, config]);

  return { cleanHTML };
};
