import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import styles from './styles';

function SectionHeader({ heading }) {
  return (
    <Stack sx={{ minWidth: '170px' }}>
      <Typography sx={styles.sectionHeader}>{heading}</Typography>
    </Stack>
  );
}

SectionHeader.propTypes = {
  heading: PropTypes.string.isRequired,
};

function Review({
  open,
  showRecipients,
  subject,
  message,
  selectedAssociates,
  selectedBuyers,
  selectedExternalContacts,
  totalBuyersSelected,
  uploadedFiles,
  sx,
}) {
  const [firstTimeRendered, setFirstTimeRendered] = useState(true);

  const htmlRegex = /<[^>]*>/;
  const containerRef = useRef(null);

  useEffect(() => {
    if (firstTimeRendered) {
      setFirstTimeRendered(false);
    } else {
      const container = containerRef.current;
      if (!container) {
        return;
      }
      // Set the inner HTML of the container
      container.innerHTML = message;
      document.getElementById('image_container').style.display = 'block';
    }
  }, [open, message, firstTimeRendered]);

  return (
    <Stack sx={[styles.container, sx]}>
      {showRecipients ? (
        <>
          <Stack direction='row' gap={1} flexWrap='wrap'>
            <SectionHeader heading='To (Buyers): ' />
            <Stack direction='row' sx={styles.recipientsContainer}>
              {selectedBuyers.map((buyer, i) => {
                if (i < 15)
                  return (
                    <Chip
                      key={i}
                      label={buyer.aname}
                      variant='outlined'
                      sx={styles.chip}
                    />
                  );
                if (i == 15)
                  return (
                    <Chip
                      label={`And ${totalBuyersSelected - i} More...`}
                      sx={styles.chip}
                    />
                  );
              })}
            </Stack>
          </Stack>
          <Stack direction='row' gap={1} flexWrap='wrap'>
            <SectionHeader heading='CC (Co-Workers): ' />
            <Stack direction='row' sx={styles.recipientsContainer}>
              {selectedExternalContacts.map((externalContact, i) => (
                <Chip
                  key={i}
                  label={externalContact?.contact_email}
                  variant='outlined'
                  sx={styles.chip}
                />
              ))}
            </Stack>
          </Stack>
          <Stack direction='row' gap={1} flexWrap='wrap'>
            <SectionHeader heading='Bcc (Outside Contacts): ' />
            <Stack direction='row' sx={styles.recipientsContainer}>
              {selectedAssociates.map((associate, i) => (
                <Chip
                  key={i}
                  label={`${associate.name} + (${associate.email})`}
                  variant='outlined'
                  sx={styles.chip}
                />
              ))}
            </Stack>
          </Stack>
        </>
      ) : null}

      <Stack spacing={1} direction='row'>
        <SectionHeader heading='Subject:' />
        <Typography variant='body1'>{subject}</Typography>
      </Stack>
      <Stack spacing={1} direction='row'>
        <SectionHeader heading='Attchments:' />
        {Array.isArray(uploadedFiles) && uploadedFiles.length > 0 ? (
          <Stack direction='row' sx={styles.attachemntsValueContainer}>
            {uploadedFiles.map((file) => {
              return (
                <Stack
                  key={`review_file_${file?.id}`}
                  direction='row'
                  spacing={0.5}
                  alignItems='center'
                >
                  {file?.filetype == 'file' ? (
                    <AttachFileOutlinedIcon style={styles.attachIcon} />
                  ) : (
                    <InsertPhotoOutlinedIcon style={styles.photoIcon} />
                  )}

                  <Typography variant='body2' color='#1189F0'>
                    {file?.actual_filename}
                  </Typography>
                </Stack>
              );
            })}
          </Stack>
        ) : null}
      </Stack>
      {/* textEditor */}
      <Stack spacing={1}>
        {/* check if message is string or html element */}
        {htmlRegex.test(message) ? (
          <div
            id='image_container'
            ref={containerRef}
            style={{ display: 'none' }}
          />
        ) : (
          <Typography
            sx={{
              lineHeight: 1.5,
              fontSize: '15px',
              whiteSpace: 'pre-line',
              wordWrap: 'break-word',
              overflowWrap: 'break-word',
              '& p': { margin: 0 },
            }}
            id='typo'
            variant='body1'
          >
            {message}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}

Review.propTypes = {
  open: PropTypes.bool.isRequired,
  showRecipients: PropTypes.bool,
  subject: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  selectedAssociates: PropTypes.array.isRequired,
  selectedBuyers: PropTypes.array.isRequired,
  totalBuyersSelected: PropTypes.number,
  uploadedFiles: PropTypes.array,
  selectedExternalContacts: PropTypes.array,
  sx: PropTypes.object,
};
Review.defaultProps = {
  open: false,
  showRecipients: true,
  subject: '',
  message: '',
  selectedAssociates: [],
  selectedBuyers: [],
  totalBuyersSelected: 0,
  uploadedFiles: [],
  selectedExternalContacts: [],
  sx: {},
};

export default React.memo(Review);
