import {
  SET_SUGGESTION_EMAIL_PAYLOAD,
  SET_CATALOGPDF_PAYLOAD,
  SET_CATALOGLINK_PAYLOAD,
  SET_DRAFTLINK_PAYLOAD,
  SET_ORDERSHAREPDF_PAYLOAD,
} from './action';

const initialState = {
  leftNavCollection: [],
  productViewStyle: 'GRID',
};

const sendEmailReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SUGGESTION_EMAIL_PAYLOAD:
      return {
        ...state,
        suggestionEmailPayload: action.payload,
      };
    case SET_CATALOGPDF_PAYLOAD:
      return {
        ...state,
        catalogPdfPayload: action.payload,
      };
    case SET_CATALOGLINK_PAYLOAD:
      return {
        ...state,
        catalogLinkPayload: action.payload,
      };
    case SET_DRAFTLINK_PAYLOAD:
      return {
        ...state,
        draftLinkPayload: action.payload,
      };
    case SET_ORDERSHAREPDF_PAYLOAD:
      return {
        ...state,
        orderSharePdfPayload: action.payload,
      };
    default:
      return state;
  }
};

export default sendEmailReducer;
